import React from 'react'
import Alert from '@mui/material/Alert'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Container from '@mui/material/Container'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import InputLabel from '@mui/material/InputLabel'
import ListSubheader from '@mui/material/ListSubheader'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import Snackbar from '@mui/material/Snackbar'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import StepLabel from '@mui/material/StepLabel'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import makeStyles from '@mui/styles/makeStyles'

import { useQuery, useMutation } from 'react-query'

import async from 'async-es'
import papaparse from 'papaparse'

import { FirebaseContext } from '../../utils/firebase'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

import { fileSize } from '../../utils/helpers'
import { API_ROOT_URL } from '../../constants/'

import CreateFormatDialog from './CreateFormatDialog'
import FieldMapper from './FieldMapper'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: 66,
    right: 0,
    width: 'calc(100% - 66px)',
    height: '100vh',
    overflowY: 'auto',
    paddingTop: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
  },
  paper: {
    padding: theme.spacing(2),
  },
  headerTopMargin: {
    marginTop: theme.spacing(2)
  },
  fileInput: {
    display: 'none'
  },
  fileName: {
    marginLeft: theme.spacing(1)
  },
  formControl: {
    minWidth: 250
  },
  uploadButton: {
  },
  buttonProgress: {
    color: theme.palette.common.white
  },
  deleteButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.error.main,
    '&:hover': {
      background: 'rgba(255,23,68,0.04)',
    },
  },
}))

const UPLOAD_SUCCESS_MESSAGE = 'CSV uploaded. Processing may take several minutes to several hours depending on file size.'
const UPLOAD_ERROR_MESSAGE = 'CSV uploaded failed. Please try again, and contact support if problem persists.'

const builtInFormats = [
  { id: 'activecampaign_contacts_csv', name: 'ActiveCampaign Contacts CSV', type: 'contact' },
  { id: 'agiliron_orders_csv', name: 'Agiliron Orders CSV', type: 'payment' },
  { id: 'authorize.net_transactions_csv', name: 'Authorize.net Transactions CSV', type: 'payment' },
  { id: 'buygoods_orders_csv', name: 'BuyGoods Orders CSV', type: 'payment' },
  { id: 'buygoods_transactions_csv', name: 'BuyGoods Transactions CSV (for rebills/refunds/chargebacks only)', type: 'payment' },
  { id: 'clickfunnels_contacts_csv', name: 'Clickfunnels Contacts CSV', type: 'contact' },
  { id: 'clickfunnels_funnels_html', name: 'Clickfunnels Funnels HTML', type: 'funnel' },
  { id: 'clickfunnels_sales_csv', name: 'Clickfunnels Sales CSV', type: 'payment' },
  { id: 'clickbank_master_transactions_csv', name: 'Clickbank Master Transactions CSV', type: 'payment' },
  { id: 'dropfunnels_leads_csv', name: 'DropFunnels Leads CSV', type: 'contact' },
  { id: 'dropfunnels_sales_csv', name: 'DropFunnels Sales CSV', type: 'payment' },
  { id: 'highlevel_contacts_csv', name: 'HighLevel Contacts CSV', type: 'contact' },
  { id: 'highlevel_orders_csv', name: 'HighLevel Orders CSV', type: 'payment' },
  { id: 'jvzoo_product_sales_csv', name: 'JVZoo Product Sales CSV', type: 'payment' },
  { id: 'kajabi_payments_csv', name: 'Kajabi Payments CSV', type: 'payment' },
  { id: 'kartra_transactions_csv', name: 'Kartra Transactions CSV', type: 'payment' },
  { id: 'keap_contacts_csv', name: 'Keap Contacts CSV', type: 'payment' },
  { id: 'keap_payments_csv', name: 'Keap Payments CSV', type: 'payment' },
  { id: 'merchant_one_transactions_csv', name: 'Merchant One Transactions CSV', type: 'payment' },
  { id: 'samcart_orders_csv', name: 'SamCart Orders CSV', type: 'payment' },
  { id: 'samcart_recurring_subscriptions_csv', name: 'SamCart Recurring Subscriptions CSV', type: 'payment' },
  { id: 'samcart_limited_subscriptions_csv', name: 'SamCart Limited Subscriptions CSV', type: 'payment' },
  { id: 'shopify_orders_csv', name: 'Shopify Orders CSV', type: 'payment' },
  { id: 'shopify_excelify_orders_csv', name: 'Shopify Excelify Orders CSV', type: 'payment' },
  { id: 'shopify_sales_attributed_to_marketing_csv', name: 'Shopify Sales Attributed To Marketing CSV', type: 'payment' },
  { id: 'sticky_orders_csv', name: 'Sticky.io Orders CSV', type: 'payment' },
  { id: 'stripe_payments_csv', name: 'Stripe Payments CSV', type: 'payment' },
  { id: 'thrivecart_transactions_csv', name: 'ThriveCart Transactions CSV', type: 'payment' },
  { id: 'warriorplus_product_sales_csv', name: 'WarriorPlus Product Sales CSV', type: 'payment' },
  { id: 'woocommerce_aoe_orders_csv', name: 'WooCommerce Advanced Order Export CSV', type: 'payment' },
]

const ltvFields = {
  payment: [
    { id: 'platform_id', group: 'payment', name: 'Payment ID', description: 'Unique identifier for payment.', dataType: 'string', examples: ['1','2','3','4','5'], importance: 'required' },
    { id: 'grouping_id', group: 'payment', name: 'Order ID', description: 'Order identifier that is shared between payments or line items in the same order, but different from one order to the next. This is used to group payments into orders. If this concept does not exist, use the same field as the Payment ID.', dataType: 'string', examples: ['someidtogrouporders','multiplepayments','canhavesame','groupingid','order12345'], importance: 'required' },
    { id: 'created_at', group: 'payment', name: 'Created Datetime', description: 'Date and time of the payment transaction. Will be interpreted in UTC time, unless the time zone offset is explicitly specified using ISO 8601 format. Date without time will be accepted and interpreted as midnight UTC on that date.', dataType: 'datetime', examples: [ '2022-01-01T12:34:56+00:00', '2022-01-02T12:34:56+01:00', '2022-01-03T12:34:56+11:00', '2022-01-04T12:34:56-03:00', '2022-01-05T12:34:56-07:00' ], importance: 'required' },
    { id: 'amount_cents', group: 'payment', name: 'Amount (cents)', description: 'Amount of payment in cents.', dataType: 'number', examples: [1000,999,99700,1299,500000], importance: 'required', exceptions: ['amount_dollars'] },
    { id: 'amount_dollars', group: 'payment', name: 'Amount (dollars)', description: 'Amount of payment in dollars and cents.', dataType: 'float', examples: ['10.00','9.99','997.00','12.99','5000.00'], importance: 'required', exceptions: ['amount_cents'] },
    { id: 'currency', group: 'payment', name: 'Currency', description: 'Currency of payment in a 3-character currency code using ISO 4217 format.', dataType: 'string', examples: ['USD','CAD','EUR','GBP','AUD'], importance: 'required' },
    { id: 'quantity', group: 'payment', name: 'Quantity', description: 'Quantity of the item associated with the payment', dataType: 'number', examples: [1,2,3,4,5], importance: 'optional' },
    { id: 'utm_source', group: 'payment', name: 'UTM Source', description: 'Identifier for the traffic source that resulted in the payment', dataType: 'string', examples: ['facebook','instagram','youtube','email','adwords'], importance: 'recommended' },
    { id: 'utm_medium', group: 'payment', name: 'UTM Medium', description: 'Identifier for the medium that is sending the traffic that resulted in the payment', dataType: 'string', examples: ['my_ad_set','ig_story','video_description','sequence_1','cpc'], importance: 'recommended' },
    { id: 'utm_campaign', group: 'payment', name: 'UTM Campaign', description: 'Identifier for the marketing campaign associated with the traffic that resulted in the payment', dataType: 'string', examples: ['my_campaign_1','my_promo','product_1','launch_campaign_1','my_campaign_2'], importance: 'recommended' },
    { id: 'utm_content', group: 'payment', name: 'UTM Content', description: 'Identifier for the marketing content used to generate the traffic that resulted in the payment', dataType: 'string', examples: ['video_ad_1','3_tips_video','top_10_mistakes','social_proof','competitor_alternative'], importance: 'recommended' },
    { id: 'utm_term', group: 'payment', name: 'UTM Term', description: 'Identifier for the keyword term associated with the traffic that resulted in the payment', dataType: 'string', examples: ['keyword_1','keyword_2','keyword_3','keyword_4','keyword_5'], importance: 'recommended' },
    { id: 'affiliate_id_1', group: 'payment', name: 'Affiliate ID 1', description: 'Identifier for affiliates. Recommended use is to group affiliates, such as by platform or other relevant grouping ID.', dataType: 'string', examples: ['clickfunnels_affiliates','clickbank_affiliates','shopify_affiliates','thrivecart_affiliates','samcart_affiliates'], importance: 'recommended' },
    { id: 'affiliate_id_2', group: 'payment', name: 'Affiliate ID 2', description: 'Additional identifier for affiliates. Recommended use it to identify individual affiliates so you can see stats on a per-affiliate level.', dataType: 'string', examples: ['12345','myaffiliateid','affiliate23456','someaffiliate','anyformatok'], importance: 'recommended' },

    { id: 'created_at', group: 'contact', name: 'Created Datetime', description: 'Date and time that the contact associated with the payment was created. If this value is not available, the date of the earliest payment for the contact will be used. Will be interpreted in UTC time, unless the time zone offset is explicitly specified using ISO 8601 format. Date without time will be accepted and interpreted as midnight UTC on that date.', dataType: 'datetime', examples: [ '2022-01-01T12:34:56+00:00', '2022-01-02T12:34:56+01:00', '2022-01-03T12:34:56+11:00', '2022-01-04T12:34:56-03:00', '2022-01-05T12:34:56-07:00' ], importance: 'recommended' },
    { id: 'email', group: 'contact', name: 'Email', description: 'Email address of the customer', dataType: 'email', examples: ['sample1@ltvnumbers.com','sample2@ltvnumbers.com','sample3@ltvnumbers.com','sample4@ltvnumbers.com','sample5@ltvnumbers.com'], importance: 'required' },
    { id: 'ip', group: 'contact', name: 'IP Address', description: 'IP Address of the customer', dataType: 'string', examples: ['000.000.000.000','000.000.000.000','000.000.000.000','000.000.000.000','000.000.000.000',], importance: 'optional' },
    { id: 'full_name', group: 'contact', name: 'Full Name', description: 'Full name of the customer', dataType: 'string', examples: ['Full Name','Some Person','Tyler Ryan','Another Guy','This Girl'], importance: 'optional' },
    { id: 'first_name', group: 'contact', name: 'First Name', description: 'First name of the customer', dataType: 'string', examples: ['Full','Some','Tyler','Another','This'], importance: 'optional' },
    { id: 'last_name', group: 'contact', name: 'Last Name', description: 'Last name of the customer', dataType: 'string', examples: ['Name','Person','Ryan','Guy','Girl'], importance: 'optional' },
    { id: 'time_zone', group: 'contact', name: 'Time Zone', description: 'Time zone of the customer', dataType: 'string', examples: ['America/Los_Angeles','America/New_York','PST','EST','UTC+10'], importance: 'optional' },
    { id: 'country', group: 'contact', name: 'Country', description: 'Country of the customer', dataType: 'string', examples: ['United States','USA','United States of America','US','Australia'], importance: 'optional' },

    { id: 'platform_id', group: 'funnel', name: 'Funnel ID', description: 'Unique identifier for the sales funnel associated with the payment', dataType: 'string', examples: ['01','02','03','04','05'], importance: 'required' },
    { id: 'nickname', group: 'funnel', name: 'Funnel Name', description: 'Name of the funnel', dataType: 'string', examples: ['My Funnel Name','Amazing Funnel','Free + Shipping Book','12 Week Course','Coaching Funnel'], importance: 'required' },
    { id: 'created_at', group: 'funnel', name: 'Funnel Datetime', description: 'Date and time that the funnel associated with the payment was created. If this value is not available, the date of the earliest payment in the funnel will be used. Will be interpreted in UTC time, unless the time zone offset is explicitly specified using ISO 8601 format. Date without time will be accepted and interpreted as midnight UTC on that date.', dataType: 'datetime', examples: [ '2022-01-01T12:34:56+00:00', '2022-01-02T12:34:56+01:00', '2022-01-03T12:34:56+11:00', '2022-01-04T12:34:56-03:00', '2022-01-05T12:34:56-07:00' ], importance: 'recommended' },

    { id: 'platform_id', group: 'product', name: 'Product ID', description: 'Unique identifier for product associated with the payment', dataType: 'string', examples: ['001','002','003','004','005'], importance: 'required' },
    { id: 'nickname', group: 'product', name: 'Product Name', description: 'Name of the product', dataType: 'string', examples: ['Product 1','My Product','How To Boost LTV','12 Week LTV Masterclass','LTV Mastermind'], importance: 'required' },
    { id: 'created_at', group: 'product', name: 'Product Datetime', description: 'Date and time that the product associated with the payment was created. If this value is not available, the date of the earliest payment for this product will be used. Will be interpreted in UTC time, unless the time zone offset is explicitly specified using ISO 8601 format. Date without time will be accepted and interpreted as midnight UTC on that date.', dataType: 'datetime', examples: [ '2022-01-01T12:34:56+00:00', '2022-01-02T12:34:56+01:00', '2022-01-03T12:34:56+11:00', '2022-01-04T12:34:56-03:00', '2022-01-05T12:34:56-07:00' ], importance: 'optional' },

    { id: 'platform_id', group: 'productVariant', name: 'Product Variant ID', description: 'Unique identifier for product variant associated with the payment', dataType: 'string', examples: ['001','002','003','004','005'], importance: 'optional' },
    { id: 'nickname', group: 'productVariant', name: 'Product Variant Name', description: 'Name of the product variant', dataType: 'string', examples: ['Variant 1','My Variant','How To Boost LTV','12 Week LTV Masterclass','LTV Mastermind'], importance: 'optional' },
    { id: 'created_at', group: 'productVariant', name: 'Product Variant Datetime', description: 'Date and time that the product variant associated with the payment was created. If this value is not available, the date of the earliest payment for this product variant will be used. Will be interpreted in UTC time, unless the time zone offset is explicitly specified using ISO 8601 format. Date without time will be accepted and interpreted as midnight UTC on that date.', dataType: 'datetime', examples: [ '2022-01-01T12:34:56+00:00', '2022-01-02T12:34:56+01:00', '2022-01-03T12:34:56+11:00', '2022-01-04T12:34:56-03:00', '2022-01-05T12:34:56-07:00' ], importance: 'optional' },

    { id: 'platform_id', group: 'subscription', name: 'Subscription ID', description: 'Unique identifier for the subscription associated with the payment', dataType: 'string', examples: ['01','02','03','04','05'], importance: 'required' },
    { id: 'created_at', group: 'subscription', name: 'Subscription Datetime', description: 'Date and time that the subscription associated with the payment was created. If this value is not available, the date of the earliest payment in the subscription will be used. Will be interpreted in UTC time, unless the time zone offset is explicitly specified using ISO 8601 format. Date without time will be accepted and interpreted as midnight UTC on that date.', dataType: 'datetime', examples: [ '2022-01-01T12:34:56+00:00', '2022-01-02T12:34:56+01:00', '2022-01-03T12:34:56+11:00', '2022-01-04T12:34:56-03:00', '2022-01-05T12:34:56-07:00' ], importance: 'recommended' },
  ],
  contact: [
    { id: 'created_at', group: 'contact', name: 'Created Datetime', description: 'Date and time that the contact associated with the contact was created. If this value is not available, the date of the earliest contact for the contact will be used. Will be interpreted in UTC time, unless the time zone offset is explicitly specified using ISO 8601 format. Date without time will be accepted and interpreted as midnight UTC on that date.', dataType: 'datetime', examples: [ '2022-01-01T12:34:56+00:00', '2022-01-02T12:34:56+01:00', '2022-01-03T12:34:56+11:00', '2022-01-04T12:34:56-03:00', '2022-01-05T12:34:56-07:00' ], importance: 'recommended' },
    { id: 'email', group: 'contact', name: 'Email', description: 'Email address of the customer', dataType: 'email', examples: ['sample1@ltvnumbers.com','sample2@ltvnumbers.com','sample3@ltvnumbers.com','sample4@ltvnumbers.com','sample5@ltvnumbers.com'], importance: 'required' },
    { id: 'ip', group: 'contact', name: 'IP Address', description: 'IP Address of the customer', dataType: 'string', examples: ['000.000.000.000','000.000.000.000','000.000.000.000','000.000.000.000','000.000.000.000',], importance: 'optional' },
    { id: 'full_name', group: 'contact', name: 'Full Name', description: 'Full name of the customer', dataType: 'string', examples: ['Full Name','Some Person','Tyler Ryan','Another Guy','This Girl'], importance: 'optional' },
    { id: 'first_name', group: 'contact', name: 'First Name', description: 'First name of the customer', dataType: 'string', examples: ['Full','Some','Tyler','Another','This'], importance: 'optional' },
    { id: 'last_name', group: 'contact', name: 'Last Name', description: 'Last name of the customer', dataType: 'string', examples: ['Name','Person','Ryan','Guy','Girl'], importance: 'optional' },
    { id: 'time_zone', group: 'contact', name: 'Time Zone', description: 'Time zone of the customer', dataType: 'string', examples: ['America/Los_Angeles','America/New_York','PST','EST','UTC+10'], importance: 'optional' },
    { id: 'country', group: 'contact', name: 'Country', description: 'Country of the customer', dataType: 'string', examples: ['United States','USA','United States of America','US','Australia'], importance: 'optional' },
    { id: 'utm_source', group: 'contact', name: 'UTM Source', description: 'Identifier for the traffic source that resulted in the contact being created', dataType: 'string', examples: ['facebook','instagram','youtube','email','adwords'], importance: 'recommended' },
    { id: 'utm_medium', group: 'contact', name: 'UTM Medium', description: 'Identifier for the medium that is sending the traffic that resulted in the contact being created', dataType: 'string', examples: ['my_ad_set','ig_story','video_description','sequence_1','cpc'], importance: 'recommended' },
    { id: 'utm_campaign', group: 'contact', name: 'UTM Campaign', description: 'Identifier for the marketing campaign associated with the traffic that resulted in the contact being created', dataType: 'string', examples: ['my_campaign_1','my_promo','product_1','launch_campaign_1','my_campaign_2'], importance: 'recommended' },
    { id: 'utm_content', group: 'contact', name: 'UTM Content', description: 'Identifier for the marketing content used to generate the traffic that resulted in the contact being created', dataType: 'string', examples: ['video_ad_1','3_tips_video','top_10_mistakes','social_proof','competitor_alternative'], importance: 'recommended' },
    { id: 'utm_term', group: 'contact', name: 'UTM Term', description: 'Identifier for the keyword term associated with the traffic that resulted in the contact being created', dataType: 'string', examples: ['keyword_1','keyword_2','keyword_3','keyword_4','keyword_5'], importance: 'recommended' },
    { id: 'affiliate_id_1', group: 'contact', name: 'Affiliate ID 1', description: 'Identifier for affiliates. Recommended use is to group affiliates, such as by platform or other relevant grouping ID.', dataType: 'string', examples: ['clickfunnels_affiliates','clickbank_affiliates','shopify_affiliates','thrivecart_affiliates','samcart_affiliates'], importance: 'recommended' },
    { id: 'affiliate_id_2', group: 'contact', name: 'Affiliate ID 2', description: 'Additional identifier for affiliates. Recommended use it to identify individual affiliates so you can see stats on a per-affiliate level.', dataType: 'string', examples: ['12345','myaffiliateid','affiliate23456','someaffiliate','anyformatok'], importance: 'recommended' },

    { id: 'platform_id', group: 'funnel', name: 'Funnel ID', description: 'Unique identifier for the sales funnel associated with the payment', dataType: 'string', examples: ['01','02','03','04','05'], importance: 'recommended' },
    { id: 'nickname', group: 'funnel', name: 'Funnel Name', description: 'Name of the funnel', dataType: 'string', examples: ['My Funnel Name','Amazing Funnel','Free + Shipping Book','12 Week Course','Coaching Funnel'], importance: 'recommended' },
    { id: 'created_at', group: 'funnel', name: 'Funnel Datetime', description: 'Date and time that the funnel associated with the payment was created. If this value is not available, the date of the earliest payment in the funnel will be used. Will be interpreted in UTC time, unless the time zone offset is explicitly specified using ISO 8601 format. Date without time will be accepted and interpreted as midnight UTC on that date.', dataType: 'datetime', examples: [ '2022-01-01T12:34:56+00:00', '2022-01-02T12:34:56+01:00', '2022-01-03T12:34:56+11:00', '2022-01-04T12:34:56-03:00', '2022-01-05T12:34:56-07:00' ], importance: 'recommended' },

    { id: 'platform_id', group: 'product', name: 'Product ID', description: 'Unique identifier for product associated with the payment', dataType: 'string', examples: ['001','002','003','004','005'], importance: 'optional' },
    { id: 'nickname', group: 'product', name: 'Product Name', description: 'Name of the product', dataType: 'string', examples: ['Product 1','My Product','How To Boost LTV','12 Week LTV Masterclass','LTV Mastermind'], importance: 'optional' },
    { id: 'created_at', group: 'product', name: 'Product Datetime', description: 'Date and time that the product associated with the payment was created. If this value is not available, the date of the earliest payment for this product will be used. Will be interpreted in UTC time, unless the time zone offset is explicitly specified using ISO 8601 format. Date without time will be accepted and interpreted as midnight UTC on that date.', dataType: 'datetime', examples: [ '2022-01-01T12:34:56+00:00', '2022-01-02T12:34:56+01:00', '2022-01-03T12:34:56+11:00', '2022-01-04T12:34:56-03:00', '2022-01-05T12:34:56-07:00' ], importance: 'optional' },
    { id: 'bump', group: 'product', name: 'Prduct Bump', description: 'Whether or not this product was considered an order bump (ie. a checkbox add on placed on the checkout page).', dataType: 'boolean', examples: ['false','true','no','yes','false'], importance: 'optional' },

    { id: 'platform_id', group: 'productVariant', name: 'Product Variant ID', description: 'Unique identifier for product variant associated with the payment', dataType: 'string', examples: ['001','002','003','004','005'], importance: 'optional' },
    { id: 'nickname', group: 'productVariant', name: 'Product Variant Name', description: 'Name of the product variant', dataType: 'string', examples: ['Variant 1','My Variant','How To Boost LTV','12 Week LTV Masterclass','LTV Mastermind'], importance: 'optional' },
    { id: 'created_at', group: 'productVariant', name: 'Product Variant Datetime', description: 'Date and time that the product variant associated with the payment was created. If this value is not available, the date of the earliest payment for this product variant will be used. Will be interpreted in UTC time, unless the time zone offset is explicitly specified using ISO 8601 format. Date without time will be accepted and interpreted as midnight UTC on that date.', dataType: 'datetime', examples: [ '2022-01-01T12:34:56+00:00', '2022-01-02T12:34:56+01:00', '2022-01-03T12:34:56+11:00', '2022-01-04T12:34:56-03:00', '2022-01-05T12:34:56-07:00' ], importance: 'optional' },
  ]
}
let emptyDataMapPayment = {}
ltvFields.payment.forEach(field => {
  if (!(field.group in emptyDataMapPayment)) {
    emptyDataMapPayment[field.group] = {}
  }
  emptyDataMapPayment[field.group][field.id] = ''
})
let emptyDataMapContact = {}
ltvFields.contact.forEach(field => {
  if (!(field.group in emptyDataMapContact)) {
    emptyDataMapContact[field.group] = {}
  }
  emptyDataMapContact[field.group][field.id] = ''
})

const platformValues = [
  'activecampaign',
  'agiliron',
  'authorize.net',
  'buygoods',
  'bucket.io',
  'clickfunnels',
  'clickbank',
  'custom',
  'convertbox',
  'digistore24',
  'dropfunnels',
  'highlevel',
  'jvzoo',
  'kajabi',
  'kartra',
  'keap',
  'konnektive',
  'merchant_one',
  'ontraport',
  'paypal',
  'samcart',
  'shopify',
  'sticky',
  'stripe',
  '10x_crm',
  'thrivecart',
  'typeform',
  'warriorplus',
  'woocommerce',
  'wordpress',
]

const Upload = () => {
  const classes = useStyles()
  const firebase = React.useContext(FirebaseContext)
  const [activeStep, setActiveStep] = React.useState(0)
  const [inputFiles, setInputFiles] = React.useState([])
  const [inputFilePreview, setInputFilePreview] = React.useState({})
  const [isSelectingFiles, setIsSelectingFiles] = React.useState(false)
  const [files, setFiles] = React.useState([])
  const [formatId, setFormatId] = React.useState('')
  const [formatName, setFormatName] = React.useState('')
  const [formatDataType, setFormatDataType] = React.useState('')
  const [showFormatDialog, setShowFormatDialog] = React.useState(false)
  const [dataMap, setDataMap] = React.useState({})
  const [acceptAllRows, setAcceptAllRows] = React.useState(false)
  const [productPlatform, setProductPlatform] = React.useState('other')
  const [integration, setIntegration] = React.useState(null)
  const [uploading, setUploading] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const [showMessage, setShowMessage] = React.useState(false)
  const [messageSeverity, setMessageSeverity] = React.useState('success')
  const [showDeleteFormatConfirmation, setShowDeleteFormatConfirmation] = React.useState(false)
  const fileInputRef = React.createRef()

  const { data: user } = useQuery(['user-id'], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/users`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      }).then(res => res.json())
    }),
    {
      cacheTime: 15 * 60 * 1000,  // 15 minutes
      staleTime: 15 * 60 * 1000,  // 15 minutes
      refetchOnWindowFocus: false,
    }
  )

  const {
    isLoading: isLoadingFormats,
    data: formats = [],
    refetch: refetchDataFormats
  } = useQuery(['data-formats'], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/dataMaps/`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      }).then(res => res.json())
    }),
    {
      cacheTime: 15 * 60 * 1000,  // 15 minutes
      staleTime: 15 * 60 * 1000,  // 15 minutes
      refetchOnWindowFocus: false,
    }
  )

  const {
    isLoading: isLoadingIntegrations,
    data: integrations = []
  } = useQuery(['integrations-list'], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      }).then(res => res.json())
    }),
    {
      cacheTime: 0,
      staleTime: 0,
      refetchOnWindowFocus: false,
    }
  )

  // When selected format changes, update format name and data type
  React.useEffect(() => {
    if (isCustomFormat(formatId)) {
      const format = getFormatById(formatId)
      setFormatName(format.name)
      setFormatDataType(format.type)
      setDataMap(format.map)
      setAcceptAllRows(format.acceptAllRows)
      setProductPlatform(format.productPlatform)
    }
  }, [formatId])

  const getFormatById = (id) => {
    return formats.find(el => el.id === id)
  }

  const isBuiltInFormat = (id) => {
    return builtInFormats.findIndex(el => el.id === id) > -1
  }

  const isCustomFormat = (id) => {
    return formatId && !isBuiltInFormat(id)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleStep = (step) => () => {
    setActiveStep(step)
  }

  const handleFormatNameChange = (event) => {
    setFormatName(event.target.value)
  }

  const handleFilesSelected = (event) => {
    let filePreview = {}
    const tasks = [...Array(event.target.files.length).keys()].map(fileIndex => {
      return callback => {
        const inputFile = event.target.files[fileIndex]

        // Split CSV files into 5MB chunks
        if (inputFile.type === 'text/csv') {
          let chunks = []
          papaparse.parse(inputFile, {
            header: true,
            worker: true,
            skipEmptyLines: 'greedy',
            chunkSize: 1024*5000,
            chunk: (chunk) => {
              chunks.push(chunk)
            },
            complete: () => {
              let files = []
              chunks.forEach((chunk, chunkIndex) => {
                // Take the first 5 rows of the first chunk of the first file as the file preview
                if (fileIndex === 0 && chunkIndex === 0) {
                  filePreview = chunk.data.slice(0, 5)
                }
                const csv = papaparse.unparse(chunk)
                const blob = new Blob([csv], { type: 'text/csv' })
                const fileObject = new File([blob], `${inputFile.name.replace(/\.[^/.]+$/, '')}-${chunkIndex + 1}.csv`)
                files.push(fileObject)
              })
              return callback(null, { inputFile, files })
            },
            error: (error) => {
              return callback(error)
            }
          })
        } else {
          callback(null, {inputFile, files: [inputFile]})
        }
      };
    })
    setIsSelectingFiles(true)
    async.parallel(tasks, (error, results) => {
      if (error) {
        setMessageSeverity('error')
        setMessage('Error reading file. Make sure it is a properly formatted CSV file.')
        setShowMessage(true)
      }
      else {
        let theseInputFiles = []
        let theseFiles = []
        results.forEach(result => {
          theseInputFiles.push(result.inputFile)
          theseFiles.push(...result.files)
        })
        setInputFiles(theseInputFiles)
        setFiles(theseFiles)
        setInputFilePreview(filePreview)
        setIsSelectingFiles(false)
      }
    })

    // Clear the file input value so files can be re-selected
    fileInputRef.current.value = null
  }

  const handleSelectFormat = (event) => {
    setFormatId(event.target.value)
  }

  const handleSelectFormatDataType = (event) => {
    setFormatDataType(event.target.value)
  }

  const handleUpdateDataMap = (group, field, target) => {
    let newDataMap = JSON.parse(JSON.stringify(dataMap))
    if (!(group in newDataMap)) {
      newDataMap[group] = {}
    }
    newDataMap[group][field] = target
    setDataMap(newDataMap)
  }

  const handleCloseFormatDialog = () => {
    setShowFormatDialog(false)
  }

  const handleFormatedCreatedSuccess = () => {
    refetchDataFormats()
    setShowFormatDialog(false)
  }

  const handleUpload = () => {
    if (files.length > 0 && !uploading) {
      setUploading(true)
      const tasks = files.map(file =>
        callback => {
          const user_id = user.user_id
          var dateString = new Date().toISOString()
          var filePath = `${user_id}/${formatId}/${integration ? integration.id : null}/${dateString}/${file.name}`
          return firebase.storage().ref(filePath).put(file).then(fileSnapshot => {
              callback(null, fileSnapshot)
          }).catch(function(error) {
              console.error('There was an error uploading a file to Cloud Storage:', error)
              callback(error)
          })
        }
      )
      async.parallel(tasks, (error) => {
        if (error) {
          setMessageSeverity('error')
          setMessage(UPLOAD_ERROR_MESSAGE)
        } else {
          setMessageSeverity('success')
          setMessage(UPLOAD_SUCCESS_MESSAGE)
        }
        setInputFiles([])
        setFiles([])
        setFormatId('')
        setIntegration(null)
        setUploading(false)
        setShowMessage(true)
        setActiveStep(0)
      })
    }
  }

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') return
    setShowMessage(false)
  }

  const { mutate: mutateEditMapping, isLoading: isLoadingEditMapping } = useMutation(
    formData => firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/dataMaps/${formData.id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          map: formData.map,
          name: formatName,
          acceptAllRows: formData.acceptAllRows,
          productPlatform,
          type: formatDataType
         })
      })
    }),
    {
      onSuccess: () => {
        refetchDataFormats()
        handleNext()
      }
    }
  )

  const deleteFormat = () => {
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/dataMaps/${formatId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      }).then(res => {
        setShowDeleteFormatConfirmation(false)
        setFormatId('')
        setMessage('Format deleted successfully')
        setShowMessage(true)
        refetchDataFormats()
        return res.json()
      })
    })
  }

  const handleSaveMapping = () => {
    mutateEditMapping({ id: formatId, map: dataMap, acceptAllRows })
  }

  const handleChangeAcceptAllRows = (event) => {
    setAcceptAllRows(event.target.checked)
  }

  const getSteps = () => {
    return ['Select File', 'Assign Format', 'Upload']
  }

  const steps = getSteps()

  let totalFields = 0
  let assignedFields = 0
  Object.keys(dataMap).forEach(key => {
    const subMap = dataMap[key]
    Object.keys(subMap).forEach(subKey => {
      totalFields += 1
      if (subMap[subKey]) {
        assignedFields += 1
      }
    })
  })

  return (
    <Container className={classes.root} maxWidth='md'>
      <Paper className={classes.paper}>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) =>
            <Step key={label} completed={activeStep > index}>
              {activeStep > index ? (
                <StepButton onClick={handleStep(index)}>
                  <StepLabel>{label}</StepLabel>
                </StepButton>
              ) : (
                <StepLabel>{label}</StepLabel>
              )}
            </Step>
          )}
        </Stepper>

        {activeStep === 0 && (
          <Box padding={2}>
            <Typography variant='h5' align='left' gutterBottom>
              Data Uploader
            </Typography>

            <Typography variant='body1' align='left' gutterBottom>
              Upload data files from your computer to LTV Numbers using the appropriate file configuration based on the format of the data, and the platform it originated from. You can select multiple files at once using CMD or CNTRL click.
            </Typography>

            <Typography variant='h6' gutterBottom>
              Select File
            </Typography>

            <input
              ref={fileInputRef}
              accept='.csv,.html'
              className={classes.fileInput}
              id='contained-button-file'
              type='file'
              multiple
              onChange={handleFilesSelected}
            />
            <label htmlFor='contained-button-file'>
              <Button variant='contained' component='span' disabled={isSelectingFiles}>
                {isSelectingFiles ?  <CircularProgress size={24} className={classes.buttonProgress} /> : 'Choose File'}
              </Button>
              {inputFiles.map(inputFile =>
                <Typography key={inputFile.name} variant='body2' display='block' className={classes.fileName}>
                  {inputFile.name} ({fileSize(inputFile.size)})
                </Typography>
              )}
            </label>

            <Box display='flex' marginTop={2} justifyContent='center'>
              <Button
                variant='contained'
                color='primary'
                onClick={handleNext}
                disabled={inputFiles.length === 0}
              >
                Next
              </Button>
            </Box>
          </Box>
        )}

        {activeStep === 1 && (
          <Box padding={2}>
            <Typography variant='h6' className={classes.headerTopMargin}>
              Choose Format
            </Typography>
            <Button
              color='secondary'
              variant='text'
              onClick={() => setShowFormatDialog(true)}
              startIcon={<AddIcon />}
            >
              New
            </Button>
            <Box marginTop={2}>
              <FormControl variant='outlined' className={classes.formControl} required>
                <InputLabel id='data-format-label'>Data Format</InputLabel>
                <Select
                  labelId='data-format-label'
                  id='data-format-select'
                  value={formatId}
                  onChange={handleSelectFormat}
                  label='Data Format'
                >
                  <ListSubheader>My Formats</ListSubheader>
                  {isLoadingFormats ? (
                    <MenuItem disabled>Loading...</MenuItem>
                  ) : (
                    formats.length === 0 ? (
                      <MenuItem disabled>No custom formats</MenuItem>
                    ) :
                      formats.map(format =>
                        <MenuItem key={format.id} value={format.id}>{format.name}</MenuItem>
                      )
                  )}
                  <ListSubheader>Built-in Formats</ListSubheader>
                  {builtInFormats.map(format =>
                    <MenuItem key={format.id} value={format.id}>{format.name}</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>

            {/* Only show for custom formats */}
            {isCustomFormat(formatId) && (
              <Box marginTop={2}>
                <Divider />
                <Box>
                  <Box marginTop={2} display='flex'>
                    <TextField
                      label='Name'
                      variant='outlined'
                      fullWidth
                      size='small'
                      value={formatName}
                      onChange={handleFormatNameChange}
                    />
                    <Button
                      className={classes.deleteButton}
                      onClick={() => setShowDeleteFormatConfirmation(true)}
                    >
                      Delete
                    </Button>
                    <Dialog
                      open={showDeleteFormatConfirmation}
                      onClose={() => setShowDeleteFormatConfirmation(false)}
                    >
                      <DialogTitle>
                        Delete Format <b>&quot;{formatName}&quot;</b>?
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          This will delete the custom format and all saved field mappings. This operation cannot be undone. Keep in mind that if you intend to use a file with the same format again, having a consistent format is important to ensure data consistency.
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          className={classes.redButton}
                          variant='text'
                          onClick={() => setShowDeleteFormatConfirmation(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          color='primary'
                          onClick={() => deleteFormat()}
                        >
                          Remove
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Box>
                  <Box marginTop={2}>
                    <FormControl variant='outlined' size='small' disabled>
                      <InputLabel id='data-type-label'>Data Type</InputLabel>
                      <Select
                        labelId='data-type-label'
                        id='data-type-select'
                        label='Data Type'
                        variant='outlined'
                        value={formatDataType}
                        onChange={handleSelectFormatDataType}
                      >
                        <MenuItem value='payment'>Payment</MenuItem>
                        <MenuItem value='contact'>Contact</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box marginTop={2}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={acceptAllRows}
                          onChange={handleChangeAcceptAllRows}
                          name='acceptAllRows'
                          color='primary'
                        />
                      }
                      label='Accept all rows and do not remove potential duplicates'
                    />
                  </Box>
                </Box>
                <Box marginTop={2}>
                  <Typography variant='h6' gutterBottom>
                    Assign LTV Numbers fields to fields in your spreadsheet data
                  </Typography>
                </Box>
                {formatDataType && ltvFields[formatDataType].map(field =>
                  <Box
                    key={`${field.group}-${field.id}`}
                    marginTop={2}
                  >
                    <FieldMapper
                      id={field.id}
                      name={field.name}
                      group={field.group}
                      description={field.description}
                      dataType={field.dataType}
                      examples={field.examples}
                      importance={field.importance}
                      inputFilePreview={inputFilePreview}
                      target={dataMap[field.group]?.[field.id]}
                      onSelectTarget={handleUpdateDataMap}
                    />
                  </Box>
                )}
                {formatDataType === 'payment' &&
                  <Box padding={2}>
                    <Typography variant='h5' gutterBottom>
                      Advanced
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                      If you would like to classify your product and variant IDs as coming from a particular platform so that they can be gropued together
                      with other products from that same platform to consolidate your analysis, you can do so here by selecting a platform below.
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                      For example, if you have a Shopify product with ID <em>12345</em> and you have sales for that same product in your data file,
                      and you would like to combine the data from both sources, you can select <b>Shopify</b> below and the product will be grouped together.
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                      Unless you understand very clearly what you are doing, we recommend leaving this option blank, and the products from this file will be
                      treated as a unique set of products.
                    </Typography>

                    <Box marginTop={2}>
                      <FormControl size='small' sx={{ minWidth: 400 }}>
                        <InputLabel id='product-platform-select-label'>
                          Assign Platform for Product and Variant IDs
                        </InputLabel>
                        <Select
                          labelId='product-platform-select-label'
                          label='Assign Platform for Product and Variant IDs'
                          id='product-platform-select'
                          value={productPlatform}
                          onChange={(event) => setProductPlatform(event.target.value)}
                        >
                          <MenuItem value='other'><em>None</em></MenuItem>
                          {platformValues.map(platform =>
                            <MenuItem key={platform} value={platform}>{platform}</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                }
              </Box>
            )}

            <Box padding={2} display='flex' justifyContent='center'>
              <Button
                onClick={handleBack}
              >
                Back
              </Button>
              <Button
                variant='contained'
                color='primary'
                onClick={isCustomFormat(formatId) ? handleSaveMapping : handleNext}
                disabled={!formatId}
              >
                {isCustomFormat(formatId) ? (
                  isLoadingEditMapping ?  <CircularProgress size={24} className={classes.buttonProgress} /> : 'Save and Next'
                ) : 'Next'}
              </Button>
            </Box>

            <CreateFormatDialog
              open={showFormatDialog}
              onClose={handleCloseFormatDialog}
              onSuccess={handleFormatedCreatedSuccess}
              emptyDataMapContact={emptyDataMapContact}
              emptyDataMapPayment={emptyDataMapPayment}
            />
          </Box>
        )}

        {activeStep === 2 && (
          <Box padding={2}>
            <Typography variant='h5' align='left' gutterBottom>
              Summary
            </Typography>
            <Typography variant='h6' align='left' gutterBottom>
              Files
            </Typography>
            {inputFiles.map(inputFile =>
              <Typography key={inputFile.name} variant='body2' display='block' className={classes.fileName}>
                {inputFile.name} ({fileSize(inputFile.size)})
              </Typography>
            )}
            {isBuiltInFormat(formatId) && (
              <Typography variant='body1' gutterBottom>
                File(s) ready for upload with selected format <b>{builtInFormats.find(f => f.id === formatId).name}</b>. If you are ready to proceed, click below to upload the file for analysis. If you would like to make changes, go back to the previous step.
              </Typography>
            )}
            {isCustomFormat(formatId) && (
              <Typography variant='body1' gutterBottom>
                Assigned <b>{assignedFields}</b> out of <b>{totalFields}</b> from your file to the LTV Numbers data map. Please remember that for the analysis, you should assign as many fields as possible. If you are ready to proceed, click below to upload the file for analysis. If you would like to modify your mapping, go back to the previous step and review.
              </Typography>
            )}
            <Typography variant='h6' gutterBottom>
              Assign data to an integration (recommended)
            </Typography>
            <Typography variant='body1' gutterBottom>
              If you would like to assign this data to an existing integration, select one from the dropdown. This is recommended to properly group together data sources, especially when using an upload to bring in historical data.
            </Typography>
            <Box marginTop={2} display='flex' alignItems='center'>
              <FormControl
                variant='outlined'
                margin='dense'
                sx={{ minWidth: 300 }}
                required
              >
                <Autocomplete
                  id='integration-select'
                  options={integrations.sort((a, b) => {
                    if (a.platform === b.platform) {
                      return -b.nickname.localeCompare(a.nickname)
                    }
                    return -b.platform.localeCompare(a.platform)
                  })}
                  getOptionLabel={(option) => `${option.nickname} (${option.platform})`}
                  renderOption={(props, option) => (
                    <Box {...props}>
                      {option.nickname}
                    </Box>
                  )}
                  groupBy={options => options.platform}
                  value={integration}
                  isOptionEqualToValue={(option) => option.id === integration.id}
                  onChange={(_, newValue) => setIntegration(newValue)}
                  renderInput={(params) => <TextField {...params} label='Integration to assign' />}
                  loading={isLoadingIntegrations}
                />
              </FormControl>
            </Box>

            <Box padding={2} display='flex' justifyContent='center'>
              <Button
                onClick={handleBack}
              >
                Back
              </Button>
              <Button
                className={classes.uploadButton}
                variant='contained'
                color='primary'
                onClick={handleUpload}
                disabled={files.length === 0 || formatId.length === 0 || uploading}
              >
                {uploading ?  <CircularProgress size={24} className={classes.buttonProgress} /> : 'Upload'}
              </Button>
            </Box>
          </Box>
        )}
      </Paper>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showMessage}
        autoHideDuration={10000}
        onClose={handleCloseMessage}
      >
        <Alert
          severity={messageSeverity}
          variant='filled'
          onClose={handleCloseMessage}
        >
          {message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default Upload