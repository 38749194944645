import React from 'react'
import PropTypes from 'prop-types'
import Collapse from '@mui/material/Collapse'
import Paper from '@mui/material/Paper'
import makeStyles from '@mui/styles/makeStyles'

import ControlsConfiguration from '../ControlsConfiguration'
import ControlsContext from '../ControlsContext'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))

const Controls = (props) => {
  const classes = useStyles()
  const showContext = props.filters.length > 0 || !!props.breakdown
  return (
    <Paper className={classes.root}>
      <ControlsConfiguration
        startDate={props.startDate}
        endDate={props.endDate}
        onDatesChange={props.onDatesChange}
        onBreakdownSelect={props.onBreakdownSelect}
        filters={props.filters}
        onFilterAdd={props.onFilterAdd}
        attribution={props.attribution}
        onAttributionSelect={props.onAttributionSelect}
        includeCosts={props.includeCosts}
        onIncludeCostsChange={props.onIncludeCostsChange}
        includeCommission={props.includeCommission}
        onIncludeCommissionChange={props.onIncludeCommissionChange}
      />
      <Collapse in={showContext}>
        <ControlsContext
          breakdown={props.breakdown}
          onBreakdownDelete={props.onBreakdownDelete}
          filters={props.filters}
          onFilterDelete={props.onFilterDelete}
          onFilterDeleteAll={props.onFilterDeleteAll}
        />
      </Collapse>
    </Paper>
  )
}

Controls.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onDatesChange: PropTypes.func.isRequired,
  breakdown: PropTypes.object,
  onBreakdownSelect: PropTypes.func.isRequired,
  onBreakdownDelete: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired,
  onFilterAdd: PropTypes.func.isRequired,
  onFilterDelete: PropTypes.func.isRequired,
  onFilterDeleteAll: PropTypes.func.isRequired,
  attribution: PropTypes.string.isRequired,
  onAttributionSelect: PropTypes.func.isRequired,
  includeCosts: PropTypes.bool.isRequired,
  onIncludeCostsChange: PropTypes.func.isRequired,
  includeCommission: PropTypes.bool.isRequired,
  onIncludeCommissionChange: PropTypes.func.isRequired,
}

export default Controls
