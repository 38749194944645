import React from 'react'
import Alert from '@mui/material/Alert'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Container from '@mui/material/Container'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import Snackbar from '@mui/material/Snackbar'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import makeStyles from '@mui/styles/makeStyles'
import { useTheme } from '@mui/material/styles'
import moment from 'moment-timezone'

import { ColorThemeContext } from '../../contexts/ColorThemeContext'
import { UserContext } from '../../contexts/UserContext'
import { FirebaseContext } from '../../utils/firebase'
import 'firebase/auth'
import 'firebase/storage'

import { updateDocumentWithoutUidFilter } from '../../utils/firestore'
import { FIRESTORE_COLLECTIONS } from '../../constants'

import { CURRENCY_ITEMS, PUBLIC_DEMO_UID } from '../../constants'

import ColorPicker from '../Reports/ColorPicker'

const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: 66,
    right: 0,
    width: 'calc(100% - 66px)',
    height: '100vh',
    overflowY: 'auto',
    paddingTop: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
  },
  paper: {
    padding: theme.spacing(2),
  },
}))

const Profile = () => {
  const classes = useStyles()
  const theme = useTheme()
  const { setPrimary, setSecondary } = React.useContext(ColorThemeContext)
  const firebase = React.useContext(FirebaseContext)
  const userDoc = React.useContext(UserContext)
  const currentUser = firebase.auth().currentUser

  const [email, setEmail] = React.useState(currentUser.email)
  const [loadingEmailChange, setLoadingEmailChange] = React.useState(false)
  const [emailErrorMessage, setEmailErrorMessage] = React.useState('')

  const [newPassword, setNewPassword] = React.useState('')
  const [newPasswordConfirm, setNewPasswordConfirm] = React.useState('')
  const [loadingPasswordChange, setLoadingPasswordChange] = React.useState(false)

  const [timezone, setTimezone] = React.useState(userDoc ? userDoc.timezone : TIMEZONE)

  const [showMessage, setShowMessage] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const [messageSeverity, setMessageSeverity] = React.useState('success')

  const [showReauthDialog, setShowReauthDialog] = React.useState(false)
  const [passwordReauth, setPasswordReauth] = React.useState('')

  const [isUploadingLogo, setIsUploadingLogo] = React.useState(false)

  const uid = firebase.auth().currentUser ? firebase.auth().currentUser.uid : null
  const isPublicDemo = uid === PUBLIC_DEMO_UID
  if (isPublicDemo) throw new Error('UNAUTHORIZED')

  const updateEmail = () => {
    setLoadingEmailChange(true)
    currentUser.updateEmail(email).then(() => {
      setLoadingEmailChange(false)
      setEmailErrorMessage('')
      setShowMessage(true)
      setMessage('Email changed successfully')
      setMessageSeverity('success')
    }).catch(error => {
      switch(error.code) {
        case 'auth/invalid-email':
        case 'auth/email-already-in-use':
          setEmailErrorMessage(error.message)
          break
        case 'auth/requires-recent-login':
          setShowMessage(true)
          setMessage('Please re-enter your password before proceeding.')
          setMessageSeverity('error')
          setShowReauthDialog(true)
          break
        default:
          break
      }
      setLoadingEmailChange(false)
    })
  }

  const updatePassword = () => {
    setLoadingPasswordChange(true)
    currentUser.updatePassword(newPassword).then(() => {
      setLoadingPasswordChange(false)
      setShowMessage(true)
      setMessage('Password changed successfully')
      setMessageSeverity('success')
      setNewPassword('')
      setNewPasswordConfirm('')
    }).catch(error => {
      switch(error.code) {
        case 'auth/weak-password':
          setShowMessage(true)
          setMessage(`Error changing password: ${error.message}`)
          setMessageSeverity('error')
          break
        case 'auth/requires-recent-login':
          setShowMessage(true)
          setMessage('Please re-enter your password before proceeding.')
          setMessageSeverity('error')
          setShowReauthDialog(true)
          break
        default:
          break
      }
      setLoadingPasswordChange(false)
    })
  }

  const reauth = () => {
    const credential = firebase.auth.EmailAuthProvider.credential(
      currentUser.email,
      passwordReauth
    )
    currentUser.reauthenticateWithCredential(credential).then(() => {
      setShowMessage(true)
      setMessage(`Authentication successful. Proceed with your operation.`)
      setMessageSeverity('success')
      handleCloseReauthDialog()
    }).catch(error => {
      setShowMessage(true)
      setMessage(`Error reauthenticating: ${error.message}`)
      setMessageSeverity('error')
    })
  }

  const handleCloseReauthDialog = () => {
    setShowReauthDialog(false)
    setPasswordReauth('')
  }

  const handleCloseMessage = (_, reason) => {
    if (reason === 'clickaway') return
    setShowMessage(false)
  }

  const handleTimezoneChange = async (timezone) => {
    if (!timezone) return
    setTimezone(timezone)
    await updateDocumentWithoutUidFilter(firebase, FIRESTORE_COLLECTIONS.USERS, currentUser.uid, { timezone })
  }

  const handlePrimaryColorChange = async (newColor) => {
    setPrimary(newColor)
    await updateDocumentWithoutUidFilter(firebase, FIRESTORE_COLLECTIONS.USERS, currentUser.uid, { primaryColor: newColor })
  }

  const handleSecondaryColorChange = async (newColor) => {
    setSecondary(newColor)
    await updateDocumentWithoutUidFilter(firebase, FIRESTORE_COLLECTIONS.USERS, currentUser.uid, { secondaryColor: newColor })
  }

  const handleLogoUpload = async (event) => {
    const file = event.target.files[0]
    if (!file) return

    // Validate that the file is an image and is square
    const isImage = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isImage) {
      alert('File must be a JPEG or PNG image')
      return
    }

    const image = new Image()
    image.src = URL.createObjectURL(file)
    image.onload = async () => {
      if (image.width !== image.height) {
        alert('Image must be square')
        return
      }

      // Resize the image
      const maxSize = 100 // replace with the maximum size you want
      const canvas = document.createElement('canvas')
      if (image.width > maxSize) {
        const scaleSize = maxSize / image.width
        canvas.width = maxSize
        canvas.height = image.height * scaleSize
      } else {
        canvas.width = image.width
        canvas.height = image.height
      }
      const ctx = canvas.getContext('2d')
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height)
      const resizedImage = await new Promise((resolve) => canvas.toBlob(resolve, file.type))

      // Upload the file to Firebase
      setIsUploadingLogo(true)
      const fileRef = firebase.storage().ref(`${userDoc.user_id}/logos/${file.name}`)
      await fileRef.put(resizedImage)
      setIsUploadingLogo(false)

      // Get the download URL and save it to the user's profile
      const url = await fileRef.getDownloadURL()
      await updateDocumentWithoutUidFilter(firebase, FIRESTORE_COLLECTIONS.USERS, currentUser.uid, { logoUrl: url })
    }
  }

  const handleLogoRemove = async () => {
    await updateDocumentWithoutUidFilter(firebase, FIRESTORE_COLLECTIONS.USERS, currentUser.uid, { logoUrl: null })
  }

  const handleDisplayCurrencyChange = async (newCurrency) => {
    await updateDocumentWithoutUidFilter(firebase, FIRESTORE_COLLECTIONS.USERS, currentUser.uid, { currency: newCurrency })
  }

  return (
    <Container className={classes.root} maxWidth='md'>
      <Box>
        <Box>
          <Paper className={classes.paper}>
            <Typography variant='h5' align='center' gutterBottom>
              Profile
            </Typography>

            <Box>
              <Typography variant='h6' gutterBottom>
                Email
              </Typography>

              <Box marginTop={2}>
                <TextField
                  label='Email'
                  name='email'
                  value={email}
                  type='email'
                  onChange={(event) => setEmail(event.target.value)}
                  variant='outlined'
                  size='small'
                  error={!!emailErrorMessage}
                  helperText={emailErrorMessage}
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2}>
                <Button
                  variant='contained'
                  size='small'
                  color='secondary'
                  onClick={updateEmail}
                >
                  {loadingEmailChange ? <CircularProgress color='inherit' size={24} /> : 'Change Email'}
                </Button>
              </Box>

            </Box>

            <Box marginTop={2}>
              <Typography variant='h6' gutterBottom>
                Change Password
              </Typography>

              <Box marginTop={2}>
                <TextField
                  label='New Password'
                  name='new-password'
                  value={newPassword}
                  type='password'
                  onChange={(event) => setNewPassword(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2}>
                <TextField
                  label='Confirm Password'
                  name='confirm-password'
                  value={newPasswordConfirm}
                  type='password'
                  onChange={(event) => setNewPasswordConfirm(event.target.value)}
                  variant='outlined'
                  size='small'
                  error={newPasswordConfirm !== newPassword}
                  helperText={newPasswordConfirm !== newPassword ? 'Passwords do not match' : ''}
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2}>
                <Button
                  variant='contained'
                  size='small'
                  color='secondary'
                  onClick={updatePassword}
                >
                  {loadingPasswordChange ? <CircularProgress color='inherit' size={24} /> : 'Change Password'}
                </Button>
              </Box>
            </Box>

            {/* Currency symbol */}
            <Box marginTop={2}>
              <Typography variant='h6' gutterBottom>
                Currency
              </Typography>

              <Typography variant='body2' paragraph>
                Choose the default currency symbol to use in reports. Note that this is a display-only setting,
                as we do not currently support currency conversion. All amounts shown will match the data sources
                they were pulled from, and this setting will only affect the display of the currency symbol on those amounts.
              </Typography>

              <Typography variant='body2' paragraph>
                Therefore it is possible for you to choose a currency symbol that does not match the currency of the data source.
                So please be sure the symbol you are choosing matches the currency of the data sources you are using to avoid confusion.
              </Typography>

              <Typography variant='body2' paragraph>
                Note that $ (US Dollar) is the default currency symbol.
              </Typography>

              <FormControl
                variant='outlined'
                size='small'
                margin='dense'
                sx={{ minWidth: 300 }}
                required
              >
                <InputLabel id='currencySymbolLabel'>Display Currency</InputLabel>
                <Select
                  name='currency'
                  label='Display Currency'
                  value={userDoc.currency || 'usd'}
                  onChange={event => handleDisplayCurrencyChange(event.target.value)}
                  fullWidth
                >
                  {CURRENCY_ITEMS.map(item => (
                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {/* Default timezone */}
            <Box marginTop={2}>
              <Typography variant='h6' gutterBottom>
                Timezone (coming soon)
              </Typography>
              <Typography variant='body2' color='textSecondary'>
                This timezone will be used in the Date Picker by default. Note that you can temporarily
                change the timezone within the Date Picker while analyzing data without affecting this default value.
              </Typography>

              <FormControl
                variant='outlined'
                sx={{ marginTop: 2, minWidth: 300 }}
                required
                >
                <Autocomplete
                  id='timezone'
                  size='small'
                  options={moment.tz.names()}
                  value={timezone}
                  onChange={event => handleTimezoneChange(event.target.textContent)}
                  renderInput={(params) => <TextField {...params} label='Timezone' />}
                  disabled
                />
              </FormControl>
            </Box>

          </Paper>
        </Box>

        <Box marginTop={2}>
          <Paper className={classes.paper}>
            <Typography variant='h5' align='center' gutterBottom>
              Theme
            </Typography>

            {/* Logo */}
            <Box>
              <Typography variant='h6' gutterBottom>
                Logo
              </Typography>

              {userDoc.logoUrl ? (
                <Box>
                  <img
                    src={userDoc.logoUrl}
                    alt='Custom Logo'
                    style={{
                      width: 100,
                      height: 100
                    }}
                  />
                </Box>
              ) : (
                <Typography variant='body1' gutterBottom>
                  No custom logo uploaded
                </Typography>
              )}

              <Box display='flex' flexDirection='row' marginTop={1}>
                <Button
                  component='label'
                  role={undefined}
                  variant='contained'
                  tabIndex={-1}
                  size='small'
                  color='secondary'
                  startIcon={<CloudUploadIcon />}
                >
                  {userDoc.logoUrl ? 'Replace Logo' : 'Upload Logo'}
                  <input type='file' hidden onChange={handleLogoUpload} />
                  {isUploadingLogo && <CircularProgress color='inherit' size={24} />}
                </Button>

                {userDoc.logoUrl && (
                  <Button
                    variant='text'
                    color='error'
                    onClick={handleLogoRemove}
                  >
                    Remove
                  </Button>
                )}
              </Box>
            </Box>

            {/* Primary color */}
            <Box marginTop={2}>
              <Typography variant='h6' gutterBottom>
                Primary Color
              </Typography>

              <Box display='flex' flexDirection='row' alignItems='center' columnGap={2}>
                <ColorPicker
                  type='mui'
                  color={userDoc.primaryColor || theme.palette.primary.main}
                  width={24}
                  height={24}
                  showContrastText={true}
                  onColorChange={handlePrimaryColorChange}
                />

                {userDoc.primaryColor && (
                  <Button
                    variant='text'
                    size='small'
                    onClick={() => handlePrimaryColorChange(null)}
                    sx={{ height: 24 }}
                  >
                    Reset to Default
                  </Button>
                )}
              </Box>
            </Box>

            {/* Accent color */}
            <Box marginTop={2}>
              <Typography variant='h6' gutterBottom>
                Accent Color
              </Typography>

              <Box display='flex' flexDirection='row' alignItems='center' columnGap={2}>
                <ColorPicker
                  type='mui'
                  color={userDoc.secondaryColor || theme.palette.secondary.main}
                  width={24}
                  height={24}
                  showContrastText={true}
                  onColorChange={handleSecondaryColorChange}
                />

                {userDoc.secondaryColor && (
                  <Button
                    variant='text'
                    size='small'
                    onClick={() => handleSecondaryColorChange(null)}
                    sx={{ height: 24 }}
                  >
                    Reset to Default
                  </Button>
                )}
              </Box>
            </Box>
          </Paper>
        </Box>

        <Box marginTop={2}>
          <Paper className={classes.paper}>
            <Typography variant='h5' align='center' gutterBottom>
              Billing
            </Typography>

            <Box>
              <Typography variant='body1' gutterBottom>
                Click below to manage your payment method in the Stripe Customer Portal.
                In the portal, be sure to use the email address that is used for billing, which may be different from the email address that you use to login to LTV Numbers.
                If you have questions or run into any issues, please contact support@ltvnumbers.com.
              </Typography>

              <Box marginTop={2}>
                <Button
                  variant='contained'
                  size='small'
                  color='secondary'
                  href='https://billing.stripe.com/p/login/5kA7sIc2W7au7qE288'
                  target='_blank'
                  endIcon={<OpenInNewIcon />}
                >
                  Manage Billing with Stripe
                </Button>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>

      <Dialog
        open={showReauthDialog}
        onClose={handleCloseReauthDialog}
      >
        <DialogTitle>
          Please re-enter your current password to continue
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Temp
          </DialogContentText>
          <Box marginTop={2}>
            <TextField
              label='Current Password'
              name='password-reauth'
              value={passwordReauth}
              type='password'
              onChange={(event) => setPasswordReauth(event.target.value)}
              variant='outlined'
              size='small'
              fullWidth
              required
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReauthDialog}>
            Cancel
          </Button>
          <Button onClick={() => reauth()} color='secondary'>
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showMessage}
        autoHideDuration={5000}
        onClose={handleCloseMessage}
      >
        <Alert
          severity={messageSeverity}
          variant='filled'
          onClose={handleCloseMessage}
        >
          {message}
        </Alert>
      </Snackbar>
    </Container>
  )
}

export default Profile