import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '@mui/material/SvgIcon'
import { useTheme } from '@mui/styles'

const SquareIconColored = ({
  color,
  width,
  height,
  showContrastText,
}) => {
  const theme = useTheme()
  const contrastTextColor = theme.palette.getContrastText(color)
  const fontSize = width * 0.7

  return (
    <SvgIcon
      sx={{
        color: color,
        width: width,
        height: height,
        borderRadius: theme => theme.shape.borderRadius / 8,
      }}
    >
      <rect width='100%' height='100%' />
      {showContrastText && (
        <text
          x='50%'
          y='50%'
          dy='0.1em'
          dominantBaseline='middle'
          textAnchor='middle'
          fill={contrastTextColor}
          fontSize={fontSize}
          fontWeight='bold'
        >
          A
        </text>
      )}
    </SvgIcon>
  )
}

SquareIconColored.propTypes = {
  color: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  showContrastText: PropTypes.bool,
}

export default SquareIconColored