import React from 'react'
import PropTypes from 'prop-types'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Drawer from '@mui/material/Drawer'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import makeStyles from '@mui/styles/makeStyles'

import { FirebaseContext } from '../../../utils/firebase'
import 'firebase/auth'

import { useQuery } from 'react-query'

import { API_ROOT_URL } from '../../../constants'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
    marginLeft: theme.spacing(1),
  },
  box: {
    height: '100%',
    width: 400,
  },
  filterContent: {
    height: 'calc(100% - 64px)',
    padding: theme.spacing(2),
    overflowY: 'auto',
  },
  actionRow: {
    height: 64,
    width: '100%',
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
  actionButton: {
    margin: theme.spacing(0, 1),
  },
  filterAutocomplete: {
    display: 'block',
  },
  valueAutocomplete: {
    marginTop: theme.spacing(2),
    display: 'block',
  },
  buttonRow: {
    display: 'block',
  },
  cancelButton: {
    float: 'right',
    marginTop: theme.spacing(1),
    color: theme.palette.error.main,
    '&:hover': {
      background: 'rgba(255,23,68,0.04)',
    },
  },
  addButton: {
    float: 'right',
    marginTop: theme.spacing(1),
  },
}))

const filterItems = [
  {id: 'platform', name: 'Platform'},
  {id: 'integration_id', name: 'Data Source'},
  {id: 'funnel_id', name: 'Funnel'},
  {id: 'utm_source', name: 'utm_source'},
  {id: 'utm_medium', name: 'utm_medium'},
  {id: 'utm_campaign', name: 'utm_campaign'},
  {id: 'utm_content', name: 'utm_content'},
  {id: 'utm_term', name: 'utm_term'},
  {id: 'affiliate_id_1', name: 'Affiliate ID 1'},
  {id: 'affiliate_id_2', name: 'Affiliate ID 2'},
  {id: 'country', name: 'Country'},
]

const AddFilter = (props) => {
  const classes = useStyles()
  const firebase = React.useContext(FirebaseContext)
  const [open, setOpen] = React.useState(false)
  const [filter, setFilter] = React.useState(null)
  const [filterInput, setFilterInput] = React.useState('')
  const [value, setValue] = React.useState(null)
  const [valueInput, setValueInput] = React.useState('')
  const filterRef = React.useRef()
  const valueRef = React.useRef()

  const { isLoading, data: filterValues } = useQuery(['add-filter', filter], () => {
    if (filter === null) return []
    return firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/fields/${filter.id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then(res => res.json())
    })},
    {
      cacheTime: 15 * 60 * 1000,  // 15 minutes
      staleTime: 15 * 60 * 1000,  // 15 minutes
      refetchOnWindowFocus: false,
    }
  )

  React.useEffect(() => {
    if (filter) valueRef.current.focus()
  }, [filter])

  const handleSelectFilter = (filter) => {
    setFilter(filter)
    setValueInput('')
    setValue(null)
  }

  const handleSelectValue = (value) => {
    setValue(value)
  }

  const handleAddFilter = () => {
    const newFilter = {
      ...filter,
      value: value.id,
      valueName: value.name,
      comparison: '='
    }
    props.onFiltersAdd([newFilter])
    handleClose()
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setFilterInput('')
    setFilter(null)
    setValueInput('')
    setValue(null)
    setOpen(false)
  }

  return (
    <div className={classes.root}>
      <Button
        variant='outlined'
        color='primary'
        onClick={handleOpen}
        endIcon={<ArrowDropDownIcon color='primary' />}
      >
        More Filters
      </Button>
      <Drawer
        anchor='right'
        open={open}
        onClose={handleClose}
      >
        <Box className={classes.box}>
          <div className={classes.filterContent}>
            <Typography variant='h6' gutterBottom>
              More Filters
            </Typography>
            <Autocomplete
              className={classes.filterAutocomplete}
              id='filter-autocomplete'
              options={filterItems}
              getOptionLabel={option => option.name}
              value={filter}
              onChange={(_, option) => handleSelectFilter(option)}
              inputValue={filterInput}
              onInputChange={(_, value) => setFilterInput(value)}
              renderInput={(params) => <TextField {...params} inputRef={filterRef} label='Filter By' variant='outlined' />}
              noOptionsText='No matches'
              autoHighlight
              openOnFocus
              fullWidth
            />
            {Boolean(filter) && (
              <Autocomplete
                className={classes.valueAutocomplete}
                id='value-autocomplete'
                options={filterValues ? filterValues : []}
                getOptionLabel={option => `${option.name}${filter.id === 'funnel_id' || filter.id === 'integration_id' ? ` [${option.platform}, ID:${option.platform_id}]` : ''}`}
                filterOptions={createFilterOptions({
                  matchFrom: 'any',
                  stringify: (option) => `${option.name} [${option.platform_id}]`
                })}
                value={value}
                onChange={(_, option) => handleSelectValue(option)}
                inputValue={valueInput}
                onInputChange={(_, value) => setValueInput(value)}
                renderInput={(params) => (
                  <TextField {...params}
                    inputRef={valueRef}
                    label={filter ? 'Select ' + filter.name : ''}
                    variant='outlined'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isLoading ? <CircularProgress color='inherit' size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option.id} style={{ justifyContent: 'space-between' }}>
                    <Box display='flex' flexDirection='column'>
                      <Typography variant='body2' display='block'>
                        {option.name}
                      </Typography>

                      {(filter.id === 'funnel_id' || filter.id === 'integration_id') && (
                        <Typography variant='caption' display='block' color='textSecondary'>
                          {option.platform}, ID:{option.platform_id}
                        </Typography>
                      )}
                    </Box>
                  </li>
                )}
                loading={isLoading}
                noOptionsText='No matches'
                autoHighlight
                openOnFocus
                fullWidth
              />
            )}
          </div>
          <div className={classes.actionRow}>
            <Button
              className={classes.actionButton}
              variant='contained'
              color='primary'
              onClick={handleAddFilter}
              disabled={value === null}
            >
              Apply
            </Button>
            <Button className={classes.actionButton} variant='outlined' onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </Box>
      </Drawer>
    </div>
  );
}

AddFilter.propTypes = {
  onFiltersAdd: PropTypes.func.isRequired,
}

export default AddFilter
