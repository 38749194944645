import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: theme.spacing(2),
  },
  selectOverride: {
    height: 20,
    padding: '10px 32px 6px 12px',
  },
}))

const frontendTypeSelectItems = [
  {id: 'order', name: 'After initial funnel'},
  {id: 'payment', name: 'After initial product'},
]

const BackendTypeSelect = (props) => {
  const classes = useStyles()

  return (
    <FormControl className={classes.root} size='small' variant='outlined' disabled={!!props.disabled}>
      <InputLabel id='frontendType-label'>Backend Starts</InputLabel>
      <Select
        labelId='frontendType-label'
        id='frontendType-select'
        label='Backend Starts'
        value={props.frontendType}
        onChange={event => props.onBackendTypeSelect(event.target.value)}
        classes={{ select: classes.selectOverride }}
      >
        {frontendTypeSelectItems.map(item =>
          <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
        )}
      </Select>
    </FormControl>
  )
}

BackendTypeSelect.propTypes = {
  frontendType: PropTypes.string.isRequired,
  onBackendTypeSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

export default BackendTypeSelect
