import React from 'react'
import Alert from '@mui/material/Alert'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Snackbar from '@mui/material/Snackbar'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import makeStyles from '@mui/styles/makeStyles'
import { useHistory } from 'react-router-dom'
import moment from 'moment-timezone'

import { FirebaseContext } from '../../utils/firebase'
import 'firebase/auth'
import { useQuery } from 'react-query'

import ContactsTable from './ContactsTable'
import ControlsContacts from './Controls/ControlsContacts'

import { API_ROOT_URL } from '../../constants/'
import * as ROUTES from '../../constants/routes'

const ERROR_MESSAGE = 'Export failed to start. Please try again, and if the problem continues, contact support.'
const SUCCESS_MESSAGE = 'Export started successfully. Give it a few minutes, then check the inbox of the email address provided.'
const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: 66,
    right: 0,
    width: 'calc(100% - 66px)',
    height: '100vh',
    overflowY: 'auto',
    paddingTop: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
  },
  tabPaper: {
    marginBottom: theme.spacing(2),
  },
}))

const Contacts = () => {
  const classes = useStyles()
  const history = useHistory()
  const firebase = React.useContext(FirebaseContext)

  const fromState = history.action === 'PUSH' ? history.location.state || {} : {}
  let filtersInitial = fromState.filters || []
  let orderInitial = fromState.order || 'desc'
  let orderByInitial = fromState.orderBy || 'created_at'
  let pageInitial = fromState.page || 0
  let rowsPerPageInitial = fromState.rowsPerPage || 50
  let searchTextInitial = fromState.searchText || ''

  const [filters, setFilters] = React.useState(filtersInitial)
  const [order, setOrder] = React.useState(orderInitial)
  const [orderBy, setOrderBy] = React.useState(orderByInitial)
  const [page, setPage] = React.useState(pageInitial)
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageInitial)
  const [searchText, setSearchText] = React.useState(searchTextInitial)
  const [exportToEmail, setExportToEmail] = React.useState('')
  const [showMessage, setShowMessage] = React.useState(false)
  const [messageSeverity, setMessageSeverity] = React.useState('success')

  const { isLoading, isError, data = {} } = useQuery(['contacts-table', filters, order, orderBy, page, rowsPerPage, searchText], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/tables/contacts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          timezone: TIMEZONE,
          filters,
          order,
          orderBy,
          page,
          rowsPerPage,
          searchText: searchText.toLowerCase().trim()
        })
      }).then(res => res.json())
    }),
    {
      cacheTime: 15 * 60 * 1000,  // 15 minutes
      staleTime: 15 * 60 * 1000,  // 15 minutes
      refetchOnWindowFocus: false,
    }
  )

  const { isLoading: isExporting, refetch: requestExport } = useQuery(['contacts-table-export', exportToEmail], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/tables/contacts/export`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          timezone: TIMEZONE,
          filters,
          order,
          orderBy,
          searchText: searchText.toLowerCase().trim(),
          toEmail: exportToEmail
        })
      }).then(res => res.json())
    }),
    {
      enabled: false,
      cacheTime: 0,
      staleTime: 0,
      refetchOnWindowFocus: false,
      onSettled: () => {
        setExportToEmail('')
      },
      onSuccess: () => {
        setShowMessage(true)
        setMessageSeverity('success')
      },
      onError: () => {
        setShowMessage(true)
        setMessageSeverity('error')
      }
    }
  )

  // If current page is out of range on load, go to first page of results
  React.useEffect(() => {
    if (data && page * rowsPerPage > data.rowCount) setPage(0)
  }, [data, page, rowsPerPage])

  // Manually trigger export request if an "exportToEmail" has been entered
  React.useEffect(() => {
    if (exportToEmail.length > 0) {
      requestExport()
    }
  }, [exportToEmail, requestExport])

  const handleChangeTab = (_, newTab) => {
    if (newTab === 0) {
      history.push(ROUTES.CUSTOMERS)
    } else if (newTab === 1) {
      history.push(ROUTES.PAYMENTS)
    }
  }

  const handleFiltersAdd = (addedFilters) => {
    let newFilters = [...filters]
    addedFilters.forEach(filter => {
      const index = newFilters.findIndex(fi => fi.id === filter.id && fi.comparison === filter.comparison)
      if (index > -1) newFilters[index] = filter
      else newFilters = [filter, ...newFilters]
    })
    setFilters(newFilters)
    setPage(0)
  }

  const handleFilterDelete = (filter) => {
    const newFilters = filters.filter(fi => fi.id !== filter.id)
    setFilters(newFilters)
    setPage(0)
  }

  const handleFilterDeleteAll = () => {
    setFilters([])
    setPage(0)
  }

  const handleChangeSearchText = (text) => {
    setSearchText(text)
  }

  const handleExport = (email) => {
    setExportToEmail(email)
  }

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') return
    setShowMessage(false)
  }

  const rows = data.rows || []
  const rowCount = data.rowCount || 0

  return (
    <Container className={classes.root} maxWidth='xl'>
      <Paper className={classes.tabPaper}>
        <Tabs
          value={2}
          onChange={handleChangeTab}
          variant='standard'
          indicatorColor='primary'
          textColor='primary'
          aria-label='people nav tabs'
        >
          <Tab label='Customers' aria-label='customers' />
          <Tab label='Payments' aria-label='payments' />
          <Tab label='Contacts' aria-label='contacts' />
        </Tabs>
      </Paper>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ControlsContacts
            filters={filters}
            onFiltersAdd={handleFiltersAdd}
            onFilterDelete={handleFilterDelete}
            onFilterDeleteAll={handleFilterDeleteAll}
            searchText={searchText}
            onSearchTextChange={handleChangeSearchText}
            onExport={handleExport}
            rowCount={rowCount}
          />
        </Grid>
        <Grid item xs={12}>
          <ContactsTable
            filters={filters}
            order={order}
            orderBy={orderBy}
            page={page}
            rowsPerPage={rowsPerPage}
            searchText={searchText}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            isLoading={isLoading || isExporting}
            isError={isError}
            rows={rows}
            rowCount={rowCount}
          />
        </Grid>
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showMessage}
        autoHideDuration={10000}
        onClose={handleCloseMessage}
      >
        <Alert
          severity={messageSeverity}
          variant='filled'
          onClose={handleCloseMessage}
        >
          {messageSeverity === 'success' ? SUCCESS_MESSAGE : ERROR_MESSAGE}
        </Alert>
      </Snackbar>
    </Container>
  )
}

Contacts.propTypes = {}

export default Contacts
