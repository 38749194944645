import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Tooltip from '@mui/material/Tooltip'

const CommissionSwitch = (props) => {
  return (
    <FormControl margin='dense' size='small'>
      <Tooltip
        title='Count affiliate commission as revenue by adding it to your earnings for LTV calculations. This only affects the LTV graph and table stats related to LTV and LTR. Supports ClickBank, Digistore, and Buygoods. Your data must be reloaded to enable this. Contact support if you are interested.'
        enterDelay={0}
      >
        <FormControlLabel
          control={
            <Switch
              checked={props.includeCosts}
              onChange={() => props.onIncludeCommissionChange(!props.includeCommission)}
              name='includeCommissionSwitch'
              color='primary'
            />
          }
          label='Include Commission in LTV'
        />
      </Tooltip>
    </FormControl>
  )
}

CommissionSwitch.propTypes = {
  includeCommission: PropTypes.bool.isRequired,
  onIncludeCommissionChange: PropTypes.func.isRequired,
}

export default CommissionSwitch
