import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import moment from 'moment-timezone'
import { useQueries } from 'react-query'

import { FirebaseContext } from '../../utils/firebase'
import { API_ROOT_URL } from '../../constants'

import Controls from './ControlsScorecards'
import { getScorecardData } from '../../utils/helpers'
import ScorecardBuilder from './ScorecardBuilder'

const TIMEZONE = moment.tz.guess() // This should be set by user configuration
const QUERY_CONFIG = {
  cacheTime: 10 * 60 * 1000,  // 10 minutes
  staleTime: 5 * 60 * 1000,  // 5 minutes
  refetchOnWindowFocus: false,
}

const Scorecard = ({
  scorecard,
  savedSegments,
  savedGroups,
  liveMode,
  startDate,
  endDate,
  resolution,
  config,
  cards,
  segments,
  onScorecardSave,
  onScorecardDelete,
  onLiveModeChange,
  onDatesChange,
  onAdjustDateRangeToCompletePeriods,
  onResolutionChange,
  onConfigChange,
  onCardAdd,
  onCardUpdate,
  onCardDelete,
  onCardReorder,
  onSegmentAdd,
  onSegmentDelete,
  onSegmentDuplicate,
  onSegmentEditChange,
  onSegmentSaveEdit,
  onSegmentRevertEdit,
  onSegmentColorChange,
  onSegmentFilterAdd,
  onSegmentReplaceFiltersWithSavedSegmentFilter,
  onSegmentFilterDelete,
  onSegmentsReorder,
  onFilterAdd,
  onFilterUpdate,
  onFilterDelete,
  onClearAllSegmentFilters,
  onSegmentBreakdownSelect,
  onSegmentBreakdownColorChange,
}) => {
  const firebase = useContext(FirebaseContext)

  const segmentsData = useQueries(segments.map(segment => ({
    queryKey: [
      'segment',
      segment.id,
      segment.segmentFilters,
      segment.filters,
      scorecard.xKey,
      startDate,
      endDate,
      TIMEZONE,
      resolution,
      config,
      cards.map(card => card.metricKey).sort(),
    ],
    queryFn: () => fetchSegmentData(segment),
    enabled: cards.length > 0,
    refetchInterval: liveMode ? 1 * 60 * 1000 : false,  // 1 minute if liveMode is enabled, otherwise no automatic refetch
    ...QUERY_CONFIG
  })))

  const segmentsBreakdownData = useQueries(segments.map(segment => ({
    queryKey: [
      'breakdown',
      segment.id,
      segment.segmentFilters,
      segment.filters,
      segment.breakdown,
      scorecard.xKey,
      startDate,
      endDate,
      TIMEZONE,
      resolution,
      config,
      cards.map(card => card.metricKey).sort(),
    ],
    queryFn: () => fetchSegmentData(segment, true),
    enabled: cards.length > 0,
    refetchInterval: liveMode ? 1 * 60 * 1000 : false,  // 1 minute if liveMode is enabled, otherwise no automatic refetch
    ...QUERY_CONFIG
  })))

  // This stores the set of breakdown keys present in the API breakdown data for each segment
  // for use in the UI to determine which breakdown keys are eligible for display
  const segmentsEligibleBreakdownKeys = useMemo(() => {
    return segmentsBreakdownData.map(query => query.data ? query.data.breakdownKeys : [])
  }, [segmentsBreakdownData])

  const scorecardData = useMemo(() => {
    const segmentIsLoading = segmentsData.some(segmentData => segmentData.isLoading)
    if (segmentIsLoading) return null

    return getScorecardData(segments, segmentsData, cards, scorecard.xKey, scorecard.emptyDataRule)
  }, [scorecard, segments, segmentsData, cards])

  const aliasesData = useQueries(segments.map(segment => ({
    queryKey: [
      'aliases',
      segment.breakdown?.field
    ],
    queryFn: () => fetchAliases(segment.breakdown.field),
    enabled: Boolean(segment.breakdown),
    cacheTime: 10 * 60 * 1000,  // 10 minutes
    staleTime: 10 * 60 * 1000,  // 10 minutes
    refetchOnWindowFocus: false,
    refetchInterval: 10 * 60 * 1000,  // 10 minutes
  })))

  const aliases = useMemo(() => {
    if (aliasesData.length === 0) return {}
    if (aliasesData.every(query => !query.data)) return {}
    let aliases = {}
    aliasesData.forEach(query => {
      if (query.data) {
        aliases[query.data.field] = query.data.aliases
      }
    })
    return aliases
  }, [aliasesData])

  const fetchSegmentData = (segment, includeBreakdown=false) => {
    if (includeBreakdown && !segment.breakdown) return null
    return firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api2/metrics`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          metricKeys: cards.map(card => card.metricKey),
          xKey: scorecard.xKey,
          segmentId: segment.id,
          segmentFilters: segment.segmentFilters,
          filters: segment.filters,
          breakdown: includeBreakdown ? segment.breakdown : null,
          startDate,
          endDate,
          timezone: TIMEZONE,
          resolution,
          config,
        })
      }).then(res => res.json())
    })
  }

  const fetchAliases = async (field) => {
    if (!field) return null
    return firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api2/fields/${field}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then(res => res.json())
    })
  }

  return (
    <Box
      flexGrow={1}
      minWidth='calc(100% - 66px - 266px)'
      sx={{
        backgroundColor: '#f1f5f9',
        padding: theme => theme.spacing(2, 4),
        height: '100vh',
        overflowY: 'auto',
      }}
    >
      <Controls
        scorecard={scorecard}
        savedSegments={savedSegments}
        savedGroups={savedGroups}
        liveMode={liveMode}
        startDate={startDate}
        endDate={endDate}
        resolution={resolution}
        config={config}
        segments={segments}
        segmentsEligibleBreakdownKeys={segmentsEligibleBreakdownKeys}
        aliases={aliases}
        onScorecardSave={onScorecardSave}
        onScorecardDelete={onScorecardDelete}
        onLiveModeChange={onLiveModeChange}
        onDatesChange={onDatesChange}
        onConfigChange={onConfigChange}
        onSegmentAdd={onSegmentAdd}
        onSegmentDelete={onSegmentDelete}
        onSegmentDuplicate={onSegmentDuplicate}
        onSegmentEditChange={onSegmentEditChange}
        onSegmentSaveEdit={onSegmentSaveEdit}
        onSegmentRevertEdit={onSegmentRevertEdit}
        onSegmentColorChange={onSegmentColorChange}
        onSegmentFilterAdd={onSegmentFilterAdd}
        onSegmentReplaceFiltersWithSavedSegmentFilter={onSegmentReplaceFiltersWithSavedSegmentFilter}
        onSegmentFilterDelete={onSegmentFilterDelete}
        onSegmentsReorder={onSegmentsReorder}
        onSegmentBreakdownSelect={onSegmentBreakdownSelect}
        onSegmentBreakdownColorChange={onSegmentBreakdownColorChange}
        onFilterAdd={onFilterAdd}
        onFilterUpdate={onFilterUpdate}
        onFilterDelete={onFilterDelete}
        onClearAllSegmentFilters={onClearAllSegmentFilters}
      />

      <ScorecardBuilder
        xKey={scorecard.xKey}
        scorecardData={scorecardData}
        startDate={startDate}
        endDate={endDate}
        resolution={resolution}
        cards={cards}
        onAdjustDateRangeToCompletePeriods={onAdjustDateRangeToCompletePeriods}
        onResolutionChange={onResolutionChange}
        onCardAdd={onCardAdd}
        onCardUpdate={onCardUpdate}
        onCardDelete={onCardDelete}
        onCardReorder={onCardReorder}
      />
    </Box>
  )
}

Scorecard.propTypes = {
  scorecard: PropTypes.object.isRequired,
  savedSegments: PropTypes.array.isRequired,
  savedGroups: PropTypes.array.isRequired,
  liveMode: PropTypes.bool.isRequired,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  resolution: PropTypes.number.isRequired,
  config: PropTypes.object.isRequired,
  cards: PropTypes.array.isRequired,
  segments: PropTypes.array.isRequired,
  onScorecardSave: PropTypes.func.isRequired,
  onScorecardDelete: PropTypes.func.isRequired,
  onLiveModeChange: PropTypes.func.isRequired,
  onDatesChange: PropTypes.func.isRequired,
  onAdjustDateRangeToCompletePeriods: PropTypes.func.isRequired,
  onResolutionChange: PropTypes.func.isRequired,
  onConfigChange: PropTypes.func.isRequired,
  onCardAdd: PropTypes.func.isRequired,
  onCardUpdate: PropTypes.func.isRequired,
  onCardDelete: PropTypes.func.isRequired,
  onCardReorder: PropTypes.func.isRequired,
  onSegmentAdd: PropTypes.func.isRequired,
  onSegmentDelete: PropTypes.func.isRequired,
  onSegmentDuplicate: PropTypes.func.isRequired,
  onSegmentEditChange: PropTypes.func.isRequired,
  onSegmentSaveEdit: PropTypes.func.isRequired,
  onSegmentRevertEdit: PropTypes.func.isRequired,
  onSegmentColorChange: PropTypes.func.isRequired,
  onSegmentFilterAdd: PropTypes.func.isRequired,
  onSegmentReplaceFiltersWithSavedSegmentFilter: PropTypes.func.isRequired,
  onSegmentFilterDelete: PropTypes.func.isRequired,
  onSegmentsReorder: PropTypes.func.isRequired,
  onFilterAdd: PropTypes.func.isRequired,
  onFilterUpdate: PropTypes.func.isRequired,
  onFilterDelete: PropTypes.func.isRequired,
  onClearAllSegmentFilters: PropTypes.func.isRequired,
  onSegmentBreakdownSelect: PropTypes.func.isRequired,
  onSegmentBreakdownColorChange: PropTypes.func.isRequired,
}

export default Scorecard