import React from 'react'
import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'

import DateRangePicker from '../../Common/DateRangePicker'
import AddFilter from './AddFilter'
import BreakdownSelect from './BreakdownSelect'
import AttributionSelect from './AttributionSelect'
import BackendTypeSelect from './BackendTypeSelect'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
  },
  controlForm: {
    marginLeft: theme.spacing(2),
    width: 90,
  },
}))

const ControlsConfiguration = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <DateRangePicker
        startDate={props.startDate}
        endDate={props.endDate}
        onDatesChange={props.onDatesChange}
      />
      <AddFilter
        filters={props.filters}
        onFilterAdd={props.onFilterAdd}
        filterLabel={props.filterLabel}
      />
      <AttributionSelect
        attribution={props.attribution}
        onAttributionSelect={props.onAttributionSelect}
      />
      <BreakdownSelect
        onBreakdownSelect={props.onBreakdownSelect}
        breakdownLabel={props.breakdownLabel}
      />
      <BackendTypeSelect
        frontendType={props.frontendType}
        onBackendTypeSelect={props.onBackendTypeSelect}
        disabled={props.attribution === 'lead'}
      />
    </div>
  )
}

ControlsConfiguration.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onDatesChange: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired,
  onFilterAdd: PropTypes.func.isRequired,
  filterLabel: PropTypes.string,
  onBreakdownSelect: PropTypes.func.isRequired,
  breakdownLabel: PropTypes.string,
  attribution: PropTypes.string.isRequired,
  onAttributionSelect: PropTypes.func.isRequired,
  frontendType: PropTypes.string.isRequired,
  onBackendTypeSelect: PropTypes.func.isRequired
}

export default ControlsConfiguration
