import React from 'react'
import PropTypes from 'prop-types'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import makeStyles from '@mui/styles/makeStyles'

import TableSwitchToggle from './TableSwitchToggle'

const useStyles = makeStyles(() => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  actionTableCell: {
    minWidth: 162,
    position: 'sticky',
    left: 0,
    background: 'white',
    zIndex: 1,
  },
  headCell: {
    fontSize: '.78rem',
    lineHeight: '1.2rem',
    background: 'white',
  },
  nameTableCell: {
    minWidth: 250,
    fontSize: '.78rem',
    lineHeight: '1.2rem',
    position: 'sticky',
    left: 162,
    background: 'white',
    boxShadow: '3px 0 2px -2px #a7a7a7',
    zIndex: 1,
  },
}))

const nameHeadCell = { id: 'name', numeric: false, label: 'Name', allowedAttribution: ['customer', 'lead'] }
const headCells = {
  default: [
    { id: 'customers_new', numeric: true, label: 'New Customers', allowedAttribution: ['customer'] },
    { id: 'leads', numeric: true, label: 'New Leads', allowedAttribution: ['lead'] },
    { id: 'ltv_0', numeric: true, label: 'Day 0 LTV', allowedAttribution: ['customer', 'lead'] },
    { id: 'ltv_30', numeric: true, label: 'Day 30 LTV', allowedAttribution: ['customer', 'lead'] },
    { id: 'ltv_60', numeric: true, label: 'Day 60 LTV', allowedAttribution: ['customer', 'lead'] },
    { id: 'ltv_90', numeric: true, label: 'Day 90 LTV', allowedAttribution: ['customer', 'lead'] },
    { id: 'ltr_max', numeric: true, label: 'LTR', allowedAttribution: ['customer', 'lead'] },
    { id: 'customers_total', numeric: true, label: 'Total Customers', allowedAttribution: ['customer'] },
    { id: 'orders', numeric: true, label: 'Orders', allowedAttribution: ['customer'] },
    { id: 'aov', numeric: true, label: 'AOV', allowedAttribution: ['customer'] },
    { id: 'revenue_orders', numeric: true, label: 'Order Revenue', allowedAttribution: ['customer'] },
    { id: 'revenue_rebills', numeric: true, label: 'Rebill Revenue', allowedAttribution: ['customer'] },
    { id: 'revenue_gross', numeric: true, label: 'Gross Revenue', allowedAttribution: ['customer'] },
    { id: 'revenue_refunds', numeric: true, label: 'Refund Amount', allowedAttribution: ['customer'] },
    { id: 'revenue_net', numeric: true, label: 'Net Revenue', allowedAttribution: ['customer'] },
    { id: 'revenue_net_frontend', numeric: true, label: 'Frontend Revenue', allowedAttribution: ['customer'] },
    { id: 'revenue_net_backend', numeric: true, label: 'Backend Revenue', allowedAttribution: ['customer'] },
    { id: 'orders_frontend', numeric: true, label: 'Frontend Orders', allowedAttribution: ['customer'] },
    { id: 'orders_backend', numeric: true, label: 'Backend Orders', allowedAttribution: ['customer'] },
    { id: 'rebills', numeric: true, label: 'Rebills', allowedAttribution: ['customer'] },
    { id: 'refunds', numeric: true, label: 'Refunds', allowedAttribution: ['customer'] },
  ],
  detailed_ltv: [
    { id: 'customers_new', numeric: true, label: 'New Customers', allowedAttribution: ['customer'] },
    { id: 'leads', numeric: true, label: 'New Leads', allowedAttribution: ['lead'] },
    { id: 'ltv_0', numeric: true, label: 'Day 0 LTV', allowedAttribution: ['customer', 'lead'] },
    { id: 'ltv_7', numeric: true, label: 'Day 7 LTV', allowedAttribution: ['customer', 'lead'] },
    { id: 'ltv_14', numeric: true, label: 'Day 14 LTV', allowedAttribution: ['customer', 'lead'] },
    { id: 'ltv_21', numeric: true, label: 'Day 21 LTV', allowedAttribution: ['customer', 'lead'] },
    { id: 'ltv_30', numeric: true, label: 'Day 30 LTV', allowedAttribution: ['customer', 'lead'] },
    { id: 'ltv_45', numeric: true, label: 'Day 45 LTV', allowedAttribution: ['customer', 'lead'] },
    { id: 'ltv_60', numeric: true, label: 'Day 60 LTV', allowedAttribution: ['customer', 'lead'] },
    { id: 'ltv_90', numeric: true, label: 'Day 90 LTV', allowedAttribution: ['customer', 'lead'] },
    { id: 'ltv_180', numeric: true, label: 'Day 180 LTV', allowedAttribution: ['customer', 'lead'] },
    { id: 'ltv_365', numeric: true, label: 'Day 365 LTV', allowedAttribution: ['customer', 'lead'] },
    { id: 'ltv_max', numeric: true, label: 'LTV Max', allowedAttribution: ['customer', 'lead'] },
  ],
  detailed_ltr: [
    { id: 'customers_new', numeric: true, label: 'New Customers', allowedAttribution: ['customer'] },
    { id: 'leads', numeric: true, label: 'New Leads', allowedAttribution: ['lead'] },
    { id: 'ltr_0', numeric: true, label: 'Day 0 LTR', allowedAttribution: ['customer', 'lead'] },
    { id: 'ltr_7', numeric: true, label: 'Day 7 LTR', allowedAttribution: ['customer', 'lead'] },
    { id: 'ltr_14', numeric: true, label: 'Day 14 LTR', allowedAttribution: ['customer', 'lead'] },
    { id: 'ltr_21', numeric: true, label: 'Day 21 LTR', allowedAttribution: ['customer', 'lead'] },
    { id: 'ltr_30', numeric: true, label: 'Day 30 LTR', allowedAttribution: ['customer', 'lead'] },
    { id: 'ltr_45', numeric: true, label: 'Day 45 LTR', allowedAttribution: ['customer', 'lead'] },
    { id: 'ltr_60', numeric: true, label: 'Day 60 LTR', allowedAttribution: ['customer', 'lead'] },
    { id: 'ltr_90', numeric: true, label: 'Day 90 LTR', allowedAttribution: ['customer', 'lead'] },
    { id: 'ltr_180', numeric: true, label: 'Day 180 LTR', allowedAttribution: ['customer', 'lead'] },
    { id: 'ltr_365', numeric: true, label: 'Day 365 LTR', allowedAttribution: ['customer', 'lead'] },
    { id: 'ltr_max', numeric: true, label: 'LTR Max', allowedAttribution: ['customer', 'lead'] },
  ]
}

const EnhancedTableHead = (props) => {
  const { attribution, columns, hideNameColumn, order, orderBy, onRequestSort, onSwitchToggleSelect } = props
  const classes = useStyles()

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  const filteredHeadCells = headCells[columns].filter(item => item.allowedAttribution.indexOf(attribution) > -1)
  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.actionTableCell} align='left'>
          <TableSwitchToggle onToggleClick={onSwitchToggleSelect} />
        </TableCell>
        {!hideNameColumn && (
          <TableCell className={classes.nameTableCell} align='left'>
            {nameHeadCell.label}
          </TableCell>
        )}
        {filteredHeadCells.map((headCell) =>
          <TableCell
            className={classes.headCell}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'desc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  attribution: PropTypes.string.isRequired,
  columns: PropTypes.string.isRequired,
  hideNameColumn: PropTypes.bool.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
}

export default EnhancedTableHead