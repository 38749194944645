import React, { useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { darken } from '@mui/material/styles'
import { useHistory } from 'react-router-dom'

import FullPageLoadingSpinner from '../Common/FullPageLoadingSpinner'

import * as ROUTES from '../../constants/routes'
import { CONTENT_MAP } from '../../constants/partners'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  gridItem: {
    maxWidth: 550,
    minWidth: 550,
  },
  headerTypography: {
    fontWeight: 900,
  },
  button: {
    marginTop: theme.spacing(2),
    height: 52,
    fontSize: 20,
  }
}))

const PasswordResetSent = () => {
  const classes = useStyles()
  const history = useHistory()
  const [contentKey, setContentKey] = React.useState(null)

  // Check URL to display the appropriate login page content
  useEffect(() => {
    // Check path to see if a alternate login page should be displayed
    const loginUrlMatch = history.location.pathname.match(/\/sent-reset-password\/([^/]+)(?:\/([^/]+))?/)
    const subPath = loginUrlMatch ? loginUrlMatch[1] : null
    if (subPath) {
      setContentKey(subPath)
    } else {
      setContentKey('default')
    }
  }, [])

  return (
    <Box>
      {contentKey === null ? <FullPageLoadingSpinner /> : (
        <Grid className={classes.root} container alignItems='center' justifyContent='center'>
          <Grid className={classes.gridItem} item xs={12}>
            <Typography className={classes.headerTypography} variant='h5' gutterBottom>
              Just one more step...
            </Typography>
            <Typography variant='body1'>
              Please check your email and click the link provided to finish resetting your password.
            </Typography>
            <Button
              className={classes.button}
              variant='contained'
              onClick={() => history.push(contentKey !== 'default' ? `${ROUTES.LOGIN}/${contentKey}` : ROUTES.LOGIN)}
              sx={{
                backgroundColor: CONTENT_MAP[contentKey].buttonColor,
                color: theme => theme.palette.getContrastText(CONTENT_MAP[contentKey].buttonColor),
                '&:hover': {
                  backgroundColor: darken(CONTENT_MAP[contentKey].buttonColor, 0.1),
                }
              }}
            >
              Return to Login
            </Button>
          </Grid>
        </Grid>
      )}
    </Box>

  )
}

export default PasswordResetSent
