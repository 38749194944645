import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts'

import moment from 'moment-timezone'

import { BLUE_ACCENT_COLOR } from '../../constants'
import { formatNumber } from '../../utils/helpers'

import ChangeBadge from './ChangeBadge'

const PRIMARY_DATA_NAME = 'primary'
const COMPARE_DATA_NAME = 'compare'

const CHART_HEIGHT = 200
const TIMEZONE = moment.tz.guess()

const DayGraph = (props) => {
  const { primaryDate, compareDate, data, isLoading, metric } = props
  const theme = useTheme()

  return (
    <Box>
      {isLoading ? <Skeleton variant="rectangular" height={CHART_HEIGHT + 20} /> : (
        <Box>
          <Box>
            <Typography variant='caption'>
              {primaryDate.format('hh:mm A')}
            </Typography>
          </Box>

          <ResponsiveContainer width='100%' height={CHART_HEIGHT}>
            <LineChart width={300} height='100%' data={data}>
              <CartesianGrid
                strokeDasharray='1'
                vertical
                horizontal={false}
              />
              <XAxis
                type='category'
                dataKey='x'
                domain={['min', 'max']}
                tickLine={false}
                ticks={[...data.map(el => el.x)]}
                minTickGap={0}
                interval={0}
                tick={<CustomizedAxisTick />}
              />
              <YAxis
                type='number'
                domain={['auto', 'auto']}
                tickFormatter={val => val}
                hide
              />
              <Tooltip
                isAnimationActive={false}
                content={<CustomTooltip compareDate={compareDate} metric={metric} />}
              />
              <Line type='monotone' dataKey={PRIMARY_DATA_NAME} stroke={BLUE_ACCENT_COLOR} strokeWidth={2} dot={false} />
              <Line type='monotone' dataKey={COMPARE_DATA_NAME} stroke={theme.palette.text.secondary} strokeWidth={1} dot={false} />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      )}

    </Box>
  );
}

const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props

  if (moment.tz(payload.value, TIMEZONE).hour() === 0) {
    return (
      <g transform={`translate(${x},${y})`}>
        <text fontSize={14} x={60} y={10} textAnchor='end' fill='#666' >{moment.tz(payload.value, TIMEZONE).format('h:mm A')}</text>
      </g>
    )
  } else if (moment.tz(payload.value, TIMEZONE).hour() === 23) {
    return (
      <g transform={`translate(${x},${y})`}>
        <text fontSize={14} x={0} y={10} textAnchor='end' fill='#666' >{moment.tz(payload.value, TIMEZONE).format('h:mm A')}</text>
      </g>
    )
  } else {
    return null
  }

}

const CustomTooltip = (props) => {
  const { active, payload, label, compareDate, metric } = props

  if (payload === null) return null

  payload.sort((a, b) => {
    return parseFloat(b.value) - parseFloat(a.value)
  })

  if (active) {
    const comparePayload = payload.find(el => el.name === COMPARE_DATA_NAME)
    const todayPayload = payload.find(el => el.name === PRIMARY_DATA_NAME)
    return (
      <Box bgcolor='white' textAlign='center' border='1px solid rgb(204, 204, 204)' borderRadius="4px" maxWidth={300}>
        {todayPayload && (
          <React.Fragment>
            <Box padding={1}>
              <Typography variant='body2'>
                {moment.tz(label, TIMEZONE).format('MMM D, YYYY, h:mm A')}
              </Typography>
              <Typography variant='body2' style={{ color: todayPayload.color, fontWeight: 'bold' }}>
                {metric.isDollar ? '$' : ''}{formatNumber(todayPayload.value, metric.decimalCount)}
              </Typography>
              <Box display='flex' justifyContent='center'>
                <Box maxWidth={100}>
                  <ChangeBadge
                    metricPrimary={todayPayload.value}
                    metricSecondary={comparePayload.value}
                  />
                </Box>
              </Box>
            </Box>
            <Divider />
          </React.Fragment>
        )}

        {comparePayload && (
          <Box padding={1}>
            <Typography variant='body2'>
              {compareDate.format('MMM D, YYYY')}, {moment.tz(label, TIMEZONE).format('h:mm A')}
            </Typography>
            <Typography variant='body2' style={{ color: comparePayload.color }}>
              {metric.isDollar ? '$' : ''}{formatNumber(comparePayload.value, metric.decimalCount)}
            </Typography>
          </Box>
        )}


      </Box>
    );
  }

  return null
}

DayGraph.propTypes = {
  primaryDate: PropTypes.object.isRequired,
  compareDate: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  metric: PropTypes.object.isRequired,
}


export default DayGraph