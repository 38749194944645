import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import Popover from '@mui/material/Popover'

import { COHORT_BREAKDOWN_FIELDS, PAYMENT_FIELDS } from '../../constants'

const BreakdownSelect = ({
  segmentId,
  kind,
  breakdownLabel,
  onSegmentBreakdownSelect,
  includeCohortFields,
}) => {
  const breakdownsItems = PAYMENT_FIELDS

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleBreakdownSelect = (field) => {
    const breakdown = { field, kind }
    onSegmentBreakdownSelect(segmentId, breakdown)
    setAnchorEl(null)
  }

  return (
    <Box>
      <Button
        variant='text'
        size='small'
        color='secondary'
        onClick={handleClick}
      >
        {breakdownLabel ? breakdownLabel : 'Breakdown'}
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
        transformOrigin={{ vertical: 'top', horizontal: 'left', }}
        sx={{
          marginTop: theme => theme.spacing(1)
        }}
      >
        <Box>
          <List disablePadding sx={{ maxHeight: 545 }}>
            {includeCohortFields && (
              <React.Fragment>
                <ListSubheader>Cohorts</ListSubheader>
                {COHORT_BREAKDOWN_FIELDS.map(item =>
                  <ListItemButton
                    key={item.id}
                    onClick={() => handleBreakdownSelect(item.id)}
                    dense
                  >
                    <ListItemText
                      primary={item.name}
                      primaryTypographyProps={{ style: { fontSize: 16 } }}
                    />
                  </ListItemButton>
                )}
                <ListSubheader>Fields</ListSubheader>
              </React.Fragment>

            )}
            {breakdownsItems.map(item =>
              <ListItemButton
                key={item.id}
                onClick={() => handleBreakdownSelect(item.id)}
                dense
              >
                <ListItemText
                  primary={item.name}
                  primaryTypographyProps={{ style: { fontSize: 16 } }}
                />
              </ListItemButton>
            )}
          </List>
        </Box>
      </Popover>
    </Box>
  )
}

BreakdownSelect.defaultProps = {
  kind: 'all',
}

BreakdownSelect.propTypes = {
  segmentId: PropTypes.string.isRequired,
  kind: PropTypes.oneOf(['all', 'frontend', 'backend']).isRequired,
  breakdownLabel: PropTypes.string,
  onSegmentBreakdownSelect: PropTypes.func.isRequired,
  includeCohortFields: PropTypes.bool,
}

export default BreakdownSelect
