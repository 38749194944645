import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
import { useTheme } from '@mui/material/styles'

import makeStyles from '@mui/styles/makeStyles'

import { FirebaseContext } from '../../../utils/firebase'
import 'firebase/auth'

import {
  ResponsiveContainer,
  ComposedChart,
  Area,
  Bar,
  Line,
  Brush,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip
} from 'recharts'

import LoadingSkeleton from './LoadingSkeleton'
import NoLinesMessage from './NoLinesMessage'

import {
  useQuery,
} from 'react-query'
import moment from 'moment-timezone'

import { formatNumber, getAlias, shortenString } from '../../../utils/helpers'
import { API_ROOT_URL } from '../../../constants/'

const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const useStyles = makeStyles(theme => ({
  root: {
  },
  controlBar: {
    display: 'flex',
    alignItems: 'flex-end',
    marginTop: -45,
    justifyContent: 'flex-end',
    [theme.breakpoints.only('xs')]: {
      marginTop: 0,
      justifyContent: 'center',
    },
  },
  controlForm: {
    marginLeft: theme.spacing(2),
    minWidth: 90,
  },
  zoomButton: {
    height: 48,
    marginLeft: theme.spacing(1),
  },
  axisLabelTypographyTop: {
    position: 'absolute',
    left: 60,
    color: theme.palette.text.secondary,
  },
  axisLabelTypographyBottom: {
    position: 'absolute',
    top: 325,
    left: 60,
    color: theme.palette.text.secondary,
  },
  tooltipRoot: {
    backgroundColor: 'white',
    textAlign: 'right',
    padding: theme.spacing(1),
    border: '1px solid rgb(204, 204, 204)',
    borderRadius: theme.shape.borderRadius,
    maxWidth: 300,
  },
  tooltipDataTypography: {
    margin: 0,
    fontSize: 12,
  },
  tooltipTitleTypography: {
    color: theme.palette.text.secondary,
  },
  noGraphDataTypography: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.text.hint,
  },
  summaryTypography: {
    color: theme.palette.text.secondary,
    margin: theme.spacing(2, 1, 0, 1),
  },
  graphAreaDiv: {
    position: 'relative',
    width: '100%',
  }
}))

const CHART_HEIGHT = 250
const BRUSH_HEIGHT = 20
const SYNC_ID = 'revenue'
const TICK_FONT_SIZE = 12

const typeSelectItems = [
  {id: 'revenue_net', value: 'Net Revenue'},
  {id: 'revenue_net_frontend', value: 'Net Frontend Revenue'},
  {id: 'revenue_net_backend', value: 'Net Backend Revenue'},
  {id: 'revenue_gross', value: 'Gross Revenue'},
  {id: 'revenue_orders', value: 'Order Revenue'},
  {id: 'revenue_rebills', value: 'Rebill Revenue'},
  {id: 'revenue_refunds', value: 'Refunds'},
]

const resolutionSelectItems = [
  {id: 'day', value: 'Day'},
  {id: 'week', value: 'Week'},
  {id: 'month', value: 'Month'},
  {id: 'quarter', value: 'Quarter'},
  {id: 'year', value: 'Year'},
]

const dataMappings = {
  revenue_net: {
    topLabel: 'Net Revenue',
    bottomLabel: 'Orders',
    bottomDataKey: 'orders'
  },
  revenue_net_frontend: {
    topLabel: 'Net Frontend Revenue',
    bottomLabel: 'Frontend Orders',
    bottomDataKey: 'orders_frontend'
  },
  revenue_net_backend: {
    topLabel: 'Net Backend Revenue',
    bottomLabel: 'Backend Orders',
    bottomDataKey: 'orders_backend'
  },
  revenue_gross: {
    topLabel: 'Gross Revenue',
    bottomLabel: 'Orders',
    bottomDataKey: 'orders'
  },
  revenue_orders: {
    topLabel: 'Order Revenue',
    bottomLabel: 'Orders',
    bottomDataKey: 'orders'
  },
  revenue_rebills: {
    topLabel: 'Rebill Revenue',
    bottomLabel: 'Rebills',
    bottomDataKey: 'rebills'
  },
  revenue_refunds: {
    topLabel: 'Refund Total',
    bottomLabel: 'Refunds',
    bottomDataKey: 'refunds'
  }
}

const GraphRevenue = (props) => {
  const { context, colors } = props
  const classes = useStyles()
  const theme = useTheme()
  const firebase = React.useContext(FirebaseContext)
  const [type, setType] = React.useState('revenue_net')
  const [resolution, setResolution] = React.useState('week')
  const [showBrush, setShowBrush] = React.useState(false)
  const [compare, setCompare] = React.useState(false)

  const activeIds = Object.keys(colors).filter(id => !!colors[id])
  let activeRowFilter = []
  if (context.breakdown) {
    activeRowFilter.push({ id: context.breakdown.id, name: context.breakdown.name, value: activeIds })
  }
  const allFilters = [...context.filters, ...activeRowFilter]
  const { isLoading, data } = useQuery(['revenue', context.dates, context.breakdown, allFilters, resolution, type], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/graphs/revenue`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          startDate: context.dates.start.format(),
          endDate: context.dates.end.format(),
          timezone: TIMEZONE,
          breakdown: context.breakdown,
          filters: allFilters,
          resolution,
          type
        })
      }).then(res => res.json())
    }),
    {
      enabled: !!context.dates.start && !!context.dates.end && activeIds.length > 0,
      cacheTime: 15 * 60 * 1000,  // 15 minutes
      staleTime: 15 * 60 * 1000,  // 15 minutes
      refetchOnWindowFocus: false,
    }
  )

  const top = data ? {
    type: compare ? 'line': 'area',
    data: data[type]
  } : {}
  const bottom = data ? {
    type: 'bar',
    data: data[dataMappings[type].bottomDataKey]
  } : {}
  const aliases = data ? data.aliases : []

  const noLines = activeIds.length === 0
  const summaryMessage = `[Top] ${dataMappings[type].topLabel} by ${resolution} from ${context.dates.start ? context.dates.start.format('MM/DD/YY') : null} to ${context.dates.end ? context.dates.end.format('MM/DD/YY') : null}, ${compare ? `shown with lines compared head to head`: `shown as a stacked area chart`}. [Bottom] ${dataMappings[type].bottomLabel} by ${resolution}, shown as a stacked bar chart.`
  return (
    <Fragment>
      <div className={classes.controlBar}>
        {context.breakdown &&
          <FormControl className={classes.controlForm} margin='dense' size='small'>
            <FormControlLabel
              control={
                <Switch
                  checked={compare}
                  onChange={() => setCompare(!compare)}
                  name='compare'
                  color='primary'
                />
              }
              label='Compare'
            />
          </FormControl>
}
        <FormControl className={classes.controlForm} margin='dense' size='small'>
          <InputLabel id='control-label'>Type</InputLabel>
          <Select
            labelId='type-label'
            label='Type'
            id='type-select'
            value={type}
            onChange={event => setType(event.target.value)}
          >
            {typeSelectItems.map(item =>
              <MenuItem key={item.id} value={item.id}>{item.value}</MenuItem>
            )}
          </Select>
        </FormControl>
        <FormControl className={classes.controlForm} margin='dense' size='small'>
          <InputLabel id='control-label'>Resolution</InputLabel>
          <Select
            labelId='resolution-label'
            label='Resolution'
            id='resolution-select'
            value={resolution}
            onChange={event => setResolution(event.target.value)}
          >
            {resolutionSelectItems.map(item =>
              <MenuItem key={item.id} value={item.id}>{item.value}</MenuItem>
            )}
          </Select>
        </FormControl>
        <IconButton
          className={classes.zoomButton}
          aria-label='zoom'
          onClick={() => setShowBrush(!showBrush)}
          size="large">
          {showBrush ? <ZoomOutIcon /> : <ZoomInIcon />}
        </IconButton>
      </div>

      {isLoading ? (
        <LoadingSkeleton classes={classes} height={CHART_HEIGHT * 4/3} />
      ) : noLines ? (
        <NoLinesMessage classes={classes} height={CHART_HEIGHT * 4/3} />
        ) : (
        <div className={classes.graphAreaDiv}>
          <Typography className={classes.axisLabelTypographyTop} component='div' variant='caption'>
            {dataMappings[type].topLabel}
          </Typography>
          <ResponsiveContainer width='100%' height={showBrush ? CHART_HEIGHT + BRUSH_HEIGHT : CHART_HEIGHT}>
            <ComposedChart data={top.data} margin={{ top: showBrush ? 40 : BRUSH_HEIGHT, bottom: showBrush ? -1 * BRUSH_HEIGHT : 0, left: 0, right: 0 }} syncId={SYNC_ID}>
              <XAxis
                dataKey='x'
                tick={{ fontSize: TICK_FONT_SIZE }}
                tickFormatter={val => moment.tz(val, TIMEZONE).format('M/D/YY')}
              />
              <YAxis
                type='number'
                domain={['auto', 'auto']}
                tick={{ fontSize: TICK_FONT_SIZE }}
                tickFormatter={val => `$${formatNumber(val, 2, true, true)}`}
              />
              <CartesianGrid strokeDasharray='1 1' vertical={false} />
              <Tooltip
                isAnimationActive={false}
                content={<CustomTooltipTop type={type} />}
                wrapperStyle={{ zIndex: 1000 }}
              />
              {showBrush &&
                <Brush
                  dataKey='x'
                  height={BRUSH_HEIGHT}
                  y={8}
                  stroke={theme.palette.secondary.main}
                  travellerWidth={20}
                  tickFormatter={val => moment.tz(val, TIMEZONE).format('M/D/YY')}
                />
              }
              {activeIds.map(id => {
                switch(top.type) {
                  case 'line':
                    return (
                      <Line
                        key={id}
                        dataKey={id}
                        name={getAlias(aliases, id)}
                        stroke={colors[id]}
                        dot={{ r: 1, fill: colors[id] }}
                        type='linear'
                        connectNulls
                      />
                    )
                  case 'bar':
                    return (
                      <Bar
                        key={id}
                        dataKey={id}
                        name={getAlias(aliases, id)}
                        fill={colors[id]}
                        stackId='bar'
                      />
                    )
                  case 'area':
                    return (
                      <Area
                        key={id}
                        dataKey={id}
                        name={getAlias(aliases, id)}
                        type='linear'
                        stroke={colors[id]}
                        dot={{ r: 1, fill: colors[id] }}
                        fill={colors[id]}
                        stackId='area'
                      />
                    )
                  default:
                    return null
                }
              })}
            </ComposedChart>
          </ResponsiveContainer>
          <ResponsiveContainer width='100%' height={CHART_HEIGHT / 3}>
            <ComposedChart data={bottom.data} margin={{ top: 0, bottom: -1 * BRUSH_HEIGHT, left: 0, right: 0 }} syncId={SYNC_ID}>
              <XAxis
                dataKey='x'
                axisLine={false}
                tickLine={false}
                tick={false}
              />
              <YAxis
                type='number'
                domain={[0, 'dataMax']}
                tick={{ fontSize: TICK_FONT_SIZE }}
                interval='preserveStartEnd'
                tickFormatter={val => formatNumber(val, 2, true, true)}
                reversed
              />
              <CartesianGrid strokeDasharray='1 1' vertical={false} />
              <Tooltip
                isAnimationActive={false}
                content={<CustomTooltipBottom type={type} />}
                wrapperStyle={{ zIndex: 1000 }}
              />
              {activeIds.map(id => {
                switch(bottom.type) {
                  case 'line':
                    return (
                      <Line
                        key={id}
                        dataKey={id}
                        name={getAlias(aliases, id)}
                        stroke={colors[id]}
                        dot={{ r: 1, fill: colors[id] }}
                        type='linear'
                        connectNulls
                      />
                    )
                  case 'bar':
                    return (
                      <Bar
                        key={id}
                        dataKey={id}
                        name={getAlias(aliases, id)}
                        fill={colors[id]}
                        stackId='stack'
                      />
                    )
                  default:
                    return null
                }
              })}
            </ComposedChart>
          </ResponsiveContainer>
          <Typography className={classes.axisLabelTypographyBottom} component='div' variant='caption'>
            {dataMappings[type].bottomLabel}
          </Typography>
          <Typography className={classes.summaryTypography} component='div' variant='caption'>
            {summaryMessage}
          </Typography>
        </div>
        )
      }
    </Fragment>
  );
}

const CustomTooltipTop = ({ active, payload, label, type }) => {
  const classes = useStyles()

  if (payload === null) return null

  payload.sort((a, b) => {
    return parseFloat(b.value) - parseFloat(a.value)
  })

  if (active) {
    return (
      <div className={classes.tooltipRoot}>
        <Typography variant='overline'>
          {moment.tz(label, TIMEZONE).format('M/D/YY')}
        </Typography>
        {payload.map(datum =>
          <Typography key={datum.dataKey} className={classes.tooltipDataTypography} style={{ color: datum.color }}>
            {`${shortenString(datum.name, 32)} : $${formatNumber(datum.value, 2)}`}
          </Typography>
        )}
        <Typography className={classes.tooltipTitleTypography} variant='caption'>
          {dataMappings[type].topLabel}
        </Typography>
      </div>
    )
  }

  return null
}

const CustomTooltipBottom = ({ active, payload, type }) => {
  const classes = useStyles()

  if (payload === null) return null

  if (active) {
    return (
      <div className={classes.tooltipRoot}>
        {payload.map(datum =>
          <Typography key={datum.dataKey} className={classes.tooltipDataTypography} style={{ color: datum.color }}>
            {`${shortenString(datum.name, 32)} : ${formatNumber(datum.value, 0)}`}
          </Typography>
        )}
        <Typography className={classes.tooltipTitleTypography} variant='caption'>
          {dataMappings[type].bottomLabel}
        </Typography>
      </div>
    )
  }

  return null
}

GraphRevenue.propTypes = {
  context: PropTypes.object.isRequired,
  colors: PropTypes.object.isRequired,
}

export default GraphRevenue
