import React from 'react'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import Skeleton from '@mui/material/Skeleton'
import Snackbar from '@mui/material/Snackbar'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'

import { FirebaseContext } from '../../utils/firebase'
import 'firebase/auth'

import {
  useQuery,
} from 'react-query'

import AnalysisCard from './AnalysisCard'
import BackendSummary from './BackendSummary'
import Controls from './Controls'
import GraphRevenue from './GraphRevenue'
import NewCustomersSummary from './NewCustomersSummary'
import RevenueTotals from './RevenueTotals'

import { getAlias, getColorPalette } from '../../utils/helpers'
import { ALL_DATA_KEY, API_ROOT_URL } from '../../constants/'

const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: 66,
    right: 0,
    width: 'calc(100% - 66px)',
    height: '100vh',
    overflowY: 'auto',
    paddingTop: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
  },
  paper: {
    padding: theme.spacing(2),
  },
  paperTopMargin: {
    marginTop: theme.spacing(2),
  },
  accentTypography: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightBold,
  },
  metricValueTypography: {
    lineHeight: 1,
  },
  metricKeyTypography: {
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
  },
  gridIndent: {
    paddingLeft: [theme.spacing(5), '!important'],
  },
}))

const TOP_ELEMENTS_LIMIT = 5
const orderBySelectItems = [
  {id: 'revenue_net', name: 'Total Revenue'},
  {id: 'customers_new', name: 'New Customers'},
]

const Dashboard = () => {
  const classes = useStyles()
  const theme = useTheme()
  const firebase = React.useContext(FirebaseContext)
  const [startDate, setStartDate] = React.useState(moment.tz(TIMEZONE).subtract(90, 'days').startOf('day'))
  const [endDate, setEndDate] = React.useState(moment.tz(TIMEZONE).endOf('day'))
  const [breakdown, setBreakdown] = React.useState(null)
  const [filters] = React.useState([])  // hard-coded for now
  const [resolution, setResolution] = React.useState('day')
  const [attribution] = React.useState('customer')  // hard-coded for now
  const [orderBy, setOrderBy] = React.useState('revenue_net')
  const [extendBackend, setExtendBackend] = React.useState(false)
  const [frontendType] = React.useState('order')

  const context = {
    dates: {
      start: startDate,
      end: endDate,
    },
    breakdown,
    filters,
  }

  const { isLoading: isLoadingRevenueGraph, isError, data: dataRevenueGraph } = useQuery(['dashboard-revenue', context, resolution, orderBy], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/dashboard/revenue`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          startDate: context.dates.start.format(),
          endDate: context.dates.end.format(),
          timezone: TIMEZONE,
          breakdown: context.breakdown,
          filters: context.filters,
          resolution,
          orderBy,
          limit: TOP_ELEMENTS_LIMIT
        })
      }).then(res => res.json())
    }),
    {
      enabled: !!context.dates.start && !!context.dates.end,
      cacheTime: 15 * 60 * 1000,  // 15 minutes
      staleTime: 15 * 60 * 1000,  // 15 minutes
      refetchOnWindowFocus: false,
    }
  )

  const handleDatesChange = ({ startDate: newStartDate, endDate: newEndDate }) => {
    setStartDate(newStartDate)
    setEndDate(newEndDate)
  }

  const getColors = (topElementIds) => {
    let colors = {}

    // Handle special case for ALL_DATA
    if (topElementIds.length === 1 && topElementIds[0] === ALL_DATA_KEY) {
      colors[ALL_DATA_KEY] = theme.palette.secondary.main
      return colors
    }

    const colorsList = getColorPalette(topElementIds.length)

    topElementIds.forEach((id, index) => {
      colors[id] = '#' + colorsList[index]
    })
    return colors
  }

  const aliases = dataRevenueGraph ? dataRevenueGraph.aliases : []
  const topElementIds = dataRevenueGraph ? (
    dataRevenueGraph.total.map(el => el.breakdown)
  ) : []
  const colors = getColors(topElementIds)

  return (
    <Container className={classes.root} maxWidth='xl'>
      <Typography variant='h5' display='block' gutterBottom>
        Journey Dashboard
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>

          <Paper className={classes.paper}>
            <Controls
              breakdown={breakdown}
              extendBackend={extendBackend}
              startDate={startDate}
              endDate={endDate}
              onDatesChange={handleDatesChange}
              setBreakdown={setBreakdown}
              setExtendBackend={setExtendBackend}
            />
            <Typography variant='h6' display='block' gutterBottom>
              Revenue
            </Typography>
            {isLoadingRevenueGraph ? (
              <Skeleton variant="rectangular" width={'100%'} height={418} animation='wave' />
            ) : (
              <React.Fragment>
                <Grid container>
                  <RevenueTotals
                    aliases={aliases}
                    colors={colors}
                    data={dataRevenueGraph ? dataRevenueGraph.total : []}
                    isLoading={isLoadingRevenueGraph}
                  />
                </Grid>
                <Grid container>
                  <GraphRevenue
                    aliases={aliases}
                    colors={colors}
                    context={context}
                    data={dataRevenueGraph ? dataRevenueGraph.graph : {}}
                    isLoading={isLoadingRevenueGraph}
                    resolution={resolution}
                    setResolution={setResolution}
                  />
                </Grid>
              </React.Fragment>
            )}
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <NewCustomersSummary
            frontendType={frontendType}
            context={context}
            extendBackend={extendBackend}
          />
        </Grid>

        {topElementIds.length === 0 ? (
          <Grid className={classes.gridIndent} item xs={12}>
            <Typography variant='overline' gutterBottom>
              new customer performance: waiting for analysis...
            </Typography>
          </Grid>
        ) : (
          <Grid className={classes.gridIndent} item xs={12}>
            <Box display='flex' alignItems='center' marginBottom={2}>
              <Typography variant='overline'>
                new customer performance {breakdown && <span>for top <b>{breakdown.name}</b> sorted by </span>}
              </Typography>
              {breakdown && (
                <Select
                  labelId='orderBy-label'
                  id='orderBy-select'
                  value={orderBy}
                  onChange={event => setOrderBy(event.target.value)}
                  size='small'
                  sx={{ marginLeft: 1 }}
                >
                  {orderBySelectItems.map(item =>
                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                  )}
                </Select>
              )}
            </Box>

            <Grid container spacing={2}>
              {topElementIds.map(elementId =>
                <Grid key={elementId} item xs={12}>
                  <AnalysisCard
                    accentColor={colors[elementId]}
                    attribution={attribution}
                    frontendType={frontendType}
                    context={context}
                    extendBackend={extendBackend}
                    filter={breakdown ? { id: breakdown.id, name: breakdown.name, value: elementId, valueName: getAlias(aliases, elementId) } : null}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        )}

        <Grid item xs={12}>
          <BackendSummary
            frontendType={frontendType}
            context={context}
          />
        </Grid>

      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
        open={isError}
        autoHideDuration={10000}
      >
        <Alert severity='error'>
          Error fetching data. Please try again, and if problem continues, contact support.
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default Dashboard