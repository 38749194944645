import React from 'react'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import Snackbar from '@mui/material/Snackbar'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'

import { useHistory, useLocation } from 'react-router-dom'
import { useQuery } from 'react-query'
import queryString from 'query-string'

import { FirebaseContext } from '../../utils/firebase'
import 'firebase/auth'

import { API_ROOT_URL, SITE_ROOT_URL } from '../../constants/'
import * as ROUTES from '../../constants/routes'

const REDIRECT_URI = SITE_ROOT_URL + ROUTES.KEAP_OAUTH

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: 66,
    right: 0,
    width: 'calc(100% - 66px)',
    height: '100vh',
    overflowY: 'auto',
    paddingTop: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
  },
}))

const KeapOauth = () => {
  const classes = useStyles()
  const firebase = React.useContext(FirebaseContext)
  const location = useLocation()
  const history = useHistory()
  const [code, setCode] = React.useState(null)

  React.useEffect(() => {
    const params = queryString.parse(location.search)
    const code = params.code
    setCode(code)
  }, [location.search])

  const { data, isError, isLoading } = useQuery(['keap-oauth', code], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/keap/connect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          code,
          redirectUri: REDIRECT_URI
        })
      }).then(res => res.json())
    }),
    {
      enabled: !!code,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  )

  React.useEffect(() => {
    if (data && data.status === 'connected') {
      history.push(ROUTES.KEAP_SOURCES + `/${data.id}`)
    }
  }, [data])

  return (
    <Container className={classes.root} maxWidth='sm'>
      <Paper>
        <Box padding={2}>
          {isLoading ? (
            <React.Fragment>
              <Typography variant='h5' align='center' gutterBottom>
                Setting Up Keap Integration
              </Typography>
              <LinearProgress color='primary' />
            </React.Fragment>
          ): (!code ? (
              <Box>
                <Typography variant='body2' gutterBottom>
                  No Keap authorization code found in the URL. Click below to return to the settings page.
                </Typography>
                <Button
                  variant='contained'
                  onClick={() => history.push(ROUTES.SETTINGS)}
                >
                  Back to settings
                </Button>
              </Box>
            ): (
              <Typography variant='body2'>
                Redirecting to Keap Integration page...
              </Typography>
            )
          )}
        </Box>
      </Paper>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={isError}
        autoHideDuration={10000}
      >
        <Alert
          severity='error'
          variant='filled'
        >
          Something went wrong. Refresh the page and try again. If the problem persists, return to the Settings page.
        </Alert>
      </Snackbar>
    </Container>
  )
}

export default KeapOauth