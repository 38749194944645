import React from 'react'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import makeStyles from '@mui/styles/makeStyles'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment-timezone'

import { FirebaseContext } from '../../utils/firebase'
import 'firebase/auth'
import { useQuery } from 'react-query'

import Table from './CustomersTable'
import ContactsTable from './ContactsTable'
import PaymentsTable from './PaymentsTable'

import { API_ROOT_URL } from '../../constants/'
import * as ROUTES from '../../constants/routes'

const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: 66,
    right: 0,
    width: 'calc(100% - 66px)',
    height: '100vh',
    overflowY: 'auto',
    paddingTop: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
  },
  paper: {
    padding: theme.spacing(1, 2),
    backgroundColor: 'rgb(232, 244, 253)',
    color: 'rgb(13, 60, 97)',
    display: 'flex',
    alignItems: 'center',
  },
  typographyHeading: {
    marginLeft: theme.spacing(2),
  },
}))

const Person = () => {
  const classes = useStyles()
  const history = useHistory()
  let { id } = useParams()
  const firebase = React.useContext(FirebaseContext)

  const { isLoading, isError, data = {} } = useQuery(['customer', id], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/tables/people/${id}?tz=${TIMEZONE}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then(res => res.json())
    }),
    {
      cacheTime: 15 * 60 * 1000,  // 15 minutes
      staleTime: 15 * 60 * 1000,  // 15 minutes
      refetchOnWindowFocus: false,
    }
  )

  const handleExit = () => {
    const fromState = history.action === 'PUSH' ? history.location.state || {} : {}
    if (fromState.previousRoute) {
      history.push(fromState.previousRoute, history.location.state)
    } else {
      history.push(ROUTES.PEOPLE, history.location.state)
    }
  }

  const contact = data.contact ? [data.contact] : []
  const customer = data.customer ? [data.customer] : []
  const payments = data.payments || []

  return (
    <Container className={classes.root} maxWidth='xl'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.paper} square>
            <Button
              className={classes.exitButton}
              variant='outlined'
              size='small'
              onClick={handleExit}
              startIcon={<ArrowBackIcon />}
            >
              Exit
            </Button>
            <Typography className={classes.typographyHeading} variant='h6'>
              Customer {id}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6' gutterBottom>
            Summary
          </Typography>
          <Table
            page={0}
            order={'desc'}
            orderBy={''}
            isLoading={isLoading}
            isError={isError}
            rows={customer}
            disablePagination
            disableSorting
            disableRowSelect
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6' gutterBottom>
            Contact
          </Typography>
          <ContactsTable
            page={0}
            order={'desc'}
            orderBy={''}
            isLoading={isLoading}
            isError={isError}
            rows={contact}
            disablePagination
            disableSorting
            disableRowSelect
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6' gutterBottom>
            Payments
          </Typography>
          <PaymentsTable
            page={0}
            order={'desc'}
            orderBy={''}
            isLoading={isLoading}
            isError={isError}
            rows={payments}
            disablePagination
            disableSorting
            disableRowSelect
          />
        </Grid>
      </Grid>

    </Container>
  )
}

Person.propTypes = {
}

export default Person
