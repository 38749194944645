// Each function expects an initialized firebase instance with both auth and firestore as first argument

export function getCurrentUserId(firebase) {
  const user = firebase.auth().currentUser
  return user ? user.uid : null
}

export async function createDocument(firebase, collection, data) {
  const uid = getCurrentUserId(firebase)
  if (!uid) throw new Error('No authenticated user')
  const docRef = await firebase.firestore().collection(collection).add({ ...data, uid })
  return docRef.id
}

export async function readDocument(firebase, collection, docId) {
  const uid = getCurrentUserId(firebase)
  if (!uid) throw new Error('No authenticated user')
  const doc = await firebase.firestore().collection(collection).doc(docId).get()
  if (doc.exists && doc.data().uid === uid) {
    return { id: doc.id, ...doc.data() }
  } else {
    throw new Error('Document not found or unauthorized')
  }
}

export async function readDocumentWithoutUidFilter(firebase, collection, docId) {
  const uid = getCurrentUserId(firebase)
  if (!uid) throw new Error('No authenticated user')
  const doc = await firebase.firestore().collection(collection).doc(docId).get()
  if (doc.exists) {
    return { id: doc.id, ...doc.data() }
  } else {
    throw new Error('Document not found or unauthorized')
  }
}

export async function getCollectionDocuments(firebase, collection) {
  const uid = getCurrentUserId(firebase)
  if (!uid) throw new Error('No authenticated user')
  const snapshot = await firebase.firestore().collection(collection).where('uid', '==', uid).get()
  return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
}

export async function getFirestoreDocumentsByIds(firebase, collection, ids) {
  const uid = getCurrentUserId(firebase)
  if (!uid) throw new Error('No authenticated user')

  const reference = firebase.firestore().collection(collection)
  const docRefs = ids.map(id => reference.doc(id))
  const snapshot = await Promise.all(docRefs.map(ref => ref.get()))

  return snapshot.map(doc => {
    if (doc.exists && doc.data().uid === uid) {
      return { id: doc.id, ...doc.data() }
    } else {
      throw new Error('Document not found or unauthorized')
    }
  })
}

export async function updateDocument(firebase, collection, docId, data) {
  const uid = getCurrentUserId(firebase)
  if (!uid) throw new Error('No authenticated user')
  const doc = await firebase.firestore().collection(collection).doc(docId).get()
  if (doc.exists && doc.data().uid === uid) {
    await firebase.firestore().collection(collection).doc(docId).update(data)
  } else {
    throw new Error('Document not found or unauthorized')
  }
}

export async function updateDocumentWithoutUidFilter(firebase, collection, docId, data) {
  const uid = getCurrentUserId(firebase)
  if (!uid) throw new Error('No authenticated user')
  const doc = await firebase.firestore().collection(collection).doc(docId).get()
  if (doc.exists) {
    await firebase.firestore().collection(collection).doc(docId).update(data)
  } else {
    throw new Error('Document not found or unauthorized')
  }
}

export async function deleteDocument(firebase, collection, docId) {
  const uid = getCurrentUserId(firebase)
  if (!uid) throw new Error('No authenticated user')
  const doc = await firebase.firestore().collection(collection).doc(docId).get()
  if (doc.exists && doc.data().uid === uid) {
    await firebase.firestore().collection(collection).doc(docId).delete()
  } else {
    throw new Error('Document not found or unauthorized')
  }
}

export function listenToCollectionUpdates(firebase, collection, callback, whereClause = [], orderByClause = []) {
  const uid = getCurrentUserId(firebase)
  if (!uid) throw new Error('No authenticated user')
  let query = firebase.firestore().collection(collection).where('uid', '==', uid)
  if (whereClause.length) query = query.where(...whereClause)
  if (orderByClause.length) query = query.orderBy(...orderByClause)
  return query.onSnapshot(callback)
}