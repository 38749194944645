import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'

import { FirebaseContext } from '../../utils/firebase'
import 'firebase/auth'

import { useQuery } from 'react-query'

import { API_ROOT_URL } from '../../constants/'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: 66,
    right: 0,
    width: 'calc(100% - 66px)',
    height: '100vh',
    overflowY: 'auto',
    paddingTop: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
    [theme.breakpoints.down('md')]: {
      left: 0,
      width: '100vw',
      paddingBottom: 86,
    },
  },
}))

const Admin = () => {
  const classes = useStyles()
  const firebase = React.useContext(FirebaseContext)
  const [email, setEmail] = React.useState('')
  const [name, setName] = React.useState('')

  const { refetch: handleCreateUser, isLoading, data } = useQuery(['create-user', email, name], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ email, name })
      }).then(res => res.json())
    }),
    {
      enabled: false,
      retry: false,
      cacheTime: 0,
      staleTime: 0,
      refetchOnWindowFocus: false,
    }
  )

  const handleChangeEmail = (event) => {
    setEmail(event.target.value)
  }

  const handleChangeName = (event) => {
    setName(event.target.value)
  }

  return (
    <Container className={classes.root} maxWidth='md'>
      <Paper>
        <Box padding={2}>
          <Typography variant='h5' gutterBottom>
            Create User
          </Typography>
          <Box>
            <TextField
              variant='outlined'
              label='email'
              size='small'
              fullWidth
              value={email}
              onChange={handleChangeEmail}
            />
          </Box>
          <Box marginTop={2}>
            <TextField
              variant='outlined'
              label='name'
              size='small'
              fullWidth
              value={name}
              onChange={handleChangeName}
            />
          </Box>
          <Box marginTop={2}>
            <Button
              variant='contained'
              onClick={handleCreateUser}
            >
              {isLoading ? <CircularProgress color='inherit' size={24} /> : "Create"}
            </Button>
          </Box>
          {data && (
            <Box marginTop={2}>
              <Typography variant='h6' gutterBottom>
                Data to save
              </Typography>
              <Typography variant='body1' gutterBottom>
                id = {data.id}
              </Typography>
              <Typography variant='body1' gutterBottom>
                uid = {data.uid}
              </Typography>
              <Typography variant='body1' gutterBottom>
                password = {data.password}
              </Typography>
            </Box>
          )}
        </Box>
      </Paper>
    </Container>
  )
}

export default Admin