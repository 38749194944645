import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'

import { formatNumber } from '../../../utils/helpers'

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
  },
  valueTypography: {
    lineHeight: 1,
  },
  keyTypography: {
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
  },
}))

const MetricCard = (props) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Typography className={classes.valueTypography} variant='body1'>
        {`${props.data.showDollar ? '$': ''}`}{formatNumber(props.data.value, props.data.decimalCount, false)}
      </Typography>
      <Typography className={classes.keyTypography} variant='caption'>
        {props.data.name}
      </Typography>
    </div>
  )
}
MetricCard.propTypes = {
  data: PropTypes.object.isRequired
}

export default MetricCard
