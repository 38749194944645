import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'

import { FirebaseContext } from '../../../utils/firebase'
import 'firebase/auth'
import { useQuery } from 'react-query'

import MetricCard from '../MetricCard'

import { API_ROOT_URL } from '../../../constants'

const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  gridContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  }
}))

const metrics = [
  {id: 'customers', name: 'Frontend Customers', decimalCount: 0, showDollar: false, allowedAttribution: ['customer'] },
  {id: 'leads', name: 'Frontend Leads', decimalCount: 0, showDollar: false, allowedAttribution: ['lead'] },
  {id: 'revenue', name: 'Frontend Revenue', decimalCount: 2, showDollar: true, allowedAttribution: ['customer', 'lead'] },
  {id: 'ltv_0', name: 'Day 0 LTV', decimalCount: 2, showDollar: true, allowedAttribution: ['customer', 'lead'] },
  {id: 'ltv_90', name: 'Day 90 LTV', decimalCount: 2, showDollar: true, allowedAttribution: ['customer', 'lead'] },
  {id: 'ltr', name: 'Lifetime Revenue', decimalCount: 2, showDollar: true, allowedAttribution: ['customer', 'lead'] },
]

const PathAnalysisSummaryMetrics = (props) => {
  const { attribution, context, frontendType, extendBackend } = props

  const classes = useStyles()
  const firebase = React.useContext(FirebaseContext)

  const { isLoading, data: frontendMetrics = {} } = useQuery(['path-analysis-summary-metrics', attribution, context, frontendType, extendBackend], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/tables/path_analysis_summary`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          attribution,
          startDate: context.dates.start.format(),
          endDate: context.dates.end.format(),
          timezone: TIMEZONE,
          breakdown: context.breakdown,
          filters: context.filters,
          frontendType,
          extendBackend
        })
      }).then(res => res.json())
    }),
    {
      enabled: !!context.dates.start && !!context.dates.end,
      cacheTime: 15 * 60 * 1000,  // 15 minutes
      staleTime: 15 * 60 * 1000,  // 15 minutes
      refetchOnWindowFocus: false,
    }
  )

  return (
    <Paper className={classes.root}>
      {isLoading ? <Skeleton variant="rectangular" />
      : <Grid className={classes.gridContainer} container justifyContent='space-evenly' spacing={3}>
          {metrics.filter(item => item.allowedAttribution.indexOf(attribution) > -1).map((metric, index) => {
            const data = {
              ...metric,
              value: frontendMetrics[metric.id] || 0
            }
            return (
              <Grid item key={metric.id} xs={4} sm={3} md={2} lg={2}>
                <MetricCard key={index} data={data} />
              </Grid>
            )
          })}
        </Grid>
      }
    </Paper>
  );
}

PathAnalysisSummaryMetrics.propTypes = {
  attribution: PropTypes.string.isRequired,
  context: PropTypes.object.isRequired,
  frontendType: PropTypes.string.isRequired,
  extendBackend: PropTypes.bool,
}

export default PathAnalysisSummaryMetrics
