import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import makeStyles from '@mui/styles/makeStyles'

import LabelsMenu from './LabelsMenu'
import SearchInput from '../../Common/SearchInput'
import ColumnsSelect from './ColumnsSelect'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 2, 2, 2),
  },
  controlForm: {
    marginLeft: theme.spacing(2)
  }
}))

const TableControls = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <LabelsMenu
        breakdown={props.breakdown}
        setLabels={props.setLabels}
        selectedLabel={props.selectedLabel}
        setSelectedLabel={props.setSelectedLabel}
      />
      <SearchInput value={props.searchText} onChange={props.onSearchTextChange} addMarginLeft={true} />
      <FormControl className={classes.controlForm} margin='dense' size='small'>
        <FormControlLabel
          control={
            <Switch
              checked={props.showActiveOnly}
              onChange={() => props.onShowActiveOnlyChange(!props.showActiveOnly)}
              name='showActiveOnly'
              color='primary'
            />
          }
          label='Show Active Rows Only'
        />
      </FormControl>
      <div style={{ flexGrow: 1 }} />
      {props.showColumnsSelect &&
        <ColumnsSelect
          columns={props.columns}
          setColumns={props.setColumns}
        />
      }
    </div>
  )
}

TableControls.propTypes = {
  breakdown: PropTypes.object,
  setLabels: PropTypes.func.isRequired,
  selectedLabel: PropTypes.object,
  setSelectedLabel: PropTypes.func.isRequired,
  searchText: PropTypes.string,
  onSearchTextChange: PropTypes.func.isRequired,
  showActiveOnly: PropTypes.bool.isRequired,
  onShowActiveOnlyChange: PropTypes.func.isRequired,
  showColumnsSelect: PropTypes.bool,
  columns: PropTypes.string.isRequired,
  setColumns: PropTypes.func.isRequired,
}

export default TableControls
