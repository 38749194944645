import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import makeStyles from '@mui/styles/makeStyles'

import GraphLTV from '../Graph/GraphLTV'
import GraphLTVGain from '../Graph/GraphLTVGain'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  formControl: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'stretch',
    },
  },
  selectOverride: {
    padding: '8px 12px',
  },
}))

const graphItems = [
  { id: 'ltv', name: 'Lifetime Value', allowedAttribution: ['customer', 'lead'] },
  { id: 'ltvGain', name: 'Lifetime Value Gain', allowedAttribution: ['customer', 'lead'] },
]

const GraphContainer = (props) => {
  const classes = useStyles()
  const [graphId, setGraphId] = React.useState(props.graphId)

  const filteredGraphItems = graphItems.filter(item => item.allowedAttribution.indexOf(props.attribution) > -1)
  let selectedGraphId = graphId
  if (filteredGraphItems.map(item => item.id).indexOf(graphId) === -1) {
    selectedGraphId = 'ttfo'
  }
  return (
    <Paper className={classes.root}>
      <FormControl className={classes.formControl} variant='outlined' >
        <Select
          className={classes.select}
          labelId='graph-select-label'
          id='graph-select'
          value={selectedGraphId}
          onChange={(event) => setGraphId(event.target.value)}
          classes={{ select: classes.selectOverride }}
        >
          {filteredGraphItems.map(graph =>
            <MenuItem key={graph.id} value={graph.id}>{graph.name}</MenuItem>
          )}
        </Select>
      </FormControl>
      <GraphSelector
        graphId={selectedGraphId}
        attribution={props.attribution}
        frontendContext={props.frontendContext}
        backendContext={props.backendContext}
        frontendType={props.frontendType}
        extendBackend={props.extendBackend}
        frontendColors={props.frontendColors}
        backendColors={props.backendColors}
      />
    </Paper>
  )
}

const GraphSelector = (props) => {
  switch (props.graphId) {
    case 'ltv':
      return (
        <GraphLTV
          attribution={props.attribution}
          context={props.frontendContext}
          extendBackend={props.extendBackend}
          colors={props.frontendColors}
        />
      )
    case 'ltvGain':
      return (
        <GraphLTVGain
          attribution={props.attribution}
          context={props.backendContext}
          frontendType={props.frontendType}
          extendBackend={props.extendBackend}
          colors={props.backendColors}
        />
      )

    default:
      return null
  }
}

GraphContainer.propTypes = {
  attribution: PropTypes.string.isRequired,
  graphId: PropTypes.string.isRequired,
  frontendContext: PropTypes.object.isRequired,
  backendContext: PropTypes.object.isRequired,
  frontendType: PropTypes.string.isRequired,
  extendBackend: PropTypes.bool,
  frontendColors: PropTypes.object.isRequired,
  backendColors: PropTypes.object.isRequired,
}

export default GraphContainer
