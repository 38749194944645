import React, { useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import LastPageIcon from '@mui/icons-material/LastPage'
import { alpha, darken } from '@mui/material/styles'
import { makeStyles, useTheme } from '@mui/styles'
import { visuallyHidden } from '@mui/utils'
import moment from 'moment-timezone'
import { useIsFetching } from 'react-query'

import { UserContext } from '../../contexts/UserContext'

import ColumnsSelect from './ColumnsSelect'
import CustomColorSwitch from './CustomColorSwitch'
import DarkTooltip from './DarkTooltip'
import LoadingSkeleton from './LoadingSkeleton'
import METRIC_ITEMS_MAP from './MetricItems'
import NoDataBox from './NoDataBox'
import PageSizeSelect from './PageSizeSelect'
import SearchInput from './SearchInput'
import {
  getBreakdownAlias,
  getBreakdownAliasPlatformId,
  getCohortBreakdownResolution,
  getDisplayValueByFormat,
  handleEmptyData,
  isCohortBreakdown,
  getDateRangeDayCount,
  getBreakdownAliasSecondary,
  shortenString,
} from '../../utils/helpers'
import {
  TABLE_CHART_TAB_KEY,
  TABLE_MILESTONES_TAB_KEY,
  TABLE_SUMMARY_TAB_KEY,
} from '../../constants'

const TABLE_PLACEHOLDER_HEIGHT = 100
const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const useStyles = makeStyles(theme => ({
  tableCellHead1: {
    position: 'sticky',
    left: 0,
    zIndex: 100,
    backgroundColor: '#DCE3EB',
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    fontSize: 13,
    fontWeight: 'bold',
    whiteSpace: 'normal',
    lineHeight: 1.2,
    width: 200,
    maxWidth: 300,
    height: 40,
  },
  tableCellHead2: {
    fontSize: 13,
    fontWeight: 'bold',
    lineHeight: 1.2,
    whiteSpace: 'normal',
    height: 40,
    minWidth: 130,
    borderRight: `1px solid ${theme.palette.divider}`,
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  tableCellBody1: {
    position: 'sticky',
    left: 0,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    width: 200,
    maxWidth: 300,
    height: 40,
    cursor: 'default',
  },
  tableCellBody1Inner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    columnGap: theme.spacing(1),
  },
  tableCellBody2: {
    color: theme.palette.text.primary,
    height: 40,
    borderRight: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    whiteSpace: 'nowrap',
  },
}))

const DataTableWrapper = ({
  parentKey,
  xKey,
  xKeyAsRange,
  metricItem,
  defaultTab,
  showSummaryTab,
  showChartTab,
  showMilestonesTab,
  summaryColumns,
  emptyDataRule,
  startDate,
  endDate,
  resolution,
  config,
  showCumulative,
  segments,
  segmentsData,
  segmentsBreakdownData,
  aliases,
  chartHoverIndex,
  onSegmentIsActiveChange,
  onSegmentBreakdownIsActiveChange,
  onChartHoverIndexChange,
}) => {
  const fetchingQueryCount = useIsFetching()
  const [tab, setTab] = React.useState(defaultTab)
  const [summaryColumnsKey, setSummaryColumnsKey] = React.useState(showSummaryTab ? 'default' : null)
  const [order, setOrder] = React.useState(() => {
    if (defaultTab === TABLE_SUMMARY_TAB_KEY) {
      return summaryColumns[summaryColumnsKey]?.order
    } else {
      return 'asc'
    }
  })
  const [orderBy, setOrderBy] = React.useState(() => {
    if (defaultTab === TABLE_SUMMARY_TAB_KEY) {
      return summaryColumns[summaryColumnsKey]?.orderBy
    } else {
      return 'name'
    }
  })
  const [sortTab, setSortTab] = React.useState(defaultTab)
  const [pages, setPages] = React.useState({})
  const [pageSize, setPageSize] = React.useState(5)
  const [breakdownsOpen, setBreakdownsOpen] = React.useState({})
  const [showActiveOnly, setShowActiveOnly] = React.useState(false)
  const [searchText, setSearchText] = React.useState('')
  const [isSearchExpanded, setIsSearchExpanded] = React.useState(false)

  // Safety checks and defaults when changing parentKey
  useEffect(() => {
    // Show the default tab
    setTab(defaultTab)

    // Reset the summary columns key and column sorting to default
    if (defaultTab === TABLE_SUMMARY_TAB_KEY) {
      setSummaryColumnsKey('default')
      setOrder(summaryColumns['default']?.order)
      setOrderBy(summaryColumns['default']?.orderBy)
    } else {
      setOrder('asc')
      setOrderBy('name')
    }
  }, [parentKey])

  useEffect(() => {
    // When switching to the summary tab, check if orderBy is a summary column.
    // If not, set to report default
    if (tab === TABLE_SUMMARY_TAB_KEY) {
      let isSummarySortColumn = false
      switch (summaryColumns[summaryColumnsKey].type) {
        case 'metrics':
          isSummarySortColumn = summaryColumns[summaryColumnsKey]?.items.some(i => i.key === orderBy)
          break
        case 'milestones':
          isSummarySortColumn = summaryColumns[summaryColumnsKey]?.items.some(i => i.metricKey === orderBy)
          break
      }
      if (!isSummarySortColumn && defaultTab === TABLE_SUMMARY_TAB_KEY) {
        setOrder(summaryColumns[summaryColumnsKey]?.order)
        setOrderBy(summaryColumns[summaryColumnsKey]?.orderBy)
        setSortTab(TABLE_SUMMARY_TAB_KEY)
      }
    }
  }, [tab])

  useEffect(() => {
    // When switching summary columns, reset the sorting to the default for that summary column set
    if (tab === TABLE_SUMMARY_TAB_KEY) {
      setOrder(summaryColumns[summaryColumnsKey]?.order)
      setOrderBy(summaryColumns[summaryColumnsKey]?.orderBy)
    }
  }, [summaryColumnsKey])

  const getSummaryMetricsTableData = (summaryColumnConfig, segments, segmentsData, segmentsBreakdownData, aliases) => {
    let loadedSegmentSummaryData = segmentsData.find(segmentData =>
      !segmentData.isLoading &&
      segmentData.data.summary &&
      Object.keys(segmentData.data.summary).length > 0
    )?.data.summary;

    if (!loadedSegmentSummaryData) return null
    if (Object.keys(loadedSegmentSummaryData).length === 0) return null

    let head = [
      {
        key: 'name',
        name: summaryColumnConfig.name,
        format: 'string',
      },
      ...summaryColumnConfig.items,
    ]

    let body = []
    let breakdownBodyMap = {}
    segments.forEach((segment, segmentIndex) => {
      // Add segment data to the body
      const segmentData = segmentsData[segmentIndex]
      let row = {
        key: segment.id,
        name: segment.name,
      }
      if (segmentData.isLoading) {
        body.push(row)
      } else {
        const segmentSummaryData = segmentData.data.summary
        body.push({
          ...row,
          ...summaryColumnConfig.items.reduce((acc, item) => {
            acc[item.key] = segmentSummaryData?.[item.key] || null
            return acc
          }, {})
        })

        // Add segment breakdown data, if present, to the breakdownBodyMap
        const segmentBreakdownData = segmentsBreakdownData[segmentIndex]
        if (segment.breakdown && !segmentBreakdownData.isLoading) {
          const segmentBreakdownSummaryData = segmentBreakdownData.data.summary
          let breakdownRows = []

          segmentBreakdownData.data.breakdownKeys.forEach(breakdownKey => {
            let row = {}

            if (isCohortBreakdown(segment.breakdown)) {
              const breakdownResolution = getCohortBreakdownResolution(segment.breakdown)
              row = {
                key: breakdownKey,
                name: breakdownKey,
                displayName: getDisplayValueByFormat(
                  breakdownKey,
                  'date_time',
                  {
                    resolution: breakdownResolution,
                    timezone: TIMEZONE,
                  }
                )
              }
            } else {
              row = {
                key: breakdownKey,
                name: getBreakdownAlias(aliases, segment.breakdown.field, breakdownKey),
                displayName: getBreakdownAlias(aliases, segment.breakdown.field, breakdownKey),
                secondary: getBreakdownAliasSecondary(aliases, segment.breakdown.field, breakdownKey),
                platform_id: getBreakdownAliasPlatformId(aliases, segment.breakdown.field, breakdownKey),
              }
            }

            summaryColumnConfig.items.forEach(item => {
              row[item.key] = segmentBreakdownSummaryData.breakdowns[breakdownKey][item.key]
            })

            breakdownRows.push(row)
          })
          breakdownBodyMap[segment.id] = breakdownRows
        }
      }
    })

    return { head, body, breakdownBodyMap }
  }

  const getSummaryMilestonesTableData = (summaryColumnConfig, startDate, endDate, config, segments, segmentsData, segmentsBreakdownData, aliases) => {
    let loadedSegmentMilestonesData = segmentsData.find(segmentData =>
      !segmentData.isLoading &&
      segmentData.data.milestones &&
      Object.keys(segmentData.data.milestones).length > 0
    )?.data.milestones

    if (!loadedSegmentMilestonesData) return null
    if (Object.keys(loadedSegmentMilestonesData).length === 0) return null

    const maxMilestoneDay = getDateRangeDayCount(startDate, endDate, TIMEZONE, config.extendBackend)

    let head = [
      {
        key: 'name',
        name: summaryColumnConfig.name,
        format: 'string',
      }
    ]
    summaryColumnConfig.items.forEach(item => {
      item.milestoneDays.forEach(day => {
        // If day is the special 'max' value, use a dynamic value for the max day
        // based on the largest returned value from the API data
        if (day === 'max') day = maxMilestoneDay

        // If day is greater than the maxMilestoneDay, skip
        if (day > maxMilestoneDay) return

        // Check if column for day has already been added.
        // This is the case when a milestone day happens to be equal to the maxMilestoneDay.
        if (head.find(column => column.key === `${item.metricKey}-${day}`)) return

        head.push({
          key: `${item.metricKey}-${day}`,
          name: item.concatDay ? `${item.name} Day ${day}` : item.name,
          format: METRIC_ITEMS_MAP[item.metricKey].format,
        })
      })
    })

    let body = []
    let breakdownBodyMap = {}
    segments.forEach((segment, segmentIndex) => {
      // Add segment data to the body
      const segmentData = segmentsData[segmentIndex]
      let row = {
        key: segment.id,
        name: segment.name,
      }
      if (segmentData.isLoading) {
        body.push(row)
      } else {
        const segmentMilestonesData = segmentData.data.milestones
        if (Object.keys(segmentMilestonesData).length === 0) return

        summaryColumnConfig.items.forEach(item => {
          item.milestoneDays.forEach(day => {
            // If day is the special 'max' value, use a dynamic value for the max day
            // based on the largest returned value from the API data
            if (day === 'max') day = maxMilestoneDay

            // If day is greater than the maxMilestoneDay, skip
            if (day > maxMilestoneDay) return

            const key = `${item.metricKey}-${day}`
            const value = segmentMilestonesData[day][item.metricKey]
            row[key] = value
          })
        })
        body.push(row)

        // Add segment breakdown data, if present, to the breakdownBodyMap
        const segmentBreakdownData = segmentsBreakdownData[segmentIndex]
        if (segment.breakdown && !segmentBreakdownData.isLoading) {
          const segmentBreakdownMilestonesData = segmentBreakdownData.data.milestones
          let breakdownRows = []

          segmentBreakdownData.data.breakdownKeys.forEach(breakdownKey => {
            let row = {}

            if (isCohortBreakdown(segment.breakdown)) {
              const breakdownResolution = getCohortBreakdownResolution(segment.breakdown)
              row = {
                key: breakdownKey,
                name: breakdownKey,
                displayName: getDisplayValueByFormat(
                  breakdownKey,
                  'date_time',
                  {
                    resolution: breakdownResolution,
                    timezone: TIMEZONE,
                  }
                )
              }
            } else {
              row = {
                key: breakdownKey,
                name: getBreakdownAlias(aliases, segment.breakdown.field, breakdownKey),
                displayName: getBreakdownAlias(aliases, segment.breakdown.field, breakdownKey),
                secondary: getBreakdownAliasSecondary(aliases, segment.breakdown.field, breakdownKey),
                platform_id: getBreakdownAliasPlatformId(aliases, segment.breakdown.field, breakdownKey),
              }
            }

            summaryColumnConfig.items.forEach(item => {
              item.milestoneDays.forEach(day => {

                // If day is the special 'max' value, use a dynamic value for the max day
                // based on the largest returned value from the API data
                if (day === 'max') day = maxMilestoneDay

                // If day is greater than the maxMilestoneDay, skip
                if (day > maxMilestoneDay) return

                const key = `${item.metricKey}-${day}`
                const value = segmentBreakdownMilestonesData[day].breakdowns[breakdownKey][item.metricKey]
                row[key] = value
              })
            })

            breakdownRows.push(row)
          })
          breakdownBodyMap[segment.id] = breakdownRows
        }
      }
    })

    return { head, body, breakdownBodyMap }
  }

  const getMilestonesTableData = (xKey, metricItem, resolution, segments, segmentsData, segmentsBreakdownData, aliases) => {
    let loadedSegmentMilestonesDataMap = segmentsData.find(segmentData =>
      !segmentData.isLoading &&
      segmentData.data.milestones &&
      Object.keys(segmentData.data.milestones).length > 0
    )?.data.milestones

    if (!loadedSegmentMilestonesDataMap) return null
    if (Object.keys(loadedSegmentMilestonesDataMap).length === 0) return null

    const loadedSegmentMilestonesData = Object.values(loadedSegmentMilestonesDataMap)
    if (loadedSegmentMilestonesData.length === 0) return null

    let head = [
      {
        key: 'name',
        name: metricItem.name,
        format: 'string',
      },
      ...loadedSegmentMilestonesData.map(data => {
        return ({
          key: data[xKey],
          name: getDisplayValueByFormat(
            data[xKey],
            xKey,
            { resolution, timezone: TIMEZONE, xKeyAsRange }
          ),
          format: metricItem.format,
        })
      })
    ]

    let body = []
    let breakdownBodyMap = {}
    segments.forEach((segment, segmentIndex) => {
      // Add segment data to the body
      const segmentData = segmentsData[segmentIndex]
      let row = {
        key: segment.id,
        name: segment.name,
      }
      if (segmentData.isLoading) {
        body.push(row)
      } else {
        const segmentMilestonesDataMap = segmentData.data.milestones
        const segmentMilestonesData = Object.values(segmentMilestonesDataMap)

        body.push({
          ...row,
          ...segmentMilestonesData.reduce((acc, data) => {
            const value = data[metricItem.key]
            acc[data[xKey]] = value
            return acc
          }, {})
        })

        // Add segment breakdown data, if present, to the breakdownBodyMap
        const segmentBreakdownData = segmentsBreakdownData[segmentIndex]
        if (segment.breakdown && !segmentBreakdownData.isLoading) {
          const segmentBreakdownMilestonesDataMap = segmentBreakdownData.data.milestones
          const segmentBreakdownMilestonesData = Object.values(segmentBreakdownMilestonesDataMap)

          let breakdownRows = []

          segmentBreakdownData.data.breakdownKeys.forEach(breakdownKey => {
            let row = {}

            if (isCohortBreakdown(segment.breakdown)) {
              const breakdownResolution = getCohortBreakdownResolution(segment.breakdown)
              const cohortDayRange = getDateRangeDayCount(breakdownKey, endDate, TIMEZONE, config.extendBackend)

              row = {
                key: breakdownKey,
                name: breakdownKey,
                displayName: getDisplayValueByFormat(
                  breakdownKey,
                  'date_time',
                  {
                    resolution: breakdownResolution,
                    timezone: TIMEZONE,
                  }
                ),
                ...segmentBreakdownMilestonesData.reduce((acc, data) => {
                  // Check if this xKey (day) value is in the range of the cohort,
                  // and if not, leave it empty
                  if (data[xKey] > cohortDayRange) return acc

                  let value = data.breakdowns[breakdownKey]?.[metricItem.key]
                  acc[data[xKey]] = value
                  return acc
                }, {})
              }
            } else {
              row = {
                key: breakdownKey,
                name: getBreakdownAlias(aliases, segment.breakdown.field, breakdownKey),
                displayName: getBreakdownAlias(aliases, segment.breakdown.field, breakdownKey),
                secondary: getBreakdownAliasSecondary(aliases, segment.breakdown.field, breakdownKey),
                platform_id: getBreakdownAliasPlatformId(aliases, segment.breakdown.field, breakdownKey),
                ...segmentBreakdownMilestonesData.reduce((acc, data) => {
                  let value = data.breakdowns[breakdownKey]?.[metricItem.key]
                  acc[data[xKey]] = value
                  return acc
                }, {})
              }
            }

            breakdownRows.push(row)
          })
          breakdownBodyMap[segment.id] = breakdownRows
        }
      }
    })

    return { head, body, breakdownBodyMap }
  }

  const getChartTableData = (xKey, metricItem, resolution, segments, segmentsData, segmentsBreakdownData, emptyDataRule, aliases, cumulative) => {
    let loadedSegmentChartData = segmentsData.find(segmentData =>
      !segmentData.isLoading &&
      segmentData.data.chart &&
      segmentData.data.chart.length > 0
    )?.data.chart

    if (!loadedSegmentChartData) return null
    if (loadedSegmentChartData.length === 0) return null

    let head = [
      {
        key: 'name',
        name: cumulative && metricItem.allowCumulative ? `${metricItem.cumulativeName || metricItem.name} (cumulative)` : metricItem.name,
        format: 'string',
      },
      ...loadedSegmentChartData.map(data => {
        return ({
          key: data[xKey],
          name: getDisplayValueByFormat(
            data[xKey],
            xKey,
            { resolution, timezone: TIMEZONE, xKeyAsRange }
          ),
          format: metricItem.format,
        })
      })
    ]

    let body = []
    let breakdownBodyMap = {}
    segments.forEach((segment, segmentIndex) => {
      // Add segment data to the body
      const segmentData = segmentsData[segmentIndex]
      let row = {
        key: segment.id,
        name: segment.name,
      }
      if (segmentData.isLoading) {
        body.push(row)
      } else {
        let segmentChartData = segmentData.data.chart

        body.push({
          ...row,
          ...segmentChartData.reduce((acc, data, dataIndex) => {
            const xKeyValue = data[xKey]
            let value = data[metricItem.key]

            // Set value for the non-cumulative case, or for the first data point of the cumulative case
            if (!cumulative || dataIndex === 0) {
              // If value exists in the data point, set it
              if (value !== undefined && value !== null) {
                acc[xKeyValue] = value
              }
              // Otherwise, set it based on the emptyDataRule
              else {
                acc[xKeyValue] = handleEmptyData(dataIndex, metricItem.key, null, segmentChartData, emptyDataRule)
              }
            }
            // Accumulate value by adding it to the previous data point's value
            else {
              const previousDataPoint = segmentChartData[dataIndex - 1]
              const previousXKeyValue = previousDataPoint[xKey]
              const previousValue = acc[previousXKeyValue]

              // If value exists, add it to the previous value
              if (value !== undefined && value !== null) {
                acc[xKeyValue] = value + previousValue
              }
              // If the value doesn't exist, set value to the previous value
              else {
                acc[xKeyValue] = previousValue
              }
            }
            return acc
          }, {})
        })

        // Add segment breakdown data, if present, to the breakdownBodyMap
        const segmentBreakdownData = segmentsBreakdownData[segmentIndex]
        if (segment.breakdown && !segmentBreakdownData.isLoading) {
          let segmentBreakdownChartData = segmentBreakdownData.data.chart
          let breakdownRows = []

          segmentBreakdownData.data.breakdownKeys.forEach(breakdownKey => {
            let row = {}

            if (isCohortBreakdown(segment.breakdown)) {
              const breakdownResolution = getCohortBreakdownResolution(segment.breakdown)
              const cohortDayRange = getDateRangeDayCount(breakdownKey, endDate, TIMEZONE, config.extendBackend)

              row = {
                key: breakdownKey,
                name: breakdownKey,
                displayName: getDisplayValueByFormat(
                  breakdownKey,
                  'date_time',
                  {
                    resolution: breakdownResolution,
                    timezone: TIMEZONE,
                  }
                ),
                ...segmentBreakdownChartData.reduce((acc, data, dataIndex) => {
                  const xKeyValue = data[xKey]

                  // Check if this xKey (day) value is in the range of the cohort,
                  // and if not, leave it empty
                  if (xKeyValue > cohortDayRange) return acc

                  let value = data.breakdowns[breakdownKey]?.[metricItem.key]

                  // Set value for the non-cumulative case, or for the first data point of the cumulative case
                  if (!cumulative || dataIndex === 0) {
                    // If value exists in the data point, set it
                    if (value !== undefined && value !== null) {
                      acc[xKeyValue] = value
                    }
                    // Otherwise, set it based on the emptyDataRule
                    else {
                      acc[xKeyValue] = handleEmptyData(dataIndex, metricItem.key, breakdownKey, segmentBreakdownChartData, emptyDataRule)
                    }
                  }
                  // Accumulate value by adding it to the previous data point's value
                  else {
                    const previousDataPoint = segmentChartData[dataIndex - 1]
                    const previousXKeyValue = previousDataPoint[xKey]
                    const previousValue = acc[previousXKeyValue]

                    // If value exists, add it to the previous value
                    if (value !== undefined && value !== null) {
                      acc[xKeyValue] = value + previousValue
                    }
                    // If the value doesn't exist, set value to the previous value
                    else {
                      acc[xKeyValue] = previousValue
                    }
                  }
                  return acc
                }, {})
              }
            } else {
              row = {
                key: breakdownKey,
                name: getBreakdownAlias(aliases, segment.breakdown.field, breakdownKey),
                displayName: getBreakdownAlias(aliases, segment.breakdown.field, breakdownKey),
                secondary: getBreakdownAliasSecondary(aliases, segment.breakdown.field, breakdownKey),
                platform_id: getBreakdownAliasPlatformId(aliases, segment.breakdown.field, breakdownKey),
                ...segmentBreakdownChartData.reduce((acc, data, dataIndex) => {
                  const xKeyValue = data[xKey]
                  let value = data.breakdowns[breakdownKey]?.[metricItem.key]

                  // Set value for the non-cumulative case, or for the first data point of the cumulative case
                  if (!cumulative || dataIndex === 0) {
                    // If value exists in the data point, set it
                    if (value !== undefined && value !== null) {
                      acc[xKeyValue] = value
                    }
                    // Otherwise, set it based on the emptyDataRule
                    else {
                      acc[xKeyValue] = handleEmptyData(dataIndex, metricItem.key, breakdownKey, segmentBreakdownChartData, emptyDataRule)
                    }
                  }
                  // Accumulate value by adding it to the previous data point's value
                  else {
                    const previousDataPoint = segmentChartData[dataIndex - 1]
                    const previousXKeyValue = previousDataPoint[xKey]
                    const previousValue = acc[previousXKeyValue]

                    // If value exists, add it to the previous value
                    if (value !== undefined && value !== null) {
                      acc[xKeyValue] = value + previousValue
                    }
                    // If the value doesn't exist, set value to the previous value
                    else {
                      acc[xKeyValue] = previousValue
                    }
                  }
                  return acc
                }, {})
              }
            }

            breakdownRows.push(row)
          })
          breakdownBodyMap[segment.id] = breakdownRows
        }
      }
    })

    return { head, body, breakdownBodyMap }
  }

  const formatTableDataAsCSV = (tableData) => {
    let csvData = []

    // add the head row
    csvData.push(tableData.head.map(column => {
      // Regular expression to match the datetime format
      const dateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}-\d{2}:\d{2}$/
      if (dateTimeRegex.test(column.key)) {
        return column.key
      }
      return column.name
    }))

    // add the body rows
    tableData.body.forEach(row => {
      if (row) {
        csvData.push(tableData.head.map(column => row[column.key]))
      }
      // add the breakdown rows underneath this row
      if (tableData.breakdownBodyMap[row.key]) {
        tableData.breakdownBodyMap[row.key].forEach(breakdownRow => {
          csvData.push(tableData.head.map(column => breakdownRow[column.key]))
        })
      }
    })
    return csvData.map(row => row.join(',')).join('\n')
  }

  const handleExportToCSV = (tableData) => {
    const csvData = formatTableDataAsCSV(tableData)
    const filename = `ltvnumbers_table_${tab}_${tab !== TABLE_SUMMARY_TAB_KEY ? `${metricItem.key}_` : ''}${moment.tz(TIMEZONE).format('YYYY-MM-DD_HH-mm-ss')}.csv`
    downloadCSV(csvData, filename)
  }

  const downloadCSV = (csvData, filename) => {
    const blob = new Blob([csvData], { type: 'text/csv' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const handleRequestSort = (event, property, tab, defaultOrder='desc') => {
    if (defaultOrder === 'desc') {
      const isDesc = orderBy === property && order === 'desc'
      setOrder(isDesc ? 'asc' : 'desc')
    } else if (defaultOrder === 'asc') {
      const isAsc = orderBy === property && order === 'asc'
      setOrder(isAsc ? 'desc' : 'asc')
    }
    setOrderBy(property)
    setSortTab(tab)
    resetPages()
  }

  const resetPages = () => {
    setPages(prevPages => {
      let newPages = {}
      Object.keys(prevPages).forEach(segmentId => {
        newPages[segmentId] = 0
      })
      return newPages
    })
  }

  const handleChangePage = (segmentId, newPage) => {
    setPages(prevPage => {
      return {
        ...prevPage,
        [segmentId]: newPage
      }
    })
  }

  const handleChangePageSize = (value) => {
    resetPages()
    setPageSize(value)
  }

  const handleChangeBreakdownOpen = (segmentId, open) => {
    setBreakdownsOpen(prev => {
      return {
        ...prev,
        [segmentId]: open
      }
    })
  }

  const handleSearchTextChange = (text) => {
    resetPages()
    setSearchText(text)
  }

  const handleIsSearchExpandedChange = (isExpanded) => {
    setIsSearchExpanded(isExpanded)
  }

  const allSegmentsAreLoading = segmentsData.every(segmentData => segmentData.isLoading)
  const anySegmentHasBreakdown = segments.some(segment => segment.breakdown)
  const isConnectedToChart = useMemo(() => tab === TABLE_CHART_TAB_KEY, [tab])

  const summaryTableData = useMemo(() => {
    if (allSegmentsAreLoading) return null
    if (!summaryColumnsKey) return null

    const summaryColumnConfig = summaryColumns[summaryColumnsKey] || summaryColumns['default']

    switch (summaryColumnConfig.type) {
      case 'metrics':
        return getSummaryMetricsTableData(summaryColumnConfig, segments, segmentsData, segmentsBreakdownData, aliases)
      case 'milestones':
        return getSummaryMilestonesTableData(summaryColumnConfig, startDate, endDate, config, segments, segmentsData, segmentsBreakdownData, aliases)
      default:
        return null
    }
  }, [allSegmentsAreLoading, summaryColumnsKey, tab, resolution, segments, segmentsData, segmentsBreakdownData, aliases])

  const tableData = useMemo(() => {
    if (allSegmentsAreLoading) return null

    switch (tab) {
      case TABLE_SUMMARY_TAB_KEY:
        return summaryTableData
      case TABLE_CHART_TAB_KEY:
        return getChartTableData(xKey, metricItem, resolution, segments, segmentsData, segmentsBreakdownData, emptyDataRule, aliases, showCumulative)
      case TABLE_MILESTONES_TAB_KEY:
        return getMilestonesTableData(xKey, metricItem, resolution, segments, segmentsData, segmentsBreakdownData, aliases)
    }
  }, [allSegmentsAreLoading, summaryColumnsKey, tab, xKey, metricItem, resolution, showCumulative, segments, segmentsData, segmentsBreakdownData, emptyDataRule, aliases])

  return (
    <Box>
      {allSegmentsAreLoading ? (
        <LoadingSkeleton width='100%' height={TABLE_PLACEHOLDER_HEIGHT} />
      ) : !tableData ? (
        <NoDataBox height={TABLE_PLACEHOLDER_HEIGHT} />
      ): (
      <Box>
        <Box display='flex' flexDirection='row' alignItems='center' justifyContent='flex-start' flexWrap='wrap'>
          <Tabs
            value={tab}
            onChange={(_, value) => setTab(value)}
            textColor='secondary'
            indicatorColor='secondary'
            sx={{
              marginTop: theme => theme.spacing(1),
              marginBottom: theme => theme.spacing(1),
              height: '40px',
              minHeight: '40px',
              '& .MuiButtonBase-root': {
                height: '40px',
                minHeight: '40px',
              },
            }}
          >
            {showSummaryTab && (
              <Tab
                label='Summary'
                value={TABLE_SUMMARY_TAB_KEY}
                sx={{
                  padding: theme => theme.spacing(0, 1),
                  borderTopLeftRadius: theme => theme.shape.borderRadius,
                  borderTopRightRadius: theme => theme.shape.borderRadius,
                  backgroundColor: theme => tab === 0 ? alpha(theme.palette.secondary.main, 0.05) : 'transparent',
                  '&:hover': {
                    backgroundColor: theme => alpha(theme.palette.secondary.main, 0.025)
                  },
                  textTransform: 'none',
                }}
              />
            )}
            {showChartTab && (
              <Tab
                label='Chart'
                value={TABLE_CHART_TAB_KEY}
                sx={{
                  padding: theme => theme.spacing(0, 1),
                  borderTopLeftRadius: theme => theme.shape.borderRadius,
                  borderTopRightRadius: theme => theme.shape.borderRadius,
                  backgroundColor: theme => tab === 1 ? alpha(theme.palette.secondary.main, 0.05) : 'transparent',
                  '&:hover': {
                    backgroundColor: theme => alpha(theme.palette.secondary.main, 0.025)
                  },
                  textTransform: 'none',
                }}
              />
            )}
            {showMilestonesTab && (
              <Tab
                label='Chart Milestones'
                value={TABLE_MILESTONES_TAB_KEY}
                sx={{
                  padding: theme => theme.spacing(0, 1),
                  borderTopLeftRadius: theme => theme.shape.borderRadius,
                  borderTopRightRadius: theme => theme.shape.borderRadius,
                  backgroundColor: theme => tab === 0 ? alpha(theme.palette.secondary.main, 0.05) : 'transparent',
                  '&:hover': {
                    backgroundColor: theme => alpha(theme.palette.secondary.main, 0.025)
                  },
                  textTransform: 'none',
                }}
              />
            )}
          </Tabs>

          <Box marginLeft={2} display='flex' flexDirection='row'>
            <FormControlLabel
              control={
                <Switch
                  color='secondary'
                  size='small'
                  checked={showActiveOnly}
                  onChange={(event) => setShowActiveOnly(event.target.checked)}
                />
              }
              label='Active only'
              labelPlacement='end'
              componentsProps={{
                typography: {
                  variant: 'caption'
                }
              }}
            />
            {(showActiveOnly || searchText) && (
              <Alert
                severity='info'
                sx={{
                  padding: theme => theme.spacing(0, 1),
                }}
              >
                Rows filtered
              </Alert>
            )}
          </Box>

          <div style={{ flexGrow: 1 }} />

          <Box display='flex' flexDirection='row'>
            {anySegmentHasBreakdown && (
              <SearchInput
                value={searchText}
                isExpanded={isSearchExpanded}
                placeholder={'Breakdown name or ID'}
                onChange={handleSearchTextChange}
                onIsExpandedChange={handleIsSearchExpandedChange}
              />
            )}

            {!isSearchExpanded && (
              <React.Fragment>
                {tab === 'summary' && (
                  <ColumnsSelect
                    parentKey={parentKey}
                    summaryColumns={summaryColumns}
                    columnsKey={summaryColumnsKey}
                    onColumnsKeyChange={setSummaryColumnsKey}
                  />
                )}

                {segments.some(s => s.breakdown) && (
                  <PageSizeSelect
                    pageSizes={[5, 10, 25]}
                    pageSize={pageSize}
                    onChange={handleChangePageSize}
                  />
                )}

                <DarkTooltip
                  title='Export table to CSV'
                  placement='bottom'
                  arrow
                >
                  <span style={{ alignSelf: 'center' }}>
                    <IconButton
                      variant='text'
                      size='small'
                      color='primary'
                      onClick={() => handleExportToCSV(tableData)}
                      disabled={fetchingQueryCount > 0}
                    >
                      <FileDownloadIcon />
                    </IconButton>
                  </span>
                </DarkTooltip>
              </React.Fragment>
            )}
          </Box>
        </Box>

        <DataTable
          key={tab}
          tab={tab}
          tableData={tableData}
          summaryTableData={summaryTableData}
          order={order}
          orderBy={orderBy}
          sortTabValue={sortTab}
          pages={pages}
          pageSize={pageSize}
          breakdownsOpen={breakdownsOpen}
          showActiveOnly={showActiveOnly}
          searchText={searchText}
          resolution={resolution}
          segments={segments}
          segmentsData={segmentsData}
          segmentsBreakdownData={segmentsBreakdownData}
          chartHoverIndex={isConnectedToChart ? chartHoverIndex : null}
          onSegmentIsActiveChange={onSegmentIsActiveChange}
          onSegmentBreakdownIsActiveChange={onSegmentBreakdownIsActiveChange}
          onChartHoverIndexChange={onChartHoverIndexChange}
          onRequestSort={handleRequestSort}
          onPageChange={handleChangePage}
          onBreakdownOpenChange={handleChangeBreakdownOpen}
          isConnectedToChart={isConnectedToChart}
        />
      </Box>
      )}
    </Box>
  )
}

const DataTable = ({
  tab,
  tableData,
  summaryTableData,
  order,
  orderBy,
  sortTabValue,
  pages,
  pageSize,
  breakdownsOpen,
  showActiveOnly,
  searchText,
  resolution,
  segments,
  segmentsData,
  segmentsBreakdownData,
  chartHoverIndex,
  onSegmentIsActiveChange,
  onSegmentBreakdownIsActiveChange,
  onChartHoverIndexChange,
  onRequestSort,
  onPageChange,
  onBreakdownOpenChange,
  isConnectedToChart,
}) => {
  const classes = useStyles()
  const theme = useTheme()

  const userDoc = React.useContext(UserContext)

  const [localHoverIndex, setLocalHoverIndex] = React.useState(null)
  const [isTableHovering, setIsTableHovering] = React.useState(false)
  const cellRefs = isConnectedToChart ? useRef([]) : null

  // Listen for changes to chartHoverIndex from outside this component
  useEffect(() => {
    if (isConnectedToChart && chartHoverIndex !== null && !isTableHovering) {
      cellRefs.current[chartHoverIndex].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
    }
  }, [chartHoverIndex])

  const handleMouseEnterColumn = (columnIndex) => {
    if (isConnectedToChart) {
      setIsTableHovering(true)
      onChartHoverIndexChange(columnIndex)
    } else {
      setLocalHoverIndex(columnIndex)
    }
  }

  const handleMouseLeaveColumn = () => {
    if (isConnectedToChart) {
      setIsTableHovering(false)
      onChartHoverIndexChange(null)
    } else {
      setLocalHoverIndex(null)
    }
  }

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  const stableSort = (array, comparator) => {
    return array.slice().sort(comparator)
  }

  const createSortHandler = (property, tab, defaultOrder='desc') => (event) => {
    onRequestSort(event, property, tab, defaultOrder)
  }

  const handleFirstPageButtonClick = (segmentId) => {
    onPageChange(segmentId, 0)
  }

  const handleBackButtonClick = (segmentId) => {
    const currentPage = pages[segmentId] || 0
    onPageChange(segmentId, Math.max(0, currentPage - 1))
  }

  const handleNextButtonClick = (segmentId, maxCount) => {
    const currentPage = pages[segmentId] || 0
    onPageChange(segmentId, Math.min(Math.ceil(maxCount / pageSize) - 1, currentPage + 1))
  }

  const handleLastPageButtonClick = (segmentId, maxCount) => {
    onPageChange(segmentId, Math.max(0, Math.ceil(maxCount / pageSize) - 1))
  }

  const sortedAndFilteredBody = React.useMemo(() => {
    if (tableData === null) return []
    if (tableData.body.length === 0) return []

    const loadingRows = tableData.body.filter(row => row === null)
    const loadedRows = tableData.body.filter(row => row !== null)

    if (loadedRows.length === 0) return loadingRows

    let sortedRows = []

    // If the sorting is on the current tab, sort the rows based on the orderBy
    if (tab === sortTabValue) {
      // Sort rows based on the orderBy
      sortedRows = [
        ...loadingRows,
        ...stableSort(loadedRows, getComparator(order, orderBy)).slice()
      ]
    }
    // If the sorting is not on the current tab, sort the rows based on the summary sort order
    else if (tab !== 'summary' && tab !== sortTabValue) {
      // Sort summary data, then sort the tableData to match that order
      const summaryLoadingRows = summaryTableData.body.filter(row => row === null)
      const summaryLoadedRows = summaryTableData.body.filter(row => row !== null)

      const summarySortedRows = [
        ...summaryLoadingRows,
        ...stableSort(summaryLoadedRows, getComparator(order, orderBy)).slice()
      ]
      const sortedKeys = summarySortedRows.map(row => row.key)

      // Sort the tableData rows based on the summary sort order
      const keyPosition = new Map(sortedKeys.map((key, i) => [key, i]))
      const sortedLoadedRows = loadedRows.sort((a, b) => keyPosition.get(a.key) - keyPosition.get(b.key)).slice()
      sortedRows = [
        ...loadingRows,
        ...sortedLoadedRows,
      ]
    }

    // Filter rows if showActiveOnly is true
    if (showActiveOnly) {
      // Include rows that are active, or that have an active breakdown inside of them
      sortedRows = sortedRows.filter(row => {
        const segment = segments.find(segment => segment.id === row.key)
        const isActive = segment.isActive
        // If segment doesn't have a breakdown, return based on isActive
        if (!segment.breakdown) return isActive
        // If the segment does have a breakdown, return based on
        // if the segment is active OR if any breakdown is active
        else {
          const hasActiveBreakdown = segment.breakdown && (
            Object.keys(segment.breakdownState).some(key => segment.breakdownState[key].isActive)
          )
          return isActive || hasActiveBreakdown
        }
      })
    }

    return sortedRows
  }, [tableData, summaryTableData, order, orderBy, pages, pageSize, showActiveOnly, searchText])

  const sortedAndFilteredBreakdownBodyMap = React.useMemo(() => {
    if (tableData === null) return {}
    if (Object.keys(tableData.breakdownBodyMap).length === 0) return {}

    let sortedBodyMap = {}

    // If the sorting is not on the current tab, sort the rows based on the summary sort order
    let useSummarySort = false
    if (tab !== 'summary' && tab !== sortTabValue) {
      useSummarySort = true
    }

    // Sort rows for each segment
    Object.keys(tableData.breakdownBodyMap).forEach(segmentId => {
      const breakdownRows = tableData.breakdownBodyMap[segmentId]

      let sortedRows = []

      // If the sorting is on the current tab, sort the rows based on the orderBy
      if (!useSummarySort) {
        sortedRows = stableSort(breakdownRows, getComparator(order, orderBy)).slice()
      }
      // Use summaryTableData to sort the tableData
      else {
        const summaryBreakdownRows = summaryTableData.breakdownBodyMap[segmentId]

        // Sort summary data, then sort the tableData to match that order
        const summarySortedRows = stableSort(summaryBreakdownRows, getComparator(order, orderBy)).slice()
        const sortedKeys = summarySortedRows.map(row => row.key)

        // Sort the tableData rows based on the summary sort order
        const keyPosition = new Map(sortedKeys.map((key, i) => [key, i]))
        sortedRows = breakdownRows.sort((a, b) => keyPosition.get(a.key) - keyPosition.get(b.key)).slice()
      }

      // Filter rows if showActiveOnly is true
      if (showActiveOnly) {
        sortedRows = sortedRows.filter(row => segments.find(segment => segment.id === segmentId)?.breakdownState[row.key]?.isActive)
      }

      if (searchText) {
        sortedRows = sortedRows.filter(row => {
          const key = row.key
          const platform_id = row.platform_id
          const name = row.name
          const displayName = row.displayName
          return (
            key.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
            name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
            platform_id.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
            displayName.toLowerCase().indexOf(searchText.toLowerCase()) > -1
          )
        })
      }

      sortedBodyMap[segmentId] = sortedRows
    })

    return sortedBodyMap
  }, [tableData, summaryTableData, order, orderBy, pages, pageSize, showActiveOnly, searchText])

  return (
    <Box sx={{
      borderRadius: theme => theme.shape.borderRadius / 4,
      backgroundColor: theme => theme.palette.common.white,
    }}>
      {!tableData ? (
        <NoDataBox height={TABLE_PLACEHOLDER_HEIGHT} />
      ) : (
      <TableContainer
        sx={{
          overflow: 'auto',
          maxHeight: 'calc(100vh - 70px)',
          '&::-webkit-scrollbar': {
            width: '10px',
            height: '10px',
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: theme => alpha(theme.palette.secondary.main, 0.8),
          },
        }}
      >
        <Table size='small' stickyHeader>
          <TableHead>
            <TableRow>
              {tableData.head.map((column, columnIndex) => {
                if (columnIndex === 0) {
                  return (
                    <TableCell
                      key={column.key}
                      className={classes.tableCellHead1}
                      align='center'
                    >
                      <TableSortLabel
                        active={orderBy === column.key}
                        direction={orderBy === column.key ? order : 'asc'}
                        onClick={createSortHandler(column.key, tab, 'asc')}
                      >
                        {column.name}
                        {orderBy === column.key ? (
                          <Box component='span' sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  )
                } else {
                  return (
                    <TableCell
                      key={column.key}
                      className={classes.tableCellHead2}
                      align='right'
                      sortDirection={orderBy === column.key ? order : false}
                      ref={ref => cellRefs ? cellRefs.current[columnIndex - 1] = ref : null}
                      onMouseEnter={() => handleMouseEnterColumn(columnIndex - 1)}
                      onMouseLeave={() => handleMouseLeaveColumn()}
                      sx={{
                        backgroundColor: columnIndex - 1 === chartHoverIndex || columnIndex - 1 === localHoverIndex ? darken('#DDE3EB', 0.1) : '#DDE3EB',
                      }}
                    >
                      <TableSortLabel
                        active={orderBy === column.key}
                        direction={orderBy === column.key ? order : 'desc'}
                        onClick={createSortHandler(column.key, tab, 'desc')}
                      >
                        {column.name}
                        {orderBy === column.key ? (
                          <Box component='span' sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  )
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* The body has one top level row per segment */}
            {sortedAndFilteredBody.map(row => {
              const segmentIndex = segments.findIndex(segment => segment.id === row.key)
              const segment = segments[segmentIndex]
              const rowIsLoading = segmentsData[segmentIndex]?.isLoading
              const segmentPage = pages[segment.id] || 0
              const filteredBreakdowns = sortedAndFilteredBreakdownBodyMap[segment.id]
              const filteredBreakdownsCount = filteredBreakdowns ? filteredBreakdowns.length : 0
              return (
                rowIsLoading ? (
                  <TableRow key={segment.id}>
                    <TableCell colSpan={100} className={classes.tableCellBody1}>
                      <LoadingSkeleton width='100%' height={20} />
                    </TableCell>
                  </TableRow>
                ) : (
                  <React.Fragment key={segment.id}>
                    <TableRow key={segment.id}>
                      {tableData.head.map((column, columnIndex) => {
                        const breakdownOpen = segment.id in breakdownsOpen ? breakdownsOpen[segment.id] : true
                        if (columnIndex === 0) {
                          return (
                            <TableCell
                              key={`${row.key}-${column.key}`}
                              className={classes.tableCellBody1}
                              sx={{ minWidth: segment.breakdown ? 300 : 200}}
                            >
                              <Box className={classes.tableCellBody1Inner}>
                                <CustomColorSwitch
                                  id={segment.id}
                                  colorHex={segment.color}
                                  checked={segment.isActive}
                                  onChange={onSegmentIsActiveChange}
                                />
                                <DarkTooltip
                                  title={row.name}
                                  placement='right'
                                  enterDelay={300}
                                  arrow
                                >
                                  <Box sx={{ flexGrow: 1 }}>
                                    <Typography
                                      variant='body2'
                                      sx={{
                                        fontWeight: segment.breakdown ? 'bold' : 'regular',
                                        lineHeight: 1.2,
                                      }}
                                    >
                                      {shortenString(row.name, 64)}
                                    </Typography>
                                  </Box>
                                </DarkTooltip>
                                {filteredBreakdownsCount > 0 && segment.breakdown && !segmentsBreakdownData[segmentIndex]?.isLoading && (
                                  <Box>
                                    <DarkTooltip
                                      title={`${breakdownOpen ? 'Hide' : 'Show'} breakdowns`}
                                      placement='right'
                                      arrow
                                    >
                                      <IconButton
                                        size='small'
                                        onClick={() => onBreakdownOpenChange(segment.id, !breakdownOpen)}
                                      >
                                        {breakdownOpen ?
                                          <KeyboardArrowUpIcon fontSize='inherit' /> :
                                          <KeyboardArrowDownIcon fontSize='inherit' />}
                                      </IconButton>
                                    </DarkTooltip>
                                  </Box>
                                )}
                              </Box>

                              {filteredBreakdownsCount > 0 && segment.breakdown && !segmentsBreakdownData[segmentIndex]?.isLoading && (
                                (() => {
                                  const activeCount = Object.keys(segment.breakdownState)
                                    .filter(key => segmentsBreakdownData[segmentIndex].data.breakdownKeys.includes(key))
                                    .filter(key => segment.breakdownState[key].isActive)
                                    .length
                                  return (
                                    <Box display='flex' flexDirection='row' columnGap={0} alignItems='center' justifyContent='space-between' marginTop={0.5}>
                                      <Box>
                                        <Typography variant='body1' style={{ fontSize: 12 }} display='block'>
                                          {(segmentPage * pageSize) + 1}&ndash;{Math.min((segmentPage + 1) * pageSize, filteredBreakdownsCount)} of {filteredBreakdownsCount} breakdowns
                                        </Typography>
                                        <Typography variant='caption' style={{ fontSize: 12, fontWeight: activeCount > 0 ? 'bold' : 'normal' }} display='block'>
                                          ({activeCount} active)
                                        </Typography>
                                      </Box>

                                      {filteredBreakdownsCount > pageSize && (
                                        <Box display='flex' flexDirection='row' alignSelf='center'>
                                          <IconButton onClick={() => handleFirstPageButtonClick(segment.id)} disabled={segmentPage === 0} size='small'>
                                            <FirstPageIcon fontSize='inherit'/>
                                          </IconButton>
                                          <IconButton onClick={() => handleBackButtonClick(segment.id)} disabled={segmentPage === 0} size='small'>
                                            <KeyboardArrowLeft fontSize='inherit'/>
                                          </IconButton>
                                          <IconButton onClick={() => handleNextButtonClick(segment.id, filteredBreakdownsCount)} disabled={segmentPage >= Math.ceil(filteredBreakdownsCount / pageSize) - 1} size='small'>
                                            <KeyboardArrowRight fontSize='inherit'/>
                                          </IconButton>
                                          <IconButton onClick={() => handleLastPageButtonClick(segment.id, filteredBreakdownsCount)} disabled={segmentPage >= Math.ceil(filteredBreakdownsCount / pageSize) - 1} size='small'>
                                            <LastPageIcon fontSize='inherit'/>
                                          </IconButton>
                                        </Box>
                                      )}
                                    </Box>
                                  )
                                })()
                              )}
                            </TableCell>
                          )
                        } else {
                          const value = row[column.key]
                          return (
                            <TableCell
                              key={`${row.key}-${column.key}`}
                              className={classes.tableCellBody2}
                              align='right'
                              onMouseEnter={() => handleMouseEnterColumn(columnIndex - 1)}
                              onMouseLeave={() => handleMouseLeaveColumn()}
                              sx={{
                                backgroundColor: theme => columnIndex - 1 === chartHoverIndex || columnIndex - 1 === localHoverIndex ? '#F8FAFD' : theme.palette.background.paper,
                                fontWeight: segment.breakdown ? 'bold': 'regular',
                              }}
                            >
                              {getDisplayValueByFormat(
                                value,
                                column.format,
                                {
                                  resolution,
                                  timezone: TIMEZONE,
                                  decimalCount: 2,
                                  hideIntegerDecimals: false,
                                  abbreviateLargeAmounts: false,
                                  currency: userDoc.currency,
                                }
                              )}
                            </TableCell>
                          )
                        }
                      })}
                    </TableRow>

                    {/* Breakdown rows for this segment */}
                    {segment.breakdown && (
                      segmentsBreakdownData[segmentIndex]?.isLoading ? (
                        <TableRow key={`${segment.id}-${segment.breakdown.field}`}>
                          <TableCell colSpan={100}>
                            <LoadingSkeleton width='100%' height={20} />
                          </TableCell>
                        </TableRow>
                      ) : (() => {
                        const breakdownOpen = segment.id in breakdownsOpen ? breakdownsOpen[segment.id] : true
                        if (!breakdownOpen) return null
                        const segmentPage = pages[segment.id] || 0
                        return (
                          <React.Fragment>
                            {filteredBreakdowns.slice(segmentPage * pageSize, segmentPage * pageSize + pageSize).map(breakdownRow => (
                              <TableRow key={breakdownRow.key}>
                                {tableData.head.map((column, columnIndex) => {
                                  if (columnIndex === 0) {
                                    return (
                                      <DarkTooltip
                                        key={`${segment.id}-${breakdownRow.key}-${column.key}`}
                                        title={
                                          <React.Fragment>
                                            {breakdownRow.displayName}
                                            {breakdownRow.key !== breakdownRow.name && (
                                              <Typography variant='caption' display='block'>
                                                Platform ID: {breakdownRow.platform_id}
                                              </Typography>
                                            )}
                                            {breakdownRow.secondary && (
                                              <Typography variant='caption' display='block'>
                                                {breakdownRow.secondary}
                                              </Typography>
                                            )}
                                          </React.Fragment>
                                        }
                                        placement='right'
                                        enterDelay={300}
                                        arrow
                                      >
                                        <TableCell
                                          className={classes.tableCellBody1}
                                          sx={{ minWidth: 300 }}
                                        >
                                          <Box className={classes.tableCellBody1Inner}>
                                            <svg style={{ minWidth: 20 }} width={20} height={24}>
                                              <line x1={6} y1={0} x2={6} y2={12} stroke={theme.palette.text.secondary} />
                                              <line x1={6} y1={12} x2={20} y2={12} stroke={theme.palette.text.secondary} />
                                            </svg>
                                            <CustomColorSwitch
                                              id={breakdownRow.key}
                                              colorHex={segment.breakdownState[breakdownRow.key]?.color}
                                              checked={segment.breakdownState[breakdownRow.key]?.isActive}
                                              onChange={(breakdownKey, isActive) => onSegmentBreakdownIsActiveChange(segment.id, breakdownRow.key, isActive)}
                                            />
                                            <Box sx={{ overflow: 'hidden', wordBreak: 'break-word' }}>
                                              <Typography variant='body2' style={{ lineHeight: 1.2, marginBottom: 2 }}>
                                                {shortenString(breakdownRow.displayName, 64)}
                                              </Typography>
                                              {breakdownRow.secondary && (
                                                <Typography variant='caption' display='block' style={{ fontWeight: 'bold', lineHeight: 1.1 }}>
                                                  {breakdownRow.secondary}
                                                </Typography>
                                              )}
                                            </Box>
                                          </Box>
                                        </TableCell>
                                      </DarkTooltip>
                                    )
                                  } else {
                                    const value = breakdownRow[column.key]
                                    return (
                                      <TableCell
                                        key={`${segment.id}-${breakdownRow.key}-${column.key}`}
                                        className={classes.tableCellBody2}
                                        align='right'
                                        onMouseEnter={() => handleMouseEnterColumn(columnIndex - 1)}
                                        onMouseLeave={() => handleMouseLeaveColumn()}
                                        sx={{
                                          backgroundColor: theme => columnIndex - 1 === chartHoverIndex || columnIndex - 1 === localHoverIndex ? '#F8FAFD' : theme.palette.background.paper,
                                        }}
                                      >
                                        {getDisplayValueByFormat(
                                          value,
                                          column.format,
                                          {
                                            resolution,
                                            timezone: TIMEZONE,
                                            decimalCount: 2,
                                            hideIntegerDecimals: false,
                                            abbreviateLargeAmounts: false,
                                            currency: userDoc.currency,
                                          }
                                        )}
                                      </TableCell>
                                    )
                                  }
                                })}
                              </TableRow>
                            ))}
                          </React.Fragment>
                        )
                      })()
                    )}
                  </React.Fragment>
                )
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      )}
    </Box>
  )
}

DataTableWrapper.propTypes = {
  parentKey: PropTypes.string.isRequired,
  xKey: PropTypes.string.isRequired,
  xKeyAsRange: PropTypes.bool,
  defaultTab: PropTypes.string.isRequired,
  showSummaryTab: PropTypes.bool.isRequired,
  showChartTab: PropTypes.bool.isRequired,
  showMilestonesTab: PropTypes.bool.isRequired,
  summaryColumns: PropTypes.object.isRequired,
  emptyDataRule: PropTypes.string.isRequired,
  metricItem: PropTypes.object.isRequired,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  resolution: PropTypes.number.isRequired,
  config: PropTypes.object.isRequired,
  showCumulative: PropTypes.bool.isRequired,
  segments: PropTypes.array.isRequired,
  segmentsData: PropTypes.array.isRequired,
  segmentsBreakdownData: PropTypes.array.isRequired,
  aliases: PropTypes.object.isRequired,
  chartHoverIndex: PropTypes.number,
  onSegmentIsActiveChange: PropTypes.func.isRequired,
  onSegmentBreakdownIsActiveChange: PropTypes.func.isRequired,
  onChartHoverIndexChange: PropTypes.func.isRequired,
}

export default DataTableWrapper