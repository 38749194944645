import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

import makeStyles from '@mui/styles/makeStyles'

import interpolate from 'color-interpolate'
import moment from 'moment-timezone'

import { FirebaseContext } from '../../utils/firebase'
import 'firebase/auth'
import { useQuery } from 'react-query'

import { API_ROOT_URL } from '../../constants'

const useStyles = makeStyles(theme => ({
  root: {
  },
  labelTypography: {
    textDecoration: 'underline',
    textDecorationStyle: 'dashed',
  },
  divider: {
    margin: theme.spacing(0, 2),
  },
  rank: {
    [theme.breakpoints.down('md')]: {
      marginTop: 8,
    },
  },
  metricGroup: {
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  }
}))

const TIMEZONE = moment.tz.guess()

const DayRankings = (props) => {
  const { date, metric } = props
  const classes = useStyles()
  const firebase = React.useContext(FirebaseContext)

  const { isLoading, data } = useQuery(['day-rankings', metric, TIMEZONE], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/daily/rankings`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          metric: metric.id,
          date: moment.tz(date, TIMEZONE).format(),
          timezone: TIMEZONE
        })
      }).then(res => res.json())
    }),
    {
      refetchInterval: 15 * 60 * 1000,  // 15 minutes
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
    }
  )

  const rankData = data || {}

  return (
    <div className={classes.root}>
      <Box display='flex' className={classes.metricGroup}>
        <Box marginRight={2}>
          <Typography variant='body2'>
            Ranking by <b>{metric.name}</b>
          </Typography>
        </Box>
        {isLoading ? (
          <Box flexGrow={1} height={36}>
            <Skeleton variant="rectangular" height='100%' />
          </Box>
        ) : (
          <>
            <Rank rank={rankData.week} count={moment.tz(TIMEZONE).isoWeekday()} label='this week' />
            <Divider className={classes.divider} orientation='vertical' flexItem light />
            <Rank rank={rankData.month} count={moment.tz(TIMEZONE).date()} label='this month' />
            <Divider className={classes.divider} orientation='vertical' flexItem light />
            <Rank rank={rankData.year} count={moment.tz(TIMEZONE).dayOfYear()} label='this year' />
          </>
        )}
      </Box>
    </div>
  );
}

DayRankings.propTypes = {
  date: PropTypes.object.isRequired,
  metric: PropTypes.object.isRequired,
}


const Rank = (props) => {
  const { count, label, rank } = props
  const classes = useStyles()
  const theme = useTheme()
  const GREEN_COLOR = '#cbf4c9'
  const YELLOW_COLOR = '#f8e5b9'
  const RED_COLOR = '#F4C9C9'

  // Scale rank into range of PALETTE_SIZE to determine which color to assign
  const colorMap = interpolate([GREEN_COLOR, YELLOW_COLOR, RED_COLOR])
  const normalizedRank = (rank - 1) / (count - 1)
  const rankColor = count === 1 ? colorMap(0) : colorMap(normalizedRank)
  const contrastTextColor = theme.palette.getContrastText(rankColor)

  return (
    <Box display='flex' flexDirection='row' alignItems='center' className={classes.rank} >
      <Box position='relative' width={36} height={36} marginRight={1} borderRadius="4px" bgcolor={rankColor} lineHeight={0}>
        <Box position='absolute' bottom='50%' width='100%' textAlign='center' color={contrastTextColor}>
          {rank}
        </Box>
      </Box>

      <Box>
        <Typography variant='body2'>
          of
        </Typography>
      </Box>

      <Box position='relative' width={36} height={36} marginX={1} borderRadius="4px" bgcolor={theme.palette.grey[200]} lineHeight={0}>
        <Box position='absolute' bottom='50%' width='100%' textAlign='center'>
          {count}
        </Box>
      </Box>

      <Box>
        <Typography className={classes.labelTypography} variant='body2'>
          {label}
        </Typography>
      </Box>
    </Box>
  );
}

Rank.defaultProps = {
  rank: 0
}

Rank.propTypes = {
  rank: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
}

export default DayRankings