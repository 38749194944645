import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TuneSharpIcon from '@mui/icons-material/TuneSharp'

const Config = ({
  config,
  configItems,
  onConfigChange,
}) => {
  if (Object.keys(configItems).length === 0) return null
  if (Object.values(configItems).every(item => !item.show)) return null
  return (
    <Box>
      <Box display='flex' flexDirection='row' alignItems='center' columnGap={1}>
        <TuneSharpIcon
          sx={{
            fontSize: 16,
            marginRight: '-4px',
          }}
        />
        {Object.keys(configItems).map(key => {
          const configItem = configItems[key]
          if (!configItem.show) return null
          return (
            <Box key={key}>
              <Box>
                <FormControl size='small' variant='outlined' sx={{ minWidth: 100, height: 29 }}>
                  <InputLabel id={`${key}-label`}>{configItem.name}</InputLabel>
                  <Select
                    id={`${key}-select`}
                    labelId={`${key}-label`}
                    label={configItem.name}
                    value={key in config ? config[key] : ''}
                    onChange={event => onConfigChange(key, event.target.value)}
                    renderValue={value => configItem.items.find(t => t.value.toString() === value.toString())?.name}
                    sx={{ height: 29 }}
                    inputProps={{
                      sx: {
                        paddingTop: '4px',
                        paddingBottom: '4px',
                        fontSize: 15,
                      }
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                    }}
                  >
                    {configItem.items.map(subItem => (
                      <MenuItem
                        key={subItem.value}
                        value={subItem.value}
                        color='secondary'
                      >
                        {subItem.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

Config.propTypes = {
  config: PropTypes.object.isRequired,
  configItems: PropTypes.object.isRequired,
  onConfigChange: PropTypes.func.isRequired,
}

export default Config