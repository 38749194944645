import React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import makeStyles from '@mui/styles/makeStyles'

import ContextChipFilter from '../../Common/ContextChip/ContextChipFilter'
import ContextChipBreakdown from '../../Common/ContextChip/ContextChipBreakdown'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  clearButton: {
    border: '1px solid rgba(255,23,68,0.5)',
    color: theme.palette.error.main,
    '&:hover': {
      background: 'rgba(255,23,68,0.04)',
    }
  },
  itemEnter: {
    opacity: 0,
  },
  itemEnterActive: {
    opacity: 1,
    transition: 'opacity 500ms ease-in',
  },
  itemExit: {
    opacity: 1,
  },
  itemExitActive: {
    opacity: 0,
    transition: 'opacity 500ms ease-in',
  },
}))

const ControlsContext = (props) => {
  const { breakdown, filters, onBreakdownDelete, onFilterDelete, onFilterDeleteAll } = props
  const classes = useStyles()

  const handleClear = () => {
    onBreakdownDelete()
    onFilterDeleteAll()
  }

  return (
    <div className={classes.root}>
      {filters.map(filter =>
        <ContextChipFilter key={filter.id} filter={filter} onDelete={onFilterDelete} />
      )}
      {breakdown &&
        <ContextChipBreakdown
          breakdown={breakdown}
          onDelete={onBreakdownDelete}
          breakdownLabel={props.breakdownLabel}
      />}
      {(filters.length > 0 || !!breakdown) && (
        <Button
          className={classes.clearButton}
          variant='outlined'
          onClick={handleClear}
        >
          Clear
        </Button>
      )}
    </div>
  )
}

ControlsContext.propTypes = {
  breakdown: PropTypes.object,
  onBreakdownDelete: PropTypes.func.isRequired,
  breakdownLabel: PropTypes.string,
  filters: PropTypes.array.isRequired,
  onFilterDelete: PropTypes.func.isRequired,
  onFilterDeleteAll: PropTypes.func.isRequired,
}

export default ControlsContext
