import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Popover from '@mui/material/Popover'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  root: {
  },
  popover: {
    marginTop: theme.spacing(1),
  },
  listItem: {
    paddingTop: theme.spacing(1) / 2,
    paddingBottom: theme.spacing(1) / 2,
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  }
}))

const TableSwitchToggle = (props) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleToggleClick = (count) => {
    props.onToggleClick(count)
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'popover-toggle' : undefined

  return (
    <div className={classes.root}>
      <Button
        aria-describedby={id}
        variant='outlined'
        onClick={handleClick}
        size='small'>
        Toggle
      </Button>
      <Popover
        className={classes.popover}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
        transformOrigin={{ vertical: 'top', horizontal: 'left', }}
      >
        <Box>
          <List component='ul' aria-label='breakdown selector' disablePadding>
            <ListItem
              className={classes.listItem}
              onClick={() => handleToggleClick(0)}
              button
            >
              <ListItemText primary='None' />
            </ListItem>
            <Divider />
            <ListItem
              className={classes.listItem}
              onClick={() => handleToggleClick(1)}
              button
            >
              <ListItemText primary='Top 1' />
            </ListItem>
            <ListItem
              className={classes.listItem}
              onClick={() => handleToggleClick(2)}
              button
            >
              <ListItemText primary='Top 2' />
            </ListItem>
            <ListItem
              className={classes.listItem}
              onClick={() => handleToggleClick(3)}
              button
            >
              <ListItemText primary='Top 3' />
            </ListItem>
            <ListItem
              className={classes.listItem}
              onClick={() => handleToggleClick(4)}
              button
            >
              <ListItemText primary='Top 4' />
            </ListItem>
            <ListItem
              className={classes.listItem}
              onClick={() => handleToggleClick(5)}
              button
            >
              <ListItemText primary='Top 5' />
            </ListItem>
          </List>
        </Box>
      </Popover>
    </div>
  );
}

TableSwitchToggle.propTypes = {
  onToggleClick: PropTypes.func.isRequired,
}

export default TableSwitchToggle
