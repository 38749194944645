import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import TuneSharpIcon from '@mui/icons-material/TuneSharp'
import { green, red, amber } from '@mui/material/colors'
import { alpha, darken, lighten } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import moment from 'moment-timezone'

import { UserContext } from '../../contexts/UserContext'

import { capitalizeFirstLetter, getDisplayValueByFormat } from '../../utils/helpers'
import { DATETIME_RESOLUTION_MAP } from '../../constants'

import CardDialog from './CardDialog'
import METRIC_ITEMS_MAP from './MetricItems'
import SquareIconColored from './SquareIconColored'
import DarkTooltip from './DarkTooltip'
import LoadingSkeleton from './LoadingSkeleton'
import NoDataBox from './NoDataBox'

const SCORES = [ 'green', 'yellow', 'red']
const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const scoreTypeItems = [
  {
    id: 'overunder',
    name: 'Over/Under',
  },
  {
    id: 'stoplight',
    name: 'Stoplight',
  },
  {
    id: 'previous',
    name: 'vs. Previous',
  },
]

const resolutionItems = [
  {
    id: 'day',
    name: 'Daily',
    value: 0,
  },
  {
    id: 'week',
    name: 'Weekly',
    value: 1,
  },
  {
    id: 'month',
    name: 'Monthly',
    value: 2,
  },
  {
    id: 'quarter',
    name: 'Quarterly',
    value: 3,
  },
  {
    id: 'year',
    name: 'Yearly',
    value: 4,
  },
]

const getColorByScore = (score) => {
  if (score === 'green') return green[500]
  if (score === 'yellow') return amber[500]
  if (score === 'red') return red[500]
}

const getConfigTextByCardAndScore = (card, score, userDoc) => {
  if (card.scoreType === 'overunder') {
    if (score === 'green') {
      return `over ${
        getDisplayValueByFormat(
          card.scoreValueOverUnder,
          METRIC_ITEMS_MAP[card.metricKey].format,
          {
            decimalCount: 2,
            hideIntegerDecimals: true,
            currency: userDoc.currency,
          }
        )}`
    }
    if (score === 'yellow') {
      return `equal to ${
        getDisplayValueByFormat(
          card.scoreValueOverUnder,
          METRIC_ITEMS_MAP[card.metricKey].format,
          {
            decimalCount: 2,
            hideIntegerDecimals: true,
            currency: userDoc.currency,
          }
        )}`
    }
    if (score === 'red') {
      return `under ${
        getDisplayValueByFormat(
          card.scoreValueOverUnder,
          METRIC_ITEMS_MAP[card.metricKey].format,
          {
            decimalCount: 2,
            hideIntegerDecimals: true,
            currency: userDoc.currency,
          }
        )}`
    }
  }
  if (card.scoreType === 'stoplight') {
    if (score === 'green') {
      return `at least ${
        getDisplayValueByFormat(
          card.scoreValueStoplightGreen,
          METRIC_ITEMS_MAP[card.metricKey].format,
          {
            decimalCount: 2,
            hideIntegerDecimals: true,
            currency: userDoc.currency,
          }
        )}`
    }
    if (score === 'yellow') {
      return `at least ${
        getDisplayValueByFormat(
          card.scoreValueStoplightYellow,
          METRIC_ITEMS_MAP[card.metricKey].format,
          {
            decimalCount: 2,
            hideIntegerDecimals: true,
            currency: userDoc.currency,
          }
        )}`
    }
    if (score === 'red') {
      return `less than ${
        getDisplayValueByFormat(
          card.scoreValueStoplightYellow,
          METRIC_ITEMS_MAP[card.metricKey].format,
          {
            decimalCount: 2,
            hideIntegerDecimals: true,
            currency: userDoc.currency,
          }
        )}`
    }
  }
  if (card.scoreType === 'previous') {
    if (score === 'green') return '> previous'
    if (score === 'yellow') return '= previous'
    if (score === 'red') return '< previous'
  }
}

const useStyles = makeStyles(theme => ({
  tableCellHead1: {
    position: 'sticky',
    left: 0,
    zIndex: 100,
    backgroundColor: '#DCE3EB',
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    fontSize: 13,
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    width: 200,
    maxWidth: 300,
    height: 40,
  },
  tableCellHead2: {
    backgroundColor: '#DCE3EB',
    fontSize: 13,
    fontWeight: 'bold',
    lineHeight: 1.2,
    whiteSpace: 'normal',
    height: 40,
    minWidth: 130,
    borderRight: `1px solid ${theme.palette.divider}`,
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  tableCellBody1: {
    position: 'sticky',
    left: 0,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    width: 200,
    maxWidth: 300,
    height: 40,
    cursor: 'default',
  },
  tableCellBody1Inner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    columnGap: theme.spacing(1),
  },
  tableCellBody2: {
    color: theme.palette.text.primary,
    height: 40,
    borderRight: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    whiteSpace: 'nowrap',
  },
}))

const ScorecardBuilder = ({
  xKey,
  scorecardData,
  startDate,
  endDate,
  resolution,
  cards,
  onAdjustDateRangeToCompletePeriods,
  onResolutionChange,
  onCardAdd,
  onCardUpdate,
  onCardDelete,
  onCardReorder,
}) => {
  const [cardDialogOpen, setCardDialogOpen] = useState(false)
  const [cardEditMetricKey, setCardEditMetricKey] = useState(null)
  const [cardReorderMenuAnchorEl, setCardReorderMenuAnchorEl] = useState(null)
  const [cardReorderMenuMetricKey, setCardReorderMenuMetricKey] = useState(null)

  const handleCardDialogOpenToAdd = (e) => {
    e.stopPropagation()
    setCardEditMetricKey(null)
    setCardDialogOpen(true)
  }

  const handleCardDialogClose = () => {
    setCardEditMetricKey(null)
    setCardDialogOpen(false)
  }

  const handleCardDialogOpenToEdit = (e, cardMetricKey) => {
    e.stopPropagation()
    setCardEditMetricKey(cardMetricKey)
    setCardDialogOpen(true)
  }

  const handleCardReorderMenuOpen = (event, metricKey) => {
    setCardReorderMenuAnchorEl(event.currentTarget)
    setCardReorderMenuMetricKey(metricKey)
  }

  const handleCardReorderMenuClose = () => {
    setCardReorderMenuAnchorEl(null)
    setCardReorderMenuMetricKey(null)
  }

  const handleCardReorder = (direction) => {
    onCardReorder(cardReorderMenuMetricKey, direction)
    handleCardReorderMenuClose()
  }

  const dateNeedsAdjusting = useMemo(() => {
    const momentResolution = resolution === 1 ? 'isoWeek' : DATETIME_RESOLUTION_MAP[resolution]
    const isStartDateAtStartOfPeriod = moment.tz(startDate, TIMEZONE).isSame(moment.tz(startDate, TIMEZONE).startOf(momentResolution))
    const isEndDateAtEndOfPeriod = moment.tz(endDate, TIMEZONE).isSame(moment.tz(endDate, TIMEZONE).endOf(momentResolution))
    return !isStartDateAtStartOfPeriod || !isEndDateAtEndOfPeriod
  }, [startDate, endDate, resolution])

  return (
    <Box marginTop={1}>
      {/* Scorecard builder controls */}
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        columnGap={1}
        marginBottom={2}
      >
        <Button
          variant='outlined'
          color='secondary'
          size='small'
          disableElevation
          onClick={handleCardDialogOpenToAdd}
          sx={{
            fontSize: 12,
            fontWeight: 600,
          }}
        >
          Add card
        </Button>

        {/* Frequency select */}
        <FormControl size='small' variant='outlined' sx={{ minWidth: 120, height: 29 }}>
          <InputLabel id='resolution-label'>Scoring Period</InputLabel>
          <Select
            id='resolution-select'
            labelId='resolution-label'
            label='Scoring Period'
            value={resolution}
            onChange={event => onResolutionChange(event.target.value)}
            renderValue={value => resolutionItems.find(i => i.value.toString() === value.toString())?.name}
            sx={{ height: 29 }}
            inputProps={{
              sx: {
                paddingTop: '4px',
                paddingBottom: '4px',
                fontSize: 15,
              }
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
          >
            {resolutionItems.map(item => (
              <MenuItem
                key={item.id}
                value={item.value}
                color='secondary'
              >
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {resolution !== 0 && dateNeedsAdjusting && (
          <React.Fragment>
            <Typography variant='body2' color='textSecondary'>
              Set date range to complete <b>{resolutionItems.find(i => i.value.toString() === resolution.toString())?.id}s</b> for meaningful scores
            </Typography>
            <Button
              variant='outlined'
              color='secondary'
              size='small'
              disableElevation
              onClick={onAdjustDateRangeToCompletePeriods}
              sx={{
                minWidth: 'auto',
                fontSize: 12,
                fontWeight: 600,
              }}
            >
              Fix
            </Button>
          </React.Fragment>
        )}
      </Box>

      {cards.length === 0 ? (
        <NoDataBox
          message='Add cards to see scores'
        />
      ) : (
        <React.Fragment>
          {/* Overall summary scores */}
          <Typography variant='h6' fontWeight='bold'>
            Overall
          </Typography>
          <Box
            display='flex'
            flexDirection='column'
            flexBasis={1}
            flexGrow={1}
            padding={2}
            marginBottom={2}
            sx={{
              minWidth: 200,
              border: theme => `1px solid ${theme.palette.divider}`,
              backgroundColor: theme => {
                if (!scorecardData) return theme.palette.background.paper
                if (scorecardData.summary.overall.score === 'green') return lighten(getColorByScore('green'), 0.9)
                if (scorecardData.summary.overall.score === 'yellow') return lighten(getColorByScore('yellow'), 0.9)
                if (scorecardData.summary.overall.score === 'red') return lighten(getColorByScore('red'), 0.9)
              },
            }}
          >
            <Box
              display='flex'
              flexDirection='row'
              alignItems='center'
              justifyContent='space-between'
              marginBottom={1}
            >
              <Typography variant='body1' fontWeight='bold'>
                Scores across {scorecardData ? cards.length : 'all'} cards {scorecardData && scorecardData.chart ? `and ${scorecardData.chart.length} periods` : ''}
              </Typography>
              {scorecardData && (
                <Typography variant='body1' fontWeight='bold'>
                  {scorecardData.summary.overall.totalCount} scores
                </Typography>
              )}
            </Box>

            {!scorecardData ? (
              <LoadingSkeleton width='100%' height={100} />
            ) : (
              <React.Fragment>
                <CardBody scorecardCardData={scorecardData.summary.overall} />
                <ResultsBar scorecardCardData={scorecardData.summary.overall} />
              </React.Fragment>
            )}
          </Box>

          {/* Cards with summary scores */}
          <Box marginBottom={2}>
            <Typography variant='h6' fontWeight='bold'>
              Summary by Card
            </Typography>
            <Box
              display='flex'
              flexDirection='row'
              alignItems='center'
              rowGap={2}
              columnGap={2}
              flexWrap='wrap'
            >
              {cards.map(card =>
                <Box
                  key={card.metricKey}
                  display='flex'
                  flexDirection='column'
                  flexBasis={1}
                  flexGrow={1}
                  padding={2}
                  sx={{
                    minWidth: 200,
                    border: theme => `1px solid ${theme.palette.divider}`,
                    backgroundColor: theme => theme.palette.background.paper,
                  }}
                >
                  <Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body1' fontWeight='bold'>
                      {card.name ? card.name : METRIC_ITEMS_MAP[card.metricKey].name}
                    </Typography>
                    <Box display='flex' flexDirection='row' alignItems='center' columnGap={1}>
                      <IconButton
                        size='small'
                        onClick={(e) => handleCardReorderMenuOpen(e, card.metricKey)}
                        sx={{ padding: 1, margin: -1 }}
                      >
                        <SwapHorizIcon fontSize='small' />
                      </IconButton>
                      <Menu
                        anchorEl={cardReorderMenuAnchorEl}
                        open={Boolean(cardReorderMenuAnchorEl)}
                        onClose={handleCardReorderMenuClose}
                      >
                        <MenuItem
                          onClick={() => handleCardReorder('left')}
                          disabled={cards.findIndex(c => c.metricKey === cardReorderMenuMetricKey) === 0}
                        >
                          Move Left
                        </MenuItem>
                        <MenuItem
                          onClick={() => handleCardReorder('right')}
                          disabled={cards.findIndex(c => c.metricKey === cardReorderMenuMetricKey) === cards.length - 1}
                        >
                          Move Right
                        </MenuItem>
                      </Menu>

                      <IconButton
                        size='small'
                        onClick={(e) => handleCardDialogOpenToEdit(e, card.metricKey)}
                        sx={{ padding: 1, margin: -1 }}
                      >
                        <TuneSharpIcon fontSize='small' />
                      </IconButton>
                    </Box>
                  </Box>

                  <Box
                    display='flex'
                    flexDirection='row'
                    alignItems='center'
                    justifyContent='space-between'
                    paddingBottom={1}
                  >
                    <Typography variant='caption' color='textSecondary'>
                      {scoreTypeItems.find(i => i.id === card.scoreType).name}
                    </Typography>
                    {scorecardData && (
                      <Typography variant='caption' fontWeight='bold'>
                        {scorecardData.summary.cards[card.metricKey].totalCount} scores
                      </Typography>
                    )}
                  </Box>

                  {!scorecardData ? (
                    <LoadingSkeleton width='100%' height={100} />
                  ) : (
                    <React.Fragment>
                      <CardBody
                        scorecardCardData={scorecardData.summary.cards[card.metricKey]}
                        card={card}
                      />
                      <ResultsBar
                        scorecardCardData={scorecardData.summary.cards[card.metricKey]}
                        card={card}
                      />
                    </React.Fragment>
                  )}
                </Box>
              )}
            </Box>
          </Box>

          {/* History */}
          <Box>
            <Typography variant='h6' fontWeight='bold'>
              History
            </Typography>
            {!scorecardData ? (
              <LoadingSkeleton width='100%' height={100} />
            ) : (
              <HistoryTable
                xKey={xKey}
                scorecardData={scorecardData}
                cards={cards}
                resolution={resolution}
              />
            )}
          </Box>
        </React.Fragment>
      )}

      {/* Card Dialog */}
      {cardDialogOpen && (
        <CardDialog
          open={cardDialogOpen}
          onClose={handleCardDialogClose}
          metricKey={cardEditMetricKey}
          cards={cards}
          startDate={startDate}
          endDate={endDate}
          onCardAdd={onCardAdd}
          onCardUpdate={onCardUpdate}
          onCardDelete={onCardDelete}
        />
      )}
    </Box>
  )
}

const CardBody = ({
  scorecardCardData,
  card,
}) => {
  const userDoc = React.useContext(UserContext)

  return (
    <Box display='flex' flexDirection='column' rowGap={0.5} marginBottom={1}>
      {SCORES.map(score => {
        const count = scorecardCardData[`${score}Count`]
        const percent = scorecardCardData[`${score}Percentage`]
        // Get the max value between the three scores and check if the current score is the largest
        const isLargest = Math.max(
          scorecardCardData.greenCount,
          scorecardCardData.yellowCount,
          scorecardCardData.redCount,
        ) === count

        return (
          <Box
            key={score}
            display='flex'
            flexDirection='row'
            alignItems='center'
            justifyContent='space-between'
            paddingX={1}
            sx={{
              backgroundColor: isLargest ? lighten(getColorByScore(score), 0.8) : 'inherit',
            }}
          >
            <Box display='flex' flexDirection='row' alignItems='center' columnGap={1} sx={{ maxWidth: 'calc(100% - 72px)' }}>
              <SquareIconColored
                color={getColorByScore(score)}
                width={10}
                height={10}
              />
              <Typography
                variant='body2'
                color='textSecondary'
                fontWeight={isLargest ? 'bold' : 'normal'}
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '100%',
                }}
              >
                {card ?
                  getConfigTextByCardAndScore(card, score, userDoc)
                  : capitalizeFirstLetter(score)
                }
              </Typography>
            </Box>
            <Box
              display='flex'
              flexDirection='row'
              columnGap={1}
              alignItems='center'
            >
              <Typography
                variant='body1'
                color='textPrimary'
                fontWeight={isLargest ? 'bold' : 'normal'}
              >
                {count}
              </Typography>
              <Box minWidth={36} display='inline-flex' justifyContent='flex-end'>
                <Typography
                  variant='body2'
                  color='textSecondary'
                  fontWeight={isLargest ? 'bold' : 'normal'}
                >
                  {getDisplayValueByFormat(percent, 'percent', { decimalCount: 0 })}
                </Typography>
              </Box>
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}

const ResultsBar = ({
  scorecardCardData,
  card,
}) => {
  const userDoc = React.useContext(UserContext)
  return (
    <Box
      height={24}
      sx={{
        flexGrow: 1,
        flexShrink: 1,
      }}
    >
      <Box
        display='flex'
        flexDirection='row'
        sx={{
          height: 24,
          backgroundColor: '#e0e0e0',
          flexGrow: 1,
          flexShrink: 1,
        }}
      >
        {SCORES.map(score => {
          const count = scorecardCardData[`${score}Count`]
          const percent = scorecardCardData[`${score}Percentage`]
          const configText = card ? getConfigTextByCardAndScore(card, score, userDoc) : ''

          // Get the max value between the three scores and check if the current score is the largest
          const isLargest = Math.max(
            scorecardCardData.greenCount,
            scorecardCardData.yellowCount,
            scorecardCardData.redCount,
          ) === count

          if (count === 0) return null
          return (
            <DarkTooltip
              key={score}
              title={
                <Box display='flex' flexDirection='column'>
                  <Box display='flex' flexDirection='row' justifyContent='space-between' columnGap={2}>
                    <Typography variant='body2' fontWeight='bold'>
                      {capitalizeFirstLetter(score)}
                    </Typography>
                    <Typography variant='body2'>
                      {configText}
                    </Typography>
                  </Box>
                  <Box display='flex' flexDirection='row' justifyContent='space-between' columnGap={2}>
                    <Typography variant='body2'>
                      {count} of {scorecardCardData.totalCount}
                    </Typography>
                    <Typography variant='body2'>
                      {getDisplayValueByFormat(percent, 'percent', { decimalCount: 0 })}
                    </Typography>
                  </Box>
                </Box>
              }
              placement='bottom'
              arrow
            >
              <Box
                key={score}
                display='flex'
                flexDirection='row'
                alignItems='center'
                justifyContent='center'
                sx={{
                  backgroundColor: () => getColorByScore(score),
                  outline: isLargest ? `2px solid ${darken(getColorByScore(score), 0.2)}` : null,
                  zIndex: isLargest ? 1 : 0,
                  height: '100%',
                  width: `${Math.min(percent, 1) * 100}%`,
                  transition: 'width 1.0s ease-out, transform 0.3s ease-out',
                  ':hover': {
                    transform: 'scaleY(1.25)',
                  },
                }}
              />
            </DarkTooltip>
          )
        })}
      </Box>
    </Box>
  )
}

const HistoryTable = ({
  xKey,
  scorecardData,
  cards,
  resolution,
}) => {
  const classes = useStyles()
  const userDoc = React.useContext(UserContext)
  return (
    <Box
      sx={{
        backgroundColor: theme => theme.palette.background.paper,
      }}
    >
      <TableContainer
        sx={{
          overflow: 'auto',
          maxHeight: 'calc(100vh - 50px)',
          '&::-webkit-scrollbar': {
            width: '10px',
            height: '10px',
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: theme => alpha(theme.palette.secondary.main, 0.8),
          },
        }}
      >
        <Table size='small' stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                className={classes.tableCellHead1}
                align='center'
              >
                Period
              </TableCell>
              {cards.map((card) => (
                <TableCell
                  key={card.metricKey}
                  className={classes.tableCellHead2}
                  align='center'
                >
                  <Box display='flex' flexDirection='column' alignItems='center' rowGap={0.5}>
                    {card.name ? card.name : METRIC_ITEMS_MAP[card.metricKey].name}
                    <Box width='100%'>
                      <Box
                        height={12}
                        sx={{
                          flexGrow: 1,
                          flexShrink: 1,
                        }}
                      >
                        <Box
                          display='flex'
                          flexDirection='row'
                          sx={{
                            height: 12,
                            backgroundColor: '#e0e0e0',
                            flexGrow: 1,
                            flexShrink: 1,
                          }}
                        >
                          {scorecardData.chart.map(dataPoint => {
                            const score = dataPoint[card.metricKey].score
                            const widthPercent = 1 / scorecardData.chart.length
                            return (
                              <DarkTooltip
                                key={dataPoint[xKey]}
                                title={
                                  <Box display='flex' flexDirection='column' alignItems='center'>
                                    <Typography variant='body2' fontWeight='bold'>
                                      {getDisplayValueByFormat(
                                        dataPoint[xKey],
                                        xKey,
                                        {
                                          resolution: resolution,
                                          timezone: TIMEZONE
                                        }
                                      )}
                                    </Typography>
                                    <Typography variant='body2'>
                                      {METRIC_ITEMS_MAP[card.metricKey].name}:&nbsp;
                                      {getDisplayValueByFormat(
                                        dataPoint[card.metricKey].value,
                                        METRIC_ITEMS_MAP[card.metricKey].format,
                                        {
                                          decimalCount: 2,
                                          hideIntegerDecimals: false,
                                          abbreviateLargeAmounts: false,
                                          currency: userDoc.currency,
                                        }
                                      )}
                                    </Typography>
                                  </Box>
                                }
                                placement='bottom'
                                enterDelay={0}
                                arrow
                              >
                                <Box
                                  key={dataPoint[xKey]}
                                  display='flex'
                                  flexDirection='row'
                                  alignItems='center'
                                  justifyContent='center'
                                  sx={{
                                    backgroundColor: () => getColorByScore(score),
                                    height: '100%',
                                    width: `${Math.min(widthPercent, 1) * 100}%`,
                                    ':hover': {
                                      transform: 'scaleY(1.75)',
                                    },
                                  }}
                                />
                              </DarkTooltip>
                            )
                          })}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Reverse sort so newest is first */}
            {scorecardData.chart.slice().reverse().map(dataPoint => (
              <TableRow key={dataPoint.date_time}>
                <TableCell
                  className={classes.tableCellBody1}
                  align='center'
                >
                  {getDisplayValueByFormat(
                    dataPoint[xKey],
                    xKey,
                    {
                      resolution: resolution,
                      timezone: TIMEZONE
                    }
                  )}
                </TableCell>
                {cards.map((card) => (
                  <TableCell
                    key={card.metricKey}
                    className={classes.tableCellBody2}
                    align='center'
                    sx={{
                      backgroundColor: lighten(getColorByScore(dataPoint[card.metricKey].score), 0.8),
                    }}
                  >
                    {getDisplayValueByFormat(
                      dataPoint[card.metricKey].value,
                      METRIC_ITEMS_MAP[card.metricKey].format,
                      {
                        decimalCount: 2,
                        hideIntegerDecimals: false,
                        abbreviateLargeAmounts: false,
                        currency: userDoc.currency,
                      }
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

ScorecardBuilder.propTypes = {
  xKey: PropTypes.string.isRequired,
  scorecardData: PropTypes.object,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  resolution: PropTypes.number.isRequired,
  cards: PropTypes.array.isRequired,
  onAdjustDateRangeToCompletePeriods: PropTypes.func.isRequired,
  onResolutionChange: PropTypes.func.isRequired,
  onCardAdd: PropTypes.func.isRequired,
  onCardUpdate: PropTypes.func.isRequired,
  onCardDelete: PropTypes.func.isRequired,
  onCardReorder: PropTypes.func.isRequired,
}

export default ScorecardBuilder