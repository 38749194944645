import React from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { useLocation } from 'react-router-dom'

import { useQuery } from 'react-query'
import queryString from 'query-string'

import { API_ROOT_URL, SHOPIFY_APP_URL, SHOPIFY_SCOPES } from '../../constants'
import { SHOPIFY_OAUTH } from '../../constants/routes'

const SHOPIFY_API_KEY = process.env.REACT_APP_SHOPIFY_API_KEY
const POST_INSTALL_REDIRECT_URI = SHOPIFY_APP_URL + SHOPIFY_OAUTH

const ShopifyAppInstall = () => {
  const location = useLocation()
  const [params, setParams] = React.useState({})
  const [redirectUri, setRedirectUri] = React.useState('')

  React.useEffect(() => {
    const params = queryString.parse(location.search)

    const shop = params.shop
    // const timestamp = params.timestamp
    // const hmac = params.hmac // TODO: make request to backend with HMAC to verify using our shopify app secret key
    const nonce = (Math.random() + 1).toString(36).substring(2)
    localStorage.setItem('shopify-nonce', nonce)
    const redirectUri = `https://${shop}/admin/oauth/authorize?client_id=${SHOPIFY_API_KEY}&scope=${SHOPIFY_SCOPES}&redirect_uri=${POST_INSTALL_REDIRECT_URI}&state=${nonce}&grant_options[]=value`

    setParams(params)
    setRedirectUri(redirectUri)
  }, [location.search])

  const { data, isLoading } = useQuery(['shopify-install-hmac-verification', params], () =>
    fetch(`${API_ROOT_URL}/api_public/integrations/shopify/installhmac`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        params: queryString.stringify(params)
      })
    }).then(res => res.json()),
    {
      enabled: !!Object.keys(params).length > 0,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  )

  const redirectToShopifyInstall = () => {
    window.location.href = redirectUri
    return null
  }

  React.useEffect(() => {
    if (data && data.isVerified) {
      redirectToShopifyInstall()
    }
  }, [data])

  return (
    <Container maxWidth='sm'>
      <Paper>
        <Box padding={2}>
          <Typography variant='h5' align='center' gutterBottom>
            Connect LTV Numbers + Shopify
          </Typography>
          {isLoading &&
            <Box marginTop={2}>
              <LinearProgress color='primary' />
              <Typography variant='caption'>
                Verifying request authenticity...
              </Typography>
            </Box>
          }
          {data && !data.isVerified && (
            <Typography style={{ color: 'red' }}>
              Install signature failed verification. Please return to Shopify and try again.
            </Typography>
          )}
          {data && data.isVerified && (
            <Typography variant='body1'>
              Request verified. Redirecting to Shopify...
            </Typography>
          )}
        </Box>
      </Paper>
    </Container>
  )
}

export default ShopifyAppInstall