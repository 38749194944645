import React from 'react'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import Skeleton from '@mui/material/Skeleton'
import Snackbar from '@mui/material/Snackbar'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'

import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import { FirebaseContext } from '../../utils/firebase'
import 'firebase/auth'

import { API_ROOT_URL } from '../../constants/'
import * as ROUTES from '../../constants/routes'

import stripeLogoImg from '../../media/stripe_logo.png'

const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: 66,
    right: 0,
    width: 'calc(100% - 66px)',
    height: '100vh',
    overflowY: 'auto',
    backgroundColor: theme.palette.grey[50],
  },
  logoImg: {
    width: 100,
  },
  syncButton: {
    width: 110,
  },
  redButton: {
    color: theme.palette.error.main,
    '&:hover': {
      background: 'rgba(255,23,68,0.04)',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250
  },
}))

const REVOKE_SUCCESS_MESSAGE = 'Account removed. Data will no longer be synced from this account. Redirecting back to settings...'
const SYNC_SUCCESS_MESSAGE = 'Sync initialized. Processing may take a while, and you can safely leave this page.'

const dataTypeMenuItems = [
  { value: 'invoices', display: 'All Invoices'},
  { value: 'subscriptions', display: 'Subscription Invoices'},
  { value: 'oneoff', display: 'One-off Invoices'},
  { value: 'charges', display: 'Payments'},
  { value: 'all', display: 'All Invoices and Payments'},
]

const dateRangeMenuItems = [
  { value: 'last_7_days', display: 'Last 7 days'},
  { value: 'last_30_days', display: 'Last 30 days'},
  { value: 'last_3_months', display: 'Last 3 months'},
  { value: 'last_6_months', display: 'Last 6 months'},
  { value: 'last_12_months', display: 'Last 12 months'},
  { value: 'last_2_years', display: 'Last 2 years'},
  { value: 'last_3_years', display: 'Last 3 years'},
  { value: 'ytd', display: 'Year to date'},
  { value: 'last_and_this_year', display: 'Last year and this year'},
  { value: 'all_time', display: 'All time'},
]

const StripeSource = () => {
  const classes = useStyles()
  const { id } = useParams()
  const history = useHistory()
  const firebase = React.useContext(FirebaseContext)
  const [message, setMessage] = React.useState('')
  const [dataType, setDataType] = React.useState('invoices')
  const [dateRange, setDateRange] = React.useState('last_2_years')
  const [showMessage, setShowMessage] = React.useState(false)
  const [showRevokeConfirmation, setShowRevokeConfirmation] = React.useState(false)

  const [startDate, setStartDate] = React.useState('')
  const [endDate, setEndDate] = React.useState('')

  React.useEffect(() => {
    setDatesFromRange(dateRange)
  }, [dateRange])

  const {
    isLoading: isLoadingIntegration,
    data: integration = {}
  } = useQuery(['integration-get', id], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      }).then(res => res.json())
    }),
    {
      cacheTime: 15 * 60 * 1000,  // 15 minutes
      staleTime: 15 * 60 * 1000,  // 15 minutes
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data.data_type) {
          setDataType(data.data_type)
        }
      }
    }
  )

  const { isFetching: isSyncing, refetch: sync } = useQuery(['integration-sync', id], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/stripe/sync`, {
        method: 'POST',
        body: JSON.stringify({
          integration_id: id,
          dataType,
          startDate,
          endDate
        }),
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      }).then(res => {
        setShowMessage(true)
        setMessage(SYNC_SUCCESS_MESSAGE)
        return res.json()
      })
    }),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      staleTime: 0,
    }
  )

  const revoke = () => {
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/stripe/revoke`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          integration_id: id
        })
      }).then(res => {
        setShowRevokeConfirmation(false)
        setMessage(REVOKE_SUCCESS_MESSAGE)
        setShowMessage(true)
        setTimeout(() => history.push(ROUTES.SETTINGS), 3000)
        return res.json()
      })
    })
  }

  const handleCloseMessage = (_, reason) => {
    if (reason === 'clickaway') return
    setShowMessage(false)
  }

  const handleSelectDataType = (event) => {
    setDataType(event.target.value)
  }

  const setDatesFromRange = (dateRange) => {
    let startDate, endDate
    switch (dateRange) {
      case 'last_7_days':
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(7, 'days').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'last_30_days':
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(30, 'days').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'last_3_months':
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(3, 'months').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'last_6_months':
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(6, 'months').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'last_12_months':
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(12, 'months').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'last_2_years':
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(2, 'years').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'last_3_years':
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(3, 'years').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'ytd':
        startDate = moment.tz(TIMEZONE).startOf('year').startOf('day').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'last_and_this_year':
        startDate = moment.tz(TIMEZONE).subtract(1, 'year').startOf('year').startOf('day').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'all_time':
        startDate = moment.tz('1970', TIMEZONE).startOf('day').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      default:
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(12, 'months').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
    }
    setStartDate(startDate)
    setEndDate(endDate)
  }

  return (
    <div className={classes.root}>
      <Box padding={2}>
        <Button
          variant='outlined'
          onClick={() => history.push(ROUTES.SETTINGS)}
          size='small'
        >
          Back
        </Button>
        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs={2}>
                  <img
                    className={classes.logoImg}
                    alt='stripe_logo'
                    src={stripeLogoImg}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant='h5' gutterBottom>
                    Stripe Source
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Name
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Box height='100%' display='flex' alignItems='center'>
                    <Typography variant='body2'>
                      {isLoadingIntegration ? <Skeleton variant='text' width={100} /> : `${integration.nickname}`}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Sync
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant='body2' paragraph>
                    To sync data from Stripe with LTV Numbers, select the appropriate data type and click the button below. This will start a process of retrieving data from the entire history of the account and loading them into LTV Numbers. Due to rate limitations inside Stripe, this process can take anywhere from several minutes to several hours.
                  </Typography>
                  <Typography variant='body2' paragraph>
                    There are two types of data that can be synced from Stripe depending on the needs of your account: Invoices and Payments. You can choose either of these options, or you can select both of them.
                  </Typography>
                  <Typography variant='body2' paragraph>
                    It is strongly recommended that you only sync the data that is needed, because payment processors like Stripe can produce redundant transactions with other connected platforms, such as Clickfunnels or Shopify.
                  </Typography>

                  <Typography variant='h6' paragraph>
                    All Invoices
                  </Typography>
                  <Typography variant='body2' paragraph>
                    This refers to all Invoices that exist inside Stripe, including both one-off and subscription invoices. They can be found in the Stripe Dashboard under Payments &gt; Invoices. Invoices are typically created manually for handling one-off transactions, or generated automatically through subscriptions, and they typically have associated product information. It is important to note that not all payments (or &quot;charges&quot;) that Stripe processes have associated invoices.
                  </Typography>
                  <Typography variant='body2' paragraph>
                    Choose this option if you are using Stripe in conjunction with a checkout system like Clickfunnels where subscription rebills are not processed and you would like to ensure that rebills are analyzed. Or, choose this option if you are generating invoices directly in Stripe or using a Stripe companion app like PayFunnels, while also using Stripe connected to your cart platform that LTV Numbers is integrated with. This ensures that we have the best available data from both places without causing redundancy issues. Do NOT use this option if you need to bring in all payments. Choose &quot;Payments&quot; instead in this case.
                  </Typography>

                  <Typography variant='h6' paragraph>
                    Subscription Invoices
                  </Typography>
                  <Typography variant='body2' paragraph>
                    This refers to all Invoices that exist inside Stripe that are associated with subscriptions. It does not include one-off invoices. They can be found in the Stripe Dashboard under Payments &gt; Invoices, then filter by Frequency for &quot;Subscription Invoice&quot;.
                  </Typography>
                  <Typography variant='body2' paragraph>
                    Choose this option if you are using Stripe in conjunction with a checkout system like Clickfunnels where subscription rebills are not processed and you would like to ensure that rebills are analyzed.
                  </Typography>

                  <Typography variant='h6' paragraph>
                    One-off Invoices
                  </Typography>
                  <Typography variant='body2' paragraph>
                    This refers to all Invoices that exist inside Stripe that are not associated with subscriptions. These invoices are typically manually created or managed by a third party service. They can be found in the Stripe Dashboard under Payments &gt; Invoices, then filter by Frequency for &quot;One-off Invoice&quot;.
                  </Typography>
                  <Typography variant='body2' paragraph>
                    Choose this option if you are creating One-off Invoices directly inside of Stripe as part of your sales process in such a way that these sales exist ONLY in Stripe, and not inside other shopping carts that may already be connected to LTV Numbers.
                  </Typography>

                  <Typography variant='h6' paragraph>
                    Payments
                  </Typography>
                  <Typography variant='body2' paragraph>
                    This refers to all Payments processed by Stripe. These can be found in the Stripe Dashboard under Payments &gt; All payments. This will include all payments processed by Stripe regardless of whether there is an associated invoice or not. Note however, that $0.00 Invoices (ie. for free trials) do NOT generate payments and therefore will NOT be synced.
                  </Typography>
                  <Typography variant='body2' paragraph>
                    If payments do not have an associated invoice, then there is no product information that can be pulled. This is why it is highly recommended to use invoices or to integrate LTV Numbers with a cart platform instead of Stripe.
                  </Typography>
                  <Typography variant='body2' paragraph>
                    Choose this option if you are not using Stripe with any other platforms integrated with LTV Numbers and you wish to have all of your Stripe payments analyzed.
                  </Typography>

                  <Typography variant='h6' paragraph>
                    All Invoices and Payments
                  </Typography>
                  <Typography variant='body2' paragraph>
                    While invoices and payments have substantial overlap, there are cases where you just want everything. This include all Invoices and their associated payments, as well as payments with no associated invoice, and any $0 Invoices as well. This is truly <b>everything</b>. In order to provide the best data, payments will always check for an associated invoice to obtain product information before loading into LTV Numbers.
                  </Typography>

                  <Box marginTop={2} display='flex' alignItems='center'>
                    <FormControl
                      variant='outlined'
                      margin='dense'
                      sx={{ margin: 1, minWidth: 200 }}
                      required
                     >
                      <InputLabel id='data-type-label'>Data Type</InputLabel>
                      <Select
                        labelId='data-type-label'
                        id='data-type-select'
                        value={dataType}
                        onChange={handleSelectDataType}
                        label='Data Type'
                      >
                        {dataTypeMenuItems.map(item =>
                          <MenuItem key={item.value} value={item.value}>{item.display}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    <FormControl
                      variant='outlined'
                      margin='dense'
                      sx={{ margin: 1, minWidth: 150 }}
                      required
                     >
                      <InputLabel id='data-type-label'>Date Range</InputLabel>
                      <Select
                        labelId='date-range-label'
                        id='date-range-select'
                        value={dateRange}
                        onChange={event => setDateRange(event.target.value)}
                        label='Date Range'
                      >
                        {dateRangeMenuItems.map(item =>
                          <MenuItem key={item.value} value={item.value}>{item.display}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box>
                    <Button
                      className={classes.syncButton}
                      variant='contained'
                      color='secondary'
                      onClick={() => sync()}
                      disabled={!dataType || !dateRange}
                    >
                      {isSyncing ?
                        <CircularProgress color='inherit' size={24} className={classes.buttonProgress} />
                        : 'Sync Data'
                      }
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Delete
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant='body2' gutterBottom>
                    If you want to remove this data integration so payments are no longer synced, click the button below.
                  </Typography>
                  <Button
                    className={classes.redButton}
                    variant='text'
                    onClick={() => setShowRevokeConfirmation(true)}
                  >
                    Delete Source
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      </Box>
      <Dialog
        open={showRevokeConfirmation}
        onClose={() => setShowRevokeConfirmation(false)}
      >
        <DialogTitle>
          Remove access to account <b>&quot;{integration.nickname}&quot;</b>?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will prevent data from being synced to LTV Numbers.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.redButton}
            variant='text'
            onClick={() => setShowRevokeConfirmation(false)}
          >
            Cancel
          </Button>
          <Button
            color='primary'
            onClick={() => revoke()}
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showMessage}
        autoHideDuration={10000}
        onClose={handleCloseMessage}
      >
        <Alert
          severity='success'
          variant='filled'
          onClose={handleCloseMessage}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  )
}

StripeSource.propTypes = {}

export default StripeSource