import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'

const LoadingSkeleton = (props) => {
  const { classes, height } = props
  return (
    <Fragment>
      <Skeleton variant="rectangular" width={'100%'} height={height} animation={false} />
      <Typography className={classes.summaryTypography} component='div' variant='caption'>
        <Skeleton animation={false} />
        <Skeleton animation={false} />
      </Typography>
    </Fragment>
  );
}

LoadingSkeleton.propTypes = {
  classes: PropTypes.object.isRequired,
  height: PropTypes.number.isRequired,
}

export default LoadingSkeleton
