import React from 'react'
import PropTypes from 'prop-types'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import makeStyles from '@mui/styles/makeStyles'

import TableSwitchToggle from './TableSwitchToggle'

const useStyles = makeStyles(() => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  actionTableCell: {
    position: 'sticky',
    left: 0,
    background: 'white',
    zIndex: 1,
  },
  headCell: {
    fontSize: '.78rem',
    lineHeight: '1.2rem',
    background: 'white',
  },
  nameTableCell: {
    minWidth: 250,
    fontSize: '.78rem',
    lineHeight: '1.2rem',
    position: 'sticky',
    left: 162,
    background: 'white',
    boxShadow: '3px 0 2px -2px #a7a7a7',
    zIndex: 1,
  },
}))

const nameHeadCell = { id: 'name', numeric: false, label: 'Name', allowedAttribution: ['customer', 'lead'] }
const headCells = {
  default: [
    { id: 'ltv_gain', numeric: true, label: 'LTV Gain', allowedAttribution: ['customer', 'lead'] },
    { id: 'customers', numeric: true, label: 'Customers', allowedAttribution: ['customer', 'lead'] },
    { id: 'revenue', numeric: true, label: 'Net Revenue', allowedAttribution: ['customer', 'lead'] },
    { id: 'revenue_gross', numeric: true, label: 'Gross Revenue', allowedAttribution: ['customer', 'lead'] },
    { id: 'revenue_orders', numeric: true, label: 'Order Revenue', allowedAttribution: ['customer', 'lead'] },
    { id: 'revenue_rebills', numeric: true, label: 'Rebill Revenue', allowedAttribution: ['customer', 'lead'] },
    { id: 'revenue_refunds', numeric: true, label: 'Refund Amount', allowedAttribution: ['customer', 'lead'] },
    { id: 'orders', numeric: true, label: 'Orders', allowedAttribution: ['customer', 'lead'] },
    { id: 'rebills', numeric: true, label: 'Rebills', allowedAttribution: ['customer', 'lead'] },
    { id: 'refunds', numeric: true, label: 'Refunds', allowedAttribution: ['customer', 'lead'] },
    { id: 'days_after_frontend', numeric: true, label: 'Days After Frontend (Average)', allowedAttribution: ['customer', 'lead'] },
  ],
}

const EnhancedTableHead = (props) => {
  const { attribution, columns, hideNameColumn, order, orderBy, onRequestSort, onSwitchToggleSelect } = props
  const classes = useStyles()

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  const filteredHeadCells = headCells[columns].filter(item => item.allowedAttribution.indexOf(attribution) > -1)
  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.actionTableCell} align='left'>
          <TableSwitchToggle onToggleClick={onSwitchToggleSelect} />
        </TableCell>
        {!hideNameColumn && (
          <TableCell className={classes.nameTableCell} align='left'>
            {nameHeadCell.label}
          </TableCell>
        )}
        {filteredHeadCells.map((headCell) =>
          <TableCell
            className={classes.headCell}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'desc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  attribution: PropTypes.string.isRequired,
  columns: PropTypes.string.isRequired,
  hideNameColumn: PropTypes.bool.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
}

export default EnhancedTableHead