import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

import makeStyles from '@mui/styles/makeStyles'

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts'

import { formatNumber } from '../../utils/helpers'
import { ALL_DATA_KEY } from '../../constants'

const useStyles = makeStyles(theme => ({
  tooltipRoot: {
    backgroundColor: 'white',
    textAlign: 'right',
    padding: theme.spacing(1),
    border: '1px solid rgb(204, 204, 204)',
    borderRadius: theme.shape.borderRadius,
    maxWidth: 300,
  },
  tooltipDataTypography: {
    margin: 0,
    fontSize: 12,
  },
  tooltipTitleTypography: {
    color: theme.palette.text.secondary,
  },
}))

const LTVMiniGraph = (props) => {
  const { data, lineColor } = props
  const theme = useTheme()

  const DEFAULT_LINE_COLOR = theme.palette.secondary.main

  return (
    <ResponsiveContainer width='100%' height={200}>
      <LineChart width={300} height='100%' data={data}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis
          dataKey='x'
          hide
        />
        <YAxis
          type='number'
          domain={['auto', 'auto']}
          tickFormatter={val => `$${formatNumber(val, 2, true)}`}
          hide
        />
        <Tooltip
          isAnimationActive={false}
          content={<CustomTooltip />}
          wrapperStyle={{ zIndex: 1000 }}
        />

        <Line
          dataKey={ALL_DATA_KEY}
          stroke={lineColor ? lineColor : DEFAULT_LINE_COLOR}
          strokeWidth={2}
          dot={{ r: 0.5, fill: lineColor ? lineColor : DEFAULT_LINE_COLOR }}
          type='linear'
          connectNulls
        />
      </LineChart>
    </ResponsiveContainer>
  )
}


const CustomTooltip = ({ active, payload, label }) => {
  const classes = useStyles()

  if (payload === null) return null

  payload.sort((a, b) => {
    return parseFloat(b.value) - parseFloat(a.value)
  })

  if (active) {
    return (
      <div className={classes.tooltipRoot}>
        <Typography variant='overline'>
          Day {label}
        </Typography>
        {payload.map(datum =>
          <Typography key={datum.dataKey} className={classes.tooltipDataTypography} style={{ color: datum.color }}>
            ${formatNumber(datum.value, 2)}
          </Typography>
        )}
        <Typography className={classes.tooltipTitleTypography} variant='caption'>
          LTV
        </Typography>
      </div>
    )
  }

  return null
}

LTVMiniGraph.propTypes = {
  data: PropTypes.array.isRequired,
  lineColor: PropTypes.string,
}

export default LTVMiniGraph