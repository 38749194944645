import React from 'react'
import Alert from '@mui/material/Alert'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import InputBase from '@mui/material/InputBase'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Snackbar from '@mui/material/Snackbar'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'

import { useHistory, useParams } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'

import { FirebaseContext } from '../../utils/firebase'
import 'firebase/auth'

import { API_ROOT_URL } from '../../constants'
import * as ROUTES from '../../constants/routes'

import buygoodsLogoImg from '../../media/buygoods_logo.png'

const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: 66,
    right: 0,
    width: 'calc(100% - 66px)',
    height: '100vh',
    overflowY: 'auto',
    backgroundColor: theme.palette.grey[50],
  },
  logoImg: {
    width: 100,
  },
  syncButton: {
    width: 110,
  },
  redButton: {
    color: theme.palette.error.main,
    '&:hover': {
      background: 'rgba(255,23,68,0.04)',
    },
  },
}))

const REVOKE_SUCCESS_MESSAGE = 'Account removed. Data will no longer be synced from this account. Redirecting back to settings...'
const WEBHOOK_URL_BASE = `https://us-central1-ltv-numbers-${process.env.REACT_APP_ENV === 'production' ? 'prod' : 'dev'}.cloudfunctions.net/webhooks/buygoods/`

const BuygoodsSource = () => {
  const classes = useStyles()
  const { id } = useParams()
  const history = useHistory()
  const firebase = React.useContext(FirebaseContext)

  const [webhookUrl, setWebhookUrl] = React.useState('')
  const [showCopyTooltip, setShowCopyTooltip] = React.useState(false)

  const [timezone, setTimezone] = React.useState(TIMEZONE)
  const [message, setMessage] = React.useState('')
  const [showMessage, setShowMessage] = React.useState(false)
  const [messageSeverity, setMessageSeverity] = React.useState('success')
  const [showRevokeConfirmation, setShowRevokeConfirmation] = React.useState(false)


  React.useEffect(() => {
    const uid = firebase.auth().currentUser.uid
    setWebhookUrl(WEBHOOK_URL_BASE + uid + '/integration/' + id)
  }, [])

  const {
    isLoading: isLoadingIntegration,
    data: integration = {}
  } = useQuery(['integration-get', id], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      }).then(res => res.json())
    }),
    {
      cacheTime: 15 * 60 * 1000,  // 15 minutes
      staleTime: 15 * 60 * 1000,  // 15 minutes
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data.timezone) {
          setTimezone(data.timezone)
        }
      }
    }
  )

  const { isLoading: isUpdatingTimezone, mutate: updateTimezone } = useMutation(
    newTimezone => firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/buygoods/sync`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          integration_id: id,
          timezone: newTimezone
        }),
      }).then(res => res.json())
    }),
    {
      onSuccess: () => {
        setShowMessage(true)
        setMessage('Timezone successfully updated')
        setMessageSeverity('success')
      },
      onError: () => {
        setShowMessage(true)
        setMessage('Error updating timezone. Please try again, and if problem persists, contact support.')
        setMessageSeverity('error')
      }
    }
  )


  const { isLoading: isLoadingRevoke, refetch: revoke } = useQuery(['revoke-buygoods'], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/buygoods/revoke`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          integration_id: id
        })
      }).then(res => res.json())
    }),
    {
      enabled: false,
      staleTime: Infinity,
      refetchOnReconnect: false,
      onSuccess: () => {
        setShowRevokeConfirmation(false)
        setMessage(REVOKE_SUCCESS_MESSAGE)
        setShowMessage(true)
        setTimeout(() => history.push(ROUTES.SETTINGS), 3000)
      },
    }
  )

  const handleCloseMessage = (_, reason) => {
    if (reason === 'clickaway') return
    setShowMessage(false)
  }

  const handleChangeTimezone = (timezone) => {
    if (!timezone) return
    setTimezone(timezone)
    updateTimezone(timezone)
  }

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
    setShowCopyTooltip(true)
  }

  return (
    <div className={classes.root}>
      <Box padding={2}>
        <Button
          variant='outlined'
          onClick={() => history.push(ROUTES.SETTINGS)}
          size='small'
        >
          Back
        </Button>
        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs={2}>
                  <img
                    className={classes.logoImg}
                    alt='buygoods_logo'
                    src={buygoodsLogoImg}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant='h5' gutterBottom>
                    BuyGoods Source
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Name
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Box height='100%' display='flex' alignItems='center'>
                    <Typography variant='body2'>
                      {isLoadingIntegration ? <Skeleton variant='text' width={100} /> : `${integration.nickname}`}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

            </Box>
          </Paper>
        </Box>
        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Instant Notification URL
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Box height='100%' display='flex' alignItems='center'>
                    {isLoadingIntegration ? <Skeleton variant='text' width={100} /> : (
                      <Box display='flex' flexDirection='row' width='100%'>
                        <InputBase
                          variant='filled'
                          value={webhookUrl}
                          size='small'
                          fullWidth
                          disabled
                        />
                        <Tooltip
                          open={showCopyTooltip}
                          onClose={() => setShowCopyTooltip(false)}
                          leaveDelay={2000}
                          placement='right'
                          title='Copied'
                        >
                          <Button
                            variant='text'
                            color='primary'
                            onClick={() => copyToClipboard(webhookUrl)}
                          >
                            Copy
                          </Button>
                        </Tooltip>
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Timezone
                  </Typography>
                </Grid>

                <Grid item xs={10}>
                  <Typography variant='body2' gutterBottom>
                    Your BuyGoods Account provides data in the same timezone that your account profile is set to. This value must be set properly below to ensure that times are interpreted correctly. Incorrectly setting this value can result in payment times being incorrectly offset from the true value. The timezone can be found in BuyGoods by logging in, clicking on the profile menu at the top right, then selecting &quot;Contact Info&quot;.
                    On the next page, look below for &quot;Timezone&quot; inside the Contact Info section.
                  </Typography>
                  <Typography variant='body2' gutterBottom>
                    It is strongly recommended that you do NOT change this value in your BuyGoods account after setting it below, or it can cause date and time inconsistencies that could affect the integrity of the data.
                  </Typography>

                  <Box marginTop={0} display='flex' alignItems='center'>
                    <FormControl
                      variant='outlined'
                      margin='dense'
                      sx={{ margin: 1, minWidth: 300 }}
                      required
                     >
                      {isLoadingIntegration ? <Skeleton variant='rect' width={300} /> : (
                        <Autocomplete
                          id='timezone'
                          options={moment.tz.names()}
                          value={timezone}
                          onChange={event => handleChangeTimezone(event.target.textContent)}
                          renderInput={(params) => <TextField {...params} label='Timezone' />}
                          loading={isUpdatingTimezone}
                        />
                      )}
                    </FormControl>
                  </Box>

                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Delete
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant='body2' gutterBottom>
                    If you want to remove this data integration so transacations can no longer be saved, click the button below.
                  </Typography>
                  <Button
                    className={classes.redButton}
                    variant='text'
                    onClick={() => setShowRevokeConfirmation(true)}
                  >
                    Delete Source
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      </Box>
      <Dialog
        open={showRevokeConfirmation}
        onClose={() => setShowRevokeConfirmation(false)}
      >
        <DialogTitle>
          Remove access to account <b>&quot;{integration.nickname}&quot;</b>?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will prevent data from being synced to LTV Numbers.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.redButton}
            variant='text'
            onClick={() => setShowRevokeConfirmation(false)}
          >
            Cancel
          </Button>
          <Button
            color='primary'
            onClick={() => revoke()}
          >
            {isLoadingRevoke ? <CircularProgress color='inherit' size={24} /> : 'Remove'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showMessage}
        autoHideDuration={3000}
        onClose={handleCloseMessage}
      >
        <Alert
          severity={messageSeverity}
          variant='filled'
          onClose={handleCloseMessage}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  )
}

BuygoodsSource.propTypes = {}

export default BuygoodsSource