import React, { useContext, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'
import { useHistory } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { useQueryClient } from 'react-query'

import { UserContext } from '../../contexts/UserContext'
import { FirebaseContext } from '../../utils/firebase'
import { createDocument, deleteDocument, getCollectionDocuments, listenToCollectionUpdates, updateDocument } from '../../utils/firestore'
import { getSegmentById, getNextAvailableColor, getSegmentDisplayName } from '../../utils/helpers'
import { DATETIME_RESOLUTION_MAP, FIRESTORE_COLLECTIONS } from '../../constants'
import * as ROUTES from '../../constants/routes'

import LoadingSkeleton from './LoadingSkeleton'
import SCORECARD_BASE_CONFIG from './ScorecardConfig'
import Scorecard from './Scorecard'
import ScorecardDialog from './ScorecardDialog'
import Sidebar from './Sidebar'

const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: 62,
    right: 0,
    width: 'calc(100% - 62px)',
    height: '100vh',
    overflowY: 'auto',
    backgroundColor: theme.palette.grey[50],
  },
}))

const Scorecards = () => {
  const classes = useStyles()
  const firebase = useContext(FirebaseContext)
  const userDoc = useContext(UserContext)
  const history = useHistory()
  const queryClient = useQueryClient()

  const [sidebarTab, setSidebarTab] = useState('saved')
  const [sidebarOpen, setSidebarOpen] = useState(true)
  const [selectedScorecard, setSelectedScorecard] = useState(null)

  const [scorecardCreateDialogOpen, setScorecardCreateDialogOpen] = useState(false)
  const [loadingInitialScorecards, setLoadingInitialScorecards] = useState(false)

  const [savedScorecards, setSavedScorecards] = useState([])
  const [savedSegments, setSavedSegments] = React.useState([])
  const [savedGroups, setSavedGroups] = React.useState([])

  const [liveMode, setLiveMode] = React.useState(false)
  const [startDate, setStartDate] = React.useState(null)
  const [endDate, setEndDate] = React.useState(null)
  const [resolution, setResolution] = React.useState(null)
  const [config, setConfig] = React.useState({})
  const [cards, setCards] = React.useState([])
  const [segments, setSegments] = React.useState([])

  useEffect(() => {
    let isMounted = true
    getIntialSelectedScorecard().then(scorecard => {
      if (isMounted) {
        handleScorecardSelect(scorecard)
      }
    })

    return () => {
      isMounted = false
    }
  }, [])

  // When liveMode is active, update the endDate every minute to handle
  // the date changing while the user is viewing the report
  useEffect(() => {
    let timer = null
    if (liveMode) {
      timer = setInterval(() => {
        // Only update the end date if the current end date is the day before today
        // because that means we have crossed midnight with liveMode enabled
        if (endDate.isSame(moment.tz(TIMEZONE).subtract(1, 'day'), 'day')) {
          setEndDate(moment.tz(TIMEZONE).endOf('day'))
        }
      }, 60000) // Runs every minute
    }
    return () => {
      if (timer) {
        clearInterval(timer)
      }
    }
  }, [liveMode])

  async function getIntialSelectedScorecard() {
    // Get saved scorecards from Firestore
    const savedScorecards = await getCollectionDocuments(firebase, FIRESTORE_COLLECTIONS.SCORECARDS)

    // Try to get initial scorecard metric from the URL
    const scorecardUrlMatch = history.location.pathname.match(/\/scorecards\/([^/]+)/)
    const savedScorecardId = scorecardUrlMatch ? scorecardUrlMatch[1] : null

    // If the scorecard has an ID, then it is a saved scorecard
    if (savedScorecardId) {
      const savedScorecard = savedScorecards.find(r => r.id === savedScorecardId)

      if (savedScorecard) return savedScorecard

      // If the saved scorecard can't be found, then the ID is invalid
      // so return null to prompt the user to select or create a scorecard
      else return null
    }
    // TODO: If there is a default scorecard saved in Firestore, use that.
    else {
      // For now, return null to prompt user to create a scorecard
      return null
    }
  }

  function getCardsFromScorecard(scorecard) {
    return scorecard.cards
  }

  function getStartDateFromScorecard(scorecard) {
    if (scorecard.dateRangeType === 'dynamic') {
      return moment.tz(TIMEZONE).subtract(scorecard.dateRangeDynamicDays, 'days').startOf('day')
    } else if (scorecard.dateRangeType === 'fixed') {
      return moment.tz(scorecard.dateRangeFixedStartDate, TIMEZONE).startOf('day')
    }
  }

  function getEndDateFromScorecard(scorecard) {
    if (scorecard.dateRangeType === 'dynamic') {
      return moment.tz(TIMEZONE).subtract(1,'day').endOf('day')
    } else if (scorecard.dateRangeType === 'fixed') {
      return moment.tz(scorecard.dateRangeFixedEndDate, TIMEZONE).endOf('day')
    }
  }

  function getResolutionFromScorecard(scorecard) {
    return scorecard.resolution
  }

  function getConfigFromScorecard(scorecard) {
    return scorecard.config
  }

  function getSegmentsFromScorecard(scorecard) {
    if (scorecard.segments.length === 0) {
      return [{ ...createNewSegment(true) }]
    } else {
      return scorecard.segments
    }
  }

  function mergeSavedScorecardWithBaseConfig(savedScorecard) {
    // Combine the core scorecard with the saved scorecard to ensure that all necessary
    // parameters are present, and the saved scorecard takes precedence
    return { ...SCORECARD_BASE_CONFIG, ...savedScorecard }
  }

  const createNewSegment = (isInitialization=false) => {
    const newSegment = {
      id: uuidv4(),
      segmentFilters: [],
      filters: [],
      breakdown: null,
      isActive: true,
      color: isInitialization ? getNextAvailableColor([], userDoc.secondaryColor) : getNextAvailableColor(segments, userDoc.secondaryColor),
      breakdownState: {},
      savedSegmentEditId: null,
      revertSegmentData: null,
    }
    return updateSegment(newSegment, {})
  }

  const updateSegment = (segment, updates, newSavedSegment=null) => {
    const updatedSegment = {
      ...segment,
      ...updates,
      name: getSegmentDisplayName({ ...segment, ...updates }, newSavedSegment ? [...savedSegments, newSavedSegment] : savedSegments),
    }
    return updatedSegment
  }

  const handleSidebarOpenToggle = (open) => {
    setSidebarOpen(open)
  }

  const handleSidebarTabSelect = (newTab) => {
    setSidebarTab(newTab)
  }

  const handleSidebarSavedScorecardSelect = (savedScorecard) => {
    handleScorecardSelect(savedScorecard)
  }

  const handleScorecardSelect = (newScorecard) => {
    initializeScorecardState(newScorecard)

    let newPath = ROUTES.SCORECARDS

    // Saved scorecards must be merged with the base config
    // to ensure all config is present for populating the UI
    if (newScorecard) {
      newScorecard = mergeSavedScorecardWithBaseConfig(newScorecard)
      newPath += `/${newScorecard.id}`
    }

    setSelectedScorecard(newScorecard)
    history.push(newPath)
  }

  const initializeScorecardState = (scorecard) => {
    if (!scorecard) {
      setCards(getCardsFromScorecard(SCORECARD_BASE_CONFIG))
      setStartDate(getStartDateFromScorecard(SCORECARD_BASE_CONFIG))
      setEndDate(getEndDateFromScorecard(SCORECARD_BASE_CONFIG))
      setResolution(getResolutionFromScorecard(SCORECARD_BASE_CONFIG))
      setConfig(getConfigFromScorecard(SCORECARD_BASE_CONFIG))
      setSegments(getSegmentsFromScorecard(SCORECARD_BASE_CONFIG))
    } else {
      setCards(getCardsFromScorecard(scorecard))
      setStartDate(getStartDateFromScorecard(scorecard))
      setEndDate(getEndDateFromScorecard(scorecard))
      setResolution(getResolutionFromScorecard(scorecard))
      setConfig(getConfigFromScorecard(scorecard))
      setSegments(getSegmentsFromScorecard(scorecard))
    }
  }

  const handleScorecardSave = async (scorecardId, formData, saveAs=false) => {
    let scorecardToSave = {
      kind: 'saved',
      ...SCORECARD_BASE_CONFIG,
    }

    // If updating or "saving as", use state to complete the scorecard object
    if (scorecardId || saveAs) {
      scorecardToSave = {
        ...scorecardToSave,
        ...formatScorecardObjectFromState(),
      }
    }

    // Add formData to the scorecard object
    scorecardToSave = {
      ...scorecardToSave,
      ...formData,
    }

    // If scorecardId is provided, update existing scorecard
    if (scorecardId) {
      await updateDocument(firebase, FIRESTORE_COLLECTIONS.SCORECARDS, scorecardId, scorecardToSave)
    }
    // Create a new scorecard
    else {
      scorecardId = await createDocument(firebase, FIRESTORE_COLLECTIONS.SCORECARDS, scorecardToSave)
    }
    handleScorecardSelect({ id: scorecardId, ...scorecardToSave })
  }

  const handleScorecardDelete = async (scorecardId) => {
    await deleteDocument(firebase, FIRESTORE_COLLECTIONS.SCORECARDS, scorecardId)

    // Set the selected scorecard to null to prompt the user to select or create a new scorecard
    handleScorecardSelect(null)
  }

  const formatScorecardObjectFromState = () => {
    let scorecardData = {
      // These are based on current values from the state
      cards: cards ? cards : SCORECARD_BASE_CONFIG.cards,
      resolution: resolution ? resolution : SCORECARD_BASE_CONFIG.resolution,
      config: Object.keys(config).length > 0 ? config : SCORECARD_BASE_CONFIG.config,
      segments: segments.length > 0 ? segments : SCORECARD_BASE_CONFIG.segments,
    }

    return scorecardData
  }

  const handleLiveModeChange = (newLiveMode) => {
    setLiveMode(newLiveMode)
  }

  const handleDatesChange = ({ startDate: newStartDate, endDate: newEndDate }) => {
    setStartDate(newStartDate)
    setEndDate(newEndDate)
    // If live mode is enabled and today is not in the date range, disable live mode
    // because live mode is only available when today is in the date range
    if (liveMode && !moment.tz(TIMEZONE).isBetween(newStartDate, newEndDate, 'day', '[]')) {
      setLiveMode(false)
    }
  }

  const handleAdjustDateRangeToCompletePeriods = () => {
    // Get moment resolution for the current resolution
    const momentResolution = resolution === 1 ? 'isoWeek' : DATETIME_RESOLUTION_MAP[resolution]

    // Adjust the start date to the first day of the period
    const newStartDate = moment.tz(startDate, TIMEZONE).startOf(momentResolution)

    // Adjust the end date to the last day of the previous period, unless it's already the last day of the period
    let newEndDate = moment.tz(endDate, TIMEZONE);
    if (!newEndDate.isSame(newEndDate.clone().endOf(momentResolution))) {
      newEndDate = newEndDate.subtract(1, momentResolution).endOf(momentResolution);
    }

    handleDatesChange({ startDate: newStartDate, endDate: newEndDate })
  }

  const handleSegmentAdd = (segmentFilterToInclude=null) => {
    const newSegment = updateSegment(createNewSegment(), {
      segmentFilters: segmentFilterToInclude ? [segmentFilterToInclude] : [],
    })
    setSegments(prevSegments => [newSegment, ...prevSegments])
  }

  const handleSegmentDelete = (segmentId) => {
    setSegments(prevSegments => prevSegments.filter(segment => segment.id !== segmentId))
  }

  const handleSegmentDuplicate = (segmentId) => {
    const segment = getSegmentById(segments, segmentId)
    const segmentIndex = segments.findIndex(segment => segment.id === segmentId)
    const newSegment = {
      ...createNewSegment(),
      name: segment.name,
      segmentFilters: segment.segmentFilters,
      filters: segment.filters,
    }
    // Insert the duplicated segment right after the segment being duplicated
    setSegments(prevSegments => [
      ...prevSegments.slice(0, segmentIndex + 1),
      newSegment,
      ...prevSegments.slice(segmentIndex + 1),
    ])
  }

  const handleSegmentEditChange = (segmentId, savedSegmentEditId) => {
    // Find savedSegmentEditId in savedSegents, get the segmentFilters and filters from the saved segment
    // and append them to those fields in segment of segmentId
    const savedSegment = savedSegments.find(savedSegment => savedSegment.id === savedSegmentEditId)
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId) {
        return updateSegment(segment, {
          savedSegmentEditId,
          revertSegmentData: segment,
          segmentFilters: [...savedSegment.segmentFilters],
          filters: [...savedSegment.filters, ...segment.filters],
        })
      } else {
        return segment
      }
    }))
  }

  const handleSegmentSaveEdit = (segmentId) => {
    const segment = getSegmentById(segments, segmentId)
    const savedSegment = getSegmentById(savedSegments, segment.savedSegmentEditId)
    const segmentToSave = {
      name: savedSegment.name,
      segmentFilters: segment.segmentFilters,
      filters: segment.filters,
    }
    updateDocument(firebase, FIRESTORE_COLLECTIONS.SEGMENTS, savedSegment.id, segmentToSave)
    const newSegmentFilter = { id: savedSegment.id }

    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId) {
        const segmentUpdate = {
          savedSegmentEditId: null,
          revertSegmentData: null,
          segmentFilters: [newSegmentFilter],
          filters: [],
        }

        // Force a refresh of the queries
        queryClient.refetchQueries()

        return updateSegment(segment, segmentUpdate)
      } else {
        return segment
      }
    }))
  }

  const handleSegmentRevertEdit = (segmentId) => {
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId) {
        return updateSegment(segment, {
          ...segment.revertSegmentData
        })
      } else {
        return segment
      }
    }))
  }

  const handleSegmentIsActiveChange = (segmentId, isActive) => {
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId) {
        return updateSegment(segment, { isActive })
      } else {
        return segment
      }
    }))
  }

  const handleSegmentColorChange = (segmentId, newColor) => {
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId) {
        return updateSegment(segment, { color: newColor })
      } else {
        return segment
      }
    }))
  }

  const handleSegmentFilterAdd = (segmentId, segmentFilter) => {
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId) {
        const segmentFilters = [...segment.segmentFilters, segmentFilter]
        return updateSegment(segment, { segmentFilters })
      } else {
        return segment
      }
    }))
  }

  const handleSegmentReplaceFiltersWithSavedSegmentFilter = (segmentId, newSavedSegment) => {
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId) {
        const newSegmentFilter = { id: newSavedSegment.id }
        return updateSegment(segment, { segmentFilters: [newSegmentFilter], filters: [] }, newSavedSegment)
      } else {
        return segment
      }
    }))
  }

  const handleSegmentFilterDelete = (segmentId, segmentFilterId) => {
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId) {
        const segmentFilters = segment.segmentFilters.filter(filter => filter.id !== segmentFilterId)
        return updateSegment(segment, { segmentFilters })
      } else {
        return segment
      }
    }))
  }

  const handleSegmentsReorder = (newSegments) => {
    setSegments(newSegments)
  }

  const handleFilterAdd = (segmentId, filter) => {
    // if segmentId is null, add filter to all segments
    // else add filter to the segment with the matching segmentId
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segmentId === null || segment.id === segmentId) {
        const filters = [...segment.filters, filter]
        return updateSegment(segment, { filters })
      } else {
        return segment
      }
    }))
  }

  const handleFilterUpdate = (segmentId, filterIndex, filter) => {
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId) {
        // Find the filter at the index and replace it with 'filter'
        const filters = segment.filters.map((f, i) => i === filterIndex ? filter : f)
        return updateSegment(segment, { filters })
      } else {
        return segment
      }
    }))
  }

  const handleFilterDelete = (segmentId, filterIndex) => {
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId) {
        const filters = [...segment.filters]
        filters.splice(filterIndex, 1)
        return updateSegment(segment, { filters })
      } else {
        return segment
      }
    }))
  }

  const handleClearAllSegmentFilters = (segmentId) => {
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId) {
        return updateSegment(segment, { segmentFilters: [], filters: [] })
      } else {
        return segment
      }
    }))
  }

  const handleSegmentBreakdownSelect = (segmentId, breakdown) => {
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId && segment.breakdown?.field !== breakdown?.field) {
        return updateSegment(segment, {
          breakdown,
          breakdownState: breakdown ? segment.breakdownState : {},
        })
      } else {
        return segment
      }
    }))
  }

  const handleSegmentBreakdownIsActiveChange = (segmentId, breakdownKey, isActive) => {
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId) {
        const breakdownState = {
          ...segment.breakdownState,
          [breakdownKey]: {
            isActive,
            color: isActive ? getNextAvailableColor(prevSegments) : null,
          }
        }
        return updateSegment(segment, { breakdownState })
      } else {
        return segment
      }
    }))
  }

  const handleSegmentBreakdownColorChange = (segmentId, breakdownKey, newColor) => {
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId) {
        const breakdownState = {
          ...segment.breakdownState,
          [breakdownKey]: {
            ...segment.breakdownState[breakdownKey],
            color: newColor
          }
        }
        return updateSegment(segment, { breakdownState })
      } else {
        return segment
      }
    }))
  }

  const handleResolutionChange = (newResolution) => {
    setResolution(newResolution)
  }

  const handleCardAdd = (card) => {
    setCards(prevCards => [...prevCards, card])
  }

  const handleCardUpdate = (metricKey, card) => {
    setCards(prevCards => prevCards.map(c => c.metricKey === metricKey ? card : c))
  }

  const handleCardDelete = (metricKey) => {
    setCards(prevCards => prevCards.filter(c => c.metricKey !== metricKey))
  }

  const handleCardReorder = (metricKey, direction) => {
    setCards(prevCards => {
      const currentCardIndex = prevCards.findIndex(c => c.metricKey === metricKey)
      if (currentCardIndex < 0) return prevCards // card not found

      const targetCardIndex = direction === 'left' ? currentCardIndex - 1 : currentCardIndex + 1
      if (targetCardIndex < 0 || targetCardIndex >= prevCards.length) return prevCards // new index out of bounds

      const reorderedCards = [...prevCards]
      const currentCard = reorderedCards[currentCardIndex]
      reorderedCards[currentCardIndex] = reorderedCards[targetCardIndex]
      reorderedCards[targetCardIndex] = currentCard

      return reorderedCards
    })
  }

  const handleConfigChange = (key, newValue) => {
    setConfig(prevConfig => ({
      ...prevConfig,
      [key]: newValue
    }))
  }

  const handleCreateScorecardClick = () => {
    setScorecardCreateDialogOpen(true)
  }

  const regenerateSegmentNames = () => {
    setSegments(prevSegments => prevSegments.map(segment => {
      return updateSegment(segment, {})
    }))
  }

  // Realtime listener for Firestore scorecards collection
  useEffect(() => {
    setLoadingInitialScorecards(true)
    const unsubscribe = listenToCollectionUpdates(firebase, FIRESTORE_COLLECTIONS.SCORECARDS, snapshot => {
      const newDocs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
      setSavedScorecards(newDocs)
      setLoadingInitialScorecards(false)
    })
    return unsubscribe
  }, [firebase])

  // Realtime listener for Firestore segments collection
  useEffect(() => {
    const unsubscribe = listenToCollectionUpdates(firebase, FIRESTORE_COLLECTIONS.SEGMENTS, snapshot => {
      const newDocs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
      setSavedSegments(newDocs)
    })
    return unsubscribe
  }, [firebase])

  // Realtime listener for Firestore groups collection
  useEffect(() => {
    const unsubscribe = listenToCollectionUpdates(firebase, FIRESTORE_COLLECTIONS.GROUPS, snapshot => {
      const newDocs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
      setSavedGroups(newDocs)
    })
    return unsubscribe
  }, [firebase])

  // Listen for changes to savedSegments to clean up delete segments if needed
  useEffect(() => {
    // Check the segmentFilters of each segment for any references to deleted saved segments
    segments.forEach(segment => {
      segment.segmentFilters.forEach(segmentFilter => {
        if (!savedSegments.find(savedSegment => savedSegment.id === segmentFilter.id)) {
          handleSegmentFilterDelete(segment.id, segmentFilter.id)
        }
      })
    })
    // Regenerate all segment names in case a referenced item was renamed
    regenerateSegmentNames()
  }, [savedSegments])

  // Listen for changes to savedGroups to clean up delete segments if needed
  useEffect(() => {
    // Check the filters of each segment for any references to deleted saved groups
    segments.forEach((segment, segmentIndex) => {
      segment.filters.forEach(filter => {
        filter.groupIds.forEach(groupId => {
          if (!savedGroups.find(savedGroup => savedGroup.id === groupId)) {
            handleFilterDelete(segmentIndex, filter)
          }
        })
      })
    })
    // Regenerate all segment names in case a referenced item was renamed
    regenerateSegmentNames()
  }, [savedGroups])

  return (
    <Box className={classes.root} display='flex' flexDirection='row' >
      <Sidebar
        pluralItemName={'scorecards'}
        isOpen={sidebarOpen}
        savedItems={savedScorecards}
        tab={sidebarTab}
        selectedItem={selectedScorecard}
        allowCreateSavedItem={true}
        allowOnlySavedTab={true}
        groupSavedItemsByKey={false}
        onTabSelect={handleSidebarTabSelect}
        onSavedItemSelect={handleSidebarSavedScorecardSelect}
        onOpenToggle={handleSidebarOpenToggle}
        onCreateSavedItemClick={handleCreateScorecardClick}
      />

      {/* Create Scorecard Dialog */}
      {scorecardCreateDialogOpen && (
        <ScorecardDialog
          open={scorecardCreateDialogOpen}
          onClose={() => setScorecardCreateDialogOpen(false)}
          scorecard={null}
          startDate={startDate}
          endDate={endDate}
          onScorecardSave={handleScorecardSave}
        />
      )}

      {selectedScorecard === null && loadingInitialScorecards && (
        <Box width='100%' padding={4}>
          <LoadingSkeleton animation={'wave'} height='100%' width='100%' />
        </Box>
      )}

      {selectedScorecard === null && !loadingInitialScorecards && (
        <Box
          width='100%'
          height='100%'
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          rowGap={2}
        >
          <Typography variant='h6' color='textSecondary'>
            Select a scorecard from the sidebar or create a new one below.
          </Typography>
          <Button
            variant='contained'
            color='secondary'
            disableElevation
            onClick={() => setScorecardCreateDialogOpen(true)}
          >
            Create Scorecard
          </Button>
        </Box>
      )}

      {selectedScorecard && (
        <Scorecard
          scorecard={selectedScorecard}
          savedSegments={savedSegments}
          savedGroups={savedGroups}
          liveMode={liveMode}
          startDate={startDate}
          endDate={endDate}
          resolution={resolution}
          config={config}
          cards={cards}
          segments={segments}
          onScorecardSave={handleScorecardSave}
          onScorecardDelete={handleScorecardDelete}
          onLiveModeChange={handleLiveModeChange}
          onDatesChange={handleDatesChange}
          onResolutionChange={handleResolutionChange}
          onAdjustDateRangeToCompletePeriods={handleAdjustDateRangeToCompletePeriods}
          onConfigChange={handleConfigChange}
          onCardAdd={handleCardAdd}
          onCardUpdate={handleCardUpdate}
          onCardDelete={handleCardDelete}
          onCardReorder={handleCardReorder}
          onSegmentAdd={handleSegmentAdd}
          onSegmentDelete={handleSegmentDelete}
          onSegmentDuplicate={handleSegmentDuplicate}
          onSegmentEditChange={handleSegmentEditChange}
          onSegmentSaveEdit={handleSegmentSaveEdit}
          onSegmentRevertEdit={handleSegmentRevertEdit}
          onSegmentColorChange={handleSegmentColorChange}
          onSegmentIsActiveChange={handleSegmentIsActiveChange}
          onSegmentFilterAdd={handleSegmentFilterAdd}
          onSegmentReplaceFiltersWithSavedSegmentFilter={handleSegmentReplaceFiltersWithSavedSegmentFilter}
          onSegmentFilterDelete={handleSegmentFilterDelete}
          onSegmentsReorder={handleSegmentsReorder}
          onSegmentBreakdownSelect={handleSegmentBreakdownSelect}
          onSegmentBreakdownColorChange={handleSegmentBreakdownColorChange}
          onSegmentBreakdownIsActiveChange={handleSegmentBreakdownIsActiveChange}
          onFilterAdd={handleFilterAdd}
          onFilterUpdate={handleFilterUpdate}
          onFilterDelete={handleFilterDelete}
          onClearAllSegmentFilters={handleClearAllSegmentFilters}
        />
      )}
    </Box>
  )
}

Scorecards.propTypes = {
}

export default Scorecards