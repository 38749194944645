import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Fade from '@mui/material/Fade'
import Popover from '@mui/material/Popover'
import TextField from '@mui/material/TextField'
import makeStyles from '@mui/styles/makeStyles'
import DateRangeIcon from '@mui/icons-material/DateRange'

import { FirebaseContext } from '../../utils/firebase'
import 'firebase/auth'

import {
  useQuery,
} from 'react-query'
import moment from 'moment-timezone'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import '../../css/react_dates_overrides.css'
import { DayPickerRangeController } from 'react-dates'
import { ANCHOR_LEFT, ANCHOR_RIGHT, START_DATE, END_DATE } from 'react-dates/constants'

import { API_ROOT_URL } from '../../constants'

const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const useStyles = makeStyles(theme => ({
  root: {},
  datePickerShortcutsContainer: {
    textAlign: 'center',
  },
  datePickerShortcuts: {
    margin: theme.spacing(1) / 2,
  },
  popover: {
    marginTop: theme.spacing(0.5),
  },
  controlButton: {
    textTransform: 'none',
  },
  buttonGroupLast: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: 'none',
    '&:hover': {
      borderLeft: 'none',
    },
  },
}))

const CustomDateRangePicker = (props) => {
  const classes = useStyles()
  const firebase = React.useContext(FirebaseContext)
  const [focusedInput, setFocusedInput] = React.useState(START_DATE)

  const [inputStartDate, setInputStartDate] = React.useState(props.startDate)
  const [inputEndDate, setInputEndDate] = React.useState(props.endDate)
  const [dateRangeAnchorEl, setDateRangeAnchorEl] = React.useState(null)

  const [textFieldStartDate, setTextFieldStartDate] = React.useState(props.startDate.format('YYYY-MM-DD'))
  const [textFieldEndDate, setTextFieldEndDate] = React.useState(props.endDate.format('YYYY-MM-DD'))
  const [textFieldStartDateError, setTextFieldStartDateError] = React.useState(false)
  const [textFieldEndDateError, setTextFieldEndDateError] = React.useState(false)

  // Reflects changes to start and endDate in the input dates
  React.useEffect(() => {
    setInputStartDate(props.startDate)
    setInputEndDate(props.endDate)
  }, [props.startDate, props.endDate])

  // Reflect changes to dates using picker in the text fields
  React.useEffect(() => {
    setTextFieldStartDate(inputStartDate.format('YYYY-MM-DD'))
    // Handle case when inputEndDate is null when starting new range
    if (inputEndDate) {
      setTextFieldEndDate(inputEndDate.format('YYYY-MM-DD'))
    } else {
      setTextFieldEndDate(inputStartDate.format('YYYY-MM-DD'))
    }
  }, [inputStartDate, inputEndDate])

  const { isLoading, data } = useQuery(['date_range'], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/dates?tz=${TIMEZONE}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      }).then(res => res.json())
    }),
    {
      cacheTime: 15 * 60 * 1000,  // 15 minutes
      staleTime: 15 * 60 * 1000,  // 15 minutes
      refetchInterval: 60 * 60 * 1000,  // 60 minutes
      refetchOnWindowFocus: false,
    }
  )

  const handleClickDatePickerShortcut = value => {
    const todayMoment = moment.tz(TIMEZONE).endOf('day')
    let newStartDate
    let newEndDate
    switch (value) {
      case 7:
      case 14:
      case 30:
      case 90:
      case 180:
      case 365:
        newStartDate = moment.tz(TIMEZONE).subtract(value, 'days').startOf('day')
        newEndDate = todayMoment.subtract(1, 'days')
        break
      case 'mtd':
        newStartDate = moment.tz(TIMEZONE).startOf('month')
        newEndDate = todayMoment
        break
      case 'ytd':
        newStartDate = moment.tz(TIMEZONE).startOf('year')
        newEndDate = todayMoment
        break
      case 'all':
        newStartDate = data ? moment.tz(data.first_date, TIMEZONE) : props.startDate
        newEndDate = todayMoment
        break
      default:
        newStartDate = inputStartDate
        newEndDate = inputEndDate
        break
    }
    setInputStartDate(newStartDate)
    setInputEndDate(newEndDate)
    setFocusedInput(START_DATE)
  }

  const handleInputDatesChange = ({ startDate: newStartDate, endDate: newEndDate }) => {
    setInputStartDate(newStartDate ? newStartDate.clone().startOf('day') : null)
    setInputEndDate(newEndDate ? newEndDate.clone().endOf('day') : null)
  }

  const handleDateRangeClick = (event) => {
    setDateRangeAnchorEl(event.currentTarget)
  }

  const handleDateRangeClose = () => {
    setDateRangeAnchorEl(null)
  }

  const handleFocusChange = (newFocusedInput) => {
    if (!newFocusedInput) {
      setFocusedInput(START_DATE)
    } else {
      setFocusedInput(newFocusedInput)
    }
  }

  const formatTextFieldDate = (value, inputType) => {
    let formattedValue = value
    if (inputType === 'deleteContentBackward') {
      return formattedValue
    }

    const re = /([0-9]{4})(-*([0-9]{1,2})(-*([0-9]{1,2}))?)?/
    const match = value.match(re)
    if (match) {
      const year = match[1]
      const month = match[3] || ''
      const day = match[5] || ''
      formattedValue = `${year}-${month.length === 2 ? month + '-' : month}${day ? day : ''}`
    }

    return formattedValue
  }

  const handleStartDateTextFieldChange = (event) => {
    const formattedValue = formatTextFieldDate(event.target.value, event.nativeEvent.inputType)
    setTextFieldStartDate(formattedValue)

    const dateIsValid = event.target.value.length === 10 && moment.tz(event.target.value, TIMEZONE).isValid() && moment.tz(event.target.value, TIMEZONE).isSameOrBefore(moment.tz(TIMEZONE))
    if (dateIsValid) {
      const newStartDate = moment.tz(event.target.value, TIMEZONE)
      setInputStartDate(newStartDate)
      if (newStartDate.isAfter(inputEndDate)) {
        setInputEndDate(newStartDate)
      }
      setTextFieldStartDateError(false)
    } else {
      setTextFieldStartDateError(true)
    }
  }

  const handleEndDateTextFieldChange = (event) => {
    const formattedValue = formatTextFieldDate(event.target.value, event.nativeEvent.inputType)
    setTextFieldEndDate(formattedValue)

    const dateIsValid = event.target.value.length === 10 && moment.tz(event.target.value, TIMEZONE).isValid() && moment.tz(event.target.value, TIMEZONE).isSameOrBefore(moment.tz(TIMEZONE))
    if (dateIsValid) {
      const newEndDate = moment.tz(event.target.value, TIMEZONE).endOf('day')
      setInputEndDate(newEndDate)
      if (newEndDate.isBefore(inputStartDate)) {
        setInputStartDate(newEndDate)
      }
      setTextFieldEndDateError(false)
    } else {
      setTextFieldEndDateError(true)
    }
  }

  const handleKeyDownDateTextField = (e) => {
    if (e.keyCode == 13) {
      handleApplyDates()
    }
  }

  const handleApplyDates = () => {
    handleDateRangeClose()
    props.onDatesChange({ startDate: inputStartDate, endDate: inputEndDate ? inputEndDate : inputStartDate })
  }

  return (
    <Box className={classes.root} display='flex'>
      <Button
        className={classes.controlButton}
        variant='outlined'
        startIcon={<DateRangeIcon />}
        onClick={handleDateRangeClick}
        classes={{
          root: props.isButtonGroupLast ? classes.buttonGroupLast : null
        }}
        sx={{
          backgroundColor: theme => theme.palette.background.paper
        }}
      >
        {props.startDate.format('MMM D, YYYY')}&#8212;{props.endDate.format('MMM D, YYYY')}
      </Button>

      <Popover
        className={classes.popover}
        open={Boolean(dateRangeAnchorEl)}
        anchorEl={dateRangeAnchorEl}
        onClose={handleDateRangeClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: props.anchorDirection, }}
        transformOrigin={{ vertical: 'top', horizontal: props.anchorDirection, }}
        TransitionComponent={Fade}
      >
        <Box>
          <Box padding={2} display='flex' justifyContent='center'>
            <Box flexGrow={1} marginRight={1}>
              <TextField
                label='Start'
                variant='outlined'
                color='primary'
                value={textFieldStartDate}
                onChange={handleStartDateTextFieldChange}
                onKeyDown={handleKeyDownDateTextField}
                onFocus={() => setFocusedInput(START_DATE)}
                placeholder='YYYY-MM-DD'
                helperText='YYYY-MM-DD'
                error={textFieldStartDateError}
                size='small'
                fullWidth
              />
            </Box>
            <Box flexGrow={1} marginLeft={1}>
              <TextField
                label='End'
                variant='outlined'
                color='primary'
                value={textFieldEndDate}
                onChange={handleEndDateTextFieldChange}
                onKeyDown={handleKeyDownDateTextField}
                onFocus={() => setFocusedInput(END_DATE)}
                placeholder='YYYY-MM-DD'
                helperText='YYYY-MM-DD'
                error={textFieldEndDateError}
                size='small'
                fullWidth
              />
            </Box>
          </Box>
          <Box>
            <DayPickerRangeController
              startDate={inputStartDate}
              endDate={inputEndDate}
              onDatesChange={handleInputDatesChange}
              focusedInput={focusedInput}
              onFocusChange={focusedInput => handleFocusChange(focusedInput)}
              firstDayOfWeek={1}
              isOutsideRange={dayMoment => dayMoment.isAfter(moment.tz(TIMEZONE), 'day')}
              initialVisibleMonth={() => moment(inputEndDate ? inputEndDate : undefined).subtract(1, 'M')}
              minimumNights={0}
              numberOfMonths={2}
              calendarInfoPosition='top'
              renderCalendarInfo={() => <DateRangeShortcuts onClick={handleClickDatePickerShortcut} loadingAll={isLoading} />}
              transitionDuration={0}
              hideKeyboardShortcutsPanel
            />
          </Box>
          <Box padding={2} display='flex' justifyContent='space-between'>
            <Button
              variant='contained'
              color='inherit'
              onClick={handleDateRangeClose}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              color='secondary'
              onClick={handleApplyDates}
              disabled={textFieldStartDateError || textFieldEndDateError}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Popover>
    </Box>
  )
}

const DateRangeShortcuts = (props) => {
  const classes = useStyles()
  return (
    <div className={classes.datePickerShortcutsContainer}>
      <Button
        className={classes.datePickerShortcuts}
        variant='text'
        color='secondary'
        size='small'
        onClick={() => props.onClick(7)}
      >
        Last 7
      </Button>
      <Button
        className={classes.datePickerShortcuts}
        variant='text'
        color='secondary'
        size='small'
        onClick={() => props.onClick(14)}
      >
        Last 14
      </Button>
      <Button
        className={classes.datePickerShortcuts}
        variant='text'
        color='secondary'
        size='small'
        onClick={() => props.onClick(30)}
      >
        Last 30
      </Button>
      <Button
        className={classes.datePickerShortcuts}
        variant='text'
        color='secondary'
        size='small'
        onClick={() => props.onClick(90)}
      >
        Last 90
      </Button>
      <Button
        className={classes.datePickerShortcuts}
        variant='text'
        color='secondary'
        size='small'
        onClick={() => props.onClick(180)}
      >
        Last 180
      </Button>
      <Button
        className={classes.datePickerShortcuts}
        variant='text'
        color='secondary'
        size='small'
        onClick={() => props.onClick(365)}
      >
        Last 365
      </Button>
      <Button
        className={classes.datePickerShortcuts}
        variant='text'
        color='secondary'
        size='small'
        onClick={() => props.onClick('mtd')}
      >
        MTD
      </Button>
      <Button
        className={classes.datePickerShortcuts}
        variant='text'
        color='secondary'
        size='small'
        onClick={() => props.onClick('ytd')}
      >
        YTD
      </Button>
      <Button
        className={classes.datePickerShortcuts}
        variant='text'
        color='secondary'
        size='small'
        onClick={() => props.onClick('all')}
        disabled={props.loadingAll}
      >
        All
      </Button>
    </div>
  )
}

CustomDateRangePicker.defaultProps = {
  anchorDirection: ANCHOR_LEFT,
  isButtonGroupLast: false,
}

CustomDateRangePicker.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onDatesChange: PropTypes.func.isRequired,
  anchorDirection: PropTypes.oneOf([ANCHOR_LEFT, ANCHOR_RIGHT]),
  isButtonGroupLast: PropTypes.bool,
}

export default CustomDateRangePicker
