import React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import makeStyles from '@mui/styles/makeStyles'

import ContextChipFilter from '../../Common/ContextChip/ContextChipFilter'

import { groupByKey } from '../../../utils/helpers'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  clearButton: {
    border: '1px solid rgba(255,23,68,0.5)',
    color: theme.palette.error.main,
    '&:hover': {
      background: 'rgba(255,23,68,0.04)',
    }
  },
  itemEnter: {
    opacity: 0,
  },
  itemEnterActive: {
    opacity: 1,
    transition: 'opacity 500ms ease-in',
  },
  itemExit: {
    opacity: 1,
  },
  itemExitActive: {
    opacity: 0,
    transition: 'opacity 500ms ease-in',
  },
}))

const Filters = (props) => {
  const { filters, onFilterDelete, onFilterDeleteAll } = props
  const classes = useStyles()

  const handleClear = () => {
    onFilterDeleteAll()
  }

  // Group filters with the same key into single filter chip for display purposes
  const groupedFilters = groupByKey(filters, 'id')
  let displayFilters = []
  Object.keys(groupedFilters).forEach(key => {
    const theseFilters = groupedFilters[key]
    if (theseFilters.length === 1) displayFilters.push(theseFilters[0])
    else {
      theseFilters.sort((a, b) => {
        if (a.comparison > b.comparison) return -1
        else if (a.comparison === b.comparison) return 0
        else if (a.comparison < b.comparison) return 1
        return 0
      })
      displayFilters.push({
        id: key,
        name: theseFilters[0].name,
        value: theseFilters.map(filter => filter.value).join(', '),
        valueName: theseFilters.map(filter => filter.valueName).join(' and ')
      })
    }
  })

  return (
    <div className={classes.root}>
      {displayFilters.map(filter =>
        <ContextChipFilter key={filter.id} filter={filter} onDelete={onFilterDelete} />
      )}
      {filters.length > 0 && (
        <Button
          className={classes.clearButton}
          variant='outlined'
          onClick={handleClear}
        >
          Clear
        </Button>
      )}
    </div>
  )
}

Filters.propTypes = {
  filters: PropTypes.array.isRequired,
  onFilterDelete: PropTypes.func.isRequired,
  onFilterDeleteAll: PropTypes.func.isRequired,
}

export default Filters
