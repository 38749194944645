const TARGET_BASE_CONFIG = {
  'kind': 'core',
  'key': null,
  'metricKey': null,
  'targetValue': null,
  'emptyDataRule': 'zero',
  'startDate': null,
  'endDate': null,
  'segments': [],
  'xKey': 'date_time',
  'resolution': 2,
  'config': {
    'includeCommission': false,
    'includeCosts': true,
  },
  'configItems': {
    'includeCosts': {
      'show': false,
      'name': 'Include Costs',
      'items': [
        {
          'value': true,
          'name': 'Yes'
        },
        {
          'value': false,
          'name': 'No'
        },
      ],
    },
    'includeCommission': {
      'show': true,
      'name': 'Affiliate Commission',
      'items': [
        {
          'value': false,
          'name': 'Subtract from revenue'
        },
        {
          'value': true,
          'name': 'Add to revenue'
        },
      ],
    },
  },
  'chart': {
    'allowSecondaryChart': false,
    'allowLineDots': true,
    'allowUseStack100': false,
    'cumulativeDefault': true,
    'hideYValuesAfterToday': true,
  },
  'table': {
    'defaultTab': 'chart',
    'summary': {
      'show': false,
      'columns': {},
    },
    'chart': {
      'show': true,
    },
    'milestones': {
      'show': false,
      'days': [],
    }
  }
}

export default TARGET_BASE_CONFIG
