import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'

import {
  ResponsiveContainer,
  ComposedChart,
  Area,
  Bar,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip
} from 'recharts'

import LoadingSkeleton from './LoadingSkeleton'

import { formatNumber, getAlias, shortenString } from '../../utils/helpers'

const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const useStyles = makeStyles(theme => ({
  root: {
  },
  controlBar: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    [theme.breakpoints.only('xs')]: {
      marginTop: 0,
      justifyContent: 'center',
    },
  },
  controlForm: {
    marginLeft: theme.spacing(2),
    minWidth: 90,
  },
  zoomButton: {
    height: 48,
    marginLeft: theme.spacing(1),
  },
  axisLabelTypographyTop: {
    position: 'absolute',
    left: 60,
    color: theme.palette.text.secondary,
  },
  axisLabelTypographyBottom: {
    position: 'absolute',
    top: 260,
    left: 60,
    color: theme.palette.text.secondary,
  },
  tooltipRoot: {
    backgroundColor: 'white',
    textAlign: 'right',
    padding: theme.spacing(1),
    border: '1px solid rgb(204, 204, 204)',
    borderRadius: theme.shape.borderRadius,
    maxWidth: 300,
  },
  tooltipDataTypography: {
    margin: 0,
    fontSize: 12,
  },
  tooltipTitleTypography: {
    color: theme.palette.text.secondary,
  },
  noGraphDataTypography: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.text.hint,
  },
  summaryTypography: {
    color: theme.palette.text.secondary,
    margin: theme.spacing(2, 1, 0, 1),
  },
  graphAreaDiv: {
    position: 'relative',
    width: '100%',
  }
}))

const CHART_HEIGHT = 200
const BRUSH_HEIGHT = 20
const SYNC_ID = 'revenue'
const TICK_FONT_SIZE = 12

const resolutionSelectItems = [
  {id: 'day', value: 'Day'},
  {id: 'week', value: 'Week'},
  {id: 'month', value: 'Month'},
  {id: 'quarter', value: 'Quarter'},
  {id: 'year', value: 'Year'},
]


const GraphRevenue = (props) => {
  const { aliases, context, colors, isLoading, data, resolution, setResolution } = props
  const classes = useStyles()
  const [compare, setCompare] = React.useState(false)

  const top = data ? {
    type: compare ? 'line': 'area',
    data: data.revenue
  } : {}
  const bottom = data ? {
    type: 'bar',
    data: data.orders
  } : {}

  const ids = Object.keys(colors).filter(id => !!colors[id])
  const summaryMessage = `[Top] Revenue by ${resolution} from ${context.dates.start ? context.dates.start.format('MM/DD/YY') : null} to ${context.dates.end ? context.dates.end.format('MM/DD/YY') : null}, ${compare ? `shown with lines compared head to head`: `shown as a stacked area chart`}. [Bottom] Number of orders by ${resolution}, shown as a stacked bar chart.`
  return (
    <Fragment>
      <div className={classes.controlBar}>
        {context.breakdown &&
          <FormControl className={classes.controlForm} margin='dense' size='small'>
            <FormControlLabel
              control={
                <Switch
                  checked={compare}
                  onChange={() => setCompare(!compare)}
                  name='compare'
                  color='primary'
                />
              }
              label='Compare'
            />
          </FormControl>
        }
        <FormControl className={classes.controlForm} margin='dense' size='small'>
          <InputLabel id='control-label'>Resolution</InputLabel>
          <Select
            labelId='resolution-label'
            label='Resolution'
            id='resolution-select'
            value={resolution}
            onChange={event => setResolution(event.target.value)}
          >
            {resolutionSelectItems.map(item =>
              <MenuItem key={item.id} value={item.id}>{item.value}</MenuItem>
            )}
          </Select>
        </FormControl>
      </div>

      {isLoading ? (
        <LoadingSkeleton classes={classes} height={CHART_HEIGHT * 4/3} />
        ) : (
        <div className={classes.graphAreaDiv}>
          <Typography className={classes.axisLabelTypographyTop} component='div' variant='caption'>
            Revenue
          </Typography>
          <ResponsiveContainer width='100%' height={CHART_HEIGHT}>
            <ComposedChart data={top.data} margin={{ top: BRUSH_HEIGHT, bottom: 0, left: 0, right: 0 }} syncId={SYNC_ID}>
              <XAxis
                dataKey='x'
                tick={{ fontSize: TICK_FONT_SIZE }}
                tickFormatter={val => moment.tz(val, TIMEZONE).format('M/D/YY')}
              />
              <YAxis
                type='number'
                domain={['auto', 'auto']}
                tick={{ fontSize: TICK_FONT_SIZE }}
                tickFormatter={val => `$${formatNumber(val, 2, true)}`}
              />
              <CartesianGrid strokeDasharray='1 1' vertical={false} />
              <Tooltip
                isAnimationActive={false}
                content={<CustomTooltipTop />}
                wrapperStyle={{ zIndex: 1000 }}
              />
              {ids.map(id => {
                switch(top.type) {
                  case 'line':
                    return (
                      <Line
                        key={id}
                        dataKey={id}
                        name={getAlias(aliases, id)}
                        stroke={colors[id]}
                        dot={{ r: 1, fill: colors[id] }}
                        type='linear'
                        connectNulls
                      />
                    )
                  case 'bar':
                    return (
                      <Bar
                        key={id}
                        dataKey={id}
                        name={getAlias(aliases, id)}
                        fill={colors[id]}
                        stackId='bar'
                      />
                    )
                  case 'area':
                    return (
                      <Area
                        key={id}
                        dataKey={id}
                        name={getAlias(aliases, id)}
                        type='linear'
                        stroke={colors[id]}
                        dot={{ r: 1, fill: colors[id] }}
                        fill={colors[id]}
                        stackId='area'
                      />
                    )
                  default:
                    return null
                }
              })}
            </ComposedChart>
          </ResponsiveContainer>
          <ResponsiveContainer width='100%' height={CHART_HEIGHT / 3}>
            <ComposedChart data={bottom.data} margin={{ top: 0, bottom: -1 * BRUSH_HEIGHT, left: 0, right: 0 }} syncId={SYNC_ID}>
              <XAxis
                dataKey='x'
                axisLine={false}
                tickLine={false}
                tick={false}
              />
              <YAxis
                type='number'
                domain={[0, 'dataMax']}
                tick={{ fontSize: TICK_FONT_SIZE }}
                interval='preserveStartEnd'
                tickFormatter={val => formatNumber(val, 2, true)}
                reversed
              />
              <CartesianGrid strokeDasharray='1 1' vertical={false} />
              <Tooltip
                isAnimationActive={false}
                content={<CustomTooltipBottom />}
                wrapperStyle={{ zIndex: 1000 }}
              />
              {ids.map(id => {
                switch(bottom.type) {
                  case 'line':
                    return (
                      <Line
                        key={id}
                        dataKey={id}
                        name={getAlias(aliases, id)}
                        stroke={colors[id]}
                        dot={{ r: 1, fill: colors[id] }}
                        type='linear'
                        connectNulls
                      />
                    )
                  case 'bar':
                    return (
                      <Bar
                        key={id}
                        dataKey={id}
                        name={getAlias(aliases, id)}
                        fill={colors[id]}
                        stackId='stack'
                      />
                    )
                  default:
                    return null
                }
              })}
            </ComposedChart>
          </ResponsiveContainer>
          <Typography className={classes.axisLabelTypographyBottom} component='div' variant='caption'>
            Orders
          </Typography>
          <Typography className={classes.summaryTypography} component='div' variant='caption'>
            {summaryMessage}
          </Typography>
        </div>
        )
      }
    </Fragment>
  )
}

const CustomTooltipTop = ({ active, payload, label }) => {
  const classes = useStyles()

  if (payload === null) return null

  payload.sort((a, b) => {
    return parseFloat(b.value) - parseFloat(a.value)
  })

  if (active) {
    return (
      <div className={classes.tooltipRoot}>
        <Typography variant='overline'>
          {moment.tz(label, TIMEZONE).format('M/D/YY')}
        </Typography>
        {payload.map(datum =>
          <Typography key={datum.dataKey} className={classes.tooltipDataTypography} style={{ color: datum.color }}>
            {`${shortenString(datum.name, 32)} : $${formatNumber(datum.value, 2)}`}
          </Typography>
        )}
        <Typography className={classes.tooltipTitleTypography} variant='caption'>
          Revenue
        </Typography>
      </div>
    )
  }

  return null
}

const CustomTooltipBottom = ({ active, payload }) => {
  const classes = useStyles()

  if (payload === null) return null

  if (active) {
    return (
      <div className={classes.tooltipRoot}>
        {payload.map(datum =>
          <Typography key={datum.dataKey} className={classes.tooltipDataTypography} style={{ color: datum.color }}>
            {`${shortenString(datum.name, 32)} : ${formatNumber(datum.value, 0)}`}
          </Typography>
        )}
        <Typography className={classes.tooltipTitleTypography} variant='caption'>
          Orders
        </Typography>
      </div>
    )
  }

  return null
}

GraphRevenue.propTypes = {
  aliases: PropTypes.array.isRequired,
  context: PropTypes.object.isRequired,
  colors: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  resolution: PropTypes.string.isRequired,
  setResolution: PropTypes.func.isRequired,
}

export default GraphRevenue
