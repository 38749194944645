import React from 'react'
import PropTypes from 'prop-types'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Switch from '@mui/material/Switch'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import makeStyles from '@mui/styles/makeStyles'
import { ANCHOR_RIGHT } from 'react-dates/constants'

import DateRangePicker from '../Common/DateRangePicker'

const useStyles = makeStyles(() => ({
  root: {
  },
  checkboxLabel: {
    fontSize: 14,
  },
}))

const NULL_BREAKDOWN_ID = 'null'
const breakdowns = [
  {id: 'platform', name: 'platforms'},
  {id: 'funnel_id', name: 'funnels'},
  {id: 'product_id', name: 'products'},
  {id: 'affiliate_id_2', name: 'affiliates'},
]

const Controls = (props) => {
  const { breakdown, extendBackend, startDate, endDate, onDatesChange, setBreakdown, setExtendBackend } = props
  const classes = useStyles()

  const handleChangeBreakdown = (event, newBreakdownId) => {
    if (newBreakdownId === NULL_BREAKDOWN_ID) setBreakdown(null)
    else {
      const newBreakdown = breakdowns.find(el => el.id === newBreakdownId)
      setBreakdown(newBreakdown)
    }
  }

  const handleChangeExtendBackend = (event) => {
    setExtendBackend(event.target.checked)
  }

  return (
    <Grid
      className={classes.root}
      container
      direction='row'
      justifyContent='space-between'
      alignItems='flex-start'
    >
      <ToggleButtonGroup
        value={breakdown ? breakdown.id : NULL_BREAKDOWN_ID}
        exclusive
        onChange={handleChangeBreakdown}
        aria-label='breakdown'
        size='small'
      >
        <ToggleButton value={NULL_BREAKDOWN_ID} aria-label='all'>
          All
        </ToggleButton>
        {breakdowns.map(breakdown =>
          <ToggleButton key={breakdown.id} value={breakdown.id} aria-label={breakdown.name}>
            {breakdown.name}
          </ToggleButton>
        )}
      </ToggleButtonGroup>
      <div>
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onDatesChange={onDatesChange}
          anchorDirection={ANCHOR_RIGHT}
        />
          <FormControlLabel
            classes={{
              label: classes.checkboxLabel
            }}
            control={
              <Switch
                checked={extendBackend}
                onChange={handleChangeExtendBackend}
                name='extendBackend'
                color='primary'
                size='small'
              />
            }
            label='extend lifetime analysis to today'
          />
      </div>
    </Grid>
  );
}

Controls.propTypes = {
  breakdown: PropTypes.object,
  extendBackend: PropTypes.bool.isRequired,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onDatesChange: PropTypes.func.isRequired,
  setBreakdown: PropTypes.func.isRequired,
  setExtendBackend: PropTypes.func.isRequired,
}

export default Controls