import React from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'
import { useHistory } from 'react-router-dom'

import Controls from './Controls'
import Graphs from './Graphs'
import Table from './Table'

import { getColorPalette, stableSort } from '../../utils/helpers'

const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: 66,
    right: 0,
    width: 'calc(100% - 66px)',
    height: '100vh',
    overflowY: 'auto',
    paddingTop: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
  }
}))

const Analysis = () => {
  const classes = useStyles()
  const history = useHistory()
  const fromState = history.action === 'PUSH' ? history.location.state || {} : {}
  let startDateInitial = fromState.startDate ? moment.tz(fromState.startDate, TIMEZONE) : moment.tz(TIMEZONE).subtract(90, 'days').startOf('day')
  let endDateInitial = fromState.endDate ? moment.tz(fromState.endDate, TIMEZONE) : moment.tz(TIMEZONE).endOf('day')
  let breakdownInitial = fromState.breakdown || null
  let filtersInitial = fromState.filters || []
  let attributionInitial = fromState.attribution || 'customer'
  let orderInitial = fromState.order || 'desc'
  let orderByInitial = fromState.orderBy || 'customers_new'

  const [startDate, setStartDate] = React.useState(startDateInitial)
  const [endDate, setEndDate] = React.useState(endDateInitial)
  const [breakdown, setBreakdown] = React.useState(breakdownInitial)
  const [filters, setFilters] = React.useState(filtersInitial)
  const [attribution, setAttribution] = React.useState(attributionInitial)
  const [extendBackend] = React.useState(true)
  const [includeCosts, setIncludeCosts] = React.useState(false)
  const [includeCommission, setIncludeCommission] = React.useState(false)
  const [order, setOrder] = React.useState(orderInitial)
  const [orderBy, setOrderBy] = React.useState(orderByInitial)
  const [rows, setRows] = React.useState([])

  const handleDatesChange = ({ startDate: newStartDate, endDate: newEndDate }) => {
    setStartDate(newStartDate)
    setEndDate(newEndDate)
  }

  const handleBreakdownSelect = (breakdown) => {
    setBreakdown(breakdown)
  }

  const handleBreakdownDelete = () => {
    setBreakdown(null)
  }

  const handleFilterAdd = (filter) => {
    setFilters([filter, ...filters])
  }

  const handleFilterDelete = (filter) => {
    const index = filters.indexOf(filter)
    const newFilters = [...filters]
    newFilters.splice(index, 1)
    setFilters(newFilters)
  }

  const handleFilterDeleteAll = () => {
    setFilters([])
  }

  const handleAttributionSelect = (attribution) => {
    setAttribution(attribution)
    // Ensure that orderBy is set to a valid value
    if (attribution === 'customer' && orderBy === 'leads') {
      setOrderBy('customers_new')
    }
    if (attribution === 'lead' && orderBy === 'customers_new') {
      setOrderBy('leads')
    }
  }

  const handleIncludeCommissionChange = (value) => {
    setIncludeCommission(value)
  }

  const handleIncludeCostsChange = (value) => {
    setIncludeCosts(value)
  }

  const getColors = (sortedRows) => {
    const colorsList = getColorPalette(sortedRows.filter(row => row.checked).length)

    let colors = {}
    let colorIndex = 0
    sortedRows.forEach(row => {
      if (row.checked) {
        colors[row.breakdown] = '#' + colorsList[colorIndex]
        colorIndex += 1
      } else {
        colors[row.breakdown] = null
      }
    })
    return colors
  }

  const context = {
    dates: {
      start: startDate,
      end: endDate,
    },
    breakdown,
    filters,
  }
  const sortedRows = stableSort(rows, order, orderBy)
  const colors = getColors(sortedRows)

  return (
    <Container className={classes.root} maxWidth='xl'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controls
            startDate={startDate}
            endDate={endDate}
            onDatesChange={handleDatesChange}
            breakdown={breakdown}
            onBreakdownSelect={handleBreakdownSelect}
            onBreakdownDelete={handleBreakdownDelete}
            filters={filters}
            onFilterAdd={handleFilterAdd}
            onFilterDelete={handleFilterDelete}
            onFilterDeleteAll={handleFilterDeleteAll}
            attribution={attribution}
            onAttributionSelect={handleAttributionSelect}
            includeCosts={includeCosts}
            onIncludeCostsChange={handleIncludeCostsChange}
            includeCommission={includeCommission}
            onIncludeCommissionChange={handleIncludeCommissionChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Graphs
            attribution={attribution}
            context={context}
            extendBackend={extendBackend}
            includeCosts={includeCosts}
            includeCommission={includeCommission}
            colors={colors}
          />
        </Grid>
        <Grid item xs={12}>
          <Table
            attribution={attribution}
            context={context}
            extendBackend={extendBackend}
            includeCosts={includeCosts}
            includeCommission={includeCommission}
            order={order}
            orderBy={orderBy}
            rows={sortedRows}
            colors={colors}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            setRows={setRows}
            onFilterAdd={handleFilterAdd}
          />
        </Grid>
      </Grid>
    </Container>
  )
}

export default Analysis
