import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

const EMPTY_TARGET_DATA = {
  name: '',
  description: '',
}

const TargetDialog = ({
  open,
  onClose,
  editId,
  target,
  onTargetSave,
}) => {
  const [targetData, setTargetData] = useState(() => {
    if (editId) {
      return {
        ...EMPTY_TARGET_DATA,
        name: target.name,
        description: target.description,
      }
    } else {
      return EMPTY_TARGET_DATA
    }
  })

  const handleChange = (event) => {
    setTargetData({ ...targetData, [event.target.name]: event.target.value })
  }

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') return
    onClose()
  }

  const handleSubmit = (editId=null, saveAs=false) => {
    if (editId) {
      // Update the target or save as a new target
      onTargetSave(saveAs ? null : editId, targetData)
    } else {
      // Create a new target
      onTargetSave(null, targetData)
    }
    onClose()
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='xs' fullWidth>
      <DialogTitle>{editId ? 'Update Target' : 'Save New Target'}</DialogTitle>
      <DialogContent>
        <Box display='flex' flexDirection='column' gap={2} paddingTop={1}>
          <TextField
            name='name'
            label='Name'
            value={targetData.name}
            onChange={handleChange}
            size='small'
            fullWidth
            required
          />
          <TextField
            name='description'
            label='Description'
            value={targetData.description}
            onChange={handleChange}
            size='small'
            fullWidth
          />
          <Typography variant='body1' color='textPrimary'>
            The target value and date range from the report will be saved on this target.
            To change these values, close this dialog then come back here when you are ready to save.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        {editId && (
          <Button
            onClick={() => handleSubmit(editId, true)}
            color='primary'
            disabled={!targetData.name}
          >
            Save New
          </Button>
        )}

        <div style={{ flexGrow: 1 }} />

        <Button
          onClick={onClose}
          color='error'
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleSubmit(editId)}
          color='secondary'
          disabled={!targetData.name}
        >
          {editId ? 'Update' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

TargetDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  editId: PropTypes.string,
  target: PropTypes.object.isRequired,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onTargetSave: PropTypes.func.isRequired,
}

export default TargetDialog