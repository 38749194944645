import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'

import { FirebaseContext } from '../../utils/firebase'
import 'firebase/auth'

import {
  useQuery,
} from 'react-query'
import moment from 'moment-timezone'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import '../../css/react_dates_overrides.css'
import { DayPickerSingleDateController } from 'react-dates'

import { API_ROOT_URL } from '../../constants'

const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
  },
  datePickerShortcutsContainer: {
    textAlign: 'center',
  },
  datePickerShortcuts: {
    margin: theme.spacing(1) / 2,
  },
  calendarInfo: {
    padding: theme.spacing(1, 0),
    textAlign: 'center',
  },
  pickerSpacer: {
    height: theme.spacing(2),
    width: '100%',
  },
}))

const CustomDateRangeController = (props) => {
  const classes = useStyles()
  const firebase = React.useContext(FirebaseContext)
  const { isLoading, data } = useQuery(['date_range'], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/dates?tz=${TIMEZONE}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      }).then(res => res.json())
    }),
    {
      cacheTime: 15 * 60 * 1000,  // 15 minutes
      staleTime: 15 * 60 * 1000,  // 15 minutes
      refetchInterval: 60 * 60 * 1000,  // 60 minutes
      refetchOnWindowFocus: false,
    }
  )

  const handleClickDateRangeShortcut = value => {
    const todayMoment = moment.tz(TIMEZONE).endOf('day')
    let newStartDate
    let newEndDate
    switch (value) {
      case 30:
      case 90:
      case 365:
        newStartDate = moment.tz(TIMEZONE).subtract(value, 'days').startOf('day')
        newEndDate = todayMoment
        break
      case 'mtd':
        newStartDate = moment.tz(TIMEZONE).startOf('month')
        newEndDate = todayMoment
        break
      case 'ytd':
        newStartDate = moment.tz(TIMEZONE).startOf('year')
        newEndDate = todayMoment
        break
      case 'all':
        newStartDate = data ? moment.tz(data.first_date, TIMEZONE) : props.startDate
        newEndDate = todayMoment
        break
      default:
        newStartDate = props.startDate
        newEndDate = props.endDate
        break
    }
    props.onStartDateChange(newStartDate)
    props.onEndDateChange(newEndDate)
  }

  const handleStartDateChange = (newStartDate) => {
    props.onStartDateChange(newStartDate.tz(TIMEZONE).startOf('day'))
  }

  const handleEndDateChange = (newEndDate) => {
    props.onEndDateChange(newEndDate.tz(TIMEZONE).endOf('day'))
  }

  return (
      <div className={classes.root}>
        <DateRangeShortcuts onClick={handleClickDateRangeShortcut} loadingAll={isLoading} />
        <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
          <DayPickerSingleDateController
            key={`${props.startDate}-start`}
            date={props.startDate}
            onDateChange={handleStartDateChange}
            focused={true}
            firstDayOfWeek={1}
            isOutsideRange={dayMoment => dayMoment.isAfter(moment.tz(TIMEZONE), 'day')}
            initialVisibleMonth={() => moment(props.startDate ? props.startDate : undefined)}
            calendarInfoPosition='top'
            renderCalendarInfo={() => <Typography className={classes.calendarInfo}>Starting on or after</Typography>}
            transitionDuration={0}
            numberOfMonths={1}
            hideKeyboardShortcutsPanel
          />
          <div className={classes.pickerSpacer} />
          <DayPickerSingleDateController
            key={`${props.endDate}-end`}
            date={props.endDate}
            onDateChange={handleEndDateChange}
            focused={true}
            firstDayOfWeek={1}
            isOutsideRange={dayMoment => dayMoment.isAfter(moment.tz(TIMEZONE), 'day') || (Boolean(props.startDate) && dayMoment.isBefore(props.startDate))}
            initialVisibleMonth={() => moment(props.endDate ? props.endDate : undefined)}
            calendarInfoPosition='top'
            renderCalendarInfo={() => <Typography className={classes.calendarInfo}>Ending on or before</Typography>}
            numberOfMonths={1}
            transitionDuration={0}
            hideKeyboardShortcutsPanel
          />
        </Box>
      </div>
  )
}

const DateRangeShortcuts = (props) => {
  const classes = useStyles()
  return (
    <div className={classes.datePickerShortcutsContainer}>
      <Button
        className={classes.datePickerShortcuts}
        variant='text'
        color='secondary'
        size='small'
        onClick={() => props.onClick(30)}
      >
        Last 30 Days
      </Button>
      <Button
        className={classes.datePickerShortcuts}
        variant='text'
        color='secondary'
        size='small'
        onClick={() => props.onClick(90)}
      >
        Last 90 Days
      </Button>
      <Button
        className={classes.datePickerShortcuts}
        variant='text'
        color='secondary'
        size='small'
        onClick={() => props.onClick(365)}
      >
        Last 365 Days
      </Button>
      <Button
        className={classes.datePickerShortcuts}
        variant='text'
        color='secondary'
        size='small'
        onClick={() => props.onClick('mtd')}
      >
        MTD
      </Button>
      <Button
        className={classes.datePickerShortcuts}
        variant='text'
        color='secondary'
        size='small'
        onClick={() => props.onClick('ytd')}
      >
        YTD
      </Button>
      <Button
        className={classes.datePickerShortcuts}
        variant='text'
        color='secondary'
        size='small'
        onClick={() => props.onClick('all')}
        disabled={props.loadingAll}
      >
        All
      </Button>
    </div>
  )
}

CustomDateRangeController.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onStartDateChange: PropTypes.func.isRequired,
  onEndDateChange: PropTypes.func.isRequired,
}

export default CustomDateRangeController
