import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import Popover from '@mui/material/Popover'
import TableRowsIcon from '@mui/icons-material/TableRows'
import { ListItemIcon } from '@mui/material'

import DarkTooltip from './DarkTooltip'

const PageSizeSelect = ({
  pageSizes,
  pageSize,
  onChange,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelect = (value) => {
    onChange(value)
    setAnchorEl(null)
  }

  return (
    <React.Fragment>
      <DarkTooltip
        title='Set breakdowns per page'
        placement='bottom'
        arrow
      >
        <Button
          variant='text'
          onClick={handleClick}
          startIcon={ <TableRowsIcon /> }
          sx={{
            textTransform: 'none',
          }}
        >
          {pageSize}
        </Button>
      </DarkTooltip>
      <Popover
        sx={{
          marginTop: theme => theme.spacing(1),
        }}
        id={anchorEl ? 'popover' : undefined}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
        transformOrigin={{ vertical: 'top', horizontal: 'right', }}
      >
        <Box>
          <List component='ul' aria-label='breakdown selector' dense subheader={<ListSubheader>Breakdowns per page</ListSubheader>}>
            {pageSizes.map(size =>
              <ListItemButton
                key={size}
                selected={size === pageSize}
                onClick={() => handleSelect(size)}
                dense
              >
                <ListItemIcon>
                  <TableRowsIcon />
                </ListItemIcon>
                <ListItemText primary={size} />
              </ListItemButton>
            )}
          </List>
        </Box>
      </Popover>
    </React.Fragment>
  )
}

PageSizeSelect.propTypes = {
  pageSizes: PropTypes.array.isRequired,
  pageSize: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default PageSizeSelect
