import React from 'react'
import PropTypes from 'prop-types'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Popover from '@mui/material/Popover'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import InfoIcon from '@mui/icons-material/Info'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() => ({
  root: {
  },
}))

const FieldMapper = (props) => {
  const { target } = props
  const classes = useStyles()
  const [infoAnchorEl, setInfoAnchorEl] = React.useState(null)

  const handleOpenInfo = (event) => {
    setInfoAnchorEl(event.currentTarget)
  }

  const handleCloseInfo = () => {
    setInfoAnchorEl(null)
  }

  const handleSelectTarget = (option) => {
    if (option) props.onSelectTarget(props.group, props.id, option.id)
    else props.onSelectTarget(props.group, props.id, '')
  }

  const firstRow = props.inputFilePreview[0] || {}
  const inputFileFields = Object.keys(firstRow) || []
  const autocompleteOptions = [{ name: 'None', id: '' }, ...inputFileFields.map(f => ({ name: f, id: f }))]

  return (
    <Box className={classes.root} border={1} padding={2} display='flex' flexDirection='row'>
      <Grid container>
        <Grid item xs={6}>
          <Box>
            <Typography variant='subtitle2' style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
              {props.group}
            </Typography>
            <Box display='flex' flexDirection='row' alignItems='center'>
              <Typography>
                {props.name}
              </Typography>
              <IconButton
                tabIndex={-1}
                size='small' onClick={handleOpenInfo}
              >
                <InfoIcon />
              </IconButton>
              <Popover
                open={Boolean(infoAnchorEl)}
                anchorEl={infoAnchorEl}
                onClose={handleCloseInfo}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                PaperProps={{
                  style: { maxWidth: 500 }
                }}
              >
                <Paper>
                  <Box padding={2}>
                    <Typography>
                      {props.description}
                    </Typography>
                  </Box>
                </Paper>
              </Popover>
            </Box>
            <Box>
              <Typography variant='overline'>
                Examples:
              </Typography>
            </Box>
            <Box>
              {props.examples.map((value, index) =>
                <Box key={index}>
                  <Typography>
                    {value}
                  </Typography>
                  <Divider />
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <Autocomplete
              id='assign-to-autocomplete'
              options={autocompleteOptions}
              getOptionLabel={option => option.name}
              renderInput={(params) =>
                <TextField
                  {...params}
                  label='Assign to field'
                  variant='outlined'
                  size='small'
                  margin='dense'
                />
              }
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              )}
              value={{ id: target, name: target }}
              onChange={(_, option) => handleSelectTarget(option)}
              noOptionsText='No matches'
              isOptionEqualToValue={(option, value) => {
                if (option) return value.id === option.id
                else return value.id === ''
              }}
              autoHighlight
              fullWidth
            />

            {target && target in firstRow && (
              <Box>
                <Box marginBottom='3px'>
                  <Typography variant='overline'>
                    Preview from file:
                  </Typography>
                </Box>
                <Box>
                  {props.inputFilePreview.map((row, index) =>
                    <Box key={index}>
                      <Typography>
                        {row[target]}
                      </Typography>
                      <Divider />
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

FieldMapper.defaultProps = {
  target: ''
}

FieldMapper.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  group: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  dataType: PropTypes.string.isRequired,
  examples: PropTypes.array.isRequired,
  importance: PropTypes.string.isRequired,
  inputFilePreview: PropTypes.array.isRequired,
  target: PropTypes.string,
  onSelectTarget: PropTypes.func.isRequired,
}

export default FieldMapper
