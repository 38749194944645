import React from 'react'
import Alert from '@mui/material/Alert'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import Skeleton from '@mui/material/Skeleton'
import Snackbar from '@mui/material/Snackbar'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'

import { useHistory, useParams } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'

import { FirebaseContext } from '../../utils/firebase'
import 'firebase/auth'

import { getFrequencyFromUnixCronSchedule } from '../../utils/helpers'
import { API_ROOT_URL } from '../../constants/'
import * as ROUTES from '../../constants/routes'

import konnektiveLogoImg from '../../media/konnektive_logo.png'

const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: 66,
    right: 0,
    width: 'calc(100% - 66px)',
    height: '100vh',
    overflowY: 'auto',
    backgroundColor: theme.palette.grey[50],
  },
  logoImg: {
    width: 100,
  },
  redButton: {
    color: theme.palette.error.main,
    '&:hover': {
      background: 'rgba(255,23,68,0.04)',
    },
  },
  formControl: {
    margin: theme.spacing(1),
  },
}))

const REVOKE_SUCCESS_MESSAGE = 'Account removed. Data will no longer be synced from this account. Redirecting back to settings...'
const SYNC_SUCCESS_MESSAGE = 'Sync initialized. Processing may take a while, and you can safely leave this page.'
const SYNC_ERROR_MESSAGE = 'Sync failed to initialize. Try again and contact support if error persists.'

const dataTypeMenuItems = [
  { value: 'all', display: 'All Transactions'},
  { value: 'internal', display: 'Exclude Imported Data'},
]

const dateRangeMenuItems = [
  { value: 'last_7_days', display: 'Last 7 days'},
  { value: 'last_30_days', display: 'Last 30 days'},
  { value: 'last_3_months', display: 'Last 3 months'},
  { value: 'last_6_months', display: 'Last 6 months'},
  { value: 'last_12_months', display: 'Last 12 months'},
  { value: 'last_2_years', display: 'Last 2 years'},
  { value: 'last_3_years', display: 'Last 3 years'},
  { value: 'ytd', display: 'Year to date'},
  { value: 'last_and_this_year', display: 'Last year and this year'},
  { value: 'all_time', display: 'All time'},
]

const syncFrequencyMenuItems = [
  { value: 'hourly', display: 'Hourly'},
  { value: 'daily', display: 'Daily'},
  { value: 'weekly', display: 'Weekly'},
  { value: 'monthly', display: 'Monthly'},
]

const KonnektiveSource = () => {
  const classes = useStyles()
  const { id } = useParams()
  const history = useHistory()
  const firebase = React.useContext(FirebaseContext)
  const [message, setMessage] = React.useState('')
  const [dataType, setDataType] = React.useState('all')
  const [dateRange, setDateRange] = React.useState('last_2_years')
  const [timezone, setTimezone] = React.useState(TIMEZONE)
  const [showMessage, setShowMessage] = React.useState(false)
  const [messageSeverity, setMessageSeverity] = React.useState('success')
  const [showRevokeConfirmation, setShowRevokeConfirmation] = React.useState(false)

  const [startDate, setStartDate] = React.useState('')
  const [endDate, setEndDate] = React.useState('')
  const [syncFrequency, setSyncFrequency] = React.useState('daily')

  React.useEffect(() => {
    setDatesFromRange(dateRange)
  }, [dateRange])

  const {
    isLoading: isLoadingIntegration,
    data: integration = {}
  } = useQuery(['integration-get', id], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      }).then(res => res.json())
    }),
    {
      cacheTime: 15 * 60 * 1000,  // 15 minutes
      staleTime: 15 * 60 * 1000,  // 15 minutes
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data.schedule) {
          const frequency = getFrequencyFromUnixCronSchedule(data.schedule)
          setSyncFrequency(frequency)
        }
        if (data.timezone) {
          setTimezone(data.timezone)
        }
      }
    },
  )

  const {
    isLoading: isLoadingIntegrationJob,
    data: integrationJob = {},
    refetch: refetchIntegrationJob
  } = useQuery(['integration-job-get', id], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/${id}/job`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      }).then(res => res.json())
    }),
    {
      cacheTime: 15 * 60 * 1000,  // 15 minutes
      staleTime: 15 * 60 * 1000,  // 15 minutes
      refetchOnWindowFocus: false,
    }
  )

  const { isFetching: isSyncing, refetch: sync } = useQuery(['integration-sync', id], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/konnektive/sync`, {
        method: 'POST',
        body: JSON.stringify({
          integration_id: id,
          dataType,
          startDate,
          endDate,
          syncFrequency,
          timezone
        }),
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      }).then(res => res.json())
    }),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      staleTime: 0,
      onSuccess: () => {
        setShowMessage(true)
        setMessage(SYNC_SUCCESS_MESSAGE)
        setMessageSeverity('success')
        refetchIntegrationJob()
      },
      onError: () => {
        setShowMessage(true)
        setMessage(SYNC_ERROR_MESSAGE)
        setMessageSeverity('error')
      }
    }
  )

  const { isLoading: isUpdatingSyncFrequency, mutate: updateSyncFrequency } = useMutation(
    newSyncFrequency => firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/konnektive/sync`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          integration_id: id,
          syncFrequency: newSyncFrequency
        }),
      }).then(res => res.json())
    }),
    {
      onSuccess: () => {
        setShowMessage(true)
        setMessage('Sync frequency successfully updated')
        setMessageSeverity('success')
        refetchIntegrationJob()
      },
      onError: () => {
        setShowMessage(true)
        setMessage('Error updating sync frequency. Please try again, and if problem persists, contact support.')
        setMessageSeverity('error')
      }
    }
  )

  const revoke = () => {
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/konnektive/revoke`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          integration_id: id
        })
      }).then(res => {
        setShowRevokeConfirmation(false)
        setMessage(REVOKE_SUCCESS_MESSAGE)
        setShowMessage(true)
        setTimeout(() => history.push(ROUTES.SETTINGS), 3000)
        return res.json()
      })
    })
  }

  const handleCloseMessage = (_, reason) => {
    if (reason === 'clickaway') return
    setShowMessage(false)
  }

  const handleChangeSyncFrequency = (frequency) => {
    setSyncFrequency(frequency)
    updateSyncFrequency(frequency)
  }

  const setDatesFromRange = (dateRange) => {
    let startDate, endDate
    switch (dateRange) {
      case 'last_7_days':
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(7, 'days').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'last_30_days':
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(30, 'days').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'last_3_months':
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(3, 'months').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'last_6_months':
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(6, 'months').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'last_12_months':
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(12, 'months').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'last_2_years':
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(2, 'years').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'last_3_years':
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(3, 'years').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'ytd':
        startDate = moment.tz(TIMEZONE).startOf('year').startOf('day').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'last_and_this_year':
        startDate = moment.tz(TIMEZONE).subtract(1, 'year').startOf('year').startOf('day').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'all_time':
        startDate = moment.tz('1970', TIMEZONE).startOf('day').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      default:
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(12, 'months').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
    }
    setStartDate(startDate)
    setEndDate(endDate)
  }

  return (
    <div className={classes.root}>
      <Box padding={2}>
        <Button
          variant='outlined'
          onClick={() => history.push(ROUTES.SETTINGS)}
          size='small'
        >
          Back
        </Button>
        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs={2}>
                  <img
                    className={classes.logoImg}
                    alt='konnektive_logo'
                    src={konnektiveLogoImg}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant='h5' gutterBottom>
                    Konnektive Source
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Name
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Box height='100%' display='flex' alignItems='center'>
                    <Typography variant='body2'>
                      {isLoadingIntegration ? <Skeleton variant='text' width={100} /> : `${integration.nickname}`}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Sync
                  </Typography>
                </Grid>
                <Grid item xs={10}>

                  <Typography variant='body2' gutterBottom>
                    To sync payment data from Konnektive with LTV Numbers, select the appropriate data type and click the Sync button below. This will start a process of retrieving data from the account and loading it into LTV Numbers. Due to rate limitations inside Konnektive, this process can take anywhere from several minutes to several hours.
                  </Typography>
                  <Typography variant='body2' gutterBottom>
                    Use the selector below to specify the date range for which you would like to import the data. It defaults to the last 2 years, but you can change it to whatever makes sense for analysis purposes.
                  </Typography>
                  <Typography variant='body2' gutterBottom>
                    Please read the Date Type options below carefully before making your seletion and beginning the sync.
                  </Typography>

                  <Typography variant='h6' paragraph>
                    All Transactions
                  </Typography>
                  <Typography variant='body2' gutterBottom>
                    This includes all transactions that exist inside your Konnektive account, including those that were imported from external sources. This is the recommended option. The only case for which this would not be recommended is if you imported orders into Konnektive from another platform that is already hooked up to LTV Numbers, because including this data could cause duplication.
                  </Typography>

                  <Typography variant='h6' paragraph>
                    Exclude Imported Data
                  </Typography>
                  <Typography variant='body2' gutterBottom>
                    This includes all transactions that were created and processed by your Konnektive account. In other words, this will be all the data the natively exists in the account, with all imported data excluded. Choose this option if you have imported data from a platform that is already connected to LTV Numbers, or if you would like to limit your analysis to native Konnektive transactions for other reasons.
                  </Typography>

                  <Box marginTop={2} display='flex' alignItems='center'>
                    <FormControl
                      variant='outlined'
                      margin='dense'
                      sx={{ margin: 1, minWidth: 150 }}
                      required
                     >
                      <InputLabel id='data-type-label'>Data Type</InputLabel>
                      <Select
                        labelId='data-type-label'
                        id='data-type-select'
                        value={dataType}
                        onChange={event => setDataType(event.target.value)}
                        label='Data Type'
                      >
                        {dataTypeMenuItems.map(item =>
                          <MenuItem key={item.value} value={item.value}>{item.display}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    <FormControl
                      variant='outlined'
                      margin='dense'
                      sx={{ margin: 1, minWidth: 150 }}
                      required
                     >
                      <InputLabel id='data-type-label'>Date Range</InputLabel>
                      <Select
                        labelId='date-range-label'
                        id='date-range-select'
                        value={dateRange}
                        onChange={event => setDateRange(event.target.value)}
                        label='Date Range'
                      >
                        {dateRangeMenuItems.map(item =>
                          <MenuItem key={item.value} value={item.value}>{item.display}</MenuItem>
                        )}
                      </Select>
                    </FormControl>

                    {isLoadingIntegration ? (
                      <Skeleton variant='text' width={200} />
                    ) : (
                      <>
                        <FormControl
                          variant='outlined'
                          margin='dense'
                          sx={{ margin: 1, minWidth: 150 }}
                          required
                        >
                          <InputLabel id='data-type-label'>Sync Frequency</InputLabel>
                          <Select
                            labelId='sync-frequency-label'
                            id='sync-frequency-select'
                            value={syncFrequency}
                            onChange={event => handleChangeSyncFrequency(event.target.value)}
                            label='Sync Frequency'
                          >
                            {syncFrequencyMenuItems.map(item =>
                              <MenuItem key={item.value} value={item.value}>{item.display}{isUpdatingSyncFrequency && <>&nbsp;<CircularProgress color='inherit' size={16}/></>}</MenuItem>
                            )}
                          </Select>
                        </FormControl>

                        {isLoadingIntegrationJob ? (
                            <Skeleton variant='text' width={200} />
                          ) : (
                            integrationJob.job ? (
                              <Box>
                                <Typography variant='caption' display='block'>
                                  <b>Last sync:</b> {integrationJob.job.lastAttemptTime ? moment(integrationJob.job.lastAttemptTime.seconds * 1000).toLocaleString() : 'None'}
                                </Typography>
                                <Typography variant='caption' display='block'>
                                  <b>Next sync:</b> {integrationJob.job.scheduleTime ? moment(integrationJob.job.scheduleTime.seconds * 1000).toLocaleString() : 'None'}
                                </Typography>
                              </Box>
                            ) : (
                              <Box>
                                <Typography variant='caption' display='block'>
                                  Sync job not yet created. Create sync job by syncing the data or updating the sync frequency.
                                </Typography>
                              </Box>
                            )
                          )
                        }
                      </>
                    )}
                  </Box>

                  <Typography variant='h6' paragraph>
                    Timezone
                  </Typography>
                  <Typography variant='body2' gutterBottom>
                    The Konnektive API provides data in the same timezone that your Konnektive account profile is set to. This value must be set properly below to ensure that times are interpreted correctly. Incorrectly setting this value can result in payment times being incorrectly offset from the true value. The timezone can be found by going to your account inside Konnektive, and then looking for &quot;Timezone&quot; inside the Account Information.
                  </Typography>
                  <Typography variant='body2' gutterBottom>
                    It is strongly recommended that you do NOT change this value in your Konnektive account after setting it below, or it can corrupt the data. However, if you must update this value, then set the timezone appropriate and then set the date range to &quot;All Time&quot; and sync the data. This will ensure that all data is reloaded with the new timezone setting so that everything is consistent.
                  </Typography>

                  <Box marginTop={0} display='flex' alignItems='center'>
                    <FormControl
                      variant='outlined'
                      margin='dense'
                      sx={{ margin: 1, minWidth: 300 }}
                      required
                     >
                      {isLoadingIntegration ? <Skeleton variant='rect' width={300} /> : (
                        <Autocomplete
                          id='timezone'
                          options={moment.tz.names()}
                          value={timezone}
                          onChange={event => setTimezone(event.target.textContent)}
                          renderInput={(params) => <TextField {...params} label='Timezone' />}
                        />
                      )}
                    </FormControl>
                  </Box>

                  <Box>
                    <Button
                      className={classes.syncButton}
                      variant='contained'
                      color='secondary'
                      onClick={() => sync()}
                      sx={{ width: 110 }}
                      disabled={!dataType || !dateRange || !syncFrequency || !timezone }
                    >
                      {isSyncing ?
                        <CircularProgress color='inherit' size={24} className={classes.buttonProgress} />
                        : 'Sync Data'
                      }
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Delete
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant='body2' gutterBottom>
                    If you want to remove this data integration so payments are no longer synced, click the button below.
                  </Typography>
                  <Button
                    className={classes.redButton}
                    variant='text'
                    onClick={() => setShowRevokeConfirmation(true)}
                  >
                    Delete Source
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      </Box>
      <Dialog
        open={showRevokeConfirmation}
        onClose={() => setShowRevokeConfirmation(false)}
      >
        <DialogTitle>
          Remove access to account <b>&quot;{integration.nickname}&quot;</b>?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will prevent data from being synced to LTV Numbers.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.redButton}
            variant='text'
            onClick={() => setShowRevokeConfirmation(false)}
          >
            Cancel
          </Button>
          <Button
            color='primary'
            onClick={() => revoke()}
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showMessage}
        autoHideDuration={10000}
        onClose={handleCloseMessage}
      >
        <Alert
          severity={messageSeverity}
          variant='filled'
          onClose={handleCloseMessage}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  )
}

KonnektiveSource.propTypes = {}

export default KonnektiveSource