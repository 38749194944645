import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: theme.spacing(2),
  },
  selectOverride: {
    height: 20,
    width: 70,
    padding: '10px 32px 6px 12px',
  },
}))

const attributionSelectItems = [
  {id: 'customer', name: 'Customer'},
  {id: 'lead', name: 'Lead'},
]

const AttributionSelect = (props) => {
  const classes = useStyles()

  return (
    <FormControl className={classes.root} size='small' variant='outlined'>
      <InputLabel id='attribution-label'>Attribution</InputLabel>
      <Select
        labelId='attribution-label'
        id='attribution-select'
        label='Attribution'
        value={props.attribution}
        onChange={event => props.onAttributionSelect(event.target.value)}
        classes={{ select: classes.selectOverride }}
      >
        {attributionSelectItems.map(item =>
          <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
        )}
      </Select>
    </FormControl>
  )
}

AttributionSelect.propTypes = {
  attribution: PropTypes.string.isRequired,
  onAttributionSelect: PropTypes.func.isRequired,
}

export default AttributionSelect
