const SCORECARD_BASE_CONFIG = {
  'xKey': 'date_time',
  'emptyDataRule': 'zero',
  'dateRangeType': 'dynamic',
  'dateRangeDynamicDays': 30,
  'dateRangeFixedStartDate': null,
  'dateRangeFixedEndDate': null,
  'segments': [],
  'cards': [],
  'resolution': 0,
  'config': {
    'includeCommission': false,
    'includeCosts': true,
  },
  'configItems': {
    'includeCosts': {
      'show': false,
      'name': 'Include Costs',
      'items': [
        {
          'value': true,
          'name': 'Yes'
        },
        {
          'value': false,
          'name': 'No'
        },
      ],
    },
    'includeCommission': {
      'show': true,
      'name': 'Affiliate Commission',
      'items': [
        {
          'value': false,
          'name': 'Subtract from revenue'
        },
        {
          'value': true,
          'name': 'Add to revenue'
        },
      ],
    },
  },
}

export default SCORECARD_BASE_CONFIG
