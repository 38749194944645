import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import TrackChangesIcon from '@mui/icons-material/TrackChanges'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import TrendingDownIcon from '@mui/icons-material/TrendingDown'
import { green, red, amber } from '@mui/material/colors'
import { lighten } from '@mui/material/styles'
import moment from 'moment-timezone'

import { UserContext } from '../../contexts/UserContext'

import { getDisplayValueByFormat } from '../../utils/helpers'

import LoadingSkeleton from './LoadingSkeleton'

const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const TargetSummary = ({
  targetValue,
  targetData,
  metricItem,
  startDate,
  segments,
  segmentsData,
}) => {
  const userDoc = React.useContext(UserContext)

  const [valuePercentage, setValuePercentage] = React.useState(0)
  const [dateTimePercentage, setDateTimePercentage] = React.useState(0)

  const segmentIsLoading = segmentsData.some(segmentData => segmentData.isLoading)

  const valueProgressColor = useMemo(() => {
    if (valuePercentage <= 0.33) return red[500]
    if (valuePercentage <= 0.66) return amber[800]
    if (valuePercentage < 1) return amber[500]
    return green[500]
  }, [valuePercentage])

  const dateTimeProgressColor = useMemo(() => {
    if (valuePercentage < dateTimePercentage) return red[500]
    return green[500]
  }, [valuePercentage, dateTimePercentage])

  // Listen for updates to targetData to set internal state
  useEffect(() => {
    // If valuePercentage doesn't have a value, set it to 0
    if (!targetData || !targetData.valuePercentage) {
      setValuePercentage(0)
      return
    } else {
      setValuePercentage(targetData.valuePercentage)
    }
  }, [targetData])

  // Listen for updates to targetData to set internal state
  useEffect(() => {
    // If dateTimePercentage doesn't have a value, set it to 0
    if (!targetData || !targetData.dateTimePercentage) {
      setDateTimePercentage(0)
      return
    } else {
      setDateTimePercentage(targetData.dateTimePercentage)
    }
  }, [targetData])

  return (
    <Box>
      {segmentIsLoading ? (
        <Box height={120} marginBottom={2}>
          <LoadingSkeleton height={'100%'} width={'100%'} />
        </Box>
      ) : !targetData ? (
        <Box width='100%' display='flex' flexDirection='row' alignItems='flex-start' justifyContent='center' marginBottom={2}>
          <Typography variant='h6' color='textSecondary'>
            Enter a target value to analyze progress
          </Typography>
        </Box>
      ) : startDate.isAfter(moment.tz(TIMEZONE)) ? (
        <Box width='100%' display='flex' flexDirection='row' alignItems='flex-start' justifyContent='center' marginBottom={2}>
          <Typography variant='h6' color='textSecondary'>
            Target period has not started yet
          </Typography>
        </Box>
      ) : (
        <Box>
          <Box
            width='100%'
            display='flex'
            flexDirection={{
              xs: 'column',
              md: 'row'
            }}
            rowGap={2}
            columnGap={2}
            marginBottom={2}
          >
            {/* Value summary */}
            <Box
              padding={2}
              flexBasis={1}
              flexGrow={1}
              sx={{
                backgroundColor: lighten(valueProgressColor, 0.8),
              }}
              >
              <Box display='flex' flexDirection='row' alignItems='center' columnGap={1}>
                <TrackChangesIcon fontSize='medium' />
                <Typography variant='h6' color='textPrimary'>
                  {getDisplayValueByFormat(valuePercentage, 'percent', { decimalCount: 0 })}
                </Typography>
              </Box>
              <Typography variant='h5' color='textPrimary' fontWeight='bold'>
                {getDisplayValueByFormat(
                  targetData.maxValue,
                  metricItem.format,
                  {
                    decimalCount: 0,
                    currency: userDoc.currency,
                  }
                )}
              </Typography>
              <Typography variant='body2' color='textSecondary'>
                of {getDisplayValueByFormat(
                  targetValue,
                  metricItem.format,
                  {
                    decimalCount: 0,
                    currency: userDoc.currency,
                  }
                )}
              </Typography>
              {dateTimePercentage < 1 ? (
                <Box marginTop={1}>
                  {valuePercentage >= 1 ? (
                    <Chip icon={<DoneIcon />} label='Target hit' color='success' />
                  ) : (
                    <Chip
                      icon={<InfoOutlinedIcon />}
                      color='default'
                      label={`${getDisplayValueByFormat(
                        targetData.valueRemaining,
                        metricItem.format,
                        {
                          decimalCount: 0,
                          currency: userDoc.currency,
                        }
                      )} remaining`}
                    />
                  )}
                </Box>
              ) : (
                <Box marginTop={1}>
                  {valuePercentage >= 1 ? (
                    <Chip icon={<DoneIcon />} label='Target hit' color='success' />
                  ) : (
                    <Chip icon={<CloseIcon />} label='Target missed' />
                  )}
                </Box>
              )}
            </Box>

            {/* Date time summary */}
            <Box
              padding={2}
              flexBasis={1}
              flexGrow={1}
              sx={{
                backgroundColor: lighten(dateTimeProgressColor, 0.8),
              }}
              >
              <Box display='flex' flexDirection='row' alignItems='center' columnGap={1}>
                <AccessTimeIcon fontSize='medium' />
                <Typography variant='h6' color='textPrimary'>
                  {getDisplayValueByFormat(dateTimePercentage, 'percent', { decimalCount: 0 })}
                </Typography>
              </Box>
              <Typography variant='h5' color='textPrimary' fontWeight='bold'>
                {getDisplayValueByFormat(targetData.daysElapsed, 'decimal', { decimalCount: 1 })} days
              </Typography>
              <Typography variant='body2' color='textSecondary'>
                of {getDisplayValueByFormat(targetData.daysTotal, 'integer')} days
              </Typography>
              {dateTimePercentage < 1 ? (
                <Box marginTop={1}>
                  <Chip
                    icon={<InfoOutlinedIcon />}
                    color='default'
                    label={`${getDisplayValueByFormat(targetData.daysRemaining, 'decimal', { decimalCount: 1 })} days remaining`}
                  />
                </Box>
              ) : (
                <Box marginTop={1}>
                  <Chip icon={<DoneIcon />} label='Period completed' />
                </Box>
              )}
            </Box>

            {/* Insights */}
            <Box
              padding={2}
              flexBasis={1}
              flexGrow={1}
              sx={{
                backgroundColor: lighten(segments[0].color, 0.8)
              }}
              >
              {(dateTimePercentage < 1 && valuePercentage < 1) ? (
                <React.Fragment>
                  <Box display='flex' flexDirection='row' alignItems='center' columnGap={1}>
                    <AutoAwesomeIcon fontSize='medium' />
                    <Typography variant='h6' color='textPrimary'>
                      To hit target
                    </Typography>
                  </Box>
                  <Typography variant='body2' color='textPrimary' fontSize={13}>
                    <b>{getDisplayValueByFormat(
                      targetData.dailyAverage,
                      metricItem.format,
                      {
                        decimalCount: 0,
                        currency: userDoc.currency,
                      }
                    )}</b> per day
                  </Typography>
                  <Typography variant='body2' color='textPrimary' fontSize={13}>
                    <b>{getDisplayValueByFormat(
                      targetData.weeklyAverage,
                      metricItem.format,
                      {
                        decimalCount: 0,
                        currency: userDoc.currency,
                      }
                    )}</b> per week
                  </Typography>
                  <Typography variant='body2' color='textPrimary' fontSize={13}>
                    <b>{getDisplayValueByFormat(
                      targetData.monthlyAverage,
                      metricItem.format,
                      {
                        decimalCount: 0,
                        currency: userDoc.currency,
                      }
                    )}</b> per month
                  </Typography>
                  <Box marginTop={0.5}>
                    {valuePercentage < dateTimePercentage ? (
                      <Chip
                        icon={<TrendingDownIcon />}
                        label='Behind schedule'
                        sx={{
                          backgroundColor: lighten(amber[500], 0.8),
                          color: theme => theme.palette.getContrastText(lighten(amber[500], 0.8)),
                        }}
                      />
                    ) : (
                      <Chip icon={<TrendingUpIcon />} label='Ahead of schedule' color='success' />
                    )}
                  </Box>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Box display='flex' flexDirection='row' alignItems='center' columnGap={1}>
                    <AutoAwesomeIcon fontSize='medium' />
                    <Typography variant='h6' color='textPrimary'>
                      Summary
                    </Typography>
                  </Box>
                  <Box paddingTop={1} display='flex' flexDirection='column' alignItems='flex-start' rowGap={1}>
                    <Chip
                      icon={<InfoOutlinedIcon />}
                      color={valuePercentage >= 1 ? 'success' : 'default'}
                      label={`Hit ${getDisplayValueByFormat(valuePercentage, 'percent', { decimalCount: 0 })} of target`}
                    />
                    {valuePercentage >= 1 ? (
                      <Chip
                        icon={<DoneIcon />}
                        color='success'
                        label={`Target exceeded by ${getDisplayValueByFormat(
                          targetData.maxValue - targetValue,
                          metricItem.format,
                          {
                            decimalCount: 0,
                            currency: userDoc.currency,
                          }
                        )}`}
                      />
                    ) : (
                      <Chip
                        icon={<CloseIcon />}
                        label={`Target missed by ${getDisplayValueByFormat(
                          targetData.valueRemaining,
                          metricItem.format,
                          {
                            decimalCount: 0,
                            currency: userDoc.currency,
                          }
                        )}`}
                      />
                    )}
                  </Box>
                </React.Fragment>
              )}
            </Box>
          </Box>

          {/* Progress bars */}
          <Box
            display='flex'
            flexDirection='column'
            rowGap={1}
            paddingX={2}
            paddingY={1}
            marginBottom={2}
            sx={{
              backgroundColor: theme => theme.palette.background.paper,
            }}
          >
            {/* Value progress */}
            <Box display='flex' flexDirection='row' alignItems='flex-start' columnGap={2}>
              <Box
                height={24}
                sx={{
                  flexGrow: 0,
                  flexShrink: 0,
                }}>
                <TrackChangesIcon fontSize='medium' />
              </Box>
              <Box
                height={24}
                sx={{
                  flexGrow: 1,
                  flexShrink: 1,
                }}
              >
                <Box
                  sx={{
                    height: 24,
                    borderRadius: theme => theme.shape.borderRadius / 4,
                    backgroundColor: '#e0e0e0',
                    flexGrow: 1,
                    flexShrink: 1,
                  }}
                >
                  <Box
                    display='flex'
                    flexDirection='row'
                    alignItems='center'
                    paddingLeft={1}
                    sx={{
                      backgroundColor: valueProgressColor,
                      height: '100%',
                      width: `${Math.min(valuePercentage, 1) * 100}%`,
                      transition: 'width 1.0s ease-out',
                      borderRadius: theme => theme.shape.borderRadius / 4,
                    }}
                  >
                    <Typography
                      variant='caption'
                      color='textPrimary'
                      sx={{
                        lineHeight: 1,
                        backgroundColor: lighten(valueProgressColor, 0.8),
                        padding: theme => theme.spacing(0.25, 1),
                        borderRadius: theme => theme.shape.borderRadius / 4,
                      }}
                    >
                      {getDisplayValueByFormat(
                        targetData.maxValue,
                        metricItem.format,
                        {
                          decimalCount: 0,
                          currency: userDoc.currency,
                        }
                      )}
                      &nbsp;of&nbsp;
                      {getDisplayValueByFormat(
                        targetValue,
                        metricItem.format,
                        {
                          decimalCount: 0,
                          currency: userDoc.currency,
                        }
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box minWidth={42}>
                <Typography variant='body1' color='textPrimary' style={{ fontWeight: 'bold' }}>
                  {getDisplayValueByFormat(valuePercentage, 'percent', { decimalCount: 0 })}
                </Typography>
              </Box>
            </Box>

            {/* Date time progress */}
            <Box display='flex' flexDirection='row' alignItems='center' columnGap={2}>
              <Box
                display='flex'
                flexDirection='column'
                sx={{
                  flexGrow: 0,
                  flexShrink: 0,
                }}>
                <AccessTimeIcon fontSize='medium' />
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  flexShrink: 1,
                }}
              >
                <Box
                  sx={{
                    height: 24,
                    borderRadius: theme => theme.shape.borderRadius / 4,
                    backgroundColor: '#e0e0e0',
                  }}
                >
                  <Box
                    display='flex'
                    flexDirection='row'
                    alignItems='center'
                    paddingLeft={1}
                    sx={{
                      backgroundColor: dateTimeProgressColor,
                      height: '100%',
                      width: `${dateTimePercentage * 100}%`,
                      transition: 'width 1.0s ease-out',
                      borderRadius: theme => theme.shape.borderRadius / 4,
                    }}
                  >
                    <Typography
                      variant='caption'
                      color='textPrimary'
                      display='inline-flex'
                      alignItems='center'
                      columnGap={1}
                      sx={{
                        lineHeight: 1,
                        backgroundColor: lighten(dateTimeProgressColor, 0.8),
                        padding: theme => theme.spacing(0.25, 1),
                        borderRadius: theme => theme.shape.borderRadius / 4,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {dateTimePercentage < 1 ? (
                        <React.Fragment>
                          {getDisplayValueByFormat(targetData.daysElapsed, 'decimal', { decimalCount: 1 })} of {getDisplayValueByFormat(targetData.daysTotal, 'integer', { decimalCount: 1 })} days
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <DoneIcon fontSize='inherit' />
                          Period completed
                        </React.Fragment>
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box minWidth={42}>
                <Typography variant='body1' color='textPrimary' style={{ fontWeight: 'bold' }}>
                  {getDisplayValueByFormat(dateTimePercentage, 'percent', { decimalCount: 0 })}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}

TargetSummary.propTypes = {
  targetValue: PropTypes.number,
  targetData: PropTypes.object,
  metricItem: PropTypes.object.isRequired,
  startDate: PropTypes.object,
  segments: PropTypes.array.isRequired,
  segmentsData: PropTypes.array.isRequired,
}

export default TargetSummary
