import React, { useMemo }  from 'react'
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup, {
  toggleButtonGroupClasses,
} from '@mui/material/ToggleButtonGroup'
import { styled } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import useMediaQuery from '@mui/material/useMediaQuery'
import DarkTooltip from './DarkTooltip'

const resolutionSelectItems = [
  {
    id: '0',
    value: 0,
    xKeys: ['date_time', 'day'],
    name: {
      date_time: {
        full: 'Day',
        short: 'D',
      },
      day: {
        full :'1 day',
        short: '1D'
      }
    }
  },
  {
    id: '1',
    value: 1,
    xKeys: ['date_time', 'day'],
    name: {
      date_time: {
        full: 'Week',
        short: 'W',
      },
      day: {
        full :'3 days',
        short: '3D'
      }
    }
  },
  {
    id: '2',
    value: 2,
    xKeys: ['date_time', 'day'],
    name: {
      date_time: {
        full: 'Month',
        short: 'M',
      },
      day: {
        full :'5 days',
        short: '5D'
      }
    }
  },
  {
    id: '3',
    value: 3,
    xKeys: ['date_time', 'day'],
    name: {
      date_time: {
        full: 'Quarter',
        short: 'Q',
      },
      day: {
        full :'7 days',
        short: '7D'
      }
    }
  },
  {
    id: '4',
    value: 4,
    xKeys: ['date_time', 'day'],
    name: {
      date_time: {
        full: 'Year',
        short: 'Y',
      },
      day: {
        full :'10 days',
        short: '10D'
      }
    }
  },
  {
    id: '5',
    value: 5,
    xKeys: ['day'],
    name: {
      day: {
        full :'30 days',
        short: '30D'
      }
    }
  },
]

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: theme.shape.borderRadius,
  },
  [`&.${toggleButtonGroupClasses.disabled}`]: {
    border: 0,
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
    {
      marginLeft: -1,
      borderLeft: '1px solid transparent',
    },
}))

const useStyles = makeStyles((theme) => ({
  toggleButton: {
    padding: theme.spacing(0.5, 1),
    height: 32,
    borderRadius: `${theme.shape.borderRadius}px !important`,
  },
}))

const ChartResolutionButtonGroup = ({
  xKey,
  resolution,
  onResolutionChange,
}) => {
  const classes = useStyles()
  const screenSizeLarge = useMediaQuery((theme) => theme.breakpoints.up('lg'))
  const nameSize = useMemo(() => screenSizeLarge ? 'full' : 'short', [screenSizeLarge])

  const handleResolutionChange = (event, newResolution) => {
    if (newResolution !== null) {
      onResolutionChange(newResolution)
    }
  }

  return (
    <div>
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          border: (theme) => `1px solid ${theme.palette.divider}`,
          flexWrap: 'wrap',
        }}
      >
        <StyledToggleButtonGroup
          exclusive
          size='small'
          value={resolution}
          onChange={handleResolutionChange}
        >
          {resolutionSelectItems.map(item => {
            if (!item.xKeys.includes(xKey)) return null
            return (
              <DarkTooltip
                key={item.id}
                title={item.name[xKey].full}
                placement='bottom'
                arrow
              >
                <ToggleButton
                  key={item.id}
                  value={item.value}
                  className={classes.toggleButton}
                >
                  {item.name[xKey][nameSize]}
                </ToggleButton>
              </DarkTooltip>
            )
          })}
        </StyledToggleButtonGroup>
      </Paper>
    </div>
  )
}

ChartResolutionButtonGroup.propTypes = {
  xKey: PropTypes.string.isRequired,
  resolution: PropTypes.number.isRequired,
  onResolutionChange: PropTypes.func.isRequired,
}

export default ChartResolutionButtonGroup