import React from 'react'
import { createTheme } from '@mui/material/styles'
import { ThemeProvider } from '@mui/material/styles'
import baseTheme from '../theme'

const ColorThemeContext = React.createContext({ setPrimary: () => {}, setSecondary: () => {} })

const defaultPrimary = '#1c2566'
const defaultSecondary = '#2267ea'

export default function ColorThemeProvider({ children }) {
  const [primary, setPrimary] = React.useState(defaultPrimary)
  const [secondary, setSecondary] = React.useState(defaultSecondary)

  const colorTheme = React.useMemo(
    () => ({
      setPrimary: (newPrimary) => {
        if (!newPrimary) setPrimary(defaultPrimary)
        else setPrimary(newPrimary)
      },
      setSecondary: (newSecondary) => {
        if (!newSecondary) setSecondary(defaultSecondary)
        else setSecondary(newSecondary)
      },
    }),
    [],
  )

  const theme = React.useMemo(
    () =>
      createTheme({
        ...baseTheme,
        palette: {
          primary: {
            main: primary,
          },
          secondary: {
            main: secondary,
          },
        },
      }),
    [primary, secondary],
  )

  return (
    <ColorThemeContext.Provider value={colorTheme}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </ColorThemeContext.Provider>
  )
}

export { ColorThemeContext }