import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import TextField from '@mui/material/TextField'

import { formatNumber } from '../../../utils/helpers'

const Exporter = (props) => {
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [email, setEmail] = React.useState('')

  const handleClick = () => {
    setDialogOpen(true)
  }

  const handleClose = () => {
    setDialogOpen(false)
  }

  const handleExport = () => {
    props.onExport(email)
    handleClose()
  }

  const catchReturn = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleExport()
    }
  }

  let emailError = false
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (!emailRegex.test(email)) emailError = true

  return (
    <React.Fragment>

    <Button onClick={handleClick} variant='text'>
      Export
    </Button>

    <Dialog
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      aria-labelledby='form-dialog-title'
      maxWidth='xs'
    >
      <DialogTitle id='export-dialog-title'>Export Table Data</DialogTitle>
      <DialogContent>
        <DialogContentText>
            Entering an email address and clicking &quot;Export&quot; below will export all records matching the
            current filters to a CSV file that will be sent via email when the data is ready for download.
            Please double check your email address before exporting.
        </DialogContentText>
        {props.rowCount > 0 &&
          <DialogContentText>
            This export is expected to contain <b>{formatNumber(props.rowCount, 0)} records.</b>
          </DialogContentText>
        }
        <Box marginTop={2}>
          <TextField
            autoFocus
            id='email'
            name='email'
            label='Email'
            value={email}
            onChange={e => setEmail(e.target.value)}
            onKeyPress={catchReturn}
            error={emailError}
            helperText={emailError ? 'Must be a valid email address' : ''}
            fullWidth
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={handleExport}
          color='primary'
          disabled={emailError}
        >
          Export
        </Button>
      </DialogActions>
        </Dialog>
    </React.Fragment>
  );
}

Exporter.propTypes = {
  onExport: PropTypes.func.isRequired,
  rowCount: PropTypes.number,
}

export default Exporter