import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const FullPageLoadingSpinner = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <CircularProgress color='primary' />
    </div>
  )
}

export default FullPageLoadingSpinner
