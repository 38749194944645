import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import CardSales from './Cards/CardSales'
import CardOrders from './Cards/CardOrders'
import CardTopItem from './Cards/CardTopItem'

const ReportsCards = (props) => {
  return (
    <Box marginTop={1}>
      <Grid container spacing={2}>
        <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
          <CardSales
            primaryDateRange={props.primaryDateRange}
            compareDateRange={props.compareDateRange}
            resolution={props.resolution}
          />
        </Grid>
        <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
          <CardOrders
            primaryDateRange={props.primaryDateRange}
            compareDateRange={props.compareDateRange}
            resolution={props.resolution}
          />
        </Grid>
        <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
          <CardTopItem
            primaryDateRange={props.primaryDateRange}
            compareDateRange={props.compareDateRange}
            metric='revenue'
            breakdown={{ id: 'product_id', name: 'Product' }}
            title='Top products by revenue'
            isDollar={true}
          />
        </Grid>
        <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
          <CardTopItem
            primaryDateRange={props.primaryDateRange}
            compareDateRange={props.compareDateRange}
            metric='orders'
            breakdown={{ id: 'product_id', name: 'Product' }}
            title='Top products by orders'
            isDollar={false}
          />
        </Grid>
        <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
          <CardTopItem
            primaryDateRange={props.primaryDateRange}
            compareDateRange={props.compareDateRange}
            metric='revenue'
            breakdown={{ id: 'funnel_id', name: 'Funnel' }}
            title='Top funnels by revenue'
            isDollar={true}
          />
        </Grid>
        <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
          <CardTopItem
            primaryDateRange={props.primaryDateRange}
            compareDateRange={props.compareDateRange}
            metric='orders'
            breakdown={{ id: 'funnel_id', name: 'Funnel' }}
            title='Top funnels by orders'
            isDollar={false}

          />
        </Grid>
        <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
          <CardTopItem
            primaryDateRange={props.primaryDateRange}
            compareDateRange={props.compareDateRange}
            metric='revenue'
            breakdown={{ id: 'affiliate_id_2', name: 'Affiliate' }}
            title='Top affiliates by revenue'
            isDollar={true}
          />
        </Grid>
        <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
          <CardTopItem
            primaryDateRange={props.primaryDateRange}
            compareDateRange={props.compareDateRange}
            metric='orders'
            breakdown={{ id: 'affiliate_id_2', name: 'Affiliate' }}
            title='Top affiliates by orders'
            isDollar={false}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

ReportsCards.propTypes = {
  primaryDateRange: PropTypes.object.isRequired,
  compareDateRange: PropTypes.object.isRequired,
  resolution: PropTypes.string.isRequired,
}

export default ReportsCards