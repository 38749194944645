import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import moment from 'moment-timezone'

import METRIC_ITEMS_MAP from './MetricItems'

const metricItems = Object.values(METRIC_ITEMS_MAP)
  .filter(metric => metric.allowScorecard)
  .sort((a, b) => a.name.localeCompare(b.name))

const scoreTypeItems = [
  {
    id: 'overunder',
    name: 'Over/Under',
  },
  {
    id: 'stoplight',
    name: 'Stoplight',
  },
  {
    id: 'previous',
    name: 'vs. Previous',
  },
]

const EMPTY_CARD_DATA = {
  name: '',
  metricKey: '',
  scoreType: 'overunder',
  scoreValueOverUnder: 0,
  scoreValueStoplightGreen: 0,
  scoreValueStoplightYellow: 0,
}

const CardDialog = ({
  open,
  onClose,
  metricKey,
  cards,
  startDate,
  endDate,
  onCardAdd,
  onCardUpdate,
  onCardDelete,
}) => {
  const [cardData, setCardData] = useState(() => {
    if (metricKey) {
      const card = cards.find(c => c.metricKey === metricKey)
      return {
        ...EMPTY_CARD_DATA,
        name: card.name || '',
        metricKey: card.metricKey,
        scoreType: card.scoreType,
        scoreValueOverUnder: card.scoreValueOverUnder,
        scoreValueStoplightGreen: card.scoreValueStoplightGreen,
        scoreValueStoplightYellow: card.scoreValueStoplightYellow,
      }
    } else {
      return {
        ...EMPTY_CARD_DATA,
        dateRangeDynamicDays: startDate && endDate ? moment(endDate).diff(startDate, 'days') + 1 : 90,
      }
    }
  })

  const handleChange = (event, type='string') => {
    setCardData({
      ...cardData,
      [event.target.name]: type === 'number' ?
        (isNaN(parseFloat(event.target.value)) ? '' : parseFloat(event.target.value))
        : event.target.value
    })
  }

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') return
    onClose()
  }

  const handleSubmit = (metricKey=null) => {
    if (metricKey) {
      // Update the card or save as a new card
      onCardUpdate(metricKey, cardData)
    } else {
      // Create a new card
      onCardAdd(cardData)
    }
    onClose()
  }

  const handleDelete = () => {
    onCardDelete(metricKey)
    onClose()
  }

  const isFormValid = (cardData) => {
    if (cardData.scoreType === 'overunder') {
      return isNumberValid(cardData.scoreValueOverUnder)
    } else if (cardData.scoreType === 'stoplight') {
      return (
        isNumberValid(cardData.scoreValueStoplightGreen)
        && isNumberValid(cardData.scoreValueStoplightYellow)
        && cardData.scoreValueStoplightGreen > cardData.scoreValueStoplightYellow
      )
    } else {
      return true
    }
  }

  const isNumberValid = (value) => {
    return !isNaN(parseFloat(value))
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='xs' fullWidth>
      <DialogTitle>{metricKey ? 'Update Card' : 'Create New Card'}</DialogTitle>
      <DialogContent>
        <Box display='flex' flexDirection='column' gap={2} paddingTop={1}>
          <FormControl size='small' fullWidth required>
            <InputLabel id='metricLabel'>Metric</InputLabel>
            <Select
              name='metricKey'
              label='Metric'
              value={cardData.metricKey}
              onChange={handleChange}
              fullWidth
            >
              {metricItems.map(item => (
                <MenuItem
                  key={item.key}
                  value={item.key}
                  // Disable the item if it is already selected in another card
                  disabled={
                    metricKey ?
                      cards.filter(card => card.metricKey !== metricKey).some(card => card.metricKey === item.key)
                      : cards.some(card => card.metricKey === item.key)
                  }
                >
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            name='name'
            label='Display name (optional)'
            value={cardData.name}
            onChange={handleChange}
            size='small'
            helperText='Will default to metric name if blank'
            fullWidth
          />
          <FormControl size='small' fullWidth required>
            <InputLabel id='scoreTypeLabel'>Scoring</InputLabel>
            <Select
              name='scoreType'
              label='Scoring'
              value={cardData.scoreType}
              onChange={handleChange}
              fullWidth
            >
              {scoreTypeItems.map(item => (
                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {cardData.scoreType === 'overunder' && (
            <React.Fragment>
              <Typography variant='body2'>
                Card will be scored green if the metric value is greater than the over/under value, yellow if equal to, and red if below.
                This value is determined based on the <b>Scoring Period</b> of the Scorecard.
              </Typography>
              <TextField
                name='scoreValueOverUnder'
                label='Over/Under Value'
                value={cardData.scoreValueOverUnder}
                onChange={e => handleChange(e, 'number')}
                error={!isNumberValid(cardData.scoreValueOverUnder)}
                helperText={
                  !isNumberValid(cardData.scoreValueOverUnder) ?
                    'Value must be a number' : null
                }
                size='small'
                fullWidth
              />
            </React.Fragment>
          )}
          {cardData.scoreType === 'stoplight' && (
            <React.Fragment>
              <Typography variant='body2'>
                Card will be scored green if the metric value is greater than or equal to the green value, yellow if greater than or equal to the yellow value, and red otherwise.
              </Typography>
              <TextField
                name='scoreValueStoplightGreen'
                label='Green value'
                value={cardData.scoreValueStoplightGreen}
                onChange={e => handleChange(e, 'number')}
                error={!isNumberValid(cardData.scoreValueStoplightGreen) || cardData.scoreValueStoplightGreen <= cardData.scoreValueStoplightYellow}
                helperText={
                  !isNumberValid(cardData.scoreValueStoplightGreen) ?
                    'Value must be a number' : (
                      cardData.scoreValueStoplightGreen <= cardData.scoreValueStoplightYellow ?
                        'Green value must be greater than yellow value' : null
                    )
                }
                size='small'
                fullWidth
              />
              <TextField
                name='scoreValueStoplightYellow'
                label='Yellow value'
                value={cardData.scoreValueStoplightYellow}
                onChange={e => handleChange(e, 'number')}
                error={!isNumberValid(cardData.scoreValueStoplightYellow)}
                helperText={
                  !isNumberValid(cardData.scoreValueStoplightYellow) ?
                    'Value must be a number' : null
                }
                size='small'
                fullWidth
              />
            </React.Fragment>
          )}
          {cardData.scoreType === 'previous' && (
            <React.Fragment>
              <Typography variant='body2'>
                Card will be scored green if the metric value is greater than its value on the last scorecard, yellow if equal, and red if below.
              </Typography>
            </React.Fragment>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        {metricKey && (
          <Button
            onClick={handleDelete}
            color='error'
          >
            Delete
          </Button>
        )}

        <div style={{ flexGrow: 1 }} />

        <Button
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleSubmit(metricKey)}
          color='secondary'
          disabled={!isFormValid(cardData)}
        >
          {metricKey ? 'Update' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

CardDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  metricKey: PropTypes.string,
  cards: PropTypes.array.isRequired,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onCardAdd: PropTypes.func.isRequired,
  onCardUpdate: PropTypes.func.isRequired,
}

export default CardDialog