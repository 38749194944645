import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import Popover from '@mui/material/Popover'
import TextField from '@mui/material/TextField'
import makeStyles from '@mui/styles/makeStyles'
import AddIcon from '@mui/icons-material/Add'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import LabelIcon from '@mui/icons-material/Label'
import LabelOffIcon from '@mui/icons-material/LabelOff'

import { FirebaseContext } from '../../../utils/firebase'
import 'firebase/auth'

import {
  useQuery,
  useQueryClient,
  useMutation,
} from 'react-query'

import { API_ROOT_URL, NONE_LABEL } from '../../../constants'
import { stableSort } from '../../../utils/helpers'

const useStyles = makeStyles(theme => ({
  root: {
  },
  popover: {
    marginTop: theme.spacing(1),
  },
  listItemContainerOverride: {
    '&:hover $listItemSecondaryAction': {
      visibility: 'visible',
    },
  },
  listItem: {
    paddingRight: 80,
  },
  listItemIconRootOverride: {
    minWidth: 42,
  },
  listItemSecondaryAction: {
    visibility: 'hidden'
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttonOverride: {
    textTransform: 'none',
  },
}))

const TableControlsLabels = (props) => {
  const { breakdown, setLabels, selectedLabel, setSelectedLabel } = props
  const classes = useStyles()
  const queryClient = useQueryClient()
  const firebase = React.useContext(FirebaseContext)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [formLabelId, setFormLabelId] = React.useState(null)
  const [formLabelName, setFormLabelName] = React.useState('')

  const { isLoading, data: labels } = useQuery(['labels', breakdown], () => {
    if (!breakdown) return []
    return firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/labels/breakdowns/${breakdown.id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then(res => res.json())
    })},
    {
      enabled: Boolean(breakdown),
      cacheTime: 15 * 60 * 1000,  // 15 minutes
      staleTime: 15 * 60 * 1000,  // 15 minutes
      refetchOnWindowFocus: false,
    }
  )

  React.useEffect(() => {
    // Set selected label to 'none' whenever the breakdown changes
    setSelectedLabel(NONE_LABEL)
  }, [breakdown, setSelectedLabel])

  React.useEffect(() => {
    // Set labels to match query data
    setLabels(labels)
  }, [labels, setLabels])

  const { mutate: mutateCreateLabel } = useMutation(
    name => firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/labels`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          breakdown_id: breakdown.id,
          name
        })
      })
    }),
    {
      onMutate: name => {
        setFormLabelName('')
        queryClient.cancelQueries('labels')
        const previousValue = queryClient.getQueryData(['labels', breakdown])
        queryClient.setQueryData(['labels', breakdown], oldLabels => {
          return stableSort([
            ...oldLabels,
            { breakdown_id: breakdown.id, name, id: name }
          ], 'asc', 'name')
        })

        return previousValue
      },
      onError: (err, variables, previousValue) =>
        queryClient.setQueryData(['labels', breakdown], previousValue),
      onSettled: () => {
        queryClient.invalidateQueries('labels')
      }
    }
  )

  const { mutate: mutateEditLabel } = useMutation(
    formData => firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/labels/${formData.id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ name: formData.name })
      })
    }),
    {
      onMutate: formData => {
        setFormLabelName('')
        setFormLabelId(null)
        queryClient.cancelQueries('labels')
        const previousValue = queryClient.getQueryData(['labels', breakdown])
        queryClient.setQueryData(['labels', breakdown], oldLabels => {
          return stableSort([
            ...oldLabels.filter(label => label.id !== formData.id),
            { breakdown_id: breakdown.id, name: formData.name, id: formData.id }
          ], 'asc', 'name')
        })

        return previousValue
      },
      onError: (err, variables, previousValue) =>
        queryClient.setQueryData(['labels', breakdown], previousValue),
      onSettled: () => {
        queryClient.invalidateQueries('labels')
      }
    }
  )

  const { mutate: mutateDeleteLabel } = useMutation(
    id => firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/labels/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
    }),
    {
      onMutate: id => {
        queryClient.cancelQueries('labels')
        const previousValue = queryClient.getQueryData(['labels', breakdown])
        queryClient.setQueryData(['labels', breakdown], oldLabels => {
          return oldLabels.filter(label => label.id !== id)
        })

        return previousValue
      },
      onError: (err, variables, previousValue) =>
        queryClient.setQueryData(['labels', breakdown], previousValue),
      onSettled: () => {
        queryClient.invalidateQueries('labels')
        queryClient.invalidateQueries('labels-applied')
      }
    }
  )

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLabelSelect = (label) => {
    setSelectedLabel(label)
    setAnchorEl(null)
  }

  const cancelSaveLabel = () => {
    setDialogOpen(false)
    setFormLabelName('')
    setFormLabelId(null)
  }

  const saveLabel = () => {
    setDialogOpen(false)
    if (formLabelId) {
      mutateEditLabel({ id: formLabelId, name: formLabelName })
    } else {
      mutateCreateLabel(formLabelName)
    }
  }

  const editLabel = (label) => {
    setFormLabelId(label.id)
    setFormLabelName(label.name)
    setDialogOpen(true)
  }

  const deleteLabel = (id) => {
    mutateDeleteLabel(id)
    if (selectedLabel.id === id) {
      handleLabelSelect(NONE_LABEL)
    }
  }

  const catchReturn = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      saveLabel()
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'popover-labels' : undefined

  return (
      <div className={classes.root}>
        <Button
          aria-describedby={id}
          variant='outlined'
          onClick={handleClick}
          startIcon={selectedLabel.id === NONE_LABEL.id ?
            <LabelOffIcon color={breakdown ? 'primary' : 'disabled'} />
            : <LabelIcon color={breakdown ? 'primary' : 'disabled'} />
          }
          endIcon={<ArrowDropDownIcon color={breakdown ? 'primary' : 'disabled'} />}
          color='primary'
          disabled={!breakdown}
          classes={{ root: classes.buttonOverride }}
        >
          {selectedLabel.name}
        </Button>
        <Popover
          className={classes.popover}
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
          transformOrigin={{ vertical: 'top', horizontal: 'left', }}
        >
          <Box>
            <List component='ul' aria-label='breakdown selector' dense subheader={<ListSubheader>Filter by label</ListSubheader>}>
              <ListItem
                className={classes.listItem}
                selected={selectedLabel.id === NONE_LABEL.id}
                onClick={() => handleLabelSelect(NONE_LABEL)}
                button
              >
                <ListItemIcon classes={{ root: classes.listItemIconRootOverride }}>
                  <LabelOffIcon />
                </ListItemIcon>
                <ListItemText primary={NONE_LABEL.name} />
              </ListItem>
              {isLoading && (
                <ListItem disabled>
                  Loading Labels...
                </ListItem>
              )}
              {labels && labels.map(label =>
                <ListItem
                  key={label.id}
                  className={classes.listItem}
                  selected={selectedLabel.id === label.id}
                  onClick={() => handleLabelSelect(label)}
                  classes={{ container: classes.listItemContainerOverride }}
                  button
                >
                  <ListItemIcon classes={{ root: classes.listItemIconRootOverride }}>
                    <LabelIcon />
                  </ListItemIcon>
                  <ListItemText primary={label.name} />
                  <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
                    <IconButton edge='end' size='small' aria-label='edit' onClick={() => editLabel(label)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton edge='end' size='small' aria-label='delete' onClick={() => deleteLabel(label.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )}
              <Divider className={classes.divider} />
              <ListItem
                className={classes.listItem}
                onClick={() => setDialogOpen(true)}
                button
              >
                <ListItemIcon classes={{ root: classes.listItemIconRootOverride }}>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText primary={'Create Label'} />
              </ListItem>
            </List>
          </Box>
        </Popover>

        {breakdown && (
          <Dialog
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            aria-labelledby='form-dialog-title'
            maxWidth='xs'
          >
            <DialogTitle id='form-dialog-title'>{formLabelId ? 'Edit' : 'Create'} label for <b>{breakdown.name}</b> breakdown</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Labels allow for you to group table rows and quickly filter the table. Specify a name below.
              </DialogContentText>
              <TextField
                autoFocus
                margin='dense'
                id='name'
                label='Label Name'
                fullWidth
                value={formLabelName}
                onChange={e => setFormLabelName(e.target.value)}
                onKeyPress={catchReturn}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={cancelSaveLabel} color='primary'>
                Cancel
              </Button>
              <Button onClick={saveLabel} color='primary'>
                Save
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
  )
}

TableControlsLabels.propTypes = {
  breakdown: PropTypes.object,
  setLabels: PropTypes.func.isRequired,
  selectedLabel: PropTypes.object,
  setSelectedLabel: PropTypes.func.isRequired,
}

export default TableControlsLabels
