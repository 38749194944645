import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Alert from '@mui/material/Alert'
import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import LinearProgress from '@mui/material/LinearProgress'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListSubheader from '@mui/material/ListSubheader'
import Paper from '@mui/material/Paper'
import Popover from '@mui/material/Popover'
import Skeleton from '@mui/material/Skeleton'
import Snackbar from '@mui/material/Snackbar'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import LabelIcon from '@mui/icons-material/Label'
import { AxisArrow, FilterPlus } from 'mdi-material-ui'
import makeStyles from '@mui/styles/makeStyles'
import { useHistory } from 'react-router-dom'
import moment from 'moment-timezone'

import { FirebaseContext } from '../../../utils/firebase'
import 'firebase/auth'

import {
  useQuery,
  useQueryClient,
  useMutation
} from 'react-query'

import EnhancedTableHead from './TableHead'
import TableControls from '../TableControls'
import CustomColorSwitch from '../CustomColorSwitch'

import * as ROUTES from '../../../constants/routes'
import { formatNumber, stableSort } from '../../../utils/helpers'
import { ALL_DATA_KEY, API_ROOT_URL, NONE_LABEL } from '../../../constants/'

const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    position: 'relative',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  actionTableCell: {
    minWidth: 162,
    position: 'sticky',
    left: 0,
    background: 'white',
    zIndex: 1,
  },
  nameTableCell: {
    minWidth: 250,
    wordBreak: 'break-word',
    position: 'sticky',
    left: 162,
    background: 'white',
    boxShadow: '3px 0 2px -2px #a7a7a7',
    zIndex: 1,
  },
  tableActionButton: {
    padding: theme.spacing(1) / 2,
  },
  linearProgress: {
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    position: 'absolute',
    width: '100%',
  },
  summaryTypography: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(1, 2, 0, 2)
  },
  popover: {
    marginTop: theme.spacing(1),
  },
  listItemIconRootOverride: {
    minWidth: 42,
  },
  listItemSecondaryIconRootOverride: {
    minWidth: 24,
    width: 24,
    marginLeft: theme.spacing(2),
  },
  listItemTextApplyRootOverride: {
    paddingLeft: 42,
  },
  noRowsTableCell: {
    textAlign: 'center',
  },
  noRowsTypography: {
    color: theme.palette.text.secondary,
  },
}))

const AnalysisTable = (props) => {
  const { attribution, context, extendBackend, includeCosts, includeCommission, order, orderBy, rows, colors, setOrder, setOrderBy, setRows, onFilterAdd } = props

  const classes = useStyles()
  const firebase = React.useContext(FirebaseContext)
  const history = useHistory()
  const [labels, setLabels] = React.useState([])
  const [selectedLabel, setSelectedLabel] = React.useState(NONE_LABEL)
  const [searchText, setSearchText] = React.useState('')
  const [showActiveOnly, setShowActiveOnly] = React.useState(false)
  const [columns, setColumns] = React.useState('default')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const { isLoading, isError, data } = useQuery(['analysis-table', attribution, context, extendBackend, includeCosts, includeCommission], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/tables/analysis`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          attribution,
          startDate: context.dates.start.format(),
          endDate: context.dates.end.format(),
          timezone: TIMEZONE,
          breakdown: context.breakdown,
          filters: context.filters,
          extendBackend,
          includeCosts,
          includeCommission
        })
      }).then(res => res.json())
    }),
    {
      enabled: !!context.dates.start && !!context.dates.end,
      cacheTime: 15 * 60 * 1000,  // 15 minutes
      staleTime: 15 * 60 * 1000,  // 15 minutes
      refetchOnWindowFocus: false,
    }
  )

  const { data: labelsApplied = [] } = useQuery(['labels-applied', context.breakdown], () => {
    if (!context.breakdown) return []
    return firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/labelsApplied/breakdowns/${context.breakdown.id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then(res => res.json())
    })},
    {
      enabled: Boolean(context.breakdown),
      cacheTime: 15 * 60 * 1000,  // 15 minutes
      staleTime: 15 * 60 * 1000,  // 15 minutes
      refetchOnWindowFocus: false,
    }
  )

  React.useEffect(() => {
    if (data) {
      let newRows = []
      if (data.table) {
        const sortedRows = stableSort(data.table, order, orderBy)
        sortedRows.forEach((row, index) => {
          newRows.push({ ...row, checked: index < 5 }) // Turn on top 5 rows on initialization
        })
      }
      setRows(newRows)
    } else {
      setRows([])
    }
  }, [data, order, orderBy, setRows])

  const handleRequestSort = (_, property) => {
    const isDesc = orderBy === property && order === 'desc'
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  }

  const handleFilterClick = (value, valueName) => {
    const filter = {
      id: context.breakdown.id,
      name: context.breakdown.name,
      value,
      valueName
    }
    onFilterAdd(filter)
  }

  const handlePathAnalysisClick = (value, valueName) => {
    history.push(ROUTES.PATH_ANALYSIS, {
      startDate: context.dates.start.format(),
      endDate: context.dates.end.format(),
      breakdown: context.breakdown,
      filters: context.filters,
      attribution,
      order,
      orderBy,
      addFilter: context.breakdown ? {
        id: context.breakdown.id,
        name: context.breakdown.name,
        value,
        valueName
      } : null
    })
  }

  const handleChangeSearchText = (text) => {
    setSearchText(text)
  }

  const handleSetShowActiveOnly = (value) => {
    setShowActiveOnly(value)
    setPage(0)
  }

  const handleChangePage = (_, newPage) => {
    setPage(newPage)
  }

  const handleColumnsSelect = (value) => {
    setColumns(value)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangeSwitch = (id, checked) => {
    const index = rows.findIndex(row => row.breakdown === id)
    let newRows = [...rows]
    newRows[index] = { ...newRows[index], checked }
    setRows(newRows)
  }

  const handleSwitchToggleSelect = (count) => {
    let newRows = [...rows]
    rows.forEach((thisRow, i) => {
      const matchIndex = rows.findIndex(row => row.breakdown === thisRow.breakdown)
      if (i < count) {
        newRows[matchIndex] = { ...newRows[matchIndex], checked: true }
      } else {
        newRows[matchIndex] = { ...newRows[matchIndex], checked: false }
      }
    })
    setRows(newRows)
  }

  const getFilteredRows = (rows, selectedLabel, searchText, showActiveOnly) => {
    let filteredRows = rows

    // Filter by selected label
    if (selectedLabel !== NONE_LABEL) {
      filteredRows = filteredRows.filter(row => {
        return labelsApplied.filter(la => la.breakdown_value === row.breakdown).map(la => la.label_id).indexOf(selectedLabel.id) > -1
      })
    }

    // Only show active rows
    if (showActiveOnly) {
      filteredRows = filteredRows.filter(row => row.checked)
    }

    if (searchText.length === 0) return filteredRows

    // Filter by search text
    return filteredRows.filter(row => {
      const alias = row.name || row.id
      const platform_id = row.platform_id
      if (alias) return alias.toLowerCase().indexOf(searchText.toLowerCase()) > -1 || (platform_id && platform_id.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
      return true
    })
  }

  const filteredRows = getFilteredRows(rows, selectedLabel, searchText, showActiveOnly)

  const SummaryMessage = (
    <Typography className={classes.summaryTypography} component='div' variant='caption'>
      Table shows aggregated data between <b>{context.dates.start ? context.dates.start.format('MM/DD/YY') : 'null'}</b> and <b>{context.dates.end ? context.dates.end.format('MM/DD/YY') : 'null'}</b>{context.breakdown ? <span>, with one row for each <b>{context.breakdown.name}</b></span> : ''}{context.filters.length > 0 ? <span>, filtered by <b>{context.filters.map((filter, index) => `${index > 0 ? ',' : ''} ${filter.name} = ${filter.valueName}`)}</b></span> : ''}, using <b>{attribution}</b> LTV attribution.
    </Typography>
  )

  return (
    <Fragment>
      <div className={classes.root}>
        {isLoading && <LinearProgress className={classes.linearProgress} />}
        <Paper className={classes.paper}>
          {SummaryMessage}
          <TableControls
            breakdown={context.breakdown}
            setLabels={setLabels}
            selectedLabel={selectedLabel}
            setSelectedLabel={setSelectedLabel}
            searchText={searchText}
            onSearchTextChange={handleChangeSearchText}
            showActiveOnly={showActiveOnly}
            onShowActiveOnlyChange={handleSetShowActiveOnly}
            showColumnsSelect={true}
            columns={columns}
            setColumns={handleColumnsSelect}
          />
          {filteredRows.length < rows.length && (
            <Alert severity='info'>
              Some rows have been filtered by the settings above
            </Alert>
          )}
          <TableContainer>
            <Table
              aria-labelledby='tableTitle'
              size='small'
              aria-label='enhanced table'
            >
              <EnhancedTableHead
                attribution={attribution}
                columns={columns}
                hideNameColumn={!context.breakdown}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                onSwitchToggleSelect={handleSwitchToggleSelect}
              />
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={22}>
                      <Skeleton animation={false} />
                    </TableCell>
                  </TableRow>
                ) : (filteredRows.length === 0 ? (
                  <TableRow>
                    <TableCell className={classes.noRowsTableCell} colSpan={4}>
                      <Typography className={classes.noRowsTypography}>
                        No rows
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row =>
                    <EnhancedTableRow
                      key={row.breakdown}
                      classes={classes}
                      columns={columns}
                      row={row}
                      attribution={attribution}
                      context={context}
                      colors={colors}
                      labels={labels}
                      labelsApplied={labelsApplied.filter(label => label.breakdown_value === row.breakdown)}
                      handleChangeSwitch={handleChangeSwitch}
                      handleFilterClick={() => handleFilterClick(row.breakdown, row.name || row.id)}
                      handlePathAnalysisClick={() => handlePathAnalysisClick(row.breakdown, row.name || row.id)}
                    />)
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component='div'
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
        open={isError}
        autoHideDuration={10000}
      >
        <Alert severity='error'>
          Error fetching table data. Please try again, and if problem continues, contact support.
        </Alert>
      </Snackbar>
    </Fragment>
  );
}

const EnhancedTableRow = (props) => {
  const { classes, columns, row, context, colors, labels, labelsApplied, handleChangeSwitch, handleFilterClick, handlePathAnalysisClick } = props
  const queryClient = useQueryClient()
  const firebase = React.useContext(FirebaseContext)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const { mutate: mutateAddLabel } = useMutation(
    newLabelApplied => firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/labelsApplied`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(newLabelApplied)
      })
    }),
    {
      onMutate: newLabelApplied => {
        queryClient.cancelQueries('labels-applied')
        const previousValue = queryClient.getQueryData(['labels-applied', context.breakdown])
        queryClient.setQueryData(['labels-applied', context.breakdown], oldLabelsApplied => {
          return [...oldLabelsApplied, newLabelApplied]
        })
        return previousValue
      },
      onError: (err, variables, previousValue) =>
        queryClient.setQueryData(['labels-applied', context.breakdown], previousValue),
      onSettled: () => {
        queryClient.invalidateQueries('labels-applied')
      }
    }
  )

  const { mutate: mutateRemoveLabel } = useMutation(
    id => firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/labelsApplied/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
    }),
    {
      onMutate: id => {
        queryClient.cancelQueries('labels-applied')
        const previousValue = queryClient.getQueryData(['labels-applied', context.breakdown])
        queryClient.setQueryData(['labels-applied', context.breakdown], oldLabelsApplied => {
          return oldLabelsApplied.filter(label => label.id !== id)
        })
        return previousValue
      },
      onError: (err, variables, previousValue) =>
        queryClient.setQueryData(['labels-applied', context.breakdown], previousValue),
      onSettled: () => {
        queryClient.invalidateQueries('labels-applied')
      }
    }
  )

  const handleLabelClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleToggleLabel = (id) => {
    const labelAppliedIndex = labelsApplied.map(la => la.label_id).indexOf(id)
    if (labelAppliedIndex > -1) {
      const idToRemove = labelsApplied[labelAppliedIndex].id
      mutateRemoveLabel(idToRemove)
    } else {
      mutateAddLabel({
        breakdown_id: context.breakdown.id,
        breakdown_value: row.breakdown,
        label_id: id
      })
    }
  }

  const labelOpen = Boolean(anchorEl)
  const popoverId = labelOpen ? 'popover-row-labels' : undefined

  return (
    <TableRow hover>
      <TableCell className={classes.actionTableCell} scope='row' align='left' sx={{ width: context.breakdown ? 162 : 80, minWidth: context.breakdown ? 162 : 80 }}>
        <CustomColorSwitch
          id={row.breakdown}
          colorHex={colors[row.breakdown]}
          checked={row.checked}
          onChange={handleChangeSwitch}
        />
        {context.breakdown &&
          <Fragment>
            <IconButton
              className={classes.tableActionButton}
              onClick={handleLabelClick}
              color='primary'
              size='small'>
              <Badge
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                color='secondary'
                badgeContent={labelsApplied.length}
              >
                <LabelIcon fontSize='small' />
              </Badge>
            </IconButton>
            <Popover
              id={popoverId}
              open={labelOpen}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left', }}
            >
              <Box>
                <List component='ul' aria-label='breakdown selector' dense subheader={<ListSubheader>Manage labels</ListSubheader>}>
                  {labels && labels.map(label =>
                    <ListItem
                      key={label.id}
                      className={classes.listItem}
                      onClick={() => handleToggleLabel(label.id)}
                      button
                    >
                      <ListItemIcon classes={{ root: classes.listItemIconRootOverride }}>
                        <LabelIcon />
                      </ListItemIcon>
                      <ListItemText primary={label.name} />
                      <ListItemSecondaryAction>
                        <Checkbox
                          color='primary'
                          edge='end'
                          onChange={() => handleToggleLabel(label.id)}
                          checked={labelsApplied.map(la => la.label_id).indexOf(label.id) > -1}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  )}
                </List>
              </Box>

            </Popover>
          </Fragment>
        }
        {context.breakdown &&
          <IconButton
            className={classes.tableActionButton}
            onClick={handleFilterClick}
            color='primary'
            size='small'>
            <FilterPlus fontSize='small' />
          </IconButton>
        }
        <Tooltip title='Path Analysis' aria-label='path-analysis'>
          <IconButton
            className={classes.tableActionButton}
            onClick={handlePathAnalysisClick}
            color='primary'
            size='small'>
            <AxisArrow fontSize='small' />
          </IconButton>
        </Tooltip>
      </TableCell>
      {row.breakdown !== ALL_DATA_KEY && (
        <TableCell align='left' className={classes.nameTableCell}>
          {row.name || row.id}
          {context.breakdown && (context.breakdown.id === 'product_id' || context.breakdown.id === 'funnel_id' || context.breakdown.id === 'integration_id') && (
            <>
              <Typography variant='caption' display='inline'>
                &nbsp; [ID:{row.platform_id}]
              </Typography>
              <Typography variant='caption' display='block'>
                <b>{row.platform}</b>
                {context.breakdown.id === 'product_id' && <b>&nbsp;&gt;&nbsp;{row.funnel_nickname} [ID:{row.funnel_platform_id}]</b>}
              </Typography>
            </>
          )}
        </TableCell>
      )}
      {(() => {
        switch(columns) {
          case 'detailed_ltv':
            return <TableCellsDetailedLTV {...props} />
          case 'detailed_ltr':
            return <TableCellsDetailedLTR {...props} />
          case 'default':
          default:
            return <TableCellsDefault {...props} />
        }
      })()}
    </TableRow>
  );
}

const TableCellsDefault = (props) => {
  const { row, attribution } = props
  return (
    <Fragment>
      <TableCell align='right'>
        {formatNumber(attribution === 'customer' ? row.customers_new : row.leads, 0)}
      </TableCell>
      <TableCell align='right'>
        {`$${formatNumber(row.ltv_0, 2, false)}`}
      </TableCell>
      <TableCell align='right'>
        {`$${formatNumber(row.ltv_30, 2, false)}`}
      </TableCell>
      <TableCell align='right'>
        {`$${formatNumber(row.ltv_60, 2, false)}`}
      </TableCell>
      <TableCell align='right'>
        {`$${formatNumber(row.ltv_90, 2, false)}`}
      </TableCell>
      <TableCell align='right'>
        {`$${formatNumber(row.ltr_max, 2, false)}`}
      </TableCell>
      {attribution === 'customer' && (
        <Fragment>
          <TableCell align='right'>
            {formatNumber(row.customers_total, 0)}
          </TableCell>
          <TableCell align='right'>
            {formatNumber(row.orders, 0)}
          </TableCell>
          <TableCell align='right'>
            {`$${formatNumber(row.aov, 2, false)}`}
          </TableCell>
          <TableCell align='right'>
            {`$${formatNumber(row.revenue_orders, 2, false)}`}
          </TableCell>
          <TableCell align='right'>
            {`$${formatNumber(row.revenue_rebills, 2, false)}`}
          </TableCell>
          <TableCell align='right'>
            {`$${formatNumber(row.revenue_gross, 2, false)}`}
          </TableCell>
          <TableCell align='right'>
            {`$${formatNumber(row.revenue_refunds, 2, false)}`}
          </TableCell>
          <TableCell align='right'>
            {`$${formatNumber(row.revenue_net, 2, false)}`}
          </TableCell>
          <TableCell align='right'>
            {`$${formatNumber(row.revenue_net_frontend, 2, false)}`}
          </TableCell>
          <TableCell align='right'>
            {`$${formatNumber(row.revenue_net_backend, 2, false)}`}
          </TableCell>
          <TableCell align='right'>
            {formatNumber(row.orders_frontend, 0)}
          </TableCell>
          <TableCell align='right'>
            {formatNumber(row.orders_backend, 0)}
          </TableCell>
          <TableCell align='right'>
            {formatNumber(row.rebills, 0)}
          </TableCell>
          <TableCell align='right'>
            {formatNumber(row.refunds, 0)}
          </TableCell>
        </Fragment>
      )}
    </Fragment>
  )
}

const TableCellsDetailedLTV = (props) => {
  const { row, attribution } = props
  return (
    <Fragment>
      <TableCell align='right'>
        {formatNumber(attribution === 'customer' ? row.customers_new : row.leads, 0)}
      </TableCell>
      <TableCell align='right'>
        {`$${formatNumber(row.ltv_0, 2, false)}`}
      </TableCell>
      <TableCell align='right'>
        {`$${formatNumber(row.ltv_7, 2, false)}`}
      </TableCell>
      <TableCell align='right'>
        {`$${formatNumber(row.ltv_14, 2, false)}`}
      </TableCell>
      <TableCell align='right'>
        {`$${formatNumber(row.ltv_21, 2, false)}`}
      </TableCell>
      <TableCell align='right'>
        {`$${formatNumber(row.ltv_30, 2, false)}`}
      </TableCell>
      <TableCell align='right'>
        {`$${formatNumber(row.ltv_45, 2, false)}`}
      </TableCell>
      <TableCell align='right'>
        {`$${formatNumber(row.ltv_60, 2, false)}`}
      </TableCell>
      <TableCell align='right'>
        {`$${formatNumber(row.ltv_90, 2, false)}`}
      </TableCell>
      <TableCell align='right'>
        {`$${formatNumber(row.ltv_180, 2, false)}`}
      </TableCell>
      <TableCell align='right'>
        {`$${formatNumber(row.ltv_365, 2, false)}`}
      </TableCell>
      <TableCell align='right'>
        {`$${formatNumber(row.ltv_max, 2, false)}`}
      </TableCell>
    </Fragment>
  )
}

const TableCellsDetailedLTR = (props) => {
  const { row, attribution } = props
  return (
    <Fragment>
      <TableCell align='right'>
        {formatNumber(attribution === 'customer' ? row.customers_new : row.leads, 0)}
      </TableCell>
      <TableCell align='right'>
        {`$${formatNumber(row.ltr_0, 2, false)}`}
      </TableCell>
      <TableCell align='right'>
        {`$${formatNumber(row.ltr_7, 2, false)}`}
      </TableCell>
      <TableCell align='right'>
        {`$${formatNumber(row.ltr_14, 2, false)}`}
      </TableCell>
      <TableCell align='right'>
        {`$${formatNumber(row.ltr_21, 2, false)}`}
      </TableCell>
      <TableCell align='right'>
        {`$${formatNumber(row.ltr_30, 2, false)}`}
      </TableCell>
      <TableCell align='right'>
        {`$${formatNumber(row.ltr_45, 2, false)}`}
      </TableCell>
      <TableCell align='right'>
        {`$${formatNumber(row.ltr_60, 2, false)}`}
      </TableCell>
      <TableCell align='right'>
        {`$${formatNumber(row.ltr_90, 2, false)}`}
      </TableCell>
      <TableCell align='right'>
        {`$${formatNumber(row.ltr_180, 2, false)}`}
      </TableCell>
      <TableCell align='right'>
        {`$${formatNumber(row.ltr_365, 2, false)}`}
      </TableCell>
      <TableCell align='right'>
        {`$${formatNumber(row.ltr_max, 2, false)}`}
      </TableCell>
    </Fragment>
  )
}

AnalysisTable.propTypes = {
  attribution: PropTypes.string.isRequired,
  context: PropTypes.object.isRequired,
  extendBackend: PropTypes.bool,
  includeCosts: PropTypes.bool,
  includeCommission: PropTypes.bool,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rows: PropTypes.array.isRequired,
  colors: PropTypes.object.isRequired,
  setOrder: PropTypes.func.isRequired,
  setOrderBy: PropTypes.func.isRequired,
  setRows: PropTypes.func.isRequired,
  onFilterAdd: PropTypes.func.isRequired,
}

export default AnalysisTable