import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import moment from 'moment-timezone'
import { useQueries } from 'react-query'

import { FirebaseContext } from '../../utils/firebase'
import { API_ROOT_URL } from '../../constants'

import Chart from './Chart'
import Controls from './ControlsTargets'
import DataTableWrapper from './DataTable'
import METRIC_ITEMS_MAP from './MetricItems'
import TargetSummary from './TargetSummary'

import {
  getChartData,
  getTargetData,
  getTargetReferenceLines,
} from '../../utils/helpers'

const TIMEZONE = moment.tz.guess() // This should be set by user configuration
const QUERY_CONFIG = {
  cacheTime: 10 * 60 * 1000,  // 10 minutes
  staleTime: 5 * 60 * 1000,  // 5 minutes
  refetchOnWindowFocus: false,
}

const Target = ({
  target,
  savedSegments,
  savedGroups,
  liveMode,
  startDate,
  endDate,
  resolution,
  config,
  targetValue,
  showCumulative,
  segments,
  onTargetSave,
  onTargetDelete,
  onLiveModeChange,
  onDatesChange,
  onResolutionChange,
  onConfigChange,
  onTargetValueChange,
  onShowCumulativeChange,
  onSegmentAdd,
  onSegmentDelete,
  onSegmentDuplicate,
  onSegmentEditChange,
  onSegmentSaveEdit,
  onSegmentRevertEdit,
  onSegmentColorChange,
  onSegmentIsActiveChange,
  onSegmentFilterAdd,
  onSegmentReplaceFiltersWithSavedSegmentFilter,
  onSegmentFilterDelete,
  onSegmentsReorder,
  onFilterAdd,
  onFilterUpdate,
  onFilterDelete,
  onClearAllSegmentFilters,
  onSegmentBreakdownSelect,
  onSegmentBreakdownColorChange,
  onSegmentBreakdownIsActiveChange,
}) => {
  const firebase = useContext(FirebaseContext)

  const [chartHoverIndex, setChartHoverIndex] = React.useState(null)

  const segmentsData = useQueries(segments.map(segment => ({
    queryKey: [
      'segment',
      segment.id,
      segment.segmentFilters,
      segment.filters,
      target.key,
      target.xKey,
      startDate,
      endDate,
      TIMEZONE,
      resolution,
      config,
    ],
    queryFn: () => fetchSegmentData(segment),
    refetchInterval: liveMode ? 1 * 60 * 1000 : false,  // 1 minute if liveMode is enabled, otherwise no automatic refetch
    ...QUERY_CONFIG
  })))

  const segmentsBreakdownData = useQueries(segments.map(segment => ({
    queryKey: [
      'breakdown',
      segment.id,
      segment.segmentFilters,
      segment.filters,
      segment.breakdown,
      target.key,
      target.xKey,
      startDate,
      endDate,
      TIMEZONE,
      resolution,
      config,
    ],
    queryFn: () => fetchSegmentData(segment, true),
    refetchInterval: liveMode ? 1 * 60 * 1000 : false,  // 1 minute if liveMode is enabled, otherwise no automatic refetch
    ...QUERY_CONFIG
  })))

  // This stores the set of breakdown keys present in the API breakdown data for each segment
  // for use in the UI to determine which breakdown keys are eligible for display
  const segmentsEligibleBreakdownKeys = useMemo(() => {
    return segmentsBreakdownData.map(query => query.data ? query.data.breakdownKeys : [])
  }, [segmentsBreakdownData])

  const targetData = useMemo(() => {
    const segmentIsLoading = segmentsData.some(segmentData => segmentData.isLoading)
    if (segmentIsLoading) return null

    // For targets, we are assuming the metric is being accumulated
    const cumulative = true
    const chartData = getChartData(segments, segmentsData, segmentsBreakdownData, target.xKey, target.metricKey, endDate, TIMEZONE, config, true, target.emptyDataRule, cumulative)
    return getTargetData(segments, chartData, targetValue, target.xKey, startDate, endDate, TIMEZONE)
  }, [target, targetValue, segments, segmentsData, segmentsBreakdownData, startDate, endDate, config])

  const referenceLines = useMemo(() => {
    if (!targetValue) return null
    return getTargetReferenceLines(targetData, targetValue, target.xKey, startDate, endDate, TIMEZONE, resolution, segments[0].color)
  }, [target, targetValue, targetData, segments, startDate, endDate])

  const aliasesData = useQueries(segments.map(segment => ({
    queryKey: [
      'aliases',
      segment.breakdown?.field
    ],
    queryFn: () => fetchAliases(segment.breakdown.field),
    enabled: Boolean(segment.breakdown),
    cacheTime: 10 * 60 * 1000,  // 10 minutes
    staleTime: 10 * 60 * 1000,  // 10 minutes
    refetchOnWindowFocus: false,
    refetchInterval: 10 * 60 * 1000,  // 10 minutes
  })))

  const aliases = useMemo(() => {
    if (aliasesData.length === 0) return {}
    if (aliasesData.every(query => !query.data)) return {}
    let aliases = {}
    aliasesData.forEach(query => {
      if (query.data) {
        aliases[query.data.field] = query.data.aliases
      }
    })
    return aliases
  }, [aliasesData])

  const fetchSegmentData = (segment, includeBreakdown=false) => {
    if (includeBreakdown && !segment.breakdown) return null
    return firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api2/metrics`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          metricKeys: [target.metricKey],
          xKey: target.xKey,
          segmentId: segment.id,
          segmentFilters: segment.segmentFilters,
          filters: segment.filters,
          breakdown: includeBreakdown ? segment.breakdown : null,
          startDate,
          endDate,
          timezone: TIMEZONE,
          resolution,
          config,
        })
      }).then(res => res.json())
    })
  }

  const fetchAliases = async (field) => {
    if (!field) return null
    return firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api2/fields/${field}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then(res => res.json())
    })
  }

  const handleChartHoverIndexChange = (index) => {
    setChartHoverIndex(index)
  }

  return (
    <Box
      flexGrow={1}
      minWidth='calc(100% - 66px - 266px)'
      sx={{
        backgroundColor: '#f1f5f9',
        padding: theme => theme.spacing(2, 4),
        height: '100vh',
        overflowY: 'auto',
      }}
    >
      <Controls
        parentKey={target.kind === 'saved' ? target.id : target.key}
        target={target}
        savedSegments={savedSegments}
        savedGroups={savedGroups}
        liveMode={liveMode}
        startDate={startDate}
        endDate={endDate}
        resolution={resolution}
        config={config}
        targetValue={targetValue}
        projectedValue={targetData ? targetData.projectedEndValue : null}
        segments={segments}
        segmentsEligibleBreakdownKeys={segmentsEligibleBreakdownKeys}
        aliases={aliases}
        onTargetSave={onTargetSave}
        onTargetDelete={onTargetDelete}
        onLiveModeChange={onLiveModeChange}
        onDatesChange={onDatesChange}
        onConfigChange={onConfigChange}
        onTargetValueChange={onTargetValueChange}
        onSegmentAdd={onSegmentAdd}
        onSegmentDelete={onSegmentDelete}
        onSegmentDuplicate={onSegmentDuplicate}
        onSegmentEditChange={onSegmentEditChange}
        onSegmentSaveEdit={onSegmentSaveEdit}
        onSegmentRevertEdit={onSegmentRevertEdit}
        onSegmentColorChange={onSegmentColorChange}
        onSegmentFilterAdd={onSegmentFilterAdd}
        onSegmentReplaceFiltersWithSavedSegmentFilter={onSegmentReplaceFiltersWithSavedSegmentFilter}
        onSegmentFilterDelete={onSegmentFilterDelete}
        onSegmentsReorder={onSegmentsReorder}
        onSegmentBreakdownSelect={onSegmentBreakdownSelect}
        onSegmentBreakdownColorChange={onSegmentBreakdownColorChange}
        onFilterAdd={onFilterAdd}
        onFilterUpdate={onFilterUpdate}
        onFilterDelete={onFilterDelete}
        onClearAllSegmentFilters={onClearAllSegmentFilters}
      />

      <TargetSummary
        targetValue={targetValue}
        targetData={targetData}
        metricItem={METRIC_ITEMS_MAP[target.metricKey]}
        startDate={startDate}
        segments={segments}
        segmentsData={segmentsData}
      />

      <Chart
        parentKey={target.kind === 'saved' ? target.id : target.key}
        xKey={target.xKey}
        emptyDataRule={target.emptyDataRule}
        allowSecondaryChart={target.chart.allowSecondaryChart}
        allowLineDots={target.chart.allowLineDots}
        allowUseStack100={target.chart.allowUseStack100}
        cumulativeDefault={target.chart.cumulativeDefault}
        hideYValuesAfterToday={target.chart.hideYValuesAfterToday}
        metricItem={METRIC_ITEMS_MAP[target.metricKey]}
        startDate={startDate}
        endDate={endDate}
        resolution={resolution}
        config={config}
        showCumulative={showCumulative}
        showSecondaryChart={false}
        segments={segments}
        segmentsData={segmentsData}
        segmentsBreakdownData={segmentsBreakdownData}
        segmentsEligibleBreakdownKeys={segmentsEligibleBreakdownKeys}
        aliases={aliases}
        chartHoverIndex={chartHoverIndex}
        referenceLines={referenceLines}
        onResolutionChange={onResolutionChange}
        onShowCumulativeChange={onShowCumulativeChange}
        onChartHoverIndexChange={handleChartHoverIndexChange}
      />

      <DataTableWrapper
        parentKey={target.kind === 'saved' ? target.id : target.key}
        xKey={target.xKey}
        defaultTab={target.table.defaultTab}
        showSummaryTab={target.table.summary.show}
        showChartTab={target.table.chart.show}
        showMilestonesTab={target.table.milestones.show}
        summaryColumns={target.table.summary.columns}
        emptyDataRule={target.emptyDataRule}
        metricItem={METRIC_ITEMS_MAP[target.metricKey]}
        startDate={startDate}
        endDate={endDate}
        resolution={resolution}
        config={config}
        showCumulative={showCumulative}
        segments={segments}
        segmentsData={segmentsData}
        segmentsBreakdownData={segmentsBreakdownData}
        aliases={aliases}
        chartHoverIndex={chartHoverIndex}
        onSegmentIsActiveChange={onSegmentIsActiveChange}
        onSegmentBreakdownIsActiveChange={onSegmentBreakdownIsActiveChange}
        onChartHoverIndexChange={handleChartHoverIndexChange}
      />
    </Box>
  )
}

Target.propTypes = {
  target: PropTypes.object.isRequired,
  savedSegments: PropTypes.array.isRequired,
  savedGroups: PropTypes.array.isRequired,
  liveMode: PropTypes.bool.isRequired,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  resolution: PropTypes.number.isRequired,
  config: PropTypes.object.isRequired,
  targetValue: PropTypes.number,
  segments: PropTypes.array.isRequired,
  onTargetSave: PropTypes.func.isRequired,
  onTargetDelete: PropTypes.func.isRequired,
  onLiveModeChange: PropTypes.func.isRequired,
  onDatesChange: PropTypes.func.isRequired,
  onResolutionChange: PropTypes.func.isRequired,
  onConfigChange: PropTypes.func.isRequired,
  onTargetValueChange: PropTypes.func.isRequired,
  onShowCumulativeChange: PropTypes.func.isRequired,
  onSegmentAdd: PropTypes.func.isRequired,
  onSegmentDelete: PropTypes.func.isRequired,
  onSegmentDuplicate: PropTypes.func.isRequired,
  onSegmentEditChange: PropTypes.func.isRequired,
  onSegmentSaveEdit: PropTypes.func.isRequired,
  onSegmentRevertEdit: PropTypes.func.isRequired,
  onSegmentColorChange: PropTypes.func.isRequired,
  onSegmentIsActiveChange: PropTypes.func.isRequired,
  onSegmentFilterAdd: PropTypes.func.isRequired,
  onSegmentReplaceFiltersWithSavedSegmentFilter: PropTypes.func.isRequired,
  onSegmentFilterDelete: PropTypes.func.isRequired,
  onSegmentsReorder: PropTypes.func.isRequired,
  onFilterAdd: PropTypes.func.isRequired,
  onFilterUpdate: PropTypes.func.isRequired,
  onFilterDelete: PropTypes.func.isRequired,
  onClearAllSegmentFilters: PropTypes.func.isRequired,
  onSegmentBreakdownSelect: PropTypes.func.isRequired,
  onSegmentBreakdownColorChange: PropTypes.func.isRequired,
  onSegmentBreakdownIsActiveChange: PropTypes.func.isRequired,
}

export default Target