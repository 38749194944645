import React from 'react'
import PropTypes from 'prop-types'
import {
  Redirect,
  Route,
  useHistory,
  useLocation
} from 'react-router-dom'

import { FirebaseContext } from '../../utils/firebase'
import 'firebase/auth'

import FullPageLoadingSpinner from '../Common/FullPageLoadingSpinner'
import Nav from './Nav'

import * as ROUTES from '../../constants/routes'
import { PUBLIC_DEMO_UID } from '../../constants'

const PrivateNavRoute = ({ exact, path, component: Component }) => {
  const history = useHistory()
  const location = useLocation()
  const firebase = React.useContext(FirebaseContext)
  const [currentUser, setCurrentUser] = React.useState(undefined)
  const redirectTo = encodeURIComponent(`${location.pathname}${location.search}`)

  const handleNavClick = (route) => {
    history.push(route)
  }

  React.useEffect(() => {
    return firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setCurrentUser(user)
      } else {
        setCurrentUser(null)
      }
    })
  }, [firebase, setCurrentUser])

  // User auth state has not yet been determined
  if (currentUser === undefined) return <FullPageLoadingSpinner />

  // Public Demo check to hide navigation to sensitive areas
  const uid = firebase.auth().currentUser ? firebase.auth().currentUser.uid : null
  const isPublicDemo = uid === PUBLIC_DEMO_UID
  if (isPublicDemo && (location.pathname === ROUTES.UPLOAD || location.pathname === ROUTES.SETTINGS)) {
    return (
      <Redirect to={ROUTES.DASHBOARD} />
    )
  }

  return (
    <Route exact={exact} path={path} render={(props) => {
      return currentUser ? (
        <React.Fragment>
          <Nav currentUser={currentUser} selectedRoute={location.pathname} handleNavClick={handleNavClick} />
          <Component {...props} />
        </React.Fragment>
      ) : <Redirect to={ROUTES.LOGIN + `?redirectTo=${redirectTo}`} />
    }}/>
  )
}

PrivateNavRoute.propTypes = {
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([ PropTypes.element, PropTypes.func ]).isRequired,
}

export default PrivateNavRoute