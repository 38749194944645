import React from 'react'
import PropTypes from 'prop-types'
import Switch from '@mui/material/Switch'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  switchBase: {
    color: props => props.checked ? props.colorHex : theme.palette.grey[200],
  },
  checked: {
    color: props => props.checked ? [props.colorHex, '!important'] : theme.palette.grey[200]
  },
  track: {
    backgroundColor: props => props.checked ? [props.colorHex, '!important'] : theme.palette.grey[500]
  },
  sizeSmall: {
    paddingRight: theme.spacing(1)
  },
}))

const CustomColorSwitch = (props) => {
  const classes = useStyles(props)
  return (
    <Switch
      classes={{
        switchBase: classes.switchBase,
        checked: classes.checked,
        track: classes.track,
        sizeSmall: classes.sizeSmall
      }}
      size='small'
      name='lineSwitch'
      color='default'
      checked={props.checked}
      onChange={event => props.onChange(props.id, event.target.checked)}
    />
  )
}

CustomColorSwitch.propTypes = {
  id: PropTypes.oneOfType([ PropTypes.string, PropTypes.number]),
  colorHex: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default CustomColorSwitch
