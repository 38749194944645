import React from 'react'
import Container from '@mui/material/Container'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { FirebaseContext } from '../../utils/firebase'
import 'firebase/auth'

import * as ROUTES from '../../constants/routes'

import FullPageLoadingSpinner from '../Common/FullPageLoadingSpinner'

const EMAIL = 'demo@ltvnumbers.com'
const PASSWORD = 'ltvdemo1!'

const Demo = () => {
  const firebase = React.useContext(FirebaseContext)
  const history = useHistory()

  const params = queryString.parse(location.search)
  const redirectTo = params.redirectTo ? decodeURIComponent(params.redirectTo) : null

  React.useEffect(() => {
    firebase.auth().signInWithEmailAndPassword(EMAIL, PASSWORD).then(() => {
      if (redirectTo) {
        history.push(redirectTo)
      } else {
        history.push(ROUTES.DAILY)
      }
    })
  }, [])

  return (
    <Container>
      <FullPageLoadingSpinner />
    </Container>
  )
}

export default Demo