import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import CloseIcon from '@mui/icons-material/Close'
import PersonIcon from '@mui/icons-material/Person'
import SegmentIcon from '@mui/icons-material/Segment'
import WorkspacesIcon from '@mui/icons-material/Workspaces'

import AddFilterPopover from './AddFilterPopover'
import DarkTooltip from './DarkTooltip'

import { getGroupDisplayName, getSegmentDisplayName, shortenString } from '../../utils/helpers'

const ContextChipFilter = ({
  savedSegments,
  savedGroups,
  config,
  segmentId,
  segment,
  type,
  kind,
  segmentFilter,
  filter,
  filterIndex,
  onFilterUpdate,
  onSegmentFilterAdd,
  onSegmentFilterDelete,
  onFilterDelete,
}) => {
  const [editAnchorEl, setEditAnchorEl] = React.useState(null)
  const boxRef = React.useRef(null)

  const handleBoxClick = (e) => {
    if (boxRef.current.contains(e.target)) {
      setEditAnchorEl(e.currentTarget)
    }
  }

  // When lead attribution is use, sale data filters are ignored, so we need to visually indicate that
  const isSaleDataFilterDisabled = useMemo(() => {
    if (type === 'filter') {
      return config.attribution === 'lead' && filter.type.id === 'payment' && (kind === 'frontend' || kind === 'all')
    } else if (type === 'segmentFilter') {
      const savedSegment = savedSegments.find(savedSegment => savedSegment.id === segmentFilter.id)
      if (!savedSegment) return null
      return config.attribution === 'lead' && savedSegment.filters.some(filter => filter.type.id === 'payment') && (kind === 'frontend' || kind === 'all')
    }
  }, [config.attribution, filter, type])

  return (
    <Box
      ref={boxRef}
      display='flex'
      alignItems='center'
      onClick={type === 'filter' ? handleBoxClick : null}
      sx={{
        backgroundColor: theme => {
          if (isSaleDataFilterDisabled) {
            return theme.palette.action.disabledBackground
          } else {
            return '#fff'
          }
        },
        alignItems: 'center',
        border: '1px solid #dbdbdb',
        borderRadius: theme => theme.shape.borderRadius / 4,
        padding: '6px 0px 6px 8px',
        '&:hover': {
          textDecoration: type === 'filter' ? 'underline' : 'none',
        },
        '& *': {
          cursor: type === 'filter' ? 'pointer' : 'default',
        },
      }}
    >
      {/* Render segmentFilter context chip */}
      {type === 'segmentFilter' && (
        (() => {
          const savedSegment = savedSegments.find(savedSegment => savedSegment.id === segmentFilter.id)
          if (!savedSegment) return null
          return (
            <Box display='flex' flexDirection='row' alignItems='center' columnGap={0.5}>
              <DarkTooltip title='Segment Filter' arrow>
                <SegmentIcon style={{ fontSize: 15 }}/>
              </DarkTooltip>
              <DarkTooltip title={getSegmentDisplayName(savedSegment, savedSegments)} arrow>
                <Typography
                  variant='body1'
                  sx={{
                    fontSize: 13,
                    lineHeight: '13px',
                    color: theme => theme.palette.text.primary,
                    fontWeight: 600,
                    flexGrow: 1,
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    cursor: 'default',
                  }}
                >
                  {savedSegment.name}
                </Typography>
              </DarkTooltip>
            </Box>
          )
        })()
      )}

      {/* Render filter context chip */}
      {type === 'filter' && (
        <Box display='flex' flexDirection='row' alignItems='center' columnGap={0.5}>
          {filter.type.id === 'contact' && (
            <DarkTooltip title={filter.type.name + ' Filter'}>
              <PersonIcon style={{ fontSize: 15 }}/>
            </DarkTooltip>
          )}
          {filter.type.id === 'payment' && (
            <DarkTooltip title={filter.type.name + ' Filter'}>
              <AttachMoneyIcon style={{ fontSize: 15 }}/>
            </DarkTooltip>
          )}
          <Typography variant='body1' sx={{ fontSize: 13, lineHeight: '13px', color: theme => theme.palette.text.secondary, flexShrink: 0 }}>
            {filter.fieldName}
          </Typography>
          <Typography variant='body1' sx={{ fontSize: 13, lineHeight: '13px', color: theme => theme.palette.text.secondary, flexShrink: 0 }}>
            {filter.operator.name}
          </Typography>
          {filter.groupIds.length > 0 && (
            <Box display='flex' flexDirection='row' alignItems='center'>
              {filter.groupNames.map((groupName, index) => (
                <React.Fragment key={index}>
                  <DarkTooltip title='Group Filter'>
                    <WorkspacesIcon style={{ fontSize: 13 }}/>
                  </DarkTooltip>
                  <DarkTooltip title={getGroupDisplayName(filter.groupIds[index], savedGroups)}>
                    <Typography variant='body1' sx={{ fontSize: 13, lineHeight: '13px', color: theme => theme.palette.text.primary, fontWeight: 600, flexGrow: 1, whiteSpace: 'normal', wordWrap: 'break-word', cursor: 'default' }}>
                      &nbsp;{groupName}
                    </Typography>
                  </DarkTooltip>
                  {index !== filter.groupNames.length - 1 && (
                    <Typography variant='body1' sx={{ fontSize: 13, lineHeight: '13px', color: theme => theme.palette.text.primary, fontWeight: 600, flexGrow: 1, whiteSpace: 'normal', wordWrap: 'break-word' }}>
                      &nbsp;|&nbsp;
                    </Typography>
                  )}
                </React.Fragment>
              ))}
            </Box>
          )}
          {filter.itemValues.length > 0 && (
            <Typography variant='body1' sx={{ fontSize: 13, lineHeight: '13px', color: theme => theme.palette.text.primary, fontWeight: 600, flexGrow: 1, whiteSpace: 'normal', wordWrap: 'break-word' }}>
              {filter.groupIds.length > 0 ? ' | ' : ''}{shortenString(filter.itemNames.join(' | '), 64)}
            </Typography>
          )}
        </Box>
      )}

      {isSaleDataFilterDisabled && (
        <DarkTooltip
          title={type === 'filter' ?
            'Click to change filter type to Contact Source if you want to enable this filter' :
            'This segment is not compatible with lead attribution because it contains a Sale Data filter. Please remove to prevent unexpected results.'
          }
          placement='bottom'
          arrow
        >
          <Box display='block'>
            <Typography variant='body1' sx={{ fontSize: 13, lineHeight: '13px', color: theme => theme.palette.text.primary, whiteSpace: 'normal', wordWrap: 'break-word' }}>
              &nbsp; {type === 'filter' ? '(Sale data filter ignored for lead attribution)' : '(Segment is not compatible with lead attribution. Please remove.)'}
            </Typography>
          </Box>
        </DarkTooltip>
      )}

      <IconButton
        aria-label='delete'
        onClick={() => {
          if (type === 'segmentFilter') onSegmentFilterDelete(segmentId, segmentFilter.id)
          else if (type === 'filter') onFilterDelete(segmentId, filterIndex)
        }}
        sx={{
          padding: theme => theme.spacing(0),
          margin: theme => theme.spacing(0, 1)
        }}
      >
        <CloseIcon sx={{ fontSize: 16 }}/>
      </IconButton>

      {editAnchorEl && (
        <AddFilterPopover
          savedGroups={savedGroups}
          savedSegments={savedSegments}
          segment={segment}
          onSegmentFilterAdd={onSegmentFilterAdd}
          onFilterUpdate={onFilterUpdate}
          isEditMode={true}
          editAnchorEl={editAnchorEl}
          setEditAnchorEl={setEditAnchorEl}
          editFilterInitial={filter}
          editFilterIndex={filterIndex}
          kind={kind}
        />
      )}
    </Box>
  );
}

ContextChipFilter.defaultProps = {
  kind: 'all',
}

ContextChipFilter.propTypes = {
  savedSegments: PropTypes.array,
  savedGroups: PropTypes.array,
  config: PropTypes.object.isRequired,
  type: PropTypes.oneOf(['filter', 'segmentFilter']).isRequired,
  kind: PropTypes.oneOf(['all', 'frontend', 'backend']).isRequired,
  segmentId: PropTypes.string.isRequired,
  segment: PropTypes.object,
  segmentFilter: PropTypes.object,
  filter: PropTypes.object,
  onSegmentFilterAdd: PropTypes.func,
  onSegmentFilterDelete: PropTypes.func,
  onFilterAdd: PropTypes.func,
  onFilterUpdate: PropTypes.func,
  onFilterDelete: PropTypes.func,
}

export default ContextChipFilter
