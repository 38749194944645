import React from 'react'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import Grid from '@mui/material/Grid'
import InputBase from '@mui/material/InputBase'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Snackbar from '@mui/material/Snackbar'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'

import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import { FirebaseContext } from '../../utils/firebase'
import 'firebase/auth'

import { API_ROOT_URL } from '../../constants'
import * as ROUTES from '../../constants/routes'

import clickfunnelsLogoImg from '../../media/clickfunnels_logo.png'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: 66,
    right: 0,
    width: 'calc(100% - 66px)',
    height: '100vh',
    overflowY: 'auto',
    backgroundColor: theme.palette.grey[50],
  },
  logoImg: {
    width: 100,
  },
  syncButton: {
    width: 110,
  },
  redButton: {
    color: theme.palette.error.main,
    '&:hover': {
      background: 'rgba(255,23,68,0.04)',
    },
  },
}))

const FUNNEL_ADDED_SUCCESS_MESSAGE = 'Funnel added successfully.'
const REVOKE_SUCCESS_MESSAGE = 'Account removed. Data will no longer be synced from this account. Redirecting back to settings...'
const WEBHOOK_URL_BASE = `https://us-central1-ltv-numbers-${process.env.REACT_APP_ENV === 'production' ? 'prod' : 'dev'}.cloudfunctions.net/webhooks/clickfunnels/`

const ClickfunnelsSource = () => {
  const classes = useStyles()
  const { id } = useParams()
  const history = useHistory()
  const firebase = React.useContext(FirebaseContext)

  const [webhookUrl, setWebhookUrl] = React.useState('')
  const [showCopyTooltip, setShowCopyTooltip] = React.useState(false)

  const [showFunnelDialog, setShowFunnelDialog] = React.useState(false)
  const [funnelNickname, setFunnelNickname] = React.useState('')
  const [funnelUrl, setFunnelUrl] = React.useState('')
  const [funnelPlatformId, setFunnelPlatformId] = React.useState('')
  const [isAddingFunnel, setIsAddingFunnel] = React.useState(false)

  const [message, setMessage] = React.useState('')
  const [showMessage, setShowMessage] = React.useState(false)
  const [messageSeverity, setMessageSeverity] = React.useState('success')
  const [showRevokeConfirmation, setShowRevokeConfirmation] = React.useState(false)


  React.useEffect(() => {
    const uid = firebase.auth().currentUser.uid
    setWebhookUrl(WEBHOOK_URL_BASE + uid + '/integration/' + id)
  }, [])

  React.useEffect(() => {
    const re = /funnels\/([0-9]*)\/share/
    const match = funnelUrl.match(re)
    if (match) {
      setFunnelPlatformId(match[1])
    }
  }, [funnelUrl])

  const {
    isLoading: isLoadingIntegration,
    data: integration = {}
  } = useQuery(['integration-get', id], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      }).then(res => res.json())
    }),
    {
      cacheTime: 15 * 60 * 1000,  // 15 minutes
      staleTime: 15 * 60 * 1000,  // 15 minutes
      refetchOnWindowFocus: false,
    }
  )


  const { isLoading: isLoadingRevoke, refetch: revoke } = useQuery(['revoke-clickfunnels'], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/clickfunnels/revoke`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          integration_id: id
        })
      }).then(res => res.json())
    }),
    {
      enabled: false,
      staleTime: Infinity,
      refetchOnReconnect: false,
      onSuccess: () => {
        setShowRevokeConfirmation(false)
        setMessage(REVOKE_SUCCESS_MESSAGE)
        setShowMessage(true)
        setTimeout(() => history.push(ROUTES.SETTINGS), 3000)
      },
    }
  )

  const addFunnel = () => {
    setIsAddingFunnel(true)
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/funnels`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          platform_id: funnelPlatformId,
          platform: 'clickfunnels',
          source: 'clickfunnels_webhook',
          integration_id: id,
          nickname: funnelNickname,
          source_of_truth: true,
        })
      }).then(res => {
        setIsAddingFunnel(false)
        setFunnelNickname('')
        setFunnelUrl('')
        setFunnelPlatformId('')
        setShowMessage(true)
        setMessage(FUNNEL_ADDED_SUCCESS_MESSAGE)
        setMessageSeverity('success')
        setShowFunnelDialog(false)
        return res.json()
      })
    }),
    {
      enabled: false,
      staleTime: 0,
      refetchOnWindowFocus: false,
    }
  }

  const handleCloseMessage = (_, reason) => {
    if (reason === 'clickaway') return
    setShowMessage(false)
  }

  const handleCloseFunnelDialog = () => {
    setShowFunnelDialog(false)
    setFunnelNickname('')
    setFunnelUrl('')
    setFunnelPlatformId('')
  }

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
    setShowCopyTooltip(true)
  }

  return (
    <div className={classes.root}>
      <Box padding={2}>
        <Button
          variant='outlined'
          onClick={() => history.push(ROUTES.SETTINGS)}
          size='small'
        >
          Back
        </Button>
        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs={2}>
                  <img
                    className={classes.logoImg}
                    alt='clickfunnels_logo'
                    src={clickfunnelsLogoImg}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant='h5' paragraph>
                    Clickfunnels Source
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Name
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Box height='100%' display='flex' alignItems='center'>
                    <Typography variant='body2'>
                      {isLoadingIntegration ? <Skeleton variant='text' width={100} /> : `${integration.nickname}`}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>

        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Account URL
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Box height='100%' display='flex' alignItems='center'>
                    <Typography variant='body2'>
                      {isLoadingIntegration ? <Skeleton variant='text' width={100} /> : `${integration.platform_id}`}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>

        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Add Funnels
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Box>
                    <Typography variant='body2' paragraph>
                      Clickfunnels requires that each funnel be added invidually to load data in real time. Use the button below to add a funnel and enter the required information. This is necessary to ensure that the funnel shows up in LTV Numbers with the proper name and ID.
                    </Typography>
                    <Typography variant='body2' paragraph>
                      For each funnel added, you need to add a webhook by following the instructions below for data to be sent from Clickfunnels to LTV Numbers.
                    </Typography>
                    <Button
                      onClick={() => setShowFunnelDialog(true)}
                      variant='contained'
                      color='primary'
                    >
                      Add Funnel
                    </Button>
                  </Box>
                  <Dialog
                    open={showFunnelDialog}
                    onClose={handleCloseFunnelDialog}
                  >
                    <DialogTitle>
                      Add Funnel
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Connect funnel to LTV Numbers by providing the funnel name and share URL.
                        To get this information, open the funnel in Clickfunnels and open the Settings.
                        <br />
                        <br />
                        The funnel name can be found in the settings under <b>NAME</b>.
                        The funnel URL can be found in the settings under <b>Share This Funnel URL</b>.
                      </DialogContentText>
                      <Box marginTop={2}>
                        <TextField
                          label='Funnel Name'
                          name='funnel-name'
                          value={funnelNickname}
                          onChange={(event) => setFunnelNickname(event.target.value)}
                          variant='outlined'
                          size='small'
                          fullWidth
                          required
                        />
                      </Box>
                      <Box marginTop={2}>
                        <TextField
                          label='Funnel URL'
                          name='funnel-url'
                          value={funnelUrl}
                          onChange={(event) => setFunnelUrl(event.target.value)}
                          variant='outlined'
                          size='small'
                          fullWidth
                          required
                        />
                      </Box>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={handleCloseFunnelDialog}
                        color='error'
                      >
                        Cancel
                      </Button>
                      <Button onClick={() => addFunnel()} color='secondary'>
                        {isAddingFunnel ? <CircularProgress color='inherit' size={24} /> : 'Add'}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>

        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Webhooks
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Box>
                    <Box display='block'>
                      <Typography variant='body2' paragraph>
                        A webhook must be added for each funnel that is connected to LTV Numbers. To create a webhook, go to a funnel and choose the &quot;Settings&quot; tab.
                      </Typography>
                      <Typography variant='body2' paragraph>
                        Scroll down to the &quot;Webhooks&quot; section and select &quot;Manage Your Funnel Webhooks&quot;. Press &quot;New Webhook&quot;.
                      </Typography>
                      <Typography variant='body2' paragraph>
                        Copy the URL below and paste it into the &quot;URL&quot; field in Clickfunnels. Leave &quot;Event&quot; set to <em>All Events</em>. Leave &quot;Version&quot; set to <em>1</em>.
                      </Typography>
                      <Typography variant='body2' paragraph>
                        Set &quot;Adapter&quot; to <em>json</em>. Press &quot;Create Funnel Webhook&quot; and the webhook will be created.
                      </Typography>
                    </Box>
                    {isLoadingIntegration ? <Skeleton variant='text' width={100} /> : (
                      <Box display='flex' flexDirection='row' width='100%'>
                        <InputBase
                          variant='filled'
                          value={webhookUrl}
                          size='small'
                          fullWidth
                          disabled
                        />
                        <Tooltip
                          open={showCopyTooltip}
                          onClose={() => setShowCopyTooltip(false)}
                          leaveDelay={2000}
                          placement='right'
                          title='Copied'
                        >
                          <Button
                            variant='text'
                            color='primary'
                            onClick={() => copyToClipboard(webhookUrl)}
                          >
                            Copy
                          </Button>
                        </Tooltip>
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>

        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Delete
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant='body2' paragraph>
                    If you want to remove this data integration so transacations can no longer be saved, click the button below.
                  </Typography>
                  <Button
                    className={classes.redButton}
                    variant='text'
                    onClick={() => setShowRevokeConfirmation(true)}
                  >
                    Delete Source
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      </Box>
      <Dialog
        open={showRevokeConfirmation}
        onClose={() => setShowRevokeConfirmation(false)}
      >
        <DialogTitle>
          Remove access to account <b>&quot;{integration.nickname}&quot;</b>?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will prevent data from being synced to LTV Numbers.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.redButton}
            variant='text'
            onClick={() => setShowRevokeConfirmation(false)}
          >
            Cancel
          </Button>
          <Button
            color='primary'
            onClick={() => revoke()}
          >
            {isLoadingRevoke ? <CircularProgress color='inherit' size={24} /> : 'Remove'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showMessage}
        autoHideDuration={10000}
        onClose={handleCloseMessage}
      >
        <Alert
          severity={messageSeverity}
          variant='filled'
          onClose={handleCloseMessage}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  )
}

ClickfunnelsSource.propTypes = {}

export default ClickfunnelsSource