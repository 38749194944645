import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const NoDataBox = ({
  message,
  height,
  typographyVariant,
  typographyColor,
}) => {
  return (
    <Box
      width='100%'
      height={height}
      display='flex'
      flexDirection='row'
      alignItems='center'
      justifyContent='center'
    >
      <Typography variant={typographyVariant} color={typographyColor}>
        {message ? message : 'No data to display. Change settings to load more data.'}
      </Typography>
    </Box>
  )
}

NoDataBox.defaultProps = {
  height: 100,
  typographyVariant: 'h6',
  typographyColor: 'textSecondary'
}

NoDataBox.propTypes = {
  message: PropTypes.string,
  height: PropTypes.number
}

export default NoDataBox