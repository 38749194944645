import React, { useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  brown,
  grey,
  blueGrey,
} from '@mui/material/colors'

import { UserContext } from '../../contexts/UserContext'
import { SEGMENT_COLOR_PALETTE } from '../../constants'

import SquareIconColored from './SquareIconColored'

const MUI_COLOR_PALETTE = [
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  brown,
  grey,
  blueGrey
]
const MUI_SHADES = [
  '500',
  '600',
  '700',
  '800',
  '900',
  'A200',
  'A400',
  'A700',
]

const ColorPicker = ({
  type,
  color,
  width,
  height,
  showContrastText,
  onColorChange,
}) => {
  const userDoc = useContext(UserContext)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleColorChange = (color) => {
    onColorChange(color)
    handleClose()
  }

  const palette = useMemo(() => {
    if (type === 'segment') {
      let segmentColorPalette = [...SEGMENT_COLOR_PALETTE]
      if (userDoc.secondaryColor) {
        segmentColorPalette = [userDoc.secondaryColor, ...segmentColorPalette]
      }
      return segmentColorPalette
    } else {
      let muiColorPalette = []
      MUI_COLOR_PALETTE.forEach(c => {
        MUI_SHADES.forEach(s => {
          muiColorPalette.push(c[s])
        })
      })
      return muiColorPalette
    }
  }, [type, userDoc.secondaryColor])

  return (
    <React.Fragment>
      <Box
        onClick={handleClick}
        display='flex'
        alignItems='center'
        justifyContent='center'
        style={{ cursor: 'pointer', height, width }}
      >
        <SquareIconColored
          color={color}
          width={width}
          height={height}
        />
      </Box>
      <Popover
        id={open ? 'color-picker-popover' : undefined}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box padding={2} width={254}>
          <Typography variant='h6' gutterBottom>
            Select a color
          </Typography>
          <Box display='flex' flexDirection='row' flexWrap='wrap' columnGap={1} rowGap={1}>
            {palette.map((paletteColor, index) => (
              <Box
                key={index}
                onClick={() => handleColorChange(paletteColor)}
                style={{
                  cursor: 'pointer',
                  border: paletteColor === color ? `2px solid ${color}` : 'none',
                  padding: paletteColor === color ? '1px' : '0px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 20,
                  height: 20,
                }}
              >
                <SquareIconColored
                  color={paletteColor}
                  width={paletteColor === color ? 10 : 20}
                  height={paletteColor === color ? 10 : 20}
                  showContrastText={showContrastText}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Popover>
    </React.Fragment>
  )
}

ColorPicker.propTypes = {
  type: PropTypes.oneOf(['segment', 'mui']).isRequired,
  color: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  showContrastText: PropTypes.bool,
  onColorChange: PropTypes.func.isRequired,
}

export default ColorPicker