import React from 'react'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import Skeleton from '@mui/material/Skeleton'
import Snackbar from '@mui/material/Snackbar'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'

import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import { FirebaseContext } from '../../utils/firebase'
import 'firebase/auth'

import { API_ROOT_URL } from '../../constants/'
import * as ROUTES from '../../constants/routes'

import woocommerceLogoImg from '../../media/woocommerce_logo.png'

const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: 66,
    right: 0,
    width: 'calc(100% - 66px)',
    height: '100vh',
    overflowY: 'auto',
    backgroundColor: theme.palette.grey[50],
  },
  logoImg: {
    width: 100,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250
  },
  syncButton: {
    width: 110,
  },
  redButton: {
    color: theme.palette.error.main,
    '&:hover': {
      background: 'rgba(255,23,68,0.04)',
    },
  },
}))

const REVOKE_SUCCESS_MESSAGE = 'Account removed. Data will no longer be synced from this account. Redirecting back to settings...'
const SYNC_SUCCESS_MESSAGE = 'Sync initialized. Processing may take a while, and you can safely leave this page.'
const SYNC_ERROR_MESSAGE = 'Sync failed to initialize. Try again and contact support if error persists.'

const WoocommerceSource = () => {
  const classes = useStyles()
  const { id } = useParams()
  const history = useHistory()
  const firebase = React.useContext(FirebaseContext)
  const [dateRange, setDateRange] = React.useState('last_2_years')
  const [message, setMessage] = React.useState('')
  const [showMessage, setShowMessage] = React.useState(false)
  const [messageSeverity, setMessageSeverity] = React.useState('success')
  const [showRevokeConfirmation, setShowRevokeConfirmation] = React.useState(false)

  const [startDate, setStartDate] = React.useState('')
  const [endDate, setEndDate] = React.useState('')

  React.useEffect(() => {
    setDatesFromRange(dateRange)
  }, [dateRange])

  const {
    isLoading: isLoadingIntegration,
    data: integration = {}
  } = useQuery(['integration-get', id], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      }).then(res => res.json())
    }),
    {
      cacheTime: 15 * 60 * 1000,  // 15 minutes
      staleTime: 15 * 60 * 1000,  // 15 minutes
      refetchOnWindowFocus: false,
    }
  )

  const { isFetching: isSyncing, refetch: sync } = useQuery(['integration-sync', id], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/woocommerce/sync`, {
        method: 'POST',
        body: JSON.stringify({
          integration_id: id,
          startDate,
          endDate
        }),
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      }).then(res => res.json())
    }),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      staleTime: 0,
      onSuccess: () => {
        setShowMessage(true)
        setMessage(SYNC_SUCCESS_MESSAGE)
        setMessageSeverity('success')
      },
      onError: () => {
        setShowMessage(true)
        setMessage(SYNC_ERROR_MESSAGE)
        setMessageSeverity('error')
      }
    }
  )

  const revoke = () => {
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/woocommerce/revoke`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          integration_id: id
        })
      }).then(res => {
        setShowRevokeConfirmation(false)
        setMessage(REVOKE_SUCCESS_MESSAGE)
        setShowMessage(true)
        setTimeout(() => history.push(ROUTES.SETTINGS), 3000)
        return res.json()
      })
    })
  }

  const handleCloseMessage = (_, reason) => {
    if (reason === 'clickaway') return
    setShowMessage(false)
  }

  const setDatesFromRange = (dateRange) => {
    let startDate, endDate
    switch (dateRange) {
      case 'last_7_days':
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(7, 'days').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'last_30_days':
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(30, 'days').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'last_3_months':
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(3, 'months').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'last_6_months':
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(6, 'months').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'last_12_months':
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(12, 'months').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'last_2_years':
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(2, 'years').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'last_3_years':
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(3, 'years').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'ytd':
        startDate = moment.tz(TIMEZONE).startOf('year').startOf('day').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'last_and_this_year':
        startDate = moment.tz(TIMEZONE).subtract(1, 'year').startOf('year').startOf('day').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'all_time':
        startDate = moment.tz('2000', TIMEZONE).startOf('day').toISOString()  // for some reason, using 1970 return a 400 error, but 1971 is okay. Just using 2000 as a safety.
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      default:
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(12, 'months').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
    }
    setStartDate(startDate)
    setEndDate(endDate)
  }

  return (
    <div className={classes.root}>
      <Box padding={2}>
        <Button
          variant='outlined'
          onClick={() => history.push(ROUTES.SETTINGS)}
          size='small'
        >
          Back
        </Button>
        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs={2}>
                  <img
                    className={classes.logoImg}
                    alt='woocommerce_logo'
                    src={woocommerceLogoImg}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant='h5' gutterBottom>
                    WooCommerce Source
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Name
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Box height='100%' display='flex' alignItems='center'>
                    <Typography variant='body2'>
                      {isLoadingIntegration ? <Skeleton variant='text' width={100} /> : `${integration.nickname}`}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Sync
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant='body2' gutterBottom>
                    To sync payment data from WooCommerce with LTV Numbers, click the button below. This will start a process of retrieving all payments in the entire history of the account and loading them into LTV Numbers. Due to rate limitations inside WooCommerce, this process can take anywhere from several minutes to several hours.
                  </Typography>
                  <Typography variant='body2' gutterBottom>
                    Use the selector below to specify the date range for which you would like to import the data. It defaults to the last 2 years, but you can change it to whatever makes sense for analysis purposes.
                  </Typography>
                  <Box marginTop={2}>
                    <FormControl
                      className={classes.formControl}
                      variant='outlined'
                      margin='dense'
                      required
                     >
                      <InputLabel id='data-type-label'>Date Range</InputLabel>
                      <Select
                        labelId='date-range-label'
                        id='date-range-select'
                        value={dateRange}
                        onChange={event => setDateRange(event.target.value)}
                        label='Date Range'
                      >
                        <MenuItem value={'last_7_days'}>Last 7 days</MenuItem>
                        <MenuItem value={'last_30_days'}>Last 30 days</MenuItem>
                        <MenuItem value={'last_3_months'}>Last 3 months</MenuItem>
                        <MenuItem value={'last_6_months'}>Last 6 months</MenuItem>
                        <MenuItem value={'last_12_months'}>Last 12 months</MenuItem>
                        <MenuItem value={'last_2_years'}>Last 2 years</MenuItem>
                        <MenuItem value={'last_3_years'}>Last 3 years</MenuItem>
                        <MenuItem value={'ytd'}>Year to date</MenuItem>
                        <MenuItem value={'last_and_this_year'}>Last year and this year</MenuItem>
                        <MenuItem value={'all_time'}>All time</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>

                  <Button
                    className={classes.syncButton}
                    variant='contained'
                    color='secondary'
                    onClick={() => sync()}
                  >
                    {isSyncing ?
                      <CircularProgress color='inherit' size={24} className={classes.buttonProgress} />
                      : 'Sync Data'
                    }
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Delete
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant='body2' gutterBottom>
                    If you want to remove this data integration so data is no longer synced, click the button below.
                  </Typography>
                  <Button
                    className={classes.redButton}
                    variant='text'
                    onClick={() => setShowRevokeConfirmation(true)}
                  >
                    Delete Source
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      </Box>
      <Dialog
        open={showRevokeConfirmation}
        onClose={() => setShowRevokeConfirmation(false)}
      >
        <DialogTitle>
          Remove access to account <b>&quot;{integration.nickname}&quot;</b>?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will prevent data from being synced to LTV Numbers.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.redButton}
            variant='text'
            onClick={() => setShowRevokeConfirmation(false)}
          >
            Cancel
          </Button>
          <Button
            color='primary'
            onClick={() => revoke()}
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showMessage}
        autoHideDuration={10000}
        onClose={handleCloseMessage}
      >
        <Alert
          severity={messageSeverity}
          variant='filled'
          onClose={handleCloseMessage}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  )
}

WoocommerceSource.propTypes = {}

export default WoocommerceSource