import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Divider from '@mui/material/Divider'
import Paper from '@mui/material/Paper'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup, {
  toggleButtonGroupClasses,
} from '@mui/material/ToggleButtonGroup'
import BarChartIcon from '@mui/icons-material/BarChart'
import CropFreeIcon from '@mui/icons-material/CropFree'
import LayersIcon from '@mui/icons-material/Layers'
import PercentIcon from '@mui/icons-material/Percent'
import ShowChartIcon from '@mui/icons-material/ShowChart'
import { ChartBellCurveCumulative } from 'mdi-material-ui'
import { styled } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

import DarkTooltip from './DarkTooltip'

const chartDisplayItems = [
  {
    id: 'line',
    name: 'Line',
    icon: <ShowChartIcon />
  },
  {
    id: 'bar',
    name: 'Bar',
    icon: <BarChartIcon />
  },
]

const STACK_VALUE = 'stack'
const STACK_100_VALUE = 'stack100'
const ZERO_Y_AXIS_VALUE = 'zeroyaxis'

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: theme.shape.borderRadius,
  },
  [`&.${toggleButtonGroupClasses.disabled}`]: {
    border: 0,
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
    {
      marginLeft: -1,
      borderLeft: '1px solid transparent',
    },
}))

const useStyles = makeStyles((theme) => ({
  toggleButton: {
    padding: theme.spacing(0.5, 1),
    height: 32,
    width: 32,
    borderRadius: `${theme.shape.borderRadius}px !important`,
    '& .MuiSvgIcon-root': {
      fontSize: 24,
    }
  },
}))

const ChartDisplayButtonGroup = ({
  allowCumulative,
  allowUseStack100,
  showCumulative,
  onShowCumulativeChange,
  chartDisplay,
  onChartDisplayChange,
  useStack,
  onUseStackChange,
  useStack100,
  onUseStack100Change,
  useZeroYAxis,
  onUseZeroYAxisChange,
}) => {
  const classes = useStyles()
  const toggles = useMemo(() => {
    let activeToggles = []
    if (useStack) activeToggles.push(STACK_VALUE)
    if (useStack100) activeToggles.push(STACK_100_VALUE)
    if (useZeroYAxis) activeToggles.push(ZERO_Y_AXIS_VALUE)
    return activeToggles
  }, [useStack, useStack100, useZeroYAxis])

  const handleShowCumulativeChange = (newShowCumulative) => {
    onShowCumulativeChange(newShowCumulative)
  }

  const handleChartDisplayChange = (event, newChartDisplay) => {
    if (newChartDisplay !== null) {
      onChartDisplayChange(newChartDisplay)
    }
  }

  const handleToggles = (event, newToggles) => {
    if (newToggles !== null) {
      let newUseStack = newToggles.includes(STACK_VALUE)
      let newUseStack100 = newToggles.includes(STACK_100_VALUE)
      let newUseZeroYAxis = newToggles.includes(ZERO_Y_AXIS_VALUE)

      // If stack100 is on but stack was previously off, turn on stack
      if (newUseStack100 && !useStack) {
        newUseStack = true
      }

      // If stack100 is on but stack is off, turn off stack100
      if (newUseStack100 && !newUseStack) {
        newUseStack100 = false
      }

      // If stack100 is on, zeroyaxis must be off
      if (newUseStack100) {
        newUseZeroYAxis = false
      }

      onUseStackChange(newUseStack)
      onUseStack100Change(newUseStack100)
      onUseZeroYAxisChange(newUseZeroYAxis)
    }
  }

  return (
    <div>
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          border: (theme) => `1px solid ${theme.palette.divider}`,
          flexWrap: 'wrap',
        }}
      >
        {allowCumulative && (
          <React.Fragment>
            <StyledToggleButtonGroup size='small'>
              <ToggleButton
                value={showCumulative}
                selected={showCumulative}
                onChange={() => handleShowCumulativeChange(!showCumulative)}
                size='small'
                className={classes.toggleButton}
              >
                <DarkTooltip title='Show Cumulative' enterDelay={0} arrow>
                  <ChartBellCurveCumulative />
                </DarkTooltip>
              </ToggleButton>
            </StyledToggleButtonGroup>

            <Divider flexItem orientation='vertical' sx={{ mx: 0.5, my: 1 }} />
          </React.Fragment>
        )}

        <StyledToggleButtonGroup
          exclusive
          size='small'
          value={chartDisplay}
          onChange={handleChartDisplayChange}
        >
          {chartDisplayItems.map(item =>
            <ToggleButton
              key={item.id}
              value={item.id}
              size='small'
              className={classes.toggleButton}
            >
              <DarkTooltip title={item.name} enterDelay={0} arrow>
                {item.icon}
              </DarkTooltip>
            </ToggleButton>
          )}
        </StyledToggleButtonGroup>

        <Divider flexItem orientation='vertical' sx={{ mx: 0.5, my: 1 }} />

        <StyledToggleButtonGroup
          size='small'
          value={toggles}
          onChange={handleToggles}
          aria-label='text formatting'
        >
          <ToggleButton
            value={STACK_VALUE}
            className={classes.toggleButton}
          >
            <DarkTooltip title='Stack' enterDelay={0} arrow>
              <LayersIcon />
            </DarkTooltip>
          </ToggleButton>
          {allowUseStack100 && (
            <ToggleButton
              value={STACK_100_VALUE}
              className={classes.toggleButton}
            >
              <DarkTooltip title='100% Stack' enterDelay={0} arrow>
                <PercentIcon />
              </DarkTooltip>
            </ToggleButton>
          )}
          <ToggleButton
            value={ZERO_Y_AXIS_VALUE}
            disabled={useStack100}
            className={classes.toggleButton}
          >
            <DarkTooltip title='Zero Y-Axis' enterDelay={0} arrow>
              <CropFreeIcon />
            </DarkTooltip>
          </ToggleButton>
        </StyledToggleButtonGroup>
      </Paper>
    </div>
  )
}

ChartDisplayButtonGroup.propTypes = {
  allowCumulative: PropTypes.bool.isRequired,
  allowUseStack100: PropTypes.bool,
  showCumulative: PropTypes.bool.isRequired,
  onShowCumulativeChange: PropTypes.func.isRequired,
  chartDisplay: PropTypes.string.isRequired,
  onChartDisplayChange: PropTypes.func.isRequired,
  useStack: PropTypes.bool.isRequired,
  onUseStackChange: PropTypes.func.isRequired,
  useStack100: PropTypes.bool.isRequired,
  onUseStack100Change: PropTypes.func.isRequired,
  useZeroYAxis: PropTypes.bool.isRequired,
  onUseZeroYAxisChange: PropTypes.func.isRequired,
}

export default ChartDisplayButtonGroup