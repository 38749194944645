import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Popover from '@mui/material/Popover'
import makeStyles from '@mui/styles/makeStyles'
import { CallSplit } from 'mdi-material-ui'

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: theme.spacing(2),
  },
  popover: {
    marginTop: theme.spacing(1),
  },
  listItem: {
    paddingTop: theme.spacing(1) / 2,
    paddingBottom: theme.spacing(1) / 2,
  },
}))

const breakdowns = [
  {id: 'platform', name: 'Platform'},
  {id: 'integration_id', name: 'Data Source'},
  {id: 'funnel_id', name: 'Funnel'},
  {id: 'product_id', name: 'Product'},
  {id: 'productVariant_id', name: 'Product Variant'},
  {id: 'utm_source', name: 'utm_source'},
  {id: 'utm_medium', name: 'utm_medium'},
  {id: 'utm_campaign', name: 'utm_campaign'},
  {id: 'utm_content', name: 'utm_content'},
  {id: 'utm_term', name: 'utm_term'},
  {id: 'affiliate_id_1', name: 'Affiliate ID 1'},
  {id: 'affiliate_id_2', name: 'Affiliate ID 2'},
  {id: 'country', name: 'Country'},
]

const BreakdownSelect = (props) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleBreakdownSelect = (breakdown) => {
    props.onBreakdownSelect(breakdown)
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'popover-breakdown' : undefined

  return (
    <div className={classes.root}>
      <Button
        aria-describedby={id}
        variant='outlined'
        color='secondary'
        onClick={handleClick}
        startIcon={<CallSplit />}
      >
        {props.breakdownLabel ? props.breakdownLabel : 'Breakdown'}
      </Button>
      <Popover
        className={classes.popover}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
        transformOrigin={{ vertical: 'top', horizontal: 'left', }}
      >
        <Box>
          <List component='ul' aria-label='breakdown selector' disablePadding>
            {breakdowns.map(breakdown =>
              <ListItem
                key={breakdown.id}
                className={classes.listItem}
                onClick={() => handleBreakdownSelect(breakdown)}
                button
              >
                <ListItemText primary={breakdown.name} />
              </ListItem>
            )}
          </List>
        </Box>
      </Popover>
    </div>
  )
}

BreakdownSelect.propTypes = {
  onBreakdownSelect: PropTypes.func.isRequired,
  breakdownLabel: PropTypes.string,
}

export default BreakdownSelect
