import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'

import { FirebaseContext } from '../../utils/firebase'
import 'firebase/auth'

import {
  useQuery,
} from 'react-query'

import { formatNumber } from '../../utils/helpers'
import { API_ROOT_URL } from '../../constants/'

const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  newCustomersNameTableCell: {
    width: 165,
    minWidth: 120,
    fontWeight: 'bold',
  },
  typographySecondaryColor: {
    color: theme.palette.text.secondary,
  }
}))

const NewCustomersSummary = (props) => {
  const { frontendType, context, extendBackend } = props
  const classes = useStyles()
  const firebase = React.useContext(FirebaseContext)

  const { isLoading, data } = useQuery(['dashboard-new-customers', context, frontendType, extendBackend], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/dashboard/new_customers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          startDate: context.dates.start.format(),
          endDate: context.dates.end.format(),
          timezone: TIMEZONE,
          filters: context.filters,
          frontendType,
          extendBackend
        })
      }).then(res => res.json())
    }),
    {
      enabled: !!context.dates.start && !!context.dates.end,
      cacheTime: 15 * 60 * 1000,  // 15 minutes
      staleTime: 15 * 60 * 1000,  // 15 minutes
      refetchOnWindowFocus: false,
    }
  )

  const frontendData = data ? (data.frontend[0] ? data.frontend[0] : {}) : {}
  const backendData = data ? (data.backend[0] ? data.backend[0] : {}) : {}
  const lifetimeData = data ? data.lifetime : {}

  return (
    <Paper className={classes.root}>
      <Typography variant='h6'>
        New Customers
      </Typography>
      {isLoading ? (
        <Skeleton variant="rectangular" width={'100%'} height={136} animation='wave' />
      ) : (
        <TableContainer>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align='right'><b>Frontend</b></TableCell>
                <TableCell align='right'><b>Backend</b></TableCell>
                <TableCell align='right'><b>Lifetime</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={classes.newCustomersNameTableCell} align='right'>customers</TableCell>
                <TableCell align='right'>{formatNumber(frontendData.customers, 0)}</TableCell>
                <TableCell align='right'>{formatNumber(backendData.customers, 0)} &nbsp;<span className={classes.typographySecondaryColor}>({formatNumber(100 * backendData.customers / frontendData.customers, 0)}%)</span></TableCell>
                <TableCell align='right'>{formatNumber(lifetimeData.customers, 0)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.newCustomersNameTableCell} align='right'>revenue</TableCell>
                <TableCell align='right'>${formatNumber(frontendData.revenue, 2)}</TableCell>
                <TableCell align='right'>${formatNumber(backendData.revenue, 2)} &nbsp;<span className={classes.typographySecondaryColor}>({backendData.revenue >= 0 ? '+' : ''}{formatNumber(100 * backendData.revenue / frontendData.revenue, 0)}%)</span></TableCell>
                <TableCell align='right'>${formatNumber(lifetimeData.revenue, 2)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.newCustomersNameTableCell} align='right'>revenue per customer</TableCell>
                <TableCell align='right'>${formatNumber(frontendData.revenue_per_customer, 2)}</TableCell>
                <TableCell align='right'>${formatNumber(backendData.revenue_per_customer, 2)}</TableCell>
                <TableCell align='right'>${formatNumber(lifetimeData.revenue_per_customer, 2)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
}

NewCustomersSummary.propTypes = {
  frontendType: PropTypes.string.isRequired,
  context: PropTypes.object.isRequired,
  extendBackend: PropTypes.bool.isRequired,
}

export default NewCustomersSummary