import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'

import { formatNumber, getAlias } from '../../utils/helpers'

const useStyles = makeStyles(theme => ({
  root: {
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  revenueTotalColumn: {
    textAlign: 'center',
    margin: theme.spacing(0, 1),
  },
  metricValueTypography: {
    lineHeight: 1,
  },
  metricKeyTypography: {
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
  },
}))

const RevenueTotals = (props) => {
  const { aliases, colors, data } = props
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container>
        {data.map(row =>
          <div key={row.breakdown} className={classes.revenueTotalColumn}>
            <Typography
              className={clsx(classes.bold, classes.metricValueTypography)}
              variant='h6'
              style={{ color: colors[row.breakdown] }}
            >
              ${formatNumber(row.revenue_net, 2)}
            </Typography>
            <Typography className={classes.metricKeyTypography} variant='caption'>
              {getAlias(aliases, row.breakdown)}
            </Typography>
          </div>
        )}
      </Grid>
    </div>
  )
}

RevenueTotals.propTypes = {
  aliases: PropTypes.array.isRequired,
  colors: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
}

export default RevenueTotals