import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import makeStyles from '@mui/styles/makeStyles'

import GraphLTV from '../Graph/GraphLTV'
import GraphRevenue from '../Graph/GraphRevenue'
import GraphAOV from '../Graph/GraphAOV'
import GraphAcquisition from '../Graph/GraphAcquisition'
import GraphTTFO from '../Graph/GraphTTFO'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  formControl: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'stretch',
    },
  },
  selectOverride: {
    padding: '8px 12px',
  },
}))

const graphItems = [
  { id: 'ltv', name: 'Lifetime Value', allowedAttribution: ['customer', 'lead'] },
  { id: 'revenue', name: 'Revenue', allowedAttribution: ['customer'] },
  { id: 'aov', name: 'Average Order Value', allowedAttribution: ['customer'] },
  { id: 'acquisition', name: 'Acquisition', allowedAttribution: ['customer', 'lead'] },
  { id: 'ttfo', name: 'Time to First Order', allowedAttribution: ['customer', 'lead'] },
]

const GraphContainer = (props) => {
  const classes = useStyles()
  const [graphId, setGraphId] = React.useState(props.graphId)

  const filteredGraphItems = graphItems.filter(item => item.allowedAttribution.indexOf(props.attribution) > -1)
  let selectedGraphId = graphId
  if (filteredGraphItems.map(item => item.id).indexOf(graphId) === -1) {
    selectedGraphId = 'ttfo'
  }
  return (
    <Paper className={classes.root}>
      <FormControl className={classes.formControl} variant='outlined' >
        <Select
          className={classes.select}
          labelId='graph-select-label'
          id='graph-select'
          value={selectedGraphId}
          onChange={(event) => setGraphId(event.target.value)}
          classes={{ select: classes.selectOverride }}
        >
          {filteredGraphItems.map(graph =>
            <MenuItem key={graph.id} value={graph.id}>{graph.name}</MenuItem>
          )}
        </Select>
      </FormControl>
      <GraphSelector
        graphId={selectedGraphId}
        attribution={props.attribution}
        context={props.context}
        extendBackend={props.extendBackend}
        includeCosts={props.includeCosts}
        includeCommission={props.includeCommission}
        colors={props.colors}
      />
    </Paper>
  )
}

const GraphSelector = (props) => {
  switch (props.graphId) {
    case 'ltv':
      return <GraphLTV attribution={props.attribution} context={props.context} extendBackend={props.extendBackend} includeCosts={props.includeCosts} includeCommission={props.includeCommission} colors={props.colors} />
    case 'revenue':
      return <GraphRevenue context={props.context} colors={props.colors} />
    case 'aov':
      return <GraphAOV context={props.context} colors={props.colors} />
    case 'acquisition':
      return <GraphAcquisition context={props.context} colors={props.colors} />
    case 'ttfo':
      return <GraphTTFO context={props.context} colors={props.colors} />
    default:
      return null
  }
}

GraphContainer.propTypes = {
  attribution: PropTypes.string.isRequired,
  graphId: PropTypes.string.isRequired,
  context: PropTypes.object.isRequired,
  extendBackend: PropTypes.bool,
  includeCosts: PropTypes.bool,
  includeCommission: PropTypes.bool,
  colors: PropTypes.object.isRequired,
}

export default GraphContainer
