import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Hidden from '@mui/material/Hidden'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Popover from '@mui/material/Popover'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CheckIcon from '@mui/icons-material/Check'
import makeStyles from '@mui/styles/makeStyles'

import { FirebaseContext } from '../../utils/firebase'
import 'firebase/auth'

import moment from 'moment-timezone'

import { BLUE_ACCENT_COLOR, PUBLIC_DEMO_UID } from '../../constants'

import DateRangePicker from '../Common/DateRangePicker'

const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const useStyles = makeStyles(theme => ({
  controlButton: {
    textTransform: 'none',
  },
  popover: {
    marginTop: theme.spacing(0.5),
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItemIcon: {
    minWidth: 24,
  },
  listItemIconFontSize: {
    fontSize: 16,
  },
  listItemTextSelected: {
    color: BLUE_ACCENT_COLOR
  },
  buttonGroupFirst: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  buttonGroupLast: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: 'none',
    '&:hover': {
      borderLeft: 'none',
    },
  },
}))

const dateRangeShortcutSelectItems = [
  {id: 'today', name: 'Today'},
  {id: 'yesterday', name: 'Yesterday'},
  {id: 'last_7_days', name: 'Last 7 days'},
  {id: 'last_4_weeks', name: 'Last 4 weeks'},
  {id: 'last_3_months', name: 'Last 3 months'},
  {id: 'last_12_months', name: 'Last 12 months'},
  {id: 'month_to_date', name: 'Month to date'},
  {id: 'quarter_to_date', name: 'Quarter to date'},
  {id: 'year_to_date', name: 'Year to date'},
  {id: 'all', name: 'All time'},
  {id: 'custom', name: 'Custom'},
]

const dateRangeCompareTypeSelectItems = [
  {id: 'previous_period', name: 'Previous period'},
  {id: 'previous_month', name: 'Previous month'},
  {id: 'previous_quarter', name: 'Previous quarter'},
  {id: 'previous_year', name: 'Previous year'},
]

let INITIAL_DATE_RANGE_SHORTCUT = 'today'

const ReportsControls = (props) => {
  const classes = useStyles()
  const firebase = React.useContext(FirebaseContext)

  // Public Demo check to show the right content
  const uid = firebase.auth().currentUser ? firebase.auth().currentUser.uid : null
  const isPublicDemo = uid === PUBLIC_DEMO_UID
  if (isPublicDemo) {
    INITIAL_DATE_RANGE_SHORTCUT = 'last_12_months'
  }

  const [dateRangeShortcutAnchorEl, setDateRangeShortcutAnchorEl] = React.useState(null)
  const [dateRangeShortcut, setDateRangeShortcut] = React.useState(INITIAL_DATE_RANGE_SHORTCUT)
  const [dateRangeCompareTypeAnchorEl, setDateRangeCompareTypeAnchorEl] = React.useState(null)

  const handleDateRangeShortcutClick = (event) => {
    setDateRangeShortcutAnchorEl(event.currentTarget)
  }

  const handleDateRangeCompareClick = (event) => {
    setDateRangeCompareTypeAnchorEl(event.currentTarget)
  }

  const handleDateRangeCompareTypeSelect = (id) => {
    props.setDateRangeCompareType(id)
    setDateRangeCompareTypeAnchorEl(null)
  }

  const handleDateRangeShortcutClose = () => {
    setDateRangeShortcutAnchorEl(null)
  }

  const handleDateRangeCompareClose = () => {
    setDateRangeCompareTypeAnchorEl(null)
  }


  const handleDateRangeShortcutSelect = (id) => {
    setDateRangeShortcut(id)
    setDateRangeShortcutAnchorEl(null)
    props.onDateRangeShortcutSelect(id)
  }

  const handleDatesChange = (dates) => {
    setDateRangeShortcut('custom')
    props.onDatesChange(dates)
  }

  const getResolutionSelectItems = (startDate, endDate) => {
    const resolutionHourSelectItem = {id: 'hour', value: 'Hourly'}
    const resolutionDaySelectItem = {id: 'day', value: 'Daily'}
    const resolutionWeekSelectItem = {id: 'week', value: 'Weekly'}
    const resolutionMonthSelectItem = {id: 'month', value: 'Monthly'}
    const resolutionYearSelectItem = {id: 'year', value: 'Yearly'}

    let resolutionSelectItems = []
    const periodDays = endDate.diff(startDate, 'days') + 1
    if (periodDays <= 1) {
      resolutionSelectItems.push(resolutionHourSelectItem)
    } else if (periodDays > 1 && periodDays < 14) {
      resolutionSelectItems.push(resolutionHourSelectItem)
      resolutionSelectItems.push(resolutionDaySelectItem)
    } else if (periodDays >= 14 && periodDays < 60) {
      resolutionSelectItems.push(resolutionDaySelectItem)
      resolutionSelectItems.push(resolutionWeekSelectItem)
    } else if (periodDays >= 60 && periodDays < 90) {
      resolutionSelectItems.push(resolutionDaySelectItem)
      resolutionSelectItems.push(resolutionWeekSelectItem)
      resolutionSelectItems.push(resolutionMonthSelectItem)
    } else if (periodDays >= 90 && periodDays < 730) {
      resolutionSelectItems.push(resolutionWeekSelectItem)
      resolutionSelectItems.push(resolutionMonthSelectItem)
    } else if (periodDays >= 730) {
      resolutionSelectItems.push(resolutionMonthSelectItem)
      resolutionSelectItems.push(resolutionYearSelectItem)
    }
    return resolutionSelectItems
  }

  const dateRangeShortcutName = dateRangeShortcutSelectItems.find(el => el.id === dateRangeShortcut).name
  const dateRangeCompareTypeName = dateRangeCompareTypeSelectItems.find(el => el.id === props.dateRangeCompareType).name
  const resolutionSelectItems = getResolutionSelectItems(props.startDate, props.endDate)

  return (
    <Paper variant='outlined'>
      <Box display='flex' flexDirection='row' padding={2}>
        <Button
          className={classes.controlButton}
          size='small'
          variant='outlined'
          endIcon={<ArrowDropDownIcon />}
          onClick={handleDateRangeShortcutClick}
          classes={{
            root: classes.buttonGroupFirst
          }}
        >
          {dateRangeShortcutName}
        </Button>
        <Popover
          className={classes.popover}
          open={Boolean(dateRangeShortcutAnchorEl)}
          anchorEl={dateRangeShortcutAnchorEl}
          onClose={handleDateRangeShortcutClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
          transformOrigin={{ vertical: 'top', horizontal: 'left', }}
        >
          <Box>
            <List component='ul' aria-label='dateRangeShortcut selector'>
              {dateRangeShortcutSelectItems.map(item => {
                const selected = item.id === dateRangeShortcut
                return (
                  <ListItem
                    key={item.id}
                    className={classes.listItem}
                    onClick={() => handleDateRangeShortcutSelect(item.id)}
                    button
                    disabled={item.id === 'custom' || (item.id === 'all' && props.datesAreLoading)}
                  >
                    <ListItemIcon
                      className={classes.listItemIcon}
                      style={{ visibility: selected ? 'visible' : 'hidden', color: selected ? BLUE_ACCENT_COLOR : 'inherit' }}
                    >
                      <CheckIcon classes={{ root: classes.listItemIconFontSize }}/>
                    </ListItemIcon>

                    <ListItemText
                      primary={item.name}
                      classes={{
                        primary: selected ? classes.listItemTextSelected : ''
                      }}
                    />
                  </ListItem>
                )
              })}
            </List>
          </Box>
        </Popover>

        <Hidden mdDown>
          <Box display='flex'>
            <DateRangePicker
              startDate={props.startDate}
              endDate={props.endDate}
              onDatesChange={handleDatesChange}
              isButtonGroupLast={true}
            />
          </Box>

          <Box display='flex' alignItems='center' marginX={1}>
            <Typography>
              compared to
            </Typography>
          </Box>

          <Button
            className={classes.controlButton}
            size='small'
            variant='outlined'
            endIcon={<ArrowDropDownIcon />}
            onClick={handleDateRangeCompareClick}
            classes={{
              root: classes.buttonGroupFirst
            }}
          >
            {dateRangeCompareTypeName}
          </Button>
          <Popover
            className={classes.popover}
            open={Boolean(dateRangeCompareTypeAnchorEl)}
            anchorEl={dateRangeCompareTypeAnchorEl}
            onClose={handleDateRangeCompareClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
            transformOrigin={{ vertical: 'top', horizontal: 'left', }}
          >
            <Box>
              <List component='ul' aria-label='dateRangeShortcut selector'>
                {dateRangeCompareTypeSelectItems.map(item => {
                  const selected = item.id === props.dateRangeCompareType
                  const dateRangeCompare = props.dateRangeCompareItems[item.id]
                  return (
                    <ListItem
                      key={item.id}
                      className={classes.listItem}
                      onClick={() => handleDateRangeCompareTypeSelect(item.id)}
                      button
                    >
                      <ListItemIcon
                        className={classes.listItemIcon}
                        style={{ visibility: selected ? 'visible' : 'hidden', color: selected ? BLUE_ACCENT_COLOR : 'inherit' }}
                      >
                        <CheckIcon classes={{ root: classes.listItemIconFontSize }}/>
                      </ListItemIcon>

                      <ListItemText
                        primary={item.name}
                        secondary={`${dateRangeCompare.startDate.format(`MMM D${dateRangeCompare.startDate.year() !== moment.tz(TIMEZONE).year() ? ', YYYY' : ''}`)}\u2014${dateRangeCompare.endDate.format(`MMM D${dateRangeCompare.endDate.year() !== moment.tz(TIMEZONE).year() ? ', YYYY' : ''}`)}`}
                        classes={{
                          primary: selected ? classes.listItemTextSelected : ''
                        }}
                      />
                    </ListItem>
                  )
                })}
              </List>
            </Box>
          </Popover>
          <Button
            className={classes.controlButton}
            size='small'
            variant='outlined'
            classes={{
              root: classes.buttonGroupLast
            }}
          >
            {`${props.dateRangeCompareItems[props.dateRangeCompareType].startDate.format(`MMM D${props.dateRangeCompareItems[props.dateRangeCompareType].startDate.year() !== moment.tz(TIMEZONE).year() ? ', YYYY' : ''}`)}\u2014${props.dateRangeCompareItems[props.dateRangeCompareType].endDate.format(`MMM D${props.dateRangeCompareItems[props.dateRangeCompareType].endDate.year() !== moment.tz(TIMEZONE).year() ? ', YYYY' : ''}`)}`}
          </Button>
        </Hidden>
        <div style={{ flexGrow: 1 }} />
        <FormControl
          variant='outlined'
          margin='none'
          size='small'
        >
            <Select
            labelId='resolution-label'
            id='resolution-select'
            value={props.resolution}
            onChange={event => props.setResolution(event.target.value)}
          >
            {resolutionSelectItems.map(item =>
              <MenuItem key={item.id} value={item.id}>{item.value}</MenuItem>
            )}
          </Select>
        </FormControl>
      </Box>
    </Paper>
  );
}

ReportsControls.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  resolution: PropTypes.string.isRequired,
  dateRangeCompareItems: PropTypes.object,
  dateRangeCompareType: PropTypes.string.isRequired,
  setResolution: PropTypes.func.isRequired,
  setDateRangeCompareType: PropTypes.func.isRequired,
  onDatesChange: PropTypes.func.isRequired,
  onDateRangeShortcutSelect: PropTypes.func.isRequired,
  datesAreLoading: PropTypes.bool.isRequired,
}

export default ReportsControls