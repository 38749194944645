import React  from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'

import moment from 'moment-timezone'

import { FirebaseContext } from '../../../utils/firebase'
import 'firebase/auth'
import { useQuery } from 'react-query'

import { API_ROOT_URL, BLUE_ACCENT_COLOR } from '../../../constants'
import { formatNumber } from '../../../utils/helpers'

import ChangeBadge from '../ChangeBadge'
import { withStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  titleTypography: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  typographyPrimary: {
    fontWeight: 'bold',
    color: BLUE_ACCENT_COLOR,
  },
  typographySecondary: {
    color: theme.palette.text.secondary,
  },
  typographyBold: {
    fontWeight: 'bold',
  },
  dashedUnderline: {
    textDecoration: 'underline',
    textDecorationStyle: 'dashed',
  },
  tableData: {
    [theme.breakpoints.down('md')]: {
      padding: '6px 12px 6px 0px',
    },
  },
  changeBadgeTableCell: {
    maxWidth: 120,
    width: 80,
    [theme.breakpoints.down('md')]: {
      padding: '6px 0px 6px 12px !important',
    },
  },
}))

const TOP_COUNT = 5
const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const CardTopItem = (props) => {
  const { breakdown, compareDateRange, isDollar, metric, primaryDateRange, title } = props
  const classes = useStyles()
  const firebase = React.useContext(FirebaseContext)

  const { isLoading, data } = useQuery(['daily-reports-top', metric, primaryDateRange, compareDateRange, TIMEZONE, breakdown], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/daily/reports/top`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          metric,
          primaryDateRange,
          compareDateRange,
          timezone: TIMEZONE,
          breakdown,
          limit: TOP_COUNT
        })
      }).then(res => res.json())
    }),
    {
      cacheTime: 15 * 60 * 1000,  // 15 minutes
      staleTime: 15 * 60 * 1000,  // 15 minutes
      refetchOnWindowFocus: false,
    }
  )

  const frontendData = data ? data.frontend.slice(0, TOP_COUNT) : []
  const backendData = data ? data.backend.slice(0, TOP_COUNT) : []

  return (
    <Box>
      <Paper variant='outlined'>
        <Box padding={2}>
          <Box display='flex' flexDirection='row' alignItems='center' marginBottom={1}>
            <Box marginRight={1}>
              <Typography className={classes.titleTypography} variant='body2'>
                {title}
              </Typography>
            </Box>
          </Box>
          {isLoading ? (
            <Box height='100%'>
              <Skeleton variant="rectangular" width='100%' height={419} />
            </Box>
          ) : (
            <Box>
              <TableContainer>
                <Typography variant='overline'>
                  Frontend
                </Typography>
                <Table size='small'>
                  <TableBody>
                    {frontendData.map(row =>
                      <TableRow key={row.breakdown}>
                        <TableCellWithoutLines align='left' className={classes.tableData}><span className={classes.typographyBold}>{row.alias}</span><span>&nbsp; {row.platform_id ? `[ID:${row.platform_id}]` : ''}</span></TableCellWithoutLines>
                        <TableCellWithoutLines align='right'><span className={classes.typographyPrimary}>{isDollar && '$'}{formatNumber(row.primary, 0)}</span></TableCellWithoutLines>
                        <TableCellWithoutLines align='right'><span className={classes.typographySecondary}>{isDollar && '$'}{formatNumber(row.compare, 0)}</span></TableCellWithoutLines>
                        <TableCellWithoutLines className={classes.changeBadgeTableCell} align='right'>
                          <ChangeBadge
                            metricPrimary={row.primary}
                            metricSecondary={row.compare}
                          />
                        </TableCellWithoutLines>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <Box marginY={1}>
                <Divider variant='middle' />
              </Box>

              <TableContainer>
                <Typography variant='overline'>
                  Backend
                </Typography>
                <Table size='small'>
                  <TableBody>
                    {backendData.map(row =>
                      <TableRow key={row.breakdown}>
                        <TableCellWithoutLines align='left' className={classes.tableData}><span className={classes.typographyBold}>{row.alias}</span><span>&nbsp; {row.platform_id ? `[ID:${row.platform_id}]` : ''}</span></TableCellWithoutLines>
                        <TableCellWithoutLines align='right'><span className={classes.typographyPrimary}>{isDollar && '$'}{formatNumber(row.primary, 0)}</span></TableCellWithoutLines>
                        <TableCellWithoutLines align='right'><span className={classes.typographySecondary}>{isDollar && '$'}{formatNumber(row.compare, 0)}</span></TableCellWithoutLines>
                        <TableCellWithoutLines className={classes.changeBadgeTableCell} align='right'>
                          <ChangeBadge
                            metricPrimary={row.primary}
                            metricSecondary={row.compare}
                          />
                        </TableCellWithoutLines>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
}

const TableCellWithoutLines = withStyles({
  root: {
    borderBottom: 'none'
  }
})(TableCell)

CardTopItem.propTypes = {
  breakdown: PropTypes.object.isRequired,
  compareDateRange: PropTypes.object.isRequired,
  metric: PropTypes.string.isRequired,
  primaryDateRange: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  isDollar: PropTypes.bool.isRequired,
}

export default CardTopItem