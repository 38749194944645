import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Drawer from '@mui/material/Drawer'
import FormControlLabel from '@mui/material/FormControlLabel'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import makeStyles from '@mui/styles/makeStyles'

import { UserContext } from '../../../contexts/UserContext'

import { getCurrencySymbol, getDisplayValueByFormat } from '../../../utils/helpers'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
  },
  box: {
    height: '100%',
    width: 300,
  },
  filterContent: {
    height: 'calc(100% - 64px)',
    padding: theme.spacing(2),
    overflowY: 'auto',
  },
  actionRow: {
    height: 64,
    width: '100%',
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',

  },
  actionButton: {
    margin: theme.spacing(0, 1),
  },
}))

const AmountFilter = (props) => {
  const classes = useStyles()
  const userDoc = React.useContext(UserContext)

  const [open, setOpen] = React.useState(false)
  const [more, setMore] = React.useState(false)
  const [less, setLess] = React.useState(false)
  const [equal, setEqual] = React.useState(false)
  const [moreInput, setMoreInput] = React.useState('')
  const [lessInput, setLessInput] = React.useState('')
  const [equalInput, setEqualInput] = React.useState('')

  const handleChange = (event) => {
    switch(event.target.name) {
      case 'more':
        setMore(event.target.checked)
        if (!event.target.checked) {
          setMoreInput('')
        }
        break
      case 'less':
        setLess(event.target.checked)
        if (!event.target.checked) {
          setLessInput('')
        }
        break
      case 'equal':
        setEqual(event.target.checked)
        if (!event.target.checked) {
          setEqualInput('')
        }
        break
      default:
        break
    }
  }

  const handleChangeInput = (event) => {
    switch(event.target.name) {
      case 'more-input':
        setMoreInput(event.target.value)
        break
      case 'less-input':
        setLessInput(event.target.value)
        break
      case 'equal-input':
        setEqualInput(event.target.value)
        break
      default:
        break
    }
  }

  const handleAddFilter = () => {
    let filters = []
    if (more) {
      const filter = {
        id: 'amount_cents',
        name: 'Amount',
        value: Math.round(parseFloat(moreInput) * 100),
        valueName: `greater than ${getDisplayValueByFormat(
          parseFloat(moreInput),
          'currency',
          {
            decimalCount: 2,
            currency: userDoc.currency,
          }
        )}`,
        comparison: '>'
      }
      filters.push(filter)
    }
    if (less) {
      const filter = {
        id: 'amount_cents',
        name: 'Amount',
        value: Math.round(parseFloat(lessInput) * 100),
        valueName: `less than ${getDisplayValueByFormat(
          parseFloat(lessInput),
          'currency',
          {
            decimalCount: 2,
            currency: userDoc.currency,
          }
        )}`,
        comparison: '<'
      }
      filters.push(filter)
    }
    if (equal) {
      const filter = {
        id: 'amount_cents',
        name: 'Amount',
        value: Math.round(parseFloat(equalInput) * 100),
        valueName: `equal to ${getDisplayValueByFormat(
          parseFloat(equalInput),
          'currency',
          {
            decimalCount: 2,
            currency: userDoc.currency,
          }
        )}`,
        comparison: '='
      }
      filters.push(filter)
    }
    props.onFiltersAdd(filters)
    handleClose()
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setMore(false)
    setLess(false)
    setEqual(false)
    setMoreInput('')
    setLessInput('')
    setEqualInput('')
    setOpen(false)
  }

  const validInputRegex = /^[0-9,]*\.?[0-9]*$/
  const moreError = moreInput.length === 0 || !validInputRegex.test(moreInput)
  const lessError = lessInput.length === 0 || !validInputRegex.test(lessInput)
  const equalError = equalInput.length === 0 || !validInputRegex.test(equalInput)

  let validEntry = true
  if (more && moreError) validEntry = false
  if (less && lessError) validEntry = false
  if (equal && equalError) validEntry = false
  if (moreInput.length === 0
      && lessInput.length === 0
      && equalInput.length === 0) validEntry = false

  return (
    <div className={classes.root}>
      <Button
        variant='outlined'
        color='primary'
        onClick={handleOpen}
        endIcon={<ArrowDropDownIcon color='primary' />}
      >
        Amount
      </Button>
      <Drawer
        anchor='right'
        open={open}
        onClose={handleClose}
      >
        <Box className={classes.box}>
          <div className={classes.filterContent}>
            <Typography variant='h6' gutterBottom>
              Amount
            </Typography>

            <FormControlLabel
              control={
                <Checkbox
                  checked={more}
                  onChange={handleChange}
                  name='more'
                  color='primary'
                />
              }
              label='Amount is greater than'
              disabled={equal}
            />
            {more && (
              <TextField
                id='more-input'
                name='more-input'
                variant='outlined'
                value={moreInput}
                onChange={handleChangeInput}
                error={moreError}
                helperText='Format must match 0.00'
                placeholder='0.00'
                InputProps={{
                  startAdornment: <InputAdornment position='start'>{getCurrencySymbol(userDoc.currency)}</InputAdornment>
                }}
                size='small'
              />
            )}

            <FormControlLabel
              control={
                <Checkbox
                  checked={less}
                  onChange={handleChange}
                  name='less'
                  color='primary'
                />
              }
              label='Amount is less than'
              disabled={equal}
            />
            {less && (
              <TextField
                id='less-input'
                name='less-input'
                variant='outlined'
                value={lessInput}
                onChange={handleChangeInput}
                error={lessError}
                helperText='Format must match 0.00'
                placeholder='0.00'
                InputProps={{
                  startAdornment: <InputAdornment position='start'>{getCurrencySymbol(userDoc.currency)}</InputAdornment>
                }}
                size='small'
              />
            )}

            <FormControlLabel
              control={
                <Checkbox
                  checked={equal}
                  onChange={handleChange}
                  name='equal'
                  color='primary'
                />
              }
              label='Amount is equal to'
              disabled={more || less}
            />
            {equal && (
              <TextField
                id='equal-input'
                name='equal-input'
                variant='outlined'
                value={equalInput}
                onChange={handleChangeInput}
                error={equalError}
                helperText='Format must match 0.00'
                placeholder='0.00'
                InputProps={{
                  startAdornment: <InputAdornment position='start'>{getCurrencySymbol(userDoc.currency)}</InputAdornment>
                }}
                size='small'
              />
            )}

          </div>
          <div className={classes.actionRow}>
            <Button
              className={classes.actionButton}
              variant='contained'
              color='primary'
              onClick={handleAddFilter}
              disabled={!validEntry}
            >
              Apply
            </Button>
            <Button className={classes.actionButton} variant='outlined' onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </Box>
      </Drawer>
    </div>
  );
}

AmountFilter.propTypes = {
  onFiltersAdd: PropTypes.func.isRequired,
}

export default AmountFilter
