import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import makeStyles from '@mui/styles/makeStyles'

import GraphContainer from '../GraphContainer/'

const useStyles = makeStyles(() => ({
  root: {
  }
}))

const Graphs = (props) => {
  const classes = useStyles()
  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} sm={12} md={6}>
        <GraphContainer
          attribution={props.attribution}
          graphId={'ltv'}
          context={props.context}
          extendBackend={props.extendBackend}
          includeCosts={props.includeCosts}
          includeCommission={props.includeCommission}
          colors={props.colors}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <GraphContainer
          attribution={props.attribution}
          graphId={'revenue'}
          context={props.context}
          extendBackend={props.extendBackend}
          includeCosts={props.includeCosts}
          includeCommission={props.includeCommission}
          colors={props.colors}
        />
      </Grid>
    </Grid>
  )
}

Graphs.propTypes = {
  attribution: PropTypes.string.isRequired,
  context: PropTypes.object.isRequired,
  extendBackend: PropTypes.bool,
  includeCosts: PropTypes.bool,
  includeCommission: PropTypes.bool,
  colors: PropTypes.object.isRequired,
}

export default Graphs
