import React, { useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Autocomplete from '@mui/material/Autocomplete'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Popover from '@mui/material/Popover'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import SegmentIcon from '@mui/icons-material/Segment'
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled'
import SyncIcon from '@mui/icons-material/Sync'
import { alpha } from '@mui/material/styles'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import moment from 'moment-timezone'

import { FirebaseContext } from '../../utils/firebase'
import { deleteDocument, updateDocument } from '../../utils/firestore'
import { FIRESTORE_COLLECTIONS } from '../../constants'

import Config from './Config'
import DateRangePicker from './DateRangePicker'
import ReportDialog from './ReportDialog'
import SegmentBuilder from './SegmentBuilder'
import DarkTooltip from './DarkTooltip'
import { CORE_REPORTS } from './ReportItems'

const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const Controls = ({
  report,
  savedSegments,
  savedGroups,
  liveMode,
  startDate,
  endDate,
  resolution,
  config,
  segments,
  segmentsEligibleBreakdownKeys,
  aliases,
  onReportSave,
  onReportDelete,
  onLiveModeChange,
  onDatesChange,
  onConfigChange,
  onSegmentAdd,
  onSegmentDelete,
  onSegmentDuplicate,
  onSegmentEditChange,
  onSegmentSaveEdit,
  onSegmentRevertEdit,
  onSegmentColorChange,
  onSegmentFilterAdd,
  onSegmentReplaceFiltersWithSavedSegmentFilter,
  onSegmentFilterDelete,
  onSegmentsReorder,
  onFilterAdd,
  onFilterUpdate,
  onFilterDelete,
  onClearAllSegmentFilters,
  onSegmentBreakdownSelect,
  onSegmentBreakdownColorChange,
}) => {
  const firebase = useContext(FirebaseContext)
  const [helpAnchorEl, setHelpAnchorEl] = useState(null)

  const [reportMenuAnchorEl, setReportMenuAnchorEl] = useState(null)
  const [reportDeleteDialogOpen, setReportDeleteDialogOpen] = useState(false)
  const [reportSaveDialogOpen, setReportSaveDialogOpen] = useState(false)

  const [segmentsMenuOpen, setSegmentsMenuOpen] = useState(false)
  const [segmentName, setSegmentName] = useState('')
  const [segmentEditId, setSegmentEditId] = useState(null)
  const [segmentDeleteId, setSegmentDeleteId] = useState(null)
  const [segmentEditDialogOpen, setSegmentEditDialogOpen] = useState(false)
  const [segmentDeleteDialogOpen, setSegmentDeleteDialogOpen] = useState(false)

  const segmentAnchorRef = React.useRef(null)
  const segmentInputRef = React.useRef()

  const handleHelpPopoverOpen = (event) => {
    setHelpAnchorEl(event.currentTarget)
  }

  const handleReportMenuOpen = (event) => {
    setReportMenuAnchorEl(event.currentTarget)
  }

  const handleReportMenuClose = () => {
    setReportMenuAnchorEl(null)
  }

  const handleReportDeleteDialogOpen = () => {
    setReportMenuAnchorEl(null)
    setReportDeleteDialogOpen(true)
  }

  const handleReportDeleteDialogClose = () => {
    setReportDeleteDialogOpen(false)
  }

  const handleReportDelete = (reportId) => {
    onReportDelete(reportId)
    setReportDeleteDialogOpen(false)
  }

  const handleHelpPopoverClose = () => {
    setHelpAnchorEl(null)
  }

  const handleSegmentsSelectOpen = () => {
    setSegmentsMenuOpen(true)
  }

  const handleSegmentsSelectClose = () => {
    setSegmentsMenuOpen(false)
  }

  const handleSelectSegmentValue = (newSegmentValue) => {
    if (newSegmentValue === null) return
    onSegmentAdd({
      id: newSegmentValue.id,
      kind: report.splitSegmentFrontBack ? 'frontend' : 'all'
    })
    handleSegmentsSelectClose()
  }

  const handleSegmentEditDialogOpen = (e, id) => {
    e.stopPropagation()
    setSegmentName(savedSegments.find(g => g.id === id).name)
    setSegmentEditId(id)
    setSegmentEditDialogOpen(true)
  }

  const handleSegmentEditDialogClose = () => {
    setSegmentName('')
    setSegmentEditDialogOpen(false)
  }

  const handleSegmentDeleteDialogOpen = (e, id) => {
    e.stopPropagation()
    setSegmentDeleteId(id)
    setSegmentDeleteDialogOpen(true)
  }

  const handleSegmentDeleteDialogClose = () => {
    setSegmentDeleteId('')
    setSegmentDeleteDialogOpen(false)
  }

  const handleSegmentEdit = async (id) => {
    await updateDocument(firebase, FIRESTORE_COLLECTIONS.SEGMENTS, id, { name: segmentName })
    setSegmentEditId(null)
  }

  const handleSegmentDelete = async (id) => {
    setSegmentDeleteId(null)
    setSegmentDeleteDialogOpen(false)

    // Delete the document from Firestore
    await deleteDocument(firebase, FIRESTORE_COLLECTIONS.SEGMENTS, id)
  }

  const handleSegmentsDragReorder = (result) => {
    if (!result.destination) {
      return
    }

    let newSegments = Array.from(segments)
    const [removed] = newSegments.splice(result.source.index, 1)
    newSegments.splice(result.destination.index, 0, removed)

    onSegmentsReorder(newSegments)
  }

  const liveModeAvailable = useMemo(() => {
    // Live mode is only available when today is in the date range
    return moment.tz(TIMEZONE).isBetween(startDate, endDate, 'day', '[]')
  })

  return (
    <Box width='100%' paddingBottom={1}>
      <Box display='flex' flexDirection='row' alignItems='center' paddingBottom={2}>
        {/* Title and help content */}
        <Box
          display='flex'
          flexDirection='row'
          alignItems='flex-start'
          columnGap={0.25}
          sx={{
            flexGrow: 1,
            flexBasis: 'auto',
            flexShrink: 1,
          }}
        >
          {report.kind === 'saved' && (
            <BookmarkBorderIcon fontSize='small' color='default' />
          )}
          <Box>
            <Typography
              variant='h6'
              fontWeight='bold'
              sx={{
                lineHeight: 1.1
              }}
            >
              {report.name}
            </Typography>
            {report.kind === 'saved' && (
              <Typography
                variant='body1'
                color='textSecondary'
                fontSize={14}
              >
                {CORE_REPORTS.find(r => r.key === report.key).name}
              </Typography>
            )}
          </Box>
          <IconButton onClick={handleHelpPopoverOpen} size='small'>
            <HelpOutlineIcon fontSize='inherit' />
          </IconButton>
          {report.kind === 'saved' && (
            <React.Fragment>
              <IconButton
                size='small'
                onClick={handleReportMenuOpen}>
                <MoreHorizIcon fontSize='inherit' />
              </IconButton>
              <Menu
                anchorEl={reportMenuAnchorEl}
                open={Boolean(reportMenuAnchorEl)}
                onClose={handleReportMenuClose}
              >
                <MenuItem onClick={handleReportDeleteDialogOpen}>Delete Report</MenuItem>
              </Menu>
            </React.Fragment>
          )}

          <Backdrop
            open={Boolean(helpAnchorEl)}
            sx={{
              zIndex: theme => theme.zIndex.modal
            }}
          >
            <Popover
              open={Boolean(helpAnchorEl)}
              anchorEl={helpAnchorEl}
              onClose={handleHelpPopoverClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              PaperProps={{
                style: {
                  width: 500,
                  maxWidth: '80%',
                }
              }}
            >
              <Box sx={{ padding: theme => theme.spacing(2, 2) }}>
                <Typography variant='h6' style={{ fontWeight: 600 }}>
                  {report.name}
                </Typography>
                <Typography variant='body1' paragraph>
                  {report.description}
                </Typography>
              </Box>
            </Popover>
          </Backdrop>
        </Box>

        {/* Other Controls */}
        <Box
          display='flex'
          alignItems='center'
          columnGap={1}
          sx={{
            flexGrow: 0,
            flexShrink: 0,
          }}
        >
          <Box>
            <DarkTooltip
              title={
                liveModeAvailable ? (
                  liveMode ?
                    'Disable live mode'
                    : 'Enable live mode (update every minute)'
                ) : 'Live mode is only available when today is in date range'
              }
              placement='bottom'
              enterDelay={liveModeAvailable ? 500 : 0}
              arrow
            >
              <span>
                <Button
                  variant={liveMode ? 'contained' : 'outlined'}
                  color='secondary'
                  size='small'
                  disableElevation
                  onClick={() => onLiveModeChange(!liveMode)}
                  sx={{
                    height: 29,
                    minWidth: 'auto',
                  }}
                  disabled={!liveModeAvailable}
                >
                  {liveMode ?
                    <SyncIcon fontSize='small' />
                    : <SyncDisabledIcon fontSize='small' />
                  }
                </Button>
              </span>

            </DarkTooltip>
          </Box>

          <Button
            variant='contained'
            color='secondary'
            size='small'
            disableElevation
            onClick={() => setReportSaveDialogOpen(true)}
            sx={{
              fontSize: 12,
              fontWeight: 600,
            }}
          >
            Save Report
          </Button>

          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            resolution={resolution}
            onDatesChange={onDatesChange}
            anchorDirection={'right'}
            allowJumping={true}
            allowAdjusting={report.xKey === 'date_time'}
          />
        </Box>
      </Box>

      <Box display='flex' flexDirection='row' alignItems='center' columnGap={1} flexWrap='wrap' paddingBottom={1}>
        <ButtonGroup
          variant='contained'
          size='small'
          disableElevation
          ref={segmentAnchorRef}
          sx={{ height: 29 }}
        >
          <Button
            color='secondary'
            onClick={() => onSegmentAdd()}
            sx={{
              borderRightWidth: '0px !important',
              fontSize: 12,
              fontWeight: 600,
            }}
            startIcon={<AddIcon />}
          >
            Segment
          </Button>
          {report.allowSegmentQuickAdd && (
            <Button
              onClick={handleSegmentsSelectOpen}
              sx={{
                backgroundColor: theme => alpha(theme.palette.secondary.main, 0.9),
                '&:hover': {
                  backgroundColor: theme => theme.palette.secondary.dark,
                },
              }}
            >
              <ArrowDropDownIcon fontSize='small' />
            </Button>
          )}
        </ButtonGroup>
        <Popover
          open={segmentsMenuOpen}
          anchorEl={segmentAnchorRef.current}
          onClose={handleSegmentsSelectClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          TransitionProps={{
            onEntered: () => {
              if (segmentInputRef.current) {
                segmentInputRef.current.focus()
              }
            }
          }}
        >
          <Box width={300} sx={{ padding: theme => theme.spacing(1, 1)}}>
            <Autocomplete
              id='segment-autocomplete-simple'
              size='small'
              options={savedSegments.sort((a, b) => a.name.localeCompare(b.name))}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              onChange={(_, option) => handleSelectSegmentValue(option)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputRef={segmentInputRef}
                  placeholder='Search my segments'
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option.id} style={{ justifyContent: 'flex-start' }}>
                  <Box width='100%' display='flex' flexDirection='row' alignItems='center'>
                    <SegmentIcon style={{ fontSize: 16, marginRight: 8 }} />
                    <Typography variant='body2'>
                      {option.name}
                    </Typography>

                    <div style={{ flexGrow: 1 }} />

                    <IconButton
                      onClick={(e) => handleSegmentEditDialogOpen(e, option.id)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={(e) => handleSegmentDeleteDialogOpen(e, option.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </li>
              )}
              noOptionsText='No matching segments'
              autoHighlight
              openOnFocus
              fullWidth
            />
          </Box>
        </Popover>

        <Config
          config={config}
          configItems={report.configItems}
          onConfigChange={onConfigChange}
        />
      </Box>

      {/* Segments rows */}
      <DragDropContext onDragEnd={handleSegmentsDragReorder}>
        <Droppable droppableId='segments'>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {segments.map((segment, index) => (
                <Draggable key={segment.id} draggableId={segment.id} index={index}>
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      display='flex'
                      flexDirection='row'
                      paddingBottom={0.5}
                    >
                      <SegmentBuilder
                        key={segment.id}
                        config={config}
                        splitSegmentFrontBack={report.splitSegmentFrontBack}
                        allowBreakdown={true}
                        allowCohorts={report.allowCohorts}
                        allowOneSegmentOnly={false}
                        segment={segment}
                        segmentEligibleBreakdownKeys={segmentsEligibleBreakdownKeys[index]}
                        segments={segments}
                        aliases={aliases}
                        savedSegments={savedSegments}
                        savedGroups={savedGroups}
                        onSegmentDelete={onSegmentDelete}
                        onSegmentDuplicate={onSegmentDuplicate}
                        onSegmentEditChange={onSegmentEditChange}
                        onSegmentSaveEdit={onSegmentSaveEdit}
                        onSegmentRevertEdit={onSegmentRevertEdit}
                        onSegmentColorChange={onSegmentColorChange}
                        onSegmentFilterAdd={onSegmentFilterAdd}
                        onSegmentReplaceFiltersWithSavedSegmentFilter={onSegmentReplaceFiltersWithSavedSegmentFilter}
                        onSegmentFilterDelete={onSegmentFilterDelete}
                        onFilterAdd={onFilterAdd}
                        onFilterUpdate={onFilterUpdate}
                        onFilterDelete={onFilterDelete}
                        onClearAllSegmentFilters={onClearAllSegmentFilters}
                        onSegmentBreakdownSelect={onSegmentBreakdownSelect}
                        onSegmentBreakdownColorChange={onSegmentBreakdownColorChange}
                        dragHandleProps={provided.dragHandleProps}
                      />
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {/* Edit segment Dialog */}
      {segmentEditId && (
        <Dialog open={segmentEditDialogOpen} onClose={handleSegmentEditDialogClose} maxWidth='xs' fullWidth>
          <DialogTitle>Edit Segment</DialogTitle>
          <DialogContent
            sx={{ padding: theme => theme.spacing(1, 3, 0, 3)}}
          >
            <TextField
              autoFocus
              size='small'
              margin='dense'
              label='Segment Name'
              type='text'
              fullWidth
              value={segmentName}
              onChange={e => setSegmentName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button variant='text' onClick={handleSegmentEditDialogClose} color='error'>
              Cancel
            </Button>
            <Button variant='text' disabled={segmentName.length === 0} onClick={() => handleSegmentEdit(segmentEditId)} color='secondary'>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Delete segment confirmation dialog */}
      {segmentDeleteId && (
        <Dialog open={segmentDeleteDialogOpen} onClose={handleSegmentDeleteDialogClose} maxWidth='xs' fullWidth>
          <DialogTitle>Delete Segment?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete the segment <b>{savedSegments.find(s => s.id === segmentDeleteId).name}</b>?
              This will remove the segment from everywhere it is used, which may cause saved reports to break. Only delete
              this segment if you are absolutely sure you no longer need it. It is recommended that you clear your active
              segments in the report before deleting this group to prevent unexpected errors.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSegmentDeleteDialogClose} color='primary'>
              Cancel
            </Button>
            <Button onClick={() => handleSegmentDelete(segmentDeleteId)} variant='outlined' color='error'>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Delete report confirmation dialog */}
      {reportDeleteDialogOpen && (
        <Dialog open={reportDeleteDialogOpen} onClose={handleReportDeleteDialogClose} maxWidth='xs' fullWidth>
          <DialogTitle>Delete Report?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete your saved report <b>{report.name}</b>?
              This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleReportDeleteDialogClose} color='primary'>
              Cancel
            </Button>
            <Button onClick={() => handleReportDelete(report.id)} variant='outlined' color='error'>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Save report Dialog */}
      {reportSaveDialogOpen && (
        <ReportDialog
          open={reportSaveDialogOpen}
          onClose={() => setReportSaveDialogOpen(false)}
          editId={report.id}
          report={report}
          startDate={startDate}
          endDate={endDate}
          onReportSave={onReportSave}
        />
      )}
    </Box>
  )
}

Controls.propTypes = {
  report: PropTypes.object.isRequired,
  savedGroups: PropTypes.array.isRequired,
  savedSegments: PropTypes.array.isRequired,
  liveMode: PropTypes.bool.isRequired,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  resolution: PropTypes.number.isRequired,
  config: PropTypes.object.isRequired,
  segments: PropTypes.array.isRequired,
  segmentsEligibleBreakdownKeys: PropTypes.array.isRequired,
  aliases: PropTypes.object.isRequired,
  onReportSave: PropTypes.func.isRequired,
  onReportDelete: PropTypes.func.isRequired,
  onLiveModeChange: PropTypes.func.isRequired,
  onDatesChange: PropTypes.func.isRequired,
  onConfigChange: PropTypes.func.isRequired,
  onSegmentAdd: PropTypes.func.isRequired,
  onSegmentDelete: PropTypes.func.isRequired,
  onSegmentDuplicate: PropTypes.func.isRequired,
  onSegmentEditChange: PropTypes.func.isRequired,
  onSegmentSaveEdit: PropTypes.func.isRequired,
  onSegmentRevertEdit: PropTypes.func.isRequired,
  onSegmentColorChange: PropTypes.func.isRequired,
  onSegmentFilterAdd: PropTypes.func.isRequired,
  onSegmentReplaceFiltersWithSavedSegmentFilter: PropTypes.func.isRequired,
  onSegmentFilterDelete: PropTypes.func.isRequired,
  onSegmentsReorder: PropTypes.func.isRequired,
  onFilterAdd: PropTypes.func.isRequired,
  onFilterUpdate: PropTypes.func.isRequired,
  onFilterDelete: PropTypes.func.isRequired,
  onClearAllSegmentFilters: PropTypes.func.isRequired,
  onSegmentBreakdownSelect: PropTypes.func.isRequired,
  onSegmentBreakdownColorChange: PropTypes.func.isRequired,
}

export default Controls