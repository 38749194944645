import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Popover from '@mui/material/Popover'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Select from '@mui/material/Select'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import PersonIcon from '@mui/icons-material/Person'
import SegmentIcon from '@mui/icons-material/Segment'
import WorkspacesIcon from '@mui/icons-material/Workspaces'
import { alpha } from '@mui/material/styles'
import { useQuery, useQueryClient } from 'react-query'

import { FirebaseContext } from '../../utils/firebase'
import { createDocument, deleteDocument, readDocument, updateDocument } from '../../utils/firestore'

import DarkTooltip from './DarkTooltip'
import GroupBuilderDialog from './GroupBuilderDialog'

import { API_ROOT_URL, CONTACT_FIELDS, FIRESTORE_COLLECTIONS, PAYMENT_FIELDS } from '../../constants'

const typeItems = [
  {
    id: 'payment',
    name: 'Sale Data',
    icon: <AttachMoneyIcon fontSize='small' />
  },
  {
    id: 'contact',
    name: 'Contact Source',
    icon: <PersonIcon fontSize='small' />
  }
]

const filterFieldItems = {
  payment: PAYMENT_FIELDS,
  contact: CONTACT_FIELDS,
}

const operatorItems = [
  {
    id: 'any',
    name: 'is one of'
  },
  {
    id: 'none',
    name: 'is none of'
  },
]

const AddFilterPopover = (props) => {
  const {
    savedGroups,
    savedSegments,
    segment,
    disableSaleFilter,
    onFilterAdd,
    onFilterUpdate,
    onSegmentFilterAdd,
    isEditMode,
    editAnchorEl,
    setEditAnchorEl,
    editFilterInitial,
    editFilterIndex,
    kind,
  } = props
  const queryClient = useQueryClient()
  const firebase = React.useContext(FirebaseContext)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [tabValue, setTabValue] = React.useState(0)
  const [type, setType] = React.useState(() => {
    if (isEditMode && editFilterInitial) {
      return typeItems.find(t => t.id === editFilterInitial.type.id)
    } else {
      let defaultType = disableSaleFilter ? 'contact' : 'payment'
      return typeItems.find(t => t.id === defaultType)
    }
  })
  const [filterField, setFilterField] = React.useState(() => {
    if (isEditMode && editFilterInitial) {
      return filterFieldItems[editFilterInitial.type.id].find(f => f.id === editFilterInitial.field)
    }
    else return null
  })
  const [filterFieldInput, setFilterFieldInput] = React.useState('')
  const [operator, setOperator] = React.useState(() => {
    if (isEditMode && editFilterInitial) {
      return operatorItems.find(o => o.id === editFilterInitial.operator.id)
    } else {
      return operatorItems.find(o => o.id === 'any')
    }
  })
  const [filterValues, setFilterValues] = React.useState([])
  const [filterValuesInput, setFilterValuesInput] = React.useState('')
  const [addToAllSegments, setAddToAllSegments] = React.useState(false)
  const [segmentValue, setSegmentValue] = React.useState(null)
  const [segmentValueInput, setSegmentValueInput] = React.useState('')

  const [groupEditId, setGroupEditId] = React.useState(null)
  const [groupDeleteId, setGroupDeleteId] = React.useState(null)
  const [groupUpsertDialogOpen, setGroupUpsertDialogOpen] = React.useState(false)
  const [groupDeleteDialogOpen, setGroupDeleteDialogOpen] = React.useState(false)

  const [segmentName, setSegmentName] = React.useState('')
  const [segmentEditId, setSegmentEditId] = React.useState(null)
  const [segmentDeleteId, setSegmentDeleteId] = React.useState(null)
  const [segmentUpsertDialogOpen, setSegmentUpsertDialogOpen] = React.useState(false)
  const [segmentDeleteDialogOpen, setSegmentDeleteDialogOpen] = React.useState(false)

  const filterFieldRef = React.useRef()
  const filterValuesRef = React.useRef()
  const segmentValueRef = React.useRef()

  const { isLoading, data: aliasesData } = useQuery(['aliases', filterField?.id], () => {
    if (!filterField) return null
    return firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api2/fields/${filterField.id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then(res => res.json())
    })},
    {
      cacheTime: 15 * 60 * 1000,  // 15 minutes
      staleTime: 15 * 60 * 1000,  // 15 minutes
      refetchOnWindowFocus: false,
    }
  )

  const filterOptions = useMemo(() => {
    if (!aliasesData) return []
    return Object.values(aliasesData.aliases)
  }, [aliasesData])

  const filterValueOptions = filterField ? [
    ...(savedGroups ? savedGroups.filter(g => g.filter.field === filterField.id).map(g => ({...g, type: 'group', group: 'My Groups'})).sort((a, b) => -b.name.localeCompare(a.name)) : []),
    ...(filterOptions ? filterOptions.map(f => ({...f, type: 'item', group: 'All Values'})).sort((a, b) => -b.name.localeCompare(a.name)) : []),
  ] : []

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (reason) => {
    // Prevent closing the popover when clicking on the backdrop
    if (reason === 'backdropClick') return

    if (isEditMode) setEditAnchorEl(null)
    else setAnchorEl(null)
    let defaultType = disableSaleFilter ? 'contact' : 'payment'
    setType(typeItems.find(t => t.id === defaultType))
    setFilterFieldInput('')
    setFilterField(null)
    setOperator(null)
    setFilterValuesInput('')
    setFilterValues([])
    setAddToAllSegments(false)
    setSegmentValueInput('')
    setSegmentValue(null)
    setTabValue(0)
  }

  const handleFilterSelect = (filter) => {
    setFilterField(filter)
    setFilterValuesInput('')
    setFilterValues([])
    setOperator(operatorItems.find(c => c.id === 'any'))
  }

  const handleValueSelect = (newValue) => {
    setFilterValues(newValue)
    setFilterValuesInput(filterValuesInput)
  }

  const handleGroupUpsertDialogOpen = (e, id=null) => {
    e.stopPropagation()
    setGroupEditId(id)
    setGroupUpsertDialogOpen(true)
  }

  const handleGroupUpsertDialogClose = () => {
    setGroupUpsertDialogOpen(false)
  }

  const handleGroupDeleteDialogOpen = (e, id) => {
    e.stopPropagation()
    setGroupDeleteId(id)
    setGroupDeleteDialogOpen(true)
  }

  const handleGroupDeleteDialogClose = () => {
    setGroupDeleteId('')
    setGroupDeleteDialogOpen(false)
  }

  const handleSegmentValueSelect = (newSegmentValue) => {
    setSegmentValue(newSegmentValue)
    setSegmentValueInput(segmentValueInput)
  }

  const handleSegmentEditDialogOpen = (e, id) => {
    e.stopPropagation()
    setSegmentName(savedSegments.find(g => g.id === id).name)
    setSegmentEditId(id)
    setSegmentUpsertDialogOpen(true)
  }

  const handleSegmentEditDialogClose = () => {
    setSegmentName('')
    setSegmentUpsertDialogOpen(false)
  }

  const handleSegmentDeleteDialogOpen = (e, id) => {
    e.stopPropagation()
    setSegmentDeleteId(id)
    setSegmentDeleteDialogOpen(true)
  }

  const handleSegmentDeleteDialogClose = () => {
    setSegmentDeleteId('')
    setSegmentDeleteDialogOpen(false)
  }

  const handleFilterAdd = () => {
    const newFilter = formatFilterObjectFromState()
    if (addToAllSegments) onFilterAdd(null, newFilter)
    else onFilterAdd(segment.id, newFilter)
    handleClose()
  }

  const handleFilterUpdate = () => {
    const updatedFilter = formatFilterObjectFromState()
    onFilterUpdate(segment.id, editFilterIndex, updatedFilter)
    handleClose()
  }

  const handleSegmentFilterAdd = () => {
    const newSegmentFilter = formatSegmentFilterObjectFromState()
    onSegmentFilterAdd(segment.id, newSegmentFilter)
    handleClose()
  }

  const handleGroupUpsert = async (editId=null, groupName, builderFilterValues) => {
    // Add the type = 'item' to builderFilterValues so they are properly formatted
    if (builderFilterValues) {
      builderFilterValues = builderFilterValues.map(f => ({ ...f, type: 'item' }))
    }
    const newFilter = formatFilterObjectFromState(builderFilterValues)
    if (editId) {
      await updateDocument(firebase, FIRESTORE_COLLECTIONS.GROUPS, editId, { name: groupName, filter: newFilter })
      // Find group in filterValues and replace name
      if (filterValues.find(v => v.id === editId)) {
        const newValue = filterValues.map(v => v.id === editId ? { ...v, name: groupName } : v)
        setFilterValues(newValue)
      }
    } else {
      const groupData = { name: groupName, filter: newFilter }
      const newGroupId = await createDocument(firebase, FIRESTORE_COLLECTIONS.GROUPS, groupData)
      const newGroup = await readDocument(firebase, FIRESTORE_COLLECTIONS.GROUPS, newGroupId)

      // Replace selected items in filterValues with the new group
      setFilterValues([{...newGroup, type: 'group', group: 'My Groups'}])
    }

    setGroupEditId(null)
    setGroupUpsertDialogOpen(false)
  }

  const handleGroupDelete = async (id) => {
    // Remove the deleted group from the filter if it is selected
    const newValue = filterValues.filter(v => v.id !== id)
    setFilterValues(newValue)

    setGroupDeleteId(null)
    setGroupDeleteDialogOpen(false)

    // Delete the document from Firestore
    await deleteDocument(firebase, FIRESTORE_COLLECTIONS.GROUPS, id)

    // Force a refresh of the queries because the group may have been
    // in use as part of a saved segment, and now it is no longer part of that segment
    queryClient.refetchQueries()
  }

  const handleSegmentEdit = async (id) => {
    await updateDocument(firebase, FIRESTORE_COLLECTIONS.SEGMENTS, id, { name: segmentName })
    if (segmentValue?.id === id) setSegmentValue({ id, name: segmentName })

    setSegmentName('')
    setSegmentEditId(null)
    setSegmentUpsertDialogOpen(false)
  }

  const handleSegmentDelete = async (id) => {
    // Remove the deleted group from the filter if it is selected
    if (segmentValue?.id === id) setSegmentValue(null)

    setSegmentDeleteId(null)
    setSegmentDeleteDialogOpen(false)

    // Delete the document from Firestore
    await deleteDocument(firebase, FIRESTORE_COLLECTIONS.SEGMENTS, id)
  }

  const formatFilterObjectFromState = (editFilterValues=null) => {
    // editFilterValues is used when editing an existing group from the GroupBuilderDialog
    let theseFilterValues = filterValues
    if (editFilterValues) theseFilterValues = editFilterValues

    let newFilter = {
      type: { id: type.id, name: type.name },
      field: filterField.id,
      fieldName: filterField.name,
      itemValues: [],
      itemNames: [],
      groupIds: [],
      groupNames: [],
      operator,
      kind,
    }

    const itemFilters = theseFilterValues.filter(f => f.type === 'item')
    const groupFilters = theseFilterValues.filter(f => f.type === 'group')

    // Add standard filters to this value
    itemFilters.forEach(f => {
      newFilter.itemValues.push(f.id)
      newFilter.itemNames.push(f.name)
    })

    // Flatten groups into normal filters
    groupFilters.forEach(g => {
      newFilter.groupIds.push(g.id)
      newFilter.groupNames.push(g.name)
    })

    return newFilter
  }

  const formatSegmentFilterObjectFromState = () => {
    let newSegmentFilter = {
      id: segmentValue.id,
      name: segmentValue.name,
      kind,
    }

    return newSegmentFilter
  }

  React.useEffect(() => {
    let defaultType = disableSaleFilter ? 'contact' : 'payment'
    setType(typeItems.find(t => t.id === defaultType))
  }, [disableSaleFilter])

  React.useEffect(() => {
    if (filterField && tabValue === 0 && !isEditMode) filterValuesRef.current.focus()
  }, [filterField, tabValue])

  React.useEffect(() => {
    if (tabValue === 1) segmentValueRef.current.focus()
  }, [tabValue])

  React.useEffect(() => {
    if (isEditMode) {
      setType(typeItems.find(t => t.id === editFilterInitial.type.id))
      setFilterValues(filterValueOptions.filter(f => editFilterInitial.itemValues.includes(f.id) || editFilterInitial.groupIds.includes(f.id)))
    }
  }, [editFilterInitial])

  return (
    <Box>
      {!isEditMode && (
        <Button
          variant='text'
          size='small'
          color='secondary'
          onClick={handleClick}
        >
          Filter
        </Button>
      )}
      <Popover
        sx={{
          marginTop: theme => theme.spacing(1),
        }}
        open={isEditMode ? Boolean(editAnchorEl) : Boolean(anchorEl)}
        anchorEl={isEditMode ? editAnchorEl : anchorEl}
        onClose={(_, reason) => handleClose(reason)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        TransitionProps={{
          onEntered: () => {
            if (tabValue === 0 && !isEditMode) filterFieldRef.current.focus()
            else if (tabValue === 1) segmentValueRef.current.focus()
          }
        }}
      >
        <Box width={400} sx={{ padding: theme => theme.spacing(0, 2, 1, 2)}}>
          <Box display='flex' flexDirection='row' width='100%' alignItems='center'>
            <Tabs
              value={tabValue}
              onChange={(_, value) => setTabValue(value)}
              textColor='secondary'
              indicatorColor='secondary'
              sx={{
                marginTop: theme => theme.spacing(1),
                marginBottom: theme => theme.spacing(2),
                height: '40px', // Set height
                minHeight: '40px', // Set minHeight
                '& .MuiButtonBase-root': {
                  height: '40px', // Set height
                  minHeight: '40px', // Set minHeight
                },
              }}
            >
              <Tab
                label='Data'
                sx={{
                  padding: theme => theme.spacing(0, 1),
                  borderTopLeftRadius: theme => theme.shape.borderRadius,
                  borderTopRightRadius: theme => theme.shape.borderRadius,
                  backgroundColor: theme => tabValue === 0 ? alpha(theme.palette.secondary.main, 0.05) : 'transparent',
                  '&:hover': {
                    backgroundColor: theme => alpha(theme.palette.secondary.main, 0.025)
                  },
                  textTransform: 'none',
                }}
              />
              {!isEditMode && (
                <Tab
                  label='Segment'
                  sx={{
                    padding: theme => theme.spacing(0, 1),
                    borderTopLeftRadius: theme => theme.shape.borderRadius,
                    borderTopRightRadius: theme => theme.shape.borderRadius,
                    backgroundColor: theme => tabValue === 1 ? alpha(theme.palette.secondary.main, 0.05) : 'transparent',
                    '&:hover': {
                      backgroundColor: theme => alpha(theme.palette.secondary.main, 0.025)
                    },
                    textTransform: 'none',
                  }}
                />
              )}
            </Tabs>

            <div style={{ flexGrow: 1 }} />

            <Box>
              <IconButton
                size='small'
                onClick={handleClose}
                sx={{
                  alignSelf: 'flex-start',
                }}
              >
                <CloseIcon fontSize='inherit' />
              </IconButton>
            </Box>
          </Box>



          {/* Data Tab */}
          {tabValue === 0 && (
            <Box marginTop={1}>
              {/* Type select */}
              <FormControl size='small' fullWidth>
                <InputLabel id='type-select-label'>Type</InputLabel>
                <Select
                  labelId='type-select-label'
                  id='type-select'
                  label='Type'
                  value={type ? type.id : ''}
                  onChange={(event) => setType(typeItems.find(t => t.id === event.target.value))}
                  renderValue={(value) => typeItems.find(t => t.id === value).name}
                  sx={{
                    '& .MuiSelect-icon': {
                      right: '9px'
                    }
                  }}
                >
                  {typeItems.map(item =>
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      disabled={item.id === 'payment' && disableSaleFilter}
                    >
                      <Box display='flex' alignItems='center' columnGap={1}>
                        {item.icon}
                        <Typography variant='inherit' noWrap>
                          {item.name}
                        </Typography>
                      </Box>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>

              {/* Filter select */}
              <Autocomplete
                id='filter-autocomplete'
                options={type ? filterFieldItems[type.id] : []}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionDisabled={option => segment.filters.filter(f => f.type.id === type.id && f.kind === kind).findIndex(filter => filter.field === option.id) > -1}
                getOptionLabel={option => option.name}
                value={filterField}
                onChange={(_, option) => handleFilterSelect(option)}
                inputValue={filterFieldInput}
                onInputChange={(_, value) => setFilterFieldInput(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputRef={filterFieldRef}
                    label='Filter By'
                    variant='outlined'
                    error={!filterField}
                  />
                )}
                noOptionsText='No matches'
                autoHighlight
                openOnFocus
                fullWidth
                size='small'
                sx={{ marginTop: theme => theme.spacing(2) }}
              />

              {/* Operator select */}
              <FormControl size='small' fullWidth sx={{ marginTop: theme => theme.spacing(2) }}>
                <InputLabel id='operator-select-label'>Operator</InputLabel>
                <Select
                  id='operator-select'
                  label='Operator'
                  value={operator ? operator.id : ''}
                  onChange={(event) => setOperator(operatorItems.find(c => c.id === event.target.value))}
                  renderValue={(value) => operatorItems.find(i => i.id === value).name}
                  sx={{
                    '& .MuiSelect-icon': {
                      right: '9px'
                    }
                  }}
                >
                  {operatorItems.map(item =>
                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                  )}
                </Select>
              </FormControl>

              {/* Filter value select */}
              <Autocomplete
                id='value-autocomplete'
                size='small'
                sx={{
                  marginTop: theme => theme.spacing(2),
                }}
                multiple
                options={filterValueOptions}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                groupBy={(option) => option.group}
                getOptionLabel={option => `${option.name}${option.group === 'All Values' && (filterField.id === 'product_id' || filterField.id === 'productVariant_id' || filterField.id === 'funnel_id' || filterField.id === 'integration_id') ? ` [${option.platform}, ID:${option.platform_id}]` : ''}`}
                filterOptions={createFilterOptions({
                  matchFrom: 'any',
                  stringify: (option) => `[${option.name} ${option.platform_id}]`
                })}
                value={filterValues}
                onChange={(_, option) => handleValueSelect(option)}
                onInputChange={(_, value, reason) => {
                  if (reason === 'reset') return
                  setFilterValuesInput(value)
                }}
                inputValue={filterValuesInput}
                renderInput={(params) => (
                  <TextField {...params}
                    inputRef={filterValuesRef}
                    label={'Values'}
                    variant='outlined'
                    placeholder={filterField ? `Select ${filterField.name}` : 'Select filter to load values'}
                    error={filterValues.length === 0}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isLoading ? <CircularProgress color='inherit' size={20} style={{ marginRight: '25px' }} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                  />
                )}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.id} style={{ justifyContent: 'flex-start' }}>
                    <Box width='100%' display='flex' flexDirection='row' alignItems='center'>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                        checkedIcon={<CheckBoxIcon fontSize='small' />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />

                      {option.type === 'group' && (
                        <WorkspacesIcon style={{ fontSize: 16, marginRight: 8 }} />
                      )}
                      <Box display='flex' flexDirection='column'>
                        <Typography variant='body2' display='block'>
                          {option.name}
                        </Typography>

                        {(option.type === 'item' && (filterField.id === 'product_id' || filterField.id === 'productVariant_id' || filterField.id === 'funnel_id' || filterField.id === 'integration_id')) && (
                            <Typography variant='caption' display='block' color='textSecondary'>
                              {option.platform}, ID:{option.platform_id}
                            </Typography>
                        )}
                      </Box>
                      <div style={{ flexGrow: 1 }} />
                      <Box display='flex' flexDirection='row'>
                        {option.type === 'group' && (
                          <>
                            <IconButton
                              onClick={(e) => handleGroupUpsertDialogOpen(e, option.id)}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              onClick={(e) => handleGroupDeleteDialogOpen(e, option.id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </>
                        )}
                      </Box>
                    </Box>
                  </li>
                )}
                loading={isLoading}
                noOptionsText='No matches'
                disableCloseOnSelect
                clearOnBlur={false}
                clearOnEscape={false}
                autoHighlight
                openOnFocus
                fullWidth
              />

              <Box width='100%'>
                <DarkTooltip
                  title={
                    filterValues.findIndex(v => v.group === 'My Groups') > -1 ?
                      'A group is already included in selection'
                      : (filterValues.length <= 1 ?
                        'Select multiple values to create a group'
                        : 'Create group from selected values'
                      )
                  }
                  arrow
                  placement='right'
                >
                  <span>
                    <Button
                      variant='text'
                      color='secondary'
                      onClick={(e) => handleGroupUpsertDialogOpen(e)}
                      disabled={filterValues.findIndex(v => v.group === 'My Groups') > -1 || filterValues.length <= 1}
                    >
                      Create {filterField ? `${filterField.name} ` : ' '}Group
                    </Button>
                  </span>
                </DarkTooltip>
              </Box>

              {!isEditMode && (
                <Box width='100%'>
                  <FormControlLabel
                    label='Add to all segments'
                    control={
                      <Checkbox
                        checked={addToAllSegments}
                        onChange={event => setAddToAllSegments(event.target.checked)}
                        color='primary'
                      />
                    }
                  />
                </Box>
              )}

              {/* Data action button row */}
              <Box display='flex' flexDirection='row' alignItems='center' marginTop={1}>
                <Button
                  sx={{
                    color: theme => theme.palette.error.main,
                    '&:hover': {
                      background: 'rgba(255,23,68,0.04)',
                    },
                  }}
                  variant='text'
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  variant='text'
                  color='secondary'
                  disabled={!type || !filterField || !operator || filterValues.length === 0}
                  onClick={isEditMode ? handleFilterUpdate : handleFilterAdd}
                >
                  {isEditMode ? 'Update' : 'Add'}
                </Button>
              </Box>
            </Box>
          )}

          {/* Segments Tab */}
          {tabValue === 1 && (
            <Box marginTop={1}>
              <Autocomplete
                id='segment-autocomplete'
                size='small'
                sx={{
                  marginTop: theme => theme.spacing(2),
                }}
                options={savedSegments.sort((a, b) => -b.name.localeCompare(a.name))}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionDisabled={option => segment.segmentFilters.findIndex(sf => sf.id === option.id) > -1}
                getOptionLabel={option => option.name}
                filterOptions={createFilterOptions({
                  matchFrom: 'any',
                  stringify: (option) => option.name
                })}
                value={segmentValue}
                onChange={(_, option) => handleSegmentValueSelect(option)}
                onInputChange={(_, value) => setSegmentValueInput(value)}
                inputValue={segmentValueInput}
                renderInput={(params) => (
                  <TextField {...params}
                    inputRef={segmentValueRef}
                    label={'Segment'}
                    variant='outlined'
                    placeholder={'Select Segment'}
                    error={!segmentValue}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isLoading ? <CircularProgress color='inherit' size={20} style={{ marginRight: '25px' }} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option.id} style={{ justifyContent: 'flex-start' }}>
                    <Box width='100%' display='flex' flexDirection='row' alignItems='center'>
                      <SegmentIcon style={{ fontSize: 16, marginRight: 8 }} />
                      <Typography variant='body2'>
                        {option.name}
                      </Typography>

                      <div style={{ flexGrow: 1 }} />
                      <IconButton
                        onClick={(e) => handleSegmentEditDialogOpen(e, option.id)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={(e) => handleSegmentDeleteDialogOpen(e, option.id, option.name)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </li>
                )}
                loading={isLoading}
                noOptionsText={savedSegments.length === 0 ? 'No saved segments' : 'No matches'}
                autoHighlight
                openOnFocus
                fullWidth
              />

              {/* Segment action button row */}
              <Box display='flex' flexDirection='row' justifyContent='flex-end' alignItems='center' marginTop={1}>
                <Button
                  sx={{
                    color: theme => theme.palette.error.main,
                    '&:hover': {
                      background: 'rgba(255,23,68,0.04)',
                    },
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  variant='text'
                  color='secondary'
                  disabled={!segmentValue}
                  onClick={handleSegmentFilterAdd}
                >
                  Add
                </Button>
              </Box>
            </Box>
          )}
        </Box>

        {/* Create and edit group dialog */}
        <GroupBuilderDialog
          open={groupUpsertDialogOpen}
          onClose={handleGroupUpsertDialogClose}
          groupEditId={groupEditId}
          filterField={filterField}
          selectedFilterValues={filterValues}
          filterOptions={filterOptions}
          savedGroups={savedGroups}
          onGroupUpsert={handleGroupUpsert}
        />

        {/* Create and edit segment dialog */}
        {segmentEditId && (
          <Dialog open={segmentUpsertDialogOpen} onClose={handleSegmentEditDialogClose} maxWidth='xs' fullWidth>
            <DialogTitle>Edit Segment</DialogTitle>
            <DialogContent
              sx={{ padding: theme => theme.spacing(1, 3, 0, 3)}}
            >
              <TextField
                autoFocus
                size='small'
                margin='dense'
                label='Segment Name'
                type='text'
                fullWidth
                value={segmentName}
                onChange={e => setSegmentName(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button variant='text' onClick={handleSegmentEditDialogClose} color='error'>
                Cancel
              </Button>
              <Button variant='text' onClick={() => handleSegmentEdit(segmentEditId)} color='secondary'>
                {segmentEditId ? 'Save' : 'Create'}
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {/* Delete group confirmation dialog */}
        {groupDeleteId && (
          <Dialog open={groupDeleteDialogOpen} onClose={handleGroupDeleteDialogClose} maxWidth='xs' fullWidth>
            <DialogTitle>Delete Group?</DialogTitle>
            <DialogContent>
              <DialogContentText paragraph>
                Are you sure you want to delete the group <b>{savedGroups.find(g => g.id === groupDeleteId).name}</b>?
              </DialogContentText>
              <DialogContentText paragraph>
                This will remove the group from everywhere it is used, such as in saved segments,
                which may cause saved reports to change unexpectedly.
              </DialogContentText>
              <DialogContentText paragraph>
                It is recommended that you look through your saved segments to see where this group is used
                and make updates to prevent unexpected outcomes.
              </DialogContentText>
              <DialogContentText paragraph>
                Only delete this group if you are absolutely sure you no longer need it.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleGroupDeleteDialogClose} color='primary'>
                Cancel
              </Button>
              <Button onClick={() => handleGroupDelete(groupDeleteId)} variant='outlined' color='error'>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {/* Delete segment confirmation dialog */}
        {segmentDeleteId && (
          <Dialog open={segmentDeleteDialogOpen} onClose={handleSegmentDeleteDialogClose} maxWidth='xs' fullWidth>
            <DialogTitle>Delete Segment?</DialogTitle>
            <DialogContent>
              <DialogContentText paragraph>
                Are you sure you want to delete the segment <b>{savedSegments.find(s => s.id === segmentDeleteId).name}</b>?
              </DialogContentText>
              <DialogContentText paragraph>
                This will remove the segment from everywhere it is used, which may cause saved reports to break. Only delete
                this segment if you are absolutely sure you no longer need it.
              </DialogContentText>
              <DialogContentText paragraph>
                It is recommended that you clear your active segments in the report before deleting this group to prevent unexpected errors.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleSegmentDeleteDialogClose} color='primary'>
                Cancel
              </Button>
              <Button onClick={() => handleSegmentDelete(segmentDeleteId)} variant='outlined' color='error'>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Popover>
    </Box>
  )
}

AddFilterPopover.defaultProps = {
  disableSaleFilter: false,
  kind: 'all',
}

AddFilterPopover.propTypes = {
  savedGroups: PropTypes.array.isRequired,
  savedSegments: PropTypes.array.isRequired,
  segment: PropTypes.object.isRequired,
  disableSaleFilter: PropTypes.bool,
  onFilterAdd: PropTypes.func,
  onFilterUpdate: PropTypes.func,
  onSegmentFilterAdd: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool,
  editAnchorEl: PropTypes.object,
  setEditAnchorEl: PropTypes.func,
  editFilterInitial: PropTypes.object,
  editFilterIndex: PropTypes.number,
  kind: PropTypes.oneOf(['all', 'frontend', 'backend']).isRequired,
}

export default AddFilterPopover
