import React from 'react'
import PropTypes from 'prop-types'
import Switch from '@mui/material/Switch'
import { alpha, styled } from '@mui/material/styles'

// eslint-disable-next-line no-unused-vars
const CustomColorSwitchInner = styled(({ colorHex, ...other }) => <Switch {...other} />)(({ theme, colorHex }) => ({
  '& .MuiSwitch-switchBase': {
    color: theme.palette.grey[400],
    '&:hover': {
      backgroundColor: alpha(theme.palette.grey[400], theme.palette.action.focusOpacity),
    },
  },
  '& .MuiSwitch-switchBase + .MuiSwitch-track': {
    backgroundColor: theme.palette.grey[500],
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: colorHex,
    '&:hover': {
      backgroundColor: alpha(colorHex ? colorHex : theme.palette.grey[400], theme.palette.action.focusOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: colorHex,
  },
}))

const CustomColorSwitch = (props) => {
  return (
    <CustomColorSwitchInner
      size='small'
      colorHex={props.colorHex}
      checked={props.checked}
      onChange={event => props.onChange(props.id, event.target.checked)}
    />
  )
}

CustomColorSwitch.defaultProps = {
  checked: false,
}

CustomColorSwitch.propTypes = {
  id: PropTypes.oneOfType([ PropTypes.string, PropTypes.number]),
  colorHex: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default CustomColorSwitch
