import React from 'react'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'
import { useHistory } from 'react-router-dom'

import Controls from '../Controls/PathAnalysis'
import Graphs from '../Graphs/PathAnalysis'
import PathAnalysisSummaryMetrics from '../Metrics/PathAnalysisSummaryMetrics'
import Table from '../Table/TablePathAnalysis'

import * as ROUTES from '../../../constants/routes'
import { ALL_DATA_KEY } from '../../../constants'
import { getColorPalette, stableSort } from '../../../utils/helpers'

const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: 66,
    right: 0,
    width: 'calc(100% - 66px)',
    height: '100vh',
    overflowY: 'auto',
    paddingTop: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
  },
  paper: {
    padding: theme.spacing(1, 2),
    backgroundColor: 'rgb(232, 244, 253)',
    color: 'rgb(13, 60, 97)',
    display: 'flex',
    alignItems: 'center',
  },
  typographyHeading: {
    marginLeft: theme.spacing(2),
  },
  typographySubheading: {
    marginLeft: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  exitButton: {
  }
}))

const PathAnalysis = () => {
  const classes = useStyles()
  const history = useHistory()
  const fromState = history.location.state || {}
  let startDateInitial = fromState.startDate ? moment.tz(fromState.startDate, TIMEZONE) : moment.tz(TIMEZONE).subtract(90, 'days').startOf('day')
  let endDateInitial = fromState.endDate ? moment.tz(fromState.endDate, TIMEZONE) : moment.tz(TIMEZONE).endOf('day')
  let filtersInitial = fromState.filters || []
  let attributionInitial = fromState.attribution || 'customer'
  if (fromState.addFilter) {
    filtersInitial = [fromState.addFilter, ...filtersInitial]
  }

  const [startDate, setStartDate] = React.useState(startDateInitial)
  const [endDate, setEndDate] = React.useState(endDateInitial)
  const [breakdown, setBreakdown] = React.useState(null)
  const [filters, setFilters] = React.useState(filtersInitial)
  const [attribution, setAttribution] = React.useState(attributionInitial)
  const [frontendType, setBackendType] = React.useState('order')
  const [extendBackend] = React.useState(true)
  const [order, setOrder] = React.useState('desc')
  const [orderBy, setOrderBy] = React.useState('ltv_gain')
  const [rows, setRows] = React.useState([])

  const handleDatesChange = ({ startDate: newStartDate, endDate: newEndDate }) => {
    setStartDate(newStartDate)
    setEndDate(newEndDate)
  }

  const handleBreakdownSelect = (breakdown) => {
    setBreakdown(breakdown)
  }

  const handleBreakdownDelete = () => {
    setBreakdown(null)
  }

  const handleFilterAdd = (filter) => {
    setFilters([filter, ...filters])
  }

  const handleFilterDelete = (filter) => {
    const index = filters.indexOf(filter)
    const newFilters = [...filters]
    newFilters.splice(index, 1)
    setFilters(newFilters)
  }

  const handleFilterDeleteAll = () => {
    setFilters([])
  }

  const handleAttributionSelect = (attribution) => {
    setAttribution(attribution)
  }

  const handleBackendTypeSelect = (frontendType) => {
    setBackendType(frontendType)
  }

  const handleExit = () => {
    history.push(ROUTES.ANALYSIS, history.location.state)
  }

  const getBackendColors = (sortedRows) => {
    // Don't return the first color to ensure no conflict with frontend color
    const colorsList = getColorPalette(sortedRows.filter(row => row.checked).length + 1).slice(1)

    let colors = {}
    let colorIndex = 0
    sortedRows.forEach(row => {
      if (row.checked) {
        colors[row.breakdown] = '#' + colorsList[colorIndex]
        colorIndex += 1
      } else {
        colors[row.breakdown] = null
      }
    })
    return colors
  }

  const frontendContext = {
    dates: {
      start: startDate,
      end: endDate,
    },
    breakdown: null,  // NOTE: there cannot be a frontend breakdown during path analysis
    filters,
  }
  const backendContext = {
    dates: {
      start: startDate,
      end: endDate,
    },
    breakdown,  // NOTE: the breakdown is applied to the backend only
    filters,
  }
  const sortedRows = stableSort(rows, order, orderBy)

  // The only frontend color is the ALL data line
  const frontendColors = { [ALL_DATA_KEY]: '#' + getColorPalette(1)[0] }
  const backendColors = getBackendColors(sortedRows)

  return (
    <Container className={classes.root} maxWidth='xl'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.paper} square>
            <Button
              className={classes.exitButton}
              variant='outlined'
              size='small'
              onClick={handleExit}
              startIcon={<ArrowBackIcon />}
            >
              Exit
            </Button>
            <Typography className={classes.typographyHeading}>
              Path Analysis Mode
            </Typography>
            <Typography className={classes.typographySubheading} variant='body2'>
              analyze backend buying behavior of a group of customers to see how LTV and LTR increased
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Controls
            startDate={startDate}
            endDate={endDate}
            onDatesChange={handleDatesChange}
            breakdown={breakdown}
            onBreakdownSelect={handleBreakdownSelect}
            onBreakdownDelete={handleBreakdownDelete}
            breakdownLabel='Backend Breakdown'
            filters={filters}
            onFilterAdd={handleFilterAdd}
            onFilterDelete={handleFilterDelete}
            onFilterDeleteAll={handleFilterDeleteAll}
            filterLabel='Frontend Filter'
            attribution={attribution}
            onAttributionSelect={handleAttributionSelect}
            frontendType={frontendType}
            onBackendTypeSelect={handleBackendTypeSelect}
          />
        </Grid>
        <Grid item xs={12}>
          <Graphs
            attribution={attribution}
            frontendContext={frontendContext}
            backendContext={backendContext}
            frontendType={frontendType}
            extendBackend={extendBackend}
            frontendColors={frontendColors}
            backendColors={backendColors}
          />
        </Grid>
        <Grid item xs={12}>
          <PathAnalysisSummaryMetrics
            attribution={attribution}
            context={frontendContext}
            frontendType={frontendType}
            extendBackend={extendBackend}
          />
        </Grid>
        <Grid item xs={12}>
          <Table
            attribution={attribution}
            context={backendContext}
            frontendType={frontendType}
            extendBackend={extendBackend}
            order={order}
            orderBy={orderBy}
            rows={sortedRows}
            colors={backendColors}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            setRows={setRows}
            onFilterAdd={handleFilterAdd}
          />
        </Grid>
      </Grid>
    </Container>
  )
}

export default PathAnalysis
