import React from 'react'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import Skeleton from '@mui/material/Skeleton'
import Snackbar from '@mui/material/Snackbar'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'

import { useHistory, useParams } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'

import { FirebaseContext } from '../../utils/firebase'
import 'firebase/auth'

import { getFrequencyFromUnixCronSchedule } from '../../utils/helpers'
import { API_ROOT_URL } from '../../constants/'
import * as ROUTES from '../../constants/routes'

import paypalLogoImg from '../../media/paypal_logo.png'

const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: 66,
    right: 0,
    width: 'calc(100% - 66px)',
    height: '100vh',
    overflowY: 'auto',
    backgroundColor: theme.palette.grey[50],
  },
  logoImg: {
    width: 100,
  },
  syncButton: {
    width: 110,
  },
  redButton: {
    color: theme.palette.error.main,
    '&:hover': {
      background: 'rgba(255,23,68,0.04)',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250
  },
}))

const REVOKE_SUCCESS_MESSAGE = 'Account removed. Data will no longer be synced from this account. Redirecting back to settings...'
const SYNC_SUCCESS_MESSAGE = 'Sync initialized. Processing may take a while, and you can safely leave this page.'
const SYNC_ERROR_MESSAGE = 'Sync failed to initialize. Try again and contact support if error persists.'


const dataTypeMenuItems = [
  { value: 'all', display: 'All Transactions'},
  { value: 'checkout', display: 'Checkout'},
  { value: 'subscriptions', display: 'Subscriptions'},
]

const dateRangeMenuItems = [
  { value: 'last_7_days', display: 'Last 7 days'},
  { value: 'last_30_days', display: 'Last 30 days'},
  { value: 'last_3_months', display: 'Last 3 months'},
  { value: 'last_6_months', display: 'Last 6 months'},
  { value: 'last_12_months', display: 'Last 12 months'},
  { value: 'last_2_years', display: 'Last 2 years'},
  { value: 'last_3_years', display: 'Last 3 years'},
  { value: 'ytd', display: 'Year to date'},
  { value: 'last_and_this_year', display: 'Last year and this year'},
]

const syncFrequencyMenuItems = [
  { value: 'hourly', display: 'Hourly'},
  { value: 'daily', display: 'Daily'},
  { value: 'weekly', display: 'Weekly'},
  { value: 'monthly', display: 'Monthly'},
]

const PaypalSource = () => {
  const classes = useStyles()
  const { id } = useParams()
  const history = useHistory()
  const firebase = React.useContext(FirebaseContext)
  const [message, setMessage] = React.useState('')
  const [dataType, setDataType] = React.useState('checkout')
  const [dateRange, setDateRange] = React.useState('last_2_years')
  const [showMessage, setShowMessage] = React.useState(false)
  const [showRevokeConfirmation, setShowRevokeConfirmation] = React.useState(false)
  const [messageSeverity, setMessageSeverity] = React.useState('success')

  const [startDate, setStartDate] = React.useState('')
  const [endDate, setEndDate] = React.useState('')
  const [syncFrequency, setSyncFrequency] = React.useState('daily')

  React.useEffect(() => {
    setDatesFromRange(dateRange)
  }, [dateRange])

  const {
    isLoading: isLoadingIntegration,
    data: integration = {}
  } = useQuery(['integration-get', id], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      }).then(res => res.json())
    }),
    {
      cacheTime: 0,
      staleTime: 0,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data.schedule) {
          const frequency = getFrequencyFromUnixCronSchedule(data.schedule)
          setSyncFrequency(frequency)
        }
        if (data.data_type) {
          setDataType(data.data_type)
        }
      }
    }
  )

  const {
    isLoading: isLoadingIntegrationJob,
    data: integrationJob = {},
    refetch: refetchIntegrationJob
  } = useQuery(['integration-job-get', id], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/${id}/job`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      }).then(res => res.json())
    }),
    {
      cacheTime: 15 * 60 * 1000,  // 15 minutes
      staleTime: 15 * 60 * 1000,  // 15 minutes
      refetchOnWindowFocus: false,
    }
  )

  const { isFetching: isSyncing, refetch: sync } = useQuery(['integration-sync', id], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/paypal/sync`, {
        method: 'POST',
        body: JSON.stringify({
          integration_id: id,
          dataType,
          startDate,
          endDate,
          syncFrequency
        }),
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      }).then(res => res.json())
    }),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      staleTime: 0,
      onSuccess: () => {
        setShowMessage(true)
        setMessage(SYNC_SUCCESS_MESSAGE)
        setMessageSeverity('success')
        refetchIntegrationJob()
      },
      onError: () => {
        setShowMessage(true)
        setMessage(SYNC_ERROR_MESSAGE)
        setMessageSeverity('error')
      }
    }
  )

  const { isLoading: isUpdatingSyncFrequency, mutate: updateSyncFrequency } = useMutation(
    newSyncFrequency => firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/paypal/sync`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          integration_id: id,
          syncFrequency: newSyncFrequency
        }),
      }).then(res => res.json())
    }),
    {
      onSuccess: () => {
        setShowMessage(true)
        setMessage('Sync frequency successfully updated')
        setMessageSeverity('success')
        refetchIntegrationJob()
      },
      onError: () => {
        setShowMessage(true)
        setMessage('Error updating sync frequency. Please try again, and if problem persists, contact support.')
        setMessageSeverity('error')
      }
    }
  )

  const revoke = () => {
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/paypal/revoke`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          integration_id: id
        })
      }).then(res => {
        setShowRevokeConfirmation(false)
        setMessage(REVOKE_SUCCESS_MESSAGE)
        setShowMessage(true)
        setTimeout(() => history.push(ROUTES.SETTINGS), 3000)
        return res.json()
      })
    })
  }

  const handleCloseMessage = (_, reason) => {
    if (reason === 'clickaway') return
    setShowMessage(false)
  }

  const handleChangeSyncFrequency = (frequency) => {
    setSyncFrequency(frequency)
    updateSyncFrequency(frequency)
  }

  const setDatesFromRange = (dateRange) => {
    let startDate, endDate
    switch (dateRange) {
      case 'last_7_days':
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(7, 'days').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'last_30_days':
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(30, 'days').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'last_3_months':
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(3, 'months').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'last_6_months':
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(6, 'months').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'last_12_months':
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(12, 'months').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'last_2_years':
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(2, 'years').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'last_3_years':
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(3, 'years').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'ytd':
        startDate = moment.tz(TIMEZONE).startOf('year').startOf('day').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'last_and_this_year':
        startDate = moment.tz(TIMEZONE).subtract(1, 'year').startOf('year').startOf('day').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      default:
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(12, 'months').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
    }
    setStartDate(startDate)
    setEndDate(endDate)
  }

  return (
    <div className={classes.root}>
      <Box padding={2}>
        <Button
          variant='outlined'
          onClick={() => history.push(ROUTES.SETTINGS)}
          size='small'
        >
          Back
        </Button>
        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs={2}>
                  <img
                    className={classes.logoImg}
                    alt='paypal_logo'
                    src={paypalLogoImg}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant='h5' gutterBottom>
                    PayPal Source
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Name
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Box height='100%' display='flex' alignItems='center'>
                    <Typography variant='body2'>
                      {isLoadingIntegration ? <Skeleton variant='text' width={100} /> : `${integration.nickname}`}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Sync
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant='body2' paragraph>
                    To sync data from PayPal with LTV Numbers, select the appropriate data type and click the button below. This will start a process of retrieving data from the account and loading it into LTV Numbers. Due to rate limitations and the number of transcations inside PayPal, this process can take anywhere from several minutes to several hours.
                  </Typography>
                  <Typography variant='body2' paragraph>
                    There are multiples types of data that can be synced from PayPal depending on the needs of your account.
                  </Typography>
                  <Typography variant='body2' paragraph>
                    It is strongly recommended that you only sync the data that is needed, because payment processors like PayPal can produce redundant transactions with other connected platforms, such as Clickfunnels or Shopify.
                  </Typography>
                  <Typography variant='body2' paragraph>
                    PayPal accounts are also tricky because they can be used for many things, including integrating with shopping carts, send money person to person, sending or receiving mass payouts through affiliate systems, or even platforms like eBay and other third parties. Therefore, we need to be careful to select the right set of data for analysis purposes, otherwise the data can be confusing and messy. For a full list of the transaction types, see <a href="https://developer.paypal.com/docs/transaction-search/transaction-event-codes/#link-tnnpaypalaccounttopaypalaccountpayment">the first section on this page under T00nn</a>.
                  </Typography>
                  <Typography variant='body2' paragraph>
                    Generally speaking, the appropriate choice for Data Type will not be &quot;All Transactions&quot;. Carefully read the descriptions below before making your selection.
                  </Typography>

                  <Typography variant='h6' paragraph>
                    All Transactions
                  </Typography>
                  <Typography variant='body2' paragraph>
                    This refers to <em>almost</em> All Transactions from PayPal. It does not include the &quot;General&quot; type of transaction (T0000) at the link above, which is typically used for sending money from your PayPal account to your bank account, paying contractors or team members, and other miscellaneous uses that do not involve your customers buying things from your business.
                  </Typography>
                  <Typography variant='body2' paragraph>
                    Aside from this excluded category, all other payment types listed at the link above are included. This encompasses all money sent from one PayPal account to another PayPal account. Becasue this includes essentially everything, be careful to only use this if PayPal is being used in isolation and it is not hooked up to cart platforms like Clickfunnels. Syncing all transcations while also using PayPal as the payment processor integrated with a cart platform will result in duplication.
                  </Typography>
                  <Typography variant='body2' paragraph>
                    Additionally, note that this will include transactions such as you sending or receiving an affiliate commission through an affiliate platform, someone sending money to your account directly, and more. If you are only looking for transactions from online shopping cart transactions, look at the other data types below.
                  </Typography>

                  <Typography variant='h6' paragraph>
                    Checkout
                  </Typography>
                  <Typography variant='body2' paragraph>
                    This refers to a category of transactions that are typically used by web and shopping cart platforms to provide a checkout system to customers. Think of this as all the purchases that are made through your online store. This is a good choice if you have PayPal integrated with a checkout platform but are not planning to integrate that platform with LTV Numbers directly. NOTE: if you choose this option and connect the cart platform you integrated with PayPal, this will likely result in duplication.
                  </Typography>
                  <Typography variant='body2' paragraph>
                    If this use case sounds resembles your setup, then this is likely the best choice.
                  </Typography>

                  <Typography variant='h6' paragraph>
                    Subscriptions
                  </Typography>
                  <Typography variant='body2' paragraph>
                    This refers to only transcations related to subscriptions, also referred to as recurring payments. Choose this option if you are using PayPal in conjunction with a checkout system like Clickfunnels where subscription rebills are not processed and you would like to ensure that rebills are analyzed.
                  </Typography>

                  <Box marginTop={2} display='flex' alignItems='center'>
                    <FormControl
                      variant='outlined'
                      margin='dense'
                      sx={{ margin: 1, minWidth: 150 }}
                      required
                     >
                      <InputLabel id='data-type-label'>Data Type</InputLabel>
                      <Select
                        labelId='data-type-label'
                        id='data-type-select'
                        value={dataType}
                        onChange={event => setDataType(event.target.value)}
                        label='Data Type'
                      >
                        {dataTypeMenuItems.map(item =>
                          <MenuItem key={item.value} value={item.value}>{item.display}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    <FormControl
                      variant='outlined'
                      margin='dense'
                      sx={{ margin: 1, minWidth: 150 }}
                      required
                     >
                      <InputLabel id='data-type-label'>Date Range</InputLabel>
                      <Select
                        labelId='date-range-label'
                        id='date-range-select'
                        value={dateRange}
                        onChange={event => setDateRange(event.target.value)}
                        label='Date Range'
                      >
                        {dateRangeMenuItems.map(item =>
                          <MenuItem key={item.value} value={item.value}>{item.display}</MenuItem>
                        )}
                      </Select>
                    </FormControl>

                    {isLoadingIntegration ? (
                      <Skeleton variant='text' width={200} />
                    ) : (
                      <>
                        <FormControl
                          variant='outlined'
                          margin='dense'
                          sx={{ margin: 1, minWidth: 150 }}
                          required
                        >
                          <InputLabel id='data-type-label'>Sync Frequency</InputLabel>
                          <Select
                            labelId='sync-frequency-label'
                            id='sync-frequency-select'
                            value={syncFrequency}
                            onChange={event => handleChangeSyncFrequency(event.target.value)}
                            label='Sync Frequency'
                          >
                            {syncFrequencyMenuItems.map(item =>
                              <MenuItem key={item.value} value={item.value}>{item.display}{isUpdatingSyncFrequency && <>&nbsp;<CircularProgress color='inherit' size={16}/></>}</MenuItem>
                            )}
                          </Select>
                        </FormControl>

                        {isLoadingIntegrationJob ? (
                            <Skeleton variant='text' width={200} />
                          ) : (
                            integrationJob.job ? (
                              <Box>
                                <Typography variant='caption' display='block'>
                                  <b>Last sync:</b> {integrationJob.job.lastAttemptTime ? moment(integrationJob.job.lastAttemptTime.seconds * 1000).toLocaleString() : 'None'}
                                </Typography>
                                <Typography variant='caption' display='block'>
                                  <b>Next sync:</b> {integrationJob.job.scheduleTime ? moment(integrationJob.job.scheduleTime.seconds * 1000).toLocaleString() : 'None'}
                                </Typography>
                              </Box>
                            ) : (
                              <Box>
                                <Typography variant='caption' display='block'>
                                  Sync job not yet created. Create sync job by syncing the data or updating the sync frequency.
                                </Typography>
                              </Box>
                            )
                          )
                        }
                      </>
                    )}
                  </Box>
                  <Box>
                    <Button
                      className={classes.syncButton}
                      variant='contained'
                      color='secondary'
                      onClick={() => sync()}
                      disabled={!dataType}
                    >
                      {isSyncing ?
                        <CircularProgress color='inherit' size={24} className={classes.buttonProgress} />
                        : 'Sync Data'
                      }
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Delete
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant='body2' gutterBottom>
                    If you want to remove this data integration so payments are no longer synced, click the button below.
                  </Typography>
                  <Button
                    className={classes.redButton}
                    variant='text'
                    onClick={() => setShowRevokeConfirmation(true)}
                  >
                    Delete Source
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      </Box>
      <Dialog
        open={showRevokeConfirmation}
        onClose={() => setShowRevokeConfirmation(false)}
      >
        <DialogTitle>
          Remove access to account <b>&quot;{integration.nickname}&quot;</b>?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will prevent data from being synced to LTV Numbers.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.redButton}
            variant='text'
            onClick={() => setShowRevokeConfirmation(false)}
          >
            Cancel
          </Button>
          <Button
            color='primary'
            onClick={() => revoke()}
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showMessage}
        autoHideDuration={10000}
        onClose={handleCloseMessage}
      >
        <Alert
          severity={messageSeverity}
          variant='filled'
          onClose={handleCloseMessage}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  )
}

PaypalSource.propTypes = {}

export default PaypalSource