import React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Hidden from '@mui/material/Hidden'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Typography from '@mui/material/Typography'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import makeStyles from '@mui/styles/makeStyles'
import { blue, green, purple } from '@mui/material/colors'
import moment from 'moment-timezone'
import clsx from 'clsx'

import { useHistory } from 'react-router-dom'

import { FirebaseContext } from '../../utils/firebase'
import 'firebase/auth'

import {
  useQuery,
} from 'react-query'

import { formatNumber, stableSort } from '../../utils/helpers'
import { API_ROOT_URL } from '../../constants/'
import * as ROUTES from '../../constants/routes'

const PAYMENTS_LIST_HEIGHT = 259
const RECORDS_LIMIT = 10
const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  filters: {
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'flex-start',
    },
  },
  paymentsList: {
    height: PAYMENTS_LIST_HEIGHT,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  activityBadgeGrid: {
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      paddingBottom: theme.spacing(1),
    },
  },
  activityBadgeGridItem: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 88,
    },
    [theme.breakpoints.down('md')]: {
      display: 'flex',
    },
  },
  activityBadge: {
    display: 'inline-flex',
    borderRadius: 4,
    color: 'white',
    textTransform: 'uppercase',
    padding: theme.spacing(0.5, 0.2),
    width: 75,
    justifyContent: 'center',
    fontSize: 10,
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      marginRight: 5,
      height: 22.5,
    },
  },
  activityViewAllDiv: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  frontendBadge: {
    backgroundColor: green[600],
  },
  backendBadge: {
    backgroundColor: blue[500],
  },
  rebillBadge: {
    backgroundColor: purple[400],
  },
}))

const RecentActivity = (props) => {
  const { frontendType } = props

  const classes = useStyles()
  const firebase = React.useContext(FirebaseContext)
  const history = useHistory()
  const [activityType, setActivityType] = React.useState('all')

  const context = {
    dates: {
      start: moment.tz(TIMEZONE).subtract(90, 'days').startOf('day'),
      end: moment.tz(TIMEZONE).endOf('day')
    },
    breakdown: null,
    filters: []
  }

  const { isLoading, data } = useQuery(['dashboard-recent-activity', context, frontendType], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/dashboard/activity`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          startDate: context.dates.start.format(),
          endDate: context.dates.end.format(),
          timezone: TIMEZONE,
          filters: context.filters,
          frontendType,
          limit: RECORDS_LIMIT
        })
      }).then(res => res.json())
    }),
    {
      enabled: !!context.dates.start && !!context.dates.end,
      cacheTime: 15 * 60 * 1000,  // 15 minutes
      staleTime: 15 * 60 * 1000,  // 15 minutes
      refetchOnWindowFocus: false,
    }
  )

  const handleChangeActivityType = (event, newValue) => {
    setActivityType(newValue)
  }

  const handlePaymentClick = (contact_id) => {
    history.push(ROUTES.PEOPLE + `/${contact_id}`)
  }

  let payments = data ? [...data.frontend, ...data.backend, ...data.rebill] : []
  if (activityType !== 'all') {
    payments = payments.filter(payment => payment.type === activityType)
  }
  payments = stableSort(payments, 'desc', 'created_at')

  return (
    <Paper className={classes.root}>
      <Typography variant='h6' gutterBottom>
        Recent Activity
      </Typography>
      <Grid container direction='row' className={classes.filters}>
        <ToggleButtonGroup
          value={activityType}
          exclusive
          onChange={handleChangeActivityType}
          aria-label='activityType'
          size='small'
        >
          <ToggleButton value='all' aria-label='all'>
            All
          </ToggleButton>
          <ToggleButton value='frontend' aria-label='frontend activity'>
            Frontend
          </ToggleButton>
          <ToggleButton value='backend' aria-label='backend activity'>
            Backend
          </ToggleButton>
          <ToggleButton value='rebill' aria-label='rebill activity'>
            Rebill
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      {isLoading ? (
        <Skeleton className={classes.marginTop} variant="rectangular" width={'100%'} height={PAYMENTS_LIST_HEIGHT - 8} style={{ marginTop: 8 }} animation='wave' />
      ) : (
        <List className={classes.paymentsList}>
          {payments.map(payment =>
            <ListItem
              key={payment.id}
              onClick={() => handlePaymentClick(payment.contact_id)}
              button
              disableGutters
              dense
            >
              <Grid container spacing={0} className={classes.activityBadgeGrid}>
                <Grid item md={3} className={classes.activityBadgeGridItem}>
                  <span
                    className={clsx(
                      classes.activityBadge,
                      {
                        [classes.frontendBadge]: payment.type === 'frontend',
                        [classes.backendBadge]: payment.type === 'backend',
                        [classes.rebillBadge]: payment.type === 'rebill',
                      }
                    )}
                  >
                    {payment.type}
                  </span>
                  <Hidden mdUp>
                    <Typography variant='body2'>
                      <b>${formatNumber(payment.amount_cents / 100, 2)}</b> from {payment.email}
                    </Typography>
                  </Hidden>
                </Grid>
                <Grid item md={9}>
                  <Hidden mdDown>
                    <Typography variant='body2'>
                      <b>${formatNumber(payment.amount_cents / 100, 2)}</b> from {payment.email}
                    </Typography>
                  </Hidden>
                  <Typography variant='caption' color='textSecondary'>
                    {payment.product} ({moment.duration(moment.tz(payment.created_at, TIMEZONE).diff()).humanize(true)})
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
          )}
        </List>
      )}
      <Hidden mdDown>
        <div className={classes.activityViewAllDiv}>
          <Button
            variant='outlined'
            endIcon={<ArrowRightIcon />}
            fullWidth
            onClick={() => history.push(ROUTES.PAYMENTS)}
          >
            View All Payments
          </Button>
        </div>
      </Hidden>
    </Paper>
  );
}

RecentActivity.propTypes = {
  frontendType: PropTypes.string.isRequired,
}

export default RecentActivity