import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Drawer from '@mui/material/Drawer'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import makeStyles from '@mui/styles/makeStyles'

import DateRangeController from '../../Common/DateRangeController'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
  },
  select: {
    width: 225,
    margin: theme.spacing(1, 0),
  },
  selectOverride: {
    padding: '8px 12px',
  },
  filterContent: {
    height: 'calc(100% - 64px)',
    padding: theme.spacing(2),
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  actionRow: {
    height: 64,
    width: '100%',
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',

  },
  actionButton: {
    margin: theme.spacing(0, 1),
  },
}))

const selectItems = [
  {id: 'created_at', name: 'Date of payment'},
]

const DateFilterPayments = (props) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [type, setType] = React.useState('created_at')
  const [startDate, setStartDate] = React.useState(undefined)
  const [endDate, setEndDate] = React.useState(undefined)

  const handleStartDateChange = (newStartDate) => {
    setStartDate(newStartDate)
  }

  const handleEndDateChange = (newEndDate) => {
    setEndDate(newEndDate)
  }

  const handleAddDateFilter = () => {
    let filters = []
    if (startDate) {
      const filter = {
        id: type,
        name: selectItems.find(item => item.id === type).name,
        value: startDate,
        valueName: `on or after ${startDate.format('M/D/YY')}`,
        comparison: '>='
      }
      filters.push(filter)
    }
    if (endDate) {
      const filter = {
        id: type,
        name: selectItems.find(item => item.id === type).name,
        value: endDate,
        valueName: `on or before ${endDate.format('M/D/YY')}`,
        comparison: '<='
      }
      filters.push(filter)
    }
    props.onFiltersAdd(filters)
    handleClose()
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setStartDate(undefined)
    setEndDate(undefined)
    setOpen(false)
  }

  return (
    <div className={classes.root}>
      <Button
        variant='outlined'
        color='primary'
        onClick={handleOpen}
        endIcon={<ArrowDropDownIcon color='primary' />}
      >
        Date
      </Button>
      <Drawer
        anchor='right'
        open={open}
        onClose={handleClose}
      >
        <Box className={classes.box}>
          <div className={classes.filterContent}>
            <Select
              className={classes.select}
              labelId='date-filter-type-select-label'
              id='date-filter-type-select'
              value={type}
              onChange={(event) => setType(event.target.value)}
              classes={{ select: classes.selectOverride }}
              variant='outlined'
            >
              {selectItems.map(item =>
                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
              )}
            </Select>
            <DateRangeController
              startDate={startDate}
              endDate={endDate}
              onStartDateChange={handleStartDateChange}
              onEndDateChange={handleEndDateChange}
            />
          </div>
          <div className={classes.actionRow}>
            <Button
              className={classes.actionButton}
              variant='contained'
              color='primary'
              onClick={handleAddDateFilter}
              disabled={startDate === undefined && endDate === undefined}
            >
              Apply
            </Button>
            <Button className={classes.actionButton} variant='outlined' onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </Box>
      </Drawer>
    </div>
  );
}

DateFilterPayments.propTypes = {
  onFiltersAdd: PropTypes.func.isRequired,
}

export default DateFilterPayments
