import React from 'react'
import PropTypes from 'prop-types'
import Collapse from '@mui/material/Collapse'
import Paper from '@mui/material/Paper'
import makeStyles from '@mui/styles/makeStyles'

import ControlsConfigurationCustomers from './ControlsConfigurationCustomers'
import Filters from './Filters'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))

const ControlsCustomers = (props) => {
  const classes = useStyles()
  const showContext = props.filters.length > 0 || !!props.breakdown
  return (
    <Paper className={classes.root}>
      <ControlsConfigurationCustomers
        filters={props.filters}
        onFiltersAdd={props.onFiltersAdd}
        onFilterDelete={props.onFilterDelete}
        onFilterDeleteAll={props.onFilterDeleteAll}
        searchText={props.searchText}
        onSearchTextChange={props.onSearchTextChange}
        onExport={props.onExport}
        rowCount={props.rowCount}
      />
      <Collapse in={showContext}>
        <Filters
          filters={props.filters}
          onFilterDelete={props.onFilterDelete}
          onFilterDeleteAll={props.onFilterDeleteAll}
        />
      </Collapse>
    </Paper>
  )
}

ControlsCustomers.propTypes = {
  filters: PropTypes.array.isRequired,
  onFiltersAdd: PropTypes.func.isRequired,
  onFilterDelete: PropTypes.func.isRequired,
  onFilterDeleteAll: PropTypes.func.isRequired,
  searchText: PropTypes.string,
  onSearchTextChange: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  rowCount: PropTypes.number,
}

export default ControlsCustomers
