import React, { useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Hidden from '@mui/material/Hidden'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { darken } from '@mui/material/styles'
import { useHistory, useLocation, Link } from 'react-router-dom'
import queryString from 'query-string'

import { FirebaseContext } from '../../utils/firebase'
import 'firebase/auth'

import FullPageLoadingSpinner from '../Common/FullPageLoadingSpinner'

import * as ROUTES from '../../constants/routes'
import { CONTENT_MAP } from '../../constants/partners'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    [theme.breakpoints.down('md')]: {
      background: 'linear-gradient(135deg, #03113b, #243862c7), url(./ltv-graph.png)',
      backgroundPosition: 'bottom right',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      color: theme.palette.primary.contrastText,
    },
  },
  logoImg: {
    display: 'flex',
    margin: '0px auto',
    width: 120,
    marginBottom: theme.spacing(2),
  },
  left: {
    padding: theme.spacing(4),
    textAlign: 'center',
  },
  right: {
    height: '100%',
    background: 'linear-gradient(315deg, rgba(0,0,0,0.80) 0%, rgba(28,37,102,0.80) 45%, rgba(34,103,234,0.80) 100%), url(./ltv-graph.png)',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
  },
  typographySignIn: {
    fontSize: 18,
    color: theme.palette.text.secondary,
  },
  email: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      background: 'white',
      borderRadius: 8,
    },
  },
  password: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      background: 'white',
      borderRadius: 8,
    },
  },
  button: {
    marginTop: theme.spacing(2),
    width: 180,
    borderRadius: 8,
    height: 42,
    fontSize: 18,
    fontWeight: 600,
    letterSpacing: 1,
  },
  forgotPasswordTypography: {
    marginTop: theme.spacing(1),
    fontSize: 12,
    color: theme.palette.text.secondary,
  },
  forgotPasswordLink: {
    textDecoration: 'underline',
    [theme.breakpoints.down('md')]: {
      color: 'white',
    },
  },
  learnMore: {
    color: 'white',
  },
}))

const Login = () => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const firebase = React.useContext(FirebaseContext)
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [emailErrorMessage, setEmailErrorMessage] = React.useState(false)
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [contentKey, setContentKey] = React.useState(null)

  const params = queryString.parse(location.search)
  const redirectTo = params.redirectTo ? decodeURIComponent(params.redirectTo) : null

  // Check URL to display the appropriate login page content
  useEffect(() => {
    // Check path to see if a alternate login page should be displayed
    const loginUrlMatch = history.location.pathname.match(/\/login\/([^/]+)(?:\/([^/]+))?/)
    const subPath = loginUrlMatch ? loginUrlMatch[1] : null

    // If subPath is found but it's not in the CONTENT_MAP, redirect to default login page
    if (subPath && !CONTENT_MAP[subPath]) {
      history.push(ROUTES.LOGIN)
    }
    else if (subPath && CONTENT_MAP[subPath]) {
      setContentKey(subPath)
    } else {
      setContentKey('default')
    }
  }, [])

  const signIn = () => {
    setLoading(true)
    firebase.auth().signInWithEmailAndPassword(email.trim(), password).then(() => {
      // Write contentKey to local storage so it can be used during logout to redirect to the correct login page
      localStorage.setItem('loginContentKey', contentKey)

      if (redirectTo) {
        history.push(redirectTo)
      } else {
        history.push(ROUTES.REPORTS)
      }
    }).catch(error => {
      switch(error.code) {
        case 'auth/invalid-email':
        case 'auth/user-disabled':
        case 'auth/user-not-found':
          setEmailErrorMessage(error.message)
          setPasswordErrorMessage('')
          break
        case 'auth/wrong-password':
          setEmailErrorMessage('')
          setPasswordErrorMessage(error.message)
          break
        default:
          break
      }
      setLoading(false)
    })
  }

  const onKeyDown = (event) => {
    // Catch enter press
    if (event.keyCode === 13) {
        signIn()
    }
  }

  return (
    <Box>
      {contentKey === null ? <FullPageLoadingSpinner /> : (
        <Grid className={classes.root} container alignItems='center'>
          <Grid className={classes.left} item sm={12} md={4}>
            <img src={CONTENT_MAP[contentKey].logo} alt='logo' style={{ maxWidth: contentKey === 'default' ? '150px' : '200px' }} />
            <form>
              <TextField
                className={classes.email}
                id='email'
                name='email'
                label='Email Address'
                variant='outlined'
                color='primary'
                value={email}
                onChange={event => setEmail(event.target.value)}
                onKeyDown={event => onKeyDown(event)}
                error={!!emailErrorMessage}
                helperText={emailErrorMessage}
                fullWidth
                autoFocus
              />
              <TextField
                className={classes.password}
                id='password'
                name='password'
                label='Password'
                variant='outlined'
                color='primary'
                type='password'
                value={password}
                onChange={event => setPassword(event.target.value)}
                onKeyDown={event => onKeyDown(event)}
                error={!!passwordErrorMessage}
                helperText={passwordErrorMessage}
                fullWidth
              />
              <Button
                className={classes.button}
                variant='contained'
                onClick={signIn}
                sx={{
                  backgroundColor: CONTENT_MAP[contentKey].buttonColor,
                  color: theme => theme.palette.getContrastText(CONTENT_MAP[contentKey].buttonColor),
                  '&:hover': {
                    backgroundColor: darken(CONTENT_MAP[contentKey].buttonColor, 0.1),
                  }
                }}
              >
                {loading ? <CircularProgress color='inherit' size={32} /> : "Log In"}
              </Button>
              <br />
              <Typography className={classes.forgotPasswordTypography} variant='body1'>
                <Link
                  className={classes.forgotPasswordLink}
                  to={contentKey !== 'default' ? `${ROUTES.PASSWORD_FORGOT}/${contentKey}` : ROUTES.PASSWORD_FORGOT}
                >
                  Forgot password?
                </Link>
              </Typography>
            </form>
          </Grid>
          <Hidden mdDown>
            <Grid
              item
              xs={8}
              sx={{
                height: '100%',
                background: CONTENT_MAP[contentKey].heroOverlayColor ?
                  `linear-gradient(315deg, rgba(0,0,0,0.80) 0%, ${CONTENT_MAP[contentKey].heroOverlayColor} 100%), url(${CONTENT_MAP[contentKey].heroBackground})`
                  : `url(${CONTENT_MAP[contentKey].heroBackground})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: contentKey === 'default' ? null : 'cover',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {contentKey === 'default' ? (
                <Box width='100%' textAlign='center'>
                  <Typography variant='h3' gutterBottom>
                    Be more data driven
                  </Typography>
                  <Typography variant='h5'>
                    Know exactly how much you can spend to acquire a customer
                  </Typography>
                </Box>
              ) : (
                <Box width='100%' textAlign='center'>
                  <img src={CONTENT_MAP[contentKey].heroLogo} alt='hero-logo' style={{ maxWidth: '80%' }} />
                </Box>
              )}
            </Grid>
          </Hidden>
        </Grid>
      )}
    </Box>
  )
}

export default Login