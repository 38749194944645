import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import moment from 'moment-timezone'
import { useQueries } from 'react-query'

import { FirebaseContext } from '../../utils/firebase'
import { API_ROOT_URL } from '../../constants'

import Controls from './Controls'
import DataTableWrapper from './DataTable'
import Chart from './Chart'
import MetricChips from './MetricChips'

const TIMEZONE = moment.tz.guess() // This should be set by user configuration
const QUERY_CONFIG = {
  cacheTime: 10 * 60 * 1000,  // 10 minutes
  staleTime: 5 * 60 * 1000,  // 5 minutes
  refetchOnWindowFocus: false,
}

const Report = ({
  report,
  savedSegments,
  savedGroups,
  liveMode,
  startDate,
  endDate,
  resolution,
  metricKey,
  showCumulative,
  showSecondaryChart,
  config,
  segments,
  onReportSave,
  onReportDelete,
  onLiveModeChange,
  onDatesChange,
  onResolutionChange,
  onMetricKeyChange,
  onShowCumulativeChange,
  onShowSecondaryChartChange,
  onConfigChange,
  onSegmentAdd,
  onSegmentDelete,
  onSegmentDuplicate,
  onSegmentEditChange,
  onSegmentSaveEdit,
  onSegmentRevertEdit,
  onSegmentColorChange,
  onSegmentIsActiveChange,
  onSegmentFilterAdd,
  onSegmentReplaceFiltersWithSavedSegmentFilter,
  onSegmentFilterDelete,
  onSegmentsReorder,
  onFilterAdd,
  onFilterUpdate,
  onFilterDelete,
  onClearAllSegmentFilters,
  onSegmentBreakdownSelect,
  onSegmentBreakdownColorChange,
  onSegmentBreakdownIsActiveChange,
}) => {
  const firebase = useContext(FirebaseContext)

  const [chartHoverIndex, setChartHoverIndex] = React.useState(null)

  const segmentsData = useQueries(segments.map(segment => ({
      queryKey: [
        'segment',
        segment.id,
        segment.segmentFilters,
        segment.filters,
        report.key,
        report.xKey,
        report.milestoneDays,
        startDate,
        endDate,
        TIMEZONE,
        resolution,
        config,
      ],
      queryFn: () => fetchSegmentData(segment),
      refetchInterval: liveMode ? 1 * 60 * 1000 : false,  // 1 minute if liveMode is enabled, otherwise no automatic refetch
      ...QUERY_CONFIG
  })))

  const segmentsBreakdownData = useQueries(segments.map(segment => ({
    queryKey: [
      'breakdown',
      segment.id,
      segment.segmentFilters,
      segment.filters,
      segment.breakdown,
      report.key,
      report.xKey,
      report.milestoneDays,
      startDate,
      endDate,
      TIMEZONE,
      resolution,
      config,
    ],
    queryFn: () => fetchSegmentData(segment, true),
    refetchInterval: liveMode ? 1 * 60 * 1000 : false,  // 1 minute if liveMode is enabled, otherwise no automatic refetch
    ...QUERY_CONFIG
  })))

  // This stores the set of breakdown keys present in the API breakdown data for each segment
  // for use in the UI to determine which breakdown keys are eligible for display
  const segmentsEligibleBreakdownKeys = useMemo(() => {
    return segmentsBreakdownData.map(query => query.data ? query.data.breakdownKeys : [])
  }, [segmentsBreakdownData])

  const aliasesData = useQueries(segments.map(segment => ({
    queryKey: [
      'aliases',
      segment.breakdown?.field
    ],
    queryFn: () => fetchAliases(segment.breakdown.field),
    enabled: Boolean(segment.breakdown),
    cacheTime: 10 * 60 * 1000,  // 10 minutes
    staleTime: 10 * 60 * 1000,  // 10 minutes
    refetchOnWindowFocus: false,
    refetchInterval: 10 * 60 * 1000,  // 10 minutes
  })))

  const aliases = useMemo(() => {
    if (aliasesData.length === 0) return {}
    if (aliasesData.every(query => !query.data)) return {}
    let aliases = {}
    aliasesData.forEach(query => {
      if (query.data) {
        aliases[query.data.field] = query.data.aliases
      }
    })
    return aliases
  }, [aliasesData])

  const fetchSegmentData = (segment, includeBreakdown=false) => {
    if (includeBreakdown && !segment.breakdown) return null
    return firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api2/reports/${report.key}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          xKey: report.xKey,
          segmentId: segment.id,
          segmentFilters: segment.segmentFilters,
          filters: segment.filters,
          breakdown: includeBreakdown ? segment.breakdown : null,
          startDate,
          endDate,
          timezone: TIMEZONE,
          resolution,
          config,
          milestoneDays: report.milestoneDays,
        })
      }).then(res => res.json())
    })
  }

  const fetchAliases = async (field) => {
    if (!field) return null
    return firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api2/fields/${field}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then(res => res.json())
    })
  }

  const handleChartHoverIndexChange = (index) => {
    setChartHoverIndex(index)
  }

  return (
    <Box
      flexGrow={1}
      minWidth='calc(100% - 66px - 266px)'
      sx={{
        backgroundColor: '#f1f5f9',
        padding: theme => theme.spacing(2, 4),
        height: '100vh',
        overflowY: 'auto',
      }}
    >
      <Controls
        segmentsEligibleBreakdownKeys={segmentsEligibleBreakdownKeys}
        aliases={aliases}

        report={report}
        savedSegments={savedSegments}
        savedGroups={savedGroups}
        liveMode={liveMode}
        startDate={startDate}
        endDate={endDate}
        resolution={resolution}
        config={config}
        segments={segments}
        onReportSave={onReportSave}
        onReportDelete={onReportDelete}
        onLiveModeChange={onLiveModeChange}
        onDatesChange={onDatesChange}
        onConfigChange={onConfigChange}
        onSegmentAdd={onSegmentAdd}
        onSegmentDelete={onSegmentDelete}
        onSegmentDuplicate={onSegmentDuplicate}
        onSegmentEditChange={onSegmentEditChange}
        onSegmentSaveEdit={onSegmentSaveEdit}
        onSegmentRevertEdit={onSegmentRevertEdit}
        onSegmentColorChange={onSegmentColorChange}
        onSegmentFilterAdd={onSegmentFilterAdd}
        onSegmentReplaceFiltersWithSavedSegmentFilter={onSegmentReplaceFiltersWithSavedSegmentFilter}
        onSegmentFilterDelete={onSegmentFilterDelete}
        onSegmentsReorder={onSegmentsReorder}
        onSegmentBreakdownSelect={onSegmentBreakdownSelect}
        onSegmentBreakdownColorChange={onSegmentBreakdownColorChange}
        onFilterAdd={onFilterAdd}
        onFilterUpdate={onFilterUpdate}
        onFilterDelete={onFilterDelete}
        onClearAllSegmentFilters={onClearAllSegmentFilters}
      />

      <MetricChips
        metricItems={report.metrics.items}
        selectedMetricKey={metricKey}
        onMetricChange={onMetricKeyChange}
      />

      <Chart
        parentKey={report.kind === 'saved' ? report.id : report.key}
        xKey={report.xKey}
        xKeyAsRange={report.xKeyAsRange}
        emptyDataRule={report.emptyDataRule}
        allowSecondaryChart={report.chart.allowSecondaryChart}
        allowLineDots={report.chart.allowLineDots}
        allowUseStack100={report.chart.allowUseStack100}
        cumulativeDefault={report.chart.cumulativeDefault}
        hideYValuesAfterToday={report.chart.hideYValuesAfterToday}
        metricItem={report.metrics.items.find(m => m.key === metricKey)}
        startDate={startDate}
        endDate={endDate}
        resolution={resolution}
        config={config}
        showCumulative={showCumulative}
        showSecondaryChart={showSecondaryChart}
        segments={segments}
        segmentsData={segmentsData}
        segmentsBreakdownData={segmentsBreakdownData}
        segmentsEligibleBreakdownKeys={segmentsEligibleBreakdownKeys}
        aliases={aliases}
        chartHoverIndex={chartHoverIndex}
        onResolutionChange={onResolutionChange}
        onShowCumulativeChange={onShowCumulativeChange}
        onShowSecondaryChartChange={onShowSecondaryChartChange}
        onChartHoverIndexChange={handleChartHoverIndexChange}
      />

      <DataTableWrapper
        parentKey={report.kind === 'saved' ? report.id : report.key}
        xKey={report.xKey}
        xKeyAsRange={report.xKeyAsRange}
        defaultTab={report.table.defaultTab}
        showSummaryTab={report.table.summary.show}
        showChartTab={report.table.chart.show}
        showMilestonesTab={report.table.milestones.show}
        summaryColumns={report.table.summary.columns}
        emptyDataRule={report.emptyDataRule}
        metricItem={report.metrics.items.find(m => m.key === metricKey)}
        startDate={startDate}
        endDate={endDate}
        resolution={resolution}
        config={config}
        showCumulative={showCumulative}
        segments={segments}
        segmentsData={segmentsData}
        segmentsBreakdownData={segmentsBreakdownData}
        aliases={aliases}
        chartHoverIndex={chartHoverIndex}
        onSegmentIsActiveChange={onSegmentIsActiveChange}
        onSegmentBreakdownIsActiveChange={onSegmentBreakdownIsActiveChange}
        onChartHoverIndexChange={handleChartHoverIndexChange}
      />
    </Box>
  )
}

Report.propTypes = {
  report: PropTypes.object.isRequired,
  savedSegments: PropTypes.array.isRequired,
  savedGroups: PropTypes.array.isRequired,
  liveMode: PropTypes.bool.isRequired,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  resolution: PropTypes.number.isRequired,
  metricKey: PropTypes.string.isRequired,
  config: PropTypes.object.isRequired,
  showCumluative: PropTypes.bool,
  showSecondaryChart: PropTypes.bool,
  segments: PropTypes.array.isRequired,
  onReportSave: PropTypes.func.isRequired,
  onReportDelete: PropTypes.func.isRequired,
  onLiveModeChange: PropTypes.func.isRequired,
  onDatesChange: PropTypes.func.isRequired,
  onResolutionChange: PropTypes.func.isRequired,
  onMetricKeyChange: PropTypes.func.isRequired,
  onConfigChange: PropTypes.func.isRequired,
  onShowCumulativeChange: PropTypes.func.isRequired,
  onShowSecondaryChartChange: PropTypes.func.isRequired,
  onSegmentAdd: PropTypes.func.isRequired,
  onSegmentDelete: PropTypes.func.isRequired,
  onSegmentDuplicate: PropTypes.func.isRequired,
  onSegmentEditChange: PropTypes.func.isRequired,
  onSegmentSaveEdit: PropTypes.func.isRequired,
  onSegmentRevertEdit: PropTypes.func.isRequired,
  onSegmentColorChange: PropTypes.func.isRequired,
  onSegmentIsActiveChange: PropTypes.func.isRequired,
  onSegmentFilterAdd: PropTypes.func.isRequired,
  onSegmentReplaceFiltersWithSavedSegmentFilter: PropTypes.func.isRequired,
  onSegmentFilterDelete: PropTypes.func.isRequired,
  onSegmentsReorder: PropTypes.func.isRequired,
  onFilterAdd: PropTypes.func.isRequired,
  onFilterUpdate: PropTypes.func.isRequired,
  onFilterDelete: PropTypes.func.isRequired,
  onClearAllSegmentFilters: PropTypes.func.isRequired,
  onSegmentBreakdownSelect: PropTypes.func.isRequired,
  onSegmentBreakdownColorChange: PropTypes.func.isRequired,
  onSegmentBreakdownIsActiveChange: PropTypes.func.isRequired,
}

export default Report