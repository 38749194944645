import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import Popover from '@mui/material/Popover'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ViewWeekIcon from '@mui/icons-material/ViewWeek'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: theme.spacing(2),
  },
  popover: {
    marginTop: theme.spacing(1),
  },
  listItemIconRootOverride: {
    minWidth: 42,
  },
  buttonOverride: {
    textTransform: 'none',
  },
}))

const columnsSelectItems = [
  {id: 'default', name: 'Overview', description: 'Overview of Key Metrics'},
  {id: 'detailed_ltv', name: 'Detailed LTV', description: 'Detailed Lifetime Value Breakdown'},
  {id: 'detailed_ltr', name: 'Detailed LTR', description: 'Detailed Lifetime Revenue Breakdown'},
]

const ColumnsSelect = (props) => {
  const { columns, setColumns } = props
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleColumnsSelect = (id) => {
    setColumns(id)
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'popover-columns' : undefined
  const selectedColumnsName = columnsSelectItems.find(item => item.id === columns).name

  return (
    <div className={classes.root}>
      <Button
        aria-describedby={id}
        variant='outlined'
        onClick={handleClick}
        startIcon={ <ViewWeekIcon color='primary' /> }
        endIcon={<ArrowDropDownIcon color='primary' />}
        color='primary'
        classes={{ root: classes.buttonOverride }}
      >
        {selectedColumnsName}
      </Button>
      <Popover
        className={classes.popover}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
        transformOrigin={{ vertical: 'top', horizontal: 'right', }}
      >
        <Box>
          <List component='ul' aria-label='breakdown selector' dense subheader={<ListSubheader>Choose columns</ListSubheader>}>
            {columnsSelectItems.map(item =>
              <ListItem
                key={item.id}
                className={classes.listItem}
                selected={columns === item.id}
                onClick={() => handleColumnsSelect(item.id)}
                button
              >
                <ListItemIcon classes={{ root: classes.listItemIconRootOverride }}>
                  <ViewWeekIcon />
                </ListItemIcon>
                <ListItemText
                  primary={item.name}
                  secondary={item.description}
                />
              </ListItem>
            )}
          </List>
        </Box>
      </Popover>
    </div>
  )
}

ColumnsSelect.propTypes = {
  columns: PropTypes.string.isRequired,
  setColumns: PropTypes.func.isRequired,
}

export default ColumnsSelect
