import React from 'react'
import PropTypes from 'prop-types'
import Backdrop from '@mui/material/Backdrop'
import Button from '@mui/material/Button'
import Fade from '@mui/material/Fade'
import Hidden from '@mui/material/Hidden'
import IconButton from '@mui/material/IconButton'
import ListSubheader from '@mui/material/ListSubheader'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Modal from '@mui/material/Modal'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import BackupIcon from '@mui/icons-material/Backup'
import HelpIcon from '@mui/icons-material/Help'
import MoneyIcon from '@mui/icons-material/Money'
import PeopleIcon from '@mui/icons-material/People'
import SettingsIcon from '@mui/icons-material/Settings'
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt'
import TrackChangesIcon from '@mui/icons-material/TrackChanges'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'

import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'

import { UserContext } from '../../contexts/UserContext'
import { FirebaseContext } from '../../utils/firebase'
import 'firebase/auth'

import * as ROUTES from '../../constants/routes'
import { PUBLIC_DEMO_UID } from '../../constants'

const useStyles = makeStyles(theme => ({
  nav: {
    position: 'absolute',
    display: 'flex',
    bottom: 0,
    padding: theme.spacing(1),
    background: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
      top: 0,
      width: 62,
      height: '100vh',
    },
    [theme.breakpoints.down('md')]: {
      width: '100vw',
      height: 62,
      justifyContent: 'space-around',
      flexDirection: 'row',
      zIndex: 9,
    },
  },
  logoImg: {
    margin: theme.spacing(1, 0, 1, 0),
    width: 48,
  },
  iconButton: {
    width: '100%',
    height: 44,
    padding: theme.spacing(1),
    borderRadius: 4,
    color: theme.palette.grey[400],
    '&:hover': {
      color: theme.palette.primary.contrastText
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.down('md')]: {
      width: '33.3%',
    },
  },
  selected: {
    [theme.breakpoints.up('md')]: {
      backgroundColor: `${theme.palette.secondary.main} !important`,
      color: theme.palette.primary.contrastText
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: '90%',
    textAlign: 'center',
  },
  appVersion: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}))

const Nav = (props) => {
  const { currentUser, selectedRoute, handleNavClick } = props
  const classes = useStyles()
  const firebase = React.useContext(FirebaseContext)
  const userDoc = React.useContext(UserContext)
  const queryClient = useQueryClient()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const openProfile = () => {
    handleMenuClose()
    history.push(ROUTES.PROFILE)
  }

  const signOut = () => {
    firebase.auth().signOut().then(() => {
      // Check local storage for loginContentKey to redirect to the proper login page
      const loginContentKey = localStorage.getItem('loginContentKey')
      queryClient.clear()

      if (loginContentKey) {
        history.push(`${ROUTES.LOGIN}/${loginContentKey}`)
      } else {
        history.push(ROUTES.LOGIN)
      }
    })
  }

  const [open, setOpen] = React.useState(false)

  const handleOpenSupport = () => {
    setOpen(true)
  }

  const handleCloseSupport = () => {
    setOpen(false)
  }

  // Public Demo check to hide navigation to sensitive areas
  const uid = firebase.auth().currentUser ? firebase.auth().currentUser.uid : null
  const isPublicDemo = uid === PUBLIC_DEMO_UID

  return (
    <nav className={classes.nav}>
      <Hidden mdDown>
        {userDoc.logoUrl ? (
          <img className={classes.logoImg} src={userDoc.logoUrl} alt='custom logo' />
        ) : (
          <img className={classes.logoImg} src={'/ltv-logo-v2.png'} alt='logo' />
        )}
      </Hidden>
      <Hidden mdDown>
        <IconButton
          className={clsx(classes.iconButton, {[classes.selected]: selectedRoute.startsWith(ROUTES.REPORTS)})}
          onClick={() => handleNavClick(ROUTES.REPORTS)}
          size='large'
          >
          <Tooltip title={<span style={{ fontSize: 12 }}>Reports</span>} placement='right-end' enterDelay={0} arrow>
            <SignalCellularAltIcon fontSize='large' />
          </Tooltip>
        </IconButton>
        <IconButton
          className={clsx(classes.iconButton, {[classes.selected]: selectedRoute.startsWith(ROUTES.SCORECARDS)})}
          onClick={() => handleNavClick(ROUTES.SCORECARDS)}
          size='large'
          >
          <Tooltip title={<span style={{ fontSize: 12 }}>Scorecards (BETA)</span>} placement='right-end' enterDelay={0} arrow>
            <MoneyIcon fontSize='large' />
          </Tooltip>
        </IconButton>
        <IconButton
          className={clsx(classes.iconButton, {[classes.selected]: selectedRoute.startsWith(ROUTES.TARGETS)})}
          onClick={() => handleNavClick(ROUTES.TARGETS)}
          size='large'
          >
          <Tooltip title={<span style={{ fontSize: 12 }}>Targets (BETA)</span>} placement='right-end' enterDelay={0} arrow>
            <TrackChangesIcon fontSize='large' />
          </Tooltip>
        </IconButton>
        <IconButton
          className={clsx(classes.iconButton, {[classes.selected]: selectedRoute.startsWith(ROUTES.PEOPLE)})}
          onClick={() => handleNavClick(ROUTES.PEOPLE)}
          size='large'>
          <Tooltip title={<span style={{ fontSize: 12 }}>People</span>} placement='right-end' enterDelay={0} arrow>
            <PeopleIcon fontSize='large' />
          </Tooltip>
        </IconButton>
        {!isPublicDemo &&
          <IconButton
            className={clsx(classes.iconButton, {[classes.selected]: selectedRoute === ROUTES.UPLOAD})}
            onClick={() => handleNavClick(ROUTES.UPLOAD)}
            size='large'>
            <Tooltip title={<span style={{ fontSize: 12 }}>Upload</span>} placement='right-end' enterDelay={0} arrow>
              <BackupIcon fontSize='large' />
            </Tooltip>
          </IconButton>
        }
        {!isPublicDemo &&
          <IconButton
            className={clsx(classes.iconButton, {[classes.selected]: selectedRoute === ROUTES.SETTINGS || selectedRoute.startsWith(ROUTES.SOURCES_BASE)})}
            onClick={() => handleNavClick(ROUTES.SETTINGS)}
            size='large'>
            <Tooltip title={<span style={{ fontSize: 12 }}>Settings</span>} placement='right-end' enterDelay={0} arrow>
              <SettingsIcon fontSize='large' />
            </Tooltip>
          </IconButton>
        }
      </Hidden>
      <Hidden mdUp>
        <IconButton
          className={clsx(classes.iconButton)}
          onClick={handleOpenSupport}
          size='large'>
          <Tooltip title={<span style={{ fontSize: 12 }}>Support</span>} placement='right-end' enterDelay={0} arrow>
            <HelpIcon fontSize='large' />
          </Tooltip>
        </IconButton>
        <Modal
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          className={classes.modal}
          open={open}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <h2 id='transition-modal-title'>Support</h2>
              <p id='transition-modal-description'>For questions or support please email support@ltvnumbers.com and we will get back to you within 24-48 hours</p>
              <Button
                className={classes.button}
                variant='contained'
                color='secondary'
                onClick={handleCloseSupport}
              >
                Okay
              </Button>
            </div>
          </Fade>
        </Modal>
      </Hidden>
      <div style={{ flexGrow: 1 }} />
      <IconButton className={classes.iconButton} onClick={handleMenuClick} size='large'>
        <AccountCircleIcon fontSize='large' />
      </IconButton>
      <Menu
        id='user-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <ListSubheader className={classes.appVersion}>
          <Typography sx={{ fontSize: 12 }}>
            {process.env.REACT_APP_VERSION}
          </Typography>
        </ListSubheader>
        {currentUser && <ListSubheader>{currentUser.email}</ListSubheader>}
        {!isPublicDemo && <MenuItem onClick={openProfile}>Profile</MenuItem>}
        <MenuItem onClick={signOut}>Log out</MenuItem>
      </Menu>
      <Hidden mdDown>
        <Typography variant='body2' color='inherit' gutterBottom sx={{ fontSize: 12 }}>
          {process.env.REACT_APP_VERSION}
        </Typography>
      </Hidden>
    </nav>
  );
}

Nav.propTypes = {
  currentUser: PropTypes.object,
  selectedRoute: PropTypes.string.isRequired,
  handleNavClick: PropTypes.func.isRequired,
}

export default Nav