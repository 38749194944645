import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'

const NoLinesMessage = (props) => {
  const { classes, height } = props
  return (
    <Fragment>
      <div style={{ width: '100%', height }}>
        <Typography className={classes.noGraphDataTypography} component='div' variant='h6'>
          No Graph Data. Turn on lines in the Table below.
        </Typography>
      </div>
      <Typography className={classes.summaryTypography} component='div' variant='caption'>
        <Skeleton animation={false} />
        <Skeleton animation={false} />
      </Typography>
    </Fragment>
  )
}

NoLinesMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  height: PropTypes.number.isRequired,
}

export default NoLinesMessage
