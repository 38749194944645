import React from 'react'
import PropTypes from 'prop-types'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  headCell: {
    fontSize: '.78rem',
    lineHeight: '1.2rem',
  },
}))

const headCells = {
  default: [
    { id: 'last_action', numeric: false, label: 'Last Action' },
    { id: 'created_at', numeric: false, label: 'Created' },
    { id: 'email', numeric: false, label: 'Email' },
    { id: 'name', numeric: false, label: 'Name' },
    { id: 'ltv', numeric: true, label: 'LTV' },
    { id: 'orders', numeric: true, label: 'Orders' },
    { id: 'payments', numeric: true, label: 'Sales' },
    { id: 'refunds', numeric: true, label: 'Refunds' },
    { id: 'days_since_last_order_tz', numeric: true, label: 'Days Since Last Order' },
    { id: 'last_order_date', numeric: false, label: 'Last Order' },
  ],
}

const EnhancedTableHead = (props) => {
  const { columns, order, orderBy, onRequestSort, disableSorting } = props
  const classes = useStyles()

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  const filteredHeadCells = headCells[columns]
  return (
    <TableHead>
      <TableRow>
        {filteredHeadCells.map((headCell) =>
          <TableCell
            className={classes.headCell}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {disableSorting ? (
              <span>{headCell.label}</span>
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'desc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  columns: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  disableSorting: PropTypes.bool,
}

export default EnhancedTableHead