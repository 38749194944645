const METRIC_ITEMS_MAP = {
  'aogp': {
    'key': 'aogp',
    'name': 'AOGP',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': true,
    'allowTarget': false,
  },
  'aogpc': {
    'key': 'aogpc',
    'name': 'AOGP Contribution',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'aogpc_rate': {
    'key': 'aogpc_rate',
    'name': 'AOGP Contribution Rate',
    'format': 'percent',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'aov': {
    'key': 'aov',
    'name': 'AOV',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': true,
    'allowTarget': false,
  },
  'aovc': {
    'key': 'aovc',
    'name': 'AOV Contribution',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'aovc_rate': {
    'key': 'aovc_rate',
    'name': 'AOV Contribution Rate',
    'format': 'percent',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'aov_frontend': {
    'key': 'aov_frontend',
    'name': 'Frontend AOV',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': true,
    'allowTarget': false,
  },
  'aov_backend': {
    'key': 'aov_backend',
    'name': 'Backend AOV',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': true,
    'allowTarget': false,
  },
  'confidence': {
    'key': 'confidence',
    'name': 'Confidence',
    'format': 'percent',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'count': {
    'key': 'count',
    'name': 'Eligible People',
    'format': 'integer',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'cost': {
    'key': 'cost',
    'name': 'Total Cost',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'costc': {
    'key': 'costc',
    'name': 'Average Cost Contribution',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'costc_rate': {
    'key': 'costc_rate',
    'name': 'Cost Contribution Rate',
    'format': 'percent',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'cost_orders': {
    'key': 'cost_orders',
    'name': 'Order Costs',
    'format': 'currency',
    'allowCumulative': true,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'cost_per_order': {
    'key': 'cost_per_order',
    'name': 'Cost per Order',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'customers': {
    'key': 'customers',
    'name': 'Customers',
    'format': 'integer',
    'allowCumulative': false,
    'allowScorecard': true,
    'allowTarget': false,
  },
  'customers_frontend': {
    'key': 'customers_frontend',
    'name': 'New Customers',
    'format': 'integer',
    'allowCumulative': true,
    'allowScorecard': true,
    'allowTarget': true,
  },
  'customers_frontend_rate': {
    'key': 'customers_frontend_rate',
    'name': 'New Customer Rate',
    'format': 'percent',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'customers_backend': {
    'key': 'customers_backend',
    'name': 'Returning Customers',
    'cumulativeName': 'Returning Customers, non-unique',
    'format': 'integer',
    'allowCumulative': true,
    'allowScorecard': true,
    'allowTarget': false,
  },
  'customers_backend_rate': {
    'key': 'customers_backend_rate',
    'name': 'Returning Customer Rate',
    'cumulativeName': 'Returning Customer Rate, non-unique',
    'format': 'percent',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'customers_backend_rate_j': {
    'key': 'customers_backend_rate_j',
    'name': 'Returning Customer Rate (Journey)',
    'cumulativeName': 'Returning Customer Rate (Journey), non-unique',
    'format': 'percent',
    'allowCumulative': true,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'gross_profit_orders': {
    'key': 'gross_profit_orders',
    'name': 'Order Gross Profit',
    'format': 'currency',
    'allowCumulative': true,
    'allowScorecard': true,
    'allowTarget': true,
  },
  'order_rate': {
    'key': 'order_rate',
    'name': 'Order Rate',
    'format': 'percent',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'orders': {
    'key': 'orders',
    'name': 'Orders',
    'format': 'integer',
    'allowCumulative': true,
    'allowScorecard': true,
    'allowTarget': true,
  },
  'orders_frontend': {
    'key': 'orders_frontend',
    'name': 'Frontend Orders',
    'format': 'integer',
    'allowCumulative': true,
    'allowScorecard': true,
    'allowTarget': true,
  },
  'orders_backend': {
    'key': 'orders_backend',
    'name': 'Backend Orders',
    'format': 'integer',
    'allowCumulative': true,
    'allowScorecard': true,
    'allowTarget': true,
  },
  'revenue_backend_rate_j': {
    'key': 'revenue_backend_rate_j',
    'name': 'Returning Customer Revenue Added % (Journey)',
    'format': 'percent',
    'allowCumulative': true,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'revenue_net': {
    'key': 'revenue_net',
    'name': 'Net Revenue',
    'format': 'currency',
    'allowCumulative': true,
    'allowScorecard': true,
    'allowTarget': true,
  },
  'revenue_net_frontend': {
    'key': 'revenue_net_frontend',
    'name': 'Net Frontend Revenue',
    'format': 'currency',
    'allowCumulative': true,
    'allowScorecard': true,
    'allowTarget': true,
  },
  'revenue_net_backend': {
    'key': 'revenue_net_backend',
    'name': 'Net Backend Revenue',
    'format': 'currency',
    'allowCumulative': true,
    'allowScorecard': true,
    'allowTarget': true,
  },
  'revenue_gross': {
    'key': 'revenue_gross',
    'name': 'Gross Revenue',
    'format': 'currency',
    'allowCumulative': true,
    'allowScorecard': true,
    'allowTarget': true,
  },
  'revenue_orders': {
    'key': 'revenue_orders',
    'name': 'Order Revenue',
    'format': 'currency',
    'allowCumulative': true,
    'allowScorecard': true,
    'allowTarget': true,
  },
  'revenue_rebills': {
    'key': 'revenue_rebills',
    'name': 'Rebill Revenue',
    'format': 'currency',
    'allowCumulative': true,
    'allowScorecard': true,
    'allowTarget': true,
  },
  'revenue_refunds': {
    'key': 'revenue_refunds',
    'name': 'Refund Amount',
    'format': 'currency',
    'allowCumulative': true,
    'allowScorecard': true,
    'allowTarget': false,
  },
  'revenue_refunds_rate': {
    'key': 'revenue_refunds_rate',
    'name': 'Refund Rate',
    'format': 'percent',
    'allowCumulative': false,
    'allowScorecard': true,
    'allowTarget': false,
  },
  'rebills': {
    'key': 'rebills',
    'name': 'Rebills',
    'format': 'integer',
    'allowCumulative': true,
    'allowScorecard': true,
    'allowTarget': true,
  },
  'refunds': {
    'key': 'refunds',
    'name': 'Refunds',
    'format': 'integer',
    'allowCumulative': true,
    'allowScorecard': true,
    'allowTarget': false,
  },
  'ltv': {
    'key': 'ltv',
    'name': 'Lifetime Value',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'ltgpp': {
    'key': 'ltgpp',
    'name': 'Lifetime Gross Profit per Person',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'ltr': {
    'key': 'ltr',
    'name': 'Lifetime Revenue',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'ltgp': {
    'key': 'ltgp',
    'name': 'Lifetime Gross Profit',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
}

export default METRIC_ITEMS_MAP