import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'

import { capitalizeFirstLetter } from '../../utils/helpers'

const WIDTH = 250

const Sidebar = ({
  pluralItemName,
  isOpen,
  coreItems,
  savedItems,
  tab,
  selectedItem,
  allowCreateSavedItem,
  allowOnlySavedTab,
  groupSavedItemsByKey,
  onTabSelect,
  onItemSelect,
  onSavedItemSelect,
  onOpenToggle,
  onCreateSavedItemClick,
}) => {
  const isItemSelected = selectedItem !== null
  const isSavedItem = selectedItem?.kind === 'saved'

  // Sort saved items alphabetically by name if groupSavedItemsByKey is false
  const sortedSavedItems = useMemo(() => {
    if (groupSavedItemsByKey) return null
    return savedItems.sort((a, b) => a.name.localeCompare(b.name))
  }, [savedItems])

  // Group and sort saved items by key if groupSavedItemsByKey is true
  const groupedAndSortedSavedItems = useMemo(() => {
    if (!groupSavedItemsByKey) return null

    // Group by key, and sort the types to match the order of the core items
    const grouped = savedItems.reduce((acc, item) => {
      const key = item.key
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(item)
      return acc
    }, {})
    // Within each key group, sort by name A-Z
    const sorted = Object.keys(grouped).sort((a, b) => {
      const aIndex = coreItems.findIndex(r => r.key === a)
      const bIndex = coreItems.findIndex(r => r.key === b)
      return aIndex - bIndex
    }).map(key => {
      return {
        key,
        keyName: coreItems.find(r => r.key === key).name,
        items: grouped[key].sort((a, b) => a.name.localeCompare(b.name))
      }
    })
    return sorted
  }, [savedItems])

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        minWidth={isOpen ? WIDTH : 0}
        width={isOpen ? WIDTH : 0}
        sx={{
          backgroundColor: theme => theme.palette.background.paper,
          padding: theme => isOpen ? theme.spacing(2, 1, 2, 1) : 0,
          height: '100vh',
          overflowY: 'auto',
        }}
      >
        {!allowOnlySavedTab && (
          <Box
            width='100%'
            display='flex'
            justifyContent='center'
            sx={{
              padding: theme => theme.spacing(0, 1, 2, 1),
            }}
          >
            <ButtonGroup size='small' color='secondary'>
              <Button
                variant={tab === 'core' ? 'contained' : 'outlined'}
                onClick={() => onTabSelect('core')}
                sx={{ fontSize: 12 }}
              >
                Core
              </Button>
              <Button
                variant={tab === 'saved' ? 'contained' : 'outlined'}
                onClick={() => onTabSelect('saved')}
                sx={{ fontSize: 12 }}
              >
                Saved
              </Button>
            </ButtonGroup>
          </Box>
        )}

        {tab === 'core' && (
          <Box width='100%' >
            <Typography variant='body2' sx={{ fontWeight: 600, padding: theme => theme.spacing(0, 1) }}>
              {capitalizeFirstLetter(pluralItemName)}
            </Typography>
            <List dense>
              {coreItems.map(item => (
                <ListItem
                  key={item.key}
                  button
                  selected={!isSavedItem && isItemSelected && item.key === selectedItem.key}
                  onClick={() => onItemSelect(item)}
                  sx={{
                    borderRadius: theme => theme.shape.borderRadius / 4,
                  }}
                >
                  <ListItemText
                    primary={item.name}
                    primaryTypographyProps={{
                      style: {
                        fontSize: 14,
                        fontWeight: !isSavedItem && isItemSelected && item.key === selectedItem.key ? 600 : 400,
                      }
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        )}

        {tab === 'saved' && (
          <Box width='100%'>
            {allowCreateSavedItem && (
              <Box display='flex' justifyContent='center' marginBottom={2}>
                <Button
                  variant='contained'
                  size='small'
                  color='secondary'
                  disableElevation
                  startIcon={<AddIcon />}
                  onClick={onCreateSavedItemClick}
                  sx={{
                    fontSize: 12,
                    height: 29,
                  }}
                >
                  Create
                </Button>
              </Box>
            )}
            {savedItems.length === 0 ? (
              <Box width='100%' display='flex' justifyContent='center' paddingTop={1}>
                <Typography variant='body2' color='textSecondary'>
                  No saved {pluralItemName.toLowerCase()}
                </Typography>
              </Box>
            ) : (
              <List dense disablePadding>
                {groupSavedItemsByKey ? (
                  <React.Fragment>
                    {groupedAndSortedSavedItems.map((group, index) => (
                      <Box
                        key={group.key}
                        sx={{
                          marginBottom: theme => {
                            if (index === groupedAndSortedSavedItems.length - 1) {
                              return 0
                            } else {
                              return theme.spacing(2)
                            }
                          }
                        }}
                      >
                        <Typography
                          variant='body2'
                          gutterBottom
                          sx={{
                            fontWeight: 600,
                            padding: theme => theme.spacing(0, 1)
                          }}
                        >
                          {group.keyName}
                        </Typography>
                        {group.items.map(item => (
                          <ListItem
                            key={item.id}
                            button
                            selected={item.id === selectedItem.id}
                            onClick={() => onSavedItemSelect(item)}
                            sx={{
                              borderRadius: theme => theme.shape.borderRadius / 4,
                            }}
                          >
                            <ListItemText
                              primary={item.name}
                              primaryTypographyProps={{
                                style: {
                                  fontSize: 14,
                                  fontWeight: item.id === selectedItem.id ? 600 : 400,
                                }
                              }}
                            />
                          </ListItem>
                        ))}
                      </Box>
                    ))}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Typography
                      variant='body2'
                      gutterBottom
                      sx={{
                        fontWeight: 600,
                        padding: theme => theme.spacing(0, 1)
                      }}
                    >
                      {capitalizeFirstLetter(pluralItemName)}
                    </Typography>
                    {sortedSavedItems.map(item => (
                      <ListItem
                        key={item.id}
                        button
                        selected={isItemSelected && item.id === selectedItem.id}
                        onClick={() => onSavedItemSelect(item)}
                        sx={{
                          borderRadius: theme => theme.shape.borderRadius / 4,
                        }}
                      >
                        <ListItemText
                          primary={item.name}
                          primaryTypographyProps={{
                            style: {
                              fontSize: 14,
                              fontWeight: isItemSelected && item.id === selectedItem.id ? 600 : 400,
                            }
                          }}
                        />
                      </ListItem>
                    ))}
                  </React.Fragment>
                )}

              </List>
            )}
          </Box>
        )}
      </Box>

      {/* Collapse and expand button */}
      <Box
        sx={{
          backgroundColor: theme => theme.palette.background.paper,
          position: 'absolute',
          top: 0,
          right: isOpen ? 0 : -28,
          borderRadius: theme => isOpen ?
            `0 0 0 ${theme.shape.borderRadius}px` :
            `0 0 ${theme.shape.borderRadius}px 0`,
          minWidth: 'auto',
          border: theme => `1px solid ${theme.palette.divider}`,
        }}
      >
        <IconButton
          size='small'
          onClick={() => onOpenToggle(!isOpen)}
        >
          {isOpen ?
            <KeyboardArrowLeft fontSize='inherit' /> :
            <KeyboardArrowRight fontSize='inherit' />
          }
        </IconButton>
      </Box>
    </Box>
  )
}

Sidebar.defaultProps = {
  coreItems: [],
  groupSavedItemsByKey: false,
}

Sidebar.propTypes = {
  pluralItemName: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  coreItems: PropTypes.array,
  savedItems: PropTypes.array.isRequired,
  tab: PropTypes.string.isRequired,
  selectedItem: PropTypes.object,
  allowCreateSavedItem: PropTypes.bool,
  allowOnlySavedTab: PropTypes.bool,
  groupSavedItemsByKey: PropTypes.bool,
  onTabSelect: PropTypes.func.isRequired,
  onItemSelect: PropTypes.func,
  onSavedItemSelect: PropTypes.func.isRequired,
  onOpenToggle: PropTypes.func.isRequired,
  onCreateSavedItemClick: PropTypes.func,
}

export default Sidebar