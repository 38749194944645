exports.CONTENT_MAP = {
  'default': {
    'logo': '/login/ltvnumbers-dark.png',
    'heroLogo': null,
    'heroBackground': '/login/ltvnumbers-hero-background.png',
    'heroOverlayColor': 'rgba(34,103,234,0.80)',
    'buttonColor': '#2267ea',
  },
  'coxcapital': {
    'logo': '/login/coxcapital-logo.png',
    'heroLogo': '/login/coxcapital-hero-logo.png',
    'heroBackground': '/login/coxcapital-hero-background.png',
    'heroOverlayColor': null,
    'buttonColor': '#FFEB3B',
  },
}