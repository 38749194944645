import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { FirebaseContext } from '../../utils/firebase'
import 'firebase/auth'

import { useQuery } from 'react-query'
import moment from 'moment-timezone'

import ReportsControls from './ReportsControls'
import ReportsCards from './ReportsCards'

import { API_ROOT_URL, PUBLIC_DEMO_UID } from '../../constants'

const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const Reports = () => {
  const firebase = React.useContext(FirebaseContext)

  // Public Demo check to show the right content
  const uid = firebase.auth().currentUser ? firebase.auth().currentUser.uid : null
  const isPublicDemo = uid === PUBLIC_DEMO_UID

  const [dateRangeCompareType, setDateRangeCompareType] = React.useState('previous_period')
  const [startDate, setStartDate] = isPublicDemo ?
    React.useState(moment.tz(TIMEZONE).subtract(12, 'months').add(1, 'day').startOf('day'))
    : React.useState(moment.tz(TIMEZONE).startOf('day'))
  const [endDate, setEndDate] = React.useState(moment.tz(TIMEZONE).endOf('day'))
  const [resolution, setResolution] = isPublicDemo ?
    React.useState('month')
    : React.useState('hour')

  const { isLoading: datesAreLoading, data: dates } = useQuery(['date_range'], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/dates?tz=${TIMEZONE}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      }).then(res => res.json())
    }),
    {
      cacheTime: 15 * 60 * 1000,  // 15 minutes
      staleTime: 15 * 60 * 1000,  // 15 minutes
      refetchInterval: 60 * 60 * 1000,  // 60 minutes
      refetchOnWindowFocus: false,
    }
  )

  const handleDateRangeShortcutSelect = (id) => {
    let newStartDate
    let newEndDate
    switch (id) {
      case 'today': {
        newStartDate = moment.tz(TIMEZONE).startOf('day')
        newEndDate = moment.tz(TIMEZONE).endOf('day')
        setDateRangeCompareType('previous_period')
        break
      }
      case 'yesterday': {
        newStartDate = moment.tz(TIMEZONE).subtract(1, 'days').startOf('day')
        newEndDate = moment.tz(TIMEZONE).subtract(1, 'days').endOf('day')
        setDateRangeCompareType('previous_period')
        break
      }
      case 'last_7_days': {
        newStartDate = moment.tz(TIMEZONE).subtract(7, 'days').add(1, 'day').startOf('day')
        newEndDate = moment.tz(TIMEZONE).endOf('day')
        setDateRangeCompareType('previous_period')
        break
      }
      case 'last_4_weeks': {
        newStartDate = moment.tz(TIMEZONE).subtract(4, 'weeks').add(1, 'day').startOf('day')
        newEndDate = moment.tz(TIMEZONE).endOf('day')
        setDateRangeCompareType('previous_period')
        break
      }
      case 'last_3_months': {
        newStartDate = moment.tz(TIMEZONE).subtract(3, 'months').add(1, 'day').startOf('day')
        newEndDate = moment.tz(TIMEZONE).endOf('day')
        setDateRangeCompareType('previous_period')
        break
      }
      case 'last_12_months': {
        newStartDate = moment.tz(TIMEZONE).subtract(12, 'months').add(1, 'day').startOf('day')
        newEndDate = moment.tz(TIMEZONE).endOf('day')
        setDateRangeCompareType('previous_period')
        break
      }
      case 'month_to_date': {
        newStartDate = moment.tz(TIMEZONE).startOf('month').startOf('day')
        newEndDate = moment.tz(TIMEZONE).endOf('day')
        setDateRangeCompareType('previous_month')
        break
      }
      case 'quarter_to_date': {
        newStartDate = moment.tz(TIMEZONE).startOf('quarter').startOf('day')
        newEndDate = moment.tz(TIMEZONE).endOf('day')
        setDateRangeCompareType('previous_quarter')
        break
      }
      case 'year_to_date': {
        newStartDate = moment.tz(TIMEZONE).startOf('year').startOf('day')
        newEndDate = moment.tz(TIMEZONE).endOf('day')
        setDateRangeCompareType('previous_year')
        break
      }
      case 'all': {
        newStartDate = dates ? moment.tz(dates.first_date, TIMEZONE) : moment.tz(TIMEZONE).startOf('year').startOf('day')
        newEndDate = moment.tz(TIMEZONE).endOf('day')
        break
      }
    }
    setStartDate(newStartDate)
    setEndDate(newEndDate)
    setResolutionFromPeriod(newStartDate, newEndDate)
  }

  const handleDatesChange = ({ startDate: newStartDate, endDate: newEndDate }) => {
    setStartDate(newStartDate)
    setEndDate(newEndDate)
    setResolutionFromPeriod(newStartDate, newEndDate)
    setDateRangeCompareType('previous_period')
  }

  const setResolutionFromPeriod = (startDate, endDate) => {
    const periodDays = endDate.diff(startDate, 'days') + 1
    if (periodDays < 7) {
      setResolution('hour')
    } else if (periodDays >= 7 && periodDays < 60) {
      setResolution('day')
    } else if (periodDays >= 60 && periodDays < 90) {
      setResolution('week')
    } else if (periodDays >= 90 && periodDays < 730) {
      setResolution('month')
    } else if (periodDays >= 730) {
      setResolution('year')
    }
  }

  const getDateRangeCompareItems = (startDate, endDate) => {
    const periodDays = endDate.diff(startDate, 'days') + 1
    return {
      previous_period: {
        startDate: moment.tz(startDate, TIMEZONE).subtract(periodDays, 'days'),
        endDate: moment.tz(endDate, TIMEZONE).subtract(periodDays, 'days')
      },
      previous_month: {
        startDate: moment.tz(startDate, TIMEZONE).subtract(1, 'month'),
        endDate: moment.tz(endDate, TIMEZONE).subtract(1, 'month')
      },
      previous_quarter: {
        startDate: moment.tz(startDate, TIMEZONE).subtract(1, 'quarter'),
        endDate: moment.tz(endDate, TIMEZONE).subtract(1, 'quarter')
      },
      previous_year: {
        startDate: moment.tz(startDate, TIMEZONE).subtract(1, 'year'),
        endDate: moment.tz(endDate, TIMEZONE).subtract(1, 'year')
      }
    }
  }

  const dateRangeCompareItems = getDateRangeCompareItems(startDate, endDate)

  // Construct compare and primary date ranges for use in Report Cards
  const compareDateRange = {
    start: dateRangeCompareItems[dateRangeCompareType].startDate.format(),
    end: dateRangeCompareItems[dateRangeCompareType].endDate.format(),
  }
  const primaryDateRange = {
    start: startDate.format(),
    end: endDate.format(),
  }

  return (
    <Box>
      <Typography variant='h5' gutterBottom>
        Reports
      </Typography>
      <ReportsControls
        startDate={startDate}
        endDate={endDate}
        resolution={resolution}
        dateRangeCompareItems={dateRangeCompareItems}
        dateRangeCompareType={dateRangeCompareType}
        setResolution={setResolution}
        setDateRangeCompareType={setDateRangeCompareType}
        onDatesChange={handleDatesChange}
        onDateRangeShortcutSelect={handleDateRangeShortcutSelect}
        datesAreLoading={datesAreLoading}
      />
      <ReportsCards
        primaryDateRange={primaryDateRange}
        compareDateRange={compareDateRange}
        resolution={resolution}
      />
    </Box>
  )
}

Reports.propTypes = {}

export default Reports