import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import { alpha } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'

const SEARCH_DELAY_MS = 500

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    border: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
    borderRadius: theme.shape.borderRadius,
    marginLeft: props => props.addMarginLeft ? theme.spacing(2) : 0,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.04),
      borderColor: alpha(theme.palette.primary.main, 1),
    },
    '&:focus-within': {
      borderColor: alpha(theme.palette.primary.main, 1),
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  clearIcon: {
    position: 'absolute',
    top: -4,
    right: 4,
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    height: 18,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '24ch',
    },
  },
}))

let debounceTimer = null

const SearchInput = (props) => {
  const classes = useStyles(props)
  const [value, setValue] = React.useState(props.value)

  React.useEffect(() => {
    clearTimeout(debounceTimer)
    debounceTimer = setTimeout(() => {props.onChange(value)}, SEARCH_DELAY_MS)
  }, [value, props])

  return (
    <div className={classes.root}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder={props.placeholder}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
        value={value}
        onChange={event => setValue(event.target.value)}
        color='primary'
      />
      {value.length > 0 &&
        <IconButton className={classes.clearIcon} onClick={() => setValue('')} size="large">
          <ClearIcon fontSize='small' />
        </IconButton>
      }
    </div>
  );
}

SearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  addMarginLeft: PropTypes.bool,
  placeholder: PropTypes.string
}

SearchInput.defaultProps = {
  addMarginLeft: false,
  placeholder: 'Search...',
}

export default SearchInput
