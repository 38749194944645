import React, { useContext, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'
import { useHistory } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { useQueryClient } from 'react-query'

import { UserContext } from '../../contexts/UserContext'
import { FirebaseContext } from '../../utils/firebase'
import { createDocument, deleteDocument, getCollectionDocuments, listenToCollectionUpdates, updateDocument } from '../../utils/firestore'
import { getSegmentById, getNextAvailableColor, getSegmentDisplayName } from '../../utils/helpers'

import Target from './Target'
import Sidebar from './Sidebar'

import { DEFAULT_TARGET_METRIC_KEY, FIRESTORE_COLLECTIONS } from '../../constants'
import * as ROUTES from '../../constants/routes'
import METRIC_ITEMS_MAP from './MetricItems'
import TARGET_BASE_CONFIG from './TargetConfig'
import LoadingSkeleton from './LoadingSkeleton'

const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: 62,
    right: 0,
    width: 'calc(100% - 62px)',
    height: '100vh',
    overflowY: 'auto',
    backgroundColor: theme.palette.grey[50],
  },
}))

const CORE_SIDEBAR_ITEMS = Object.values(METRIC_ITEMS_MAP)
  .filter(metric => metric.allowTarget)
  .sort((a, b) => a.name.localeCompare(b.name))

const Targets = () => {
  const classes = useStyles()
  const firebase = useContext(FirebaseContext)
  const userDoc = useContext(UserContext)
  const history = useHistory()
  const queryClient = useQueryClient()

  const [sidebarTab, setSidebarTab] = useState('core')
  const [selectedTarget, setSelectedTarget] = useState(null)
  const [sidebarOpen, setSidebarOpen] = useState(true)

  const [savedTargets, setSavedTargets] = useState([])
  const [savedSegments, setSavedSegments] = React.useState([])
  const [savedGroups, setSavedGroups] = React.useState([])

  const [liveMode, setLiveMode] = React.useState(false)
  const [startDate, setStartDate] = React.useState(null)
  const [endDate, setEndDate] = React.useState(null)
  const [resolution, setResolution] = React.useState(null)
  const [config, setConfig] = React.useState({})
  const [targetValue, setTargetValue] = React.useState(null)
  const [showCumulative, setShowCumulative] = React.useState(false)
  const [segments, setSegments] = React.useState([])

  useEffect(() => {
    let isMounted = true

    getIntialSelectedTarget().then(target => {
      if (isMounted) {
        handleTargetSelect(target)
        handleSidebarTabSelect(target.kind)
      }
    })

    return () => {
      isMounted = false
    }
  }, [])

  // When liveMode is active, update the endDate every minute to handle
  // the date changing while the user is viewing the report
  useEffect(() => {
    let timer = null
    if (liveMode) {
      timer = setInterval(() => {
        // Only update the end date if the current end date is the day before today
        // because that means we have crossed midnight with liveMode enabled
        if (endDate.isSame(moment.tz(TIMEZONE).subtract(1, 'day'), 'day')) {
          setEndDate(moment.tz(TIMEZONE).endOf('day'))
        }
      }, 60000) // Runs every minute
    }
    return () => {
      if (timer) {
        clearInterval(timer)
      }
    }
  }, [liveMode])

  async function getIntialSelectedTarget() {
    // Get saved targets from Firestore
    const savedTargets = await getCollectionDocuments(firebase, FIRESTORE_COLLECTIONS.TARGETS)

    // Try to get initial target metric from the URL
    const targetUrlMatch = history.location.pathname.match(/\/targets\/([^/]+)(?:\/([^/]+))?/)
    const targetMetricKey = targetUrlMatch ? targetUrlMatch[1] : null
    const savedTargetId = targetUrlMatch ? targetUrlMatch[2] : null

    // If the target has an ID, then it is a saved target
    if (savedTargetId) {
      const savedTarget = savedTargets.find(r => r.id === savedTargetId)

      if (savedTarget) return savedTarget
      // If the saved target can't be found, then the ID is invalid, so fallback to
      // a core target with the same metric key
      else {
        const targetMetric = METRIC_ITEMS_MAP[targetMetricKey]
        // If the metric key is invalid, fallback to the default target metric key
        if (targetMetric && targetMetric.allowTarget) {
          return getBaseTargetWithMetricKey(targetMetricKey)
        }
        else {
          return getBaseTargetWithMetricKey(DEFAULT_TARGET_METRIC_KEY)
        }
      }
    }
    // Find the core target of a matching type
    else {
      const targetMetric = METRIC_ITEMS_MAP[targetMetricKey]
      // If the core target can't be found then the URL was likely manually modified
      // so fallback to the default target metric
      if (targetMetric && targetMetric.allowTarget) {
        return getBaseTargetWithMetricKey(targetMetricKey)
      }
      else {
        return getBaseTargetWithMetricKey(DEFAULT_TARGET_METRIC_KEY)
      }
    }
  }

  function getBaseTargetWithMetricKey(metricKey) {
    return {
      ...TARGET_BASE_CONFIG,
      key: metricKey,
      metricKey,
    }
  }

  function getTargetValueFromTarget(target) {
    return target.targetValue
  }

  function getStartDateFromTarget(target) {
    if (target.startDate) {
      return moment.tz(target.startDate, TIMEZONE).startOf('day')
    } else {
      return moment.tz(TIMEZONE).startOf('year')
    }
  }

  function getEndDateFromTarget(target) {
    if (target.endDate) {
      return moment.tz(target.endDate, TIMEZONE).endOf('day')
    } else {
      return moment.tz(TIMEZONE).endOf('year')
    }
  }

  function getResolutionFromTarget(target) {
    return target.resolution
  }

  function getConfigFromTarget(target) {
    return target.config
  }

  function getShowCumulativeFromTarget(target) {
    return target.chart.cumulativeDefault
  }

  function getSegmentsFromTarget(target) {
    if (target.segments.length === 0) {
      return [{ ...createNewSegment(true) }]
    } else {
      return target.segments
    }
  }

  function mergeSavedTargetWithBaseConfig(savedTarget) {
    // Combine the core target with the saved target to ensure that all necessary
    // parameters are present, and the saved target takes precedence
    return { ...TARGET_BASE_CONFIG, ...savedTarget }
  }

  function mergePrevConfigWithNewTarget(prevConfig, newTarget) {
    // For each key in the previous config, check if that key in is the new target's config
    // If it is, preserve the previous config's value, otherwise use the new target's value
    const targetConfig = getConfigFromTarget(newTarget)
    let newConfig = {...targetConfig}
    for (const key in targetConfig) {
      if (key in prevConfig) {
        newConfig[key] = prevConfig[key]
      }
    }
    return newConfig
  }

  const createNewSegment = (isInitialization=false) => {
    const newSegment = {
      id: uuidv4(),
      segmentFilters: [],
      filters: [],
      breakdown: null,
      isActive: true,
      color: isInitialization ? getNextAvailableColor([], userDoc.secondaryColor) : getNextAvailableColor(segments, userDoc.secondaryColor),
      breakdownState: {},
      savedSegmentEditId: null,
      revertSegmentData: null,
    }
    return updateSegment(newSegment, {})
  }

  const updateSegment = (segment, updates, newSavedSegment=null) => {
    const updatedSegment = {
      ...segment,
      ...updates,
      name: getSegmentDisplayName({ ...segment, ...updates }, newSavedSegment ? [...savedSegments, newSavedSegment] : savedSegments),
    }
    return updatedSegment
  }

  const handleSidebarOpenToggle = (open) => {
    setSidebarOpen(open)
  }

  const handleSidebarTabSelect = (newTab) => {
    setSidebarTab(newTab)
  }

  const handleSidebarMetricSelect = (metric) => {
    const metricKey = metric.key
    const target = getBaseTargetWithMetricKey(metricKey)
    handleTargetSelect(target)
  }

  const handleSidebarSavedTargetSelect = (savedTarget) => {
    handleTargetSelect(savedTarget)
  }

  const handleTargetSelect = (newTarget) => {
    // There are two top level cases:
    // -- 1. selecting a target for the first time
    // -- 2. switching from one target to another

    // 1. selecting a target for the first time
    if (selectedTarget === null) {
      initializeTargetState(newTarget)
    }
    // -- 2. switching from one target to another
    else {
      // If switching from one target kind to another, reset all state based on the new target
      if (selectedTarget.kind !== newTarget.kind) {
        initializeTargetState(newTarget)
      }
      // If switching between two saved targets, reset all state based on the new target
      else if (selectedTarget.kind === 'saved' && newTarget.kind === 'saved') {
        initializeTargetState(newTarget)
      }
      // If switching between two core targets, preserve the all existing state except targetValue
      // and only override the config in case the new target has config not previously present
      else {
        setTargetValue(null)
        setConfig(prevConfig => mergePrevConfigWithNewTarget(prevConfig, newTarget))
      }
    }

    // Saved targets must be merged with the base config
    // to ensure all config is present for populating the UI
    if (newTarget.kind === 'saved') {
      newTarget = mergeSavedTargetWithBaseConfig(newTarget)
    }

    // Set showCumulative to default target config value
    setShowCumulative(getShowCumulativeFromTarget(newTarget))

    setSelectedTarget(newTarget)
    let newPath = ROUTES.TARGETS + `/${newTarget.metricKey}`
    if (newTarget.kind === 'saved') {
      newPath += `/${newTarget.id}`
    }
    history.push(newPath)
  }

  const initializeTargetState = (target) => {
    setTargetValue(getTargetValueFromTarget(target))
    setStartDate(getStartDateFromTarget(target))
    setEndDate(getEndDateFromTarget(target))
    setResolution(getResolutionFromTarget(target))
    setConfig(getConfigFromTarget(target))
    setSegments(getSegmentsFromTarget(target))
  }

  const handleTargetSave = async (targetId, formData) => {
    const targetToSave = {
      kind: 'saved',
      ...formatTargetObjectFromState(),
      ...formData,
    }

    // If targetId is provided, update existing target
    if (targetId) {
      await updateDocument(firebase, FIRESTORE_COLLECTIONS.TARGETS, targetId, targetToSave)
    }
    // Create a new target
    else {
      targetId = await createDocument(firebase, FIRESTORE_COLLECTIONS.TARGETS, targetToSave)
    }
    handleTargetSelect({ id: targetId, ...targetToSave })
    handleSidebarTabSelect('saved')
  }

  const handleTargetDelete = async (targetId) => {
    await deleteDocument(firebase, FIRESTORE_COLLECTIONS.TARGETS, targetId)

    // Select a core target of the same metricKey
    handleTargetSelect(getBaseTargetWithMetricKey(selectedTarget.metricKey))
    handleSidebarTabSelect('core')
  }

  const formatTargetObjectFromState = () => {
    let targetData = {
      key: selectedTarget.key,
      xKey: selectedTarget.xKey,
      metricKey: selectedTarget.metricKey,

      // These are based on current values from the state
      targetValue,
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      resolution,
      config,
      segments,
    }

    return targetData
  }

  const handleLiveModeChange = (newLiveMode) => {
    setLiveMode(newLiveMode)
  }

  const handleDatesChange = ({ startDate: newStartDate, endDate: newEndDate }) => {
    setStartDate(newStartDate)
    setEndDate(newEndDate)
    setResolutionFromPeriod(newStartDate, newEndDate)
    // If live mode is enabled and today is not in the date range, disable live mode
    // because live mode is only available when today is in the date range
    if (liveMode && !moment.tz(TIMEZONE).isBetween(newStartDate, newEndDate, 'day', '[]')) {
      setLiveMode(false)
    }
  }

  const setResolutionFromPeriod = (startDate, endDate) => {
    const periodDays = endDate.diff(startDate, 'days') + 1
    if (periodDays >= 0 && periodDays <= 30) {
      setResolution(0)
    } else if (periodDays >= 31 && periodDays <= 179) {
      setResolution(1)
    } else if (periodDays >= 180 && periodDays <= 732) {
      setResolution(2)
    } else if (periodDays > 732) {
      setResolution(3)
    }
  }

  const handleSegmentAdd = (segmentFilterToInclude=null) => {
    const newSegment = updateSegment(createNewSegment(), {
      segmentFilters: segmentFilterToInclude ? [segmentFilterToInclude] : [],
    })
    setSegments(prevSegments => [newSegment, ...prevSegments])
  }

  const handleSegmentDelete = (segmentId) => {
    setSegments(prevSegments => prevSegments.filter(segment => segment.id !== segmentId))
  }

  const handleSegmentDuplicate = (segmentId) => {
    const segment = getSegmentById(segments, segmentId)
    const segmentIndex = segments.findIndex(segment => segment.id === segmentId)
    const newSegment = {
      ...createNewSegment(),
      name: segment.name,
      segmentFilters: segment.segmentFilters,
      filters: segment.filters,
    }
    // Insert the duplicated segment right after the segment being duplicated
    setSegments(prevSegments => [
      ...prevSegments.slice(0, segmentIndex + 1),
      newSegment,
      ...prevSegments.slice(segmentIndex + 1),
    ])
  }

  const handleSegmentEditChange = (segmentId, savedSegmentEditId) => {
    // Find savedSegmentEditId in savedSegents, get the segmentFilters and filters from the saved segment
    // and append them to those fields in segment of segmentId
    const savedSegment = savedSegments.find(savedSegment => savedSegment.id === savedSegmentEditId)
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId) {
        return updateSegment(segment, {
          savedSegmentEditId,
          revertSegmentData: segment,
          segmentFilters: [...savedSegment.segmentFilters],
          filters: [...savedSegment.filters, ...segment.filters],
        })
      } else {
        return segment
      }
    }))
  }

  const handleSegmentSaveEdit = (segmentId) => {
    const segment = getSegmentById(segments, segmentId)
    const savedSegment = getSegmentById(savedSegments, segment.savedSegmentEditId)
    const segmentToSave = {
      name: savedSegment.name,
      segmentFilters: segment.segmentFilters,
      filters: segment.filters,
    }
    updateDocument(firebase, FIRESTORE_COLLECTIONS.SEGMENTS, savedSegment.id, segmentToSave)
    const newSegmentFilter = { id: savedSegment.id }

    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId) {
        const segmentUpdate = {
          savedSegmentEditId: null,
          revertSegmentData: null,
          segmentFilters: [newSegmentFilter],
          filters: [],
        }

        // Force a refresh of the queries
        queryClient.refetchQueries()

        return updateSegment(segment, segmentUpdate)
      } else {
        return segment
      }
    }))
  }

  const handleSegmentRevertEdit = (segmentId) => {
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId) {
        return updateSegment(segment, {
          ...segment.revertSegmentData
        })
      } else {
        return segment
      }
    }))
  }

  const handleSegmentIsActiveChange = (segmentId, isActive) => {
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId) {
        return updateSegment(segment, { isActive })
      } else {
        return segment
      }
    }))
  }

  const handleSegmentColorChange = (segmentId, newColor) => {
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId) {
        return updateSegment(segment, { color: newColor })
      } else {
        return segment
      }
    }))
  }

  const handleSegmentFilterAdd = (segmentId, segmentFilter) => {
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId) {
        const segmentFilters = [...segment.segmentFilters, segmentFilter]
        return updateSegment(segment, { segmentFilters })
      } else {
        return segment
      }
    }))
  }

  const handleSegmentReplaceFiltersWithSavedSegmentFilter = (segmentId, newSavedSegment) => {
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId) {
        const newSegmentFilter = { id: newSavedSegment.id }
        return updateSegment(segment, { segmentFilters: [newSegmentFilter], filters: [] }, newSavedSegment)
      } else {
        return segment
      }
    }))
  }

  const handleSegmentFilterDelete = (segmentId, segmentFilterId) => {
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId) {
        const segmentFilters = segment.segmentFilters.filter(filter => filter.id !== segmentFilterId)
        return updateSegment(segment, { segmentFilters })
      } else {
        return segment
      }
    }))
  }

  const handleSegmentsReorder = (newSegments) => {
    setSegments(newSegments)
  }

  const handleFilterAdd = (segmentId, filter) => {
    // if segmentId is null, add filter to all segments
    // else add filter to the segment with the matching segmentId
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segmentId === null || segment.id === segmentId) {
        const filters = [...segment.filters, filter]
        return updateSegment(segment, { filters })
      } else {
        return segment
      }
    }))
  }

  const handleFilterUpdate = (segmentId, filterIndex, filter) => {
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId) {
        // Find the filter at the index and replace it with 'filter'
        const filters = segment.filters.map((f, i) => i === filterIndex ? filter : f)
        return updateSegment(segment, { filters })
      } else {
        return segment
      }
    }))
  }

  const handleFilterDelete = (segmentId, filterIndex) => {
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId) {
        const filters = [...segment.filters]
        filters.splice(filterIndex, 1)
        return updateSegment(segment, { filters })
      } else {
        return segment
      }
    }))
  }

  const handleClearAllSegmentFilters = (segmentId) => {
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId) {
        return updateSegment(segment, { segmentFilters: [], filters: [] })
      } else {
        return segment
      }
    }))
  }

  const handleSegmentBreakdownSelect = (segmentId, breakdown) => {
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId && segment.breakdown?.field !== breakdown?.field) {
        return updateSegment(segment, {
          breakdown,
          breakdownState: breakdown ? segment.breakdownState : {},
        })
      } else {
        return segment
      }
    }))
  }

  const handleSegmentBreakdownIsActiveChange = (segmentId, breakdownKey, isActive) => {
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId) {
        const breakdownState = {
          ...segment.breakdownState,
          [breakdownKey]: {
            isActive,
            color: isActive ? getNextAvailableColor(prevSegments) : null,
          }
        }
        return updateSegment(segment, { breakdownState })
      } else {
        return segment
      }
    }))
  }

  const handleSegmentBreakdownColorChange = (segmentId, breakdownKey, newColor) => {
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId) {
        const breakdownState = {
          ...segment.breakdownState,
          [breakdownKey]: {
            ...segment.breakdownState[breakdownKey],
            color: newColor
          }
        }
        return updateSegment(segment, { breakdownState })
      } else {
        return segment
      }
    }))
  }

  const handleResolutionChange = (newResolution) => {
    setResolution(newResolution)
  }

  const handleTargetValueChange = (newValue) => {
    setTargetValue(newValue ? parseFloat(newValue) : null)
  }

  const handleConfigChange = (key, newValue) => {
    setConfig(prevConfig => ({
      ...prevConfig,
      [key]: newValue
    }))
  }

  const handleShowCumulativeChange = (show) => {
    setShowCumulative(show)
  }

  const regenerateSegmentNames = () => {
    setSegments(prevSegments => prevSegments.map(segment => {
      return updateSegment(segment, {})
    }))
  }

  // Realtime listener for Firestore targets collection
  useEffect(() => {
    const unsubscribe = listenToCollectionUpdates(firebase, FIRESTORE_COLLECTIONS.TARGETS, snapshot => {
      const newDocs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
      setSavedTargets(newDocs)
    })
    return unsubscribe
  }, [firebase])

  // Realtime listener for Firestore segments collection
  useEffect(() => {
    const unsubscribe = listenToCollectionUpdates(firebase, FIRESTORE_COLLECTIONS.SEGMENTS, snapshot => {
      const newDocs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
      setSavedSegments(newDocs)
    })
    return unsubscribe
  }, [firebase])

  // Realtime listener for Firestore groups collection
  useEffect(() => {
    const unsubscribe = listenToCollectionUpdates(firebase, FIRESTORE_COLLECTIONS.GROUPS, snapshot => {
      const newDocs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
      setSavedGroups(newDocs)
    })
    return unsubscribe
  }, [firebase])

  // Listen for changes to savedSegments to clean up delete segments if needed
  useEffect(() => {
    // Check the segmentFilters of each segment for any references to deleted saved segments
    segments.forEach(segment => {
      segment.segmentFilters.forEach(segmentFilter => {
        if (!savedSegments.find(savedSegment => savedSegment.id === segmentFilter.id)) {
          handleSegmentFilterDelete(segment.id, segmentFilter.id)
        }
      })
    })
    // Regenerate all segment names in case a referenced item was renamed
    regenerateSegmentNames()
  }, [savedSegments])

  // Listen for changes to savedGroups to clean up delete segments if needed
  useEffect(() => {
    // Check the filters of each segment for any references to deleted saved groups
    segments.forEach((segment, segmentIndex) => {
      segment.filters.forEach(filter => {
        filter.groupIds.forEach(groupId => {
          if (!savedGroups.find(savedGroup => savedGroup.id === groupId)) {
            handleFilterDelete(segmentIndex, filter)
          }
        })
      })
    })
    // Regenerate all segment names in case a referenced item was renamed
    regenerateSegmentNames()
  }, [savedGroups])

  return (
    <Box className={classes.root} display='flex' flexDirection='row' >
      <Sidebar
        pluralItemName={'targets'}
        isOpen={sidebarOpen}
        coreItems={CORE_SIDEBAR_ITEMS}
        savedItems={savedTargets}
        tab={sidebarTab}
        selectedItem={selectedTarget}
        groupSavedItemsByKey={true}
        onTabSelect={handleSidebarTabSelect}
        onItemSelect={handleSidebarMetricSelect}
        onSavedItemSelect={handleSidebarSavedTargetSelect}
        onOpenToggle={handleSidebarOpenToggle}
      />
      {selectedTarget === null ? (
        <Box width='100%' padding={4}>
          <LoadingSkeleton animation={'wave'} height='100%' width='100%' />
        </Box>
      ) : (
        <Target
          target={selectedTarget}
          savedSegments={savedSegments}
          savedGroups={savedGroups}
          liveMode={liveMode}
          startDate={startDate}
          endDate={endDate}
          resolution={resolution}
          config={config}
          targetValue={targetValue}
          showCumulative={showCumulative}
          segments={segments}
          onTargetSave={handleTargetSave}
          onTargetDelete={handleTargetDelete}
          onLiveModeChange={handleLiveModeChange}
          onDatesChange={handleDatesChange}
          onResolutionChange={handleResolutionChange}
          onConfigChange={handleConfigChange}
          onTargetValueChange={handleTargetValueChange}
          onShowCumulativeChange={handleShowCumulativeChange}
          onSegmentAdd={handleSegmentAdd}
          onSegmentDelete={handleSegmentDelete}
          onSegmentDuplicate={handleSegmentDuplicate}
          onSegmentEditChange={handleSegmentEditChange}
          onSegmentSaveEdit={handleSegmentSaveEdit}
          onSegmentRevertEdit={handleSegmentRevertEdit}
          onSegmentColorChange={handleSegmentColorChange}
          onSegmentIsActiveChange={handleSegmentIsActiveChange}
          onSegmentFilterAdd={handleSegmentFilterAdd}
          onSegmentReplaceFiltersWithSavedSegmentFilter={handleSegmentReplaceFiltersWithSavedSegmentFilter}
          onSegmentFilterDelete={handleSegmentFilterDelete}
          onSegmentsReorder={handleSegmentsReorder}
          onSegmentBreakdownSelect={handleSegmentBreakdownSelect}
          onSegmentBreakdownColorChange={handleSegmentBreakdownColorChange}
          onSegmentBreakdownIsActiveChange={handleSegmentBreakdownIsActiveChange}
          onFilterAdd={handleFilterAdd}
          onFilterUpdate={handleFilterUpdate}
          onFilterDelete={handleFilterDelete}
          onClearAllSegmentFilters={handleClearAllSegmentFilters}
        />
      )}
    </Box>
  )
}

Targets.propTypes = {
}

export default Targets