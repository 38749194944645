import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Popover from '@mui/material/Popover'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CheckIcon from '@mui/icons-material/Check'
import makeStyles from '@mui/styles/makeStyles'

import moment from 'moment-timezone'

import { FirebaseContext } from '../../utils/firebase'
import 'firebase/auth'
import { useQuery } from 'react-query'

import { API_ROOT_URL, BLUE_ACCENT_COLOR } from '../../constants'
import { formatNumber } from '../../utils/helpers'

import ChangeBadge from './ChangeBadge'
import DayGraph from './DayGraph'
import DayRankings from './DayRankings'
import SingleDatePicker from '../Common/SingleDatePicker'

const useStyles = makeStyles(theme => ({
  root: {
  },
  box: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  metricGroup: {
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
    },
  },
  controlButton: {
    textTransform: 'none',
    fontSize: '1rem',
  },
  metricTypographyPrimary: {
    paddingLeft: 5,
    fontWeight: 'bold',
    color: BLUE_ACCENT_COLOR,
  },
  metricTypographySecondary: {
    paddingLeft: 5,
    color: theme.palette.text.secondary
  },
  popover: {
    marginTop: theme.spacing(0.5),
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItemIcon: {
    minWidth: 24,
  },
  listItemIconFontSize: {
    fontSize: 16,
  },
  listItemTextSelected: {
    color: BLUE_ACCENT_COLOR
  }
}))

const metricSelectItems = [
  {id: 'revenue', name: 'Sales Volume', isDollar: true, decimalCount: 2},
  {id: 'customers_new', name: 'New Customers', isDollar: false, decimalCount: 0},
  {id: 'orders', name: 'Orders', isDollar: false, decimalCount: 0},
]
const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const DayStats = () => {
  const classes = useStyles()
  const firebase = React.useContext(FirebaseContext)
  const [metricAnchorEl, setMetricAnchorEl] = React.useState(null)
  const [compareDateAnchorEl, setCompareDateAnchorEl] = React.useState(null)
  const [metric, setMetric] = React.useState('revenue')
  const [currentDate, setCurrentDate] = React.useState(moment.tz(TIMEZONE).startOf('day'))
  const [compareDate, setCompareDate] = React.useState(moment.tz(TIMEZONE).subtract(1, 'days').startOf('day'))

  const { isLoading, data } = useQuery(['day-stats', metric, compareDate, TIMEZONE], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/daily/today`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          metric,
          primaryDate: currentDate.format(),
          compareDate: compareDate.format(),
          timezone: TIMEZONE
        })
      }).then(res => res.json())
    }),
    {
      refetchInterval: 15 * 60 * 1000,  // 15 minutes
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
    }
  )

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(moment.tz(TIMEZONE))
    }, 1000)
    return () => {
      clearInterval(intervalId)
    }
  }, [])

  const handleCompareDateClick = (event) => {
    setCompareDateAnchorEl(event.currentTarget)
  }

  const handleMetricClick = (event) => {
    setMetricAnchorEl(event.currentTarget)
  }

  const handleCompareDateChange = (date) => {
    setCompareDate(date)
    setCompareDateAnchorEl(null)
  }

  const handleMetricSelect = (id) => {
    setMetric(id)
    setMetricAnchorEl(null)
  }

  const handleCompareDateClose = () => {
    setCompareDateAnchorEl(null)
  }

  const handleMetricClose = () => {
    setMetricAnchorEl(null)
  }

  const selectedMetric = metricSelectItems.find(el => el.id === metric)
  const totalData = data ? data.total : {}
  const graphData = data ? data.graph : []

  return (
    <div className={classes.root}>
      <Typography variant='h5' display='block' gutterBottom>
        Today
      </Typography>
      <Box display='flex' flexDirection='row' className={classes.box}>

      <Box minWidth={200}>
          <Button
            className={classes.controlButton}
            size='small'
            endIcon={<ArrowDropDownIcon />}
            onClick={handleMetricClick}
          >
            {selectedMetric.name}
          </Button>
          <Popover
            className={classes.popover}
            open={Boolean(metricAnchorEl)}
            anchorEl={metricAnchorEl}
            onClose={handleMetricClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
            transformOrigin={{ vertical: 'top', horizontal: 'left', }}
          >
            <Box>
              <List component='ul' aria-label='metric selector'>
                {metricSelectItems.map(item => {
                  const selected = item.id === metric
                  return (
                    <ListItem
                      key={item.id}
                      className={classes.listItem}
                      onClick={() => handleMetricSelect(item.id)}
                      button
                    >
                      <ListItemIcon
                        className={classes.listItemIcon}
                        style={{ visibility: selected ? 'visible' : 'hidden', color: selected ? BLUE_ACCENT_COLOR : 'inherit' }}
                      >
                        <CheckIcon classes={{ root: classes.listItemIconFontSize }}/>
                      </ListItemIcon>

                      <ListItemText
                        primary={item.name}
                        classes={{
                          primary: selected ? classes.listItemTextSelected : ''
                        }}
                      />
                    </ListItem>
                  )
                })}
              </List>
            </Box>
          </Popover>
          <Box display='flex' flexDirection='row' alignItems='center' className={classes.metricGroup}>
            {isLoading ? <Skeleton variant="rectangular" width={120} height={32} /> : (
              <>
                <Box marginRight={1}>
                  <Typography className={classes.metricTypographyPrimary} variant='h6'>
                    {selectedMetric.isDollar ? '$' : ''}{formatNumber(totalData.primary, selectedMetric.decimalCount)}
                  </Typography>
                </Box>
                <ChangeBadge
                  metricPrimary={totalData.primary}
                  metricSecondary={totalData.compare}
                />
              </>
            )}
          </Box>
        </Box>
        <Box alignItems='flex-start'>
          <Button
            className={classes.controlButton}
            size='small'
            endIcon={<ArrowDropDownIcon />}
            onClick={handleCompareDateClick}
          >
            {moment.tz(TIMEZONE).diff(compareDate, 'd') !== 1 ? compareDate.format('MMM D, YYYY') : 'Yesterday'}
          </Button>
          <Popover
            className={classes.popover}
            open={Boolean(compareDateAnchorEl)}
            anchorEl={compareDateAnchorEl}
            onClose={handleCompareDateClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
            transformOrigin={{ vertical: 'top', horizontal: 'left', }}
          >
            <Box>
              <SingleDatePicker
                date={compareDate}
                onDateChange={handleCompareDateChange}
                disableToday={true}
              />
            </Box>
          </Popover>
          {isLoading ? <Skeleton variant="rectangular" width={120} height={32} /> : (
            <Typography className={classes.metricTypographySecondary} variant='h6'>
              {selectedMetric.isDollar ? '$' : ''}{formatNumber(totalData.compare, selectedMetric.decimalCount)}
            </Typography>
          )}
        </Box>
      </Box>

      <Box marginTop={1}>
        <DayGraph
          primaryDate={currentDate}
          compareDate={compareDate}
          data={graphData}
          isLoading={isLoading}
          metric={selectedMetric}
        />
      </Box>

      <Box marginTop={1}>
        <DayRankings
          date={currentDate}
          metric={selectedMetric}
        />
      </Box>
    </div>
  );
}

DayStats.propTypes = {}

export default DayStats