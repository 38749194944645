import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import ArrowDropUp from '@mui/icons-material/ArrowDropUp'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import { formatNumber } from '../../utils/helpers'
import { Typography } from '@mui/material'

const BADGE_BACKGROUND_COLOR_POSITIVE = '#cbf4c9'
const BADGE_TEXT_COLOR_POSITIVE = 'rgb(14, 98, 69)'
const BADGE_BACKGROUND_COLOR_NEGATIVE = '#f8e5b9'
const BADGE_TEXT_COLOR_NEGATIVE = 'rgb(152, 55, 5)'
const BADGE_BACKGROUND_COLOR_NEUTRAL = '#e3e8ee'
const BADGE_TEXT_COLOR_NEUTRAL = 'rgb(60, 66, 87)'

const ChangeBadge = (props) => {
  let bgColor = BADGE_BACKGROUND_COLOR_NEUTRAL
  let color = BADGE_TEXT_COLOR_NEUTRAL
  let prefix = null

  if (props.metricPrimary > props.metricSecondary && props.metricSecondary) {
    bgColor = BADGE_BACKGROUND_COLOR_POSITIVE
    color = BADGE_TEXT_COLOR_POSITIVE
    prefix = <ArrowDropUp />
  } else if (props.metricPrimary < props.metricSecondary) {
    bgColor = BADGE_BACKGROUND_COLOR_NEGATIVE
    color = BADGE_TEXT_COLOR_NEGATIVE
    prefix = <ArrowDropDown />
  }

  let value = Math.abs(props.metricPrimary - props.metricSecondary) / props.metricSecondary * 100
  if (isNaN(value)) value = 0
  return (
    <Box
      height={24}
      bgcolor={bgColor}
      color={color}
      borderRadius={1}
      display='flex'
      flexDirection='row'
      alignItems='center'
      justifyContent='center'
      sx={{
        padding: theme => theme.spacing(0, 1, 0, 0),
      }}
    >
      {prefix}
      <Typography variant='caption' style={{ fontSize: 12, lineHeight: '12px' }}>
        {formatNumber(value, 0)}%
      </Typography>
    </Box>
  )
}

ChangeBadge.defaultProps = {
  metricPrimary: 0,
  metricSecondary: 0,
}

ChangeBadge.propTypes = {
  metricPrimary: PropTypes.number,
  metricSecondary: PropTypes.number,
}

export default ChangeBadge