import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'

import { FirebaseContext } from '../../utils/firebase'
import 'firebase/auth'

import {
  useQuery,
} from 'react-query'

import { formatNumber, stableSort } from '../../utils/helpers'
import { API_ROOT_URL, DASHBOARD_BACKEND_BREAKDOWN_ROWS_LIMIT } from '../../constants/'

const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  typographySecondaryColor: {
    color: theme.palette.text.secondary,
  },
  backendSummaryRow: {
    display: 'flex',
    padding: theme.spacing(1, 0)
  },
  backendSummaryColumn: {
    flexBasis: '33%',
    textAlign: 'center',
  },
  metricValueTypography: {
    lineHeight: 1,
  },
  metricKeyTypography: {
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
  },
}))

const BackendSummary = (props) => {
  const { frontendType, context } = props
  const classes = useStyles()
  const firebase = React.useContext(FirebaseContext)

  const productBreakdownContext = {...context, breakdown: { id: 'product_id', name: 'Product' }}

  const { isLoading, data } = useQuery(['dashboard-backend', productBreakdownContext, frontendType], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/dashboard/backend`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          startDate: productBreakdownContext.dates.start,
          endDate: productBreakdownContext.dates.end,
          timezone: TIMEZONE,
          breakdown: productBreakdownContext.breakdown,
          filters: productBreakdownContext.filters,
          frontendType,
        })
      }).then(res => res.json())
    }),
    {
      enabled: !!context.dates.start && !!context.dates.end,
      cacheTime: 15 * 60 * 1000,  // 15 minutes
      staleTime: 15 * 60 * 1000,  // 15 minutes
      refetchOnWindowFocus: false,
    }
  )

  const backendData = data ? (data.backend[0] ? data.backend[0] : {}) : {}
  let tableData = data ? data.table : []
  tableData = stableSort(tableData, 'desc', 'customers').slice(0, DASHBOARD_BACKEND_BREAKDOWN_ROWS_LIMIT )

  return (
    <Paper className={classes.root}>
      <Typography variant='h6' gutterBottom>
        Backend Summary
      </Typography>
      {isLoading ? (
        <Skeleton variant="rectangular" width={'100%'} height={254} animation='wave' />
      ) : (
        <React.Fragment>
          <div className={classes.backendSummaryRow}>
            <div className={classes.backendSummaryColumn}>
              <Typography className={classes.metricValueTypography} variant='body1'>
                {formatNumber(backendData.customers, 0)}
              </Typography>
              <Typography className={classes.metricKeyTypography} variant='caption'>
                customers
              </Typography>
            </div>
            <div className={classes.backendSummaryColumn}>
              <Typography className={classes.metricValueTypography} variant='body1'>
                ${formatNumber(backendData.revenue, 0)}
              </Typography>
              <Typography className={classes.metricKeyTypography} variant='caption'>
                revenue
              </Typography>
            </div>
            <div className={classes.backendSummaryColumn}>
              <Typography className={classes.metricValueTypography} variant='body1'>
                ${formatNumber(backendData.revenue_per_customer, 0)}
              </Typography>
              <Typography className={classes.metricKeyTypography} variant='caption'>
                revenue per customer
              </Typography>
            </div>
          </div>

          <TableContainer>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell align='right'><b>Name</b></TableCell>
                  <TableCell align='right'><b>Customers</b></TableCell>
                  <TableCell align='right'><b>Revenue</b></TableCell>
                  <TableCell align='right'><b>Revenue per Customer</b></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map(row =>
                  <TableRow key={row.breakdown}>
                    <TableCell align='right'>
                      {row.name} &nbsp; [ID:{row.platform_id}]
                      <Typography variant='caption' display='block'>
                        <b>{row.platform}</b><b>&nbsp;&gt;&nbsp;{row.funnel_nickname} [ID:{row.funnel_platform_id}]</b>
                      </Typography>
                    </TableCell>
                    <TableCell align='right'>{formatNumber(row.customers, 0)} <span className={classes.typographySecondaryColor}>({formatNumber(100 * row.customers / backendData.customers, 0)}%)</span></TableCell>
                    <TableCell align='right'>${formatNumber(row.revenue, 0)} <span className={classes.typographySecondaryColor}>({formatNumber(100 * row.revenue / backendData.revenue, 0)}%)</span></TableCell>
                    <TableCell align='right'>${formatNumber(row.revenue / row.customers, 0)}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </React.Fragment>
      )}

    </Paper>
  );
}

BackendSummary.propTypes = {
  frontendType: PropTypes.string.isRequired,
  context: PropTypes.object.isRequired,
}

export default BackendSummary