import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import { Typography } from '@mui/material'

const MetricChips = ({
  metricItems,
  selectedMetricKey,
  onMetricChange,
}) => {
  if (metricItems.length === 0) return null
  return (
    <Box
      paddingBottom={1}
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        flexWrap: { xs: 'nowrap', sm: 'wrap' },
        columnGap: 1,
        rowGap: 1,
      }}
    >
      {metricItems.map(metricItem => (
        <Chip
          key={metricItem.key}
          label={
            <Box display='flex' flexDirection='row' alignItems='center' columnGap={0.5}>
              <Typography fontSize='inherit'>
                {metricItem.name}
              </Typography>
            </Box>
          }
          onClick={() => onMetricChange(metricItem.key)}
          size='small'
          variant={selectedMetricKey === metricItem.key ? 'filled' : 'outlined'}
          color='primary'
          clickable
          sx={{
            '& .MuiSvgIcon-root': {
              fontSize: 14,
            }
          }}
        />
      ))}
    </Box>
  )
}

MetricChips.propTypes = {
  metricItems: PropTypes.array.isRequired,
  selectedMetricKey: PropTypes.string.isRequired,
  onMetricChange: PropTypes.func.isRequired,
}

export default MetricChips