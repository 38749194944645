import React  from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'

import moment from 'moment-timezone'

import { FirebaseContext } from '../../../utils/firebase'
import 'firebase/auth'
import { useQuery } from 'react-query'

import { API_ROOT_URL, BLUE_ACCENT_COLOR } from '../../../constants'
import { formatNumber } from '../../../utils/helpers'

import CardGraph from './CardGraph'
import ChangeBadge from '../ChangeBadge'
import { withStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  titleTypography: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  typographyPrimary: {
    fontWeight: 'bold',
    color: BLUE_ACCENT_COLOR,
  },
  typographySecondary: {
    color: theme.palette.text.secondary,
  },
  typographyBold: {
    fontWeight: 'bold',
  },
  tableData: {
    [theme.breakpoints.down('md')]: {
      padding: '6px 12px 6px 0px',
    },
  },
  changeBadgeTableCell: {
    maxWidth: 120,
    width: 80,
    [theme.breakpoints.down('md')]: {
      padding: '6px 0px 6px 12px !important',
    },
  },
}))

const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const CardOrders = (props) => {
  const { primaryDateRange, compareDateRange, resolution } = props
  const classes = useStyles()
  const firebase = React.useContext(FirebaseContext)

  const { isLoading, data } = useQuery(['daily-reports-orders', primaryDateRange, compareDateRange, TIMEZONE, resolution], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/daily/reports/orders`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          primaryDateRange,
          compareDateRange,
          timezone: TIMEZONE,
          resolution
        })
      }).then(res => res.json())
    }),
    {
      cacheTime: 15 * 60 * 1000,  // 15 minutes
      staleTime: 15 * 60 * 1000,  // 15 minutes
      refetchOnWindowFocus: false,
    }
  )

  const totalData = data ? data.total : {}
  const breakdownData = data ? data.breakdown : { frontend_order: {}, backend_order: {}, rebill: {}, refund: {} }
  const graphData = data ? data.graph : []

  return (
    <Box>
      <Paper variant='outlined'>
        <Box padding={2}>
          <Box display='flex' flexDirection='row' alignItems='center' marginBottom={1}>
            <Box marginRight={1}>
              <Typography className={classes.titleTypography} variant='body2'>
                Orders
              </Typography>
            </Box>
            <ChangeBadge
              metricPrimary={totalData.primary}
              metricSecondary={totalData.compare}
            />
          </Box>
          {isLoading ? (
            <Box height='100%'>
              <Skeleton variant="rectangular" width='100%' height={265} />
            </Box>
          ) : (
            <Box>
              <Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between'>
                <Box>
                  <Typography className={classes.typographyPrimary} variant='h6'>
                    {formatNumber(totalData.primary, 0)}
                  </Typography>
                </Box>
                <Box>
                  <Typography className={classes.typographySecondary} variant='h6'>
                    {formatNumber(totalData.compare, 0)}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <TableContainer>
                  <Table size='small'>
                    <TableBody>
                      <TableRow>
                        <TableCellWithoutLines align='left' className={classes.tableData}><span className={classes.typographyBold}>frontend orders</span></TableCellWithoutLines>
                        <TableCellWithoutLines align='right'><span className={classes.typographyPrimary}>{formatNumber(breakdownData.frontend_order.primary, 0)}</span></TableCellWithoutLines>
                        <TableCellWithoutLines align='right'><span className={classes.typographySecondary}>{formatNumber(breakdownData.frontend_order.compare, 0)}</span></TableCellWithoutLines>
                        <TableCellWithoutLines className={classes.changeBadgeTableCell} align='right'>
                          <ChangeBadge
                            metricPrimary={breakdownData.frontend_order.primary}
                            metricSecondary={breakdownData.frontend_order.compare}
                          />
                        </TableCellWithoutLines>
                      </TableRow>
                      <TableRow>
                        <TableCellWithoutLines align='left' className={classes.tableData}><span className={classes.typographyBold}>backend orders</span></TableCellWithoutLines>
                        <TableCellWithoutLines align='right'><span className={classes.typographyPrimary}>{formatNumber(breakdownData.backend_order.primary, 0)}</span></TableCellWithoutLines>
                        <TableCellWithoutLines align='right'><span className={classes.typographySecondary}>{formatNumber(breakdownData.backend_order.compare, 0)}</span></TableCellWithoutLines>
                        <TableCellWithoutLines className={classes.changeBadgeTableCell} align='right'>
                          <ChangeBadge
                            metricPrimary={breakdownData.backend_order.primary}
                            metricSecondary={breakdownData.backend_order.compare}
                          />
                        </TableCellWithoutLines>
                      </TableRow>
                      <TableRow>
                        <TableCellWithoutLines align='left' className={classes.tableData}><span className={classes.typographyBold}>rebill</span></TableCellWithoutLines>
                        <TableCellWithoutLines align='right'><span className={classes.typographyPrimary}>{formatNumber(breakdownData.rebill.primary, 0)}</span></TableCellWithoutLines>
                        <TableCellWithoutLines align='right'><span className={classes.typographySecondary}>{formatNumber(breakdownData.rebill.compare, 0)}</span></TableCellWithoutLines>
                        <TableCellWithoutLines className={classes.changeBadgeTableCell} align='right'>
                          <ChangeBadge
                            metricPrimary={breakdownData.rebill.primary}
                            metricSecondary={breakdownData.rebill.compare}
                          />
                        </TableCellWithoutLines>
                      </TableRow>
                      <TableRow>
                        <TableCellWithoutLines align='left' className={classes.tableData}><span className={classes.typographyBold}>refund</span></TableCellWithoutLines>
                        <TableCellWithoutLines align='right'><span className={classes.typographyPrimary}>{formatNumber(breakdownData.refund.primary, 0)}</span></TableCellWithoutLines>
                        <TableCellWithoutLines align='right'><span className={classes.typographySecondary}>{formatNumber(breakdownData.refund.compare, 0)}</span></TableCellWithoutLines>
                        <TableCellWithoutLines className={classes.changeBadgeTableCell} align='right'>
                          <ChangeBadge
                            metricPrimary={breakdownData.refund.primary}
                            metricSecondary={breakdownData.refund.compare}
                          />
                        </TableCellWithoutLines>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box>
                <Typography variant='overline'>
                  orders over time
                </Typography>
                <CardGraph
                  data={graphData}
                  isDollar={false}
                  resolution={resolution}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
}

const TableCellWithoutLines = withStyles({
  root: {
    borderBottom: 'none'
  }
})(TableCell)

CardOrders.propTypes = {
  primaryDateRange: PropTypes.object.isRequired,
  compareDateRange: PropTypes.object.isRequired,
  resolution: PropTypes.string.isRequired,
}

export default CardOrders