import React from 'react'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import Grid from '@mui/material/Grid'
import InputBase from '@mui/material/InputBase'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Snackbar from '@mui/material/Snackbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'

import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import { FirebaseContext } from '../../utils/firebase'
import 'firebase/auth'

import { API_ROOT_URL } from '../../constants/'
import * as ROUTES from '../../constants/routes'

import easywebinarLogoImg from '../../media/easywebinar_logo.png'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: 66,
    right: 0,
    width: 'calc(100% - 66px)',
    height: '100vh',
    overflowY: 'auto',
    backgroundColor: theme.palette.grey[50],
  },
  logoImg: {
    width: 100,
  },
  syncButton: {
    width: 110,
  },
  redButton: {
    color: theme.palette.error.main,
    '&:hover': {
      background: 'rgba(255,23,68,0.04)',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250
  },
}))

const REVOKE_SUCCESS_MESSAGE = 'Account removed. Data will no longer be synced from this account. Redirecting back to settings...'
const WEBHOOK_URL_BASE = `https://us-central1-ltv-numbers-${process.env.REACT_APP_ENV === 'production' ? 'prod' : 'dev'}.cloudfunctions.net/webhooks/easywebinar/`

const EasyWebinarSource = () => {
  const classes = useStyles()
  const { id } = useParams()
  const history = useHistory()
  const firebase = React.useContext(FirebaseContext)

  const [webhookUrl, setWebhookUrl] = React.useState('')
  const [showCopyTooltip, setShowCopyTooltip] = React.useState(false)

  const [message, setMessage] = React.useState('')
  const [showMessage, setShowMessage] = React.useState(false)
  const [showRevokeConfirmation, setShowRevokeConfirmation] = React.useState(false)

  React.useEffect(() => {
    const uid = firebase.auth().currentUser.uid
    setWebhookUrl(WEBHOOK_URL_BASE + uid + '/integration/' + id)
  }, [])

  const {
    isLoading: isLoadingIntegration,
    data: integration = {}
  } = useQuery(['integration-get', id], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      }).then(res => res.json())
    }),
    {
      cacheTime: 15 * 60 * 1000,  // 15 minutes
      staleTime: 15 * 60 * 1000,  // 15 minutes
      refetchOnWindowFocus: false,
    }
  )

  const revoke = () => {
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/easywebinar/revoke`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          integration_id: id
        })
      }).then(res => {
        setShowRevokeConfirmation(false)
        setMessage(REVOKE_SUCCESS_MESSAGE)
        setShowMessage(true)
        setTimeout(() => history.push(ROUTES.SETTINGS), 3000)
        return res.json()
      })
    })
  }

  const handleCloseMessage = (_, reason) => {
    if (reason === 'clickaway') return
    setShowMessage(false)
  }

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
    setShowCopyTooltip(true)
  }

  return (
    <div className={classes.root}>
      <Box padding={2}>
        <Button
          variant='outlined'
          onClick={() => history.push(ROUTES.SETTINGS)}
          size='small'
        >
          Back
        </Button>
        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs={2}>
                  <img
                    className={classes.logoImg}
                    alt='easywebinar_logo'
                    src={easywebinarLogoImg}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant='h5' gutterBottom>
                    EasyWebinar Source
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>

        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Name
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Box height='100%' display='flex' alignItems='center'>
                    <Typography variant='body2'>
                      {isLoadingIntegration ? <Skeleton variant='text' width={100} /> : `${integration.nickname}`}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>

        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Webhook URL
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Box display='block'>
                    <Typography variant='body2' paragraph>
                      Easy Webinar does not have a built in webhook integration, so you need to pass the data through their Zapier integration.
                    </Typography>
                    <Typography variant='body2' paragraph>
                      Use the trigger called <b>New Webinar Registration</b> and the action called <b>Webhooks by Zapier</b>.
                      Send the data as a POST request in JSON format to the webhook below.
                    </Typography>
                    <Typography variant='body2' paragraph>
                      Note that LTV Numbers is looking for fields called utm_source, utm_medium, utm_campaign, utm_content, utm_term,
                      affiliate_id_1, and affiliate_id_2 to be present in the data. You can add these using custom fields that are hidden
                      in your webinar registration forms. These fields will capture the data if they are present in the URL
                      and pass that data through to LTV Numbers. If any field is not present, it will be left blank.
                    </Typography>
                  </Box>
                  <Box display='flex' alignItems='center'>
                    {isLoadingIntegration ? <Skeleton variant='text' width={100} /> : (
                      <Box display='flex' flexDirection='row' width='100%'>
                        <InputBase
                          variant='filled'
                          value={webhookUrl}
                          size='small'
                          fullWidth
                          disabled
                        />
                        <Tooltip
                          open={showCopyTooltip}
                          onClose={() => setShowCopyTooltip(false)}
                          leaveDelay={2000}
                          placement='right'
                          title='Copied'
                        >
                          <Button
                            variant='text'
                            color='primary'
                            onClick={() => copyToClipboard(webhookUrl)}
                          >
                            Copy
                          </Button>
                        </Tooltip>
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>

        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Delete
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant='body2' gutterBottom>
                    If you want to remove this data integration, click the button below.
                  </Typography>
                  <Button
                    className={classes.redButton}
                    variant='text'
                    onClick={() => setShowRevokeConfirmation(true)}
                  >
                    Delete Source
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      </Box>
      <Dialog
        open={showRevokeConfirmation}
        onClose={() => setShowRevokeConfirmation(false)}
      >
        <DialogTitle>
          Remove access to account <b>&quot;{integration.nickname}&quot;</b>?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will prevent data from being synced to LTV Numbers.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.redButton}
            variant='text'
            onClick={() => setShowRevokeConfirmation(false)}
          >
            Cancel
          </Button>
          <Button
            color='primary'
            onClick={() => revoke()}
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showMessage}
        autoHideDuration={10000}
        onClose={handleCloseMessage}
      >
        <Alert
          severity='success'
          variant='filled'
          onClose={handleCloseMessage}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  )
}

EasyWebinarSource.propTypes = {}

export default EasyWebinarSource