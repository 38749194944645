import React from 'react'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'

import { FirebaseContext } from '../../utils/firebase'
import 'firebase/auth'

import DayStats from './DayStats'
import RecentActivity from '../Dashboard/RecentActivity'
import Reports from './Reports'

import { PUBLIC_DEMO_UID } from '../../constants'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: 66,
    right: 0,
    width: 'calc(100% - 66px)',
    height: '100vh',
    overflowY: 'auto',
    paddingTop: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
    [theme.breakpoints.down('md')]: {
      left: 0,
      width: '100vw',
      paddingBottom: 86,
    },
  },
  paper: {
    padding: theme.spacing(2),
  },
  paperTopMargin: {
    marginTop: theme.spacing(2),
  },
}))

const Daily = () => {
  const classes = useStyles()
  const firebase = React.useContext(FirebaseContext)
  const [frontendType] = React.useState('order')

  // Public Demo check to hide navigation to sensitive areas
  const uid = firebase.auth().currentUser ? firebase.auth().currentUser.uid : null
  const isPublicDemo = uid === PUBLIC_DEMO_UID

  return (
    <Container className={classes.root} maxWidth='xl'>
      {isPublicDemo && (
        <Box marginTop={-2} bgcolor='white' width='100%' position='fixed' zIndex={100}>
          <Alert severity='info'>
            <Box display='flex' alignItems='center' justifyContent='space-between' width='100%'>
              <Typography variant='body1'>
                For a personalized demo: &nbsp;
              </Typography>
              <Button
                variant='contained'
                size='small'
                color='info'
                href='https://www.ltvnumbers.com/demo'
                target='_blank'
              >
                Book Demo
              </Button>
            </Box>
          </Alert>
        </Box>
      )}
      <Grid container spacing={2} marginTop={isPublicDemo ? 4 : -2}>
        <Grid item xs={12} lg={8}>
          <Paper className={classes.paper}>
            <DayStats />
          </Paper>
        </Grid>

        <Grid item xs={12} lg={4}>
          <RecentActivity
            frontendType={frontendType}
          />
        </Grid>

        <Grid item xs={12}>
          <Reports />
        </Grid>
      </Grid>
    </Container>
  )
}

export default Daily