import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Tooltip from '@mui/material/Tooltip'

const CostsSwitch = (props) => {
  return (
    <FormControl margin='dense' size='small'>
      <Tooltip
        title='Subtract costs from net revenue to use gross profit for LTV calculations. This only affects the LTV graph and table stats related to LTV and LTR. These costs must be manually configured by the LTV team. Contact support if you are interested.'
        enterDelay={0}
      >
        <FormControlLabel
          control={
            <Switch
              checked={props.includeCosts}
              onChange={() => props.onIncludeCostsChange(!props.includeCosts)}
              name='includeCostsSwitch'
              color='primary'
            />
          }
          label='Subtract Costs from LTV'
        />
      </Tooltip>
    </FormControl>
  )
}

CostsSwitch.propTypes = {
  includeCosts: PropTypes.bool.isRequired,
  onIncludeCostsChange: PropTypes.func.isRequired,
}

export default CostsSwitch
