import React, { useContext, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'
import { useHistory } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { useQueryClient } from 'react-query'

import { UserContext } from '../../contexts/UserContext'
import { FirebaseContext } from '../../utils/firebase'
import { createDocument, deleteDocument, getCollectionDocuments, listenToCollectionUpdates, updateDocument } from '../../utils/firestore'
import { getSegmentById, getNextAvailableColor, getSegmentDisplayName } from '../../utils/helpers'

import Report from './Report'
import Sidebar from './Sidebar'

import { DEFAULT_REPORT_KEY, FIRESTORE_COLLECTIONS } from '../../constants'
import * as ROUTES from '../../constants/routes'
import { CORE_REPORTS } from './ReportItems'
import LoadingSkeleton from './LoadingSkeleton'

const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: 62,
    right: 0,
    width: 'calc(100% - 62px)',
    height: '100vh',
    overflowY: 'auto',
    backgroundColor: theme.palette.grey[50],
  },
}))

const Reports = () => {
  const classes = useStyles()
  const firebase = useContext(FirebaseContext)
  const userDoc = useContext(UserContext)
  const history = useHistory()
  const queryClient = useQueryClient()

  const [sidebarTab, setSidebarTab] = useState('core')
  const [selectedReport, setSelectedReport] = useState(null)
  const [sidebarOpen, setSidebarOpen] = useState(true)

  const [savedReports, setSavedReports] = useState([])
  const [savedSegments, setSavedSegments] = React.useState([])
  const [savedGroups, setSavedGroups] = React.useState([])

  const [liveMode, setLiveMode] = React.useState(false)
  const [startDate, setStartDate] = React.useState(null)
  const [endDate, setEndDate] = React.useState(null)
  const [resolution, setResolution] = React.useState(null)
  const [config, setConfig] = React.useState({})
  const [metricKey, setMetricKey] = React.useState('')
  const [showCumulative, setShowCumulative] = React.useState(false)
  const [showSecondaryChart, setShowSecondaryChart] = React.useState(false)
  const [segments, setSegments] = React.useState([])

  useEffect(() => {
    let isMounted = true

    getIntialSelectedReport().then(report => {
      if (isMounted) {
        handleReportSelect(report)
        handleSidebarTabSelect(report.kind)
      }
    })

    return () => {
      isMounted = false
    }
  }, [])

  // When liveMode is active, update the endDate every minute to handle
  // the date changing while the user is viewing the report
  useEffect(() => {
    let timer = null
    if (liveMode) {
      timer = setInterval(() => {
        // Only update the end date if the current end date is the day before today
        // because that means we have crossed midnight with liveMode enabled
        if (endDate.isSame(moment.tz(TIMEZONE).subtract(1, 'day'), 'day')) {
          setEndDate(moment.tz(TIMEZONE).endOf('day'))
        }
      }, 60000) // Runs every minute
    }
    return () => {
      if (timer) {
        clearInterval(timer)
      }
    }
  }, [liveMode])

  async function getIntialSelectedReport() {
    // Get saved reports from Firestore
    const savedReports = await getCollectionDocuments(firebase, FIRESTORE_COLLECTIONS.REPORTS)

    // Try to get initial report type from the URL
    const reportUrlMatch = history.location.pathname.match(/\/reports\/([^/]+)(?:\/([^/]+))?/)
    const reportType = reportUrlMatch ? reportUrlMatch[1] : null
    const reportId = reportUrlMatch ? reportUrlMatch[2] : null

    // If the report has an ID, then it is a saved report
    if (reportId) {
      const savedReport = savedReports.find(r => r.id === reportId)

      if (savedReport) return savedReport
      // If the saved report can't be found, then the ID is invalid, so fallback to
      // a core report of the same type
      else {
        const coreReport = CORE_REPORTS.find(r => r.key === reportType)
        if (coreReport) return coreReport
        else {
          return CORE_REPORTS.find(r => r.key === DEFAULT_REPORT_KEY)
        }
      }
    }
    // Find the core report of a matching type
    else {
      const coreReport = CORE_REPORTS.find(r => r.key === reportType)
      // If the core report can't be found then the URL was likely manually modified
      // so fallback to the default report type
      if (coreReport) return coreReport
      else {
        return CORE_REPORTS.find(r => r.key === DEFAULT_REPORT_KEY)
      }
    }
  }

  function getStartDateFromReport(report) {
    if (report.dateRangeType === 'dynamic') {
      return moment.tz(TIMEZONE).subtract(report.dateRangeDynamicDays, 'days').startOf('day')
    } else if (report.dateRangeType === 'fixed') {
      return moment.tz(report.dateRangeFixedStartDate, TIMEZONE).startOf('day')
    }
  }

  function getEndDateFromReport(report) {
    if (report.dateRangeType === 'dynamic') {
      return moment.tz(TIMEZONE).subtract(1,'day').endOf('day')
    } else if (report.dateRangeType === 'fixed') {
      return moment.tz(report.dateRangeFixedEndDate, TIMEZONE).endOf('day')
    }
  }

  function getResolutionFromReport(report) {
    return report.resolution
  }

  function getConfigFromReport(report) {
    return report.config
  }

  function getMetricKeyFromReport(report) {
    return report.metricKey || report.metrics.defaultKey
  }

  function getShowCumulativeFromReport(report) {
    return report.chart.cumulativeDefault
  }

  function getShowSecondaryChartFromReport(report) {
    const metricKey = report.metricKey || report.metrics.defaultKey
    const metricItem = report.metrics.items.find(m => m.key === metricKey)
    const metricHasSecondary = Boolean(metricItem.secondary)
    return metricHasSecondary && report.chart.allowSecondaryChart
  }

  function getSegmentsFromReport(report) {
    if (report.segments.length === 0) {
      return [{ ...createNewSegment(true) }]
    } else {
      return report.segments
    }
  }

  function mergeSavedReportWithCoreReport(savedReport) {
    // Find core report with matching type
    const coreReport = CORE_REPORTS.find(r => r.key === savedReport.key)

    // Combine the core report with the saved report to ensure that all necessary
    // parameters are present, and the saved report takes precedence
    return { ...coreReport, ...savedReport }
  }

  function mergePrevConfigWithNewReport(prevConfig, newReport) {
    // For each key in the previous config, check if that key in is the new report's config
    // If it is, preserve the previous config's value, otherwise use the new report's value
    const reportConfig = getConfigFromReport(newReport)
    let newConfig = {...reportConfig}
    for (const key in reportConfig) {
      if (key in prevConfig) {
        // If config item in the new report is marked as an override,
        // then use the new report's value instead of preserving the previous value
        if (newReport.configItems[key]?.override) {
          continue
        }
        newConfig[key] = prevConfig[key]
      }
    }
    return newConfig
  }

  const createNewSegment = (isInitialization=false) => {
    const newSegment = {
      id: uuidv4(),
      segmentFilters: [],
      filters: [],
      breakdown: null,
      isActive: true,
      color: isInitialization ? getNextAvailableColor([], userDoc.secondaryColor) : getNextAvailableColor(segments, userDoc.secondaryColor),
      breakdownState: {},
      savedSegmentEditId: null,
      revertSegmentData: null,
    }
    return updateSegment(newSegment, {})
  }

  const updateSegment = (segment, updates, newSavedSegment=null) => {
    const updatedSegment = {
      ...segment,
      ...updates,
      name: getSegmentDisplayName({ ...segment, ...updates }, newSavedSegment ? [...savedSegments, newSavedSegment] : savedSegments),
    }
    return updatedSegment
  }

  const handleSidebarOpenToggle = (open) => {
    setSidebarOpen(open)
  }

  const handleSidebarTabSelect = (newTab) => {
    setSidebarTab(newTab)
  }

  const handleReportSelect = (newReport) => {
    // There are two top level cases:
    // -- 1. selecting a report for the first time
    // -- 2. switching from one report to another

    // 1. selecting a report for the first time
    if (selectedReport === null) {
      initializeReportState(newReport)
    }
    // 2. switching from one report to another
    else {
      // If switching from one report kind to another, reset all state based on the new report
      if (selectedReport.kind !== newReport.kind) {
        initializeReportState(newReport)
      }
      // If switching between two saved reports, reset all state based on the new report
      else if (selectedReport.kind === 'saved' && newReport.kind === 'saved') {
        initializeReportState(newReport)
      }
      // If switching between two core reports, preserve the all existing state
      // and only override the config in case the new report has config not previously present.
      else {
        // Special case: if the splitSegmentFrontBack changes, reset the resolution and segments
        // because segments are fundamentally different in that they are split into frontend and backend filters and breakdowns.
        // Resolution should also be reset because the analysis is a fundamentally different kind.
        if (selectedReport.splitSegmentFrontBack !== newReport.splitSegmentFrontBack) {
          setResolution(getResolutionFromReport(newReport))
          setSegments(getSegmentsFromReport(newReport))
        }
        // If the xKey is changing, then use the default resolution of the report
        // because the resolutions are not compatible between different xKeys
        if (selectedReport.xKey !== newReport.xKey) {
          setResolution(getResolutionFromReport(newReport))
        }
        setMetricKey(getMetricKeyFromReport(newReport))
        setConfig(prevConfig => mergePrevConfigWithNewReport(prevConfig, newReport))
      }
    }

    // Saved reports must be merged with the full built in report
    // to ensure all config is present for populating the UI
    if (newReport.kind === 'saved') {
      newReport = mergeSavedReportWithCoreReport(newReport)
    }

    // Set showCumulative to default report value
    setShowCumulative(getShowCumulativeFromReport(newReport))

    // Once full report config available, check whether to show secondary chart
    setShowSecondaryChart(getShowSecondaryChartFromReport(newReport))

    setSelectedReport(newReport)
    let newPath = ROUTES.REPORTS + `/${newReport.key}`
    if (newReport.kind === 'saved') {
      newPath += `/${newReport.id}`
    }
    history.push(newPath)
  }

  const initializeReportState = (report) => {
    setStartDate(getStartDateFromReport(report))
    setEndDate(getEndDateFromReport(report))
    setResolution(getResolutionFromReport(report))
    setMetricKey(getMetricKeyFromReport(report))
    setConfig(getConfigFromReport(report))
    setSegments(getSegmentsFromReport(report))
  }

  const handleReportSave = async (reportId, formData) => {
    const reportToSave = {
      kind: 'saved',
      ...formatReportObjectFromState(),
      ...formData,
    }

    // If reportId is provided, update existing report
    if (reportId) {
      await updateDocument(firebase, FIRESTORE_COLLECTIONS.REPORTS, reportId, reportToSave)
    }
    // Create a new report
    else {
      reportId = await createDocument(firebase, FIRESTORE_COLLECTIONS.REPORTS, reportToSave)
    }
    handleReportSelect({ id: reportId, ...reportToSave })
    handleSidebarTabSelect('saved')
  }

  const handleReportDelete = async (reportId) => {
    await deleteDocument(firebase, FIRESTORE_COLLECTIONS.REPORTS, reportId)

    // Select a core report of the same type
    handleReportSelect(CORE_REPORTS.find(r => r.key === selectedReport.key))
    handleSidebarTabSelect('core')
  }

  const formatReportObjectFromState = () => {
    let reportData = {
      // These are straight from the report config and cannot be changed
      key: selectedReport.key,
      xKey: selectedReport.xKey,

      // These are based on current values from the state
      metricKey,
      resolution,
      config,
      segments,
    }

    return reportData
  }

  const handleLiveModeChange = (newLiveMode) => {
    setLiveMode(newLiveMode)
  }

  const handleDatesChange = ({ startDate: newStartDate, endDate: newEndDate }) => {
    setStartDate(newStartDate)
    setEndDate(newEndDate)
    setResolutionFromPeriod(newStartDate, newEndDate)
    // If live mode is enabled and today is not in the date range, disable live mode
    // because live mode is only available when today is in the date range
    if (liveMode && !moment.tz(TIMEZONE).isBetween(newStartDate, newEndDate, 'day', '[]')) {
      setLiveMode(false)
    }
  }

  const setResolutionFromPeriod = (startDate, endDate) => {
    const periodDays = endDate.diff(startDate, 'days') + 1
    if (periodDays >= 0 && periodDays <= 30) {
      setResolution(0)
    } else if (periodDays >= 31 && periodDays <= 179) {
      setResolution(1)
    } else if (periodDays >= 180 && periodDays <= 732) {
      setResolution(2)
    } else if (periodDays > 732) {
      setResolution(3)
    }
  }

  const handleSegmentAdd = (segmentFilterToInclude=null) => {
    const newSegment = updateSegment(createNewSegment(), {
      segmentFilters: segmentFilterToInclude ? [segmentFilterToInclude] : [],
    })
    setSegments(prevSegments => [newSegment, ...prevSegments])
  }

  const handleSegmentDelete = (segmentId) => {
    setSegments(prevSegments => prevSegments.filter(segment => segment.id !== segmentId))
  }

  const handleSegmentDuplicate = (segmentId) => {
    const segment = getSegmentById(segments, segmentId)
    const segmentIndex = segments.findIndex(segment => segment.id === segmentId)
    const newSegment = {
      ...createNewSegment(),
      name: segment.name,
      segmentFilters: segment.segmentFilters,
      filters: segment.filters,
    }
    // Insert the duplicated segment right after the segment being duplicated
    setSegments(prevSegments => [
      ...prevSegments.slice(0, segmentIndex + 1),
      newSegment,
      ...prevSegments.slice(segmentIndex + 1),
    ])
  }

  const handleSegmentEditChange = (segmentId, savedSegmentEditId) => {
    // Find savedSegmentEditId in savedSegents, get the segmentFilters and filters from the saved segment
    // and append them to those fields in segment of segmentId
    const savedSegment = savedSegments.find(savedSegment => savedSegment.id === savedSegmentEditId)
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId) {
        return updateSegment(segment, {
          savedSegmentEditId,
          revertSegmentData: segment,
          segmentFilters: [...savedSegment.segmentFilters],
          filters: [...savedSegment.filters, ...segment.filters],
        })
      } else {
        return segment
      }
    }))
  }

  const handleSegmentSaveEdit = (segmentId) => {
    const segment = getSegmentById(segments, segmentId)
    const savedSegment = getSegmentById(savedSegments, segment.savedSegmentEditId)
    const segmentToSave = {
      name: savedSegment.name,
      segmentFilters: segment.segmentFilters,
      filters: segment.filters,
    }
    updateDocument(firebase, FIRESTORE_COLLECTIONS.SEGMENTS, savedSegment.id, segmentToSave)
    const newSegmentFilter = { id: savedSegment.id }

    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId) {
        const segmentUpdate = {
          savedSegmentEditId: null,
          revertSegmentData: null,
          segmentFilters: [newSegmentFilter],
          filters: [],
        }

        // Force a refresh of the queries
        queryClient.refetchQueries()

        return updateSegment(segment, segmentUpdate)
      } else {
        return segment
      }
    }))
  }

  const handleSegmentRevertEdit = (segmentId) => {
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId) {
        return updateSegment(segment, {
          ...segment.revertSegmentData
        })
      } else {
        return segment
      }
    }))
  }

  const handleSegmentIsActiveChange = (segmentId, isActive) => {
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId) {
        return updateSegment(segment, { isActive })
      } else {
        return segment
      }
    }))
  }

  const handleSegmentColorChange = (segmentId, newColor) => {
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId) {
        return updateSegment(segment, { color: newColor })
      } else {
        return segment
      }
    }))
  }

  const handleSegmentFilterAdd = (segmentId, segmentFilter) => {
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId) {
        const segmentFilters = [...segment.segmentFilters, segmentFilter]
        return updateSegment(segment, { segmentFilters })
      } else {
        return segment
      }
    }))
  }

  const handleSegmentReplaceFiltersWithSavedSegmentFilter = (segmentId, newSavedSegment, kind='all') => {
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId) {
        const newSegmentFilter = { id: newSavedSegment.id, kind }
        return updateSegment(segment, { segmentFilters: [newSegmentFilter], filters: [] }, newSavedSegment)
      } else {
        return segment
      }
    }))
  }

  const handleSegmentFilterDelete = (segmentId, segmentFilterId) => {
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId) {
        const segmentFilters = segment.segmentFilters.filter(filter => filter.id !== segmentFilterId)
        return updateSegment(segment, { segmentFilters })
      } else {
        return segment
      }
    }))
  }

  const handleSegmentsReorder = (newSegments) => {
    setSegments(newSegments)
  }

  const handleFilterAdd = (segmentId, filter) => {
    // if segmentId is null, add filter to all segments
    // else add filter to the segment with the matching segmentId
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segmentId === null || segment.id === segmentId) {
        const filters = [...segment.filters, filter]
        return updateSegment(segment, { filters })
      } else {
        return segment
      }
    }))
  }

  const handleFilterUpdate = (segmentId, filterIndex, filter) => {
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId) {
        // Find the filter at the index and replace it with 'filter'
        const filtersOfSameKind = segment.filters.filter(f => f.kind === filter.kind)
        const filtersOfDifferentKind = segment.filters.filter(f => f.kind !== filter.kind)

        const newFiltersOfSameKind = filtersOfSameKind.map((f, i) => i === filterIndex ? filter : f)
        const filters = [...newFiltersOfSameKind, ...filtersOfDifferentKind]
        return updateSegment(segment, { filters })
      } else {
        return segment
      }
    }))
  }

  const handleFilterDelete = (segmentId, filterIndex) => {
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId) {
        const filters = [...segment.filters]
        filters.splice(filterIndex, 1)
        return updateSegment(segment, { filters })
      } else {
        return segment
      }
    }))
  }

  const handleClearAllSegmentFilters = (segmentId, kind='all') => {
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId) {
        const filters = segment.filters.filter(filter => filter.kind !== kind)
        const segmentFilters = segment.segmentFilters.filter(filter => filter.kind !== kind)
        return updateSegment(segment, { segmentFilters, filters })
      } else {
        return segment
      }
    }))
  }

  const handleSegmentBreakdownSelect = (segmentId, breakdown) => {
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId && segment.breakdown?.field !== breakdown?.field) {
        return updateSegment(segment, {
          breakdown,
          breakdownState: breakdown ? segment.breakdownState : {},
        })
      } else {
        return segment
      }
    }))
  }

  const handleSegmentBreakdownIsActiveChange = (segmentId, breakdownKey, isActive) => {
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId) {
        const breakdownState = {
          ...segment.breakdownState,
          [breakdownKey]: {
            isActive,
            color: isActive ? getNextAvailableColor(prevSegments) : null,
          }
        }
        return updateSegment(segment, { breakdownState })
      } else {
        return segment
      }
    }))
  }

  const handleSegmentBreakdownColorChange = (segmentId, breakdownKey, newColor) => {
    setSegments(prevSegments => prevSegments.map(segment => {
      if (segment.id === segmentId) {
        const breakdownState = {
          ...segment.breakdownState,
          [breakdownKey]: {
            ...segment.breakdownState[breakdownKey],
            color: newColor
          }
        }
        return updateSegment(segment, { breakdownState })
      } else {
        return segment
      }
    }))
  }

  const handleResolutionChange = (newResolution) => {
    setResolution(newResolution)
  }

  const handleMetricKeyChange = (newKey) => {
    setMetricKey(newKey)
    setShowSecondaryChart(getShowSecondaryChartFromMetricKey(newKey))
  }

  const getShowSecondaryChartFromMetricKey = (newKey) => {
    const metricHasSecondary = Boolean(selectedReport.metrics.items.find(m => m.key === newKey).secondary)
    return metricHasSecondary && selectedReport.chart.allowSecondaryChart
  }

  const handleShowCumulativeChange = (show) => {
    setShowCumulative(show)
  }

  const handleShowSecondaryChartChange = (show) => {
    setShowSecondaryChart(show)
  }

  const handleConfigChange = (key, newValue) => {
    setConfig(prevConfig => ({
      ...prevConfig,
      [key]: newValue
    }))
  }

  const regenerateSegmentNames = () => {
    setSegments(prevSegments => prevSegments.map(segment => {
      return updateSegment(segment, {})
    }))
  }

  // Realtime listener for Firestore reports collection
  useEffect(() => {
    const unsubscribe = listenToCollectionUpdates(firebase, FIRESTORE_COLLECTIONS.REPORTS, snapshot => {
      const newDocs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
      setSavedReports(newDocs)
    })
    return unsubscribe
  }, [firebase])

  // Realtime listener for Firestore segments collection
  useEffect(() => {
    const unsubscribe = listenToCollectionUpdates(firebase, FIRESTORE_COLLECTIONS.SEGMENTS, snapshot => {
      const newDocs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
      setSavedSegments(newDocs)
    })
    return unsubscribe
  }, [firebase])

  // Realtime listener for Firestore groups collection
  useEffect(() => {
    const unsubscribe = listenToCollectionUpdates(firebase, FIRESTORE_COLLECTIONS.GROUPS, snapshot => {
      const newDocs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
      setSavedGroups(newDocs)
    })
    return unsubscribe
  }, [firebase])

  // Listen for changes to savedSegments to clean up delete segments if needed
  useEffect(() => {
    // Check the segmentFilters of each segment for any references to deleted saved segments
    segments.forEach(segment => {
      segment.segmentFilters.forEach(segmentFilter => {
        if (!savedSegments.find(savedSegment => savedSegment.id === segmentFilter.id)) {
          handleSegmentFilterDelete(segment.id, segmentFilter.id)
        }
      })
    })
    // Regenerate all segment names in case a referenced item was renamed
    regenerateSegmentNames()
  }, [savedSegments])

  // Listen for changes to savedGroups to clean up delete segments if needed
  useEffect(() => {
    // Check the filters of each segment for any references to deleted saved groups
    segments.forEach((segment, segmentIndex) => {
      segment.filters.forEach(filter => {
        filter.groupIds.forEach(groupId => {
          if (!savedGroups.find(savedGroup => savedGroup.id === groupId)) {
            handleFilterDelete(segmentIndex, filter)
          }
        })
      })
    })
    // Regenerate all segment names in case a referenced item was renamed
    regenerateSegmentNames()
  }, [savedGroups])

  return (
    <Box className={classes.root} display='flex' flexDirection='row' >
      <Sidebar
        pluralItemName={'reports'}
        isOpen={sidebarOpen}
        coreItems={CORE_REPORTS}
        savedItems={savedReports}
        tab={sidebarTab}
        selectedItem={selectedReport}
        groupSavedItemsByKey={true}
        onTabSelect={handleSidebarTabSelect}
        onItemSelect={handleReportSelect}
        onSavedItemSelect={handleReportSelect}
        onOpenToggle={handleSidebarOpenToggle}
      />
      {selectedReport === null ? (
        <Box width='100%' padding={4}>
          <LoadingSkeleton animation={'wave'} height='100%' width='100%' />
        </Box>
      ) : (
        <Report
          report={selectedReport}
          savedSegments={savedSegments}
          savedGroups={savedGroups}
          liveMode={liveMode}
          startDate={startDate}
          endDate={endDate}
          resolution={resolution}
          metricKey={metricKey}
          config={config}
          showCumulative={showCumulative}
          showSecondaryChart={showSecondaryChart}
          segments={segments}
          onReportSave={handleReportSave}
          onReportDelete={handleReportDelete}
          onLiveModeChange={handleLiveModeChange}
          onDatesChange={handleDatesChange}
          onResolutionChange={handleResolutionChange}
          onMetricKeyChange={handleMetricKeyChange}
          onShowCumulativeChange={handleShowCumulativeChange}
          onShowSecondaryChartChange={handleShowSecondaryChartChange}
          onConfigChange={handleConfigChange}
          onSegmentAdd={handleSegmentAdd}
          onSegmentDelete={handleSegmentDelete}
          onSegmentDuplicate={handleSegmentDuplicate}
          onSegmentEditChange={handleSegmentEditChange}
          onSegmentSaveEdit={handleSegmentSaveEdit}
          onSegmentRevertEdit={handleSegmentRevertEdit}
          onSegmentColorChange={handleSegmentColorChange}
          onSegmentIsActiveChange={handleSegmentIsActiveChange}
          onSegmentFilterAdd={handleSegmentFilterAdd}
          onSegmentReplaceFiltersWithSavedSegmentFilter={handleSegmentReplaceFiltersWithSavedSegmentFilter}
          onSegmentFilterDelete={handleSegmentFilterDelete}
          onSegmentsReorder={handleSegmentsReorder}
          onSegmentBreakdownSelect={handleSegmentBreakdownSelect}
          onSegmentBreakdownColorChange={handleSegmentBreakdownColorChange}
          onSegmentBreakdownIsActiveChange={handleSegmentBreakdownIsActiveChange}
          onFilterAdd={handleFilterAdd}
          onFilterUpdate={handleFilterUpdate}
          onFilterDelete={handleFilterDelete}
          onClearAllSegmentFilters={handleClearAllSegmentFilters}
        />
      )}
    </Box>
  )
}

Reports.propTypes = {
}

export default Reports