import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import Cancel from '@mui/icons-material/Cancel'
import { Filter } from 'mdi-material-ui'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    border: '1px solid #dbdbdb',
    borderRadius: theme.shape.borderRadius,
    padding: '5px 15px',
  },
  icon: {
    display: 'inline-block',
    marginLeft: -theme.spacing(1) / 2,
    marginRight: theme.spacing(1),
  },
  contentDiv: {
    marginRight: theme.spacing(1),
  },
  valueTypography: {
    lineHeight: 1,
  },
  keyTypography: {
    color: theme.palette.text.secondary
  },
  deleteButton: {
    padding: theme.spacing(1) / 2,
    marginRight: -theme.spacing(1),
  },
}))

const ContextChipFilter = (props) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Filter className={classes.icon} fontSize='small' />
      <div className={classes.contentDiv}>
        <Typography className={classes.valueTypography} variant='body2'>
          {props.filter.valueName}
        </Typography>
        <Typography className={classes.keyTypography} variant='caption'>
          {props.filter.name}
        </Typography>
      </div>
      <IconButton
        className={classes.deleteButton}
        aria-label='delete'
        onClick={() => props.onDelete(props.filter)}
        size="large">
        <Cancel />
      </IconButton>
    </div>
  );
}

ContextChipFilter.propTypes = {
  filter: PropTypes.object.isRequired
}

export default ContextChipFilter
