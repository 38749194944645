import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import Fade from '@mui/material/Fade'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import Popover from '@mui/material/Popover'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import AutoFixHighSharpIcon from '@mui/icons-material/AutoFixHighSharp'
import DateRangeIcon from '@mui/icons-material/DateRange'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'

import { FirebaseContext } from '../../utils/firebase'
import 'firebase/auth'

import { useQuery } from 'react-query'
import moment from 'moment-timezone'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import '../../css/react_dates_overrides.css'
import { DayPickerRangeController } from 'react-dates'
import { ANCHOR_LEFT, ANCHOR_RIGHT, START_DATE, END_DATE } from 'react-dates/constants'

import { API_ROOT_URL, DATETIME_RESOLUTION_MAP } from '../../constants'
import { capitalizeFirstLetter } from '../../utils/helpers'
import DarkTooltip from './DarkTooltip'

const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const useStyles = makeStyles(theme => ({
  datePickerShortcutsContainer: {
    textAlign: 'center',
  },
  datePickerShortcuts: {
    margin: theme.spacing(1) / 2,
  },
  popover: {
    marginTop: theme.spacing(0.5),
  },
}))

const CustomDateRangePicker = ({
  startDate,
  endDate,
  resolution,
  onDatesChange,
  anchorDirection,
  allowFuture,
  allowJumping,
  allowAdjusting,
}) => {
  const classes = useStyles()
  const firebase = React.useContext(FirebaseContext)
  const [focusedInput, setFocusedInput] = React.useState(START_DATE)

  const [inputStartDate, setInputStartDate] = React.useState(startDate)
  const [inputEndDate, setInputEndDate] = React.useState(endDate)
  const [dateRangeAnchorEl, setDateRangeAnchorEl] = React.useState(null)

  const [textFieldStartDate, setTextFieldStartDate] = React.useState(startDate.format('YYYY-MM-DD'))
  const [textFieldEndDate, setTextFieldEndDate] = React.useState(endDate.format('YYYY-MM-DD'))
  const [textFieldStartDateError, setTextFieldStartDateError] = React.useState(false)
  const [textFieldEndDateError, setTextFieldEndDateError] = React.useState(false)

  const [jumpDatesMenuAnchorEl, setJumpDatesMenuAnchorEl] = React.useState(null)
  const [jumpDatesDirection, setJumpDatesDirection] = React.useState(null)

  // Reflects changes to start and endDate in the input dates
  React.useEffect(() => {
    setInputStartDate(startDate)
    setInputEndDate(endDate)
  }, [startDate, endDate])

  // Reflect changes to dates using picker in the text fields
  React.useEffect(() => {
    setTextFieldStartDate(inputStartDate.format('YYYY-MM-DD'))
    // Handle case when inputEndDate is null when starting new range
    if (inputEndDate) {
      setTextFieldEndDate(inputEndDate.format('YYYY-MM-DD'))
    } else {
      setTextFieldEndDate(inputStartDate.format('YYYY-MM-DD'))
    }
  }, [inputStartDate, inputEndDate])

  const { isLoading, data } = useQuery(['date_range'], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api2/dates?tz=${TIMEZONE}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      }).then(res => res.json())
    }),
    {
      cacheTime: 15 * 60 * 1000,  // 15 minutes
      staleTime: 15 * 60 * 1000,  // 15 minutes
      refetchInterval: 60 * 60 * 1000,  // 60 minutes
      refetchOnWindowFocus: false,
    }
  )

  const handleClickDatePickerShortcut = value => {
    const todayMoment = moment.tz(TIMEZONE).endOf('day')
    let newStartDate
    let newEndDate
    switch (value) {
      case 7:
      case 14:
      case 30:
      case 90:
      case 180:
      case 365:
        newStartDate = moment.tz(TIMEZONE).subtract(value, 'days').startOf('day')
        newEndDate = todayMoment.subtract(1, 'days')
        break
      case 'mtd':
        newStartDate = moment.tz(TIMEZONE).startOf('month')
        newEndDate = todayMoment
        break
      case 'ytd':
        newStartDate = moment.tz(TIMEZONE).startOf('year')
        newEndDate = todayMoment
        break
      case 'all':
        newStartDate = data ? moment.tz(data.first_date, TIMEZONE) : startDate
        newEndDate = todayMoment
        break
      default:
        newStartDate = inputStartDate
        newEndDate = inputEndDate
        break
    }
    setInputStartDate(newStartDate)
    setInputEndDate(newEndDate)
    setFocusedInput(START_DATE)
  }

  const handleInputDatesChange = ({ startDate: newStartDate, endDate: newEndDate }) => {
    setInputStartDate(newStartDate ? newStartDate.clone().startOf('day') : null)
    setInputEndDate(newEndDate ? newEndDate.clone().endOf('day') : null)
  }

  const handleDateRangeClick = (event) => {
    setDateRangeAnchorEl(event.currentTarget)
  }

  const handleDateRangeClose = () => {
    setDateRangeAnchorEl(null)
  }

  const handleFocusChange = (newFocusedInput) => {
    if (!newFocusedInput) {
      setFocusedInput(START_DATE)
    } else {
      setFocusedInput(newFocusedInput)
    }
  }

  const formatTextFieldDate = (value, inputType) => {
    let formattedValue = value
    if (inputType === 'deleteContentBackward') {
      return formattedValue
    }

    const re = /([0-9]{4})(-*([0-9]{1,2})(-*([0-9]{1,2}))?)?/
    const match = value.match(re)
    if (match) {
      const year = match[1]
      const month = match[3] || ''
      const day = match[5] || ''
      formattedValue = `${year}-${month.length === 2 ? month + '-' : month}${day ? day : ''}`
    }

    return formattedValue
  }

  const handleStartDateTextFieldChange = (event) => {
    const formattedValue = formatTextFieldDate(event.target.value, event.nativeEvent.inputType)
    setTextFieldStartDate(formattedValue)

    let dateIsValid = event.target.value.length === 10 && moment.tz(event.target.value, TIMEZONE).isValid()
    // If the date is valid but future is not allowed, check if the date is before or equal to today
    if (dateIsValid && !allowFuture) {
      dateIsValid = moment.tz(event.target.value, TIMEZONE).isSameOrBefore(moment.tz(TIMEZONE))
    }

    if (dateIsValid) {
      const newStartDate = moment.tz(event.target.value, TIMEZONE)
      setInputStartDate(newStartDate)
      if (newStartDate.isAfter(inputEndDate)) {
        setInputEndDate(newStartDate)
      }
      setTextFieldStartDateError(false)
    } else {
      setTextFieldStartDateError(true)
    }
  }

  const handleEndDateTextFieldChange = (event) => {
    const formattedValue = formatTextFieldDate(event.target.value, event.nativeEvent.inputType)
    setTextFieldEndDate(formattedValue)

    let dateIsValid = event.target.value.length === 10 && moment.tz(event.target.value, TIMEZONE).isValid()
    // If the date is valid but future is not allowed, check if the date is before or equal to today
    if (dateIsValid && !allowFuture) {
      dateIsValid = moment.tz(event.target.value, TIMEZONE).isSameOrBefore(moment.tz(TIMEZONE))
    }

    if (dateIsValid) {
      const newEndDate = moment.tz(event.target.value, TIMEZONE).endOf('day')
      setInputEndDate(newEndDate)
      if (newEndDate.isBefore(inputStartDate)) {
        setInputStartDate(newEndDate)
      }
      setTextFieldEndDateError(false)
    } else {
      setTextFieldEndDateError(true)
    }
  }

  const handleKeyDownDateTextField = (e) => {
    if (e.keyCode == 13) {
      handleApplyDates()
    }
  }

  const handleApplyDates = () => {
    handleDateRangeClose()
    onDatesChange({ startDate: inputStartDate, endDate: inputEndDate ? inputEndDate : inputStartDate })
  }

  const handleJumpDatesMenuOpen = (event, direction) => {
    setJumpDatesMenuAnchorEl(event.currentTarget)
    setJumpDatesDirection(direction)
  }

  const handleJumpDatesMenuClose = () => {
    setJumpDatesMenuAnchorEl(null)
    setJumpDatesDirection(null)
  }

  const handleJumpDates = ({ startDate: newStartDate, endDate: newEndDate }) => {
    handleJumpDatesMenuClose()
    onDatesChange({ startDate: newStartDate, endDate: newEndDate })
  }

  const handleAdjustDateRangeToCompletePeriods = () => {
    // Get moment resolution for the current resolution
    const momentResolution = resolution === 1 ? 'isoWeek' : DATETIME_RESOLUTION_MAP[resolution]

    // Adjust the start date to the first day of the period
    const newStartDate = moment.tz(startDate, TIMEZONE).startOf(momentResolution)

    // Adjust the end date to the last day of the previous period, unless it's already the last day of the period
    let newEndDate = moment.tz(endDate, TIMEZONE);
    if (!newEndDate.isSame(newEndDate.clone().endOf(momentResolution))) {
      newEndDate = newEndDate.subtract(1, momentResolution).endOf(momentResolution);
    }

    onDatesChange({ startDate: newStartDate, endDate: newEndDate })
  }

  const jumpDates = useMemo(() => {
    const periodDays = endDate.diff(startDate, 'days') + 1
    return {
      back: {
        period: {
          startDate: startDate.clone().subtract(periodDays, 'days'),
          endDate: endDate.clone().subtract(periodDays, 'days'),
        },
        week: {
          startDate: startDate.clone().subtract(1, 'weeks'),
          endDate: endDate.clone().subtract(1, 'weeks'),
        },
        month: {
          startDate: startDate.clone().subtract(1, 'months'),
          endDate: endDate.clone().subtract(1, 'months'),
        },
        quarter: {
          startDate: startDate.clone().subtract(1, 'quarters'),
          endDate: endDate.clone().subtract(1, 'quarters'),
        },
        year: {
          startDate: startDate.clone().subtract(1, 'years'),
          endDate: endDate.clone().subtract(1, 'years'),
        },
      },
      forward: {
        period: {
          startDate: startDate.clone().add(periodDays, 'days'),
          endDate: endDate.clone().add(periodDays, 'days'),
        },
        week: {
          startDate: startDate.clone().add(1, 'weeks'),
          endDate: endDate.clone().add(1, 'weeks'),
        },
        month: {
          startDate: startDate.clone().add(1, 'months'),
          endDate: endDate.clone().add(1, 'months'),
        },
        quarter: {
          startDate: startDate.clone().add(1, 'quarters'),
          endDate: endDate.clone().add(1, 'quarters'),
        },
        year: {
          startDate: startDate.clone().add(1, 'years'),
          endDate: endDate.clone().add(1, 'years'),
        },
      }
    }
  }, [startDate, endDate])

  const dateNeedsAdjusting = useMemo(() => {
    const momentResolution = resolution === 1 ? 'isoWeek' : DATETIME_RESOLUTION_MAP[resolution]
    const isStartDateAtStartOfPeriod = moment.tz(startDate, TIMEZONE).isSame(moment.tz(startDate, TIMEZONE).startOf(momentResolution))
    const isEndDateAtEndOfPeriod = moment.tz(endDate, TIMEZONE).isSame(moment.tz(endDate, TIMEZONE).endOf(momentResolution))
    return !isStartDateAtStartOfPeriod || !isEndDateAtEndOfPeriod
  }, [startDate, endDate, resolution])

  return (
    <Box display='flex'>
      <ButtonGroup
        variant='contained'
        size='small'
        disableElevation
        sx={{
          height: 31,
          border: theme => `1px solid ${theme.palette.divider}`,
          '& .MuiButtonGroup-firstButton': {
            borderColor: theme => theme.palette.divider,
          },
          '& .MuiButtonGroup-middleButton': {
            borderColor: theme => theme.palette.divider,
          },
          '& .MuiButtonGroup-lastButton': {
            borderColor: theme => theme.palette.divider,
          },
          '& .MuiButtonGroup-grouped': {
            minWidth: 24,
          },
        }}
      >
        {/* Jump dates back shortcut button */}
        {allowJumping && (
          <DarkTooltip
            title='Jump dates back'
            placement='bottom'
            arrow
          >
            <Button
              onClick={(e) => handleJumpDatesMenuOpen(e, 'back')}
              sx={{
                width: 24,
                padding: theme => theme.spacing(0.5, 0),
                backgroundColor: theme => theme.palette.background.paper,
                color: theme => theme.palette.text.primary,
                '&:hover': {
                  backgroundColor: theme => theme.palette.action.hover,
                },
              }}
            >
              <KeyboardArrowLeft fontSize='small' />
            </Button>
          </DarkTooltip>
        )}

        {/* Date range picker popover button */}
        <Button
          startIcon={<DateRangeIcon />}
          onClick={handleDateRangeClick}
          sx={{
            textTransform: 'none',
            fontSize: 13,
            backgroundColor: theme => theme.palette.background.paper,
            color: theme => theme.palette.text.primary,
            '&:hover': {
              backgroundColor: theme => theme.palette.action.hover,
            },
          }}
        >
          {startDate.format('MMM D, YYYY')}&#8212;{endDate.format('MMM D, YYYY')}
          <Typography variant='caption' color='textSecondary'>
            &nbsp; {moment.tz(TIMEZONE).zoneAbbr()}
          </Typography>
        </Button>

        {/* Jump dates forward shortcut button */}
        {allowJumping && (
          <DarkTooltip
            title='Jump dates forward'
            placement='bottom'
            arrow
          >
            <Button
              onClick={(e) => handleJumpDatesMenuOpen(e, 'forward')}
              sx={{
                width: 24,
                padding: theme => theme.spacing(0.5, 0),
                backgroundColor: theme => theme.palette.background.paper,
                color: theme => theme.palette.text.primary,
                '&:hover': {
                  backgroundColor: theme => theme.palette.action.hover,
                },
              }}
            >
              <KeyboardArrowRight fontSize='small' />
            </Button>
          </DarkTooltip>
        )}

        {/* Date Adjustment shortcut button */}
        {allowAdjusting && resolution !== 0 && dateNeedsAdjusting && (
          <DarkTooltip
            title={`Adjust dates to nearest complete ${DATETIME_RESOLUTION_MAP[resolution]}s`}
            placement='bottom'
            arrow
          >
            <Button
              onClick={handleAdjustDateRangeToCompletePeriods}
              sx={{
                width: 24,
                padding: theme => theme.spacing(0.5, 0),
                backgroundColor: theme => theme.palette.background.paper,
                color: theme => theme.palette.text.primary,
                '&:hover': {
                  backgroundColor: theme => theme.palette.action.hover,
                },
              }}
            >
              <AutoFixHighSharpIcon fontSize='small' color='secondary' />
            </Button>
          </DarkTooltip>
        )}
      </ButtonGroup>

      {/* Date jumping menu */}
      {allowJumping && jumpDatesMenuAnchorEl && jumpDatesDirection && (
        <JumpDatesMenu
          direction={jumpDatesDirection}
          jumpDates={jumpDates}
          jumpDatesMenuAnchorEl={jumpDatesMenuAnchorEl}
          allowFuture={allowFuture}
          handleJumpDatesMenuClose={handleJumpDatesMenuClose}
          handleJumpDates={handleJumpDates}
        />
      )}

      <Popover
        className={classes.popover}
        open={Boolean(dateRangeAnchorEl)}
        anchorEl={dateRangeAnchorEl}
        onClose={handleDateRangeClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: anchorDirection, }}
        transformOrigin={{ vertical: 'top', horizontal: anchorDirection, }}
        TransitionComponent={Fade}
      >
        <Box>
          <Box padding={2} display='flex' flexDirection='row' justifyContent='center' alignItems='center' columnGap={1}>
            <Box flexGrow={1}>
              <TextField
                label='Start'
                variant='outlined'
                color='primary'
                value={textFieldStartDate}
                onChange={handleStartDateTextFieldChange}
                onKeyDown={handleKeyDownDateTextField}
                onFocus={() => setFocusedInput(START_DATE)}
                placeholder='YYYY-MM-DD'
                error={textFieldStartDateError}
                size='small'
                fullWidth
              />
            </Box>
            <Box flexGrow={1}>
              <TextField
                label='End'
                variant='outlined'
                color='primary'
                value={textFieldEndDate}
                onChange={handleEndDateTextFieldChange}
                onKeyDown={handleKeyDownDateTextField}
                onFocus={() => setFocusedInput(END_DATE)}
                placeholder='YYYY-MM-DD'
                error={textFieldEndDateError}
                size='small'
                fullWidth
              />
            </Box>
            <Box>
              <DarkTooltip
                title={`Timezone: ${TIMEZONE}`}
                placement='bottom'
                arrow
              >
                <Typography variant='body2' color='textSecondary'>
                  {moment.tz(TIMEZONE).zoneAbbr()}
                </Typography>
              </DarkTooltip>
            </Box>
          </Box>
          <Box>
            <DayPickerRangeController
              startDate={inputStartDate}
              endDate={inputEndDate}
              onDatesChange={handleInputDatesChange}
              focusedInput={focusedInput}
              onFocusChange={focusedInput => handleFocusChange(focusedInput)}
              firstDayOfWeek={1}
              isOutsideRange={dayMoment => allowFuture ? false : dayMoment.isAfter(moment.tz(TIMEZONE), 'day')}
              initialVisibleMonth={() => moment(inputEndDate ? inputEndDate : undefined).subtract(1, 'M')}
              minimumNights={0}
              numberOfMonths={2}
              calendarInfoPosition='top'
              renderCalendarInfo={() => <DateRangeShortcuts onClick={handleClickDatePickerShortcut} loadingAll={isLoading} />}
              transitionDuration={0}
              hideKeyboardShortcutsPanel
            />
          </Box>
          <Box padding={2} display='flex' flexDirection='row' justifyContent='flex-end' columnGap={1}>
            <Button
              variant='text'
              size='small'
              color='inherit'
              onClick={handleDateRangeClose}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              size='small'
              color='secondary'
              onClick={handleApplyDates}
              disabled={textFieldStartDateError || textFieldEndDateError}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Popover>
    </Box>
  )
}

const DateRangeShortcuts = (props) => {
  const classes = useStyles()
  return (
    <div className={classes.datePickerShortcutsContainer}>
      <Button
        className={classes.datePickerShortcuts}
        variant='text'
        color='secondary'
        size='small'
        onClick={() => props.onClick(7)}
      >
        Last 7
      </Button>
      <Button
        className={classes.datePickerShortcuts}
        variant='text'
        color='secondary'
        size='small'
        onClick={() => props.onClick(14)}
      >
        Last 14
      </Button>
      <Button
        className={classes.datePickerShortcuts}
        variant='text'
        color='secondary'
        size='small'
        onClick={() => props.onClick(30)}
      >
        Last 30
      </Button>
      <Button
        className={classes.datePickerShortcuts}
        variant='text'
        color='secondary'
        size='small'
        onClick={() => props.onClick(90)}
      >
        Last 90
      </Button>
      <Button
        className={classes.datePickerShortcuts}
        variant='text'
        color='secondary'
        size='small'
        onClick={() => props.onClick(180)}
      >
        Last 180
      </Button>
      <Button
        className={classes.datePickerShortcuts}
        variant='text'
        color='secondary'
        size='small'
        onClick={() => props.onClick(365)}
      >
        Last 365
      </Button>
      <Button
        className={classes.datePickerShortcuts}
        variant='text'
        color='secondary'
        size='small'
        onClick={() => props.onClick('mtd')}
      >
        MTD
      </Button>
      <Button
        className={classes.datePickerShortcuts}
        variant='text'
        color='secondary'
        size='small'
        onClick={() => props.onClick('ytd')}
      >
        YTD
      </Button>
      <Button
        className={classes.datePickerShortcuts}
        variant='text'
        color='secondary'
        size='small'
        onClick={() => props.onClick('all')}
        disabled={props.loadingAll}
      >
        All
      </Button>
    </div>
  )
}

const JumpDatesMenu = ({
  direction,
  jumpDates,
  jumpDatesMenuAnchorEl,
  allowFuture,
  handleJumpDatesMenuClose,
  handleJumpDates,
}) => {
  const items = ['period', 'week', 'month', 'quarter', 'year']

  const formatDateRange = (startDate, endDate) => {
    return `${startDate.format('MMM D, YYYY')} - ${endDate.format('MMM D, YYYY')}`
  }

  return (
    <Popover
      open={Boolean(jumpDatesMenuAnchorEl)}
      anchorEl={jumpDatesMenuAnchorEl}
      onClose={handleJumpDatesMenuClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
      transformOrigin={{ vertical: 'top', horizontal: 'left', }}
    >
      <List component='ul' aria-label='jump dates' dense subheader={<ListSubheader>Jump dates {direction}...</ListSubheader>}>
        {items.map(item =>
          <ListItemButton
            key={item}
            onClick={() => handleJumpDates(jumpDates[direction][item])}
            disabled={!allowFuture && jumpDates[direction][item].endDate.isAfter(moment.tz(TIMEZONE), 'day')}
            dense
          >
            <ListItemText
              primary={`${capitalizeFirstLetter(item)}`}
              secondary={formatDateRange(jumpDates[direction][item].startDate, jumpDates[direction][item].endDate)}
            />
          </ListItemButton>
        )}
      </List>
    </Popover>
  )
}

CustomDateRangePicker.defaultProps = {
  anchorDirection: ANCHOR_LEFT,
  allowFuture: false,
  allowJumping: false,
  allowAdjusting: false,
}

CustomDateRangePicker.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  resolution: PropTypes.number.isRequired,
  onDatesChange: PropTypes.func.isRequired,
  anchorDirection: PropTypes.oneOf([ANCHOR_LEFT, ANCHOR_RIGHT]),
  allowFuture: PropTypes.bool,
  allowJumping: PropTypes.bool,
  allowAdjusting: PropTypes.bool,
}

export default CustomDateRangePicker
