import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Snackbar from '@mui/material/Snackbar'
import TextField from '@mui/material/TextField'

import { useMutation } from 'react-query'

import { FirebaseContext } from '../../utils/firebase'
import 'firebase/auth'

import { API_ROOT_URL } from '../../constants/'

const SUCCESS_MESSAGE = 'Format created successfully.'
const ERROR_MESSAGE = 'Format creation failed. Try again, and if error persists, contact support.'

const CreateFormatDialog = (props) => {
  const firebase = React.useContext(FirebaseContext)
  const [formatName, setFormatName] = React.useState('')
  const [formatDataType, setFormatDataType] = React.useState('')
  const [showMessage, setShowMessage] = React.useState(false)
  const [messageSeverity, setMessageSeverity] = React.useState('success')

  const handleFormatNameChange = (event) => {
    setFormatName(event.target.value)
  }

  const handleSelectFormatDataType = (event) => {
    setFormatDataType(event.target.value)
  }

  const handleCloseDialog = () => {
    setFormatName('')
    setFormatDataType('')
    props.onClose()
  }

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') return
    setShowMessage(false)
  }

  const { mutate: mutateCreateMapping, isLoading: isLoadingCreateMapping } = useMutation(
    () => firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/dataMaps`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          map: formatDataType === 'contact' ? props.emptyDataMapContact : props.emptyDataMapPayment,
          name: formatName,
          type: formatDataType,
          acceptAllRows: false
        })
      })
    }),
    {
      onSuccess: () => {
        setFormatName('')
        setFormatDataType('')
        setShowMessage(true)
        setMessageSeverity('success')
        props.onSuccess()
      },
      onError: () => {
        setShowMessage(true)
        setMessageSeverity('error')
      }
    }
  )

  return (
    <Fragment>
      <Dialog
        open={props.open}
        onClose={handleCloseDialog}
      >
        <DialogTitle>
          Create New Format
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Specify the name of the format and the data type it will be associated with.
            <br />
            <br />
            <em>Note: after the format is created, the data type cannot be changed.</em>
          </DialogContentText>

          <Box marginTop={2}>
            <Box>
              <TextField
                label='Name'
                variant='outlined'
                fullWidth
                size='medium'
                value={formatName}
                onChange={handleFormatNameChange}
              />
            </Box>
            <Box marginTop={2}>
              <FormControl variant='outlined' style={{ minWidth: 150 }}>
                <InputLabel id='data-type-label'>Data Type</InputLabel>
                <Select
                  labelId='data-type-label'
                  id='data-type-select'
                  label='Data Type'
                  variant='outlined'
                  value={formatDataType}
                  onChange={handleSelectFormatDataType}
                >
                  <MenuItem value='payment'>Payment</MenuItem>
                  <MenuItem value='contact'>Contact</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button
            color='secondary'
            onClick={() => mutateCreateMapping()}
            disabled={!formatName || !formatDataType}
          >
            {isLoadingCreateMapping ? <CircularProgress size={24} /> : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showMessage}
        autoHideDuration={10000}
        onClose={handleCloseMessage}
      >
        <Alert
          severity={messageSeverity}
          variant='filled'
          onClose={handleCloseMessage}
        >
          {messageSeverity === 'success' ? SUCCESS_MESSAGE : ERROR_MESSAGE}
        </Alert>
      </Snackbar>
    </Fragment>
  );
}

CreateFormatDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  emptyDataMapContact: PropTypes.object.isRequired,
  emptyDataMapPayment: PropTypes.object.isRequired,
}

export default CreateFormatDialog