import React from 'react'
import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'

import DateRangePicker from '../../Common/DateRangePicker'
import AddFilter from './AddFilter'
import BreakdownSelect from './BreakdownSelect'
import AttributionSelect from './AttributionSelect'
import CommissionSwitch from './CommissionSwitch'
import CostsSwitch from './CostsSwitch'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
  },
  controlForm: {
    marginLeft: theme.spacing(2),
    width: 90,
  },
}))

const ControlsConfiguration = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <DateRangePicker
        startDate={props.startDate}
        endDate={props.endDate}
        onDatesChange={props.onDatesChange}
      />
      <AddFilter
        filters={props.filters}
        onFilterAdd={props.onFilterAdd}
      />
      <BreakdownSelect
        onBreakdownSelect={props.onBreakdownSelect}
      />
      <AttributionSelect
        attribution={props.attribution}
        onAttributionSelect={props.onAttributionSelect}
      />
      <div style={{ flexGrow: 1 }} />
      <CommissionSwitch
        includeCommission={props.includeCommission}
        onIncludeCommissionChange={props.onIncludeCommissionChange}
      />
      <CostsSwitch
        includeCosts={props.includeCosts}
        onIncludeCostsChange={props.onIncludeCostsChange}
      />
    </div>
  )
}

ControlsConfiguration.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onDatesChange: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired,
  onFilterAdd: PropTypes.func.isRequired,
  onBreakdownSelect: PropTypes.func.isRequired,
  attribution: PropTypes.string.isRequired,
  onAttributionSelect: PropTypes.func.isRequired,
  includeCommission: PropTypes.bool.isRequired,
  onIncludeCommissionChange: PropTypes.func.isRequired,
  includeCosts: PropTypes.bool.isRequired,
  onIncludeCostsChange: PropTypes.func.isRequired,
}

export default ControlsConfiguration
