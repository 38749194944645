import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import moment from 'moment-timezone'

import ColorPicker from './ColorPicker'
import DarkTooltip from './DarkTooltip'

import {
  getBreakdownAlias,
  getCohortBreakdownResolution,
  getDisplayValueByFormat,
  isCohortBreakdown,
  shortenString
} from '../../utils/helpers'

const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const ContextChipBreakdown = ({
  segment,
  breakdownKey,
  aliases,
  onSegmentBreakdownColorChange,
}) => {
  let name = getBreakdownAlias(aliases, segment.breakdown.field, breakdownKey)
  if (isCohortBreakdown(segment.breakdown)) {
    const breakdownResolution = getCohortBreakdownResolution(segment.breakdown)
    name = getDisplayValueByFormat(
      breakdownKey,
      'date_time',
      {
        resolution: breakdownResolution,
        timezone: TIMEZONE
      }
    )
  }
  return (
    <Box
      key={breakdownKey}
      display='flex'
      alignItems='center'
      sx={{
        backgroundColor: '#fff',
        alignItems: 'center',
        border: '1px solid #dbdbdb',
        borderRadius: theme => theme.shape.borderRadius / 4,
        padding: '6px 8px 6px 8px',
      }}
      columnGap={0.5}
    >
      <ColorPicker
        type='segment'
        color={segment.breakdownState[breakdownKey].color}
        width={12}
        height={12}
        onColorChange={color => onSegmentBreakdownColorChange(segment.id, breakdownKey, color)}
      />
      <DarkTooltip
        title={name}
        placement='bottom'
        arrow
      >
        <Typography variant='body1' sx={{ fontSize: 13, lineHeight: '13px', color: theme => theme.palette.text.secondary, flexShrink: 0 }}>
          {shortenString(name, 32)}
        </Typography>
      </DarkTooltip>
    </Box>
  )
}

ContextChipBreakdown.defaultProps = {
  kind: 'all',
}

ContextChipBreakdown.propTypes = {
  segment: PropTypes.object.isRequired,
  breakdownKey: PropTypes.string.isRequired,
  aliases: PropTypes.object.isRequired,
  onSegmentBreakdownColorChange: PropTypes.func.isRequired
}

export default ContextChipBreakdown