// Mapping of report identifiers to their definitions

import METRIC_ITEMS_MAP from './MetricItems'

export const CORE_REPORTS = [
  {
    'kind': 'core',
    'key': 'sales_overview',
    'name': 'Sales Overview',
    'description': 'Core sales metrics over time',
    'xKey': 'date_time',
    'xKeyAsRange': false,
    'allowSegmentQuickAdd': true,
    'allowCohorts': false,
    'splitSegmentFrontBack': false,
    'emptyDataRule': 'zero',
    'dateRangeType': 'dynamic',
    'dateRangeDynamicDays': 90,
    'dateRangeFixedStartDate': null,
    'dateRangeFixedEndDate': null,
    'resolution': 1,
    'segments': [],
    'config': {
      'includeCosts': true,
      'includeCommission': false,
      'kind': 'all',
    },
    'configItems': {
      'includeCosts': {
        'show': false,
        'name': 'Include Costs',
        'items': [
          {
            'value': true,
            'name': 'Yes'
          },
          {
            'value': false,
            'name': 'No'
          },
        ],
      },
      'includeCommission': {
        'show': true,
        'name': 'Affiliate Commission',
        'items': [
          {
            'value': false,
            'name': 'Subtract from revenue'
          },
          {
            'value': true,
            'name': 'Add to revenue'
          },
        ],
      },
      'kind': {
        'show': true,
        'name': 'Sale Kind',
        'items': [
          {
            'value': 'all',
            'name': 'All'
          },
          {
            'value': 'frontend',
            'name': 'Frontend'
          },
          {
            'value': 'backend',
            'name': 'Backend'
          },
        ],
      },
    },
    'metrics': {
      'defaultKey': 'revenue_net',
      'items': [
        {
          ...METRIC_ITEMS_MAP['revenue_net'],
        },
        {
          ...METRIC_ITEMS_MAP['revenue_gross'],
          'secondary': {
            ...METRIC_ITEMS_MAP['revenue_refunds'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['revenue_orders'],
          'secondary': {
            ...METRIC_ITEMS_MAP['orders'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['revenue_rebills'],
          'secondary': {
            ...METRIC_ITEMS_MAP['rebills'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['revenue_refunds'],
          'secondary': {
            ...METRIC_ITEMS_MAP['refunds'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['aov'],
          'secondary': {
            ...METRIC_ITEMS_MAP['orders'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['orders'],
        },
        {
          ...METRIC_ITEMS_MAP['customers'],
        },
      ],
    },
    'milestoneDays': [],
    'chart': {
      'allowSecondaryChart': true,
      'allowLineDots': true,
      'allowUseStack100': true,
      'cumulativeDefault': false,
      'hideYValuesAfterToday': false,
    },
    'table': {
      'defaultTab': 'summary',
      'summary': {
        'show': true,
        'columns': {
          'default': {
            'type': 'metrics',
            'name': 'Overview',
            'description': 'Overview of Key Metrics',
            'orderBy': 'customers',
            'order': 'desc',
            'items': [
              {
                ...METRIC_ITEMS_MAP['customers'],
              },
              {
                ...METRIC_ITEMS_MAP['orders'],
              },
              {
                ...METRIC_ITEMS_MAP['aov'],
              },
              {
                ...METRIC_ITEMS_MAP['revenue_net'],
              },
              {
                ...METRIC_ITEMS_MAP['revenue_gross'],
              },
              {
                ...METRIC_ITEMS_MAP['revenue_orders'],
              },
              {
                ...METRIC_ITEMS_MAP['revenue_rebills'],
              },
              {
                ...METRIC_ITEMS_MAP['revenue_refunds'],
              },
              {
                ...METRIC_ITEMS_MAP['rebills'],
              },
              {
                ...METRIC_ITEMS_MAP['refunds'],
              },
            ],
          },
        },
      },
      'chart': {
        'show': true,
      },
      'milestones': {
        'show': false,
        'days': [],
      },
    },
  },

  {
    'kind': 'core',
    'key': 'revenue',
    'name': 'Revenue',
    'description': 'Revenue over time',
    'xKey': 'date_time',
    'xKeyAsRange': false,
    'allowSegmentQuickAdd': true,
    'allowCohorts': false,
    'splitSegmentFrontBack': false,
    'emptyDataRule': 'zero',
    'dateRangeType': 'dynamic',
    'dateRangeDynamicDays': 90,
    'dateRangeFixedStartDate': null,
    'dateRangeFixedEndDate': null,
    'resolution': 1,
    'segments': [],
    'config': {
      'includeCosts': true,
      'includeCommission': false,
    },
    'configItems': {
      'includeCosts': {
        'show': false,
        'name': 'Include Costs',
        'items': [
          {
            'value': true,
            'name': 'Yes'
          },
          {
            'value': false,
            'name': 'No'
          },
        ],
      },
      'includeCommission': {
        'show': true,
        'name': 'Affiliate Commission',
        'items': [
          {
            'value': false,
            'name': 'Subtract from revenue'
          },
          {
            'value': true,
            'name': 'Add to revenue'
          },
        ],
      },
    },
    'metrics': {
      'defaultKey': 'revenue_net',
      'items': [
        {
          ...METRIC_ITEMS_MAP['revenue_net'],
        },
        {
          ...METRIC_ITEMS_MAP['revenue_net_frontend'],
          'secondary': {
            ...METRIC_ITEMS_MAP['revenue_net_backend'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['revenue_net_backend'],
          'secondary': {
            ...METRIC_ITEMS_MAP['revenue_net_frontend'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['revenue_gross'],
          'secondary': {
            ...METRIC_ITEMS_MAP['revenue_refunds'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['revenue_orders'],
        },
        {
          ...METRIC_ITEMS_MAP['revenue_rebills'],
        },
        {
          ...METRIC_ITEMS_MAP['revenue_refunds'],
        },
      ],
    },
    'milestoneDays': [],
    'chart': {
      'allowSecondaryChart': true,
      'allowLineDots': true,
      'allowUseStack100': true,
      'cumulativeDefault': false,
      'hideYValuesAfterToday': false,
    },
    'table': {
      'defaultTab': 'summary',
      'summary': {
        'show': true,
        'columns': {
          'default': {
            'type': 'metrics',
            'name': 'Overview',
            'description': 'Overview of Key Metrics',
            'orderBy': 'revenue_net',
            'order': 'desc',
            'items': [
              {
                ...METRIC_ITEMS_MAP['revenue_net'],
              },
              {
                ...METRIC_ITEMS_MAP['revenue_net_frontend'],
              },
              {
                ...METRIC_ITEMS_MAP['revenue_net_backend'],
              },
              {
                ...METRIC_ITEMS_MAP['revenue_gross'],
              },
              {
                ...METRIC_ITEMS_MAP['revenue_orders'],
              },
              {
                ...METRIC_ITEMS_MAP['revenue_rebills'],
              },
              {
                ...METRIC_ITEMS_MAP['revenue_refunds'],
              },
            ],
          },
        },
      },
      'chart': {
        'show': true,
      },
      'milestones': {
        'show': false,
        'days': [],
      },
    },
  },

  {
    'kind': 'core',
    'key': 'aov',
    'name': 'Average Order Value',
    'description': 'Order revenue divided by number of orders, with options to see total, frontend, and backend AOVs.',
    'xKey': 'date_time',
    'xKeyAsRange': false,
    'allowSegmentQuickAdd': true,
    'allowCohorts': false,
    'splitSegmentFrontBack': false,
    'emptyDataRule': 'empty',
    'dateRangeType': 'dynamic',
    'dateRangeDynamicDays': 90,
    'dateRangeFixedStartDate': null,
    'dateRangeFixedEndDate': null,
    'resolution': 1,
    'segments': [],
    'config': {
      'includeCosts': true,
      'includeCommission': false,
    },
    'configItems': {
      'includeCosts': {
        'show': false,
        'name': 'Include Costs',
        'items': [
          {
            'value': true,
            'name': 'Yes'
          },
          {
            'value': false,
            'name': 'No'
          },
        ],
      },
      'includeCommission': {
        'show': true,
        'name': 'Affiliate Commission',
        'items': [
          {
            'value': false,
            'name': 'Subtract from revenue'
          },
          {
            'value': true,
            'name': 'Add to revenue'
          },
        ],
      },
    },
    'metrics': {
      'defaultKey': 'aov',
      'items': [
        {
          ...METRIC_ITEMS_MAP['aov'],
          'secondary': {
            ...METRIC_ITEMS_MAP['orders'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['aov_frontend'],
          'secondary': {
            ...METRIC_ITEMS_MAP['orders_frontend'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['aov_backend'],
          'secondary': {
            ...METRIC_ITEMS_MAP['orders_backend'],
          },
        },
      ],
    },
    'milestoneDays': [],
    'chart': {
      'allowSecondaryChart': true,
      'allowLineDots': true,
      'allowUseStack100': true,
      'cumulativeDefault': false,
      'hideYValuesAfterToday': false,
    },
    'table': {
      'defaultTab': 'summary',
      'summary': {
        'show': true,
        'columns': {
          'default': {
            'type': 'metrics',
            'name': 'Overview',
            'description': 'Overview of Key Metrics',
            'orderBy': 'orders',
            'order': 'desc',
            'items': [
              {
                ...METRIC_ITEMS_MAP['orders'],
              },
              {
                ...METRIC_ITEMS_MAP['orders_frontend'],
              },
              {
                ...METRIC_ITEMS_MAP['orders_backend'],
              },
              {
                ...METRIC_ITEMS_MAP['aov'],
              },
              {
                ...METRIC_ITEMS_MAP['aov_frontend'],
              },
              {
                ...METRIC_ITEMS_MAP['aov_backend'],
              },
            ],
          }
        },
      },
      'chart': {
        'show': true,
      },
      'milestones': {
        'show': false,
        'days': [],
      },
    },
  },

  {
    'kind': 'core',
    'key': 'aovc',
    'name': 'AOV Contributions',
    'description': 'Contributions of each product to order value metrics. It is recommended that you use this report with filters or segments that include a funnel, and apply a product breakdown to see how much each product contributes to AOV. Note that the abbreviation "GP" stands for Gross Profit.',
    'xKey': 'date_time',
    'xKeyAsRange': false,
    'allowSegmentQuickAdd': true,
    'allowCohorts': false,
    'splitSegmentFrontBack': false,
    'emptyDataRule': 'empty',
    'dateRangeType': 'dynamic',
    'dateRangeDynamicDays': 90,
    'dateRangeFixedStartDate': null,
    'dateRangeFixedEndDate': null,
    'resolution': 1,
    'segments': [],
    'config': {
      'includeCosts': true,
      'includeCommission': false,
      'kind': 'all',
    },
    'configItems': {
      'includeCosts': {
        'show': false,
        'name': 'Include Costs',
        'items': [
          {
            'value': true,
            'name': 'Yes'
          },
          {
            'value': false,
            'name': 'No'
          },
        ],
      },
      'includeCommission': {
        'show': true,
        'name': 'Affiliate Commission',
        'items': [
          {
            'value': false,
            'name': 'Subtract from revenue'
          },
          {
            'value': true,
            'name': 'Add to revenue'
          },
        ],
      },
      'kind': {
        'show': true,
        'name': 'Order Kind',
        'items': [
          {
            'value': 'all',
            'name': 'All'
          },
          {
            'value': 'frontend',
            'name': 'Frontend'
          },
          {
            'value': 'backend',
            'name': 'Backend'
          },
        ],
      },
    },
    'metrics': {
      'defaultKey': 'aovc',
      'items': [
        {
          ...METRIC_ITEMS_MAP['aovc'],
          'secondary': {
            ...METRIC_ITEMS_MAP['orders'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['aogpc'],
          'secondary': {
            ...METRIC_ITEMS_MAP['orders'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['costc'],
          'secondary': {
            ...METRIC_ITEMS_MAP['orders'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['order_rate'],
        },
        {
          ...METRIC_ITEMS_MAP['revenue_orders'],
          'secondary': {
            ...METRIC_ITEMS_MAP['orders'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['aovc_rate'],
        },
        {
          ...METRIC_ITEMS_MAP['aogpc_rate'],
        },
        {
          ...METRIC_ITEMS_MAP['costc_rate'],
        },
        {
          ...METRIC_ITEMS_MAP['cost_orders'],
          'secondary': {
            ...METRIC_ITEMS_MAP['orders'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['gross_profit_orders'],
          'secondary': {
            ...METRIC_ITEMS_MAP['orders'],
          },
        },
      ],
    },
    'milestoneDays': [],
    'chart': {
      'allowSecondaryChart': true,
      'allowLineDots': true,
      'allowUseStack100': true,
      'cumulativeDefault': false,
      'hideYValuesAfterToday': false,
    },
    'table': {
      'defaultTab': 'summary',
      'summary': {
        'show': true,
        'columns': {
          'default': {
            'type': 'metrics',
            'name': 'Overview',
            'description': 'Contributions overview',
            'orderBy': 'orders',
            'order': 'desc',
            'items': [
              {
                ...METRIC_ITEMS_MAP['orders'],
              },
              {
                ...METRIC_ITEMS_MAP['order_rate'],
              },
              {
                ...METRIC_ITEMS_MAP['aovc'],
              },
              {
                ...METRIC_ITEMS_MAP['aogpc'],
              },
              {
                ...METRIC_ITEMS_MAP['costc'],
              },
              {
                ...METRIC_ITEMS_MAP['revenue_orders'],
              },
              {
                ...METRIC_ITEMS_MAP['gross_profit_orders'],
              },
              {
                ...METRIC_ITEMS_MAP['cost_orders'],
              },
            ],
          },
          'rates': {
            'type': 'metrics',
            'name': 'Percentages',
            'description': 'Percantage contributions to each key metric',
            'orderBy': 'orders',
            'order': 'desc',
            'items': [
              {
                ...METRIC_ITEMS_MAP['orders'],
              },
              {
                ...METRIC_ITEMS_MAP['order_rate'],
              },
              {
                ...METRIC_ITEMS_MAP['aovc_rate'],
              },
              {
                ...METRIC_ITEMS_MAP['aogpc_rate'],
              },
              {
                ...METRIC_ITEMS_MAP['costc_rate'],
              },
              {
                ...METRIC_ITEMS_MAP['revenue_orders'],
              },
              {
                ...METRIC_ITEMS_MAP['gross_profit_orders'],
              },
              {
                ...METRIC_ITEMS_MAP['cost_orders'],
              },
            ],
          }
        }
      },
      'chart': {
        'show': true,
      },
      'milestones': {
        'show': false,
        'days': [],
      }
    },
  },

  {
    'kind': 'core',
    'key': 'customers',
    'name': 'Customers',
    'description': 'Customer counts, broken into new and returning customers. "Rates" refer to the number of new or returning customers divided by the total number of customers. Note that it is possible for "New Customer Rate" + "Returning Customer Rate" to be greater than 100% because an invidiual customer can be both new and returning if they make multiple purchases in a period.',
    'xKey': 'date_time',
    'xKeyAsRange': false,
    'allowSegmentQuickAdd': true,
    'allowCohorts': false,
    'splitSegmentFrontBack': false,
    'emptyDataRule': 'zero',
    'dateRangeType': 'dynamic',
    'dateRangeDynamicDays': 90,
    'dateRangeFixedStartDate': null,
    'dateRangeFixedEndDate': null,
    'resolution': 1,
    'segments': [],
    'config': {
      'includeCommission': false,
    },
    'configItems': {
      'includeCommission': {
        'show': true,
        'name': 'Affiliate Commission',
        'items': [
          {
            'value': false,
            'name': 'Subtract from revenue'
          },
          {
            'value': true,
            'name': 'Add to revenue'
          },
        ],
      },
    },
    'metrics': {
      'defaultKey': 'customers',
      'items': [
        {
          ...METRIC_ITEMS_MAP['customers'],
          name: 'Total Customers',
        },
        {
          ...METRIC_ITEMS_MAP['customers_frontend'],
          'secondary': {
            ...METRIC_ITEMS_MAP['customers_backend'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['customers_backend'],
          'secondary': {
            ...METRIC_ITEMS_MAP['customers_frontend'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['customers_frontend_rate'],
          'secondary': {
            ...METRIC_ITEMS_MAP['customers_frontend'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['customers_backend_rate'],
          'secondary': {
            ...METRIC_ITEMS_MAP['customers_backend'],
          },
        },
      ],
    },
    'milestoneDays': [],
    'chart': {
      'allowSecondaryChart': true,
      'allowLineDots': true,
      'allowUseStack100': true,
      'cumulativeDefault': false,
      'hideYValuesAfterToday': false,
    },
    'table': {
      'defaultTab': 'summary',
      'summary': {
        'show': true,
        'columns': {
          'default': {
            'type': 'metrics',
            'name': 'Overview',
            'description': 'Overview of Key Metrics',
            'orderBy': 'customers',
            'order': 'desc',
            'items': [
              {
                ...METRIC_ITEMS_MAP['customers'],
                name: 'Total Customers',
              },
              {
                ...METRIC_ITEMS_MAP['customers_frontend'],
              },
              {
                ...METRIC_ITEMS_MAP['customers_backend'],
              },
              {
                ...METRIC_ITEMS_MAP['customers_frontend_rate'],
              },
              {
                ...METRIC_ITEMS_MAP['customers_backend_rate'],
              },
            ]
          }
        },
      },
      'chart': {
        'show': true,
      },
      'milestones': {
        'show': false,
        'days': [],
      }
    },
  },

  {
    'kind': 'core',
    'key': 'refunds',
    'name': 'Refund Rate',
    'description': 'Refund metrics over time. Note that "Refund Rate" is Refunds divided by Gross Revenue, meaning that it is based on retained revenue as opposed to counting the number of refunds. This is a more reliable metric as there is often disagreement about how to count the number of refunds. Ultimately, the dollars are what matters. Note that the refund rate is not cohort-based, which means that the refund rate is calculated based on the total revenue and total refunds for the period, and does not taken into account when the original sale happened before it was refunded.',
    'xKey': 'date_time',
    'xKeyAsRange': false,
    'allowSegmentQuickAdd': true,
    'allowCohorts': false,
    'splitSegmentFrontBack': false,
    'emptyDataRule': 'zero',
    'dateRangeType': 'dynamic',
    'dateRangeDynamicDays': 90,
    'dateRangeFixedStartDate': null,
    'dateRangeFixedEndDate': null,
    'resolution': 1,
    'segments': [],
    'config': {
      'includeCosts': true,
      'includeCommission': false,
    },
    'configItems': {
      'includeCosts': {
        'show': false,
        'name': 'Include Costs',
        'items': [
          {
            'value': true,
            'name': 'Yes'
          },
          {
            'value': false,
            'name': 'No'
          },
        ],
      },
      'includeCommission': {
        'show': true,
        'name': 'Affiliate Commission',
        'items': [
          {
            'value': false,
            'name': 'Subtract from revenue'
          },
          {
            'value': true,
            'name': 'Add to revenue'
          },
        ],
      },
    },
    'metrics': {
      'defaultKey': 'revenue_refunds_rate',
      'items': [
        {
          ...METRIC_ITEMS_MAP['revenue_refunds_rate'],
          'secondary': {
            ...METRIC_ITEMS_MAP['refunds'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['revenue_refunds'],
          'secondary': {
            ...METRIC_ITEMS_MAP['refunds'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['revenue_gross'],
          'secondary': {
            ...METRIC_ITEMS_MAP['revenue_refunds'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['revenue_net'],
        },
      ],
    },
    'milestoneDays': [],
    'chart': {
      'allowSecondaryChart': true,
      'allowLineDots': true,
      'allowUseStack100': true,
      'cumulativeDefault': false,
      'hideYValuesAfterToday': false,
    },
    'table': {
      'defaultTab': 'summary',
      'summary': {
        'show': true,
        'columns': {
          'default': {
            'type': 'metrics',
            'name': 'Overview',
            'description': 'Overview of Key Metrics',
            'orderBy': 'refunds',
            'order': 'desc',
            'items': [
              {
                ...METRIC_ITEMS_MAP['refunds'],
              },
              {
                ...METRIC_ITEMS_MAP['revenue_refunds_rate'],
              },
              {
                ...METRIC_ITEMS_MAP['revenue_refunds'],
              },
              {
                ...METRIC_ITEMS_MAP['revenue_gross'],
              },
              {
                ...METRIC_ITEMS_MAP['revenue_net'],
              },
            ],
          },
        },
      },
      'chart': {
        'show': true,
      },
      'milestones': {
        'show': false,
        'days': [],
      },
    },
  },

  {
    'kind': 'core',
    'key': 'ltv',
    'name': 'Lifetime Value',
    'description': 'Lifetime value day by day',
    'xKey': 'day',
    'xKeyAsRange': false,
    'allowSegmentQuickAdd': true,
    'allowCohorts': true,
    'splitSegmentFrontBack': false,
    'emptyDataRule': 'previous',
    'dateRangeType': 'dynamic',
    'dateRangeDynamicDays': 90,
    'dateRangeFixedStartDate': null,
    'dateRangeFixedEndDate': null,
    'resolution': 2,
    'segments': [],
    'config': {
      'attribution': 'customer',
      'frontendEnforcement': 'absolute',
      'includeCosts': true,
      'includeCommission': false,
      'extendBackend': true,
    },
    'configItems': {
      'attribution': {
        'show': true,
        'name': 'Attribution',
        'items': [
          {
            'value': 'customer',
            'name': 'Customer'
          },
          {
            'value': 'lead',
            'name': 'Lead'
          },
        ],
      },
      'frontendEnforcement': {
        'show': false,
        'name': 'Analyze LTV for',
        'items': [
          {
            'value': 'absolute',
            'name': 'New customers'
          },
          {
            'value': 'relative',
            'name': 'All customers'
          },
        ],
      },
      'includeCosts': {
        'show': false,
        'name': 'Include Costs',
        'items': [
          {
            'value': true,
            'name': 'Yes'
          },
          {
            'value': false,
            'name': 'No'
          },
        ],
      },
      'includeCommission': {
        'show': true,
        'name': 'Affiliate Commission',
        'items': [
          {
            'value': false,
            'name': 'Subtract from revenue'
          },
          {
            'value': true,
            'name': 'Add to revenue'
          },
        ],
      },
      'extendBackend': {
        'show': false,
        'name': 'Extend Backend',
        'items': [
          {
            'value': true,
            'name': 'To today'
          },
          {
            'value': false,
            'name': 'To end date'
          },
        ],
      }
    },
    'metrics': {
      'defaultKey': 'ltv',
      'items': [
        {
          ...METRIC_ITEMS_MAP['ltv'],
          'secondary': {
            ...METRIC_ITEMS_MAP['confidence'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['ltgpp'],
          'secondary': {
            ...METRIC_ITEMS_MAP['confidence'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['ltr'],
        },
        {
          ...METRIC_ITEMS_MAP['ltgp'],
        },
        {
          ...METRIC_ITEMS_MAP['count'],
          'secondary': {
            ...METRIC_ITEMS_MAP['confidence'],
          },
        },
      ],
    },
    'milestoneDays': [0, 7, 14, 30, 60, 90, 120, 150, 180, 270, 365],
    'chart': {
      'allowSecondaryChart': true,
      'allowLineDots': true,
      'allowUseStack100': true,
      'cumulativeDefault': false,
      'hideYValuesAfterToday': false,
    },
    'table': {
      'defaultTab': 'summary',
      'summary': {
        'show': true,
        'columns': {
          'default': {
            'type': 'milestones',
            'name': 'LTV Overview',
            'description': 'Overview of LTV Metrics',
            'orderBy': 'count-0',
            'order': 'desc',
            'items': [
              {
                'metricKey': 'count',
                'name': 'People',
                'concatDay': false,
                'milestoneDays': [0],
              },
              {
                'metricKey': 'ltv',
                'name': 'LTV',
                'concatDay': true,
                'milestoneDays': [0, 30, 60, 90],
              },
              {
                'metricKey': 'ltv',
                'name': 'LTV',
                'concatDay': true,
                'milestoneDays': ['max'],
              },
              {
                'metricKey': 'ltgpp',
                'name': 'LTGPP',
                'concatDay': true,
                'milestoneDays': ['max'],
              },
              {
                'metricKey': 'ltr',
                'name': 'LTR',
                'concatDay': false,
                'milestoneDays': ['max'],
              },
              {
                'metricKey': 'ltgp',
                'name': 'LTGP',
                'concatDay': false,
                'milestoneDays': ['max'],
              },
            ]
          },
          'ltgpp': {
            'type': 'milestones',
            'name': 'LTGPC Overview',
            'description': 'Overview of LTGPC Metrics',
            'orderBy': 'count-0',
            'order': 'desc',
            'items': [
              {
                'metricKey': 'count',
                'name': 'Customers',
                'concatDay': false,
                'milestoneDays': [0],
              },
              {
                'metricKey': 'ltgpp',
                'name': 'LTGPP',
                'concatDay': true,
                'milestoneDays': [0, 30, 60, 90],
              },
              {
                'metricKey': 'ltgpp',
                'name': 'LTGPP',
                'concatDay': true,
                'milestoneDays': ['max'],
              },
              {
                'metricKey': 'ltgp',
                'name': 'LTGP',
                'concatDay': false,
                'milestoneDays': ['max'],
              },
              {
                'metricKey': 'ltr',
                'name': 'LTR',
                'concatDay': false,
                'milestoneDays': ['max'],
              },
            ]
          }
        }
      },
      'chart': {
        'show': true,
      },
      'milestones': {
        'show': true,
      }
    },
  },

  {
    'kind': 'core',
    'key': 'returning_customer_behavior',
    'name': 'Returning Customer Behavior',
    'description': 'In-depth analysis of returning customer behavior. You can analyze different segments or apply a frontend breakdown to compare returning customer behavior across different groups of new customers. Or you can apply a backend breakdown to see what returning customers are buying. A common pattern would be to pick a frontend customer segment and apply a backend breakdown by product to see what they buy and when after their initial purchase.',
    'xKey': 'day',
    'xKeyAsRange': true,
    'allowSegmentQuickAdd': true,
    'allowCohorts': true,
    'splitSegmentFrontBack': true,
    'emptyDataRule': 'zero',
    'dateRangeType': 'dynamic',
    'dateRangeDynamicDays': 90,
    'dateRangeFixedStartDate': null,
    'dateRangeFixedEndDate': null,
    'resolution': 2,
    'segments': [],
    'config': {
      'attribution': 'customer',
      'frontendEnforcement': 'absolute',
      'includeCosts': true,
      'includeCommission': false,
      'extendBackend': true,
    },
    'configItems': {
      'attribution': {
        'override': true,
        'show': false,
        'name': 'Attribution',
        'items': [
          {
            'value': 'customer',
            'name': 'Customer'
          },
        ],
      },
      'frontendEnforcement': {
        'show': false,
        'name': 'Analyze LTV for',
        'items': [
          {
            'value': 'absolute',
            'name': 'New customers'
          },
          {
            'value': 'relative',
            'name': 'All customers'
          },
        ],
      },
      'includeCosts': {
        'show': false,
        'name': 'Include Costs',
        'items': [
          {
            'value': true,
            'name': 'Yes'
          },
          {
            'value': false,
            'name': 'No'
          },
        ],
      },
      'includeCommission': {
        'show': true,
        'name': 'Affiliate Commission',
        'items': [
          {
            'value': false,
            'name': 'Subtract from revenue'
          },
          {
            'value': true,
            'name': 'Add to revenue'
          },
        ],
      },
      'extendBackend': {
        'show': false,
        'name': 'Extend Backend',
        'items': [
          {
            'value': true,
            'name': 'To today'
          },
          {
            'value': false,
            'name': 'To end date'
          },
        ],
      }
    },
    'metrics': {
      'defaultKey': 'customers_backend_rate_j',
      'items': [
        {
          ...METRIC_ITEMS_MAP['customers_backend_rate_j'],
          'secondary': {
            ...METRIC_ITEMS_MAP['customers'],
            'name': 'Returning Customers',
            'cumulativeName': 'Returning Customers, non-unique',
            'allowCumulative': true,
          },
        },
        {
          ...METRIC_ITEMS_MAP['revenue_backend_rate_j'],
          'secondary': {
            ...METRIC_ITEMS_MAP['revenue_net'],
            'name': 'Net Backend Revenue',
          },
        },
        {
          ...METRIC_ITEMS_MAP['customers'],
          'name': 'Returning Customers',
          'cumulativeName': 'Returning Customers, non-unique',
          'allowCumulative': true,
        },
        {
          ...METRIC_ITEMS_MAP['revenue_net'],
        },
        {
          ...METRIC_ITEMS_MAP['revenue_gross'],
        },
        {
          ...METRIC_ITEMS_MAP['revenue_orders'],
          'secondary': {
            ...METRIC_ITEMS_MAP['orders'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['revenue_rebills'],
          'secondary': {
            ...METRIC_ITEMS_MAP['rebills'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['revenue_refunds'],
          'secondary': {
            ...METRIC_ITEMS_MAP['refunds'],
          },
        },
      ]
    },
    'milestoneDays': [],
    'chart': {
      'allowSecondaryChart': true,
      'allowLineDots': true,
      'allowUseStack100': true,
      'cumulativeDefault': true,
      'hideYValuesAfterToday': false,
    },
    'table': {
      'defaultTab': 'summary',
      'summary': {
        'show': true,
        'columns': {
          'default': {
            'type': 'metrics',
            'name': 'Overview',
            'description': 'Overview',
            'orderBy': 'customers_backend_rate_j',
            'order': 'desc',
            'items': [
              {
                ...METRIC_ITEMS_MAP['customers_backend_rate_j'],
              },
              {
                ...METRIC_ITEMS_MAP['count'],
                'name': 'New Customers',
              },
              {
                ...METRIC_ITEMS_MAP['customers'],
                'name': 'Returning Customers',
              },
              {
                ...METRIC_ITEMS_MAP['revenue_backend_rate_j'],
              },
              {
                ...METRIC_ITEMS_MAP['revenue_net_frontend'],
                'name': 'Frontend Revenue',
              },
              {
                ...METRIC_ITEMS_MAP['revenue_net'],
                'name': 'Net Backend Revenue',
              },
              {
                ...METRIC_ITEMS_MAP['revenue_gross'],
              },
              {
                ...METRIC_ITEMS_MAP['revenue_orders'],
              },
              {
                ...METRIC_ITEMS_MAP['revenue_rebills'],
              },
              {
                ...METRIC_ITEMS_MAP['revenue_refunds'],
              },
              {
                ...METRIC_ITEMS_MAP['orders'],
              },
              {
                ...METRIC_ITEMS_MAP['rebills'],
              },
              {
                ...METRIC_ITEMS_MAP['refunds'],
              },
            ],
          },
        }
      },
      'chart': {
        'show': true,
      },
      'milestones': {
        'show': false,
        'days': [],
      }
    },
  },

  {
    'kind': 'core',
    'key': 'returning_lead_behavior',
    'name': 'Returning Lead Behavior',
    'description': 'In-depth analysis of returning lead behavior. You can analyze different segments or apply a frontend breakdown to compare returning lead behavior across different groups of new leads. Or you can apply a backend breakdown to see what returning leads are buying. A common pattern would be to pick a frontend segment of leads and apply a backend breakdown by product to see what they buy and when after becoming a lead.',
    'xKey': 'day',
    'xKeyAsRange': true,
    'allowSegmentQuickAdd': true,
    'allowCohorts': true,
    'splitSegmentFrontBack': true,
    'emptyDataRule': 'zero',
    'dateRangeType': 'dynamic',
    'dateRangeDynamicDays': 90,
    'dateRangeFixedStartDate': null,
    'dateRangeFixedEndDate': null,
    'resolution': 2,
    'segments': [],
    'config': {
      'attribution': 'lead',
      'includeCosts': true,
      'includeCommission': false,
      'extendBackend': true,
    },
    'configItems': {
      'attribution': {
        'override': true,
        'show': false,
        'name': 'Attribution',
        'items': [
          {
            'value': 'lead',
            'name': 'Lead'
          },
        ],
      },
      'includeCosts': {
        'show': false,
        'name': 'Include Costs',
        'items': [
          {
            'value': true,
            'name': 'Yes'
          },
          {
            'value': false,
            'name': 'No'
          },
        ],
      },
      'includeCommission': {
        'show': true,
        'name': 'Affiliate Commission',
        'items': [
          {
            'value': false,
            'name': 'Subtract from revenue'
          },
          {
            'value': true,
            'name': 'Add to revenue'
          },
        ],
      },
      'extendBackend': {
        'show': false,
        'name': 'Extend Backend',
        'items': [
          {
            'value': true,
            'name': 'To today'
          },
          {
            'value': false,
            'name': 'To end date'
          },
        ],
      },
    },
    'metrics': {
      'defaultKey': 'customers_backend_rate_j',
      'items': [
        {
          ...METRIC_ITEMS_MAP['customers_backend_rate_j'],
          'name': 'Lead to Customer Rate (Journey)',
          'cumulativeName': 'Lead to Customer Rate (Journey), non-unique',
          'secondary': {
            ...METRIC_ITEMS_MAP['customers'],
            'name': 'Customers',
            'cumulativeName': 'Customers, non-unique',
            'allowCumulative': true,
          },
        },
        {
          ...METRIC_ITEMS_MAP['customers'],
          'name': 'Customers',
          'cumulativeName': 'Customers, non-unique',
          'allowCumulative': true,
        },
        {
          ...METRIC_ITEMS_MAP['revenue_net'],
        },
        {
          ...METRIC_ITEMS_MAP['revenue_gross'],
        },
        {
          ...METRIC_ITEMS_MAP['revenue_orders'],
          'secondary': {
            ...METRIC_ITEMS_MAP['orders'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['revenue_rebills'],
          'secondary': {
            ...METRIC_ITEMS_MAP['rebills'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['revenue_refunds'],
          'secondary': {
            ...METRIC_ITEMS_MAP['refunds'],
          },
        },
      ]
    },
    'milestoneDays': [],
    'chart': {
      'allowSecondaryChart': true,
      'allowLineDots': true,
      'allowUseStack100': true,
      'cumulativeDefault': true,
      'hideYValuesAfterToday': false,
    },
    'table': {
      'defaultTab': 'summary',
      'summary': {
        'show': true,
        'columns': {
          'default': {
            'type': 'metrics',
            'name': 'Overview',
            'description': 'Overview',
            'orderBy': 'customers_backend_rate_j',
            'order': 'desc',
            'items': [
              {
                ...METRIC_ITEMS_MAP['customers_backend_rate_j'],
                'name': 'Lead to Customer Rate (Journey)',
              },
              {
                ...METRIC_ITEMS_MAP['count'],
                'name': 'New Leads',
              },
              {
                ...METRIC_ITEMS_MAP['customers'],
                'name': 'Customers',
              },
              {
                ...METRIC_ITEMS_MAP['revenue_net'],
              },
              {
                ...METRIC_ITEMS_MAP['revenue_gross'],
              },
              {
                ...METRIC_ITEMS_MAP['revenue_orders'],
              },
              {
                ...METRIC_ITEMS_MAP['revenue_rebills'],
              },
              {
                ...METRIC_ITEMS_MAP['revenue_refunds'],
              },
              {
                ...METRIC_ITEMS_MAP['orders'],
              },
              {
                ...METRIC_ITEMS_MAP['rebills'],
              },
              {
                ...METRIC_ITEMS_MAP['refunds'],
              },
            ],
          },
        }
      },
      'chart': {
        'show': true,
      },
      'milestones': {
        'show': false,
        'days': [],
      }
    },
  },
]
