import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import moment from 'moment-timezone'

const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const dateRangeTypeItems = [
  {
    id: 'dynamic',
    name: 'Dynamic',
  },
  {
    id: 'fixed',
    name: 'Fixed',
  },
]

const EMPTY_REPORT_DATA = {
  name: '',
  description: '',
  dateRangeType: 'dynamic',
  dateRangeDynamicDays: 90,
  dateRangeFixedStartDate: null,
  dateRangeFixedEndDate: null,
}

const ReportDialog = ({
  open,
  onClose,
  editId,
  report,
  startDate,
  endDate,
  onReportSave,
}) => {
  const [reportData, setReportData] = useState(() => {
    if (editId) {
      return {
        ...EMPTY_REPORT_DATA,
        name: report.name,
        description: report.description,
        dateRangeType: report.dateRangeType,
        dateRangeDynamicDays: report.dateRangeDynamicDays,
        dateRangeFixedStartDate: report.dateRangeFixedStartDate,
        dateRangeFixedEndDate: report.dateRangeFixedEndDate,
      }
    } else {
      return {
        ...EMPTY_REPORT_DATA,
        dateRangeDynamicDays: startDate && endDate ? moment(endDate).diff(startDate, 'days') + 1 : 90,
        dateRangeFixedStartDate: startDate.format('YYYY-MM-DD'),
        dateRangeFixedEndDate: endDate.format('YYYY-MM-DD'),
      }
    }
  })

  const handleChange = (event) => {
    setReportData({ ...reportData, [event.target.name]: event.target.value })
  }

  const handleDateChange = (date, name) => {
    setReportData({ ...reportData, [name]: date.format('YYYY-MM-DD') })
  }

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') return
    onClose()
  }

  const handleSubmit = (editId=null, saveAs=false) => {
    if (editId) {
      // Update the report or save as a new report
      onReportSave(saveAs ? null : editId, reportData)
    } else {
      // Create a new report
      onReportSave(null, reportData)
    }
    onClose()
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='xs' fullWidth>
      <DialogTitle>{editId ? 'Update Report' : 'Save New Report'}</DialogTitle>
      <DialogContent>
        <Box display='flex' flexDirection='column' gap={2} paddingTop={1}>
          <TextField
            name='name'
            label='Name'
            value={reportData.name}
            onChange={handleChange}
            size='small'
            fullWidth
            required
          />
          <TextField
            name='description'
            label='Description'
            value={reportData.description}
            onChange={handleChange}
            size='small'
            fullWidth
          />
          <FormControl size='small' fullWidth required>
            <InputLabel id='dateRangeTypeLabel'>Date Range Type</InputLabel>
            <Select
              name='dateRangeType'
              label='Date Range Type'
              value={reportData.dateRangeType}
              onChange={handleChange}
              fullWidth
            >
              {dateRangeTypeItems.map(item => (
                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {reportData.dateRangeType === 'dynamic' && (
            <TextField
              name='dateRangeDynamicDays'
              label='Last X Days'
              value={reportData.dateRangeDynamicDays}
              onChange={handleChange}
              size='small'
              fullWidth
            />
          )}
          {reportData.dateRangeType === 'fixed' && (
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label='Start Date'
                value={moment(reportData.dateRangeFixedStartDate)}
                timezone={TIMEZONE}
                onChange={(date) => handleDateChange(date, 'dateRangeFixedStartDate')}
                slotProps={{
                  textField: {
                    size: 'small',
                    fullWidth: true,
                  }
                }}
              />
              <DatePicker
                label='End Date'
                value={moment(reportData.dateRangeFixedEndDate)}
                timezone={TIMEZONE}
                onChange={(date) => handleDateChange(date, 'dateRangeFixedEndDate')}
                slotProps={{
                  textField: {
                    size: 'small',
                    fullWidth: true,
                  }
                }}
              />
            </LocalizationProvider>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        {editId && (
          <Button
            onClick={() => handleSubmit(editId, true)}
            color='primary'
            disabled={!reportData.name}
          >
            Save New
          </Button>
        )}

        <div style={{ flexGrow: 1 }} />

        <Button
          onClick={onClose}
          color='error'
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleSubmit(editId)}
          color='secondary'
          disabled={!reportData.name}
        >
          {editId ? 'Update' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ReportDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  editId: PropTypes.string,
  report: PropTypes.object.isRequired,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onReportSave: PropTypes.func.isRequired,
}

export default ReportDialog