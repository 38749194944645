import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts'

import moment from 'moment-timezone'

import { BLUE_ACCENT_COLOR } from '../../../constants'
import { formatNumber } from '../../../utils/helpers'

import ChangeBadge from '../ChangeBadge'

const PRIMARY_DATA_NAME = 'primary'
const COMPARE_DATA_NAME = 'compare'
const TIMEZONE = moment.tz.guess()

const CardGraph = (props) => {
  const { data, isDollar, resolution } = props
  const theme = useTheme()

  return (
    <Box>
      <ResponsiveContainer width='100%' height={100}>
        <LineChart width={300} height='100%' data={data}>
          <XAxis
            type='category'
            dataKey='x'
            domain={['min', 'max']}
            tickLine={false}
            minTickGap={0}
            interval={0}
            tick={<CustomizedAxisTick data={data} resolution={resolution} />}
          />
          <YAxis
            type='number'
            domain={['auto', 'auto']}
            tickFormatter={val => val}
            hide
          />
          <Tooltip
            isAnimationActive={false}
            content={<CustomTooltip resolution={resolution} isDollar={isDollar} />}
          />
          <Line type='monotone' dataKey={PRIMARY_DATA_NAME} stroke={BLUE_ACCENT_COLOR} strokeWidth={2} dot={false} />
          <Line type='monotone' dataKey={COMPARE_DATA_NAME} stroke={theme.palette.text.secondary} strokeWidth={1} dot={false} />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  )
}

const CustomizedAxisTick = (props) => {
  const { x, y, payload, data, resolution } = props

  let format = 'MM/DD/YY'
  if (resolution === 'hour') {
    format += ', hh:mm A'
  }

  let startTickX = 56
  if (format.length === 17) {
    startTickX = 116
  }

  if (payload.value === data[0].x) {
    return (
      <g transform={`translate(${x},${y})`}>
        <text style={{ fontSize: 13 }} x={startTickX} y={10} textAnchor='end' fill='#666' >{moment.tz(payload.value, TIMEZONE).format(format)}</text>
      </g>
    )
  } else if (payload.value === data[data.length - 1].x) {
    return (
      <g transform={`translate(${x},${y})`}>
        <text style={{ fontSize: 13 }} x={0} y={10} textAnchor='end' fill='#666' >{moment.tz(payload.value, TIMEZONE).format(format)}</text>
      </g>
    )
  } else {
    return null
  }

}

const CustomTooltip = (props) => {
  const { active, payload, label, resolution, isDollar } = props

  if (payload === null) return null

  payload.sort((a, b) => {
    return parseFloat(b.value) - parseFloat(a.value)
  })

  let format = 'MMM D, YYYY'
  if (resolution === 'hour') {
    format += ', hh:mm A'
  }

  if (active) {
    const valuePayload = payload.find(el => el.name === PRIMARY_DATA_NAME)
    const comparePayload = payload.find(el => el.name === COMPARE_DATA_NAME)
    return (
      <Box bgcolor='white' textAlign='center' border='1px solid rgb(204, 204, 204)' borderRadius="4px" maxWidth={300}>
        {valuePayload && (
          <React.Fragment>
            <Box padding={1}>
              <Typography variant='body2'>
                {moment.tz(label, TIMEZONE).format(format)}
              </Typography>
              <Typography variant='body2' style={{ color: valuePayload.color, fontWeight: 'bold' }}>
                {isDollar && '$'}{formatNumber(valuePayload.value, isDollar ? 2 : 0)}
              </Typography>
              <Box display='flex' justifyContent='center'>
                <Box maxWidth={100}>
                  <ChangeBadge
                    metricPrimary={valuePayload.value}
                    metricSecondary={comparePayload.value}
                  />
                </Box>
              </Box>
            </Box>
            <Divider />
          </React.Fragment>
        )}

        {comparePayload && (
          <Box padding={1}>
            <Typography variant='body2'>
              {moment.tz(comparePayload.payload.compareDate, TIMEZONE).format(format)}
            </Typography>
            <Typography variant='body2' style={{ color: comparePayload.color }}>
              {isDollar && '$'}{formatNumber(comparePayload.value, isDollar ? 2 : 0)}
            </Typography>
          </Box>
        )}
      </Box>
    );
  }

  return null
}

CardGraph.propTypes = {
  data: PropTypes.array,
  isDollar: PropTypes.bool,
  resolution: PropTypes.string.isRequired,
}

export default CardGraph