import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Skeleton from '@mui/material/Skeleton'

const LoadingSkeleton = ({
  height,
  width,
  animation,
}) => {
  return (
    <Fragment>
      <Skeleton
        variant='rectangular'
        width={width}
        height={height}
        animation={animation}
      />
    </Fragment>
  );
}

LoadingSkeleton.defaultProps = {
  animation: 'pulse',
}

LoadingSkeleton.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  animation: PropTypes.oneOf(['pulse', 'wave', false]),
}

export default LoadingSkeleton
