import { red } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#1c2566',
    },
    secondary: {
      main: '#2267ea',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fafafa',
    },
  },
  typography: {
    fontFamily: [ 'Lato', 'sans-serif'].join(','),
    fontSize: 14,
    numeric: {
      fontFamily: [ 'Roboto Mono', 'monospace'].join(','),
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#fafafa',
        },
      },
    },
    MuiInputLabel: {
        shrink: {
          backgroundColor: '#fff',
          borderRadius: 8,
          padding: '0px 10px',
          marginLeft: -4,
        }
    },
    MuiTableCell: {
      sizeSmall: {
        padding: '6px 12px 6px 12px'
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
})
console.log('theme:', theme)

export default theme