import React from 'react'
import PropTypes from 'prop-types'
import Collapse from '@mui/material/Collapse'
import Paper from '@mui/material/Paper'
import makeStyles from '@mui/styles/makeStyles'

import ControlsConfiguration from '../ControlsConfiguration/PathAnalysis'
import ControlsContext from '../ControlsContext'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))

const Controls = (props) => {
  const classes = useStyles()
  const showContext = props.filters.length > 0 || !!props.breakdown
  return (
    <Paper className={classes.root}>
      <ControlsConfiguration
        startDate={props.startDate}
        endDate={props.endDate}
        onDatesChange={props.onDatesChange}
        onBreakdownSelect={props.onBreakdownSelect}
        breakdownLabel={props.breakdownLabel}
        filters={props.filters}
        onFilterAdd={props.onFilterAdd}
        filterLabel={props.filterLabel}
        attribution={props.attribution}
        onAttributionSelect={props.onAttributionSelect}
        frontendType={props.frontendType}
        onBackendTypeSelect={props.onBackendTypeSelect}
      />
      <Collapse in={showContext}>
        <ControlsContext
          breakdown={props.breakdown}
          onBreakdownDelete={props.onBreakdownDelete}
          breakdownLabel={'Backend Breakdown'}
          filters={props.filters}
          onFilterDelete={props.onFilterDelete}
          onFilterDeleteAll={props.onFilterDeleteAll}
        />
      </Collapse>
    </Paper>
  )
}

Controls.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onDatesChange: PropTypes.func.isRequired,
  breakdown: PropTypes.object,
  onBreakdownSelect: PropTypes.func.isRequired,
  onBreakdownDelete: PropTypes.func.isRequired,
  breakdownLabel: PropTypes.string,
  filters: PropTypes.array.isRequired,
  onFilterAdd: PropTypes.func.isRequired,
  onFilterDelete: PropTypes.func.isRequired,
  onFilterDeleteAll: PropTypes.func.isRequired,
  filterLabel: PropTypes.string,
  attribution: PropTypes.string.isRequired,
  onAttributionSelect: PropTypes.func.isRequired,
  frontendType: PropTypes.string.isRequired,
  onBackendTypeSelect: PropTypes.func.isRequired
}

export default Controls
