import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'

import moment from 'moment-timezone'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import '../../css/react_dates_overrides.css'
import { DayPickerSingleDateController } from 'react-dates'

const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
  },
  calendarInfo: {
    padding: theme.spacing(1, 0),
    textAlign: 'center',
  },
  pickerSpacer: {
    height: theme.spacing(2),
    width: '100%',
  },
}))

const CustomSingleDatePicker = (props) => {
  const classes = useStyles()

  const handleDateChange = (newDate) => {
    props.onDateChange(newDate.tz(TIMEZONE).startOf('day'))
  }

  return (
      <div className={classes.root}>
        <Box>
          <Typography className={classes.calendarInfo} >
            {props.date.format('MM/DD/YYYY')}
          </Typography>

          <Divider />

          <Box paddingTop={2}>
            <DayPickerSingleDateController
              key={`${props.date}-start`}
              date={props.date}
              onDateChange={handleDateChange}
              focused={true}
              firstDayOfWeek={1}
              isOutsideRange={dayMoment => props.disableToday ? dayMoment.isSameOrAfter(moment.tz(TIMEZONE), 'day') : dayMoment.isAfter(moment.tz(TIMEZONE), 'day')}
              initialVisibleMonth={() => moment(props.date ? props.date : undefined)}
              transitionDuration={0}
              numberOfMonths={1}
              hideKeyboardShortcutsPanel
              noBorder
            />
          </Box>
        </Box>
      </div>
  )
}

CustomSingleDatePicker.propTypes = {
  date: PropTypes.object,
  onDateChange: PropTypes.func.isRequired,
  disableToday: PropTypes.bool,
}

export default CustomSingleDatePicker
