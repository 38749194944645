import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'

import { FirebaseContext } from '../../utils/firebase'
import 'firebase/auth'

import { useQuery } from 'react-query'

import { formatNumber, stableSort } from '../../utils/helpers'
import { ALL_DATA_KEY, API_ROOT_URL, DASHBOARD_BACKEND_BREAKDOWN_ROWS_LIMIT } from '../../constants/'

const TIMEZONE = moment.tz.guess() // This should be set by user configuration

import LTVMiniGraph from './LTVMiniGraph'

const useStyles = makeStyles(theme => ({
  root: {
  },
  gridAnalysisCardLeft: {
    padding: theme.spacing(2),
    borderRightColor: 'rgb(224,224,224)',
    borderRightWidth: 1,
    borderRightStyle: 'solid'
  },
  gridAnalysisCardRight: {
    padding: theme.spacing(2),
  },
  analysisCardRow: {
    display: 'flex',
    padding: theme.spacing(1, 0)
  },
  analysisCardColumn: {
    flexBasis: '25%',
    textAlign: 'center',
  },
  analysisCardColumnRight: {
    flexBasis: '75%',
    textAlign: 'right',
  },
  accentTypography: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightBold,
  },
  metricValueTypography: {
    lineHeight: 1,
  },
  metricKeyTypography: {
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
  },
  typographySecondaryColor: {
    color: theme.palette.text.secondary,
  }
}))

const AnalysisCard = (props) => {
  const { accentColor, attribution, frontendType, context, extendBackend, filter } = props
  const classes = useStyles()
  const theme = useTheme()
  const firebase = React.useContext(FirebaseContext)

  // Create Analysis Card context by applying a product breakdown on the backend
  // and adding the filter for the element being analyzed
  // (ie. a particular frontend funnel or product)
  const analysisCardContext = {
    dates: context.dates,
    filters: filter ? [...context.filters, filter] : context.filters,  // if filter is provided, add it to the context
    breakdown: { id: 'product_id', name: 'Product' }  // set product breakdown for backend
  }

  const { isLoading, data } = useQuery(['dashboard-analysis-card', attribution, frontendType, analysisCardContext, extendBackend], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/dashboard/analysis_card`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          attribution,
          startDate: analysisCardContext.dates.start,
          endDate: analysisCardContext.dates.end,
          timezone: TIMEZONE,
          breakdown: analysisCardContext.breakdown,
          frontendFilters: analysisCardContext.filters,
          frontendType,
          extendBackend
        })
      }).then(res => res.json())
    }),
    {
      enabled: !!context.dates.start && !!context.dates.end,
      cacheTime: 15 * 60 * 1000,  // 15 minutes
      staleTime: 15 * 60 * 1000,  // 15 minutes
      refetchOnWindowFocus: false,
    }
  )

  const frontendData = data ? (data.frontend[0] ? data.frontend[0] : {}) : {}
  const backendData = data ? (data.backend[0] ? data.backend[0] : {}) : {}
  let tableData = data ? data.table : []
  tableData = stableSort(tableData, 'desc', 'ltv_gain').slice(0, DASHBOARD_BACKEND_BREAKDOWN_ROWS_LIMIT )
  const ltvData = data ? data.ltv : {}
  const ltvDay0 = data ? (ltvData.ltv[0] ? ltvData.ltv[0][ALL_DATA_KEY] || 'N/A' : 'N/A') : 0
  const ltvDay30 = data ? (ltvData.ltv[30] ? ltvData.ltv[30][ALL_DATA_KEY] : 'N/A') : 0
  const ltvDay90 = data ? (ltvData.ltv[90] ? ltvData.ltv[90][ALL_DATA_KEY] : 'N/A') : 0
  const ltr = data ? ltvData.ltr[ltvData.ltr.length - 1][ALL_DATA_KEY] : 0

  return (
    <Paper className={classes.root}>
      {isLoading ? (
        <Skeleton variant="rectangular" width={'100%'} height={136} animation='wave' />
      ) : (
        <Grid container>
          <Grid className={classes.gridAnalysisCardLeft} item xs={8} >
            <div className={classes.analysisCardRow}>
              <div className={classes.analysisCardColumn}>
                <Typography
                  className={classes.accentTypography}
                  style={{ color: accentColor ? accentColor: theme.palette.secondary.main }}
                  variant='h6'
                  align='left'
                >
                  {filter ? filter.valueName : 'All New Customers'}
                </Typography>
              </div>
              <div className={classes.analysisCardColumn}>
                <Typography className={classes.metricValueTypography} variant='body1'>
                  {formatNumber(frontendData.customers, 0)}
                </Typography>
                <Typography className={classes.metricKeyTypography} variant='caption'>
                  frontend customers
                </Typography>
              </div>
              <div className={classes.analysisCardColumn}>
                <Typography className={classes.metricValueTypography} variant='body1'>
                  ${formatNumber(frontendData.revenue, 0)}
                </Typography>
                <Typography className={classes.metricKeyTypography} variant='caption'>
                  frontend revenue
                </Typography>
              </div>
              <div className={classes.analysisCardColumn}>
                <Typography className={classes.metricValueTypography} variant='body1'>
                  ${formatNumber(frontendData.revenue_per_customer, 0)}
                </Typography>
                <Typography className={classes.metricKeyTypography} variant='caption'>
                  revenue per customer
                </Typography>
              </div>
            </div>
            <div className={classes.analysisCardRow}>
              <div className={classes.analysisCardColumn}>
                <Typography variant='h6' align='left'>
                  Backend
                </Typography>
              </div>
              <div className={classes.analysisCardColumn}>
                <Typography className={classes.metricValueTypography} variant='body1'>
                  {formatNumber(backendData.customers, 0)} <span className={classes.typographySecondaryColor}>({formatNumber(100 * backendData.customers / frontendData.customers, 0)}%)</span>
                </Typography>
                <Typography className={classes.metricKeyTypography} variant='caption'>
                  backend customers
                </Typography>
              </div>
              <div className={classes.analysisCardColumn}>
                <Typography className={classes.metricValueTypography} variant='body1'>
                  ${formatNumber(backendData.revenue, 0)} <span className={classes.typographySecondaryColor}>({backendData.revenue >= 0 ? '+' : ''}{formatNumber(100 * backendData.revenue / frontendData.revenue, 0)}%)</span>
                </Typography>
                <Typography className={classes.metricKeyTypography} variant='caption'>
                  backend revenue
                </Typography>
              </div>
              <div className={classes.analysisCardColumn}>
                <Typography className={classes.metricValueTypography} variant='body1'>
                  ${formatNumber(backendData.revenue_per_customer, 0)}
                </Typography>
                <Typography className={classes.metricKeyTypography} variant='caption'>
                  revenue per customer
                </Typography>
              </div>
            </div>

            <TableContainer>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell align='right'><b>Name</b></TableCell>
                    <TableCell align='right'><b>LTV Gain</b></TableCell>
                    <TableCell align='right'><b>Customers</b></TableCell>
                    <TableCell align='right'><b>Revenue</b></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map(row =>
                    <TableRow key={row.breakdown}>
                      <TableCell align='right'>
                        {row.name} &nbsp; [ID:{row.platform_id}]
                        <Typography variant='caption' display='block'>
                          <b>{row.platform}</b><b>&nbsp;&gt;&nbsp;{row.funnel_nickname} [ID:{row.funnel_platform_id}]</b>
                        </Typography>
                      </TableCell>
                      <TableCell align='right'>${formatNumber(row.ltv_gain, 2)}</TableCell>
                      <TableCell align='right'>{formatNumber(row.customers, 0)}</TableCell>
                      <TableCell align='right'>${formatNumber(row.revenue, 0)}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

          </Grid>
          <Grid className={classes.gridAnalysisCardRight} item xs={4}>
            <div className={classes.analysisCardRow}>
              <div className={classes.analysisCardColumn}>
                <Typography
                  className={classes.accentTypography}
                  style={{ color: accentColor ? accentColor : theme.palette.secondary.main }}
                  variant='h6'
                  align='left'
                >
                  LTV
                </Typography>
              </div>
              <div className={classes.analysisCardColumnRight}>
                <Typography className={classes.metricValueTypography} variant='body1'>
                  ${isNaN(ltvDay0) ? ltvDay0 : formatNumber(ltvDay0, 2)} -&gt; ${isNaN(ltvDay30) ? ltvDay30 : formatNumber(ltvDay30, 2)} -&gt; ${isNaN(ltvDay90) ? ltvDay90 : formatNumber(ltvDay90, 2)}
                </Typography>
                <Typography className={classes.metricKeyTypography} variant='caption'>
                  LTV Day 0 -&gt; 30 -&gt; 90
                </Typography>
              </div>
            </div>
            <LTVMiniGraph
              data={ltvData.ltv}
              lineColor={accentColor}
            />
            <div className={classes.analysisCardRow} style={{ justifyContent: 'center' }}>
              <div style={{ flexBasis: '100%', textAlign: 'center' }}>
                <Typography className={classes.metricValueTypography} variant='body1'>
                  ${formatNumber(ltr, 0)}
                </Typography>
                <Typography className={classes.metricKeyTypography} variant='caption'>
                  lifetime revenue
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
}

AnalysisCard.propTypes = {
  accentColor: PropTypes.string,
  attribution: PropTypes.string.isRequired,
  frontendType: PropTypes.string.isRequired,
  context: PropTypes.object.isRequired,
  extendBackend: PropTypes.bool.isRequired,
  filter: PropTypes.object,
}

export default AnalysisCard