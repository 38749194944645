import React, { useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Autocomplete from '@mui/material/Autocomplete'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Popover from '@mui/material/Popover'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import PlaceIcon from '@mui/icons-material/Place'
import SegmentIcon from '@mui/icons-material/Segment'
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled'
import SyncIcon from '@mui/icons-material/Sync'
import { amber, green } from '@mui/material/colors'
import { lighten } from '@mui/material/styles'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import moment from 'moment-timezone'

import { FirebaseContext } from '../../utils/firebase'
import { UserContext } from '../../contexts/UserContext'

import { deleteDocument, updateDocument } from '../../utils/firestore'
import { getCurrencySymbol, getDisplayValueByFormat } from '../../utils/helpers'
import { FIRESTORE_COLLECTIONS } from '../../constants'

import Config from './Config'
import DateRangePicker from './DateRangePicker'
import METRIC_ITEMS_MAP from './MetricItems'
import TargetDialog from './TargetDialog'
import SegmentBuilder from './SegmentBuilder'
import DarkTooltip from './DarkTooltip'

const TIMEZONE = moment.tz.guess() // This should be set by user configuration

let debounceTimer = null
const SEARCH_DELAY_MS = 1000

const Controls = ({
  parentKey,
  target,
  savedSegments,
  savedGroups,
  liveMode,
  startDate,
  endDate,
  resolution,
  config,
  targetValue,
  projectedValue,
  segments,
  segmentsEligibleBreakdownKeys,
  aliases,
  onTargetSave,
  onTargetDelete,
  onLiveModeChange,
  onDatesChange,
  onConfigChange,
  onTargetValueChange,
  onSegmentDelete,
  onSegmentDuplicate,
  onSegmentEditChange,
  onSegmentSaveEdit,
  onSegmentRevertEdit,
  onSegmentColorChange,
  onSegmentFilterAdd,
  onSegmentReplaceFiltersWithSavedSegmentFilter,
  onSegmentFilterDelete,
  onSegmentsReorder,
  onFilterAdd,
  onFilterUpdate,
  onFilterDelete,
  onClearAllSegmentFilters,
  onSegmentBreakdownSelect,
  onSegmentBreakdownColorChange,
}) => {
  const firebase = useContext(FirebaseContext)
  const userDoc = useContext(UserContext)
  const [helpAnchorEl, setHelpAnchorEl] = useState(null)

  const [targetMenuAnchorEl, setTargetMenuAnchorEl] = useState(null)
  const [targetDeleteDialogOpen, setTargetDeleteDialogOpen] = useState(false)
  const [targetSaveDialogOpen, setTargetSaveDialogOpen] = useState(false)

  const [segmentsMenuOpen, setSegmentsMenuOpen] = useState(false)
  const [segmentName, setSegmentName] = useState('')
  const [segmentEditId, setSegmentEditId] = useState(null)
  const [segmentDeleteId, setSegmentDeleteId] = useState(null)
  const [segmentEditDialogOpen, setSegmentEditDialogOpen] = useState(false)
  const [segmentDeleteDialogOpen, setSegmentDeleteDialogOpen] = useState(false)

  const [inputValue, setInputValue] = useState(targetValue || '')

  const segmentAnchorRef = React.useRef(null)
  const segmentInputRef = React.useRef()

  const handleHelpPopoverOpen = (event) => {
    setHelpAnchorEl(event.currentTarget)
  }

  const handleTargetMenuOpen = (event) => {
    setTargetMenuAnchorEl(event.currentTarget)
  }

  const handleTargetMenuClose = () => {
    setTargetMenuAnchorEl(null)
  }

  const handleTargetDeleteDialogOpen = () => {
    setTargetMenuAnchorEl(null)
    setTargetDeleteDialogOpen(true)
  }

  const handleTargetDeleteDialogClose = () => {
    setTargetDeleteDialogOpen(false)
  }

  const handleTargetDelete = (targetId) => {
    onTargetDelete(targetId)
    setTargetDeleteDialogOpen(false)
  }

  const handleHelpPopoverClose = () => {
    setHelpAnchorEl(null)
  }

  const handleSegmentsSelectOpen = () => {
    setSegmentsMenuOpen(true)
  }

  const handleSegmentsSelectClose = () => {
    setSegmentsMenuOpen(false)
  }

  const handleSelectSegmentValue = (newSegmentValue) => {
    if (newSegmentValue === null) return
    // Replace the first (and only) segment with the selected saved segment
    const segmentToReplace = segments[0]
    onSegmentReplaceFiltersWithSavedSegmentFilter(segmentToReplace.id, newSegmentValue)
    handleSegmentsSelectClose()
  }

  const handleSegmentEditDialogOpen = (e, id) => {
    e.stopPropagation()
    setSegmentName(savedSegments.find(g => g.id === id).name)
    setSegmentEditId(id)
    setSegmentEditDialogOpen(true)
  }

  const handleSegmentEditDialogClose = () => {
    setSegmentName('')
    setSegmentEditDialogOpen(false)
  }

  const handleSegmentDeleteDialogOpen = (e, id) => {
    e.stopPropagation()
    setSegmentDeleteId(id)
    setSegmentDeleteDialogOpen(true)
  }

  const handleSegmentDeleteDialogClose = () => {
    setSegmentDeleteId('')
    setSegmentDeleteDialogOpen(false)
  }

  const handleSegmentEdit = async (id) => {
    await updateDocument(firebase, FIRESTORE_COLLECTIONS.SEGMENTS, id, { name: segmentName })
    setSegmentEditId(null)
  }

  const handleSegmentDelete = async (id) => {
    setSegmentDeleteId(null)
    setSegmentDeleteDialogOpen(false)

    // Delete the document from Firestore
    await deleteDocument(firebase, FIRESTORE_COLLECTIONS.SEGMENTS, id)
  }

  const handleSegmentsDragReorder = (result) => {
    if (!result.destination) {
      return
    }

    let newSegments = Array.from(segments)
    const [removed] = newSegments.splice(result.source.index, 1)
    newSegments.splice(result.destination.index, 0, removed)

    onSegmentsReorder(newSegments)
  }

  const liveModeAvailable = useMemo(() => {
    // Live mode is only available when today is in the date range
    return moment.tz(TIMEZONE).isBetween(startDate, endDate, 'day', '[]')
  })

  // Update target value after a delay to prevent too many updates
  useEffect(() => {
    clearTimeout(debounceTimer)
    debounceTimer = setTimeout(() => {
      onTargetValueChange(inputValue)
    }, SEARCH_DELAY_MS)
  }, [inputValue])

  // When the parentKey changes, reset the input value
  useEffect(() => {
    setInputValue(targetValue || '')
  }, [parentKey])

  return (
    <Box width='100%' paddingBottom={1}>
      <Box display='flex' flexDirection='row' alignItems='center' paddingBottom={2}>
        {/* Title and help content */}
        <Box
          display='flex'
          flexDirection='row'
          alignItems='flex-start'
          columnGap={0.25}
          sx={{
            flexGrow: 1,
            flexBasis: 'auto',
            flexShrink: 1,
          }}
        >
          {target.kind === 'saved' && (
            <BookmarkBorderIcon fontSize='small' color='default' />
          )}
          <Box>
            <Typography
              variant='h6'
              fontWeight='bold'
              sx={{
                lineHeight: 1.1
              }}
            >
              {target.kind === 'saved' ? target.name : METRIC_ITEMS_MAP[target.metricKey].name}
            </Typography>
            {target.kind === 'saved' && (
              <Typography
                variant='body1'
                color='textSecondary'
                fontSize={14}
              >
                {METRIC_ITEMS_MAP[target.metricKey].name}
              </Typography>
            )}
          </Box>
          <IconButton onClick={handleHelpPopoverOpen} size='small'>
            <HelpOutlineIcon fontSize='inherit' />
          </IconButton>
          {target.kind === 'saved' && (
            <React.Fragment>
              <IconButton
                size='small'
                onClick={handleTargetMenuOpen}>
                <MoreHorizIcon fontSize='inherit' />
              </IconButton>
              <Menu
                anchorEl={targetMenuAnchorEl}
                open={Boolean(targetMenuAnchorEl)}
                onClose={handleTargetMenuClose}
              >
                <MenuItem onClick={handleTargetDeleteDialogOpen}>Delete Target</MenuItem>
              </Menu>
            </React.Fragment>
          )}

          <Backdrop
            open={Boolean(helpAnchorEl)}
            sx={{
              zIndex: theme => theme.zIndex.modal
            }}
          >
            <Popover
              open={Boolean(helpAnchorEl)}
              anchorEl={helpAnchorEl}
              onClose={handleHelpPopoverClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              PaperProps={{
                style: {
                  width: 500,
                  maxWidth: '80%',
                }
              }}
            >
              <Box sx={{ padding: theme => theme.spacing(2, 2) }}>
                <Typography variant='h6' style={{ fontWeight: 600 }}>
                  {target.name}
                </Typography>
                <Typography variant='body1' paragraph>
                  {target.description}
                </Typography>
              </Box>
            </Popover>
          </Backdrop>
        </Box>

        {/* Other Controls */}
        <Box
          display='flex'
          alignItems='center'
          columnGap={1}
          sx={{
            flexGrow: 0,
            flexShrink: 0,
          }}
        >
          <Box>
            <DarkTooltip
              title={
                liveModeAvailable ? (
                  liveMode ?
                    'Disable live mode'
                    : 'Enable live mode (update every minute)'
                ) : 'Live mode is only available when today is in date range'
              }
              placement='bottom'
              enterDelay={liveModeAvailable ? 500 : 0}
              arrow
            >
              <span>
                <Button
                  variant={liveMode ? 'contained' : 'outlined'}
                  color='secondary'
                  size='small'
                  disableElevation
                  onClick={() => onLiveModeChange(!liveMode)}
                  sx={{
                    height: 29,
                    minWidth: 'auto',
                  }}
                  disabled={!liveModeAvailable}
                >
                  {liveMode ?
                    <SyncIcon fontSize='small' />
                    : <SyncDisabledIcon fontSize='small' />
                  }
                </Button>
              </span>

            </DarkTooltip>
          </Box>

          <Button
            variant='contained'
            color='secondary'
            size='small'
            disableElevation
            onClick={() => setTargetSaveDialogOpen(true)}
            sx={{
              fontSize: 12,
              fontWeight: 600,
            }}
          >
            Save Target
          </Button>

          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            resolution={resolution}
            onDatesChange={onDatesChange}
            anchorDirection={'right'}
            allowFuture={true}
            allowJumping={true}
            allowAdjusting={true}
          />
        </Box>
      </Box>

      <Box display='flex' flexDirection='row' alignItems='center' columnGap={1} flexWrap='wrap' paddingBottom={1}>
        <Button
          ref={segmentAnchorRef}
          variant='contained'
          size='small'
          color='secondary'
          onClick={handleSegmentsSelectOpen}
          endIcon={<ArrowDropDownIcon />}
          disableElevation
          sx={{
            height: 29,
            fontSize: 12,
            fontWeight: 600,
          }}
        >
          Saved Segments
        </Button>
        <Popover
          open={segmentsMenuOpen}
          anchorEl={segmentAnchorRef.current}
          onClose={handleSegmentsSelectClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          TransitionProps={{
            onEntered: () => {
              if (segmentInputRef.current) {
                segmentInputRef.current.focus()
              }
            }
          }}
        >
          <Box width={300} sx={{ padding: theme => theme.spacing(1, 1)}}>
            <Autocomplete
              id='segment-autocomplete-simple'
              size='small'
              options={savedSegments.sort((a, b) => a.name.localeCompare(b.name))}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              onChange={(_, option) => handleSelectSegmentValue(option)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputRef={segmentInputRef}
                  placeholder='Search my segments'
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option.id} style={{ justifyContent: 'flex-start' }}>
                  <Box width='100%' display='flex' flexDirection='row' alignItems='center'>
                    <SegmentIcon style={{ fontSize: 16, marginRight: 8 }} />
                    <Typography variant='body2'>
                      {option.name}
                    </Typography>

                    <div style={{ flexGrow: 1 }} />

                    <IconButton
                      onClick={(e) => handleSegmentEditDialogOpen(e, option.id)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={(e) => handleSegmentDeleteDialogOpen(e, option.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </li>
              )}
              noOptionsText='No matching segments'
              autoHighlight
              openOnFocus
              fullWidth
            />
          </Box>
        </Popover>

        <Config
          config={config}
          configItems={target.configItems}
          onConfigChange={onConfigChange}
        />
      </Box>

      {/* Segments rows */}
      <DragDropContext onDragEnd={handleSegmentsDragReorder}>
        <Droppable droppableId='segments'>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {segments.map((segment, index) => (
                <Draggable key={segment.id} draggableId={segment.id} index={index}>
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      display='flex'
                      flexDirection='row'
                    >
                      <SegmentBuilder
                        key={segment.id}
                        config={config}
                        allowCohorts={false}
                        allowBreakdown={false}
                        allowOneSegmentOnly={true}
                        segment={segment}
                        segmentEligibleBreakdownKeys={segmentsEligibleBreakdownKeys[index]}
                        segments={segments}
                        aliases={aliases}
                        savedSegments={savedSegments}
                        savedGroups={savedGroups}
                        onSegmentDelete={onSegmentDelete}
                        onSegmentDuplicate={onSegmentDuplicate}
                        onSegmentEditChange={onSegmentEditChange}
                        onSegmentSaveEdit={onSegmentSaveEdit}
                        onSegmentRevertEdit={onSegmentRevertEdit}
                        onSegmentColorChange={onSegmentColorChange}
                        onSegmentFilterAdd={onSegmentFilterAdd}
                        onSegmentReplaceFiltersWithSavedSegmentFilter={onSegmentReplaceFiltersWithSavedSegmentFilter}
                        onSegmentFilterDelete={onSegmentFilterDelete}
                        onFilterAdd={onFilterAdd}
                        onFilterUpdate={onFilterUpdate}
                        onFilterDelete={onFilterDelete}
                        onClearAllSegmentFilters={onClearAllSegmentFilters}
                        onSegmentBreakdownSelect={onSegmentBreakdownSelect}
                        onSegmentBreakdownColorChange={onSegmentBreakdownColorChange}
                        dragHandleProps={provided.dragHandleProps}
                      />
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Box display='flex' flexDirection='row' alignItems='center' columnGap={1} flexWrap='wrap' marginTop={2}>
        <TextField
          focused
          size='small'
          variant='outlined'
          color='secondary'
          name='targetValue'
          label='Target Value'
          placeholder='Enter target value'
          value={inputValue ? getDisplayValueByFormat( // Force targets to be integers, input adornment will handle currency symbol if needed
            inputValue,
            'integer',
          ) : ''}
          onChange={(event) => {
            const onlyNumbers = event.target.value.replace(/[^0-9]/g, '')
            setInputValue(onlyNumbers)
          }}
          InputProps={{
            startAdornment: METRIC_ITEMS_MAP[target.metricKey].format === 'currency' ?
              <InputAdornment position='start'>{getCurrencySymbol(userDoc.currency)}</InputAdornment>
              : null
          }}
          error={!inputValue}
        />

        {endDate.isSameOrAfter(moment.tz(TIMEZONE)) && projectedValue && (
          <Chip
            icon={<PlaceIcon fontSize='small' color='inherit' />}
            label={`${getDisplayValueByFormat(
              projectedValue,
              METRIC_ITEMS_MAP[target.metricKey].format,
              {
                decimalCount: 0,
                currency: userDoc.currency,
              }
            )} projected`}
            sx={{
              backgroundColor: projectedValue >= targetValue ? green[800] : lighten(amber[500], 0.8),
              color: theme => theme.palette.getContrastText(projectedValue >= targetValue ? green[800] : lighten(amber[500], 0.8)),
            }}
          />
        )}
      </Box>

      {/* Edit segment Dialog */}
      {segmentEditId && (
        <Dialog open={segmentEditDialogOpen} onClose={handleSegmentEditDialogClose} maxWidth='xs' fullWidth>
          <DialogTitle>Edit Segment</DialogTitle>
          <DialogContent
            sx={{ padding: theme => theme.spacing(1, 3, 0, 3)}}
          >
            <TextField
              autoFocus
              size='small'
              margin='dense'
              label='Segment Name'
              type='text'
              fullWidth
              value={segmentName}
              onChange={e => setSegmentName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button variant='text' onClick={handleSegmentEditDialogClose} color='error'>
              Cancel
            </Button>
            <Button variant='text' disabled={segmentName.length === 0} onClick={() => handleSegmentEdit(segmentEditId)} color='secondary'>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Delete segment confirmation dialog */}
      {segmentDeleteId && (
        <Dialog open={segmentDeleteDialogOpen} onClose={handleSegmentDeleteDialogClose} maxWidth='xs' fullWidth>
          <DialogTitle>Delete Segment?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete the segment <b>{savedSegments.find(s => s.id === segmentDeleteId).name}</b>?
              This will remove the segment from everywhere it is used, which may cause saved targets to break. Only delete
              this segment if you are absolutely sure you no longer need it. It is recommended that you clear your active
              segments in the target before deleting this group to prevent unexpected errors.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSegmentDeleteDialogClose} color='primary'>
              Cancel
            </Button>
            <Button onClick={() => handleSegmentDelete(segmentDeleteId)} variant='outlined' color='error'>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Delete target confirmation dialog */}
      {targetDeleteDialogOpen && (
        <Dialog open={targetDeleteDialogOpen} onClose={handleTargetDeleteDialogClose} maxWidth='xs' fullWidth>
          <DialogTitle>Delete Target?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete your saved target <b>{target.name}</b>?
              This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleTargetDeleteDialogClose} color='primary'>
              Cancel
            </Button>
            <Button onClick={() => handleTargetDelete(target.id)} variant='outlined' color='error'>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Save target Dialog */}
      {targetSaveDialogOpen && (
        <TargetDialog
          open={targetSaveDialogOpen}
          onClose={() => setTargetSaveDialogOpen(false)}
          editId={target.id}
          target={target}
          startDate={startDate}
          endDate={endDate}
          onTargetSave={onTargetSave}
        />
      )}
    </Box>
  )
}

Controls.propTypes = {
  parentKey: PropTypes.string.isRequired,
  target: PropTypes.object.isRequired,
  savedGroups: PropTypes.array.isRequired,
  savedSegments: PropTypes.array.isRequired,
  liveMode: PropTypes.bool.isRequired,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  resolution: PropTypes.number.isRequired,
  config: PropTypes.object.isRequired,
  targetValue: PropTypes.number,
  projectedValue: PropTypes.number,
  segments: PropTypes.array.isRequired,
  segmentsEligibleBreakdownKeys: PropTypes.array.isRequired,
  aliases: PropTypes.object.isRequired,
  onTargetSave: PropTypes.func.isRequired,
  onTargetDelete: PropTypes.func.isRequired,
  onLiveModeChange: PropTypes.func.isRequired,
  onDatesChange: PropTypes.func.isRequired,
  onConfigChange: PropTypes.func.isRequired,
  onTargetValueChange: PropTypes.func.isRequired,
  onSegmentAdd: PropTypes.func.isRequired,
  onSegmentDelete: PropTypes.func.isRequired,
  onSegmentDuplicate: PropTypes.func.isRequired,
  onSegmentEditChange: PropTypes.func.isRequired,
  onSegmentSaveEdit: PropTypes.func.isRequired,
  onSegmentRevertEdit: PropTypes.func.isRequired,
  onSegmentColorChange: PropTypes.func.isRequired,
  onSegmentFilterAdd: PropTypes.func.isRequired,
  onSegmentReplaceFiltersWithSavedSegmentFilter: PropTypes.func.isRequired,
  onSegmentFilterDelete: PropTypes.func.isRequired,
  onSegmentsReorder: PropTypes.func.isRequired,
  onFilterAdd: PropTypes.func.isRequired,
  onFilterUpdate: PropTypes.func.isRequired,
  onFilterDelete: PropTypes.func.isRequired,
  onClearAllSegmentFilters: PropTypes.func.isRequired,
  onSegmentBreakdownSelect: PropTypes.func.isRequired,
  onSegmentBreakdownColorChange: PropTypes.func.isRequired,
}

export default Controls