import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'

import DarkTooltip from './DarkTooltip'

let debounceTimer = null
const SEARCH_DELAY_MS = 500

const SearchInput = ({
  value,
  isExpanded,
  placeholder,
  onChange,
  onIsExpandedChange,
}) => {
  const [inputValue, setInputValue] = useState(value)

  useEffect(() => {
    clearTimeout(debounceTimer)
    debounceTimer = setTimeout(() => {
      onChange(inputValue)
    }, SEARCH_DELAY_MS)
  }, [inputValue, value])

  const handleClear = () => {
    onIsExpandedChange(false)
    setInputValue('')
    onChange('')
  }

  return (
    <Box>
      {!isExpanded && (
        <DarkTooltip
          title='Search breakdowns'
          placement='bottom'
          arrow
        >
          <IconButton
            color='primary'
            onClick={() => onIsExpandedChange(true)}
          >
            <SearchIcon />
          </IconButton>
        </DarkTooltip>
      )}
      {isExpanded && (
        <TextField
          autoFocus
          size='small'
          variant='outlined'
          value={inputValue}
          onChange={(event) => setInputValue(event.target.value)}
          placeholder={placeholder || 'Search...'}
          onBlur={() => inputValue.length === 0 ? onIsExpandedChange(false) : null}
          sx={{
            width: 250,
            '& .MuiOutlinedInput-root': {
              paddingLeft: theme => theme.spacing(1),
              paddingRight: theme => theme.spacing(1),
            },
            marginRight: theme => theme.spacing(1),
          }}
          InputProps={{
            startAdornment: (
              <IconButton size='small'>
                <SearchIcon />
              </IconButton>
            ),
            endAdornment: (
              <IconButton onClick={handleClear} size='small'>
                <CloseIcon fontSize='inherit' />
              </IconButton>
            ),
          }}
        />
      )}
    </Box>
  )
}

SearchInput.propTypes = {
  value: PropTypes.string,
  isExpanded: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onIsExpandedChange: PropTypes.func.isRequired,

}

export default SearchInput