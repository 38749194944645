import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import makeStyles from '@mui/styles/makeStyles'

import GraphContainer from '../GraphContainer/PathAnalysis'

const useStyles = makeStyles(() => ({
  root: {
  }
}))

const Graphs = (props) => {
  const classes = useStyles()
  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} sm={12} md={6}>
        <GraphContainer
          attribution={props.attribution}
          graphId={'ltv'}
          frontendContext={props.frontendContext}
          backendContext={props.backendContext}
          frontendType={props.frontendType}
          extendBackend={props.extendBackend}
          frontendColors={props.frontendColors}
          backendColors={props.backendColors}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <GraphContainer
          attribution={props.attribution}
          graphId={'ltvGain'}
          frontendContext={props.frontendContext}
          backendContext={props.backendContext}
          frontendType={props.frontendType}
          extendBackend={props.extendBackend}
          frontendColors={props.frontendColors}
          backendColors={props.backendColors}
        />
      </Grid>
    </Grid>
  )
}

Graphs.propTypes = {
  attribution: PropTypes.string.isRequired,
  frontendContext: PropTypes.object.isRequired,
  backendContext: PropTypes.object.isRequired,
  frontendType: PropTypes.string.isRequired,
  extendBackend: PropTypes.bool,
  frontendColors: PropTypes.object.isRequired,
  backendColors: PropTypes.object.isRequired,
}

export default Graphs
