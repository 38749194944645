import React, { useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { darken } from '@mui/material/styles'
import { useHistory, Link } from 'react-router-dom'

import { FirebaseContext } from '../../utils/firebase'
import 'firebase/auth'

import FullPageLoadingSpinner from '../Common/FullPageLoadingSpinner'

import * as ROUTES from '../../constants/routes'
import { CONTENT_MAP } from '../../constants/partners'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    backgroundColor: theme.palette.background.default,
  },
  gridItem: {
    maxWidth: 550,
    minWidth: 550,
  },
  headerTypography: {
    fontWeight: 900,
  },
  email: {
    marginTop: theme.spacing(2),
  },
  buttonRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 0),
  },
  button: {
    width: 220,
    height: 52,
    fontSize: 20,
  },
  signInLink: {
    textDecoration: 'underline',
  },
}))

const PasswordForgot = () => {
  const classes = useStyles()
  const history = useHistory()
  const firebase = React.useContext(FirebaseContext)
  const [email, setEmail] = React.useState('')
  const [emailErrorMessage, setEmailErrorMessage] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [contentKey, setContentKey] = React.useState(null)

  // Check URL to display the appropriate login page content
  useEffect(() => {
    // Check path to see if a alternate login page should be displayed
    const loginUrlMatch = history.location.pathname.match(/\/forgot-password\/([^/]+)(?:\/([^/]+))?/)
    const subPath = loginUrlMatch ? loginUrlMatch[1] : null
    if (subPath) {
      setContentKey(subPath)
    } else {
      setContentKey('default')
    }
  }, [])

  const sendPasswordResetEmail = () => {
    setLoading(true)
    firebase.auth().sendPasswordResetEmail(email).then(() => {
      history.push(contentKey ? `${ROUTES.PASSWORD_RESET_SENT}/${contentKey}` : ROUTES.PASSWORD_RESET_SENT)
    }).catch(error => {
      switch(error.code) {
        case 'auth/invalid-email':
        case 'auth/user-not-found':
          setEmailErrorMessage(error.message)
          break
        default:
          setEmailErrorMessage('An unexpected error occurred. Please try again.')
          break
      }
      setLoading(false)
    })
  }

  return (
    <Box>
      {contentKey === null ? <FullPageLoadingSpinner /> : (
        <Grid className={classes.root} container alignItems='center' justifyContent='center'>
          <Grid className={classes.gridItem} item xs={12}>
            <Typography className={classes.headerTypography} variant='h5' gutterBottom>
              Reset Your Password
            </Typography>
            <Typography variant='body1'>
              It&apos;s okay, it happens! What&apos;s your email address?
            </Typography>
            <form>
              <TextField
                className={classes.email}
                id='email'
                name='email'
                label='Email Address'
                variant='outlined'
                color='primary'
                value={email}
                onChange={event => setEmail(event.target.value)}
                error={!!emailErrorMessage}
                helperText={emailErrorMessage}
                fullWidth
                autoFocus
              />
              <div className={classes.buttonRow}>
                <Button
                  className={classes.button}
                  variant='contained'
                  onClick={sendPasswordResetEmail}
                  sx={{
                    backgroundColor: CONTENT_MAP[contentKey].buttonColor,
                    color: theme => theme.palette.getContrastText(CONTENT_MAP[contentKey].buttonColor),
                    '&:hover': {
                      backgroundColor: darken(CONTENT_MAP[contentKey].buttonColor, 0.1),
                    }
                  }}
                >
                  {loading ? <CircularProgress color='inherit' size={32} /> : 'Reset Password'}
                </Button>
                <Typography variant='body1'>
                  Remember your password after all? &nbsp;
                  <Link
                    className={classes.signInLink}
                    to={contentKey !== 'default' ? `${ROUTES.LOGIN}/${contentKey}` : ROUTES.LOGIN}
                  >
                    Log In
                  </Link>
                </Typography>
              </div>
            </form>
          </Grid>
        </Grid>
      )}
    </Box>

  );
}

export default PasswordForgot
