import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'

import { FirebaseContext } from '../../../utils/firebase'
import 'firebase/auth'

import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  Line,
  Brush,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip
} from 'recharts'

import LoadingSkeleton from './LoadingSkeleton'
import NoLinesMessage from './NoLinesMessage'

import {
  useQuery,
} from 'react-query'

import { formatNumber, getAlias, shortenString } from '../../../utils/helpers'
import { API_ROOT_URL } from '../../../constants/'

const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const useStyles = makeStyles(theme => ({
  root: {
  },
  controlBar: {
    height: 57,
    display: 'flex',
    alignItems: 'flex-end',
    marginTop: -45,
    justifyContent: 'flex-end',
    [theme.breakpoints.only('xs')]: {
      marginTop: 0,
      justifyContent: 'center',
    },
  },
  controlForm: {
    marginLeft: theme.spacing(2),
  },
  zoomButton: {
    height: 48,
    marginLeft: theme.spacing(1),
  },
  axisLabelTypographyTop: {
    position: 'absolute',
    left: 60,
    color: theme.palette.text.secondary,
  },
  axisLabelTypographyBottom: {
    position: 'absolute',
    top: 325,
    left: 60,
    color: theme.palette.text.secondary,
  },
  tooltipRoot: {
    backgroundColor: 'white',
    textAlign: 'right',
    padding: theme.spacing(1),
    border: '1px solid rgb(204, 204, 204)',
    borderRadius: theme.shape.borderRadius,
    maxWidth: 300,
  },
  tooltipDataTypography: {
    margin: 0,
    fontSize: 12,
  },
  tooltipTitleTypography: {
    color: theme.palette.text.secondary,
  },
  noGraphDataTypography: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.text.hint,
  },
  summaryTypography: {
    color: theme.palette.text.secondary,
    margin: theme.spacing(2, 1, 0, 1),
  },
}))

const CHART_HEIGHT = 250
const BRUSH_HEIGHT = 20
const SYNC_ID = 'ltv'
const TICK_FONT_SIZE = 12

const dataSelectItems = [
  {id: 'ltv', value: 'LTV', description: 'Lifetime Value' },
  {id: 'ltr', value: 'LTR', description: 'Lifetime Revenue' },
]

const GraphLTV = (props) => {
  const { attribution, context, extendBackend, includeCosts, includeCommission, colors } = props
  const classes = useStyles()
  const theme = useTheme()
  const firebase = React.useContext(FirebaseContext)
  const [topData, setTopData] = React.useState('ltv')
  const [showBrush, setShowBrush] = React.useState(false)

  const activeIds = Object.keys(colors).filter(id => !!colors[id])
  let activeRowFilter = []
  if (context.breakdown) {
    activeRowFilter.push({ id: context.breakdown.id, name: context.breakdown.name, value: activeIds })
  }
  const allFilters = [...context.filters, ...activeRowFilter]
  const { isLoading, data } = useQuery(['ltv', attribution, context.dates, context.breakdown, allFilters, extendBackend, includeCosts, includeCommission], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/graphs/ltv`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          attribution,
          startDate: context.dates.start.format(),
          endDate: context.dates.end.format(),
          timezone: TIMEZONE,
          breakdown: context.breakdown,
          filters: allFilters,
          extendBackend,
          includeCosts,
          includeCommission
        })
      }).then(res => res.json())
    }),
    {
      enabled: !!context.dates.start && !!context.dates.end && activeIds.length > 0,
      cacheTime: 15 * 60 * 1000,  // 15 minutes
      staleTime: 15 * 60 * 1000,  // 15 minutes
      refetchOnWindowFocus: false,
    }
  )

  const top = data ? {
    type: 'line',
    data: data[topData]
  } : {}
  const bottom = data ? {
    type: 'bar',
    data: data.counts
  } : {}
  const aliases = data ? data.aliases : []
  const topName = dataSelectItems.find(item => item.id === topData).value
  const topDescription = dataSelectItems.find(item => item.id === topData).description

  const noLines = !data || activeIds.length === 0
  const summaryMessage = `[Top] ${topData === 'ltv' ? 'Lifetime Value' : 'Lifetime Revenue'} by day for ${attribution === 'customer' ? 'customers that made their first purchase' : 'leads that were captured'} between ${context.dates.start ? context.dates.start.format('MM/DD/YY') : null} and ${context.dates.end ? context.dates.end.format('MM/DD/YY') : null}, extending until today's date. [Bottom] Number of ${attribution === 'customer' ? 'customers' : 'leads'} that have reached that "age" in days. Bigger number = more meaningful ${topName}.`
  return (
    <Fragment>
      <div className={classes.controlBar}>
        <FormControl className={classes.controlForm} margin='dense' size='small'>
          <InputLabel id='top-label'>Top</InputLabel>
          <Select
            labelId='top-label'
            label='Top'
            id='top-select'
            value={topData}
            onChange={event => setTopData(event.target.value)}
          >
            {dataSelectItems.map(item =>
              <MenuItem key={item.id} value={item.id}>{item.value}</MenuItem>
            )}
          </Select>
        </FormControl>
        <IconButton
          className={classes.zoomButton}
          aria-label='zoom'
          onClick={() => setShowBrush(!showBrush)}
          size="large">
          {showBrush ? <ZoomOutIcon /> : <ZoomInIcon />}
        </IconButton>
      </div>

      {isLoading ? (
        <LoadingSkeleton classes={classes} height={CHART_HEIGHT * 4/3} />
      ) : noLines ? (
        <NoLinesMessage classes={classes} height={CHART_HEIGHT * 4/3} />
        ) : (
        <div style={{ position: 'relative' }}>
          <Typography className={classes.axisLabelTypographyTop} component='div' variant='caption'>
            {topDescription}
          </Typography>
          <ResponsiveContainer width='100%' height={showBrush ? CHART_HEIGHT + BRUSH_HEIGHT : CHART_HEIGHT}>
            <ComposedChart data={top.data} margin={{ top: showBrush ? 40 : BRUSH_HEIGHT, bottom: showBrush ? -1 * BRUSH_HEIGHT : 0, left: 0, right: 0 }} syncId={SYNC_ID}>
              <XAxis
                dataKey='x'
                tick={{ fontSize: TICK_FONT_SIZE }}
              />
              <YAxis
                type='number'
                domain={['auto', 'auto']}
                tick={{ fontSize: TICK_FONT_SIZE }}
                tickFormatter={val => `$${formatNumber(val, 2, true, true)}`}
              />
              <CartesianGrid strokeDasharray='1 1' vertical={false} />
              <Tooltip
                isAnimationActive={false}
                content={<CustomTooltipTop dataName={topDescription} />}
                wrapperStyle={{ zIndex: 1000 }}
              />
              {showBrush &&
                <Brush
                  dataKey='x'
                  height={BRUSH_HEIGHT}
                  y={8}
                  stroke={theme.palette.secondary.main}
                  travellerWidth={20}
                  gap={30}
                />
              }
              {activeIds.map(id => {
                switch(top.type) {
                  case 'line':
                    return (
                      <Line
                        key={id}
                        dataKey={id}
                        name={getAlias(aliases, id)}
                        stroke={colors[id]}
                        dot={{ r: 0, fill: colors[id] }}
                        type='linear'
                        connectNulls
                      />
                    )
                  case 'bar':
                    return (
                      <Bar
                        key={id}
                        dataKey={id}
                        name={getAlias(aliases, id)}
                        fill={colors[id]}
                        stackId='stack'
                      />
                    )
                  default:
                    return null
                }
              })}
            </ComposedChart>
          </ResponsiveContainer>
          <ResponsiveContainer width='100%' height={CHART_HEIGHT / 3}>
            <ComposedChart data={bottom.data} margin={{ top: 0, bottom: -1 * BRUSH_HEIGHT, left: 0, right: 0 }} syncId={SYNC_ID}>
              <XAxis
                dataKey='x'
                axisLine={false}
                tickLine={false}
                tick={false}
              />
              <YAxis
                type='number'
                domain={[0, 'dataMax']}
                tick={{ fontSize: TICK_FONT_SIZE }}
                interval='preserveStartEnd'
                tickFormatter={val => formatNumber(val, 0, true, true)}
                reversed
              />
              <CartesianGrid strokeDasharray='1 1' vertical={false} />
              <Tooltip
                isAnimationActive={false}
                content={<CustomTooltipBottom attribution={attribution} />}
                wrapperStyle={{ zIndex: 1000 }}
              />
              {activeIds.map(id => {
                switch(bottom.type) {
                  case 'line':
                    return (
                      <Line
                        key={id}
                        dataKey={id}
                        name={getAlias(aliases, id)}
                        stroke={colors[id]}
                        dot={{ r: 0, fill: colors[id] }}
                        type='linear'
                        connectNulls
                      />
                    )
                  case 'bar':
                    return (
                      <Bar
                        key={id}
                        dataKey={id}
                        name={getAlias(aliases, id)}
                        fill={colors[id]}
                        stackId='stack'
                      />
                    )
                  default:
                    return null
                }
              })}
            </ComposedChart>
          </ResponsiveContainer>
          <Typography className={classes.axisLabelTypographyBottom} component='div' variant='caption'>
            {attribution === 'customer' ? 'Customers "Alive"' : 'Leads "Alive"'}
          </Typography>
          <Typography className={classes.summaryTypography} component='div' variant='caption'>
            {summaryMessage}
          </Typography>
        </div>
        )
      }
    </Fragment>
  );
}

const CustomTooltipTop = ({ active, payload, label, dataName }) => {
  const classes = useStyles()

  if (payload === null) return null

  payload.sort((a, b) => {
    return parseFloat(b.value) - parseFloat(a.value)
  })

  if (active) {
    return (
      <div className={classes.tooltipRoot}>
        <Typography variant='overline'>
          Day {label}
        </Typography>
        {payload.map(datum =>
          <Typography key={datum.dataKey} className={classes.tooltipDataTypography} style={{ color: datum.color }}>
            {`${shortenString(datum.name, 32)} : $${formatNumber(datum.value, 2)}`}
          </Typography>
        )}
        <Typography className={classes.tooltipTitleTypography} variant='caption'>
          {dataName}
        </Typography>
      </div>
    )
  }

  return null
}

const CustomTooltipBottom = ({ attribution, active, payload }) => {
  const classes = useStyles()

  if (payload === null) return null

  if (active) {
    return (
      <div className={classes.tooltipRoot}>
        {payload.map(datum =>
          <Typography key={datum.dataKey} className={classes.tooltipDataTypography} style={{ color: datum.color }}>
            {`${shortenString(datum.name, 32)} : ${formatNumber(datum.value, 0)}`}
          </Typography>
        )}
        <Typography className={classes.tooltipTitleTypography} variant='caption'>
          {attribution === 'customer' ? 'Customers "Alive"' : 'Leads "Alive"'}
        </Typography>
      </div>
    )
  }

  return null
}

GraphLTV.defaultProps = {
  extendBackend: false,
  includeCosts: false,
  includeCommission: false,
}

GraphLTV.propTypes = {
  attribution: PropTypes.string.isRequired,
  context: PropTypes.object.isRequired,
  extendBackend: PropTypes.bool,
  includeCosts: PropTypes.bool,
  includeCommission: PropTypes.bool,
  colors: PropTypes.object.isRequired,
}

export default GraphLTV
