import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Drawer from '@mui/material/Drawer'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
  },
  box: {
    height: '100%',
    width: 300,
  },
  filterContent: {
    height: 'calc(100% - 64px)',
    padding: theme.spacing(2),
    overflowY: 'auto',
  },
  actionRow: {
    height: 64,
    width: '100%',
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',

  },
  actionButton: {
    margin: theme.spacing(0, 1),
  },
}))

const typeItems = [
  {
    value: 'order',
    name: 'Order',
  },
  {
    value: 'rebill',
    name: 'Rebill',
  },
  {
    value: 'refund',
    name: 'Refund',
  },
]
const INITIAL_TYPE = typeItems.find(t => t.value === 'order')

const TypeFilter = (props) => {
  const classes = useStyles()

  const [open, setOpen] = React.useState(false)
  const [type, setType] = React.useState(INITIAL_TYPE)

  const handleAddFilter = () => {
    let filters = []

    const filter = {
      id: 'type',
      name: 'Type',
      value: type.value,
      valueName: type.name,
      comparison: '='
    }
    filters.push(filter)

    props.onFiltersAdd(filters)
    handleClose()
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setType(INITIAL_TYPE)
    setOpen(false)
  }

  let validEntry = Boolean(type)

  return (
    <div className={classes.root}>
      <Button
        variant='outlined'
        color='primary'
        onClick={handleOpen}
        endIcon={<ArrowDropDownIcon color='primary' />}
      >
        Type
      </Button>
      <Drawer
        anchor='right'
        open={open}
        onClose={handleClose}
      >
        <Box className={classes.box}>
          <div className={classes.filterContent}>
            <Typography variant='h6' gutterBottom>
              Payment Type
            </Typography>

            <FormControl size='small' fullWidth>
              <InputLabel id='type-select-label'>Type</InputLabel>
              <Select
                labelId='type-select-label'
                id='type-select'
                label='Type'
                value={type ? type.value : ''}
                onChange={(event) => setType(typeItems.find(t => t.value === event.target.value))}
                renderValue={(value) => typeItems.find(t => t.value === value).name}
                sx={{
                  '& .MuiSelect-icon': {
                    right: '9px'
                  }
                }}
              >
                {typeItems.map(item =>
                  <MenuItem
                    key={item.value}
                    value={item.value}
                  >
                    {item.name}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </div>
          <div className={classes.actionRow}>
            <Button
              className={classes.actionButton}
              variant='contained'
              color='primary'
              onClick={handleAddFilter}
              disabled={!validEntry}
            >
              Apply
            </Button>
            <Button className={classes.actionButton} variant='outlined' onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </Box>
      </Drawer>
    </div>
  );
}

TypeFilter.propTypes = {
  onFiltersAdd: PropTypes.func.isRequired,
}

export default TypeFilter
